import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { CMDSpForceStand, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
import { getHostById, hostsSliceActions } from '../../../modules/host/slice';
import store from '../../../store/store';
import { batch } from 'react-redux';
import { popupSliceActions } from '../../../modules/popup/slice';
import { GAME_KEY } from '../../../modules/games/slice';

export class CMDSpForceStandHandler extends AbstractHandler {
    _command: CMDSpForceStand;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpForceStand;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const state = store.getState();
        const gameState = state[GAME_KEY];
        const host = getHostById(state, this._command.HostID);
        if (host) {
            batch(() => {
                dispatch(
                    hostsSliceActions.updateCountDown({
                        hostId: host.HostId,
                        countDown: host.GameDelay
                            ? host.GameDelay.kickNoBetPlayer
                            : 0,
                        canLeave: false,
                    })
                );
                if (host.CurrentResult) {
                    if (gameState.currentGameId == host.CurrentResult.GameID) {
                        dispatch(
                            popupSliceActions.open('blackjack.force_stand')
                        );
                    }
                    dispatch(
                        hostsSliceActions.updateConfirmedBets({
                            hostId: host.HostId,
                            gameId: host.CurrentResult.GameID,
                            bets: [],
                        })
                    );
                }
            });
        }
    }
}
