import { Button, Grid } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SeatAreaContext } from '../../../../../../contexts/SeatAreaContext';
import { WebSocketContext } from '../../../../../../contexts/WebSocketContext';
import { useBetSource } from '../../../../../../hooks/useBetSource';
import { CMDPsMakeDecision } from '../../../../../../models/cmd/live';
import { Decision } from '../../../../../../models/games/blackjack/enums/Decision';
import { BaseTypography } from '../../../../../../components/common/baseText/BaseTypography';

export const SurrenderDecisionButton = () => {
    const { t } = useTranslation();
    const betSource = useBetSource();
    const { sendCommand } = useContext(WebSocketContext);
    const {
        setShowDecision,
        gameId,
        setHasSurrender,
        isFirstDecision,
        canSurrender,
    } = useContext(SeatAreaContext);
    return (
        <Button
            className="blackjack-decision-button"
            onClick={() => {
                const command = new CMDPsMakeDecision();
                command.GameID = gameId;
                command.BetSource = betSource;
                command.Decision = Decision.Surrender;

                sendCommand(command);
                setShowDecision(false);
                setHasSurrender(true);
            }}
            disabled={!isFirstDecision || !canSurrender}
        >
            <Grid>
                <svg
                    width="57"
                    height="56"
                    viewBox="0 0 57 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="0.800049"
                        width="56"
                        height="56"
                        rx="28"
                        fill="#757575"
                    />
                    <g clipPath="url(#clip0_246_291829)">
                        <path
                            d="M28.8 26.1146L35.4 19.5146L37.2854 21.4L30.6854 28L37.2854 34.6L35.4 36.4853L28.8 29.8853L22.2 36.4853L20.3147 34.6L26.9147 28L20.3147 21.4L22.2 19.5146L28.8 26.1146Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_246_291829">
                            <rect
                                x="12.8"
                                y="12"
                                width="32"
                                height="32"
                                rx="16"
                                fill="white"
                            />
                        </clipPath>
                    </defs>
                </svg>
                <BaseTypography
                    color={'white'}
                    fontSize={18}
                    resize={{ direction: 'horizontal', value: '80px' }}
                >
                    {t('blackjack.decision.surrender')}
                </BaseTypography>
            </Grid>
        </Button>
    );
};
