import { createContext } from 'react';
import { useSeatArea, useSeatAreaState } from '../hooks/useSeatArea';

export const SeatAreaContext = createContext({} as useSeatAreaState);
const { Provider } = SeatAreaContext;
type SeatAreaProviderOwnProps = {
    children: JSX.Element;
    hostId: number;
};
type SeatAreaProviderProps = SeatAreaProviderOwnProps;
export const SeatAreaProvider = (props: SeatAreaProviderProps) => {
    const { children, hostId } = props;
    const sa = useSeatArea(hostId);
    return <Provider value={sa}>{children}</Provider>;
};
