import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { SupportedGameType } from '../../../epics/roadmapEpic';
import { GameConfigHostsType } from '../../../hooks/useConfig';
import { HostTable } from '../../../modules/host/models';
import { hostsSliceActions } from '../../../modules/host/slice';
import { lobbySliceActions } from '../../../modules/lobby/slice';
import { resultProgressSliceActions } from '../../../modules/progress/slice/result';
import { roadmapSliceActions } from '../../../modules/roadmap/slice';
import {
    TotalBet,
    totalBetsSliceActions,
} from '../../../modules/totalBet/slice';
import { getEmptyRoadMap } from '../../Roadmap';
import { Progress } from '../../games/Progress';
import { BaccaratRoadmapRecord } from '../../games/baccarat';
import { CMDSpHostList, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpHostListHandler extends AbstractHandler {
    _command: ICommand;
    _gameConfigHosts: Array<GameConfigHostsType>;
    _gameConfigHallOrder: number[];
    _gameConfigHostsKey: number[];
    _productionTestPermission: boolean;
    _dafaBetPermission: boolean;
    _hostList: Array<HostTable>;
    constructor(
        command: ICommand,
        gameConfigHosts: Array<GameConfigHostsType>,
        gameConfigHallOrder: number[],
        productionTestPermission: boolean,
        dafaBetPermission: boolean
    ) {
        super();
        this._command = command;
        this._gameConfigHosts = gameConfigHosts;
        this._gameConfigHostsKey = this._gameConfigHosts.map(v => v.hostId);
        this._gameConfigHallOrder = gameConfigHallOrder;
        this._productionTestPermission = productionTestPermission;
        this._dafaBetPermission = dafaBetPermission;
    }
    hostList(): Array<HostTable> {
        let order = 0;
        const hostListCommand = this._command as CMDSpHostList;
        this._hostList = hostListCommand.Host.filter(h => {
            const hostId = h.HostID;
            //if (this._gameConfigHostsKey.includes(hostId)) return true;

            if (this._gameConfigHostsKey.includes(hostId)) {
                return true;
            }

            return false;
        })
            .sort((a, b) => {
                if (!this._gameConfigHostsKey) return 0;
                return (
                    this._gameConfigHostsKey.indexOf(a.HostID) -
                    this._gameConfigHostsKey.indexOf(b.HostID)
                );
            })
            .map(h => {
                const hostId = h.HostID;
                const gameConfig =
                    this._gameConfigHosts[
                        this._gameConfigHostsKey.indexOf(hostId)
                    ];
                const group = gameConfig.group;
                let hallStateName = 'other';
                if (group.length >= 1) {
                    hallStateName = group[0] + 'Hall';
                }
                const hall = gameConfig.hall;
                order++;
                let orderBy = order;
                if (hall) {
                    orderBy =
                        (this._gameConfigHallOrder.indexOf(Number(hall)) + 1) *
                            100 +
                        orderBy;
                } else {
                    orderBy = orderBy + 1000;
                }

                return {
                    HostId: hostId,
                    Setting: h.Setting,
                    //GameType: h.GameType,
                    //game_config
                    HallId: hall,
                    GameType: gameConfig.type,
                    Group: group,
                    HallStateName: hallStateName,
                    Video: gameConfig.video,
                    Audio: gameConfig.audio,
                    Hls: gameConfig.hls,
                    Nano: gameConfig.nano,
                    HostName: gameConfig.hostName,
                    DelayDraw: gameConfig.delayDraw,
                    DelayResult: gameConfig.delayResult,
                    DelayZoom:
                        gameConfig.delayZoom !== undefined
                            ? gameConfig.delayZoom
                            : 5000,
                    enabled: gameConfig.enabled,
                    TableLimit: gameConfig.tableLimit,
                    Time: gameConfig.time,
                    Sai: gameConfig.sai,
                    ShuffleDelay: gameConfig.shuffleDelay,
                    IsTest: gameConfig.test,
                    OrderBy: orderBy,
                    IsRest: true, //set default true, 有CMD冇返到IsRest，所以set default = true;
                } as HostTable;
            });
        return this._hostList;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const hosts = this._hostList ? this._hostList : this.hostList();

        const hallGameTypeMap = hosts.map(h => ({
            hostId: h.HostId,
            hallId: h.HallId,
            gameType: h.GameType,
        }));
        const roadmaps = hallGameTypeMap
            .filter(h => (SupportedGameType & h.gameType) > 0)
            .map(h => getEmptyRoadMap<BaccaratRoadmapRecord>(h.hostId));
        const totalBets = hallGameTypeMap.map(
            h =>
                ({
                    HostId: h.hostId,
                    TotalBet: {},
                }) as TotalBet
        );
        batch(() => {
            dispatch(hostsSliceActions.setAll(hosts));
            dispatch(lobbySliceActions.updateHallGameTypeMap(hallGameTypeMap));
            dispatch(roadmapSliceActions.updateAll(roadmaps));
            dispatch(totalBetsSliceActions.updateAll(totalBets));
            dispatch(
                resultProgressSliceActions.endLoad(Progress.SERVER_HOST_LIST)
            );
        });
    }
}
