import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { VideoPlayerProvider } from '../../contexts/VideoContext';
import { BetChipProvider } from '../../contexts/BetChipContext';
import { GameType } from '../../models/games/enums/GameType';
import { AppBar, Box, Grid, Stack } from '@mui/material';
import { PGameHeader } from '../game/components/Header';
import { VideoPlayer } from '../videoPlayer';
import { Footer } from '../lobby/components/Footer';
import { ChipSelector } from '../game/components/BetAreaPanels/Baccarat/ChipSelector';
import { ChipList } from '../game/components/BetAreaPanels/Baccarat/ChipList';
import { useDispatch, useSelector } from 'react-redux';
import { getHostById } from '../host/slice';
import { RootState } from '../../store/store';
import { TableInfoHeader } from '../game/components/TableInfoHeader';
import { getLobbyStateHallGameTypeMap } from '../lobby/selector';
import { MultiBetTableCell } from './GameTables/MultiBetTableCell';
import { getMainLimitRedState, getMainUserState } from '../main/selector';
import { CMDPsRuleChange, CMDPsSetPrefs } from '../../models/cmd/live';
import { WebSocketContext } from '../../contexts/WebSocketContext';
import { updateFavoriteBit, useFavorite } from '../../hooks/useFavorite';
import { UserState, userSliceActions } from '../main/slice/user';
import { getGameSate } from '../games/selector';
import { routeSliceActions } from '../routeController/slice';
import { ROUTE_LOBBY, ROUTE_THANKS4PLAY } from '../../models/Route';
import VideoImages from '../game/VideoImages';
import moment from 'moment';
import { popupSliceActions } from '../popup/slice';
import { GameState } from '../../models/games/enums/GameState';
import { InGameCountdown } from '../countdown';
import { t } from 'i18next';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SwitchVideoIcon from '@mui/icons-material/SwitchVideo';
import BaseImage from '../../components/common/baseImage';
import { InGamePopupLayer } from '../game/components/InGamePopupLayer';
import { gameSliceActions } from '../games/slice';

export const MultiBetScene = () => {
    const dispatch = useDispatch();
    const {
        IsDemo,
        FPlayerPrefs,
        FPlayerPrefs2,
        FPlayerPrefs3,
        NeedPrefsUpdate,
    } = useSelector(getMainUserState);
    const { notToReconnect } = useSelector(getGameSate);
    const [initVideo, setVideoInited] = useState(false);
    const [tableReady, setTableReady] = useState(0);
    const [hostId, switchVideo] = useState(0);
    const [switchFavorite, changeFavorite] = useState(0);
    const [videoBg, setVideoBg] = useState<string>('');
    const kickTime = useRef<number>(0);
    const [checkTimeout, doCheckTimeout] = useState(0);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const favTableList = useFavorite(FPlayerPrefs3);
    const hallGameTypeMap = useSelector(getLobbyStateHallGameTypeMap);
    const [displayFavi, setDisplayFavi] = useState(Array<JSX.Element>());
    const [displayComm, setDisplayComm] = useState(Array<JSX.Element>());
    const [display, setDisplay] = useState(<></>);
    const [itemIndex, setItemIndex] = useState(0);
    const [showCountdown, setShowCountdown] = useState(false);
    const baccaratFavoriteTablesMap = useMemo(
        () =>
            hallGameTypeMap.filter(
                h =>
                    h.gameType === GameType.Baccarat &&
                    favTableList.indexOf(h.hostId) >= 0
            ),
        [hallGameTypeMap, favTableList]
    );
    const baccaratNormalTablesMap = useMemo(
        () =>
            hallGameTypeMap.filter(
                h =>
                    h.gameType === GameType.Baccarat &&
                    favTableList.indexOf(h.hostId) < 0
            ),
        [hallGameTypeMap, favTableList]
    );
    const [hIds, setHids] = useState<Array<number>>([]);
    const [hFIds, setFHids] = useState<Array<number>>([]);
    const limitRedByGameType = useSelector(getMainLimitRedState);
    const { sendCommand } = useContext(WebSocketContext);
    const onGetMaxBet = () => {
        //TODO: 如果有問題，改就收到'CMDSpLimitRedUpdate', 後面pre send;
        const { MaxBet, HostId, GameType } = host;
        if (limitRedByGameType.BetRuleByGameType) {
            const limitRedByGame = limitRedByGameType.BetRuleByGameType?.find(
                br => br.GameType === GameType
            );
            if (limitRedByGame) {
                const BetRule = limitRedByGame.BetRule;
                if (limitRedByGame.RuleToSelect) {
                    sendRuleCommand(
                        GameType,
                        HostId,
                        limitRedByGame.RuleToSelect.RuleID
                    );
                } else if (BetRule && !MaxBet) {
                    //const l = BetRule.concat(BetRule);
                    let selBetRule = BetRule.find(r => r.Selected === 1);
                    if (!selBetRule) selBetRule = BetRule[0]; //有機會全部selected=0
                    if (selBetRule) {
                        sendRuleCommand(GameType, HostId, selBetRule.RuleID);
                    }
                }
            }
        }
    };
    const sendRuleCommand = (gt: number, hId: number, idx: number) => {
        const cmd = new CMDPsRuleChange();
        cmd.GameType = gt;
        cmd.HostID = hId;
        cmd.RuleIndex = idx;
        sendCommand(cmd);
    };
    useEffect(() => {
        const isBetting = host.CurrentState === GameState.Betting;
        setShowCountdown(isBetting);
    }, [host.CurrentState, hostId]);
    useEffect(() => {
        console.log('host::length::update');
        setFHids(baccaratFavoriteTablesMap.map(i => i.hostId));
    }, [baccaratFavoriteTablesMap.length, favTableList]);
    useEffect(() => {
        console.log('host::length::update');
        setHids(baccaratNormalTablesMap.map(i => i.hostId));
    }, [baccaratNormalTablesMap.length, favTableList]);

    const emptyCell = (keyIndex: number) => {
        return (
            <>
                <Stack height="242px" key={`empty_${keyIndex}`}>
                    <Stack
                        direction="row"
                        alignContent="center"
                        justifyContent="space-between"
                        alignItems="center"
                        className={`table-cell-info mHall`}
                    >
                        <Stack
                            direction="row"
                            alignContent="center"
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{
                                gap: '10px',
                                paddingLeft: '8px',
                                '& div': { marginLeft: '8px' },
                            }}
                        >
                            {t(`multibet.baccarat`)}
                            <FavoriteIcon
                                sx={{
                                    color: '#787878',
                                }}
                            />
                            <div className="summaryList">
                                <div className="banker">
                                    {t('road.banker_win')}
                                </div>
                                {0}
                                <div className="player">
                                    {t('road.player_win')}
                                </div>
                                {0}
                                <div className="tie">{t('road.draw')}</div>
                                {0}
                            </div>
                        </Stack>
                        <Stack
                            direction="row"
                            alignContent="center"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                            sx={{
                                '& div.CountdownBox': {
                                    width: '127px',
                                },
                            }}
                        >
                            <>
                                <SwitchVideoIcon sx={{ color: '#BD9F71' }} />
                                <Stack
                                    sx={{
                                        backgroundColor: '#196260',
                                        width: '88px',
                                        height: '28px',
                                    }}
                                ></Stack>
                            </>
                        </Stack>
                    </Stack>
                    <BaseImage
                        className="multibet_game roadmap_multibet_baccarat"
                        scale={0.437}
                    />
                    <Stack>
                        <Box
                            height={'28px'}
                            sx={{
                                display: 'flex',
                                direction: 'column',
                                fontSize: '15px',
                            }}
                        >
                            <Box
                                width={'50%'}
                                sx={{
                                    display: 'flex',
                                    backgroundColor: '#8b7554',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {t('multibet.baccarat')}
                            </Box>
                            <Box
                                width={'50%'}
                                sx={{
                                    display: 'flex',
                                    backgroundColor: '#000',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#967e5a',
                                }}
                            >
                                {t('multibet.no_commission')}
                            </Box>
                        </Box>
                    </Stack>
                </Stack>
            </>
        );
    };

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const containerElement = ref.current;
        if (containerElement) {
            const handleScroll = () => {
                const index = Math.floor(containerElement.scrollTop / 247);
                setItemIndex(index);
            };
            containerElement.addEventListener('scroll', handleScroll);
            handleScroll(); // Check initial scroll position

            return () => {
                containerElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [ref.current]);

    useEffect(() => {
        let displayCount = 3;
        let displayList = [];
        for (let i = 0; i < displayFavi.length + displayComm.length; i++) {
            if (itemIndex <= i && displayCount > 0) {
                if (i < displayFavi.length) {
                    displayList.push(displayFavi[i]);
                } else {
                    displayList.push(displayComm[i - displayFavi.length]);
                }
                displayCount--;
            } else {
                displayList.push(emptyCell(i));
            }
        }
        setDisplay(<>{displayList}</>);
    }, [ref.current, itemIndex, displayComm]);

    useEffect(() => {
        const eles = new Array<JSX.Element>();
        for (const hid of hIds) {
            eles.push(
                <MultiBetTableCell
                    key={`multibet-baccarat-cell-${hid}`}
                    hostId={hid}
                    switchVideo={switchVideo}
                    isFavorite={false}
                    changeFavorite={changeFavorite}
                    setTableReady={setTableReady}
                />
            );
        }
        setDisplayComm(eles);
    }, [hIds]);
    useEffect(() => {
        const eles = new Array<JSX.Element>();
        for (const hid of hFIds) {
            eles.push(
                <MultiBetTableCell
                    key={`multibet-baccarat-cell-${hid}`}
                    hostId={hid}
                    switchVideo={switchVideo}
                    isFavorite={true}
                    changeFavorite={changeFavorite}
                    setTableReady={setTableReady}
                />
            );
        }
        setDisplayFavi(eles);
    }, [hFIds]);
    useEffect(() => {
        if (!initVideo && tableReady != 0) {
            setVideoInited(true);
            switchVideo(tableReady);
        }
    }, [tableReady]);
    useEffect(() => {
        if (NeedPrefsUpdate) {
            let cmd = new CMDPsSetPrefs();
            cmd.FPrefs = BigInt(FPlayerPrefs);
            cmd.FPrefs2 = BigInt(FPlayerPrefs2);
            cmd.FPrefs3 = BigInt(FPlayerPrefs3);
            sendCommand(cmd);
            dispatch(userSliceActions.finishPrefUpdate());
        }
    }, [NeedPrefsUpdate]);
    useEffect(() => {
        if (switchFavorite) {
            const prefs = updateFavoriteBit(FPlayerPrefs3, switchFavorite);

            const data = {} as UserState;
            data.FPlayerPrefs3 = prefs;
            dispatch(userSliceActions.updateUserPrefs(data));
        }
        return () => {
            changeFavorite(0);
        };
    }, [switchFavorite]);
    useEffect(() => {
        const { Group } = host;
        if (Group) {
            let imgUrl = `bg_baccarat_${Group}table`;
            setVideoBg(imgUrl);
        }
    }, [hostId]); //switch table
    const handleTouch = () => {
        kickTime.current = moment().valueOf() + 1000 * 5 * 60;
    };
    useEffect(() => {
        if (kickTime.current != 0) {
            const diff = kickTime.current - moment().valueOf();
            if (diff < 0) {
                //timeout
                dispatch(routeSliceActions.goto(ROUTE_LOBBY));
                dispatch(popupSliceActions.open('system.multibet_timeout'));
            } else {
                setTimeout(() => {
                    doCheckTimeout(v => v + 1);
                }, diff);
            }
        }
    }, [checkTimeout]);
    useEffect(() => {
        onGetMaxBet();
        handleTouch();
        dispatch(gameSliceActions.resetAll());
        if (notToReconnect) {
            //For handle back button
            dispatch(routeSliceActions.goto(ROUTE_THANKS4PLAY));
        }
        window.addEventListener('touchstart', handleTouch);
        if (!IsDemo) {
            doCheckTimeout(v => v + 1);
        }
        return () => {
            window.removeEventListener('touchstart', handleTouch);
        };
    }, []);
    return (
        <>
            <VideoPlayerProvider hostId={hostId}>
                <BetChipProvider gameType={GameType.Baccarat}>
                    <>
                        <InGamePopupLayer />
                        <AppBar
                            component={'header'}
                            elevation={0}
                            sx={{ backgroundColor: 'rgba(255,255,255,0)' }}
                        >
                            <PGameHeader isMultiBet={true} />
                        </AppBar>
                        <Box
                            component={'main'}
                            sx={{
                                marginTop: '50px',
                                marginLeft: '0px',
                            }}
                        >
                            {showCountdown && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        left: '10px',
                                        top: '75px',
                                    }}
                                >
                                    <InGameCountdown hostId={hostId} />
                                </Box>
                            )}
                            <Box
                                id={'video-bg'}
                                sx={{
                                    position: 'absolute',
                                    top: '50px',
                                    left: '0px',
                                    zIndex: '-1',
                                }}
                            >
                                {/* <BaseImage
                                    className={`videoBg ${videoBg}`}
                                    scale={1}
                                /> */}
                                {videoBg && (
                                    <img
                                        src={
                                            VideoImages[
                                                videoBg as keyof typeof VideoImages
                                            ]
                                        }
                                        alt=""
                                        width="540px"
                                    />
                                )}
                            </Box>
                            <VideoPlayer />

                            <Grid paddingTop={'300px'}>
                                <Grid
                                    ref={ref}
                                    container
                                    alignContent="flex-start"
                                    justifyContent="space-between"
                                    height={'478px'}
                                    overflow={'scroll'}
                                    className="multi-bet-games-tab"
                                    gap={'5px'}
                                    sx={{
                                        overflowX: 'hidden',
                                    }}
                                >
                                    {display}
                                </Grid>
                            </Grid>
                            <Stack
                                direction={'row'}
                                sx={{
                                    paddingTop: '0px',
                                    paddingLeft: '5px',
                                    paddingRight: '5px',
                                    marginBottom: '1px',
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                }}
                            >
                                <ChipSelector />
                                <ChipList isShowAllChips={true} />
                            </Stack>
                            <TableInfoHeader
                                haveZoom={false}
                                haveInfo={false}
                                haveGameRule={false}
                                haveVideoButtonBackground={true}
                                haveSwitchTable={false}
                                haveTotalBet={false}
                            />
                            <Footer />
                        </Box>
                    </>
                </BetChipProvider>
            </VideoPlayerProvider>
        </>
    );
};
