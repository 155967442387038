import {
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Stack,
    Typography,
} from '@mui/material';
import {
    Dispatch,
    SetStateAction,
    useState,
    MouseEvent,
    ChangeEvent,
    useEffect,
    useContext,
} from 'react';
import { TickButton } from '../TickButton';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PasswordScoreBar } from './components/PasswordScoreBar';
import { popupSliceActions } from '../../../popup/slice';
import { useDispatch, useSelector } from 'react-redux';
import { POPUP_TYPE } from '../../../../models/Popup';
import { RootState } from '../../../../store/store';
import { getMainUserState, getResultState } from '../../../main/selector';
import { resultSliceActions } from '../../../main/slice/result';
import { CMDPsChangePassword } from '../../../../models/cmd/live';
import { WebSocketContext } from '../../../../contexts/WebSocketContext';
import './index.scss';
type ChangePasswordProps = {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
};
export const ChangePassword = (props: ChangePasswordProps) => {
    const { setIsOpen } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { sendCommand } = useContext(WebSocketContext);
    const [passwords, setPasswords] = useState({
        oldPassword: '',
        showOldPassword: false,
        newPassword: '',
        showNewPassword: false,
        confirmPassword: '',
        showConfirmPassword: false,
    });
    const { isPasswordChange } = useSelector((state: RootState) =>
        getResultState(state)
    );
    const { LobbyType } = useSelector((state: RootState) =>
        getMainUserState(state)
    );
    useEffect(() => {
        if (isPasswordChange) {
            dispatch(popupSliceActions.close());
            dispatch(resultSliceActions.reset());
            setIsOpen(false);
        }
    }, [isPasswordChange]);

    const submit = async () => {
        if (passwords.oldPassword == '' || passwords.newPassword == '') {
            dispatch(popupSliceActions.open('password.password_empty'));
            return;
        }
        if (passwords.newPassword != passwords.confirmPassword) {
            dispatch(popupSliceActions.open('password.password_confirm_fail'));
            return;
        }
        if (passwords.oldPassword == passwords.newPassword) {
            dispatch(popupSliceActions.open('password.password_same'));
            return;
        }

        const Exp = '^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])([a-zA-Z0-9]+){8,15}$';
        if (!passwords.newPassword.match(Exp)) {
            dispatch(popupSliceActions.open('password.password_rule'));
            return;
        }

        dispatch(
            popupSliceActions.open(
                'password.updating_password',
                undefined,
                POPUP_TYPE.POPUP
            )
        );

        const cmd = new CMDPsChangePassword();
        cmd.OldPassword = passwords.oldPassword;
        cmd.NewPassword = passwords.newPassword;
        cmd.type = LobbyType;
        sendCommand(cmd);
    };

    const handleClickShowPassword =
        (key: keyof typeof passwords) => (event: MouseEvent) => {
            console.log(event);
            setPasswords({
                ...passwords,
                [key]: !passwords[key],
            });
        };
    const handleMouseDownPassword = (event: MouseEvent) => {
        event.preventDefault();
    };

    const handlePasswordChange =
        (key: keyof typeof passwords, maxLength: number) =>
        (event: ChangeEvent<HTMLInputElement>) => {
            const Exp = '^[A-Za-z0-9_-]*$';
            if (event.target.value.match(Exp)) {
                setPasswords({
                    ...passwords,
                    [key]: event.target.value.slice(0, maxLength),
                });
            }
        };
    return (
        <>
            <Stack>
                <Stack
                    direction="row"
                    alignItems={'center'}
                    sx={{
                        height: '20vw',
                        paddingLeft: '3vw',
                    }}
                >
                    <Grid sx={{ width: '40vw', fontSize: '21px' }}>
                        {t('password.current_password')}
                    </Grid>
                    <Grid alignItems={'center'} justifyContent={'center'}>
                        <OutlinedInput
                            sx={{
                                width: '55vw',
                                height: '80px',
                                backgroundColor: '#1c1c1c',
                                borderRadius: '1vw',
                                fontSize: '21px',
                            }}
                            type={
                                passwords.showOldPassword ? 'text' : 'password'
                            }
                            // label={t('feedback.detail')}
                            value={passwords.oldPassword}
                            onChange={handlePasswordChange('oldPassword', 15)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickShowPassword(
                                            'showOldPassword'
                                        )}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {passwords.showOldPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                </Stack>
                <Stack
                    direction="row"
                    alignItems={'top'}
                    sx={{
                        height: '25vw',
                        paddingLeft: '3vw',
                        paddingTop: '30px',
                    }}
                >
                    <Grid
                        sx={{
                            width: '40vw',
                            height: '80px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '21px',
                            }}
                        >
                            {t('password.new_password')}
                        </Typography>
                    </Grid>
                    <Grid
                        alignItems={'center'}
                        justifyContent={'center'}
                        maxWidth={'320px'}
                    >
                        <FormControl>
                            <OutlinedInput
                                sx={{
                                    width: '55vw',
                                    height: '80px',
                                    backgroundColor: '#1c1c1c',
                                    borderRadius: '1vw',
                                    fontSize: '21px',
                                }}
                                type={
                                    passwords.showNewPassword
                                        ? 'text'
                                        : 'password'
                                }
                                // label={t('feedback.detail')}
                                value={passwords.newPassword}
                                onChange={handlePasswordChange(
                                    'newPassword',
                                    15
                                )}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword(
                                                'showNewPassword'
                                            )}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                        >
                                            {passwords.showNewPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <PasswordScoreBar
                                password={passwords.newPassword}
                            />
                        </FormControl>
                    </Grid>
                </Stack>

                <Stack
                    direction="row"
                    alignItems={'top'}
                    sx={{
                        height: '25vw',
                        paddingLeft: '3vw',
                        paddingTop: '30px',
                    }}
                >
                    <Grid
                        sx={{ width: '40vw', height: '80px', fontSize: '21px' }}
                    >
                        {t('password.confirm_password')}
                    </Grid>
                    <Grid alignItems={'center'} justifyContent={'center'}>
                        <FormControl
                            sx={{
                                width: '55vw',
                                height: '20vw',
                            }}
                        >
                            <OutlinedInput
                                sx={{
                                    width: '55vw',
                                    height: '80px',
                                    backgroundColor: '#1c1c1c',
                                    borderRadius: '1vw',
                                    fontSize: '21px',
                                }}
                                type={
                                    passwords.showConfirmPassword
                                        ? 'text'
                                        : 'password'
                                }
                                // label={t('feedback.detail')}
                                value={passwords.confirmPassword}
                                onChange={handlePasswordChange(
                                    'confirmPassword',
                                    15
                                )}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword(
                                                'showConfirmPassword'
                                            )}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                        >
                                            {passwords.showConfirmPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>
                                {t('password.password_rule')}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </Stack>
                <TickButton onClick={submit} />
            </Stack>
        </>
    );
};
export default ChangePassword;
