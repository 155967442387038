import { Backdrop, Grid, Stack } from '@mui/material';
import './PokDengECard.scss';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { getHostById } from '../../../host/slice';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import {
    GetPDCardsHandResult,
    GetPDPointFromPDHandResult,
} from '../../../../utils/games/pokdeng';
import {
    PokDengFullDeckInfo,
    DeckInfo as PokDengDeckInfo,
} from '../../../../models/games/pokdeng/DeckInfo';
import { Poker } from '../../../../components/common/poker';
import { HandResult as PokDengHandResult } from '../../../../models/games/pokdeng/HandResult';
import { useTranslation } from 'react-i18next';
import { PokDengResult } from '../../../../models/games/pokdeng';

const emptyDeckData = (): PokDengFullDeckInfo => {
    return {
        bankerDeck: {
            position: 0,
            point: 0,
            card1: -1,
            card2: -1,
            handResult: PokDengHandResult.NoResult,
        },
        playerDecks: [
            {
                position: 1,
                point: 0,
                card1: -1,
                card2: -1,
                handResult: PokDengHandResult.NoResult,
            },
            {
                position: 2,
                point: 0,
                card1: -1,
                card2: -1,
                handResult: PokDengHandResult.NoResult,
            },
            {
                position: 3,
                point: 0,
                card1: -1,
                card2: -1,
                handResult: PokDengHandResult.NoResult,
            },
            {
                position: 4,
                point: 0,
                card1: -1,
                card2: -1,
                handResult: PokDengHandResult.NoResult,
            },
            {
                position: 5,
                point: 0,
                card1: -1,
                card2: -1,
                handResult: PokDengHandResult.NoResult,
            },
        ],
    };
};

type PokDengECardProps = {
    show: boolean;
};
export const PokDengECard = (props: PokDengECardProps) => {
    const { show } = props;
    const { t } = useTranslation();
    const { hostId } = useContext(VideoPlayerContext);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const [display, setDisplay] = useState<PokDengFullDeckInfo>();
    useEffect(() => {
        const showResult = host?.PokerShowResult;
        const result = host?.CurrentResult as PokDengResult;
        if (showResult && showResult.length > 0) {
            const d = emptyDeckData();
            for (const ps of showResult) {
                const card = ps.Card;
                const cardIdx = ps.Slot % 2;
                const handIdx = Math.floor(ps.Slot / 2);
                let hand: PokDengDeckInfo;
                if (handIdx < 5) {
                    hand = d.playerDecks[handIdx];
                } else {
                    hand = d.bankerDeck;
                }
                if (cardIdx == 0) {
                    hand.card1 = card;
                } else {
                    hand.card2 = card;
                }
                hand.handResult = GetPDCardsHandResult([
                    hand.card1,
                    hand.card2,
                ]);
                hand.point = GetPDPointFromPDHandResult(hand.handResult);
            }
            setDisplay(d);
        } else if (result?.fullDeck) {
            setDisplay(result.fullDeck);
        } else {
            setDisplay(undefined);
        }
    }, [host.PokerShowResult, show]);
    // useEffect(() => {
    //     const result = host?.CurrentResult as PokDengResult;
    //     if (result?.ResultString) {
    //         setDisplay(result.fullDeck);
    //     }
    //     return () => {
    //         setDisplay(undefined);
    //     };
    // }, [host?.CurrentResult]);
    return (
        <Backdrop
            className={'pokdeng-poker-show-backdrop'}
            sx={{
                backgroundColor: 'rgba(0,0,0,0.8)',
                zIndex: theme => theme.zIndex.drawer + 1,
            }}
            open={show}
        >
            <Stack direction={'column'} alignItems={'center'} gap={'15px'}>
                {display && (
                    <Stack direction={'row'} gap={'4px'} alignItems={'center'}>
                        <Grid
                            item
                            sx={{
                                color: '#D30200',
                                fontSize: '18px',
                                minWidth: '30px',
                                textAlign: 'center',
                            }}
                        >
                            {t('pokdeng.dealer')}
                        </Grid>
                        <Stack direction={'row'} gap={'4px'}>
                            <Poker scale={0.7} n={display.bankerDeck.card1} />
                            <Poker scale={0.7} n={display.bankerDeck.card2} />
                        </Stack>
                        <Grid
                            item
                            sx={{
                                fontSize: '18px',
                                minWidth: '30px',
                                textAlign: 'center',
                            }}
                        >
                            {display.bankerDeck.point}
                        </Grid>
                    </Stack>
                )}
                {display && (
                    <Stack direction={'row'} gap={'12px'}>
                        {display.playerDecks.map((record, index) => {
                            const title = `pokdeng.player.${
                                record.position - 1
                            }`;
                            return (
                                <Stack
                                    direction={'column'}
                                    key={`pokdeng-player-hand-${index}`}
                                >
                                    <Stack direction={'row'} gap={'4px'}>
                                        <Poker scale={0.7} n={record.card1} />
                                        <Poker scale={0.7} n={record.card2} />
                                    </Stack>
                                    <Stack
                                        direction={'row'}
                                        justifyContent={'space-between'}
                                    >
                                        <Grid
                                            item
                                            sx={{
                                                color: '#406DF2',
                                                fontSize: '18px',
                                                maxWidth: '50px',
                                            }}
                                        >
                                            {t(title)}
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                fontSize: '18px',
                                            }}
                                        >
                                            {record.point}
                                        </Grid>
                                    </Stack>
                                </Stack>
                            );
                        })}
                    </Stack>
                )}
            </Stack>
        </Backdrop>
    );
};
