import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import {
    betRoomSliceActions,
    getPlayerSeatNo,
} from '../../../modules/bet/room/slice';
import { getHostByGameId } from '../../../modules/host/slice';
import store from '../../../store/store';
import { Bet } from '../../host/BetAmount';
import { CMDSpRoomBet, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
export class CMDSpRoomBetHandler extends AbstractHandler {
    _command: CMDSpRoomBet;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpRoomBet;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const gameId = this._command.GameID;
        const state = store.getState();
        const { HostId } = getHostByGameId(state, gameId);
        if (HostId) {
            for (const bet of this._command.SeatBet) {
                const sn = getPlayerSeatNo(state, HostId, bet.PlayerID);
                if (sn) {
                    const bets = bet.Bet.map(
                        b =>
                            ({
                                GameId: gameId,
                                Type: b.betType,
                                Amount: b.betAmount,
                            }) as Bet
                    );
                    dispatch(
                        betRoomSliceActions.bet({
                            HostId,
                            Seat: sn,
                            PlayerId: bet.PlayerID,
                            Bets: bets,
                        })
                    );
                }
            }
        }
    }
}
