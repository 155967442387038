import { Box} from '@mui/material';
export const InGamePopupLayer = () => {
    return (
        <Box
            id="in-game-popup-container" // Emoji popup 用到
            sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                padding: 0,
                margin: 0,
                border: 0,
            }}
        />
    );
};