import { FormControl, FormLabel, Stack } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Rating } from '../../../../../models/Rating';
import { RatingRadio } from './RatingRadio';

type FeedbackRatingProps = {
    name: string;
    value: Rating;
    onChange: Dispatch<SetStateAction<Rating>>;
    playButtonAudio: () => void;
};

export const FeedbackRating = (props: FeedbackRatingProps) => {
    const { t } = useTranslation();
    const { name, value, onChange, playButtonAudio } = props;
    const groupKey = `feedback-radio-${name}`;
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const v = Number(event.target.value);
        onChange(v);
        playButtonAudio();
    };
    return (
        <FormControl className="FeedbackRating">
            <Stack key={groupKey}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <FormLabel>{t(name)}</FormLabel>
                    {value >= 0 && (
                        <FormLabel>{t('feedback.' + Rating[value])}</FormLabel>
                    )}
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    {Object.values(Rating)
                        .filter(value => typeof value === 'number')
                        .map(r => Number(r))
                        .filter(r => r > -1)
                        .sort((a, b) => b - a)
                        .map(r => (
                            <RatingRadio
                                key={`${groupKey}-${r}`}
                                value={r}
                                name={name}
                                checked={value === r}
                                inputProps={{ 'aria-label': Rating[r] }}
                                onChange={handleChange}
                            />
                        ))}
                </Stack>
            </Stack>
        </FormControl>
    );
};
