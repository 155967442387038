import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Bet } from '../../models/host/BetAmount';

const onPendingBet = (state: GameState, action: PayloadAction<Bet>) => {
    const { GameId, Type, Amount, isMultiBet } = action.payload;
    if (isMultiBet) {
        if (GameId !== state.currentGameId) {
            state.pendingBets = [];
            state.currentGameId = GameId;
        }
    }

    if (GameId == state.currentGameId) {
        const index = state.pendingBets.findIndex(b => b.Type === Type);
        if (index == -1) {
            state.pendingBets.push({
                GameId: GameId,
                Type: Type,
                Amount: Amount,
            });
        } else {
            state.pendingBets[index].Amount += Amount;
        }
    }
};

const onPendingBetHistory = (
    state: GameState,
    action: PayloadAction<Array<Bet>>
) => {
    const pendingBets = action.payload;

    // Pending Bet History
    state.pendingBetHistory.push(pendingBets);
};

const resetBets = (state: GameState, action: PayloadAction<number>) => {
    if (action.payload <= 0 || state.currentGameId == action.payload) {
        if (state.pendingBetHistory.length > 0) state.pendingBetHistory = [];
        if (state.pendingBets.length > 0) state.pendingBets = [];
        if (state.confirmedBets.length > 0) state.confirmedBets = [];
        state.waitingBetResult = false;
    }
};

const onCancelPendingBet = (state: GameState) => {
    if (state.pendingBetHistory.length > 0) state.pendingBetHistory = [];
    if (state.pendingBets.length > 0) state.pendingBets = [];
};

const onPopPendingBet = (state: GameState) => {
    if (state.pendingBetHistory.length > 0) {
        const pendingBets = state.pendingBetHistory.pop();

        // TODO: review logic
        if (pendingBets) {
            pendingBets.forEach(bet => {
                if (bet.Type >= 1000 && bet.ContainNum) {
                    const betTypes = bet.ContainNum;

                    betTypes.forEach(betType => {
                        const index = state.pendingBets.findIndex(
                            pb => pb.Type === betType
                        );

                        if (index > -1) {
                            state.pendingBets[index].Amount -= bet.Amount;

                            if (state.pendingBets[index].Amount === 0) {
                                state.pendingBets.splice(index, 1);
                            }
                        }
                    });
                } else {
                    const index = state.pendingBets.findIndex(
                        pb => pb.Type === bet.Type
                    );

                    if (index > -1) {
                        state.pendingBets[index].Amount -= bet.Amount;

                        if (state.pendingBets[index].Amount === 0) {
                            state.pendingBets.splice(index, 1);
                        }
                    }
                }
            });
        }
    }
};

const setNetworkState = (
    state: GameState,
    action: PayloadAction<{ online?: boolean; notToReconnect?: boolean }>
) => {
    const { online, notToReconnect } = action.payload;

    if (online != undefined) state.online = online;
    if (notToReconnect != undefined) state.notToReconnect = notToReconnect;
};
const setCurrentProxyID = (
    state: GameState,
    action: PayloadAction<{ proxyID: number }>
) => {
    const { proxyID } = action.payload;

    state.proxyID = proxyID;
};
const setInitBlock = (
    state: GameState,
    action: PayloadAction<{ block: boolean }>
) => {
    const { block } = action.payload;

    state.initBlock = block;
};
const setCurrentGame = (
    state: GameState,
    action: PayloadAction<{
        gameId: number;
        bets: Array<Bet>;
        withHold?: Array<Bet>;
    }>
) => {
    const { gameId, bets, withHold } = action.payload;

    if (state.currentGameId !== gameId) {
        state.confirmedBets = bets;
        state.betTypeWithHold = withHold;
        state.pendingBets = [];
        state.pendingBetHistory = [];
        state.currentBetType = -1;
        state.waitingBetResult = false;
    }
    state.currentGameId = gameId;
};
const setSelectedBetType = (
    state: GameState,
    action: PayloadAction<{ type: number }>
) => {
    const { type } = action.payload;
    state.currentBetType = type;
};
const resetSelectedBetType = (state: GameState) => {
    state.currentBetType = -1;
};
const onConfirmedBetResult = (
    state: GameState,
    action: PayloadAction<{
        gameId: number;
        bet: Array<Bet>;
    }>
) => {
    const { gameId } = action.payload;
    if (gameId == state.currentGameId) {
        state.prevBets = state.confirmedBets = action.payload.bet;

        // Reset pending bet
        state.pendingBets = [];
        state.pendingBetHistory = [];
        state.waitingBetResult = false;
    }
};
const setCurrentGameState = (
    state: GameState,
    action: PayloadAction<{ gameId: number; gameState: number }>
) => {
    const { gameId, gameState } = action.payload;

    if (state.currentGameId === gameId) {
        state.currentGameState = gameState;
        state.waitingBetResult = false;
    }
};
const setOnceEvent = (
    state: GameState,
    action: PayloadAction<{ gameId: number; event: string }>
) => {
    const { gameId, event } = action.payload;
    if (state.currentGameId === gameId) {
        state.onceEvent = event;
    }
};
const onDoubleBet = (
    state: GameState,
    action: PayloadAction<{ onClick: boolean }>
) => {
    const { onClick } = action.payload;
    state.doubleBet = onClick;
};
const onReBet = (
    state: GameState,
    action: PayloadAction<{ onClick: boolean }>
) => {
    const { onClick } = action.payload;
    state.reBet = onClick;
};
const resetAll = (state: GameState) => {
    state.pendingBetHistory = initialGameState.pendingBetHistory;
    state.pendingBets = initialGameState.pendingBets;
    state.confirmedBets = initialGameState.confirmedBets;
    state.prevBets = initialGameState.prevBets;
    state.doubleBet = initialGameState.doubleBet;
    state.reBet = initialGameState.reBet;
    state.currentBetType = initialGameState.currentBetType;
    state.currentGameId = initialGameState.currentGameId;
    state.currentGameState = initialGameState.currentGameState;
    state.betTypeWithHold = initialGameState.betTypeWithHold;
    state.onceEvent = initialGameState.onceEvent;
    state.waitingBetResult = initialGameState.waitingBetResult;
};
const setLastVideo = (state: GameState, action: PayloadAction<string>) => {
    state.lastVideo = action.payload;
};
const setVideoLibraryVersion = (
    state: GameState,
    action: PayloadAction<string>
) => {
    state.videoLibraryVersion = action.payload;
};
const setWaitingBetResult = (
    state: GameState,
    action: PayloadAction<boolean>
) => {
    state.waitingBetResult = action.payload;
};
export type GameState = {
    proxyID: number;
    pendingBets: Array<Bet>;
    pendingBetHistory: Array<Array<Bet>>;
    confirmedBets: Array<Bet>; //for single bet in-game rebet/double/total bet
    prevBets: Array<Bet>;
    doubleBet: boolean;
    reBet: boolean;
    currentBetType: number;
    currentGameId: number;
    currentGameState: number;
    online: boolean;
    notToReconnect: boolean;
    betTypeWithHold?: Array<Bet>;
    onceEvent: string;
    lastVideo: string;
    videoLibraryVersion: string;
    waitingBetResult: boolean;
    initBlock: boolean; //
};
const initialGameState = {
    proxyID: 0,
    pendingBets: [],
    pendingBetHistory: [],
    confirmedBets: [],
    prevBets: [],
    doubleBet: false,
    reBet: false,
    currentBetType: -1,
    currentGameId: -1,
    currentGameState: -1,
    online: false,
    notToReconnect: false,
    onceEvent: '',
    lastVideo: '',
    videoLibraryVersion: '',
    waitingBetResult: false,
    initBlock: false,
} as GameState;
export const GAME_KEY = 'app::game';
const gameSlice = createSlice({
    name: GAME_KEY,
    initialState: initialGameState,
    reducers: {
        onCancelPendingBet,
        onPendingBet,
        onPendingBetHistory,
        setSelectedBetType,
        resetSelectedBetType,
        onConfirmedBetResult,
        setNetworkState,
        setCurrentProxyID,
        setCurrentGame,
        setCurrentGameState,
        setOnceEvent,
        resetBets,
        onDoubleBet,
        onReBet,
        resetAll,
        onPopPendingBet,
        setLastVideo,
        setVideoLibraryVersion,
        setWaitingBetResult,
        setInitBlock,
    },
});
export const gameSliceReducer = gameSlice.reducer;
export const gameSliceActions = gameSlice.actions;
