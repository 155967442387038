import { createContext } from 'react';
import { useTableInfo, useTableInfoState } from '../hooks/useTableInfo';

export const TableInfoContext = createContext({} as useTableInfoState);
const { Provider } = TableInfoContext;
type TableInfoProviderOwnProps = {
    children: JSX.Element;
};
type TableInfoProviderProps = TableInfoProviderOwnProps;
export const TableInfoProvider = ({ children }: TableInfoProviderProps) => {
    const ti = useTableInfo();
    return <Provider value={ti}>{children}</Provider>;
};
