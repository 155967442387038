import { useContext } from 'react';
import BaseImage from '../../../../../components/common/baseImage';
import BaseButton from '../../../../../components/common/button/baseButton';
import { GameCategoryTab as GameCategoryTabEnum } from '../../../../../models/lobby/GameCategoryTab';
import { LobbyUiContext } from '../../../../../contexts/LobbyUiContext';
import './index.scss';


export const BaccaratButton = () => {
    const id = GameCategoryTabEnum.Baccarat;
    const {currentTab: activeId, isHall, changeIsHall} = useContext(LobbyUiContext);

    const pullDownImage =
        id === activeId
            ? 'lobby ic_lobby_pull_down_black'
            : 'lobby ic_lobby_pull_down';

    const onClick = () => {
        if (changeIsHall) {
            changeIsHall(!isHall);
        }
    };

    return (
        <>
            <BaseButton
                id={id}
                className="baccarat-button"
                imageName={'menu'}
                active={'ic_baccarat_hover_vert'}
                inActive={'ic_baccarat_vert'}
                activeId={activeId}
                onClick={onClick}
            />
            <div
                className={
                    isHall
                        ? 'pull-down-div pull-down-rotate180-div'
                        : 'pull-down-div'
                }
            >
                <BaseImage className={pullDownImage} />
            </div>
        </>
    );
};

export default BaccaratButton;
