import { BetType } from '../../models/games/roulette/BetType';
import { SupportedGameResult } from '../../modules/host/models';
import { RouletteRecord, RouletteResult } from '../../models/games/roulette';
import { isBitActive } from '../bitwiseUtil';
import {
    RouletteColor,
    RouletteBigSmall,
    RouletteOddEven,
} from '../../models/games/roulette/Record';
import { GameResult as GameResultEnum } from '../../models/games/roulette/GameResult';
import { LocalState } from '../../models/games/enums/LocalState';
import { GameState } from '../../models/games/enums/GameState';
import { GameState as RouletteGameState } from '../../models/games/roulette/GameState';

const getWinBetType = (result: number): Array<number> => {
    let arr = [];
    for (let i = 0; i < BetType.RBMaxBet; i++) {
        if (isBitActive(result, i)) {
            arr.push(i);
        }
    }
    return arr;
};
export const resultToColor = (resultVal: number, resultPoint: number) => {
    if (resultPoint === 0) {
        return RouletteColor.GREEN;
    } else if (isBitActive(resultVal, GameResultEnum.RRRed)) {
        return RouletteColor.RED;
    } else if (isBitActive(resultVal, GameResultEnum.RRBlack)) {
        return RouletteColor.BLACK;
    }
};

const resultToOddEven = (resultVal: number) => {
    if (isBitActive(resultVal, GameResultEnum.RROdd)) {
        return RouletteOddEven.ODD;
    } else if (isBitActive(resultVal, GameResultEnum.RREven)) {
        return RouletteOddEven.EVEN;
    }
};

const resultToBigSmall = (resultVal: number) => {
    if (isBitActive(resultVal, GameResultEnum.RR19To36)) {
        return RouletteBigSmall.BIG;
    } else if (isBitActive(resultVal, GameResultEnum.RR1To18)) {
        return RouletteBigSmall.SMALL;
    }
};

export const convertResultToRecord = (
    result: RouletteResult
): RouletteRecord => {
    const winBetList = getWinBetType(result.Result);
    const record: RouletteRecord = {
        gameID: result.GameID,
        result: result.Result,
        round: result.GameCount % 10000,
        shoes: Math.floor(result.GameCount),
        winBetType: winBetList,
        resultPoint: result.ResultPoint,
        color: resultToColor(result.Result, result.ResultPoint),
        oddEven: resultToOddEven(result.Result),
        bigSmall: resultToBigSmall(result.Result),
    };

    return record;
};

export const convertResultStringToRecord = (
    currentResult: SupportedGameResult,
    resultString: string,
    result?: number
): RouletteRecord => {
    const resultPoint = resultString.split(',').map(s => Number(s));
    const { FResult, GameID, Result, GameCount } = currentResult;
    const updateResult: RouletteResult = {
        FResult: FResult,
        GameCount: GameCount,
        GameID: GameID,
        Result: result ?? Result,
        ResultPoint: resultPoint[0],
    };

    const resultRecord = convertResultToRecord(updateResult);
    return resultRecord;
};

export const getLocalState = (
    isRest: boolean,
    state: number,
    isGameResultReleased: boolean
) => {
    if (isRest) {
        return LocalState.REST;
    }

    switch (state) {
        case GameState.RoundCancel:
            return LocalState.ROUND_CANCEL;
        case GameState.ReStart:
            return GameState.ReStart;
        case GameState.Idle:
        case GameState.Shuffle:
            return LocalState.IDLE;
        case GameState.Betting:
            return LocalState.BETTING;
        case GameState.Started:
        case RouletteGameState.WaitResult:
            return LocalState.DEALING;
        default:
            if (
                state >= RouletteGameState.Zero &&
                state <= RouletteGameState.ThirtySix
            ) {
                return LocalState.RESULT;
            }
            break;
    }

    if (isGameResultReleased) {
        return LocalState.RESULT;
    }

    return LocalState.IDLE;
};
