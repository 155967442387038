import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import {
    getHostByGameId,
    hostsSliceActions,
} from '../../../modules/host/slice';
import store from '../../../store/store';
import { GameState } from '../../games/enums/GameState';
import { CMDScStartGame, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScStartGameHandler extends AbstractHandler {
    _command: CMDScStartGame;

    constructor(command: ICommand) {
        super();
        this._command = command as CMDScStartGame;
    }

    override handle(dispatch: Dispatch<AnyAction>): void {
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);
        if (host) {
            console.log(`hook::socket::run::${host.HostId}`, this._command);
            batch(() => {
                dispatch(
                    hostsSliceActions.updateGameState({
                        hostId: host.HostId,
                        state: GameState.Started,
                    })
                );
            });
        } else {
            console.warn(
                'hook::socket::warning::cannot::find::host',
                this._command.GameID
            );
        }
    }
}
