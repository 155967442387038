import { Box, Button, Stack, Typography } from "@mui/material"
import BaseImage from "../../../../components/common/baseImage"
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { promotionToolSliceActions } from "../../../main/slice/promotiontool/promotionTool";
import { AudioPlayContext } from "../../../../contexts/AudioPlayContext";
import { RootState } from "../../../../store/store";
import { getPromotionToolState } from "../../../main/selector";
import { calcLowHigh, numberFormat } from "../../../../utils/commonFunc";
import { PromotionBubble } from "../promotionBubble";
import { PromotionPage } from "../../../popup/promotiontool/components/PromotionDialog";

export const PromotionIndicator = () => {
    const dispatch = useDispatch();
    const { playButtonAudio } = useContext(AudioPlayContext);
    const { Point } = useSelector((state: RootState) => getPromotionToolState(state));
    const [eventPoint, setEventPoint] = useState<string>(numberFormat(0, 2));
    const fixedHeight = 20;

    useEffect(() => {
        setEventPoint(numberFormat(calcLowHigh(Point) / 100, 2));
    }, [Point])

    return (
        <>
            <Button sx={{ position:'relative', padding:'0' }} onClick={() => {
                dispatch(promotionToolSliceActions.updatePage(PromotionPage.Gacha));
                dispatch(promotionToolSliceActions.isOpenDialog(true));
                playButtonAudio();
            }}>
                <Box sx={{ minHeight:`${fixedHeight}px`, maxHeight:`${fixedHeight}px`, backgroundColor:'rgba(50, 177, 162, 0.3)', borderRadius:'2px', padding:'0 5px 0 5px', marginRight:'2.5px' }}>
                    <Stack direction={'row'} spacing={0.8}>
                        <Box sx={{ minHeight:`${fixedHeight}px`, maxHeight:`${fixedHeight}px`, display:'flex', alignItems:'center' }}>
                            <BaseImage className='promotion_tool event_gem' scale={1} />
                        </Box>
                        <Box sx={{ minHeight:`${fixedHeight}px`, maxHeight:`${fixedHeight}px`, display:'flex', alignItems:'center' }}>
                            <Typography fontSize={18} sx={{ color:'#C3A475', marginTop:'3px' }}>{eventPoint}</Typography>
                        </Box>
                    </Stack>
                </Box>
                <PromotionBubble direction="left" spacing={5}/>
            </Button>
        </>
    )
}