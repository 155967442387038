export const PopupCloseIcon = () => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g opacity="1">
            <path
                d="M8 8L20 20M20 8L8 20"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="14"
                cy="14"
                r="13.35"
                stroke="white"
                strokeWidth="1.3"
            />
        </g>
    </svg>
);
