// import { BitArray } from '../../models/cmd/BitArray';
import { ByteArray } from '../../models/cmd';
import { BitArray } from '../../models/cmd/BitArray';
import { GameState } from '../../models/games/enums/GameState';
import { LocalState } from '../../models/games/enums/LocalState';
import { SicBoRecord, SicBoResult } from '../../models/games/sicbo';
import { GameState as SicBoState } from '../../models/games/sicbo/GameState';
import { SupportedGameResult } from '../../modules/host/models';

export const convertResultToRecord = (result: SicBoResult): SicBoRecord => {
    const [d1, d2, d3] = [result.Dice1, result.Dice2, result.Dice3].sort(
        (a, b) => a - b
    );
    const record: SicBoRecord = {
        gameID: result.GameID,
        result: result.Result,
        shoes: Math.floor(result.GameCount),
        round: result.GameCount % 10000,
        resultPoint: d1 + d2 + d3,
        Dice1: d1,
        Dice2: d2,
        Dice3: d3,
        SRBigSmall: result.SRBigSmall, // 0 = None, 1 = Big, 2 = Small
        SROddEven: result.SROddEven, // 0 = None, 1 = Odd, 2 = Even
        SRTripleArmyOne: result.SRTripleArmyOne,
        SRTripleArmyTwo: result.SRTripleArmyTwo,
        SRTripleArmyThree: result.SRTripleArmyThree,
        SRTripleArmyFour: result.SRTripleArmyFour,
        SRTripleArmyFive: result.SRTripleArmyFive,
        SRTripleArmySix: result.SRTripleArmySix,
        SRTriple: result.SRTriple, // 0 = None, 1 - 6
        SRAllTriple: result.SRAllTriple, // Must True When SRTriple Not Zero
        SRPoint: result.SRPoint, // 0 = None, 4 - 17
        SRLongOneTwo: result.SRLongOneTwo,
        SRLongOneThree: result.SRLongOneThree,
        SRLongOneFour: result.SRLongOneFour,
        SRLongOneFive: result.SRLongOneFive,
        SRLongOneSix: result.SRLongOneSix,
        SRLongTwoThree: result.SRLongTwoThree,
        SRLongTwoFour: result.SRLongTwoFour,
        SRLongTwoFive: result.SRLongTwoFive,
        SRLongTwoSix: result.SRLongTwoSix,
        SRLongThreeFour: result.SRLongThreeFour,
        SRLongThreeFive: result.SRLongThreeFive,
        SRLongThreeSix: result.SRLongThreeSix,
        SRLongFourFive: result.SRLongFourSix,
        SRLongFourSix: result.SRLongFourSix,
        SRLongFiveSix: result.SRLongFiveSix,
        SRShort: result.SRShort, // 0 = None, 1 - 6
        SROddEvenCombination: result.SROddEvenCombination, // 0 = AllDicesOdd, 1 = TwoDicesOddOneDiceEven, 2 = TwoDicesEvenOneDiceOdd, 3 = AllDicesEven
        SR_1_2_3_4: result.SR_1_2_3_4,
        SR_2_3_4_5: result.SR_2_3_4_5,
        SR_2_3_5_6: result.SR_2_3_5_6,
        SR_3_4_5_6: result.SR_3_4_5_6,
        SRCombination: result.SRCombination,
    };

    return record;
};

export const convertResultStringToRecord = (
    currentResult: SupportedGameResult,
    resultString: string,
    FResult: bigint,
    result?: number
): SicBoRecord => {
    const arr = resultString.split(',');
    const d1 = parseInt(arr[0]);
    const d2 = parseInt(arr[1]);
    const d3 = parseInt(arr[2]);
    const { GameID, Result, GameCount } = currentResult;
    const updateResult: SicBoResult = Object.assign(
        {
            Dice1: d1,
            Dice2: d2,
            Dice3: d3,
            FResult: FResult.toString(),
            GameCount: GameCount,
            GameID: GameID,
            Result: result ?? Result,
        },
        getResultForFResult(BigInt(FResult))
    );
    const resultRecord = convertResultToRecord(updateResult);
    return resultRecord;
};

export const getResultForFResult = (FResult: bigint) => {
    //SA -> SicBoResult.ts
    const ba: ByteArray = new ByteArray();
    ba.writeUint64(FResult);
    const bitArray = new BitArray(ba);
    return {
        SRBigSmall: bitArray.readNumber(2), // 0 = None, 1 = Big, 2 = Small
        SROddEven: bitArray.readNumber(2), // 0 = None, 1 = Odd, 2 = Even
        SRTripleArmyOne: bitArray.readBoolean(),
        SRTripleArmyTwo: bitArray.readBoolean(),
        SRTripleArmyThree: bitArray.readBoolean(),
        SRTripleArmyFour: bitArray.readBoolean(),
        SRTripleArmyFive: bitArray.readBoolean(),
        SRTripleArmySix: bitArray.readBoolean(),
        SRTriple: bitArray.readNumber(3), // 0 = None, 1 - 6
        SRAllTriple: bitArray.readBoolean(), // Must True When SRTriple Not Zero
        SRPoint: bitArray.readNumber(5), // 0 = None, 4 - 17
        SRLongOneTwo: bitArray.readBoolean(),
        SRLongOneThree: bitArray.readBoolean(),
        SRLongOneFour: bitArray.readBoolean(),
        SRLongOneFive: bitArray.readBoolean(),
        SRLongOneSix: bitArray.readBoolean(),
        SRLongTwoThree: bitArray.readBoolean(),
        SRLongTwoFour: bitArray.readBoolean(),
        SRLongTwoFive: bitArray.readBoolean(),
        SRLongTwoSix: bitArray.readBoolean(),
        SRLongThreeFour: bitArray.readBoolean(),
        SRLongThreeFive: bitArray.readBoolean(),
        SRLongThreeSix: bitArray.readBoolean(),
        SRLongFourFive: bitArray.readBoolean(),
        SRLongFourSix: bitArray.readBoolean(),
        SRLongFiveSix: bitArray.readBoolean(),
        SRShort: bitArray.readNumber(3), // 0 = None, 1 - 6
        SROddEvenCombination: bitArray.readNumber(2), // 0 = AllDicesOdd, 1 = TwoDicesOddOneDiceEven, 2 = TwoDicesEvenOneDiceOdd, 3 = AllDicesEven
        SR_1_2_3_4: bitArray.readBoolean(),
        SR_2_3_4_5: bitArray.readBoolean(),
        SR_2_3_5_6: bitArray.readBoolean(),
        SR_3_4_5_6: bitArray.readBoolean(),
        SRCombination: bitArray.readNumber(6),
    };
};

export const getLocalState = (
    isRest: boolean,
    state: number,
    isGameResultReleased: boolean
) => {
    if (isRest) {
        return LocalState.REST;
    }
    switch (state) {
        case GameState.RoundCancel:
            return LocalState.ROUND_CANCEL;
        case GameState.ReStart:
            return GameState.ReStart;
        case GameState.Idle:
        case GameState.Shuffle:
            return LocalState.IDLE;
        // return LocalState.SHUFFLING;
        case GameState.Betting:
            return LocalState.BETTING;
        case GameState.Started:
        case SicBoState.WaitResult:
            return LocalState.DEALING;
        case SicBoState.Small:
        case SicBoState.Big:
            return LocalState.RESULT;
    }
    if (isGameResultReleased) {
        return LocalState.RESULT;
    }

    return LocalState.IDLE;
};
