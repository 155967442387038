import { useSelector, useDispatch, batch } from 'react-redux';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VideoPlayerContext } from '../../../../../../contexts/VideoContext';
import { RootState } from '../../../../../../store/store';
import { getHostById } from '../../../../../host/slice';
import { ChipList } from '../../Baccarat/ChipList';
import { ChipSelector } from '../../Baccarat/ChipSelector';
import { DoubleBetButton } from '../../Baccarat/DoubleBetButton';
import { AndarBaharBetArea } from './AndarBaharBetArea';
import { ReBetButton } from '../../Baccarat/ReBetButton';
import {
    getCurrentGameId,
    getGameSate,
    getTotalPendingBetsAmount,
    isReBetOn,
} from '../../../../../games/selector';
import { GameState as GameStateEnum } from '../../../../../../models/games/enums/GameState';
import { AndarBaharNCSwitch } from './AndarBaharNCSwitch';
import { AndarBaharNoCommBetArea } from './AndarBaharNoCommBetArea';
import { AudioPlayContext } from '../../../../../../contexts/AudioPlayContext';
import { Bet } from '../../../../../../models/host/BetAmount';
import { BetType } from '../../../../../../models/games/andarBahar/BetType';
import { gameSliceActions } from '../../../../../games/slice';
import { MobPref } from '../../../../../../hooks/storage/useLocalStorage';
import { checkBetsAvailed } from '../../../../../../utils/commonFunc';
import { amountOverBetLimit } from '../utils';
import { inGamePopupSliceActions } from '../../../../../popup/inGameSlice';
import { StateMsgType } from '../../../../../../models/Popup';
import { useBalance } from '../../../../../../hooks/useBalance';

export const getTableBetAreaColor = (k: string | undefined): string => {
    switch (k) {
        case 'd':
            return '122,35,20'; //#7a2314
        case 'p':
            return '121,79,177'; //794fb1
        case 'e':
        case 'm':
            return '3,66,31'; //03421f
        case 'a':
            return '100,62,64'; //643E40
        case 'c':
            return '53,68,87'; //354457
        default:
            return '27,57,27'; //1b391b
    }
};

const getTabIndex = (betType: number): number => {
    let tabIndex: number = -1;

    const tabs = [
        [
            BetType.ABBAndarWin,
            BetType.ABBBaharWin,
            BetType.ABB1To5,
            BetType.ABB6To10,
            BetType.ABB11To15,
            BetType.ABB16To20,
            BetType.ABB21To25,
            BetType.ABB26To30,
            BetType.ABB31To35,
            BetType.ABB41To45,
            BetType.ABB46To49,
            BetType.ABBFirst3,
            BetType.ABBFirstAndar,
            BetType.ABBFirstBahar,
        ],
        [
            BetType.ABBNCAndarWin,
            BetType.ABBNCBaharWin,
            BetType.ABBNC1To5,
            BetType.ABBNC6To10,
            BetType.ABBNC11To15,
            BetType.ABBNC16To20,
            BetType.ABBNC21To25,
            BetType.ABBNC26To30,
            BetType.ABBNC31To35,
            BetType.ABBNC36To40,
            BetType.ABBNC41To45,
            BetType.ABBNC46To49,
        ],
    ];

    tabs.forEach((tab, idx) => {
        if (tab.indexOf(betType) > -1) {
            tabIndex = idx;
        }
    });

    for (let i = 0; i < tabs.length; i++) {
        if (tabs[i].indexOf(betType) > -1) {
            return i;
        }
    }

    return tabIndex;
};

export const BetAreaPanel = () => {
    const { t } = useTranslation();
    const { hostId } = useContext(VideoPlayerContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const [isShowReBet, setIsShowReBet] = useState<boolean>(false);
    const { IsRest, CurrentState, ResultReleased, Group, GameType } = useSelector((state: RootState) => getHostById(state, hostId));
    const reBetButtonEnable = useSelector((state: RootState) => isReBetOn(state));

    const addingBet = useRef(false);
    const [toAddBets, setToAddBets] = useState<Array<Bet>>();
    const { availableBalance } = useBalance();
    const allPendingBetsAmount = useSelector((state: RootState) =>
        getTotalPendingBetsAmount(state)
    );
    const currentGameId = useSelector((state: RootState) =>
        getCurrentGameId(state)
    );

    const [doubleBets, setDoubleBets] = useState<Array<Bet>>([]);
    const { doubleBet, reBet, prevBets, pendingBets, confirmedBets } = useSelector(getGameSate);

    useEffect(() => {
        const bets = new Array<Bet>();
        for (const cb of confirmedBets) {
            bets.push({ Type: cb.Type, Amount: cb.Amount, GameId: cb.GameId });
        }
        for (const pb of pendingBets) {
            const index = bets.findIndex(cb => cb.Type === pb.Type);
            if (index === -1) bets.push(pb);
            else bets[index].Amount += pb.Amount;
        }
        setDoubleBets(bets);
    }, [pendingBets, confirmedBets]);

    const [ncSelected, setNcSelect] = useState(
        Boolean(
            Number(
                localStorage.getItem(`MobPref_${MobPref.ANDAR_BAHAR_BET_MODE}`)
            )
        )
    );

    const handleSwitchToggle = () => {
        playButtonAudio();
        setNcSelect(!ncSelected);
    };
    const ButtonNc = () => {
        return (
            <Stack
                sx={{
                    marginTop: '12px',
                    marginLeft: 'auto',
                    marginRight: '110px',
                }}
                direction="row"
                spacing={1}
                alignItems="center"
            >
                <Typography>{t('baccarat.super_six_short')}</Typography>
                <AndarBaharNCSwitch
                    checked={ncSelected}
                    onChange={handleSwitchToggle}
                    inputProps={{ 'aria-label': 'ant design' }}
                />
            </Stack>
        );
    };
    const dispatch = useDispatch();

    const doPreBet = (bets: Array<Bet>) => {
        batch(() => {
            bets.forEach(bet => {
                dispatch(
                    gameSliceActions.onPendingBet({
                        GameId: currentGameId,
                        Type: bet.Type,
                        Amount: bet.Amount,
                    })
                );
            });
        });
    };

    const [tableColor, setTableColor] = useState<string>('');
    const [isBetting, setIsBetting] = useState<boolean>(false);

    useEffect(() => {
        setIsBetting(IsRest ? false : CurrentState === GameStateEnum.Betting);
    }, [IsRest, CurrentState, ResultReleased]);

    useEffect(() => {
        setIsShowReBet(isBetting ? reBetButtonEnable : false);
    }, [reBetButtonEnable, isBetting]);

    useEffect(() => {
        if (doubleBet) {
            if (doubleBets && !toAddBets && !addingBet.current) {
                addingBet.current = true;
                setToAddBets(doubleBets);
            }
            dispatch(gameSliceActions.onDoubleBet({ onClick: false }));
        }
    }, [doubleBet, doubleBets]);
    useEffect(() => {
        if (reBet) {
            if (prevBets && !toAddBets && !addingBet.current) {
                addingBet.current = true;
                setToAddBets(prevBets);
            }
            dispatch(gameSliceActions.onReBet({ onClick: false }));
        }
    }, [reBet, prevBets]);
    useEffect(() => {
        if (toAddBets) {
            const availableBets = checkBetsAvailed({
                hostId: hostId,
                bets: toAddBets,
                amountOverBetLimit: amountOverBetLimit,
                totalPendingBetAmount: allPendingBetsAmount,
                totalPendingWithHoldAmount: 0,
                availableBalance: availableBalance,
            });
            if (availableBets.isNotEnoughMoney) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'system.not_enough_money',
                        GameType,
                        StateMsgType.betInfo_Fail,
                        currentGameId
                    )
                );
            } else if (availableBets.isOutOfBetLimit) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'system.bet_out_of_limit_red',
                        GameType,
                        StateMsgType.betInfo_Fail,
                        currentGameId
                    )
                );
            } else {
                const toBets = availableBets.bets;
                const betTypes = toBets.map((bet: Bet) => ({
                    type: bet.Type,
                }));
                const lastBetType: number = betTypes.length - 1;
                const destTab = Boolean(
                    getTabIndex(betTypes[lastBetType].type)
                );

                let delay = 0;
                if (ncSelected != destTab) {
                    setNcSelect(destTab);
                    delay = 800;
                }

                if (delay > 0) {
                    setTimeout(() => doPreBet(toBets), delay);
                } else {
                    doPreBet(toBets);
                } // TODO: any alternatives to using setTimeout?

                if (availableBets.haveDisabledBetType) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.have_cannot_rebet_zone',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                } else if (availableBets.haveBetLimitAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.bet_exceed_limit_allin',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                } else if (availableBets.isAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.all_in',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                }
                dispatch(
                    gameSliceActions.setSelectedBetType(betTypes[lastBetType])
                );
            }
            setToAddBets(undefined);
            addingBet.current = false;
        }
    }, [toAddBets]);

    useEffect(() => {
        localStorage.setItem(
            'MobPref_' + MobPref.ANDAR_BAHAR_BET_MODE,
            JSON.stringify(Number(ncSelected))
        );
    }, [ncSelected, reBet]);

    useEffect(() => {
        if (Group) {
            setTableColor(getTableBetAreaColor(Group[0]));
        }
    }, [Group]);

    return (
        <>
            <Stack
                sx={{
                    marginTop: '298px', //50+300-22//52=header,300=video,22=betPage
                    width: '540px',
                    height: '390px',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        width: '540px',
                        height: '100%',
                        zIndex: -1,
                        background: `rgba(${tableColor})`,
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        width: '540px',
                        height: '100%',
                        //SA SceneTradBacc -> getTableColorLayer -> [0,60,255]   23.5% = (60 / 255 * 100)
                        background: `linear-gradient(to bottom, rgba(${tableColor},0) 0%, rgba(${tableColor},1) 23.5%, rgba(${tableColor},1)) 100%`,
                        zIndex: -3,
                    }}
                />
                {(Group[0] === 'c' || Group[0] === 'p') && (
                    <Box
                        sx={{
                            position: 'absolute',
                            width: '540px',
                            height: '335px',
                            background: `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.32) 23.5%, rgba(0,0,0,0.8)) 100%`,
                            zIndex: -3,
                        }}
                    />
                )}
                {ncSelected ? (
                    <AndarBaharNoCommBetArea />
                ) : (
                    <AndarBaharBetArea />
                )}
                <Box sx={{ zIndex: 1 }}>
                    <Grid
                        item
                        container
                        sx={{
                            paddingTop: '4px',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            marginBottom: '1px',
                        }}
                    >
                        <ChipSelector />
                        <ChipList />
                        {isShowReBet ? <ReBetButton /> : <DoubleBetButton />}
                    </Grid>
                </Box>
                <ButtonNc />
            </Stack>
        </>
    );
};
