import { Close as CloseIcon } from '@mui/icons-material';
import { AppBar, Dialog, IconButton, Toolbar, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../theme';

type CloseHandler = () => void;
type SettingDialogProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    title: string;
    icon: JSX.Element;
    children: JSX.Element;
    onClose?: CloseHandler;
};
export const SettingDialog = (props: SettingDialogProps) => {
    const { isOpen, setIsOpen, title, icon, children, onClose } = props;
    const { t } = useTranslation();

    return (
        <Dialog
            fullScreen
            open={isOpen}
            onClose={() => { setIsOpen(false); onClose && onClose(); }}
            className={`SettingDialog ${title}Dialog`}
            disableEscapeKeyDown
        >
            <AppBar
                position="static"
                elevation={0}
                sx={{ backgroundColor: 'primary.main' }}
            >
                <Toolbar>
                    {icon}
                    <Typography variant="h5" component="div">
                        {t(title)}
                    </Typography>
                    <IconButton
                        aria-label="close setting"
                        onClick={() => { setIsOpen(false); onClose && onClose(); }}
                        sx={{ ml: 'auto' }}
                    >
                        <CloseIcon
                            sx={{ color: theme.palette.text.primary, width: 21, height: 21 }}
                        />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {children}
        </Dialog>
    );
};

export default SettingDialog;
