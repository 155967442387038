import { GAME_KEY } from '../../../../games/slice';
import { PHOST_KEY } from '../../../../host/slice';
import { Bet } from '../../../../../models/host/BetAmount';

import store from '../../../../../store/store';
import { BetType } from '../../../../../models/games/andarBahar/BetType';

const calculateBetAmount = (
    bets: Array<Bet>,
    matchBetTypes: Array<number>
): number => {
    let amount = 0;
    bets.forEach(bet => {
        if (matchBetTypes.indexOf(bet.Type) > -1) {
            amount += bet.Amount;
        }
    });
    return amount;
};

/**
 * Bet limit sharing
 */
const getMatchBetTypes = (type:BetType): BetType[] => {
    switch (type) {
        case BetType.ABBAndarWin:
        case BetType.ABBNCAndarWin:
            return [BetType.ABBAndarWin, BetType.ABBNCAndarWin];
        case BetType.ABBBaharWin:
        case BetType.ABBNCBaharWin:
            return [BetType.ABBBaharWin, BetType.ABBNCBaharWin];
        case BetType.ABB1To5:
        case BetType.ABBNC1To5:
            return [BetType.ABB1To5, BetType.ABBNC1To5];
        case BetType.ABB6To10:
        case BetType.ABBNC6To10:
            return [BetType.ABB6To10, BetType.ABBNC6To10];
        case BetType.ABB11To15:
        case BetType.ABBNC11To15:
            return [BetType.ABB11To15, BetType.ABBNC11To15];
        case BetType.ABB16To20:
        case BetType.ABBNC16To20:
            return [BetType.ABB16To20, BetType.ABBNC16To20];
        case BetType.ABB21To25:
        case BetType.ABBNC21To25:
            return [BetType.ABB21To25, BetType.ABBNC21To25];
        case BetType.ABB26To30:
        case BetType.ABBNC26To30:
            return [BetType.ABB26To30, BetType.ABBNC26To30];
        case BetType.ABB31To35:
        case BetType.ABBNC31To35:
            return [BetType.ABB31To35, BetType.ABBNC31To35];
        case BetType.ABB36To40:
        case BetType.ABBNC36To40:
            return [BetType.ABB36To40, BetType.ABBNC36To40];
        case BetType.ABB41To45:
        case BetType.ABBNC41To45:
            return [BetType.ABB41To45, BetType.ABBNC41To45];
        case BetType.ABB46To49:
        case BetType.ABBNC46To49:
            return [BetType.ABB46To49, BetType.ABBNC46To49];
        default:
            return [type];
    }
}

const getPendingBet = (betType: BetType): number => {
    const { pendingBets } = store.getState()[GAME_KEY];
    return calculateBetAmount(pendingBets, getMatchBetTypes(betType));
};

const getConfirmedBetted = (betType: BetType): number => {
    const { confirmedBets } = store.getState()[GAME_KEY];
    return calculateBetAmount(confirmedBets, getMatchBetTypes(betType));
};

const getTotalBet = (betType: BetType): number => {
    return getPendingBet(betType) + getConfirmedBetted(betType);
};

export const isOppositeBetting = (betType: number): boolean => {
    switch (betType) {
        case BetType.ABBAndarWin:
        case BetType.ABBNCAndarWin:
            return (getTotalBet(BetType.ABBBaharWin) + getTotalBet(BetType.ABBNCBaharWin)) > 0;
        case BetType.ABBBaharWin:
        case BetType.ABBNCBaharWin:
            return (getTotalBet(BetType.ABBAndarWin) + getTotalBet(BetType.ABBNCAndarWin)) > 0;
        default:
            return false;
    }
};

const getTotalBetByBetTypes = (betTypes: Array<number>): number => {
    let amount = 0;
    const { pendingBets } = store.getState()[GAME_KEY];
    pendingBets.forEach(bet => {
        if (betTypes.indexOf(bet.Type) > -1) {
            amount += bet.Amount;
        }
    });

    const { confirmedBets } = store.getState()[GAME_KEY];
    confirmedBets.forEach(bet => {
        if (betTypes.indexOf(bet.Type) > -1) {
            amount += bet.Amount;
        }
    });

    return amount;
};

const getBetLimitByBetType = (betType: number, hostId: number): number => {
    const host = store.getState()[PHOST_KEY].entities[hostId];
    let limit = 0;

    if (host && host.MaxBet) {
        const maxBet = host.MaxBet.find(mb => mb.BetType === betType);
        if (maxBet) {
            return maxBet.MaxBet;
        }
    }

    // TODO: user.getCurBetRule(this.gametype).minBet;
    return limit;
};

export const amountOverBetLimit = (
    betType: number,
    hostId: number,
    chipAmount: number
): number => {
    let amount = 0;
    amount = chipAmount + getTotalBetByBetTypes(getMatchBetTypes(betType)) - getBetLimitByBetType(betType, hostId);
    return amount;
};