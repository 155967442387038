import { Grid, Typography } from '@mui/material';
import { memo } from 'react';
type PointCellProps = {
    point?: number;
};
const PointCell = ({ point }: PointCellProps) => {
    let pointDisplay: string = '';
    let isBust = false;
    if (point) {
        pointDisplay = point.toString();
        if (point === 255) pointDisplay = 'BJ';
        else if (point > 21) {
            pointDisplay = 'B';
            isBust = true;
        }
    }
    return (
        <Grid
            item
            className={`blackjack-point-cell ${
                isBust && 'blackjack-result-bust'
            }`}
        >
            <Typography textAlign={'center'}>{pointDisplay}</Typography>
        </Grid>
    );
};
export default memo(PointCell);
