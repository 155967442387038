import { useState } from 'react';

export type TableInfoKeyType =
    | 'roadmap'
    | 'tableInfo'
    | 'markerRoad'
    | 'winning';
export const useTableInfo = () => {
    const [tableInfo, setTableInfo] = useState<TableInfoKeyType>('roadmap');
    return { tableInfo, setTableInfo };
};
export type useTableInfoState = ReturnType<typeof useTableInfo>;
