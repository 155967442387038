import { Dialog, Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LoadingDot } from '../../components/images/loading/LoadingDot';
import { WebSocketContext } from '../../contexts/WebSocketContext';
import { useNavigatorOnLine } from '../../hooks/useNavigatorOnLine';
import { ROUTE_LOBBY, ROUTE_ROOT, ROUTE_THANKS4PLAY } from '../../models/Route';
import { getGameSate } from '../games/selector';
import { routeSliceActions } from '../routeController/slice';

export const ReconnectLayer = () => {
    const [isOpen, setIsOpen] = useState(false);
    const onlineState = useNavigatorOnLine();
    const dispatch = useDispatch();
    const {
        hasLogin: { current },
    } = useContext(WebSocketContext);

    const location = useLocation();
    const { online: socketOnline, notToReconnect } = useSelector(getGameSate);
    useEffect(() => {
        if (notToReconnect) {
            setIsOpen(false);
        } else {
            console.log('app::network::', socketOnline, onlineState);
            setIsOpen(
                (!socketOnline || !onlineState) &&
                    location.pathname != ROUTE_ROOT &&
                    location.pathname != ROUTE_THANKS4PLAY &&
                    current == true
            );
        }
    }, [socketOnline, notToReconnect, onlineState]);

    useEffect(() => {
        if (
            !isOpen &&
            location.pathname !== ROUTE_LOBBY &&
            location.pathname != ROUTE_ROOT
        ) {
            dispatch(routeSliceActions.goto(ROUTE_LOBBY));
        }
    }, [isOpen]);
    return (
        <Dialog
            fullScreen
            open={isOpen}
            disableEscapeKeyDown
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }}
        >
            <Stack
                alignItems={'center'}
                justifyContent={'center'}
                width={'100%'}
                height={'100%'}
            >
                {!notToReconnect && <LoadingDot width={120} />}
            </Stack>
        </Dialog>
    );
};

export default ReconnectLayer;
