export enum LocalState {
    IDLE,
    REST,
    SHUFFLING,
    BETTING,
    DEALING,
    RESULT,
    TIE,
    PLAYER,
    BANKER,
    PLAYER_A,
    PLAYER_B,
    ROUND_CANCEL,
}
