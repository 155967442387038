import { Box, Grid, Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseImage from '../../../../../components/common/baseImage';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import { RootState } from '../../../../../store/store';
import { getHostById } from '../../../../host/slice';
import { REPORT_COLORS } from '../../../../lobby/constants';
import './SedieWinStatisticsPanel.scss';
import { SedieStatistics } from '../../../../../models/games/sedie';

type WinStatisticsProps = {
    title: string;
    imageData?: {
        image: string;
        scale?: number;
        isMultiLang?: boolean;
    }
    value: string | number;
};
type WinStatisticsTypeRow1 = {
    odd: WinStatisticsProps;
    even: WinStatisticsProps;
    small: WinStatisticsProps;
    big: WinStatisticsProps;
};
type WinStatisticsTypeRow2 = {
    fourWhite: WinStatisticsProps;
    oneRed: WinStatisticsProps;
    twoRed: WinStatisticsProps;
    threeRed: WinStatisticsProps;
    FourRed: WinStatisticsProps;
    FourRedOrFourRed: WinStatisticsProps;
};
type WinStatisticsType = [WinStatisticsTypeRow1, WinStatisticsTypeRow2];
const initWinStatisticsData = [
    {
        odd: {
            title: 'tableinfo.table',
            value: '',
        },
        even: {
            title: 'tableinfo.dealer',
            value: '',
        },
        small: {
            title: 'tableinfo.shoe',
            value: '',
        },
        big: {
            title: 'tableinfo.round',
            value: '',
        },
    },
    {
        fourWhite: {
            imageData: {
                image: 'sedie_sheet sd_4w',
                scale: 0.25
            },
            value: '',
        },
        oneRed: {
            imageData: {
                image: 'sedie_sheet sd_3w1r',
                scale: 0.25
            },
            value: '',
        },
        twoRed: {
            imageData: {
                image: 'sedie_sheet sd_2w2r',
                scale: 0.25
            },
            value: '',
        },
        threeRed: {
            imageData: {
                image: 'sedie_sheet sd_1w3r',
                scale: 0.25
            },
            title: '',
            value: '',
        },
        FourRed: {
            imageData: {
                image: 'sedie_sheet sd_4r',
                scale: 0.25
            },
            title: '',
            value: '',
        },
        FourRedOrFourRed: {
            imageData: {
                image: 'sedie_sheet sd_4w4r',
                scale: 0.25
            },
            title: '',
            value: '',
        },
    }
] as WinStatisticsType;
export const SedieStatisticsTab = () => {
    const { t } = useTranslation();
    const { hostId } = useContext(VideoPlayerContext);
    const host = useSelector((state: RootState) => getHostById(state, hostId));

    const [winStatistics, setWinStatistics] =
        useState<WinStatisticsType>(initWinStatisticsData);

    useEffect(() => {
        if (host.Statistics) {
            const statistics = host.Statistics as SedieStatistics;
            const changeToRate = (value: number) => Math.round(value * 100);
            const bigSmallTotalCount = statistics.BigCount + statistics.SmallCount + statistics.TwoRedTwoWhiteCount;
            const evenOddTotalCount = statistics.OddCount + statistics.EvenCount;

            let newWinStatistics = Object.create(winStatistics);
            newWinStatistics[0].odd.value =
                evenOddTotalCount == 0
                    ? '0%'
                    : changeToRate(statistics.OddCount / evenOddTotalCount) +
                      '%';
            newWinStatistics[0].even.value =
                evenOddTotalCount == 0
                    ? '0%'
                    : changeToRate(statistics.EvenCount / evenOddTotalCount) +
                      '%';
            newWinStatistics[0].small.value =
                bigSmallTotalCount == 0
                    ? '0%'
                    : changeToRate(statistics.SmallCount / bigSmallTotalCount) +
                      '%';
            newWinStatistics[0].big.value =
                bigSmallTotalCount == 0
                    ? '0%'
                    : changeToRate(statistics.BigCount / bigSmallTotalCount) +
                      '%';

            newWinStatistics[1].fourWhite.value = statistics.FourWhiteCount;
            newWinStatistics[1].oneRed.value = statistics.ThreeWhiteCount;
            newWinStatistics[1].twoRed.value = statistics.TwoRedTwoWhiteCount;
            newWinStatistics[1].threeRed.value = statistics.ThreeRedCount;
            newWinStatistics[1].FourRed.value = statistics.FourRedCount;
            newWinStatistics[1].FourRedOrFourRed.value =
                statistics.FourRedOrWhiteCount;
            setWinStatistics(newWinStatistics);
        }
    }, [host.Statistics]);

    const style = (firstGrid: boolean) =>
        firstGrid ? { width: '50%', zIndex: '1' }
            : {
                width: '49.5%',
                zIndex: '1',
                borderLeft:
                    '2px solid rgba(255,255,255,0.2)',
            };

    const leftPanel = () => {
        return (winStatistics && winStatistics.length > 1) && (
            <>
                <Grid
                    container
                    direction='column'
                    className='sedie-statistics-left'
                >
                    <Grid
                        className='sedie-odd-even-grp'
                    >
                        <Grid
                            container
                            width={winStatistics[0].odd.value}
                            direction='column'
                            className='sedie-odd-small-item'
                        >
                            <Grid item>{t('sedie.s_odd')}</Grid>
                            <Grid item>{winStatistics[0].odd.value}</Grid>
                        </Grid>
                        <Grid
                            container
                            width={winStatistics[0].even.value}
                            direction='column'
                            className='sedie-even-big-item'
                        >
                            <Grid item >{t('sedie.s_even')}</Grid>
                            <Grid item >{winStatistics[0].even.value}</Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction={'row'}
                        className='sedie-small-big-grp'
                    >
                        <Grid
                            container
                            width={winStatistics[0].small.value}
                            direction='column'
                            className='sedie-odd-small-item'
                        >
                            <Grid item>{t('sedie.s_small')}</Grid>
                            <Grid item>{winStatistics[0].small.value}</Grid>
                        </Grid>
                        <Grid
                            container
                            width={winStatistics[0].big.value}
                            direction='column'
                            className='sedie-even-big-item'
                        >
                            <Grid item>{t('sedie.s_big')}</Grid>
                            <Grid item>{winStatistics[0].big.value}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }

    const rightPanel = () => {
        return (winStatistics && winStatistics.length > 1) && (
            <>
                <Grid
                    className='sedie-statistics-right'
                >
                    {Object.keys(winStatistics[1]).map((k, ii) => {
                        const v = winStatistics[1][
                            k as keyof typeof winStatistics[1]
                        ] as WinStatisticsProps;
                        return (
                            <Grid
                                key={`info-cell${ii}`}
                                item
                                container
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                height={'39.5px'}
                                padding={'0 10px 0 18px'}
                            >
                                <Grid
                                    item
                                    paddingTop={'5px'}
                                    sx={{
                                        color: REPORT_COLORS.TITLE_TEXT,
                                    }}
                                >
                                    {v.imageData ?
                                        (<BaseImage
                                            className={v.imageData.image}
                                            isMultiLang={v.imageData.isMultiLang}
                                            scale={v.imageData.scale ?? 1}
                                        />) : t(v.title)
                                    }
                                </Grid>
                                <Grid item>{v.value}</Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </>
        )
    }

    return (
        <>
            {winStatistics && (
                <>
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        sx={{
                            fontSize: '14px',
                            height: '225px',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                            }}
                        >
                            <BaseImage
                                className="tradbaccBg table_info_bg"
                                scale={1}
                                opacity={0.5}
                                transform={
                                    'translate(0, 17.5px) scale(1, 1.226)'
                                }
                            />
                        </Box>
                        <Box sx={style(true)}>{leftPanel()}</Box>
                        <Box sx={style(false)}>{rightPanel()}</Box>
                    </Stack>
                </>
            )}
        </>
    );
}
