import { createContext } from 'react';
import useVideoPlayer, { useVideoPlayerState } from '../hooks/useVideoPlayer';

export const VideoPlayerContext = createContext({} as useVideoPlayerState);
const { Provider } = VideoPlayerContext;
type VideoPlayerProviderOwnProps = {
    children: JSX.Element;
    hostId: number;
};
type VideoPlayerProviderProps = VideoPlayerProviderOwnProps;
export const VideoPlayerProvider = (props: VideoPlayerProviderProps) => {
    const { children, hostId } = props;
    const vp = useVideoPlayer(hostId);
    return <Provider value={vp}>{children}</Provider>;
};
