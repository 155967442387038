import { Box, SxProps, Theme } from '@mui/material';
import { useMemo } from 'react';
import { BettingSeatArea } from './BettingSeatArea';
import { GamingSeatArea } from './GamingSeatArea';
import './index.scss';

const SeatAreaStyle: SxProps<Theme> = {
    height: 120,
    // top: '250px',
    top: '280px',
};
const ZoomInSeatStyle: SxProps<Theme> = {
    height: 152,
    top: '245px',
    // top: '219px',
};

type SeatAreaProps = {
    isVideoZoom: boolean;
};
export const SeatArea = (props: SeatAreaProps) => {
    const { isVideoZoom } = props;
    const containerStyle = useMemo(
        () => (isVideoZoom ? ZoomInSeatStyle : SeatAreaStyle),
        [isVideoZoom]
    );
    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    width: 540,
                    ...containerStyle,
                }}
            >
                {isVideoZoom ? <GamingSeatArea /> : <BettingSeatArea />}
            </Box>
        </>
    );
};
