import { memo } from 'react';
import {
    AndarBaharRoadMapCellProps,
    CommonCellProps,
    useAndarBaharRoadMapCellColorText,
} from '../hook';
const BeadRoadCell = (props: AndarBaharRoadMapCellProps & CommonCellProps) => {
    const {
        record: { Win },
        size,
    } = props;
    const { color, text } = useAndarBaharRoadMapCellColorText(Win);
    return (
        <svg width={size} height={size}>
            {Win >= 0 && (
                <>
                    <circle
                        cx={size / 2}
                        cy={size / 2}
                        r={size / 2}
                        fill={color}
                    />
                    <text
                        x={size / 2}
                        y={size / 2 + 2}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill="#ffffff"
                        fontSize={size * 0.75}
                    >
                        {text}
                    </text>
                </>
            )}
        </svg>
    );
};
export default memo(BeadRoadCell);