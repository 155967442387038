import { Button, Grid } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SeatAreaContext } from '../../../../../../contexts/SeatAreaContext';
import { WebSocketContext } from '../../../../../../contexts/WebSocketContext';
import { useBetSource } from '../../../../../../hooks/useBetSource';
import { CMDPsMakeDecision } from '../../../../../../models/cmd/live';
import { Decision } from '../../../../../../models/games/blackjack/enums/Decision';
import { BaseTypography } from '../../../../../../components/common/baseText/BaseTypography';

export const SplitDecisionButton = () => {
    const { t } = useTranslation();
    const betSource = useBetSource();
    const { sendCommand } = useContext(WebSocketContext);
    const { setShowDecision, gameId, setHasSplitted, canSplit } =
        useContext(SeatAreaContext);
    return (
        <Button
            className="blackjack-decision-button"
            onClick={() => {
                const command = new CMDPsMakeDecision();
                command.GameID = gameId;
                command.BetSource = betSource;
                command.Decision = Decision.Split;

                sendCommand(command);
                setShowDecision(false);
                setHasSplitted(true);
            }}
            disabled={!canSplit}
        >
            <Grid>
                <svg
                    width="56"
                    height="56"
                    viewBox="0 0 56 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="56" height="56" rx="28" fill="#153962" />
                    <g filter="url(#filter0_d_1096_1887)">
                        <path
                            d="M16.3679 30.2486V28.2031L26.0838 23.7145V26.2358L19.1804 29.1974L19.2585 29.0625V29.3892L19.1804 29.2543L26.0838 32.2159V34.7372L16.3679 30.2486ZM39.5408 30.2486L29.8249 34.7372V32.2159L36.7283 29.2543L36.6502 29.3892V29.0625L36.7283 29.1974L29.8249 26.2358V23.7145L39.5408 28.2031V30.2486Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_1096_1887"
                            x="12.3672"
                            y="19.7145"
                            width="31.1719"
                            height="19.0228"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_1096_1887"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_1096_1887"
                                result="shape"
                            />
                        </filter>
                    </defs>
                </svg>
                <BaseTypography
                    color={'white'}
                    fontSize={18}
                    resize={{ direction: 'horizontal', value: '80px' }}
                >
                    {t('blackjack.decision.split')}
                </BaseTypography>
            </Grid>
        </Button>
    );
};
