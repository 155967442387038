import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { LobbyUiContext } from '../../../contexts/LobbyUiContext';
import { AndarBaharRecord, AndarBaharRoadmapRecord } from '../../../models/games/andarBahar';
import { RootState } from '../../../store/store';
import { getHostById } from '../../host/slice';
import { getBeadRoad, getOtherRoad } from './util';
import { ROAD_MAP_COLORS } from '../../lobby/constants';

const transBaccaratRecord = (Record: AndarBaharRecord[] | undefined, askType: number) => {
    const andarBaharRecord = Record?.map((r: AndarBaharRecord) => {
        const data = {} as AndarBaharRecord;
        data.gameID = r.gameID;
        data.result = r.result;
        data.round = r.round;

        data.andarWin = r.andarWin;
        data.baharWin = r.baharWin;
        data.cardCount = r.cardCount;
        return data;
    });
    return getBeadRoad(andarBaharRecord as AndarBaharRecord[], askType);
};

export const STROKE_WIDTH = 1.5;
export type CommonCellProps = {
    size: number;
};

export type AndarBaharRoadMapCellProps = {
    record: AndarBaharRoadmapRecord;
} & CommonCellProps;

export const useAndarBaharRoadMapCellColorText = (
    win: number
) => {
    let color = ROAD_MAP_COLORS.GREEN;
    let text = '';
    switch (win) {
        case 0:
            color = ROAD_MAP_COLORS.RED;
            text = 'B';
            break;
        case 1:
            color = ROAD_MAP_COLORS.BLUE;
            text = 'A';
            break;
    }
    return { color, text };
};

export const useAndarBaharPointRoadMapCellColorText = (
    win: number,
    cardDealt?: number
) => {
    let color = ROAD_MAP_COLORS.GREEN;
    let text = cardDealt? cardDealt.toString():'';
    switch (win) {
        case 0:
            color = ROAD_MAP_COLORS.RED;
            break;
        case 1:
            color = ROAD_MAP_COLORS.BLUE;
            break;
    }
    return { color, text };
};

export const useRoadMapState = (hostId: number) => {
    const { grid } = useContext(LobbyUiContext);
    const { Record, IsRest } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    //use baccarat roadmap
    const [beadRoadRecord, setBeadRoadRecord] =
        useState<Array<AndarBaharRoadmapRecord>>();
    const [bigRoadRecord, setBigRoadRecord] =
        useState<Array<AndarBaharRoadmapRecord>>();
    const [nextRoad, setNextRoad] = useState<Array<number>>([-1, -1, -1]);
    const isOneColumn = useMemo(() => grid === 12, [grid]);
    const beadRoad = useMemo(
        () => transBaccaratRecord(Record as AndarBaharRecord[], -1),
        [Record]
    );
    const otherRoad = useMemo(() => getOtherRoad(beadRoad), [beadRoad]);

    return {
        beadRoadRecord,
        setBeadRoadRecord,
        bigRoadRecord,
        setBigRoadRecord,
        nextRoad,
        setNextRoad,
        isOneColumn,
        beadRoad,
        otherRoad,
        Record,
        IsRest,
    };
};