import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import BaseImage from '../../../../../../components/common/baseImage';
import BetArea from '../../../../../../components/common/betArea';
import { amountOverBetLimit } from '../utils';
import betAreaJson from './DoubleBetArea.json';
const { betType: betTypeList } = betAreaJson;
export const DoubleBetArea = () => {
    const [display, setDisplay] = useState(<></>);
    useEffect(() => {
        const dp = new Array<JSX.Element>();
        if (betTypeList) {
            const keys = Object.keys(betTypeList);
            for (const key of keys) {
                const betType = betTypeList[key as keyof typeof betTypeList];
                dp.push(
                    <Box
                        key={`double-betarea-${key}`}
                        sx={{
                            position: 'absolute',
                            left: betType.xy[0],
                            top: betType.xy[1],
                        }}
                    >
                        <BetArea
                            style={betType.style}
                            confirmPanelStyle={betType.confirmPanelStyle}
                            isMultiLang={betType.isMultiLang}
                            imageName={betType.image}
                            betType={Number(key)}
                            amountOverBetLimit={amountOverBetLimit}
                        />
                    </Box>
                );
            }
        }
        setDisplay(<>{dp}</>);
    }, [betTypeList]);
    return (
        <>
            <Grid item sx={{ width: 540, height: 473, overflow: 'hidden' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: 540,
                        height: 30,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <BaseImage className="double payrate1" />
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        left: 0,
                        top: 292,
                        width: 540,
                        height: 30,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <BaseImage className="double payrate2" />
                </Box>
                {display}
            </Grid>
        </>
    );
};
