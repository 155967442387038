import { Box, Grid, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BaseImage from '../../../components/common/baseImage';
import { AudioPlayContext } from '../../../contexts/AudioPlayContext';
import { VideoPlayerContext } from '../../../contexts/VideoContext';
import { RootState } from '../../../store/store';
import { LobbyCountdown } from '../../countdown';
import { getHostById } from '../../host/slice';
import { SwitchTableRoadMap } from '../../lobby/components/Roadmaps';

import { ROUTE_GAME } from '../../../models/Route';
import { GameType as GameTypeEnum } from '../../../models/games/enums/GameType';

import { getGameSate } from '../../games/selector';

type SwitchTableCellProps = {
    hostId: number;
    changeOpen: (b: boolean) => void;
};
export const SwitchTableCell = ({
    hostId,
    changeOpen,
}: SwitchTableCellProps) => {
    const {
        GameType,
        ConfirmedBets,
        CurrentResult,
        ResultReleased,
        HallStateName,
        IsRest,
    } = useSelector((state: RootState) => getHostById(state, hostId));
    const { audioStop } = useContext(AudioPlayContext);
    const { hostId: curHostId } = useContext(VideoPlayerContext);

    const navigate = useNavigate();
    const [isCurrent, setIsCurrent] = useState(false);
    const [cn, setCn] = useState('baccarat-tab');
    const [haveBet, setHaveBet] = useState(false);
    const { t, i18n } = useTranslation();
    const [hostName, setHostName] = useState<string | undefined>('');
    const [fontSize, setFontSize] = useState<number>(14);

    const { initBlock } = useSelector(getGameSate);

    const [block, setBlock] = useState<boolean>(false);

    useEffect(() => {
        setBlock(initBlock);
    }, [initBlock]);
    useEffect(() => {
        setIsCurrent(hostId === curHostId);
    }, [curHostId]);
    useEffect(() => {
        const c =
            GameType === GameTypeEnum.Baccarat
                ? 'baccarat-tab'
                : 'other-games-tab';
        setCn(c);
    }, [GameType]);

    useEffect(() => {
        if (ConfirmedBets && ConfirmedBets.length > 0) {
            const lastIndex = ConfirmedBets.length - 1;
            const validBet =
                ConfirmedBets[lastIndex].GameId == CurrentResult?.GameID;
            if (validBet) {
                setHaveBet(!ResultReleased);
            }
        }
    }, [ConfirmedBets, ResultReleased, CurrentResult, ResultReleased]);
    useEffect(() => {
        switch (GameType) {
            case GameTypeEnum.Baccarat:
                setHostName(
                    t(`hostList.${hostId}`).split(' ').pop()?.toString()
                );
                break;
            case GameTypeEnum.AndarBahar:
            case GameTypeEnum.Dragon:
                setFontSize(11);
                setHostName(t(`hostList.${hostId}`));
                break;
            case GameTypeEnum.TeenPatti2020:
                setFontSize(12);
                setHostName(t(`hostCodeNames.${hostId}`));
                break;
            default:
                setHostName(t(`hostList.${hostId}`));
                if (hostName && hostName.length > 5) {
                    switch (i18n.language) {
                        case 'ja-JP':
                            setFontSize(10);
                            break;
                        case 'ko-KR':
                            setFontSize(11);
                            break;
                        default:
                            setFontSize(12);
                            break;
                    }
                }
                break;
        }
    }, [hostId, i18n.language]);
    const handleClickTable = (hostId: number) => {
        if (!hostId || curHostId === hostId) return;
        if (IsRest) return;
        if (block) return;

        navigate(`${ROUTE_GAME}/${hostId}`);
        changeOpen(false);
        // doRefresh();
        audioStop();
    };
    return (
        <Box
            className={cn}
            onClick={() => {
                handleClickTable(hostId);
            }}
        >
            <Stack direction={'row'} className={`${HallStateName}2`}>
                <Typography
                    component="span"
                    fontSize={fontSize}
                    className="host-name"
                >
                    {hostName}
                </Typography>
                <LobbyCountdown hostId={hostId} gameType={GameType} />
            </Stack>
            <Box className={'roadmap-box'}>
                <SwitchTableRoadMap hostId={hostId} gameType={GameType} />
                {isCurrent && (
                    <>
                        <Box className="here-box">{t('here')}</Box>
                    </>
                )}
            </Box>
            {haveBet && (
                <Grid className={'icon-box'}>
                    <BaseImage className="commonUI ic_placed-bet" scale={0.5} />
                </Grid>
            )}
        </Box>
    );
};
