import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import {
    getHostByGameId,
    hostsSliceActions,
} from '../../../modules/host/slice';
import store from '../../../store/store';
import {
    getGameState,
    isWaitingCard,
    isWaitingDecision,
} from '../../../utils/games/blackjack';
import { parseDeckData } from '../../../utils/games/pokdeng';
import { BlackjackDecision, BlackjackResult } from '../../games/blackjack';
import {
    BlackjackDealtTurnState,
    BlackjackInitState,
} from '../../games/blackjack/states';
import { GameState } from '../../games/enums/GameState';
import { GameType as GameTypeEnum } from '../../games/enums/GameType';
import { PokDengResult } from '../../games/pokdeng';
import { CMDScGameState, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
// import { GameState as GameStateEnum } from '../../games/enums/GameState';
export class CMDScGameStateHandler extends AbstractHandler {
    _command: CMDScGameState;

    constructor(command: ICommand) {
        super();
        this._command = command as CMDScGameState;
    }
    commandDelay2() {
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);

        const gameState = getGameState(host.CurrentState);
        if (host.GameType == GameTypeEnum.Blackjack) {
            if (isWaitingCard(gameState) && this._command.ID != 0) {
                return host.GameDelay
                    ? host.GameDelay.countDownDelay - 1000
                    : 0;
            } else {
                return 0;
            }
        }
        return 0;
    }
    commandDelay() {
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);

        const gameState = getGameState(host.CurrentState);
        if (host.GameType == GameTypeEnum.Blackjack) {
            if (isWaitingCard(gameState) && this._command.ID != 0) {
                return host.GameDelay ? host.GameDelay.countDownDelay : 0;
            } else {
                return 0;
            }
        }
        return host.DelayDraw;
    }
    delayKey() {
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);
        if (host.GameType == GameTypeEnum.Dragon) {
            return this._command.GameID.toString();
        }
        if (host.GameType == GameTypeEnum.PokDeng) {
            if (this._command.State == GameState.GameResult) {
                return this._command.GameID.toString();
            }
        }
        if (host.GameType == GameTypeEnum.Baccarat) {
            if (
                this._command.State >= GameState.Started &&
                this._command.State <= GameState.Shuffle
            ) {
                return this._command.GameID.toString();
            }
        }
        if (host.GameType == GameTypeEnum.Blackjack) {
            return this._command.GameID.toString();
        }
        return '';
    }
    forceRun() {
        return '';
    }
    // forceRun() {
    //     return this._command.GameID.toString();
    // }
    handle2(dispatch: Dispatch<AnyAction>): void {
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);
        const bjLastGameState = getGameState(host.CurrentState);
        batch(() => {
            switch (host.GameType) {
                case GameTypeEnum.Blackjack:
                    if (isWaitingDecision(bjLastGameState)) {
                        switch (this._command.ID) {
                            case BlackjackDecision.Split:
                                //TODO Decision success
                                break;
                            case BlackjackDecision.DoubleDown:
                                //TODO Decision success
                                break;
                            case BlackjackDecision.Surrender:
                                //TODO Decision success
                                break;
                        }

                        //Decision End
                        dispatch(
                            hostsSliceActions.updateCountDown({
                                hostId: host.HostId,
                                countDown: 0,
                                canLeave: false,
                            })
                        );
                    }
                    dispatch(
                        hostsSliceActions.updateGameResultObject({
                            hostId: host.HostId,
                            result: {
                                GameID: this._command.GameID,
                                GameCount: host.CurrentResult?.GameCount,
                                Result: this._command.ID,
                                ResultString: this._command.ResultString,
                            } as BlackjackResult,
                        })
                    );
                    break;
            }
        });
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const gameState = this._command.State;
        const bjGameState = getGameState(this._command.State);
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);
        if (host) {
            console.log(`hook::socket::run::${host.HostId}`, this._command);
            batch(() => {
                dispatch(
                    hostsSliceActions.updateGameState({
                        hostId: host.HostId,
                        state: gameState,
                    })
                );
                switch (host.GameType) {
                    case GameTypeEnum.Roulette:
                    case GameTypeEnum.SeDie:
                        break;
                    case GameTypeEnum.Blackjack:
                        dispatch(
                            hostsSliceActions.updateGameResultObject({
                                hostId: host.HostId,
                                result: {
                                    GameID: this._command.GameID,
                                    GameCount: host.CurrentResult?.GameCount,
                                    Result: this._command.ID,
                                    ResultString: this._command.ResultString,
                                } as BlackjackResult,
                            })
                        );
                        switch (bjGameState.subState) {
                            case BlackjackInitState.WaitSeatPlayerInsurance:
                                dispatch(
                                    hostsSliceActions.updateCountDown({
                                        hostId: host.HostId,
                                        countDown: host.GameDelay
                                            ? host.GameDelay.insurance
                                            : 0,
                                        canLeave: false,
                                    })
                                );
                                break;
                            case BlackjackDealtTurnState.WaitSideBetPlayerFollow:
                                dispatch(
                                    hostsSliceActions.updateCountDown({
                                        hostId: host.HostId,
                                        countDown: host.GameDelay
                                            ? host.GameDelay.followDecision
                                            : 0,
                                        canLeave: false,
                                    })
                                );
                                break;
                            default:
                                //Decision Start
                                if (isWaitingDecision(bjGameState)) {
                                    dispatch(
                                        hostsSliceActions.updateCountDown({
                                            hostId: host.HostId,
                                            countDown: host.GameDelay
                                                ? host.GameDelay.decision
                                                : 0,
                                            canLeave: false,
                                        })
                                    );
                                }
                                break;
                        }

                        break;
                    case GameTypeEnum.AndarBahar:
                        dispatch(
                            hostsSliceActions.updateGameResult({
                                hostId: host.HostId,
                                result: this._command.ID,
                                resultString: this._command.ResultString,
                                resultReleased: false,
                            })
                        );
                        break;
                    case GameTypeEnum.PokDeng:
                        if (gameState == GameState.GameResult) {
                            dispatch(
                                hostsSliceActions.updateGameResultObject({
                                    hostId: host.HostId,
                                    result: Object.assign({
                                        GameID: this._command.GameID,
                                        GameCount:
                                            host.CurrentResult?.GameCount,
                                        Result: this._command.ID,
                                        ResultString:
                                            this._command.ResultString,
                                        fullDeck: parseDeckData(
                                            this._command.ResultString
                                        ),
                                    } as PokDengResult),
                                })
                            );
                            dispatch(
                                hostsSliceActions.updatePokerShow({
                                    hostId: host.HostId,
                                    results: [],
                                })
                            );
                        } else {
                            dispatch(
                                hostsSliceActions.updateGameResult({
                                    hostId: host.HostId,
                                    result: this._command.ID,
                                    resultString: this._command.ResultString,
                                    resultReleased: false, //(gameState >= GameStateEnum.GameResult),
                                })
                            );
                        }
                        break;
                    default:
                        dispatch(
                            hostsSliceActions.updateGameResult({
                                hostId: host.HostId,
                                result: this._command.ID,
                                resultString: this._command.ResultString,
                                resultReleased: false, //(gameState >= GameStateEnum.GameResult),
                            })
                        );
                        break;
                }
            });
        } else {
            console.warn(
                'hook::socket::warning::cannot::find::host',
                this._command.GameID
            );
        }
    }
}
