import { ROAD_MAP_TYPE } from '../../lobby/constants';
import { AndarBaharRecord, AndarBaharRoadmapRecord } from '../../../models/games/andarBahar';

export const getUnion = (list: Array<AndarBaharRoadmapRecord>) => {
    let tmp: AndarBaharRoadmapRecord = {
        LastWin: list[0].LastWin,
        Win: list[0].Win,
        WinPoint: list[0].WinPoint,
        // type: list[0]["type"],
        SpaceNum: list.length - 1,
        IsLast: false,
        Detail: list[0].Detail,
    };
    for (let i = 0; i < list.length; i++) {
        if (list[i].Detail) tmp.Detail = list[i].Detail;
    }
    return tmp;
};

export const getBeadRoad = (
    oldResult: AndarBaharRecord[] | undefined,
    askType: number = -1
): Array<AndarBaharRoadmapRecord> => {
    let record = new Array<AndarBaharRoadmapRecord>();
    let otherRecord = new Array<Array<AndarBaharRoadmapRecord>>();

    if (oldResult) {
        let curDataIdx: number = 0;
        let curWin: number = -1;
        let x: number = -1;
        let y: number = -1;
        oldResult.forEach((result, idx) => {
            let win = -1; //-1 empty, 0 banker, 1 player, 2 draw
            win = result.baharWin ? 0 : 1;

            let winPoint: number = 0;
            winPoint = result.gameCard;

            if (win >= 0) {
                record.push({
                    LastWin: -1,
                    Win: win,
                    WinPoint: winPoint,
                    Idx: idx,
                    SpaceNum: 0,
                    IsLast: idx == oldResult.length - 1,
                    Detail: {
                        GameID: result.gameID,
                        Result: result.result,
                        Shoes: 0,
                        Round: result.round,
                        andarWin: result.andarWin,
                        baharWin: result.baharWin,
                        cardDealt: result.cardCount
                    },
                });
            }

            if (askType >= 0 && idx == oldResult.length - 1) {
                //For ask road
                record.push({
                    LastWin: 0,
                    Win: askType,
                    WinPoint:
                        ROAD_MAP_TYPE.BEAD_ROAD || ROAD_MAP_TYPE.POINT_ROAD
                            ? -1
                            : 0,
                    SpaceNum: 0,
                    IsLast: false,
                });
            }

            while (curDataIdx < record.length) {
                const curRecord = record[curDataIdx];
                if (curRecord.Win != curWin) {
                    if (curRecord.Win != 2) {
                        curWin = curRecord.Win;
                        x++;
                        y = 0;
                    } else {
                        curDataIdx++;
                        continue;
                    }
                } else {
                    y++;
                }

                while (otherRecord.length <= x) {
                    let tmp: Array<AndarBaharRoadmapRecord> = [];
                    tmp.push({
                        LastWin: 0,
                        Win: -1,
                        WinPoint: 0,
                        SpaceNum: 0,
                        IsLast: false,
                    });
                    otherRecord.push(tmp);
                }

                while (otherRecord[x].length <= y) {
                    otherRecord[x].push({
                        LastWin: 0,
                        Win: -1,
                        WinPoint: 0,
                        SpaceNum: 0,
                        IsLast: false,
                    });
                }
                otherRecord[x][y] = record[curDataIdx];
                curDataIdx++;
            }
        });
    }

    return record;
};

export const getOtherRoad = (
    beadRoad: Array<AndarBaharRoadmapRecord> | undefined
): Array<Array<AndarBaharRoadmapRecord>> => {
    let record = new Array<Array<AndarBaharRoadmapRecord>>();

    if (beadRoad) {
        let curDataIdx: number = 0;
        let curWin: number = -1;
        let x: number = -1;
        let y: number = -1;

        while (curDataIdx < beadRoad.length) {
            const curRecord = beadRoad[curDataIdx];
            if (curRecord.Win != curWin) {
                if (curRecord.Win != 2) {
                    curWin = curRecord.Win;
                    x++;
                    y = 0;
                } else {
                    curDataIdx++;
                    continue;
                }
            } else {
                y++;
            }

            while (record.length <= x) {
                let tmp = new Array<AndarBaharRoadmapRecord>();
                tmp.push({
                    LastWin: 0,
                    Win: -1,
                    WinPoint: 0,
                    SpaceNum: 0,
                    IsLast: false,
                });
                record.push(tmp);
            }

            while (record[x].length <= y) {
                record[x].push({
                    LastWin: 0,
                    Win: -1,
                    WinPoint: 0,
                    SpaceNum: 0,
                    IsLast: false,
                });
            }

            record[x][y] = beadRoad[curDataIdx];
            curDataIdx++;
        }
    }

    return record;
};
export const getMappedRoad = (
    beadRoad: Array<AndarBaharRoadmapRecord>,
    otherRoad: Array<Array<AndarBaharRoadmapRecord>>,
    typeID: number,
    gridWidth: number,
    gridHeight: number,
    askRoad: number = -1
): Array<AndarBaharRoadmapRecord> => {
    let record = new Array<AndarBaharRoadmapRecord>();
    let offset: number = 0;
    // let tmpArr: Array<Array<number>>;
    let width: number = 0;
    let askRoadFix: boolean;
    let offsetFixed: boolean;
    let list: Array<number>;
    let markEmpty: boolean;
    let x: number = -1;
    let y: number = -1;
    let curDataIdx: number = -1;
    let tmpArr: Array<Array<number>>;

    switch (typeID) {
        case ROAD_MAP_TYPE.BEAD_ROAD:
            while (gridWidth * gridHeight <= beadRoad.length - offset) {
                offset += gridHeight;
            }

            if (askRoad >= 0) {
                if (beadRoad.length % gridHeight == 0) {
                    if (beadRoad.length >= gridWidth * gridHeight) {
                        offset -= gridHeight;
                        if (offset < 0) {
                            offset = 0;
                        }
                    }
                }
            }

            for (let i: number = offset; i < beadRoad.length; i++) {
                record.push(beadRoad[i]);
            }
            break;

        case ROAD_MAP_TYPE.BIG_ROAD:
            width = gridWidth;
            offset = 0;
            askRoadFix = false;
            offsetFixed = false;

            if (askRoad >= 0) {
                if (otherRoad.length > 1) {
                    if (otherRoad[otherRoad.length - 1].length > 6) {
                        width += 1;
                    } else if (otherRoad[otherRoad.length - 1].length == 1) {
                        width += 1;
                        askRoadFix = true; //for check if long road long than new column
                    }
                }
            }

            while (!offsetFixed) {
                let placed: boolean = true;
                for (let x: number = offset; x < otherRoad.length; x++) {
                    let start_pt = x - offset;
                    let turned: boolean = false;
                    let turned_pt: number = -1;
                    let spaceOne: boolean = false;

                    for (let y: number = 0; y < otherRoad[x].length; y++) {
                        let idx: number = start_pt * gridHeight + y;
                        const max: number = gridHeight;

                        if (y >= max && !turned) {
                            turned = true;
                            turned_pt = y - 1;
                            idx =
                                (start_pt + (y - (max - 1))) * gridHeight +
                                (max - 1);
                        }

                        if (spaceOne) {
                            // spaceCount++;
                        } else {
                            if (!turned) {
                                if (
                                    idx < record.length &&
                                    record[idx].Win >= 0
                                ) {
                                    turned = true;
                                    turned_pt = y - 1;

                                    if (
                                        record[idx].Win == otherRoad[x][y].Win
                                    ) {
                                        turned_pt--;
                                    }

                                    idx =
                                        (start_pt + (y - turned_pt)) *
                                            gridHeight +
                                        turned_pt;

                                    if (turned_pt == 0) {
                                        spaceOne = true;
                                    }
                                }

                                if (!turned) {
                                    if (
                                        idx + 1 < record.length &&
                                        record[idx + 1].Win ==
                                            otherRoad[x][y].Win
                                    ) {
                                        if (y == 0) {
                                            spaceOne = true;
                                            // spaceCount++;
                                        } else {
                                            idx += gridHeight - 1;

                                            if (idx % gridHeight == 0) {
                                                spaceOne = true;
                                                // spaceCount++;
                                            }
                                        }
                                    }
                                }
                            } else {
                                idx =
                                    (start_pt + (y - turned_pt)) * gridHeight +
                                    turned_pt;
                            }
                        }

                        if (spaceOne) {
                            idx = start_pt * gridHeight;
                        }

                        if (idx < 0) {
                            continue;
                        }

                        while (record.length <= idx) {
                            record.push({
                                LastWin: 0,
                                Win: -1,
                                WinPoint: 0,
                                SpaceNum: 0,
                                IsLast: false,
                            });
                        }

                        if (spaceOne) {
                            record[idx].SpaceNum = y + 1;
                            otherRoad[x][y].SpaceNum = y;

                            let tmp = (start_pt + 1) * gridHeight;
                            if (tmp < record.length) {
                                record[tmp].Win = -1;
                            }

                            record[idx] = getUnion(otherRoad[x]);
                        } else {
                            otherRoad[x][y].SpaceNum = 0;
                            record[idx] = otherRoad[x][y];
                        }

                        if ((idx + 1) / gridHeight > width) {
                            placed = false;
                        }

                        if (askRoadFix && x == otherRoad.length - 2) {
                            //Fix have turned road longer than new line, cancel ask road width added
                            let lastColumeFirstOffset =
                                record.length % gridHeight == 0
                                    ? gridHeight
                                    : record.length % gridHeight;
                            if (
                                record[record.length - lastColumeFirstOffset]
                                    .Win == -1
                            ) {
                                askRoadFix = false;
                                width -= 1;
                                placed = false;
                            }
                        }
                    }
                }

                if (placed) {
                    offsetFixed = true;
                } else {
                    while (record.length / gridHeight > width) {
                        let haveLast = false;
                        for (let i = 0; i < gridHeight; i++) {
                            if (record[i]?.IsLast) {
                                haveLast = true;
                            }
                        }

                        if (haveLast) {
                            record = record.slice(0, record.length - 1);
                        } else {
                            record = record.slice(gridHeight);
                        }
                    }
                    offsetFixed = true;
                }
            }
            break;
        default:
            list = [];
            markEmpty = false;

            if (typeID == ROAD_MAP_TYPE.BIG_EYE_ROAD) {
                x = 1;
                y = 1;
                offset = 2;
            } else if (typeID == ROAD_MAP_TYPE.SMALL_ROAD) {
                x = 2;
                y = 1;
                offset = 3;
            } else if (typeID == ROAD_MAP_TYPE.COCKROACH_ROAD) {
                x = 3;
                y = 1;
                offset = 4;
            }
            width = gridWidth;

            while (x < otherRoad.length) {
                if (y >= otherRoad[x].length) {
                    x++;
                    y = 0;
                    markEmpty = false;
                }

                if (y == 0) {
                    if (
                        otherRoad[x - 1].length == otherRoad[x - offset].length
                    ) {
                        list.push(0);
                    } else {
                        list.push(1);
                    }
                } else {
                    if (y < otherRoad[x - offset + 1].length) {
                        list.push(0);
                    } else {
                        if (markEmpty) {
                            list.push(0);
                        } else {
                            markEmpty = true;
                            list.push(1);
                        }
                    }
                }
                y++;
            }

            curDataIdx = -1;
            tmpArr = [];
            for (let i: number = 0; i < list.length - 1; i++) {
                if (list[i] != curDataIdx) {
                    curDataIdx = list[i];
                    tmpArr.push([]);
                    tmpArr[tmpArr.length - 1].push(curDataIdx);
                } else {
                    tmpArr[tmpArr.length - 1].push(curDataIdx);
                }
            }

            // if(list.length > 1) {
            //     //this.askRoad = list[list.length - 2];
            //     this.nextRoad = list[list.length - 1];
            // } else {
            //     this.nextRoad = -1;
            // }

            offsetFixed = false;
            offset = 0;
            askRoadFix = false;

            if (askRoad >= 0) {
                if (tmpArr.length > 1) {
                    if (tmpArr[tmpArr.length - 1].length > 6) {
                        width += 1;
                    } else if (tmpArr[tmpArr.length - 1].length == 1) {
                        width += 1;
                        askRoadFix = true; //for check if long road long than new column
                    }
                }
            }

            while (!offsetFixed) {
                let placed = true;

                for (let x: number = offset; x < tmpArr.length; x++) {
                    let start_pt: number = x - offset;
                    let turned: boolean = false;
                    let turned_pt = -1;
                    let spaceOne: boolean = false;
                    for (let y: number = 0; y < tmpArr[x].length; y++) {
                        let idx: number = start_pt * gridHeight + y;
                        const max: number = gridHeight;

                        if (y >= max) {
                            idx =
                                (start_pt + (y - (max - 1))) * gridHeight +
                                (max - 1);
                        }

                        if (!spaceOne) {
                            if (!turned) {
                                if (
                                    idx < record.length &&
                                    record[idx].Win >= 0
                                ) {
                                    turned = true;
                                    turned_pt = y - 1;
                                    if (record[idx].Win == tmpArr[x][y]) {
                                        turned_pt--;
                                    }
                                    idx =
                                        (start_pt + (y - turned_pt)) *
                                            gridHeight +
                                        turned_pt;

                                    if (turned_pt <= 0) {
                                        spaceOne = true;
                                    }
                                }
                                if (!turned) {
                                    if (
                                        idx + 1 < record.length &&
                                        record[idx + 1].Win == tmpArr[x][y]
                                    ) {
                                        if (y == 0) {
                                            spaceOne = true;
                                        } else {
                                            turned = true;
                                            turned_pt = y - 1;
                                            idx += gridHeight - 1;
                                            if (idx % gridHeight == 0) {
                                                spaceOne = true;
                                            }
                                        }
                                    }
                                }
                            } else {
                                idx =
                                    (start_pt + (y - turned_pt)) * gridHeight +
                                    turned_pt;
                            }
                        }

                        if (y != 0 && idx % gridHeight == 0) {
                            spaceOne = true;
                        }

                        if (spaceOne) {
                            idx = start_pt * gridHeight;
                        }

                        while (record.length <= idx) {
                            record.push({
                                Win: -1, //-1 empty, 0 banker, 1 player, 2 draw
                                SpaceNum: 0,
                            });
                        }

                        record[idx].Win = tmpArr[x][y];

                        if ((idx + 1) / gridHeight > width) {
                            placed = false;
                        }

                        if (askRoadFix && x == tmpArr.length - 2) {
                            //Fix have turned road longer than new line, cancel ask road width added
                            let lastColumnFirstOffset =
                                record.length % gridHeight == 0
                                    ? gridHeight
                                    : record.length % gridHeight;
                            if (
                                record[record.length - lastColumnFirstOffset]
                                    .Win == -1
                            ) {
                                askRoadFix = false;
                                width -= 1;
                                placed = false;
                            }
                        }
                    }
                }

                if (placed) {
                    offsetFixed = true;
                } else {
                    while (record.length / gridHeight > width) {
                        let haveLast = false;
                        for (let i = 0; i < gridHeight; i++) {
                            if (record[i]?.IsLast) {
                                haveLast = true;
                            }
                        }

                        if (haveLast) {
                            record = record.slice(0, record.length - 1);
                        } else {
                            record = record.slice(gridHeight);
                        }
                    }
                    offsetFixed = true;
                }
            }
            break;
    }
    return record;
};

export const getNextRoad = (
    records: Array<AndarBaharRoadmapRecord> | undefined,
    otherRoad: Array<Array<AndarBaharRoadmapRecord>>,
    typeID: number
) => {
    const getNextRoadValue = (): number => {
        let offset: number = 0;
        let list: Array<number>;
        let markEmpty: boolean;
        let x: number = -1;
        let y: number = -1;
        let nextRoad: number = -1;

        list = [];
        markEmpty = false;
        if (typeID == ROAD_MAP_TYPE.BIG_EYE_ROAD) {
            x = 1;
            y = 1;
            offset = 2;
        } else if (typeID == ROAD_MAP_TYPE.SMALL_ROAD) {
            x = 2;
            y = 1;
            offset = 3;
        } else if (typeID == ROAD_MAP_TYPE.COCKROACH_ROAD) {
            x = 3;
            y = 1;
            offset = 4;
        }

        while (x < otherRoad.length) {
            if (y >= otherRoad[x].length) {
                x++;
                y = 0;
                markEmpty = false;
            }

            if (y == 0) {
                if (otherRoad[x - 1].length == otherRoad[x - offset].length) {
                    list.push(0);
                } else {
                    list.push(1);
                }
            } else {
                if (y < otherRoad[x - offset + 1].length) {
                    list.push(0);
                } else {
                    if (markEmpty) {
                        list.push(0);
                    } else {
                        markEmpty = true;
                        list.push(1);
                    }
                }
            }
            y++;
        }

        if (list.length > 1) {
            nextRoad = list[list.length - 1];
        } else {
            nextRoad = -1;
        }

        return nextRoad;
    };

    let nextRoad = getNextRoadValue();

    if (nextRoad < 0 || !records) {
        return -1;
    }
    let offset = 1;
    while (records.length - offset >= 0) {
        if (records[records.length - offset].Win == 0) {
            return nextRoad;
        } else if (records[records.length - offset].Win == 1) {
            if (nextRoad == 0) {
                return 1;
            } else {
                return 0;
            }
        }
        offset++;
    }
    return -1;
};