import { memo } from 'react';

import {
    RouletteRoadMapCellProps,
    useRouletteRoadMapCellColorText,
} from '../hook';
import { ROAD_MAP_TYPE } from '../../../lobby/constants';

const BeadRoadCell = (props: RouletteRoadMapCellProps) => {
    const {
        record: { Win, WinPoint },
        size,
    } = props;

    const { color, text } = useRouletteRoadMapCellColorText(
        ROAD_MAP_TYPE.BEAD_ROAD,
        Win,
        WinPoint
    );

    return (
        <svg width={size} height={size}>
            {Win >= 0 && (
                <>
                    <rect fill={color} width={size} height={size} />
                    <text
                        x={size / 2}
                        y={size / 2 + 2}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill="#ffffff"
                        fontSize={size * 0.75}
                    >
                        {text}
                    </text>
                </>
            )}
        </svg>
    );
};

export default memo(BeadRoadCell);
