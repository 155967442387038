import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BetRoomSeatIndex } from '../../../../models/games/BetRoom';
import {
    BlackjackBetType,
    BlackjackResult,
} from '../../../../models/games/blackjack';
import {
    getBlackjackPoints,
    getDisplaySeat,
} from '../../../../utils/games/blackjack';
import { getMainPlayerState } from '../../../main/selector';
import { Bet } from '../../../report/slice/betRecord';

type BlackjackResultRecordPanel = {
    bet: Bet;
};
export const BlackjackResultRecordPanel = ({
    bet,
}: BlackjackResultRecordPanel) => {
    const { BetType, Result } = bet;
    const { t } = useTranslation();
    const [seat, setSeat] = useState<number>();
    const [bankerPoint, setBankerPoint] = useState<string>();
    const [playerPoint, setPlayerPoint] = useState<string>();
    const { Id: PlayerId } = useSelector(getMainPlayerState);

    useEffect(() => {
        let seat = ((BetType % 7) + 1) as BetRoomSeatIndex;
        const blackjackResult = JSON.parse(Result) as BlackjackResult;
        const bp = blackjackResult.B.R;
        let pp = blackjackResult.P.find(p => p.SN === seat)?.H;
        if (bet.BetType === BlackjackBetType.BJB_Insurance) {
            const target = blackjackResult.P.find(
                p => p.I === PlayerId.toString()
            );
            if (target) {
                pp = target.H;
                seat = target.SN;
            }
        }

        setSeat(getDisplaySeat(seat));
        setBankerPoint(bp === 255 ? 'BJ' : bp.toString());
        if (pp) {
            const hand1 = pp.at(0);
            const hand2 = pp.at(1);
            if (
                hand2 &&
                bet.BetType >= BlackjackBetType.BJB1_Split2 &&
                bet.BetType <= BlackjackBetType.BJB7_Split2
            ) {
                const points = getBlackjackPoints(hand2.PK);
                const p2 = hand2.R === 255 ? 'BJ' : points[0].toString();
                setPlayerPoint(p2);
            } else if (hand1) {
                const points = getBlackjackPoints(hand1.PK);
                const p1 = hand1.R === 255 ? 'BJ' : points[0].toString();
                setPlayerPoint(p1);
            }
        }
    }, []);
    return (
        <Typography>
            P{seat}:{playerPoint},{t('tableinfo.dealer')}:{bankerPoint}
        </Typography>
    );
};
