import { Grid, Stack } from '@mui/material';
// import BaseImage from '../../../../components/common/baseImage';
import { Bet } from '../../../report/slice/betRecord';
import { parseDeckData } from '../../../../utils/games/pokdeng';
import { DetailResult } from './BaccGameResultRecordPanel';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { PokDengFullDeckInfo } from '../../../../models/games/pokdeng/DeckInfo';

type PokDengGameResultRecordPanelProps = {
    bet: Bet;
};
export const PokDengGameResultRecordPanel = (
    props: PokDengGameResultRecordPanelProps
) => {
    const { bet } = props;
    const [results, setResult] = useState<PokDengFullDeckInfo>();
    const type = bet.BetType % 5;
    const { t } = useTranslation();
    useEffect(() => {
        setResult(parseDeckData(bet.Result));
    }, [bet.Result]);

    return (
        <>
            {results && (
                <Stack
                    justifyContent="center"
                    sx={{ height: '86px', alignItems: 'end' }}
                >
                    <Stack direction="row" gap="10px">
                        <DetailResult
                            lbl={t('road.banker_win')}
                            cards={[
                                results.bankerDeck.card1,
                                results.bankerDeck.card2,
                            ]}
                        />
                        <Grid minWidth="50px">{results.bankerDeck.point}</Grid>
                    </Stack>
                    <Stack direction="row" gap="10px">
                        <DetailResult
                            lbl={t(`pokdeng.player.${type}`)}
                            cards={[
                                results.playerDecks[type].card1,
                                results.playerDecks[type].card2,
                            ]}
                        />
                        <Grid minWidth="50px">
                            {results.playerDecks[type].point}
                        </Grid>
                    </Stack>
                </Stack>
            )}
        </>
    );
};
