import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { LobbyUiContext } from '../../../contexts/LobbyUiContext';
import { TeenPattiRoadmapRecord } from '../../../models/games/teenpatti';
import { RootState } from '../../../store/store';
import { getHostById } from '../../host/slice';
import { ROAD_MAP_COLORS, ROAD_MAP_TYPE } from '../../lobby/constants';
import { getBeadRoad } from './util';
import { useTranslation } from 'react-i18next';

export const STROKE_WIDTH = 1.5;
export type CommonCellProps = {
    size: number;
};

export type TeenPattiRoadMapCellProps = {
    record: TeenPattiRoadmapRecord;
} & CommonCellProps;

export type TeenPattiRoadMapCellColorText = {
    color: ROAD_MAP_COLORS;
    text: string;
};

export const useTeenPattiRoadMapCellColorText = (
    roadType: ROAD_MAP_TYPE,
    win: number
) => {
    const { t } = useTranslation();
    let color = ROAD_MAP_COLORS.GREEN;
    let text = '';
    if (win == 1) {
        color = ROAD_MAP_COLORS.BLUE;
    } else if (win == 0) {
        color = ROAD_MAP_COLORS.RED;
    } else {
        color = ROAD_MAP_COLORS.GREEN;
    }

    switch (roadType) {
        case ROAD_MAP_TYPE.BEAD_ROAD:
            if (win == 1) {
                text = 'A';
            } else if (win == 0) {
                text = 'B';
            } else text = t('road.draw');
            break;
    }

    return { color, text };
};

export const useRoadMapState = (hostId: number) => {
    const { grid } = useContext(LobbyUiContext);
    const { Record, IsRest } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [beadRoadRecord, setBeadRoadRecord] =
        useState<Array<TeenPattiRoadmapRecord>>();
    const [bigRoadRecord, setBigRoadRecord] =
        useState<Array<TeenPattiRoadmapRecord>>();
    const isOneColumn = useMemo(() => grid === 12, [grid]);
    const beadRoad = useMemo(() => getBeadRoad(Record), [Record]);
    return {
        beadRoadRecord,
        setBeadRoadRecord,
        bigRoadRecord,
        setBigRoadRecord,
        isOneColumn,
        beadRoad,
        Record,
        IsRest,
    };
};
