import { Box, Grid, Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Chip } from '../../../../../../components/common/chips';
import { BetChipContext } from '../../../../../../contexts/BetChipContext';
import { VideoPlayerContext } from '../../../../../../contexts/VideoContext';
import { RootState } from '../../../../../../store/store';
import { getHostById } from '../../../../../host/slice';
import { getChipListMask } from '../BetArea';
import './index.scss';

const CHIP_SCALE: number = 0.73;

type ChipListProps = {
    isHorizontal?: boolean;
    isShowAllChips?: boolean;
};
export const ChipList = (props: ChipListProps) => {
    const { isHorizontal = true, isShowAllChips = false } = props;
    const { hostId } = useContext(VideoPlayerContext);
    const { Group } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const { chipList, selectedChip, handleChipClick } =
        useContext(BetChipContext);

    const [maskColor, setMaskColor] = useState<string>('');

    useEffect(() => {
        if (Group) {
            setMaskColor(getChipListMask(Group[0]));
        }
    }, [Group]);

    return (
        <>
            {isHorizontal ? (
                <Stack
                    className="chip-list"
                    direction={'row'}
                    flexWrap={'nowrap'}
                    paddingLeft={'15px'}
                    sx={{
                        '&::-webkit-scrollbar': {
                            display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                        },
                        '&-ms-overflow-style': {
                            display: 'none', // Hide the scrollbar for IE
                        },
                        width: isShowAllChips === false ? 390 : 460,
                    }}
                >
                    {chipList?.map((v, k) => {
                        const n = k + 1;
                        return (
                            <Grid
                                key={`chip-${k}-${v}`}
                                item
                                marginTop={'-22px'}
                                onClick={() => {
                                    handleChipClick(n);
                                }}
                            >
                                <Chip
                                    value={v}
                                    scale={CHIP_SCALE}
                                    isSelected={n === selectedChip}
                                    isChipStack={false}
                                />
                            </Grid>
                        );
                    })}
                    {isShowAllChips === false && (
                        <>
                            <Box
                                className="left-gradient"
                                sx={{
                                    pointerEvents: 'none',
                                    //SA SceneTradBacc -> getTableColorLayer2 -> [0,200,255]  21.5% = 1 - (200 / 255 * 100)
                                    background: `linear-gradient(to right, rgba(${maskColor},1), rgba(${maskColor},1) 21.5%, rgba(${maskColor},0))`,
                                }}
                            />
                            <Box
                                className="right-gradient"
                                sx={{
                                    pointerEvents: 'none',
                                    background: `linear-gradient(to left, rgba(${maskColor},1), rgba(${maskColor},1) 21.5%, rgba(${maskColor},0))`,
                                }}
                            />
                        </>
                    )}
                </Stack>
            ) : (
                <Stack
                    className="chip-list-vertical"
                    direction={'column'}
                    flexWrap={'nowrap'}
                    paddingLeft={'15px'}
                    sx={{
                        '&::-webkit-scrollbar': {
                            display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                        },
                        '&-ms-overflow-style': {
                            display: 'none', // Hide the scrollbar for IE
                        },
                    }}
                >
                    {chipList?.map((v, k) => {
                        const n = k + 1;
                        return (
                            <Grid
                                key={`chip-${k}-${v}`}
                                item
                                marginTop={'-22px'}
                                onClick={() => {
                                    handleChipClick(n);
                                }}
                                sx={{ marginBottom: '-5px' }}
                            >
                                <Chip
                                    value={v}
                                    scale={CHIP_SCALE}
                                    isSelected={n === selectedChip}
                                    isChipStack={false}
                                />
                            </Grid>
                        );
                    })}
                    {/* <Box
                        className="top-gradient"
                        sx={{
                            //SA SceneTradBacc -> getTableColorLayer2 -> [0,200,255]  21.5% = 1 - (200 / 255 * 100)
                            background: `linear-gradient(to bottom, rgba(${maskColor},1), rgba(${maskColor},1) 21.5%, rgba(${maskColor},0))`,
                        }}
                    />
                    <Box
                        className="bottom-gradient"
                        sx={{
                            background: `linear-gradient(to up, rgba(${maskColor},1), rgba(${maskColor},1) 21.5%, rgba(${maskColor},0))`,
                        }}
                    /> */}
                </Stack>
            )}
        </>
    );
};
