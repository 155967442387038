import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { VideoPlayerContext } from '../../../contexts/VideoContext';
import { getZoomFactor } from '../../../hooks/useVideoPlayer';
import { RootState } from '../../../store/store';
import { getHostById } from '../../host/slice';
const VideoZoomButton = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { GameType } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const { zoom } = useContext(VideoPlayerContext);
    const [isZoom, setIsZoom] = useState(false);
    useEffect(() => {
        const zf = isZoom
            ? getZoomFactor(GameType, true)
            : getZoomFactor(GameType);
        zoom(zf);
    }, [isZoom, GameType]);
    return (
        <>
            <Button
                variant="contained"
                sx={{
                    minWidth: '40px',
                    width: '40px',
                    height: '40px',
                    backgroundColor: isZoom ? 'primary' : '#000000 !important',
                }}
                onClick={() => {
                    setIsZoom(!isZoom);
                }}
            >
                {isZoom ? (
                    <ZoomOutIcon sx={{ fontSize: '28px' }} />
                ) : (
                    <ZoomInIcon sx={{ fontSize: '28px', color: '#767676' }} />
                )}
            </Button>
        </>
    );
};

export default VideoZoomButton;
