import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import BaseImage from '../../../../../../../components/common/baseImage';
import BetArea from '../../../../../../../components/common/betArea';
import { RouletteBetAreaType2 } from '../constants';

export const BetAreaContainer = () => {
    const [betAreaElements, setBetAreaElements] = useState<Array<JSX.Element>>(
        []
    );
    useEffect(() => {
        const bae = new Array<JSX.Element>();
        for (const betAreaType of RouletteBetAreaType2) {
            const ele = (
                <Box
                    sx={betAreaType.boxStyle}
                    key={`bet-2-${betAreaType.betType}`}
                >
                    <BetArea
                        style={betAreaType.style}
                        betType={betAreaType.betType}
                        confirmedChipScale={0.25}
                        pendingChipScale={0.2}
                        isEnableConfirmPanel={false}
                    />
                </Box>
            );
            bae.push(ele);
        }
        setBetAreaElements(bae);
    }, []);
    return (
        <Stack sx={{ width: '330px', height: '688px', position: 'relative' }}>
            <BaseImage
                className="roul_betarea roul_vert_betarea2_png"
                isMultiLang={true}
                scale={0.46}
            />
            {betAreaElements}
        </Stack>
    );
};
