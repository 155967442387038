import { BetType as BetTypeEnum } from '../../../../../models/games/teenpatti/BetType';
// import { LimitRed } from '../../../../main/slice/limitRed';
import { GAME_KEY } from '../../../../games/slice';
import { PHOST_KEY } from '../../../../host/slice';

import store from '../../../../../store/store';
import { Bet } from '../../../../../models/host/BetAmount';

const ShareLimitType = [
    BetTypeEnum.TPBTie,
    BetTypeEnum.TPBPlayerAPairPlus,
    BetTypeEnum.TPB6CardBonus,
    BetTypeEnum.TPBPlayerBPairPlus,
];

const calculateBetAmount = (
    bets: Array<Bet>,
    matchBetTypes: number
): number => {
    let amount = 0;
    amount = bets
        .filter(item => item.Type == matchBetTypes)
        .reduce((sum, bet) => sum + bet.Amount, 0);
    return amount;
};

const getPlayerAPendingBet = (): number => {
    const { pendingBets } = store.getState()[GAME_KEY];
    return calculateBetAmount(pendingBets, BetTypeEnum.TPBPlayerAWin);
};
const getPlayerAConfirmedBetted = (): number => {
    const { confirmedBets } = store.getState()[GAME_KEY];
    return calculateBetAmount(confirmedBets, BetTypeEnum.TPBPlayerAWin);
};
const getPlayerATotalBet = (): number => {
    return getPlayerAPendingBet() + getPlayerAConfirmedBetted();
};

const getPlayerBPendingBet = (): number => {
    const { pendingBets } = store.getState()[GAME_KEY];
    return calculateBetAmount(pendingBets, BetTypeEnum.TPBPlayerBWin);
};
const getPlayerBConfirmedBetted = (): number => {
    const { confirmedBets } = store.getState()[GAME_KEY];
    return calculateBetAmount(confirmedBets, BetTypeEnum.TPBPlayerBWin);
};
const getPlayerBTotalBet = (): number => {
    return getPlayerBPendingBet() + getPlayerBConfirmedBetted();
};

// const getTiePendingBet = (): number => {
//     const { pendingBets } = store.getState()[GAME_KEY];
//     return calculateBetAmount(
//         pendingBets,
//         BetTypeEnum.TPBTie
//     );
// };
// const getTieConfirmedBetted = (): number => {
//     const { confirmedBets } = store.getState()[GAME_KEY];
//     return calculateBetAmount(
//         confirmedBets,
//         BetTypeEnum.TPBTie
//     );
// };
// const getTieTotalBet = (): number => {
//     return getTiePendingBet() + getTieConfirmedBetted();
// };

const getTotalBetByBetTypes = (betType: number): number => {
    let amount = 0;
    const { pendingBets } = store.getState()[GAME_KEY];
    amount = pendingBets
        .filter(item => item.Type == betType)
        .reduce((sum, bet) => sum + bet.Amount, 0);

    const { confirmedBets } = store.getState()[GAME_KEY];
    amount += confirmedBets
        .filter(item => item.Type == betType)
        .reduce((sum, bet) => sum + bet.Amount, 0);

    return amount;
};

export const isOppositeBetting = (betType: number): boolean => {
    switch (betType) {
        case BetTypeEnum.TPBPlayerAWin:
            return getPlayerBTotalBet() > 0;
        case BetTypeEnum.TPBPlayerBWin:
            return getPlayerATotalBet() > 0;
        default:
            return false;
    }
};

const getBetLimitByBetType = (betType: number, hostId: number): number => {
    const host = store.getState()[PHOST_KEY].entities[hostId];
    let limit = 0;

    if (host && host.MaxBet) {
        const maxBet = host.MaxBet.find(mb => mb.BetType === betType);
        if (maxBet) {
            return maxBet.MaxBet;
        }
    }
    return limit;
};

export const amountOverBetLimit = (
    betType: number,
    hostId: number,
    chipAmount: number
): number => {
    let amount = 0;
    switch (betType) {
        case BetTypeEnum.TPBPlayerAWin:
            amount =
                chipAmount +
                getPlayerATotalBet() -
                getPlayerBTotalBet() -
                getBetLimitByBetType(betType, hostId);
            break;
        case BetTypeEnum.TPBPlayerBWin:
            amount =
                chipAmount +
                getPlayerBTotalBet() -
                getPlayerATotalBet() -
                getBetLimitByBetType(betType, hostId);
            break;
        default:
            for (let i = 0; i < ShareLimitType.length; i++) {
                if (ShareLimitType[i] == betType) {
                    amount =
                        chipAmount +
                        getTotalBetByBetTypes(ShareLimitType[i]) -
                        getBetLimitByBetType(betType, hostId);
                    break;
                }
            }
    }
    return amount;
};
