import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InGamePopupKeyType } from '../../../../hooks/useInGamePopup';
import { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { AudioPlayContext } from '../../../../contexts/AudioPlayContext';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import { RootState } from '../../../../store/store';
import { getHostById } from '../../../host/slice';
import { DragonTigerRecord } from '../../../../models/games/dragonTiger';
import { VoiceList } from '../../../../models/Voice';

export const DragonTigerGameResultPopup = (props: InGamePopupKeyType) => {
    const { amount, bg, message, audioKey, playSound } = props;
    const { t } = useTranslation();
    const { audioPlay } = useContext(AudioPlayContext);
    const { hostId } = useContext(VideoPlayerContext);
    const { Record, CurrentResult } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const ref = useRef<boolean>(false); //防止play 2 次
    useEffect(() => {
        if (!ref.current) {
            ref.current = true;
            if (audioKey && playSound) {
                let aKey = [];
                if (Record && CurrentResult) {
                    const gameId = CurrentResult.GameID;
                    const curRecord: DragonTigerRecord = Record.filter(
                        r => (r as DragonTigerRecord).gameID === gameId
                    )[0] as DragonTigerRecord;
                    if (
                        curRecord &&
                        curRecord.tigerPoint != undefined &&
                        curRecord.dragonPoint != undefined
                    ) {
                        const tp = VoiceList.TigerPoint.replace(
                            '{point}',
                            curRecord.tigerPoint.toString()
                        );
                        const dp = VoiceList.DragonPoint.replace(
                            '{point}',
                            curRecord.dragonPoint.toString()
                        );
                        if (curRecord.tigerWin) {
                            aKey.push(tp);
                            aKey.push(dp);
                        } else {
                            aKey.push(dp);
                            aKey.push(tp);
                        }
                    }
                }
                aKey.push(audioKey);
                audioPlay(aKey);
            }
        }
    }, []);

    return (
        <Stack
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
                position: 'absolute',
                height: 150,
                width: 400,
                top: 302,
                left: 70,
                right: 70,
                fontSize: 35,
                borderRadius: '4px',
                backgroundColor: bg,
                textAlign: 'center',
                lineHeight: 1.5,
                zIndex: theme => theme.zIndex.drawer + 80,
            }}
        >
            {t(message)}
            {amount && (
                <>
                    <Typography sx={{ fontSize: 28, marginLeft: '10px' }}>
                        {amount}
                    </Typography>
                </>
            )}
        </Stack>
    );
};
