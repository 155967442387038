import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BetRoomSeatIndex } from '../../../../models/games/BetRoom';
import {
    BlackjackBetContent,
    BlackjackBetType,
    BlackjackDecision,
    BlackjackResult,
} from '../../../../models/games/blackjack';
import { BetState } from '../../../../models/games/enums/BetState';
import { getMainUserState } from '../../../main/selector';
import { Bet } from '../../../report/slice/betRecord';

type BlackjackDetailContentRemarkProps = {
    bet: Bet;
};
export const BlackjackDetailContentRemark = ({
    bet,
}: BlackjackDetailContentRemarkProps) => {
    const { BetType, Result } = bet;
    const { PlayerID } = useSelector(getMainUserState);
    const [betContent, setBetContent] = useState<BlackjackBetContent>(
        BlackjackBetContent.SitInBet
    );
    const [remarkKey, setRemarkKey] = useState('');
    const { t } = useTranslation();
    useEffect(() => {
        if (BetType === BlackjackBetType.BJB_Insurance) {
            setBetContent(BlackjackBetContent.Insurance);
        } else {
            const blackjackResult = JSON.parse(Result) as BlackjackResult;
            const seat = ((BetType % 7) + 1) as BetRoomSeatIndex;
            const playerResult = blackjackResult.P.find(p => p.SN === seat);
            if (playerResult) {
                const isSideBet = playerResult.I !== PlayerID.toString();
                const decision = playerResult.D as BlackjackDecision;
                switch (Math.floor(bet.BetType / 7)) {
                    case 0: //split1
                        if (decision === BlackjackDecision.Surrender)
                            setBetContent(
                                isSideBet
                                    ? BlackjackBetContent.BetBehindSurrender
                                    : BlackjackBetContent.SitInSurrender
                            );
                        else
                            setBetContent(
                                isSideBet
                                    ? BlackjackBetContent.BetBehind
                                    : BlackjackBetContent.SitInBet
                            );
                        break;
                    case 1: //split2
                        setBetContent(
                            isSideBet
                                ? BlackjackBetContent.BetBehindSplit
                                : BlackjackBetContent.SitInSplit
                        );
                        break;
                    case 2: //double down
                        setBetContent(
                            isSideBet
                                ? BlackjackBetContent.BetBehindDoubleDown
                                : BlackjackBetContent.SitInDoubleDown
                        );
                        break;
                    case 3: //pair
                        setBetContent(
                            isSideBet
                                ? BlackjackBetContent.BetBehindPair
                                : BlackjackBetContent.SitInPair
                        );
                        break;
                    case 4: //insurance
                        setBetContent(BlackjackBetContent.Insurance);
                        break;
                }
            }
        }

        switch (bet.State) {
            case BetState.Won:
            case BetState.MWon:
                setRemarkKey('betlog.win');
                break;
            case BetState.Lost:
            case BetState.MLost:
                setRemarkKey('betlog.lose');
                break;
            case BetState.Refunded:
            case BetState.MRefunded:
                setRemarkKey('betlog.tie');
                break;
        }
    }, []);
    return (
        <>
            {t(`betlog.bet_blackjack.${betContent}`)} / {t(remarkKey)}
        </>
    );
};
