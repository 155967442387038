import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { SupportedGame } from '../../../modules/host/models';
import { hostsSliceActions } from '../../../modules/host/slice';
import {
    convertGoodRoad,
    convertResultToRecord,
} from '../../../utils/games/baccarat';
import { BaccaratResult } from '../../games/baccarat';
import { GameState } from '../../games/enums/GameState';
import { GameType } from '../../games/enums/GameType';
import { Poker } from '../../host/Poker';
import { CMDScInitBaccarat, ICommand } from '../live';
import { CMDBaccarat } from '../live/CMDBaccarat';
import { CMDLiveBaccaratData } from '../live/CMDLiveBaccaratData';
import { AbstractHandler } from './AbstractHandler';

export class CMDScInitBaccaratHandler extends AbstractHandler {
    _command: CMDScInitBaccarat;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScInitBaccarat;
    }
    override convertResult(result: CMDLiveBaccaratData): BaccaratResult {
        const newResult = Object.assign(
            this.convertBaseResult(result.BaseData.BaseResult),
            {
                PlayerCards: result.BaseData.playerCards,
                BankerCards: result.BaseData.bankerCards,
                PokerData: result.PokerData.map(
                    p =>
                        ({
                            SlotIndex: p.SlotIndex,
                            CardIndex: p.CardIndex,
                        }) as Poker
                ),
            }
        ) as BaccaratResult;
        return newResult;
    }
    convertPokerResult(result: CMDBaccarat): BaccaratResult {
        const newResult = Object.assign(
            this.convertBaseResult(result.BaseResult),
            {
                PlayerCards: result.playerCards,
                BankerCards: result.bankerCards,
            }
        ) as BaccaratResult;
        return newResult;
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const game = {} as SupportedGame;
        const info = this.convertInformation(this._command.Information);
        const hostId = info.HostId;
        game.CurrentState = this._command.CurrentState;
        game.CurrentResult = this.convertResult(this._command.CurrentResult);
        game.GoodRoad = convertGoodRoad(this._command.GoodRoad);
        game.OldResult = this._command.OldResult.map(o =>
            this.convertPokerResult(o)
        );
        game.OldResult = game.OldResult.filter(
            (arr, index, self) =>
                index === self.findIndex(t => t.GameID === arr.GameID)
        );

        game.ResultReleased = false;
        game.IsRest = this._command.Information.Rest != 0;

        const convertedRecord =
            game.CurrentState === GameState.Shuffle
                ? []
                : game.OldResult.map(r =>
                      convertResultToRecord(r as BaccaratResult, true)
                  );

        batch(() => {
            dispatch(hostsSliceActions.gameInit({ hostId, game }));
            dispatch(hostsSliceActions.updateInformation(info));
            dispatch(
                hostsSliceActions.updateRecord({
                    hostId,
                    record: convertedRecord,
                    gameType: GameType.Baccarat,
                })
            );
        });
    }
}
