import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { betPlayersSliceActions } from '../../../modules/bet/players/slice';
import { getMainUserState } from '../../../modules/main/selector';
import { playerSliceActions } from '../../../modules/main/slice/player';
import { promotionToolSliceActions } from '../../../modules/main/slice/promotiontool/promotionTool';
import { resultProgressSliceActions } from '../../../modules/progress/slice/result';
import store from '../../../store/store';
import { SyncData } from '../../SyncData';
import { Progress } from '../../games/Progress';
import { CMDScSyncData, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
export class CMDScSyncDataHandler extends AbstractHandler {
    _command: CMDScSyncData;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScSyncData;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const state = store.getState();
        const { PlayerID } = getMainUserState(state);
        const syncDataCommand = this._command;
        if (PlayerID && PlayerID === syncDataCommand.PlayerID) {
            dispatch(
                playerSliceActions.updatePlayerId(syncDataCommand.PlayerID)
            );
            syncDataCommand.Field.map(f => {
                const v = f.Value;
                switch (f.Index) {
                    case SyncData.LOW_BALANCE:
                        dispatch(playerSliceActions.updateBalanceLow(v));
                        break;
                    case SyncData.HIGH_BALANCE:
                        dispatch(playerSliceActions.updateBalanceHigh(v));
                        break;
                    case SyncData.LOW_HOLDING_MONEY:
                        dispatch(playerSliceActions.updateHoldingMoneyLow(v));
                        break;
                    case SyncData.HIGH_HOLDING_MONEY:
                        dispatch(playerSliceActions.updateHoldingMoneyHigh(v));
                        break;
                    case SyncData.LOW_CURRENCY_TYPE:
                        dispatch(playerSliceActions.updateCurrencyTypeLow(v));
                        break;
                    case SyncData.HIGH_CURRENCY_TYPE:
                        dispatch(playerSliceActions.updateCurrencyTypeHigh(v));
                        break;
                    case SyncData.LOW_AUTO_BET_BALANCE:
                        dispatch(playerSliceActions.updateAutoBetBalanceLow(v));
                        break;
                    case SyncData.HIGH_AUTO_BET_BALANCE:
                        dispatch(
                            playerSliceActions.updateAutoBetBalanceHigh(v)
                        );
                        break;
                    case SyncData.LOW_GAME_FEATURE:
                        dispatch(playerSliceActions.updateGameFeatureLow(v));
                        break;
                    case SyncData.HIGH_GAME_FEATURE:
                        dispatch(playerSliceActions.updateGameFeatureHigh(v));
                        break;
                    case SyncData.BALANCE_VALID:
                        dispatch(
                            playerSliceActions.updateBalanceValid(v === 1)
                        );
                        break;
                    case SyncData.ALLOW_TIPS:
                        dispatch(playerSliceActions.updateAllowTips(v === 1));
                        break;
                    //Promotion tool
                    case SyncData.EVENT_ID:
                        dispatch(promotionToolSliceActions.updateEventId(v));
                        break;
                    case SyncData.LOW_EVENT_POINT:
                        dispatch(
                            promotionToolSliceActions.updateEventPointLow(v)
                        );
                        break;
                    case SyncData.HIGH_EVENT_POINT:
                        dispatch(
                            promotionToolSliceActions.updateEventPointHigh(v)
                        );
                        break;
                }
            });
        } else {
            // other player balance
            syncDataCommand.Field.map(f => {
                const v = f.Value;
                switch (f.Index) {
                    // for blackjack
                    case SyncData.LOW_BALANCE:
                        dispatch(
                            betPlayersSliceActions.update({
                                playerId: syncDataCommand.PlayerID,
                                data: 'balance',
                                range: 'Low',
                                value: v,
                            })
                        );
                        break;
                    case SyncData.HIGH_BALANCE:
                        dispatch(
                            betPlayersSliceActions.update({
                                playerId: syncDataCommand.PlayerID,
                                data: 'balance',
                                range: 'High',
                                value: v,
                            })
                        );
                        break;
                    case SyncData.LOW_HOLDING_MONEY:
                        dispatch(
                            betPlayersSliceActions.update({
                                playerId: syncDataCommand.PlayerID,
                                data: 'holding',
                                range: 'Low',
                                value: v,
                            })
                        );
                        break;
                    case SyncData.HIGH_HOLDING_MONEY:
                        dispatch(
                            betPlayersSliceActions.update({
                                playerId: syncDataCommand.PlayerID,
                                data: 'holding',
                                range: 'High',
                                value: v,
                            })
                        );
                        break;
                    case SyncData.LOW_CURRENCY_TYPE:
                        dispatch(
                            betPlayersSliceActions.update({
                                playerId: syncDataCommand.PlayerID,
                                data: 'currencyType',
                                range: 'Low',
                                value: v,
                            })
                        );
                        break;
                    case SyncData.HIGH_CURRENCY_TYPE:
                        dispatch(
                            betPlayersSliceActions.update({
                                playerId: syncDataCommand.PlayerID,
                                data: 'currencyType',
                                range: 'High',
                                value: v,
                            })
                        );
                        break;
                }
            });
        }
        dispatch(resultProgressSliceActions.endLoad(Progress.SERVER_BALANCE));
    }
}
