import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { RootState } from '../../store/store';
import { getHostById } from '../host/slice';
import BaseImage from '../../components/common/baseImage';
import { ROAD_MAP_COLORS } from '../lobby/constants';
import { useTranslation } from 'react-i18next';
import { LocalState } from '../../models/games/enums/LocalState';
import { getLocalState } from '../../utils/games/blackjack';
import moment from 'moment';
import { AudioPlayContext } from '../../contexts/AudioPlayContext';
import { SoundList } from '../../models/Voice';
import { BaseTypography } from '../../components/common/baseText/BaseTypography';

const CountdownColor = ['0,148,97', '148,132,0', '148,0,0']; //009461, 948400, 940000
type BlackjackCountdownProps = {
    hostId: number;
};
export const BlackjackLobbyCountdown = (props: BlackjackCountdownProps) => {
    const { hostId } = props;
    const { t, i18n } = useTranslation();
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { IsRest, CurrentState, ResultReleased, RemainTime, CountDown } =
        host;
    const [totalTime, setTotalTime] = useState<number>(0);
    const [timer, setTimer] = useState<number>(-1);
    const Ref = useRef<NodeJS.Timeout>();
    const [stateLabelKey, setStateLabelKey] = useState<string>('');
    const [localState, setLocalState] = useState<number>(-1);
    const [stateColor, setStateColor] = useState<string>(
        ROAD_MAP_COLORS.DARK_GREEN
    );
    const [display, setDisplay] = useState('');

    const startTimer = () => {
        if (timer > 0) {
            const cTimer = timer - 1;
            setTimer(cTimer);
        }
    };

    const clearTimer = () => {
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer();
        }, 1000);
        Ref.current = id;
    };

    useEffect(() => {
        setLocalState(getLocalState(IsRest, CurrentState, ResultReleased));
    }, [IsRest, CurrentState, ResultReleased]);
    useEffect(() => {
        if (localState !== -1) {
            if (localState === LocalState.BETTING) {
                setStateLabelKey('game.betting');
                setStateColor(ROAD_MAP_COLORS.DARK_GREEN);
            } else {
                if (localState === LocalState.DEALING) {
                    setStateLabelKey('game.dealing');
                    setStateColor(ROAD_MAP_COLORS.DARK_GREEN);
                } else if (localState === LocalState.SHUFFLING) {
                    setStateLabelKey('game.shuffling');
                    setStateColor(ROAD_MAP_COLORS.DARK_GREEN);
                } else if (localState === LocalState.REST) {
                    setStateLabelKey('game.rest');
                    setStateColor(ROAD_MAP_COLORS.DARK_GREEN);
                } else {
                    setStateLabelKey('game.idle');
                    setStateColor(ROAD_MAP_COLORS.DARK_GREEN);
                }
            }
        } else {
            setStateLabelKey('');
        }
    }, [localState]);
    useEffect(() => {
        if (stateLabelKey === 'game.betting') {
            const curTimer = Math.max(0, Math.round((RemainTime - moment().valueOf()) / 1000));
            setTimer(curTimer);
            setDisplay(curTimer.toString().padStart(2, '0'));
            clearTimer();
        } else {
            setTimer(0);
        }
        return () => {
            if (Ref.current) clearInterval(Ref.current);
        };
    }, [stateLabelKey, timer, RemainTime, totalTime]);
    useEffect(() => {
        if(stateLabelKey !== 'game.betting'){
            setDisplay(t(stateLabelKey));
        }
    }, [stateLabelKey, i18n.language]);
    useEffect(() => {
        setTotalTime(Math.round((CountDown || 0) / 1000));
    }, [CountDown]);
    return (
        <>
            <Stack
                alignContent="center"
                justifyContent="center"
                alignItems="center"
                className="countdown-box"
                sx={{
                    backgroundColor: stateColor,
                    width: '88px',
                    height: '28px',
                }}
            >
                {timer >= 0 && (
                    <BaseTypography
                        fontSize={14}
                        resize={{ direction: 'horizontal', value: '86px' }}
                    >
                        {display}
                    </BaseTypography>
                )}
            </Stack>
        </>
    );
};

export const BlackjackInGameCountdown = (props: BlackjackCountdownProps) => {
    const { hostId } = props;
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { audioPlay } = useContext(AudioPlayContext);
    const { IsRest, CurrentState, ResultReleased, RemainTime, CountDown } =
        host;
    const [totalTime, setTotalTime] = useState<number>(0);
    const [timer, setTimer] = useState<number>(-1);
    const Ref = useRef<NodeJS.Timeout>();
    const [localState, setLocalState] = useState<number>(-1);

    const [countdownColor, setCountdownColor] = useState<string>(
        CountdownColor[0]
    );
    useEffect(() => {
        setLocalState(getLocalState(IsRest, CurrentState, ResultReleased));
    }, [IsRest, CurrentState, ResultReleased]);

    const startTimer = () => {
        if (timer > 0) {
            const cTimer = timer - 1;
            if (cTimer <= 5) {
                audioPlay(SoundList.Beep);
            }
            setTimer(cTimer);
            const rate = cTimer / totalTime;
            if (rate >= 0.67) {
                setCountdownColor(CountdownColor[0]);
            } else if (rate >= 0.34) {
                setCountdownColor(CountdownColor[1]);
            } else {
                setCountdownColor(CountdownColor[2]);
            }
        }
    };

    const clearTimer = () => {
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer();
        }, 1000);
        Ref.current = id;
    };

    useEffect(() => {
        const curTimer = Math.max(
            0,
            Math.round((RemainTime - moment().valueOf()) / 1000)
        );
        setTimer(curTimer);
        clearTimer();

        return () => {
            if (Ref.current) clearInterval(Ref.current);
        };
    }, [localState, timer, RemainTime, totalTime]);
    useEffect(() => {
        setTotalTime(Math.round((CountDown || 0) / 1000));
    }, [CountDown]);
    return (
        <>
            {timer >= 0 && (
                <Box
                    sx={{
                        position: 'relative',
                        display: 'inline-flex',
                        width: '90.092px', //scale 0.446 = 202*0.446
                        height: '90.092px',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress
                        variant="determinate"
                        size={80.092} //80.092 = 90.092-10
                        value={100 - ((totalTime - timer) / totalTime) * 100}
                        thickness={1}
                        sx={{
                            color: `rgba(${countdownColor})`,
                            zIndex: 1,
                            scale: '-1 1',
                        }}
                    />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '90.092px',
                            height: '90.092px',
                        }}
                    >
                        <Box
                            sx={{
                                width: '90.092px',
                                height: '90.092px',
                                position: 'absolute',
                            }}
                        >
                            <BaseImage
                                className="commonUI timer_base"
                                scale={0.892} //0.892 = 90.092/101
                            />
                        </Box>
                        <Typography
                            variant="caption"
                            component="div"
                            color="#ffffff"
                            fontSize={'50px'}
                            zIndex={1}
                        >
                            {timer}
                        </Typography>
                    </Box>
                </Box>
            )}
        </>
    );
};
