import { BetType as BetTypeEnum } from '../../../../../models/games/baccarat/BetType';
// import { LimitRed } from '../../../../main/slice/limitRed';
import { GAME_KEY } from '../../../../games/slice';
import { PHOST_KEY } from '../../../../host/slice';

import store from '../../../../../store/store';
import { Bet } from '../../../../../models/host/BetAmount';

const BankerBetType = [BetTypeEnum.BBBankerWin, BetTypeEnum.BBSSBankerWin];
const PlayerBetType = [BetTypeEnum.BBPlayerWin, BetTypeEnum.BBSSPlayerWin];
const ShareLimitType = [
    [BetTypeEnum.BBTie, BetTypeEnum.BBSSTie],
    [BetTypeEnum.BBSTotalCardSmall, BetTypeEnum.BBSSSTotalCardSmall],
    [BetTypeEnum.BBSTotalCardBig, BetTypeEnum.BBSSSTotalCardBig],
    [BetTypeEnum.BBSPlayerDragon, BetTypeEnum.BBSSSPlayerDragon],
    [BetTypeEnum.BBSBankerDragon, BetTypeEnum.BBSSSBankerDragon],
    [BetTypeEnum.BBSLuckySix, BetTypeEnum.BBSSSLuckySix],
    [BetTypeEnum.BBPlayerPair, BetTypeEnum.BBSSPlayerPair],
    [BetTypeEnum.BBBankerPair, BetTypeEnum.BBSSBankerPair],
    [BetTypeEnum.BBPlayerNatural, BetTypeEnum.BBSSPlayerNatural],
    [BetTypeEnum.BBBankerNatural, BetTypeEnum.BBSSBankerNatural],
];
const CowCowBankerBetType = [BetTypeEnum.BBCowBankerWin];
const CowCowPlayerBetType = [BetTypeEnum.BBCowPlayerWin];
const CowCowBetMultipleFactor = 9;

const calculateBetAmount = (
    bets: Array<Bet>,
    matchBetTypes: Array<number>,
    multipleBetTypes: Array<number>,
    multipleFactor: number
): number => {
    let amount = 0;
    bets.forEach(bet => {
        if (matchBetTypes.indexOf(bet.Type) > -1) {
            amount += bet.Amount;
        } else if (multipleBetTypes.indexOf(bet.Type) > -1) {
            amount += bet.Amount * multipleFactor;
        }
    });
    return amount;
};

const getBankerPendingBet = (): number => {
    const { pendingBets } = store.getState()[GAME_KEY];
    return calculateBetAmount(
        pendingBets,
        BankerBetType,
        CowCowBankerBetType,
        CowCowBetMultipleFactor
    );
};

const getBankerConfirmedBetted = (confirmedBets?: Array<Bet>): number => {
    const { confirmedBets: bets } = store.getState()[GAME_KEY];
    return calculateBetAmount(
        confirmedBets ? confirmedBets : bets,
        BankerBetType,
        CowCowBankerBetType,
        CowCowBetMultipleFactor
    );
};

const getBankerTotalBet = (confirmedBet?: Array<Bet>): number => {
    return getBankerPendingBet() + getBankerConfirmedBetted(confirmedBet);
};

const getPlayerPendingBet = (): number => {
    const { pendingBets } = store.getState()[GAME_KEY];
    return calculateBetAmount(
        pendingBets,
        PlayerBetType,
        CowCowPlayerBetType,
        CowCowBetMultipleFactor
    );
};

const getPlayerConfirmedBetted = (confirmedBets?: Array<Bet>): number => {
    const { confirmedBets: bets } = store.getState()[GAME_KEY];
    return calculateBetAmount(
        confirmedBets ? confirmedBets : bets,
        PlayerBetType,
        CowCowPlayerBetType,
        CowCowBetMultipleFactor
    );
};

const getPlayerTotalBet = (confirmedBets?: Array<Bet>): number => {
    return getPlayerPendingBet() + getPlayerConfirmedBetted(confirmedBets);
};

const getTotalBetByBetTypes = (betTypes: Array<number>): number => {
    let amount = 0;
    const { pendingBets } = store.getState()[GAME_KEY];
    pendingBets.forEach(bet => {
        if (betTypes.indexOf(bet.Type) > -1) {
            amount += bet.Amount;
        }
    });

    const { confirmedBets } = store.getState()[GAME_KEY];
    confirmedBets.forEach(bet => {
        if (betTypes.indexOf(bet.Type) > -1) {
            amount += bet.Amount;
        }
    });

    return amount;
};

// const getTotalBet = (): number => {
//     let amount = 0;

//     const { pendingBets } = store.getState()[GAME_KEY];
//     pendingBets.forEach(bet => amount += bet.amount);

//     const { confirmedBets } = store.getState()[GAME_KEY];
//     confirmedBets.forEach(bet => amount += bet.amount);

//     return amount;
// }

export const isOppositeBetting = (
    betType: number,
    confirmedBets?: Array<Bet>
): boolean => {
    switch (betType) {
        case BetTypeEnum.BBPlayerWin:
        case BetTypeEnum.BBSSPlayerWin:
        case BetTypeEnum.BBCowPlayerWin:
            return getBankerTotalBet(confirmedBets) > 0;
        case BetTypeEnum.BBBankerWin:
        case BetTypeEnum.BBSSBankerWin:
        case BetTypeEnum.BBCowBankerWin:
            return getPlayerTotalBet(confirmedBets) > 0;
        default:
            return false;
    }
};

const getBetLimitByBetType = (betType: number, hostId: number): number => {
    const host = store.getState()[PHOST_KEY].entities[hostId];
    let limit = 0;

    if (host && host.MaxBet) {
        const maxBet = host.MaxBet.find(mb => mb.BetType === betType);
        if (maxBet) {
            return maxBet.MaxBet;
        }
    }

    // TODO: user.getCurBetRule(this.gametype).minBet;
    return limit;
};

export const amountOverBetLimit = (
    betType: number,
    hostId: number,
    chipAmount: number,
    confirmedBets?: Array<Bet>
): number => {
    let amount = 0;
    switch (betType) {
        case BetTypeEnum.BBPlayerWin:
        case BetTypeEnum.BBSSPlayerWin:
            amount =
                chipAmount +
                getPlayerTotalBet(confirmedBets) -
                getBankerTotalBet(confirmedBets) -
                getBetLimitByBetType(betType, hostId);
            break;
        case BetTypeEnum.BBCowPlayerWin:
            amount =
                chipAmount +
                getPlayerTotalBet(confirmedBets) -
                getBankerTotalBet(confirmedBets) -
                getBetLimitByBetType(BetTypeEnum.BBPlayerWin, hostId);
            break;
        case BetTypeEnum.BBBankerWin:
        case BetTypeEnum.BBSSBankerWin:
            amount =
                chipAmount +
                getBankerTotalBet(confirmedBets) -
                getPlayerTotalBet(confirmedBets) -
                getBetLimitByBetType(betType, hostId);
            break;
        case BetTypeEnum.BBCowBankerWin:
            amount = amount =
                chipAmount +
                getBankerTotalBet(confirmedBets) -
                getPlayerTotalBet(confirmedBets) -
                getBetLimitByBetType(BetTypeEnum.BBBankerWin, hostId);
            break;
        default:
            for (let i = 0; i < ShareLimitType.length; i++) {
                if (ShareLimitType[i].indexOf(betType) > -1) {
                    amount =
                        chipAmount +
                        getTotalBetByBetTypes(ShareLimitType[i]) -
                        getBetLimitByBetType(betType, hostId);
                    return amount;
                }
            }
    }
    return amount;
};
