import { memo } from 'react';
import { ROAD_MAP_COLORS, ROAD_MAP_TYPE } from '../../../lobby/constants';
import {
    SedieRoadMapCellProps,
    CommonCellProps,
    useSedieRoadMapCellColorText,
} from '../hook';


const TieCircle = memo(function TieCircle({
    size,
}: CommonCellProps) {
    return (
        <circle
            cx={size * 0.2}
            cy={size * 0.2}
            r={size * 0.2}
            fill={ROAD_MAP_COLORS.GREEN}
            stroke={ROAD_MAP_COLORS.WHITE}
            strokeWidth={size * 0.05}
        />
    );
});
const BigRoadCell = (props: SedieRoadMapCellProps) => {
    const {
        record: { Win, WinPoint, LastDraw },
        size,
    } = props;

    const { color, text } = useSedieRoadMapCellColorText(ROAD_MAP_TYPE.BIG_SMALL_ROAD, WinPoint);

    let tieWin: boolean = LastDraw == true;


    return (
        <svg width={size} height={size}>
            {Win >= 0 && (
                <>
                    <circle
                        cx={size / 2}
                        cy={size / 2}
                        r={size / 2}
                        fill={color}
                    />
                    {tieWin && <TieCircle size={size} />}
                    <text
                        x={size / 2}
                        y={size / 2 + 2}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill="#ffffff"
                        fontSize={size * 0.75}
                    >
                        {text}
                    </text>

                </>
            )}
        </svg>
    );
};
export default memo(BigRoadCell);
