import { PokDengRecord, PokDengResult } from '../../models/games/pokdeng';
import { BetType as PokDengBetType } from '../../models/games/pokdeng/BetType';
import { GameResultState as PokDengGameResultState } from '../../models/games/pokdeng/GameState';
import { HandResult as PokDengHandResult } from '../../models/games/pokdeng/HandResult';
import { LocalState } from '../../models/games/enums/LocalState';
import { SupportedGameResult } from '../../modules/host/models';
import {
    DeckInfo,
    PokDengFullDeckInfo,
    PDJsonDeckObject,
} from '../../models/games/pokdeng/DeckInfo';
import { GameState } from '../../models/games/enums/GameState';
import { GameState as PokDengGameState } from '../../models/games/pokdeng/GameState';
import { GetPokerNumber, GetTotalBaccaratPoint } from '../pokerCount';
import { ByteArray } from '../../models/cmd';
import { BitArray } from '../../models/cmd/BitArray';
import { DeckResult } from '../../models/games/pokdeng/DeckInfo';
export const convertResultToRecord = (result: PokDengResult): PokDengRecord => {
    const record: PokDengRecord = {
        gameID: result.GameID,
        result: result.Result,
        shoes: Math.floor(result.GameCount),
        round: result.GameCount % 10000,
        gameResult: result,
    };

    return record;
};

export const parseDeckData = (resultString: string): PokDengFullDeckInfo => {
    const o = JSON.parse(resultString);
    const banker: DeckInfo = parseDeckInfo(0, o.B as PDJsonDeckObject);
    let players: Array<DeckInfo> = new Array<DeckInfo>();
    let n = 1;
    for (let p in o['P']) {
        if (n < 6) {
            players.push(parseDeckInfo(n++, o['P'][p] as PDJsonDeckObject));
        }
    }
    const full: PokDengFullDeckInfo = {
        bankerDeck: banker,
        playerDecks: players,
    };
    return full;
};

export const parseDeckInfo = (
    position: number,
    json: PDJsonDeckObject
): DeckInfo => {
    const info: DeckInfo = {
        position: position,
        card1: json?.PK && json.PK[0] != undefined ? json.PK[0] : -1,
        card2: json?.PK && json.PK[1] != undefined ? json.PK[1] : -1,
        handResult: json.R,
        point: GetPDPointFromPDHandResult(json.R),
    };
    return info;
};

export const GetPDCardsFlush = (cards: Array<number>): boolean => {
    const suit1: number =
        cards[0] !== undefined ? Math.floor(cards[0] / 13) : -1;
    const suit2: number =
        cards[1] !== undefined ? Math.floor(cards[1] / 13) : -2;
    if (suit1 == suit2) return true;
    return false;
};

export const GetPDCardsHandResult = (
    cards: Array<number>
): PokDengHandResult => {
    const PDSpecPair: Array<number> = [1, 5, 6, 10];
    if (cards.length != 2) return PokDengHandResult.NoResult;
    if (cards[0] == -1 && cards[1] == -1) return PokDengHandResult.NoResult;
    const suit1: number = cards[0] >= 0 ? Math.floor(cards[0] / 13) : -1;
    const num1: number = cards[0] >= 0 ? GetPokerNumber(cards[0]) : 0;
    const suit2: number = cards[1] >= 0 ? Math.floor(cards[1] / 13) : -2;
    const num2: number = cards[1] >= 0 ? GetPokerNumber(cards[1]) : 0;

    if (num1 == num2 && PDSpecPair.indexOf(num1) >= 0) {
        return PokDengHandResult.Point7Dot4;
    }
    if (suit1 == suit2) {
        if ((num1 == 1 && num2 == 13) || (num2 == 1 && num1 == 13)) {
            return PokDengHandResult.Point7Dot5;
        }
        if (num1 + num2 == 10) {
            return PokDengHandResult.Point7Dot1;
        }
    }
    if (num1 >= 11 && num2 >= 11) {
        return PokDengHandResult.Point7Dot3;
    }
    if ((num1 == 10 && num2 >= 11) || (num2 == 10 && num1 >= 11)) {
        return PokDengHandResult.Point7Dot2;
    }
    switch (GetTotalBaccaratPoint(cards)) {
        case 0:
            return PokDengHandResult.Point0;
        case 1:
            return PokDengHandResult.Point1;
        case 2:
            return PokDengHandResult.Point2;
        case 3:
            return PokDengHandResult.Point3;
        case 4:
            return PokDengHandResult.Point4;
        case 5:
            return PokDengHandResult.Point5;
        case 6:
            return PokDengHandResult.Point6;
        case 7:
            return PokDengHandResult.Point7;
        case 8:
            return PokDengHandResult.Point8;
        case 9:
            return PokDengHandResult.Point9;
    }
    return PokDengHandResult.NoResult;
};

export const GetPDPointFromPDHandResult = (
    result: PokDengHandResult
): number => {
    let nums = [0, 0, 1, 2, 3, 4, 5, 6, 7, 7.1, 7.2, 7.3, 7.4, 7.5, 8, 9];
    return nums[result];
};

export const convertPDResultStringToRecord = (
    currentResult: SupportedGameResult,
    resultString: string,
    FResult: string,
    result?: number
): PokDengRecord => {
    const { GameID, Result, GameCount } = currentResult;
    const deckInfo = parseDeckData(resultString);
    const updateResult: PokDengResult = {
        FResult: FResult,
        GameCount: GameCount,
        GameID: GameID,
        fullDeck: deckInfo,
        Result: result ?? Result,
    };
    const resultRecord = convertResultToRecord(updateResult);
    return resultRecord;
};
export const convertPDFResultToDetail = (
    FResult: string
): Array<DeckResult> => {
    let value = BigInt(FResult);
    const ba: ByteArray = new ByteArray();
    ba.writeUint64(value);
    ba.position = 0;
    const bits = new BitArray(ba);
    let results: Array<DeckResult> = [];

    results.push({
        handResult: bits.readNumber(7),
        resultState: 0,
        isPair: bits.readBoolean(),
    });

    for (let i = 0; i < 5; i++) {
        results.push({
            handResult: bits.readNumber(7),
            resultState: bits.readNumber(2),
            isPair: bits.readBoolean(),
        });
    }
    return results;
};
export const isPDBetTypeWin = (
    BetType: number,
    resultDetail: Array<DeckResult>
) => {
    switch (BetType) {
        case PokDengBetType.PDBPlayer1Win:
            return (
                resultDetail[1].resultState ==
                PokDengGameResultState.PDRS_PlayerWin
            );
        case PokDengBetType.PDBPlayer2Win:
            return (
                resultDetail[2].resultState ==
                PokDengGameResultState.PDRS_PlayerWin
            );
        case PokDengBetType.PDBPlayer3Win:
            return (
                resultDetail[3].resultState ==
                PokDengGameResultState.PDRS_PlayerWin
            );
        case PokDengBetType.PDBPlayer4Win:
            return (
                resultDetail[4].resultState ==
                PokDengGameResultState.PDRS_PlayerWin
            );
        case PokDengBetType.PDBPlayer5Win:
            return (
                resultDetail[5].resultState ==
                PokDengGameResultState.PDRS_PlayerWin
            );
        case PokDengBetType.PDBPlayer1Pair:
            return resultDetail[1].isPair;
        case PokDengBetType.PDBPlayer2Pair:
            return resultDetail[2].isPair;
        case PokDengBetType.PDBPlayer3Pair:
            return resultDetail[3].isPair;
        case PokDengBetType.PDBPlayer4Pair:
            return resultDetail[4].isPair;
        case PokDengBetType.PDBPlayer5Pair:
            return resultDetail[5].isPair;
    }
    return false;
};

export const getLocalState = (
    isRest: boolean,
    state: number,
    isGameResultReleased: boolean
) => {
    if (isRest) {
        return LocalState.REST;
    }
    switch (state) {
        case GameState.RoundCancel:
            return LocalState.ROUND_CANCEL;
        case GameState.Idle:
            return LocalState.IDLE;
        case GameState.Shuffle:
            return LocalState.SHUFFLING;
        case GameState.Betting:
            return LocalState.BETTING;
        case GameState.Started:
        case PokDengGameState.WaitPlayer1Card1:
        case PokDengGameState.WaitPlayer1Card2:
        case PokDengGameState.WaitPlayer2Card1:
        case PokDengGameState.WaitPlayer2Card2:
        case PokDengGameState.WaitPlayer3Card1:
        case PokDengGameState.WaitPlayer3Card2:
        case PokDengGameState.WaitPlayer4Card1:
        case PokDengGameState.WaitPlayer4Card2:
        case PokDengGameState.WaitPlayer5Card1:
        case PokDengGameState.WaitPlayer5Card2:
        case GameState.GameResult:
            if (isGameResultReleased) {
                return LocalState.RESULT;
            }
            return LocalState.DEALING;
    }
    return LocalState.IDLE;
};
