import { Backdrop, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BetChipContext } from '../../../../../contexts/BetChipContext';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import { BlackjackBetType } from '../../../../../models/games/blackjack';
import { BetTypeMaxBet } from '../../../../../models/host/MaxBet';
import { RootState } from '../../../../../store/store';
import { getHostById } from '../../../../host/slice';
import { getMaxBetByBetType } from '../../TableInfoPanel/util';
import { PopupButtonProps } from './PopupButton';

type PopupPanelProps = {
    isOpen: boolean;
} & PopupButtonProps;
export const PopupPanel = ({ isOpen, onClickHandler }: PopupPanelProps) => {
    const { hostId } = useContext(VideoPlayerContext);
    const { MaxBet, Multiplier } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const { selectedBetRule } = useContext(BetChipContext);
    const currentMaxBet = useMemo(() => MaxBet ?? [], [MaxBet]);
    const [minBet, setMinBet] = useState(1);
    const [multiplier, setMultiplier] = useState(1);
    const { t } = useTranslation();
    useEffect(() => {
        if (selectedBetRule) {
            setMinBet(selectedBetRule.MinBet);
        }
    }, [selectedBetRule]);
    useEffect(() => {
        if (Multiplier !== undefined) {
            setMultiplier(Multiplier);
        }
    }, [Multiplier]);
    return (
        <>
            <Backdrop
                className={'blackjack-bet-limit-backdrop'}
                sx={{
                    zIndex: theme => theme.zIndex.drawer + 1,
                    width: '100%',
                    backgroundColor: 'rgba(0,0,0,0.9)',
                }}
                open={isOpen}
            >
                <Grid container justifyContent={'center'}>
                    <Typography color={'white'} variant="h3">
                        {t('betLimit2')}
                    </Typography>
                    <Grid container sx={{ marginX: '40px', marginY: '80px' }}>
                        <PanelHeader title={'blackjack.per_hand'} />
                        <PanelLimitInfo
                            title={'blackjack.seat'}
                            betType={BlackjackBetType.BJB1_Split1}
                            maxBet={currentMaxBet}
                            minBet={minBet}
                            multiplier={multiplier}
                        />
                        <PanelLimitInfo
                            title={'betlog.bet_blackjack.6'}
                            betType={BlackjackBetType.BJB1_Split1}
                            maxBet={currentMaxBet}
                            minBet={minBet}
                            multiplier={1}
                        />
                        <PanelHeader title={'blackjack.side_bet'} />
                        <PanelLimitInfo
                            title={'betlog.bet_blackjack.4'}
                            betType={BlackjackBetType.BJB1_Pair}
                            maxBet={currentMaxBet}
                            minBet={minBet}
                            multiplier={1}
                        />
                    </Grid>
                    {/* close button */}
                    <svg
                        width="82"
                        height="82"
                        viewBox="0 0 164 164"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => onClickHandler(false)}
                    >
                        <g filter="url(#filter0_d_449_742671)">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M82.7314 0C127.614 0 164 36.713 164 80.524C164 127.287 127.614 164 82.7314 164C37.8484 164 0 127.287 0 80.524C0 36.713 37.8484 0 82.7314 0Z"
                                fill="black"
                                fillOpacity="0.1"
                                shapeRendering="crispEdges"
                            />
                        </g>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M82.5089 6.56C123.801 6.56 157.276 40.336 157.276 80.6421C157.276 123.664 123.801 157.44 82.5089 157.44C41.2165 157.44 6.396 123.664 6.396 80.6421C6.396 40.336 41.2165 6.56 82.5089 6.56Z"
                            fill="#876F4D"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M144.156 80.524C144.156 47.6305 116.302 19.68 81.8358 19.68C47.3695 19.68 19.5158 47.6305 19.5158 80.524C19.5158 116.466 47.4656 144.32 81.8358 144.32C116.206 144.32 144.156 116.466 144.156 80.524ZM147.436 80.524C147.436 45.7708 118.065 16.4 81.8358 16.4C45.6066 16.4 16.2358 45.7708 16.2358 80.524C16.2358 118.229 45.6066 147.6 81.8358 147.6C118.065 147.6 147.436 118.229 147.436 80.524Z"
                            fill="white"
                        />
                        <g clipPath="url(#clip0_449_742671)">
                            <path
                                d="M100.04 69.0604L96.5797 65.6L82.8201 79.3596L69.0605 65.6L65.6001 69.0604L79.3597 82.82L65.6001 96.5796L69.0605 100.04L82.8201 86.2804L96.5797 100.04L100.04 96.5796L86.2805 82.82L100.04 69.0604Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <filter
                                id="filter0_d_449_742671"
                                x="-22"
                                y="-6"
                                width="200"
                                height="200"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                            >
                                <feFlood
                                    floodOpacity="0"
                                    result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                />
                                <feOffset dx="-4" dy="12" />
                                <feGaussianBlur stdDeviation="9" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0"
                                />
                                <feBlend
                                    mode="multiply"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_449_742671"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_449_742671"
                                    result="shape"
                                />
                            </filter>
                            <clipPath id="clip0_449_742671">
                                <rect
                                    width="34.44"
                                    height="34.44"
                                    fill="white"
                                    transform="translate(65.6001 65.6)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </Grid>
            </Backdrop>
        </>
    );
};

type PanelHeaderProps = {
    title: string;
};
const PanelHeader = ({ title }: PanelHeaderProps) => {
    const { t } = useTranslation();
    return (
        <Grid item xs={12} container justifyContent={'center'}>
            <Typography
                variant="h5"
                color={theme => theme.palette.primary.main}
            >
                {t(title).replace('\n', ' ')}
            </Typography>
        </Grid>
    );
};
type PanelLimitInfoProps = {
    title: string;
    betType: BlackjackBetType;
    maxBet: Array<BetTypeMaxBet>;
    minBet: number;
    multiplier: number;
};
const PanelLimitInfo = ({
    title,
    betType,
    maxBet,
    minBet,
    multiplier,
}: PanelLimitInfoProps) => {
    const { t } = useTranslation();
    const maxLimit = getMaxBetByBetType(betType, maxBet);
    return (
        <Grid item xs={12}>
            <Grid container direction={'row'} justifyContent={'space-between'}>
                <Typography
                    variant="h6"
                    color={theme => theme.palette.primary.main}
                    textTransform={'none'}
                >
                    {t(title)}
                </Typography>
                <Typography variant="h6" textTransform={'none'}>
                    {(minBet * multiplier) / 100} - {maxLimit}
                </Typography>
            </Grid>
        </Grid>
    );
};
