import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import BaseImage from '../../../components/common/baseImage';
import {
    getAllConfirmedBetAmount,
    getAllWithHoldBetAmount,
} from '../../games/selector';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GameType } from '../../../models/games/enums/GameType';

type Prop = {
    gameType: GameType;
};
export const TotalBetAmount = (prop: Prop) => {
    const { gameType } = prop;
    const totalBetAmount = useSelector(getAllConfirmedBetAmount);
    const withHoldAmount = useSelector(getAllWithHoldBetAmount);
    const { t } = useTranslation();
    const [amount, setAmount] = useState(0);
    const [haveWithHold, setHaveWithHold] = useState(false);
    useEffect(() => {
        setAmount(totalBetAmount / 100);
    }, [totalBetAmount]);
    useEffect(() => {
        setHaveWithHold(gameType === GameType.PokDeng);
    }, [gameType]);

    return (
        <>
            <Stack
                direction={'row'}
                flexWrap={'nowrap'}
                alignContent={'center'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                sx={{
                    fontSize: '18px',
                    '& div': {
                        marginRight: '5px',
                    },
                }}
            >
                <BaseImage className="commonUI icon_totalbet" />
                {amount.toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}
                {haveWithHold && (
                    <>
                        {' ('}
                        {t('game.withHold')}
                        {withHoldAmount.toLocaleString('en-US', {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })}
                        {')'}
                    </>
                )}
            </Stack>
        </>
    );
};
