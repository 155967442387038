import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GameType as GameTypeEnum } from '../../models/games/enums/GameType';
export const LOBBY_KEY = 'app::lobby';
export type HallGameTypeMap = {
    hostId: number;
    hallId?: number;
    gameType: GameTypeEnum;
};
type LobbyState = {
    hallGameTypeMap: Array<HallGameTypeMap>;
};
const updateHallGameTypeMap = (
    state: LobbyState,
    action: PayloadAction<Array<HallGameTypeMap>>
) => {
    state.hallGameTypeMap = action.payload;
};
const lobbySlice = createSlice({
    name: LOBBY_KEY,
    initialState: {} as LobbyState,
    reducers: {
        updateHallGameTypeMap,
    },
});
export const lobbySliceReducer = lobbySlice.reducer;
export const lobbySliceActions = lobbySlice.actions;
