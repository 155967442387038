import { Button } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseImage from '../../../components/common/baseImage';
import { RootState } from '../../../store/store';
import ShuffleLabel from '../../game/components/ShuffleLabel';
import TrialImage from '../../game/components/TrialImage';
import { ROAD_MAP_TYPE } from '../../lobby/constants';
import BigEyeAnimatedCell from '../baccarat/cells/BigEyeAnimatedCell';
import BigEyeCell from '../baccarat/cells/BigEyeCell';
import BigRoadCell from '../baccarat/cells/BigRoadCell';
import CockroachRoadAnimatedCell from '../baccarat/cells/CockroachRoadAnimatedCell';
import CockroachRoadCell from '../baccarat/cells/CockroachRoadCell';
import SmallRoadAnimatedCell from '../baccarat/cells/SmallRoadAnimatedCell';
import SmallRoadCell from '../baccarat/cells/SmallRoadCell';
import { useRoadMapState } from '../baccarat/hook';
import { AskRoadButton } from '../baccarat/map';
import { getAskIdx, getNextRoad, getViewRoadRecord } from '../baccarat/util';
import '../index.scss';
import { getRoadmap } from '../slice';
import DTBeadRoadCell from './cell/DTBeadRoadCell';
import './dragonTiger.scss';
import { useDragonTigerStatistics } from './hook';
type DragonTigerRoadMapProps = {
    hostId: number;
};
export const DragonTigerRoadMap = (props: DragonTigerRoadMapProps) => {
    const { hostId } = props;
    const { isOneColumn, IsRest } = useRoadMapState(hostId);
    const [bigRoadCells, setBigRoadCells] = useState<Array<JSX.Element>>([]);
    const [bigEyeCells, setBigEyeCells] = useState<Array<JSX.Element>>([]);
    const [smallCells, setSmallCells] = useState<Array<JSX.Element>>([]);
    const [cockroachCells, setCockroachCells] = useState<Array<JSX.Element>>(
        []
    );

    const { BigRoad, BigEyeRoad, SmallRoad, CockroachRoad } = useSelector(
        (state: RootState) => getRoadmap(state, hostId)
    );
    const maxBigGrid = useMemo(() => (isOneColumn ? 33 : 16), [isOneColumn]);
    const maxBigEyeGrid = useMemo(() => (isOneColumn ? 66 : 0), [isOneColumn]);
    const maxSmallGrid = useMemo(() => (isOneColumn ? 33 : 0), [isOneColumn]);
    const maxCockroachGrid = useMemo(
        () => (isOneColumn ? 33 : 0),
        [isOneColumn]
    );
    const containerClass = useMemo(
        () =>
            isOneColumn
                ? 'dragon-tiger one-column-main-container'
                : 'dragon-tiger two-column-main-container',
        [isOneColumn]
    );
    const roadMapClass = useMemo(
        () => (isOneColumn ? 'road_viewlist' : 'road_viewColumn'),
        [isOneColumn]
    );
    useEffect(() => {
        if (!IsRest && maxBigGrid > 0) {
            const list = getViewRoadRecord(BigRoad, maxBigGrid);
            const brc = list?.map((record, idx) => {
                const key = `big-road-cell-${idx}`;
                return <BigRoadCell record={record} key={key} />;
            });
            setBigRoadCells(brc);
        } else {
            setBigRoadCells([]);
        }
    }, [BigRoad, IsRest, maxBigGrid]);
    useEffect(() => {
        if (!IsRest && maxBigEyeGrid > 0) {
            const list = getViewRoadRecord(BigEyeRoad, maxBigEyeGrid);
            const bigEyec = list.map((record, idx) => {
                const key = `big-eye-cell-${idx}`;
                return <BigEyeCell win={record.Win} key={key} />;
            });
            setBigEyeCells(bigEyec);
        } else {
            setBigEyeCells([]);
        }
    }, [BigEyeRoad, IsRest, maxBigEyeGrid]);
    useEffect(() => {
        if (!IsRest && maxSmallGrid > 0) {
            const list = getViewRoadRecord(SmallRoad, maxSmallGrid);
            const smallc = list.map((record, idx) => {
                const key = `small-road-cell-${idx}`;
                return <SmallRoadCell win={record.Win} key={key} />;
            });

            setSmallCells(smallc);
        } else {
            setSmallCells([]);
        }
    }, [SmallRoad, IsRest, maxSmallGrid]);
    useEffect(() => {
        if (!IsRest && maxCockroachGrid > 0) {
            const list = getViewRoadRecord(CockroachRoad, maxCockroachGrid);
            const cockroachc = list.map((record, idx) => {
                const key = `cockroach-road-cell-${idx}`;
                return <CockroachRoadCell win={record.Win} key={key} />;
            });
            setCockroachCells(cockroachc);
        } else {
            setCockroachCells([]);
        }
    }, [CockroachRoad, IsRest, maxCockroachGrid]);

    return (
        <div className={containerClass}>
            <BaseImage className={`lobby ${roadMapClass}`} scale={1} />
            <div className="bigRoad">
                <div className="bigRoad">{bigRoadCells}</div>
            </div>
            {isOneColumn && (
                <>
                    <div className="bigEyeRoad">{bigEyeCells}</div>
                    <div className="smallRoad">{smallCells}</div>
                    <div className="cockroachRoad">{cockroachCells}</div>
                </>
            )}
        </div>
    );
};

export const DragonTigerSwitchTableMap = (props: DragonTigerRoadMapProps) => {
    const { hostId } = props;
    const { IsRest } = useRoadMapState(hostId);
    const { BigRoad } = useSelector((state: RootState) =>
        getRoadmap(state, hostId)
    );

    const [bigRoadCells, setBigRoadCells] = useState<JSX.Element>(<></>);
    useEffect(() => {
        if (!IsRest) {
            const list = getViewRoadRecord(BigRoad, 10);
            const brc = list?.map((record, idx) => (
                <BigRoadCell record={record} key={`big-road-cell-${idx}`} />
            ));
            setBigRoadCells(<>{brc}</>);
        } else {
            setBigRoadCells(<></>);
        }
    }, [BigRoad, IsRest]);

    return (
        <div className="SwitchTableMapMainContainer">
            <BaseImage
                className="tradbaccRoadmapBg hori_allroad_ver2_android"
                scale={1}
            />
            {/* <div className="bigEyeRoad"> */}
            <div className="bigRoad">{bigRoadCells}</div>
            {/* </div> */}
        </div>
    );
};

export const DragonTigerInGameRoadMap = (props: DragonTigerRoadMapProps) => {
    const { hostId } = props;
    const { t } = useTranslation();
    const { Bead, Other, BigRoad, BigEyeRoad, SmallRoad, CockroachRoad } =
        useSelector((state: RootState) => getRoadmap(state, hostId));
    // const { dragonTotal, tigerTotal, tieTotal } = Summary;
    const { dragon, tiger, tie } = useDragonTigerStatistics(hostId);
    const { askType, setAskType, IsRest } = useRoadMapState(hostId);
    const [bigEyeNextRoad, setBigEyeNextRoad] = useState(0);
    const [smallNextRoad, setSmallNextRoad] = useState(0);
    const [cockroachNextRoad, setCockroachNextRoad] = useState(0);
    const askRoadTimeout = useRef<NodeJS.Timeout>();

    useEffect(() => {
        const ber = getNextRoad(Bead, Other, ROAD_MAP_TYPE.BIG_EYE_ROAD);
        const sr = getNextRoad(Bead, Other, ROAD_MAP_TYPE.SMALL_ROAD);
        const cr = getNextRoad(Bead, Other, ROAD_MAP_TYPE.COCKROACH_ROAD);

        setBigEyeNextRoad(ber);
        setSmallNextRoad(sr);
        setCockroachNextRoad(cr);
    }, [Bead, Other]);

    const [bigCells, setBigCells] = useState<Array<JSX.Element>>([]);
    const [bigEyeCells, setBigEyeCells] = useState<Array<JSX.Element>>([]);
    const [smallCells, setSmallCells] = useState<Array<JSX.Element>>([]);
    const [cockroachCells, setCockroachCells] = useState<Array<JSX.Element>>(
        []
    );
    useEffect(() => {
        if (!IsRest) {
            const list = getViewRoadRecord(BigRoad, 37);
            let askIdx = getAskIdx(list, askType);
            let toFill = 0;

            if (askIdx >= list.length) {
                toFill = askIdx - list.length + 1;
            }

            const bc = list?.map((record, idx) => {
                const key = `big-road-cell-${idx}`;
                if (askIdx === idx) {
                    return (
                        <BigRoadCell
                            record={{ ...record, AskType: askType }}
                            key={key}
                        />
                    );
                } else {
                    return <BigRoadCell record={record} key={key} />;
                }
            });
            for (let i = 0; i < toFill; i++) {
                const key = `big-road-cell-${bc.length}`;

                bc.push(
                    <BigRoadCell
                        record={{
                            Win: -1,
                            BankerPair: false,
                            PlayerPair: false,
                            WinPoint: -1,
                            AskType: bc.length == askIdx ? askType : -1,
                        }}
                        key={key}
                    />
                );
            }
            setBigCells(bc);
        } else {
            setBigCells([]);
        }
    }, [BigRoad, IsRest, askType]);
    useEffect(() => {
        if (!IsRest) {
            const list = getViewRoadRecord(BigEyeRoad, 80);
            let color = -1;

            if (bigEyeNextRoad == 0) {
                color = askType == 0 ? 1 : 0;
            } else if (bigEyeNextRoad == 1) {
                color = askType == 0 ? 0 : 1;
            }
            const askIdx = askType < 0 ? -1 : getAskIdx(list, color);
            let toFill = 0;

            if (askIdx >= list.length) {
                toFill = askIdx - list.length + 1;
            }
            const bc = list?.map((record, idx) => {
                const key = `big-eye-cell-${idx}`;
                if (askIdx === idx) {
                    return <BigEyeAnimatedCell win={color} key={key} />;
                } else {
                    return <BigEyeCell win={record.Win} key={key} />;
                }
            });
            for (let i = 0; i < toFill; i++) {
                const key = `big-eye-cell-${bc.length}`;
                bc.push(
                    bc.length == askIdx ? (
                        <BigEyeAnimatedCell win={color} key={key} />
                    ) : (
                        <BigEyeCell win={-1} key={key} />
                    )
                );
            }
            setBigEyeCells(bc);
        } else {
            setBigEyeCells([]);
        }
    }, [BigEyeRoad, IsRest, askType]);
    useEffect(() => {
        if (!IsRest) {
            const list = getViewRoadRecord(SmallRoad, 36);
            let color = -1;

            if (smallNextRoad == 0) {
                color = askType == 0 ? 1 : 0;
            } else if (smallNextRoad == 1) {
                color = askType == 0 ? 0 : 1;
            }
            const askIdx = askType < 0 ? -1 : getAskIdx(list, color);
            let toFill = 0;

            if (askIdx >= list.length) {
                toFill = askIdx - list.length + 1;
            }
            const sc = list?.map((record, idx) => {
                const key = `small-road-cell-${idx}`;
                if (askIdx === idx) {
                    return <SmallRoadAnimatedCell win={color} key={key} />;
                } else {
                    return <SmallRoadCell win={record.Win} key={key} />;
                }
            });
            for (let i = 0; i < toFill; i++) {
                const key = `small-road-cell-${sc.length}`;
                sc.push(
                    sc.length == askIdx ? (
                        <SmallRoadAnimatedCell win={color} key={key} />
                    ) : (
                        <SmallRoadCell win={-1} key={key} />
                    )
                );
            }
            setSmallCells(sc);
        } else {
            setSmallCells([]);
        }
    }, [SmallRoad, IsRest, askType]);
    useEffect(() => {
        if (!IsRest) {
            const list = getViewRoadRecord(CockroachRoad, 36);
            let color = -1;

            if (cockroachNextRoad == 0) {
                color = askType == 0 ? 1 : 0;
            } else if (cockroachNextRoad == 1) {
                color = askType == 0 ? 0 : 1;
            }
            const askIdx = askType < 0 ? -1 : getAskIdx(list, color);
            let toFill = 0;

            if (askIdx >= list.length) {
                toFill = askIdx - list.length + 1;
            }
            const cc = list?.map((record, idx) => {
                const key = `cockroach-road-cell-${idx}`;
                if (askIdx === idx) {
                    return <CockroachRoadAnimatedCell win={color} key={key} />;
                } else {
                    return <CockroachRoadCell win={record.Win} key={key} />;
                }
            });
            for (let i = 0; i < toFill; i++) {
                const key = `cockroach-road-cell-${cc.length}`;
                cc.push(
                    cc.length == askIdx ? (
                        <CockroachRoadAnimatedCell win={color} key={key} />
                    ) : (
                        <CockroachRoadCell win={-1} key={key} />
                    )
                );
            }
            setCockroachCells(cc);
        } else {
            setCockroachCells([]);
        }
    }, [CockroachRoad, IsRest, askType]);
    useEffect(() => {
        if (askRoadTimeout.current) {
            clearTimeout(askRoadTimeout.current);
        }
        askRoadTimeout.current = setTimeout(() => {
            setAskType(-1);
        }, 1000);
    }, [askType]);
    const onAskTiger = () => {
        setAskType(0);
    };
    const onAskDragon = () => {
        setAskType(1);
    };
    return (
        <>
            <div className="dragon-tiger-in-game-main-container">
                <BaseImage
                    className="tradbaccRoadmapBg bg_roadmap"
                    scale={0.5}
                />
                <div className="bigRoad">{bigCells}</div>
                <div className="bigEyeRoad">{bigEyeCells}</div>
                <div className="smallRoad">{smallCells}</div>
                <div className="cockroachRoad">{cockroachCells}</div>
                <div className="ask-road">
                    <div className="summary-list">
                        <div className="dr">{t('road.dragon_win')}</div>
                        {dragon}
                        <div className="tr">{t('road.tiger_win')}</div>
                        {tiger}
                        <div className="t">{t('road.draw')}</div>
                        {tie}
                    </div>
                    <div className="askBtn">
                        <Button onClick={onAskDragon} sx={{ padding: 0 }}>
                            <AskRoadButton
                                isBanker={false}
                                btnText={'road.ask_dragon'}
                                getBigEyeNextRoad={bigEyeNextRoad}
                                getSmallNextRoad={smallNextRoad}
                                getCockroachNextRoad={cockroachNextRoad}
                            />
                        </Button>
                        <Button onClick={onAskTiger} sx={{ padding: 0 }}>
                            <AskRoadButton
                                isBanker={true}
                                btnText={'road.ask_tiger'}
                                getBigEyeNextRoad={bigEyeNextRoad}
                                getSmallNextRoad={smallNextRoad}
                                getCockroachNextRoad={cockroachNextRoad}
                            />
                        </Button>
                    </div>
                </div>
            </div>
            <TrialImage />
            <ShuffleLabel hostId={hostId} />
        </>
    );
};
export const DragonTigerInGameMarkerRoadRoadMap = (
    props: DragonTigerRoadMapProps
) => {
    const { hostId } = props;
    const { t } = useTranslation();
    const { IsRest } = useRoadMapState(hostId);
    const { BeadRoad } = useSelector((state: RootState) =>
        getRoadmap(state, hostId)
    );
    const [beadCells, setBeadCells] = useState<Array<JSX.Element>>([]);

    // const { dragonTotal, tigerTotal, tieTotal } = Summary;
    const { dragon, tiger, tie } = useDragonTigerStatistics(hostId);
    useEffect(() => {
        if (!IsRest) {
            const bc = BeadRoad?.map((record, idx) => {
                const key = `bead-road-cell-${idx}`;
                return <DTBeadRoadCell record={record} key={key} />;
            });
            setBeadCells(bc);
        } else {
            setBeadCells([]);
        }
    }, [BeadRoad, IsRest]);

    return (
        <>
            <div className="dragon-tiger-in-game-main-container">
                <BaseImage
                    className="tradbaccRoadmapBg bg_roadmap-markerroad"
                    scale={0.5}
                />
                <div className="beadRoad">{beadCells}</div>
                <div className="summary-list">
                    <div className="dr">{t('road.dragon_win')}</div>
                    {dragon}
                    <div className="tr">{t('road.tiger_win')}</div>
                    {tiger}
                    <div className="t">{t('road.draw')}</div>
                    {tie}
                </div>
                <TrialImage />
                <ShuffleLabel hostId={hostId} />
            </div>
        </>
    );
};
