import { createContext, useContext, useState } from 'react';
import { useLocalStorage } from '../hooks/storage/useLocalStorage';
import { GameCategoryTab as GameCategoryTabEnum } from '../models/lobby/GameCategoryTab';
import { AudioPlayContext } from './AudioPlayContext';

export const LobbyUiContext = createContext({} as LobbyUiState);
const { Provider } = LobbyUiContext;
type LobbyUiProviderOwnProps = {
    children: JSX.Element;
};
type LobbyUiProviderProps = LobbyUiProviderOwnProps;
type LobbyUiState = {
    currentTab: GameCategoryTabEnum;
    grid: ColumnGrid;
    isHall: boolean;
    toggleGrid: () => void;
    changeCurrentTab: (t: GameCategoryTabEnum) => void;
    changeIsHall: (b: boolean) => void;
};
export type ColumnGrid = 6 | 12;
export const LobbyUiProvider = (props: LobbyUiProviderProps) => {
    const { children } = props;
    const [currentTab, setCurrentTab] = useLocalStorage(
        'lobby_ui_tab',
        GameCategoryTabEnum.Baccarat
    );
    const [grid, setGrid] = useLocalStorage<ColumnGrid>('grid', 6);
    const [isHall, setIsHall] = useState(false);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const toggleGrid = () => {
        playButtonAudio();
        setGrid(grid === 6 ? 12 : 6);
    };
    const changeCurrentTab = (t: GameCategoryTabEnum) => {
        setCurrentTab(t);
        isHall && changeIsHall(false);
    };
    const changeIsHall = (b: boolean) => {
        setIsHall(b);
    };
    const state: LobbyUiState = {
        grid,
        toggleGrid,
        currentTab,
        changeCurrentTab,
        isHall,
        changeIsHall,
    };
    return <Provider value={state}>{children}</Provider>;
};
