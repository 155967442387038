import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { getHostById } from '../../../../host/slice';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import BaseImage from '../../../../../components/common/baseImage';
import { AndarBaharGameState, AndarBaharRecord, AndarBaharResult, AndarBaharStatistics } from '../../../../../models/games/andarBahar';
import { BetType } from '../../../../../models/games/andarBahar/BetType';
import { Poker, PokerType } from '../../../../../components/common/poker';
import { GameState } from '../../../../../models/games/andarBahar/GameState';
import { AndarBaharBetRatePanel, BetRateType } from './AndarBaharBetRatePanel';
import AndarBaharLatest30RecordsPager from './AndarBaharLatest30RecordsPager';
import './AndarBaharWinStatisticPanel.scss';

export type WinStatisticsProps = {
    title: string;
    value: string;
};
export type WinStatisticsTypeSideBet = {
    r1_5: WinStatisticsProps;
    r6_10: WinStatisticsProps;
    r11_15: WinStatisticsProps;
    r16_20: WinStatisticsProps;
    r21_25: WinStatisticsProps;
    r26_30: WinStatisticsProps;
    r31_35: WinStatisticsProps;
    r36_40: WinStatisticsProps;
    r41_45: WinStatisticsProps;
    r46_49: WinStatisticsProps;
}
type WinStatisticsTypeAB = {
    andar: WinStatisticsProps;
    bahar: WinStatisticsProps;
};
type WinStatisticsTypeGameCard = {
    andar: WinStatisticsProps;
    bahar: WinStatisticsProps;
    gameCard: WinStatisticsProps;
};
type WinStatisticsType = [WinStatisticsTypeAB, WinStatisticsTypeGameCard, WinStatisticsTypeSideBet];
const initWinStatisticsData = [
    {
        andar: {title: 'andar_bahar.andar', value: ''},
        bahar: {title: 'andar_bahar.bahar', value: ''}
    }, 
    {
        andar: {title: 'andar_bahar.andar', value: ''},
        bahar: {title: 'andar_bahar.bahar', value: ''}
    }, 
    {
        r1_5: {title: '1-5', value: ''},
        r6_10: {title: '6-10', value: ''},
        r11_15: {title: '11-15', value: ''},
        r16_20: {title: '16-20', value: ''},
        r21_25: {title: '21-25', value: ''},
        r26_30: {title: '26-30', value: ''},
        r31_35: {title: '31-35', value: ''},
        r36_40: {title: '36-40', value: ''},
        r41_45: {title: '41-45', value: ''},
        r46_49: {title: '46-49', value: ''},
    }
] as WinStatisticsType;
const cards = ['1','2','3','4','5','6','7','8','9','10','J','Q','K'];

export const AndarBaharStatisticsTab = () => {
    const { t } = useTranslation();
    const { hostId } = useContext(VideoPlayerContext);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const [winStatistics, setWinStatistics] = useState<WinStatisticsType>(initWinStatisticsData);
    const [gameCard, setGameCard] = useState(-1);
    const [gameCardPanelVisibility, setGameCardPanelVisibility] = useState(
        host.CurrentState as AndarBaharGameState !== GameState.Idle && host.CurrentState as AndarBaharGameState !== GameState.GameStart
    );
    const [records, setRecords] = useState<AndarBaharRecord[]>();

    const getBaharWinRateByCard = (card?:number):number => {
		//if (!host.Statistics) return 0;
        if(!card) return 0;
        const statistics = host.Statistics as AndarBaharStatistics;
		const num = (card)%13;
		const total = statistics['Andar'+cards[num]+'Count']+statistics['Bahar'+cards[num]+'Count'];
		if(total == 0) return 0;
		const rate = statistics['Bahar'+cards[num]+'Count']/total;
		return rate;
	}

	const getAndarWinRateByCard = (card?:number):number => {
		//if (!host.Statistics) return 0;
        if(!card) return 0;
        const statistics = host.Statistics as AndarBaharStatistics;
		const num = (card)%13;
		const total = statistics['Andar'+cards[num]+'Count']+statistics['Bahar'+cards[num]+'Count'];
		if(total == 0) return 0;
		const rate = statistics['Andar'+cards[num]+'Count']/total;
		return rate;
	}

    const getRounds = (betType:BetType): number => {
        //if (!host.Statistics) return 0;
        const statistics = host.Statistics as AndarBaharStatistics;
		let total:number = 0;
		let tmp = [];
		switch(betType){
			case BetType.ABBAndarWin:
			case BetType.ABBBaharWin:
				tmp.push('TotalAndarCount');
				tmp.push('TotalBaharCount');
				break;
			case BetType.ABB1To5:
			case BetType.ABB6To10:
			case BetType.ABB11To15:
			case BetType.ABB16To20:
			case BetType.ABB21To25:
			case BetType.ABB26To30:
			case BetType.ABB31To35:
			case BetType.ABB36To40:
			case BetType.ABB41To45:
			case BetType.ABB46To49:
			case BetType.ABBFirst3:
			case BetType.ABBFirstAndar:
			case BetType.ABBFirstBahar:
				tmp.push('PokerCount1To5');
				tmp.push('PokerCount6To10');
				tmp.push('PokerCount11To15');
				tmp.push('PokerCount16To20');
				tmp.push('PokerCount21To25');
				tmp.push('PokerCount26To30');
				tmp.push('PokerCount31To35');
				// tmp.push('PokerCount36To49');
				tmp.push('PokerCount36To40');
				tmp.push('PokerCount41To45');
				tmp.push('PokerCount46To49');
				tmp.push('PokerCountFirst3');
				tmp.push('PokerCountFirstAndar');
				tmp.push('PokerCountFirstBahar');
				break;
		}
		for(let i = 0; i < tmp.length; i++){
			total += statistics[tmp[i]];
		}
        
		return total;
	}

    const getRateByType = (betType:BetType): number => {
		//if (!host.Statistics) return 0;
        const statistics = host.Statistics as AndarBaharStatistics;
		let round = getRounds(betType);
		if(round == 0) return 0;
		switch(betType){
			case BetType.ABBAndarWin:
				return statistics.TotalAndarCount/round;
			case BetType.ABBBaharWin:
				return statistics.TotalBaharCount/round;
			case BetType.ABB1To5:
				return statistics.PokerCount1To5/round;
			case BetType.ABB6To10:
				return statistics.PokerCount6To10/round;
			case BetType.ABB11To15:
				return statistics.PokerCount11To15/round;
			case BetType.ABB16To20:
				return statistics.PokerCount16To20/round;
			case BetType.ABB21To25:
				return statistics.PokerCount21To25/round;
			case BetType.ABB26To30:
				return statistics.PokerCount26To30/round;
			case BetType.ABB31To35:
				return statistics.PokerCount31To35/round;
			// case BetType.ABB36To49:
			// 	return statistics.PokerCount36To49/round;
			case BetType.ABB36To40:
				return statistics.PokerCount36To40/round;
			case BetType.ABB41To45:
				return statistics.PokerCount41To45/round;
			case BetType.ABB46To49:
				return statistics.PokerCount46To49/round;
			case BetType.ABBFirst3:
				return statistics.PokerCountFirst3/round;
			case BetType.ABBFirstAndar:
				return statistics.PokerCountFirstAndar/round;
			case BetType.ABBFirstBahar:
				return statistics.PokerCountFirstBahar/round;
		}
		return 0;
	}

    const changeToPercentage = (value: number) => Math.round(value * 100);

    useEffect(() => {
        if (host.CurrentResult) {            
            const result = host.CurrentResult as AndarBaharResult;
            const gameCard = Number(result.ResultString?.split(',')[0]);
            const validGameCard = isNaN(gameCard)? result.Joker:gameCard;
            setGameCard(validGameCard);
            winStatistics[1].andar.value = changeToPercentage(getAndarWinRateByCard(validGameCard)) + '%';
            winStatistics[1].bahar.value = changeToPercentage(getBaharWinRateByCard(validGameCard)) + '%';
        }
    }, [host.CurrentResult]);

    useEffect(() => {
        if (host.CurrentState) {
            const state = host.CurrentState as AndarBaharGameState;
            switch(state){
                case GameState.Idle:
                case GameState.GameStart:
                    setGameCardPanelVisibility(false);
                    break;
                default:
                    setGameCardPanelVisibility(true);
            }
        }
    }, [host.CurrentState]);

    useEffect(() => {
        if (host.Statistics) {
            //const statistics = host.Statistics as AndarBaharStatistics;

            winStatistics[0].andar.value = changeToPercentage(getRateByType(BetType.ABBAndarWin)) + '%';
            winStatistics[0].bahar.value = changeToPercentage(getRateByType(BetType.ABBBaharWin)) + '%';
            
            winStatistics[2].r1_5.value = changeToPercentage(getRateByType(BetType.ABB1To5)) + '%';
            winStatistics[2].r6_10.value = changeToPercentage(getRateByType(BetType.ABB6To10)) + '%';
            winStatistics[2].r11_15.value = changeToPercentage(getRateByType(BetType.ABB11To15)) + '%';
            winStatistics[2].r16_20.value = changeToPercentage(getRateByType(BetType.ABB16To20)) + '%';
            winStatistics[2].r21_25.value = changeToPercentage(getRateByType(BetType.ABB21To25)) + '%';
            winStatistics[2].r26_30.value = changeToPercentage(getRateByType(BetType.ABB26To30)) + '%';
            winStatistics[2].r31_35.value = changeToPercentage(getRateByType(BetType.ABB31To35)) + '%';
            winStatistics[2].r36_40.value = changeToPercentage(getRateByType(BetType.ABB36To40)) + '%';
            winStatistics[2].r41_45.value = changeToPercentage(getRateByType(BetType.ABB41To45)) + '%';
            winStatistics[2].r46_49.value = changeToPercentage(getRateByType(BetType.ABB46To49)) + '%';

            setWinStatistics(winStatistics);
        }
    }, [host.Statistics]);

    const style = (firstGrid: boolean) =>
        firstGrid
            ? { width: '55%', zIndex: '1' }
            : {
                  width: '44.5%',
                  zIndex: '1',
                  borderLeft: '2px solid rgba(255,255,255,0.2)',
              };

    useEffect(() => {
        let recs = (host.Record as AndarBaharRecord[]);
        if(recs) {
            if (recs.length > 30) recs = recs.slice(recs.length - 30);
            setRecords(recs);
        }
    }, [host.Record])
    const leftPanel = () => {
        return (
            winStatistics &&
            winStatistics.length > 1 && (
                <>
                    <Stack direction='column' spacing={1} sx={{ marginTop:'5px', marginBottom:'5px', borderRadius:'5px 5px 5px 5px' }}>
                        <AndarBaharLatest30RecordsPager recs={records} />
                        <AndarBaharBetRatePanel
                            type={BetRateType.ABWinRate} 
                            andarTitle={t(winStatistics[0].andar.title)}
                            baharTitle={t(winStatistics[0].bahar.title)}
                            andarWinPercentage={winStatistics[0].andar.value} 
                            baharWinPercentage={winStatistics[0].bahar.value} />
                        <Stack direction='column' spacing={0}>
                            <Box sx={{display:gameCardPanelVisibility? 'flex':'none', height: '65px', justifyContent: 'center'}}>
                                <Poker scale={0.45} n={gameCard+1} type={PokerType.AndarBahar2}/>
                            </Box>
                            <Box sx={{display:gameCardPanelVisibility? 'flex':'none', justifyContent: 'center'}}>
                                <AndarBaharBetRatePanel 
                                    type={BetRateType.GameCardABRate} 
                                    andarTitle={t(winStatistics[1].andar.title)}
                                    baharTitle={t(winStatistics[1].bahar.title)}
                                    andarWinPercentage={winStatistics[1].andar.value} 
                                    baharWinPercentage={winStatistics[1].bahar.value} />
                            </Box>
                        </Stack>
                    </Stack>
                </>
            )
        );
    };

    const rightPanel = () => {
        return (
            winStatistics &&
            winStatistics.length > 1 && (
                <>
                    <Stack direction='column' spacing={0} sx={{height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        {Object.values(winStatistics[2]).map((data, index) => (
                            <Stack key={`andar-bahar-sidebet-${index}`} direction='row' spacing={1} sx={{alignItems: 'center'}}>
                                <Typography 
                                    noWrap 
                                    variant='caption' 
                                    fontSize='10px' 
                                    color={'#C3A475'}
                                    sx={{width: '27.5px', alignSelf:'center', textAlign:'center'}}
                                >
                                    {data.title}
                                </Typography>
                                <Box className='andarbahar-bahar-sidebet-win-item'>
                                    {<Box className='andarbahar-bahar-sidebet-win-thumb-item' sx={{width: data.value}}>
                                    </Box>}
                                </Box>
                                <Typography 
                                    noWrap
                                    variant='caption' 
                                    fontSize='10px'
                                    sx={{width: '27.5px', alignSelf:'center', textAlign:'center', opacity:'0.85'}}
                                >
                                    {data.value}
                                </Typography>
                            </Stack>
                        ))}
                    </Stack>
                </>
            )
        );
    };

    return (
        <>
            {winStatistics && (
                <>
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        sx={{
                            fontSize: '14px',
                            height: '172px',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                            }}
                        >
                            <BaseImage
                                className='tradbaccBg table_info_bg'
                                scale={1}
                                opacity={0.5}
                                transform={
                                    'translate(0, 17.5px) scale(1, 1.226)'
                                }
                            />
                        </Box>
                        <Box sx={style(true)}>{leftPanel()}</Box>
                        <Box sx={style(false)}>{rightPanel()}</Box>
                    </Stack>
                </>
            )}
        </>
    );
};
