import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { LobbyUiContext } from '../../../contexts/LobbyUiContext';
import { SedieRoadmapRecord } from '../../../models/games/sedie';
import { RootState } from '../../../store/store';
import { getHostById } from '../../host/slice';
import { ROAD_MAP_COLORS, ROAD_MAP_TYPE } from '../../lobby/constants';
import { getBeadRoad } from './util';
import { useTranslation } from 'react-i18next';

export const STROKE_WIDTH = 1.5;
export type CommonCellProps = {
    size: number;
};

export type SedieRoadMapCellProps = {
    record: SedieRoadmapRecord;
} & CommonCellProps;

export type SedieRoadMapCellColorText = {
    color: ROAD_MAP_COLORS;
    text: string;
};

export const useSedieRoadMapCellColorText = (
    roadType: ROAD_MAP_TYPE,
    winPoint?: number,
) => {
    const { t } = useTranslation();
    let color = ROAD_MAP_COLORS.GREEN;
    let text = '';
    if (winPoint == undefined) {
        return { color, text };
    }
    switch (roadType) {
        case ROAD_MAP_TYPE.BIG_SMALL_ROAD:
        case ROAD_MAP_TYPE.BEAD_ROAD:
        case ROAD_MAP_TYPE.POINT_ROAD:
            if (winPoint < 2) {
                color = ROAD_MAP_COLORS.BLUE;
            } else if (winPoint > 2) {
                color = ROAD_MAP_COLORS.RED;
            }
            break;
        case ROAD_MAP_TYPE.EVEN_ODD_ROAD:
            color = winPoint % 2 == 0 ? ROAD_MAP_COLORS.RED : ROAD_MAP_COLORS.BLUE;
            break;
    }

    switch (roadType) {
        case ROAD_MAP_TYPE.BIG_SMALL_ROAD:
            if (winPoint < 2) {
                text = t('road.small')
            } else if (winPoint > 2) {
                text = t('road.big')
            }
            break;
        case ROAD_MAP_TYPE.BEAD_ROAD:
        case ROAD_MAP_TYPE.POINT_ROAD:
            text = '' + winPoint;
            break;
        case ROAD_MAP_TYPE.EVEN_ODD_ROAD:
            text = winPoint % 2 == 0 ? t('road.even') : t('road.odd');
            break;
    }

    return { color, text };
};

export const useRoadMapState = (hostId: number) => {
    const { grid } = useContext(LobbyUiContext);
    const { Record, IsRest } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [beadRoadRecord, setBeadRoadRecord] =
        useState<Array<SedieRoadmapRecord>>();
    const [pointRoadRecord, setPointRoadRecord] =
        useState<Array<SedieRoadmapRecord>>();
    const [bigSmallRoadRecord, setBigSmallRoadRecord] =
        useState<Array<SedieRoadmapRecord>>();
    const [evenOddRoadRecord, setEvenOddRoadRecord] =
        useState<Array<SedieRoadmapRecord>>();
    const isOneColumn = useMemo(() => grid === 12, [grid]);
    const beadRoad = useMemo(
        () => getBeadRoad(Record),
        [Record]
    );
    return {
        beadRoadRecord,
        setBeadRoadRecord,
        pointRoadRecord,
        setPointRoadRecord,
        bigSmallRoadRecord,
        setBigSmallRoadRecord,
        evenOddRoadRecord,
        setEvenOddRoadRecord,
        isOneColumn,
        beadRoad,
        Record,
        IsRest,
    };
};
