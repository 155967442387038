import { Stack } from '@mui/material';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LocalState } from '../../models/games/enums/LocalState';
import { RootState } from '../../store/store';
import { getHostById } from '../host/slice';
import { ROAD_MAP_COLORS } from '../lobby/constants';
import { getLocalState } from '../../utils/games/sicbo';
import { BaseTypography } from '../../components/common/baseText/BaseTypography';
type Props = {
    hostId: number;
};
export const SicBoLobbyCountdown = (props: Props) => {
    const { hostId } = props;
    const { t, i18n } = useTranslation();
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { IsRest, CurrentState, ResultReleased, RemainTime, CountDown } =
        host;
    const totalTime = Math.round((CountDown || 0) / 1000);
    const [timer, setTimer] = useState<number>(-1);
    const Ref = useRef<NodeJS.Timeout>();
    const [stateLabelKey, setStateLabelKey] = useState<string>('');
    const [stateColor, setStateColor] = useState<string>(
        ROAD_MAP_COLORS.DARK_GREEN
    );
    const [localState, setLocalState] = useState<number>(-1);
    const [display, setDisplay] = useState('');

    const startTimer = () => {
        if (timer > 0) {
            const cTimer = timer - 1;
            setTimer(cTimer);
        }
    };

    const clearTimer = () => {
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer();
        }, 1000);
        Ref.current = id;
    };

    useEffect(() => {
        setLocalState(getLocalState(IsRest, CurrentState, ResultReleased));
    }, [IsRest, CurrentState, ResultReleased]);

    useEffect(() => {
        if (localState !== -1) {
            if (localState === LocalState.BETTING) {
                setStateLabelKey('game.betting');
                setStateColor(ROAD_MAP_COLORS.DARK_GREEN);
            } else {
                if (localState === LocalState.DEALING) {
                    setStateLabelKey('game.dealing');
                    setStateColor(ROAD_MAP_COLORS.DARK_GREEN);
                } else if (localState === LocalState.SHUFFLING) {
                    setStateLabelKey('game.shuffling');
                    setStateColor(ROAD_MAP_COLORS.DARK_GREEN);
                } else if (localState === LocalState.REST) {
                    setStateLabelKey('game.rest');
                    setStateColor(ROAD_MAP_COLORS.DARK_GREEN);
                } else {
                    setStateLabelKey('game.idle');
                    setStateColor(ROAD_MAP_COLORS.DARK_GREEN);
                }
            }
        } else {
            setStateLabelKey('');
        }
    }, [localState]);

    useEffect(() => {
        if (stateLabelKey === 'game.betting') {
            const curTimer = Math.max(0, Math.round((RemainTime - moment().valueOf()) / 1000));
            setTimer(curTimer);
            setDisplay(curTimer.toString().padStart(2, '0'));
            clearTimer();
        } else {
            setTimer(0);
        }
        return () => {
            if (Ref.current) clearInterval(Ref.current);
        };
    }, [stateLabelKey, timer, RemainTime, totalTime]);
    useEffect(() => {
        if(stateLabelKey !== 'game.betting'){
            setDisplay(t(stateLabelKey));
        }
    }, [stateLabelKey, i18n.language]);
    return (
        <Stack
            alignContent="center"
            justifyContent="center"
            alignItems="center"
            className="countdown-box"
            sx={{
                backgroundColor: stateColor,
                width: '88px',
                height: '28px',
            }}
        >
            {timer >= 0 && (
                <BaseTypography
                    fontSize={14}
                    resize={{
                        direction: 'horizontal',
                        value: '86px',
                    }}
                >
                    {display}
                </BaseTypography>
            )}
        </Stack>
    );
};
