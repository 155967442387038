import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface BaseImageProps {
    className?: string;
    isMultiLang?: boolean;
    opacity?: number | '';
    scale?: number;
    transform?: string | '';
    transformOrigin?: string | '';
    //defaultLang?: {langList:string[], default:string};
}

const BaseImage = (props: BaseImageProps) => {
    const { i18n } = useTranslation();
    const {
        className,
        isMultiLang,
        scale = 0.5,
        opacity = '',
        transform = '', //TODO: enhance transform
        transformOrigin = '',
        //defaultLang
    } = props;
    const [img, setImg] = useState(<></>);

    useEffect(() => {
        const langClassName = isMultiLang
            ? `${i18n.language}-${className}`
            : className;
        const image = (
            <Box
                className={langClassName}
                style={{
                    zoom: scale,
                    transform: transform,
                    opacity: opacity,
                    transformOrigin: transformOrigin,
                }}
            />
        );
        setImg(image);
    }, [i18n.language, className, opacity, scale, transform, transformOrigin]);
    return img;
};

export default BaseImage;
