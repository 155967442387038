import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InGamePopupKeyType } from '../../../../hooks/useInGamePopup';
import { LocalState as LocalStateEnum } from '../../../../models/games/enums/LocalState';
import { useContext, useEffect, useRef } from 'react';
import { AudioPlayContext } from '../../../../contexts/AudioPlayContext';

export const TeenPattiGameResultPopup = (props: InGamePopupKeyType) => {
    const { amount, bg, message, localState, audioKey, playSound } = props;
    const { t } = useTranslation();
    const { audioPlay } = useContext(AudioPlayContext);
    const ref = useRef<boolean>(false); //防止play 2 次
    useEffect(() => {
        if (!ref.current) {
            ref.current = true;
            if (audioKey && playSound) {
                let aKey = [];
                aKey.push(audioKey);
                audioPlay(aKey);
            }
        }
    }, []);

    const getSX = () => {
        return localState == LocalStateEnum.SHUFFLING
            ? {
                  position: 'absolute',
                  minHeight: 90,
                  width: '100%',
                  top: 378,
                  left: 0,
                  right: 0,
                  fontSize: 34,
                  padding: '5px',
                  borderRadius: '0px',
                  background: `linear-gradient(to right, rgba(115, 95, 68,0.2) 0%, rgba(115, 95, 68,1) 8%, rgba(115, 95, 68,1) 92%, rgba(115, 95, 68,0.2)) 100%`,
              }
            : localState == LocalStateEnum.BETTING ||
                localState == LocalStateEnum.DEALING
              ? {
                    position: 'absolute',
                    minHeight: 125,
                    width: 340,
                    top: 302,
                    left: 100,
                    right: 100,
                    fontSize: 34,
                    padding: '10px 0',
                    borderRadius: '4px',
                    background: bg,
                }
              : {
                    position: 'absolute',
                    minHeight: 90,
                    width: '100%',
                    top: 378,
                    left: 0,
                    right: 0,
                    fontSize: 34,
                    padding: '10px 0',
                    borderRadius: '0px',
                    background: bg,
                };
    };

    const getFontSize = () => {
        if (localState != LocalStateEnum.SHUFFLING) {
            return 28;
        }
        return 20;
    };

    return (
        <Stack
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={theme => theme.zIndex.drawer + 80}
            fontSize={getFontSize()}
            sx={getSX()}
            textAlign={'center'}
        >
            {t(message)}
            {amount && localState != LocalStateEnum.SHUFFLING && (
                <Typography sx={{ fontSize: 28 }}>{amount}</Typography>
            )}
        </Stack>
    );
};
