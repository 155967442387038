import { memo, useEffect } from 'react';
import { ROAD_MAP_COLORS } from '../../../lobby/constants';
import {
    BaccaratRoadMapCellProps,
    RESOLUTION,
    STROKE_WIDTH,
    useBaccaratRoadMapCellColorText,
} from '../hook';
import { motion, useAnimationControls } from 'framer-motion';
const LastDrawLine = (
    <line
        x1={RESOLUTION * 0.35}
        y1={RESOLUTION}
        x2={RESOLUTION}
        y2={RESOLUTION * 0.35}
        stroke={ROAD_MAP_COLORS.GREEN}
        strokeWidth={STROKE_WIDTH}
    />
);
const BankerPairCircle = (
    <circle
        cx={RESOLUTION * 0.2}
        cy={RESOLUTION * 0.2}
        r={RESOLUTION * 0.2}
        fill={ROAD_MAP_COLORS.RED}
        stroke={ROAD_MAP_COLORS.WHITE}
        strokeWidth={RESOLUTION * 0.05}
    />
);
const PlayerPairCircle = (
    <circle
        cx={RESOLUTION - RESOLUTION * 0.2}
        cy={RESOLUTION - RESOLUTION * 0.2}
        r={RESOLUTION * 0.2}
        fill={ROAD_MAP_COLORS.BLUE}
        stroke={ROAD_MAP_COLORS.WHITE}
        strokeWidth={RESOLUTION * 0.05}
    />
);
const BankerWinCircle = (
    <circle
        cx={RESOLUTION / 2}
        cy={RESOLUTION / 2}
        r={RESOLUTION / 2 - STROKE_WIDTH / 2}
        fill="transparent"
        stroke={ROAD_MAP_COLORS.RED}
        strokeWidth={STROKE_WIDTH}
    />
);
const PlayerWinCircle = (
    <circle
        cx={RESOLUTION / 2}
        cy={RESOLUTION / 2}
        r={RESOLUTION / 2 - STROKE_WIDTH / 2}
        fill="transparent"
        stroke={ROAD_MAP_COLORS.BLUE}
        strokeWidth={STROKE_WIDTH}
    />
);
const BigRoadCell = (props: BaccaratRoadMapCellProps) => {
    const {
        record: { Win, LastDraw, BankerPair, PlayerPair, SpaceNum, AskType },
    } = props;
    const { color } = useBaccaratRoadMapCellColorText(Win);
    const askRoadAnimation = useAnimationControls();
    const anim = () => {
        askRoadAnimation.start({
            opacity: [1, 1, 0, 1, 1, 0],
            transition: { duration: 1 },
        });
    };
    useEffect(() => {
        if (AskType >= 0) {
            anim();
        }
    }, [AskType]);
    return (
        <svg
            viewBox={`0 0 ${RESOLUTION} ${RESOLUTION}`}
            className="roadmap-cell"
        >
            {Win !== undefined && (
                <>
                    {Win === 0 && BankerWinCircle}
                    {Win === 1 && PlayerWinCircle}
                    {LastDraw && LastDrawLine}
                    {BankerPair && BankerPairCircle}
                    {PlayerPair && PlayerPairCircle}
                    {SpaceNum && SpaceNum > 0 && (
                        <text
                            x={RESOLUTION / 2}
                            y={RESOLUTION / 2}
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fill={color}
                            fontSize={RESOLUTION * 0.7}
                            fontWeight="bold"
                        >
                            {SpaceNum}
                        </text>
                    )}
                </>
            )}
            {AskType >= 0 && (
                <motion.circle
                    animate={askRoadAnimation}
                    initial={{ opacity: 0 }}
                    cx={RESOLUTION / 2}
                    cy={RESOLUTION / 2}
                    r={RESOLUTION / 2 - STROKE_WIDTH / 2}
                    fill="transparent"
                    stroke={
                        AskType === 0
                            ? ROAD_MAP_COLORS.RED
                            : ROAD_MAP_COLORS.BLUE
                    }
                    strokeWidth={STROKE_WIDTH}
                />
            )}
        </svg>
    );
};
export default memo(BigRoadCell);
