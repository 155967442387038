import { Typography } from '@mui/material';
import { LimitRed } from '../modules/main/slice/limitRed';

export const amountFormatter = (
    amount: number,
    withColor: boolean = true,
    fontSize: string = '',
    withSign: boolean = false
) => {
    let color = 'white';
    let sign = '';
    if (withColor) {
        if (amount > 0) color = 'green';
        else if (amount < 0) color = 'red';
        else if (amount === 0) color = 'yellow';
    } else {
        if (amount < 0) color = 'red';
        else if (amount === 0) color = 'yellow';
    }

    if (withSign) {
        if (amount > 0) sign = '+';
    }

    return (
        <Typography sx={{ color: color, fontSize: fontSize }}>
            {sign +
                amount?.toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}
        </Typography>
    );
};

export const limitRedFormatter = (lr: LimitRed, CurrencyName: string) => {
    return `${shortLimitRed(lr.MinBet / 100, CurrencyName)} - ${shortLimitRed(
        lr.LimitRed / 100,
        CurrencyName
    )}`;
};
// `${(lr.MinBet / 100).toLocaleString('en-US', {
//     maximumFractionDigits: 2,
// })} - ${(lr.LimitRed / 100).toLocaleString('en-US', {
//     maximumFractionDigits: 2,
// })}`;

export const shortLimitRed = (n: number, CurrencyName: string) => {
    if (n >= 1000 && n % 1000 > 0) {
        return n.toLocaleString('en-US', {
            maximumFractionDigits: 2,
        });
    }

    if (CurrencyName.toUpperCase() != 'KRW' && n < 10000) {
        return n.toLocaleString('en-US', {
            maximumFractionDigits: 2,
        });
    }

    if (n >= 1000 && n <= 900 * 1000) {
        return n / 1000 + 'K';
    }

    if (n >= 1000 * 1000) {
        return n / 1000000 + 'M';
    }

    return n.toString();
};

export const shortAmountToString = (n: number, digit: number = 2): string => {
    if (n >= 1000 * 1000) {
        return (
            Math.round(n / (1000000 / Math.pow(10, digit))) /
                Math.pow(10, digit) +
            'M'
        );
    } else if (n >= 1000) {
        return (
            Math.round(n / (1000 / Math.pow(10, digit))) / Math.pow(10, digit) +
            'K'
        );
    }

    return n.toString();
};
export const mask = (s: string) => {
    // remove the lobby name
    const pattern = /@.*/;
    const tmp = s.replace(pattern, '');
    // get last 3 char
    return '***' + tmp.slice(-3);
};
