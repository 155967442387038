import { Box, Grid, Stack } from '@mui/material';
import BaseImage from '../../../../../../components/common/baseImage';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { VideoPlayerContext } from '../../../../../../contexts/VideoContext';
import { SicBoStatistics } from '../../../../../../models/games/sicbo';
import { RootState } from '../../../../../../store/store';
import { getHostById } from '../../../../../host/slice';

export const SicboStatisticDice = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { Statistics } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [rateList, setRateList] = useState<number[]>([]);

    useEffect(() => {
        if (Statistics) {
            const d1 = (Statistics as SicBoStatistics).Dice1Rate || 0;
            const d1Per = Math.round(d1 * 100);
            const d2 = (Statistics as SicBoStatistics).Dice2Rate || 0;
            const d2Per = Math.round(d2 * 100);
            const d3 = (Statistics as SicBoStatistics).Dice3Rate || 0;
            const d3Per = Math.round(d3 * 100);
            const d4 = (Statistics as SicBoStatistics).Dice4Rate || 0;
            const d4Per = Math.round(d4 * 100);
            const d5 = (Statistics as SicBoStatistics).Dice5Rate || 0;
            const d5Per = Math.round(d5 * 100);
            const d6 = (Statistics as SicBoStatistics).Dice6Rate || 0;
            const d6Per = Math.round(d6 * 100);
            setRateList([d1Per, d2Per, d3Per, d4Per, d5Per, d6Per]);
        }
    }, [Statistics]);

    return (
        <>
            <Stack direction={'column'} className="dice">
                <Grid
                    item
                    container
                    direction={'row'}
                    flexWrap={'nowrap'}
                    alignItems={'center'}
                    className="d123"
                >
                    <DicePercent d={1} rate={rateList[0] || 0} />
                    <DicePercent d={3} rate={rateList[2] || 0} />
                    <DicePercent d={5} rate={rateList[4] || 0} />
                </Grid>
                <Grid
                    item
                    container
                    direction={'row'}
                    flexWrap={'nowrap'}
                    className="d456"
                >
                    <DicePercent d={2} rate={rateList[1] || 0} />
                    <DicePercent d={4} rate={rateList[3] || 0} />
                    <DicePercent d={6} rate={rateList[5] || 0} />
                </Grid>
            </Stack>
        </>
    );
};

const DicePercent = ({ d, rate }: { d: number; rate: number }) => {
    return (
        <Grid
            item
            container
            direction={'row'}
            flexWrap={'nowrap'}
            alignItems={'center'}
        >
            <BaseImage className={`dices_stroke dice${d}`} />
            <Box className="rate-text">{`${rate}%`}</Box>
        </Grid>
    );
};
