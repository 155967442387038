import { useEffect, useState } from 'react';
import { GameType as GameTypeEnum } from '../models/games/enums/GameType';
import { ROAD_MAP_TYPE } from '../modules/lobby/constants';
import { useClientIp } from './useClientIp';
import { ResolutionType } from './useVideoPlayer';

//Feedback data
type FeedbackData = {
    blockTimestamp: number;
    sends: number;
};
//Disable Emoji Button
type DisableEmojiButtonData = {
    duration: number;
    hostId: number;
};
type DisableEmojiButton = {
    hostId: number;
    disable: boolean;
};
type TimerType = {
    hostId: number;
    timer: NodeJS.Timeout | null;
};
export type useGlobalDataState = ReturnType<typeof useGlobalData>;
export const useGlobalData = () => {
    const [data, setData] = useState<DisableEmojiButtonData[]>([]);
    const [disable, setDisable] = useState<DisableEmojiButton[]>([]);
    const [timer, setTimer] = useState<TimerType[]>([]);

    const setDisableEmojiButtonData = (hostId: number, dur: number) => {
        const dd = {} as DisableEmojiButtonData;
        dd.hostId = hostId;
        dd.duration = dur;
        const ddIdx = data.findIndex(d => d.hostId === hostId);
        let newData: DisableEmojiButtonData[];
        if (ddIdx === -1) {
            newData = data.concat([dd]);
        } else {
            data[ddIdx].duration = dur;
            newData = data;
        }
        setData(newData);

        const d = {} as DisableEmojiButton;
        d.hostId = hostId;
        d.disable = true;
        const dIdx = disable.findIndex(d => d.hostId === hostId);
        let newDisable: DisableEmojiButton[];
        if (dIdx === -1) {
            newDisable = disable.concat([d]);
        } else {
            disable[dIdx].disable = true;
            newDisable = disable;
        }
        setDisable(newDisable);
    };
    useEffect(() => {
        if (data.length > 0) {
            data.map(dd => {
                const { hostId, duration } = dd;

                const dIdx = disable.findIndex(d => d.hostId === hostId);
                const tIdx = timer.findIndex(
                    t => t.hostId === hostId && t.timer === null
                );
                if (dIdx >= 0 && tIdx === -1) {
                    let dur = duration * 3000;
                    if (dur < 60000) dur = 60000;

                    const t = {} as TimerType;
                    t.hostId = hostId;
                    t.timer = setTimeout(() => {
                        const newData = data.filter(d => d.hostId !== hostId);
                        setData(newData);
                        const newDisable = disable.filter(
                            d => d.hostId !== hostId
                        );
                        setDisable(newDisable);
                        const newTimer = timer.filter(d => d.hostId !== hostId);
                        setTimer(newTimer);
                    }, dur);

                    setTimer(timer.concat([t]));
                }
            });
        }
    }, [data]);

    const [resolution, setResolution] = useState<ResolutionType>('HD');
    //Video On/Off
    const [videoOffList, setVideoOffList] = useState<Array<GameTypeEnum>>([]);
    const saveVideoOffList = (g: GameTypeEnum) => {
        let newList = Array.from(videoOffList);
        newList.push(g);
        setVideoOffList(newList);
    };
    const removeVideoOffList = (g: GameTypeEnum) => {
        let newList = Array.from(videoOffList);
        newList = newList.filter(l => l !== g);
        setVideoOffList(newList);
    };
    const [baccaratRoadMapType, setBaccaratRoadMapType] =
        useState<ROAD_MAP_TYPE>(ROAD_MAP_TYPE.BEAD_ROAD);
    const [isDefaultTable, setIsDefaultTable] = useState<boolean>(true);
    const [feedbackData, setFeedbackData] = useState<FeedbackData>();

    const { address, countryCode, ip } = useClientIp();

    useEffect(() => {
        console.log('video::resolution', resolution);
    }, [resolution]);

    return {
        setDisableEmojiButtonData,
        disable,

        resolution,
        setResolution,

        videoOffList,
        saveVideoOffList,
        removeVideoOffList,

        baccaratRoadMapType,
        setBaccaratRoadMapType,

        isDefaultTable,
        setIsDefaultTable,

        feedbackData,
        setFeedbackData,

        address,
        countryCode,
        ip,
    };
};
