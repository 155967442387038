import { Grid } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SeatAreaContext } from '../../../../../contexts/SeatAreaContext';
import { WebSocketContext } from '../../../../../contexts/WebSocketContext';
import { useBetSource } from '../../../../../hooks/useBetSource';
import { CMDPsMakeDecision } from '../../../../../models/cmd/live';

export const FollowDecisionPanel = () => {
    const { t } = useTranslation();
    return (
        <Grid
            container
            direction={'column'}
            className="blackjack-follow-decision-panel"
        >
            <Grid item textAlign={'center'}>
                {t('blackjack.ask_follow')}
            </Grid>
            <Grid container item justifyContent={'space-evenly'}>
                <CloseIcon />
                <TickIcon />
            </Grid>
        </Grid>
    );
};

const CloseIcon = () => {
    const { setShowFollow } = useContext(SeatAreaContext);
    return (
        <svg
            width="64"
            height="65"
            viewBox="0 0 64 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setShowFollow(false)}
        >
            <rect
                y="0.5"
                width="64"
                height="64"
                rx="32"
                fill="#322A1D"
                fillOpacity="0.8"
            />
            <g clipPath="url(#clip0_1306_13462)">
                <path
                    d="M31.9973 30.1436L40.2473 21.8936L42.604 24.2502L34.354 32.5002L42.604 40.7502L40.2473 43.1069L31.9973 34.8569L23.7473 43.1069L21.3906 40.7502L29.6406 32.5002L21.3906 24.2502L23.7473 21.8936L31.9973 30.1436Z"
                    fill="white"
                />
            </g>
            <circle
                cx="32"
                cy="32.5"
                r="27.5"
                stroke="white"
                strokeOpacity="0.2"
            />
            <defs>
                <clipPath id="clip0_1306_13462">
                    <rect
                        x="12"
                        y="12.5"
                        width="40"
                        height="40"
                        rx="8"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
const TickIcon = () => {
    const { selectedSeat, setShowFollow, playerResult, gameId } =
        useContext(SeatAreaContext);
    const { sendCommand } = useContext(WebSocketContext);
    const betSource = useBetSource();
    return (
        <svg
            width="64"
            height="65"
            viewBox="0 0 64 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
                const decision = playerResult.find(p => p.SN === selectedSeat)
                    ?.D;
                console.log(
                    'blackjack::follow::decision',
                    selectedSeat,
                    decision
                );
                if (decision) {
                    const cmd = new CMDPsMakeDecision();
                    cmd.BetSource = betSource;
                    cmd.GameID = gameId;
                    cmd.Decision = decision;
                    sendCommand(cmd);
                }
                setShowFollow(false);
            }}
        >
            <rect
                y="0.5"
                width="64"
                height="64"
                rx="32"
                fill="#6F5F45"
                fillOpacity="0.8"
            />
            <path
                d="M27.9998 37.6115L42.4433 23.1665L44.6667 25.3883L27.9998 42.0552L18 32.0554L20.2218 29.8336L27.9998 37.6115Z"
                fill="white"
            />
            <circle
                cx="32"
                cy="32.5"
                r="27.5"
                stroke="white"
                strokeOpacity="0.2"
            />
        </svg>
    );
};
