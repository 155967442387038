import { memo } from 'react';
import BaseImage, { BaseImageProps } from '../baseImage';
type PokerOwnProps = {
    n: number;
    scale?: number;
    opacity?: number;
    type?: PokerType;
};
type PokerProps = PokerOwnProps & BaseImageProps;
export enum PokerType {
    Common,
    AndarBahar1,
    AndarBahar2,
}
export const Poker = memo(function Poker(props: PokerProps) {
    const {
        n,
        scale = 1,
        opacity = 1,
        type = PokerType.Common,
        transform,
    } = props;
    let currentCard;
    let defaultCard;
    switch (type) {
        case PokerType.AndarBahar1:
            currentCard = 'abCard1 card' + String(n);
            defaultCard = 'abCard1 card1';
            break;
        case PokerType.AndarBahar2:
            currentCard = 'abCard2 card' + String(n);
            defaultCard = 'abCard2 card1';
            break;
        default:
            currentCard = 'poker card' + String(n).padStart(3, '0');
            defaultCard = 'poker card001';
    }

    return (
        //n < 0 - handle empty card support valid width & height
        <BaseImage
            className={n < 0 ? defaultCard : currentCard}
            scale={scale}
            transform={transform}
            opacity={n < 0 ? 0 : opacity}
        />
    );
});
