import { memo } from 'react';
import { CockroachBankerWin, CockroachPlayerWin, Empty } from '../svg';
type CockroachRoadCellProps = {
    win: number;
};
const CockroachRoadCell = ({ win }: CockroachRoadCellProps) => {
    if (win === 0) return <CockroachBankerWin />;
    else if (win === 1) return <CockroachPlayerWin />;
    else return <Empty />;
};
export default memo(CockroachRoadCell);
