import { Button, Grid } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SeatAreaContext } from '../../../../../../contexts/SeatAreaContext';
import { WebSocketContext } from '../../../../../../contexts/WebSocketContext';
import { useBetSource } from '../../../../../../hooks/useBetSource';
import { CMDPsMakeDecision } from '../../../../../../models/cmd/live';
import { Decision } from '../../../../../../models/games/blackjack/enums/Decision';
import { BaseTypography } from '../../../../../../components/common/baseText/BaseTypography';

export const StandDecisionButton = () => {
    const { t } = useTranslation();
    const betSource = useBetSource();
    const { sendCommand } = useContext(WebSocketContext);
    const { setShowDecision, gameId } = useContext(SeatAreaContext);
    return (
        <Button
            className="blackjack-decision-button"
            onClick={() => {
                const command = new CMDPsMakeDecision();
                command.GameID = gameId;
                command.BetSource = betSource;
                command.Decision = Decision.Stand;

                sendCommand(command);
                setShowDecision(false);
            }}
            sx={{ marginTop: '3px' }}
        >
            <Grid>
                <svg
                    width="57"
                    height="56"
                    viewBox="0 0 57 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="0.600098"
                        width="56"
                        height="56"
                        rx="28"
                        fill="#912420"
                    />
                    <g filter="url(#filter0_d_246_291825)">
                        <path
                            d="M38.6001 27.8835V30H18.6001V27.8835H38.6001Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_246_291825"
                            x="14.6001"
                            y="23.8833"
                            width="28"
                            height="10.1167"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_246_291825"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_246_291825"
                                result="shape"
                            />
                        </filter>
                    </defs>
                </svg>
                <BaseTypography
                    color={'white'}
                    fontSize={18}
                    resize={{ direction: 'horizontal', value: '80px' }}
                >
                    {t('blackjack.decision.stand')}
                </BaseTypography>
            </Grid>
        </Button>
    );
};
