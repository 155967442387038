import { Dispatch, SetStateAction, useState } from 'react';
import { GameType as GameTypeEnum } from '../../../../models/games/enums/GameType';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import {
    Grid,
    Button,
    Menu,
    MenuItem,
    Checkbox,
    ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type GameTypeSelectorProp = {
    dropdown: number[];
    gameType: Array<GameTypeEnum | -1>;
    playButtonAudio: () => void;
    setGameType: Dispatch<SetStateAction<Array<GameTypeEnum | -1>>>;
};
export const GameTypeSelector = (props: GameTypeSelectorProp) => {
    const { gameType, setGameType, dropdown, playButtonAudio } = props;
    const id = 'game-type-menu';
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    // const [dropdown, setDropdown] = useState<Array<GameTypeEnum>>([
    //     GameTypeEnum.None,
    // ]);
    const open = Boolean(anchorEl);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        playButtonAudio();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChangeType = (c: GameTypeEnum | -1) => {
        let newGameType = Array.from(gameType);
        if (c === -1) {
            newGameType = [-1];
        } else {
            if (newGameType.length > 0 && newGameType[0] === -1) {
                newGameType = dropdown.filter(g => {
                    if (g === -1) return false;
                    if (g === c) return false;
                    return true;
                });
            } else {
                if (newGameType.includes(c)) {
                    newGameType = newGameType.filter(g => g !== c);
                } else {
                    newGameType.push(c);
                }
                if (
                    newGameType.length === dropdown.length - 1 ||
                    newGameType.length <= 0
                ) {
                    newGameType = [-1];
                }
            }
        }
        setGameType(newGameType);
        playButtonAudio();
    };
    return (
        <Grid item className="game-type-selector" sx={{ marginLeft: '24px' }}>
            <Button
                aria-controls={open ? id : undefined}
                aria-haspopup={true}
                aria-expanded={open ? true : undefined}
                variant="contained"
                disableElevation
                onClick={handleOpen}
                endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                sx={{
                    textTransform: 'none'
                }}
            >
                {/* {t(getString(gameType[0]))} */}
                {gameType.length >= 0 && gameType[0] === -1 ? (
                    <>{t(getString(-1))}</>
                ) : (
                    <>{t('betlog.selectedGame')}</>
                )}
            </Button>
            <Menu
                id={id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {dropdown.map(c => {
                    let checked = gameType.includes(c) || gameType.includes(-1);
                    return (
                        <MenuItem
                            onClick={() => handleChangeType(c)}
                            value={c}
                            key={`game-type-selector-${c}`}
                        >
                            <Checkbox checked={checked} />
                            <ListItemText primary={t(getString(c))} />
                        </MenuItem>
                    );
                })}
            </Menu>
        </Grid>
    );
};

const getString = (c: GameTypeEnum | -1) => `betlog.gameCode.${c}`;
