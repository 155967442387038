import { Box, Button, Stack, SxProps } from '@mui/material';
import '../../../../../assets/image/common/roulette/roulette.scss';
import { BetAreaContainer as BetArea1 } from './BetArea/Type1/BetArea';
import { TapAreaContainer as TapArea1 } from './BetArea/Type1/TapArea';
import { BetAreaContainer as BetArea2 } from './BetArea/Type2/BetArea';
import { TapAreaContainer as TapArea2 } from './BetArea/Type2/TapArea';
import { RouletteBeatAreaBg1, RouletteBeatAreaBg2 } from './svg';

import { useContext, useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import BaseImage from '../../../../../components/common/baseImage';
import ConfirmPanel from '../../../../../components/common/confirmPanel';
import { AudioPlayContext } from '../../../../../contexts/AudioPlayContext';
import { BetChipContext } from '../../../../../contexts/BetChipContext';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import { WebSocketContext } from '../../../../../contexts/WebSocketContext';
import { useBalance } from '../../../../../hooks/useBalance';
import { useBetSource } from '../../../../../hooks/useBetSource';
import { StateMsgType } from '../../../../../models/Popup';
import { CMDPsBet } from '../../../../../models/cmd/live';
import { CMDBet } from '../../../../../models/cmd/live/CMDBet';
import { GameState } from '../../../../../models/games/enums/GameState';
import { Bet } from '../../../../../models/host/BetAmount';
import { RootState } from '../../../../../store/store';
import { checkBetsAvailed } from '../../../../../utils/commonFunc';
import {
    getCurrentGameId,
    getGameSate,
    getPendingBets,
    getTotalPendingBetsAmount,
    getWaitingBetResult,
    isReBetOn,
} from '../../../../games/selector';
import { gameSliceActions } from '../../../../games/slice';
import { getHostById } from '../../../../host/slice';
import { inGamePopupSliceActions } from '../../../../popup/inGameSlice';
import { RouletteHistoryInfoTable } from '../../HistoryInfo/RouletteHistoryInfoTable';
import { TotalBetAmount } from '../../TotalBetAmount';
import { ChipList } from '../Baccarat/ChipList';
import { ChipSelector } from '../Baccarat/ChipSelector';
import { DoubleBetButton } from '../Baccarat/DoubleBetButton';
import { ReBetButton } from '../Baccarat/ReBetButton';
import { amountOverBetLimit } from './utils';

const animDuration: number = 0.5;
const BET_MODE_1: number = 1;
const BET_MODE_2: number = 2;

export const RouletteBetAreaPanel = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const { IsRest, CurrentState, ResultReleased, CurrentResult, GameType } =
        useSelector((state: RootState) => getHostById(state, hostId));
    const { selectedBetRule } = useContext(BetChipContext);
    const betSource = useBetSource();
    const [curBetMode, setCurBetMode] = useState<number>(BET_MODE_1);
    const [isExpendMode, setIsExpendMode] = useState<boolean>(false);
    const [isBetting, setIsBetting] = useState<boolean>(false);
    const [isShowReBet, setIsShowReBet] = useState<boolean>(false);
    const [isPendingBet, setIsPendingBet] = useState<boolean>(false);
    const [gameId, setGameId] = useState<number>(-1);

    const transitionEnded = useRef<boolean>(true);

    const reBetButtonEnable = useSelector((state: RootState) =>
        isReBetOn(state)
    );

    const [doubleBets, setDoubleBets] = useState<Array<Bet>>([]);
    const [betAreaSvg, setBetAreaSvg] = useState(<RouletteBeatAreaBg1 />);
    const [tapArea, setTapArea] = useState<JSX.Element>(<TapArea1 />);
    const [betArea, setBetArea] = useState<JSX.Element>(<BetArea1 />);

    const { doubleBet, reBet, prevBets, pendingBets, confirmedBets } =
        useSelector(getGameSate);
    useEffect(() => {
        const bets = new Array<Bet>();
        for (const cb of confirmedBets) {
            bets.push({ Type: cb.Type, Amount: cb.Amount, GameId: cb.GameId });
        }
        for (const pb of pendingBets) {
            const index = bets.findIndex(cb => cb.Type === pb.Type);
            if (index === -1) bets.push(pb);
            else bets[index].Amount += pb.Amount;
        }
        setDoubleBets(bets);
    }, [pendingBets, confirmedBets]);
    const addingBet = useRef(false);
    const [toAddBets, setToAddBets] = useState<Array<Bet>>();
    const allPendingBetsAmount = useSelector(getTotalPendingBetsAmount);
    const { availableBalance } = useBalance();
    const currentGameId = useSelector((state: RootState) =>
        getCurrentGameId(state)
    );

    const doPreBet = (bets: Array<Bet>) => {
        batch(() => {
            bets.forEach(bet => {
                dispatch(
                    gameSliceActions.onPendingBet({
                        GameId: currentGameId,
                        Type: bet.Type,
                        Amount: bet.Amount,
                    })
                );
            });
        });
    };

    useEffect(() => {
        if (doubleBet) {
            if (doubleBets && !toAddBets && !addingBet.current && isBetting) {
                addingBet.current = true;
                setToAddBets(doubleBets);
            }
            dispatch(gameSliceActions.onDoubleBet({ onClick: false }));
        }
    }, [doubleBet, doubleBets]);

    useEffect(() => {
        if (reBet) {
            if (prevBets && !toAddBets && !addingBet.current && isBetting) {
                addingBet.current = true;
                setToAddBets(prevBets);
            }
            dispatch(gameSliceActions.onReBet({ onClick: false }));
        }
    }, [reBet, prevBets]);
    useEffect(() => {
        if (toAddBets) {
            const availableBets = checkBetsAvailed({
                hostId: hostId,
                bets: toAddBets,
                amountOverBetLimit: amountOverBetLimit,
                totalPendingBetAmount: allPendingBetsAmount,
                totalPendingWithHoldAmount: 0,
                availableBalance: availableBalance,
            });
            if (availableBets.isNotEnoughMoney) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'system.not_enough_money',
                        GameType,
                        StateMsgType.betInfo_Fail,
                        currentGameId
                    )
                );
            } else if (availableBets.isOutOfBetLimit) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'system.bet_out_of_limit_red',
                        GameType,
                        StateMsgType.betInfo_Fail,
                        currentGameId
                    )
                );
            } else {
                const toBets = availableBets.bets;
                if (availableBets.haveDisabledBetType) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.have_cannot_rebet_zone',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                } else if (availableBets.haveBetLimitAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.bet_exceed_limit_allin',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                } else if (availableBets.isAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.all_in',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                }
                doPreBet(toBets);

                dispatch(gameSliceActions.onPendingBetHistory(toBets));
            }
            setToAddBets(undefined);
            addingBet.current = false;
        }
    }, [toAddBets]);

    const allPendingBets = useSelector((state: RootState) =>
        getPendingBets(state)
    );

    const dispatch = useDispatch();
    const { sendCommand } = useContext(WebSocketContext);

    useEffect(() => {
        setIsPendingBet(
            allPendingBets && allPendingBets.length > 0 ? true : false
        );
    }, [allPendingBets]);

    useEffect(() => {
        if (IsRest) {
            setIsBetting(false);
            return;
        }
        setIsBetting(CurrentState === GameState.Betting);
        setIsExpendMode(CurrentState === GameState.Betting);
    }, [IsRest, CurrentState, ResultReleased]);

    useEffect(() => {
        setIsShowReBet(isBetting ? reBetButtonEnable : false);
    }, [reBetButtonEnable, isBetting]);

    useEffect(() => {
        if (CurrentResult && CurrentResult.GameID !== gameId) {
            setGameId(CurrentResult.GameID);
        }
    }, [CurrentResult]);

    const onChangeBetMode = () => {
        playButtonAudio();
        setCurBetMode(curBetMode == BET_MODE_1 ? BET_MODE_2 : BET_MODE_1);
    };

    const onChangeExpendMode = () => {
        if (isBetting && transitionEnded.current) {
            playButtonAudio();

            transitionEnded.current = false;
            setTimeout(() => {
                transitionEnded.current = true;
            }, 1000);

            setIsExpendMode(!isExpendMode);
        }
    };

    const getSwitchBetModeClassName = () => {
        return curBetMode === BET_MODE_1
            ? 'roulette roul_switch_betting_mode1_png'
            : 'roulette roul_switch_betting_mode2_png';
    };

    const getExpendModeClassName = () => {
        return isExpendMode ? 'roulette btn_collapse' : 'roulette btn_expend';
    };

    const isWaitingBetResult = useSelector((state: RootState) =>
        getWaitingBetResult(state)
    );
    // const getBetAreaBgImageClassName = () => {
    //     return curBetMode == BET_MODE_1
    //         ? 'roulette roul_vert_betarea1_png'
    //         : 'roulette roul_vert_betarea2_png';
    // };

    const [betAreaBgStyle, setBetAreaBgStyle] = useState<SxProps>({
        width: '342px',
        height: '690px',
        bottom: '50px',
        left: '88px',
        position: 'absolute',
        scale: isExpendMode ? '1.13' : '0.73',
        transitionDuration: `0s`,
        transformOrigin: 'bottom',
    });
    const [betAreaStyle, setBetAreaStyle] = useState<SxProps>({
        width: '332px',
        height: '690px',
        bottom: '50px',
        left: '99px',
        position: 'absolute',
        scale: isExpendMode ? '1.13' : '0.73',
        transitionDuration: `0s`,
        transformOrigin: 'bottom',
    });

    useEffect(() => {
        setBetAreaSvg(
            curBetMode === BET_MODE_1 ? (
                <RouletteBeatAreaBg1 />
            ) : (
                <RouletteBeatAreaBg2 />
            )
        );
        setTapArea(curBetMode === BET_MODE_1 ? <TapArea1 /> : <TapArea2 />);
        setBetArea(curBetMode === BET_MODE_1 ? <BetArea1 /> : <BetArea2 />);
    }, [curBetMode]);
    useEffect(() => {
        switch (curBetMode) {
            case BET_MODE_1:
            default:
                setBetAreaBgStyle({
                    width: '342px',
                    height: '690px',
                    bottom: '50px',
                    left: '88px',
                    position: 'absolute',
                    scale: isExpendMode ? '1.13' : '0.73',
                    transitionDuration: `${animDuration}s`,
                    transformOrigin: 'bottom',
                });
                setBetAreaStyle({
                    width: '342px',
                    height: '690px',
                    bottom: '50px',
                    left: '88px',
                    position: 'absolute',
                    scale: isExpendMode ? '1.13' : '0.73',
                    transitionDuration: `${animDuration}s`,
                    transformOrigin: 'bottom',
                });
                break;
            case BET_MODE_2:
                setBetAreaBgStyle({
                    width: '332px',
                    height: '690px',
                    bottom: '50px',
                    left: '99px',
                    position: 'absolute',
                    scale: isExpendMode ? '1.13' : '0.73',
                    transitionDuration: `${animDuration}s`,
                    transformOrigin: 'bottom',
                });
                setBetAreaStyle({
                    width: '332px',
                    height: '690px',
                    bottom: '50px',
                    left: '96px',
                    position: 'absolute',
                    scale: isExpendMode ? '1.13' : '0.73',
                    transitionDuration: `${animDuration}s`,
                    transformOrigin: 'bottom',
                });
                break;
        }
    }, [transitionEnded, isExpendMode]);

    const handleConfirmClick = () => {
        if (!selectedBetRule) return;

        if (isWaitingBetResult || !isBetting) return;

        // TODO: DRY
        if (allPendingBets && allPendingBets.length > 0) {
            let belowBetLimit = false;
            const cmd = new CMDPsBet();
            cmd.gameID = gameId;
            cmd.BetSource = betSource;
            allPendingBets.forEach(pb => {
                let cmdBet = new CMDBet();
                cmdBet.betType = pb.Type;
                cmdBet.betAmount = pb.Amount;
                if (pb.Amount < selectedBetRule.MinBet) {
                    belowBetLimit = true;
                    if (
                        confirmedBets &&
                        confirmedBets.findIndex(cb => cb.Type === pb.Type) >= 0
                    ) {
                        belowBetLimit = false;
                    }
                }
                cmd.bets.push(cmdBet);
            });
            if (belowBetLimit) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'system.bet_below_win_bet',
                        GameType,
                        StateMsgType.betInfo_Fail,
                        currentGameId
                    )
                );
            } else {
                sendCommand(cmd);
                dispatch(gameSliceActions.setWaitingBetResult(true));
            }
        }
        if (isBetting && isPendingBet) {
            playButtonAudio();
        }
        dispatch(gameSliceActions.resetSelectedBetType());
    };

    const handleCancelClick = () => {
        // TODO: DRY
        if (isBetting && isPendingBet) {
            playButtonAudio();
        }
        batch(() => {
            dispatch(gameSliceActions.onCancelPendingBet());
            dispatch(gameSliceActions.resetSelectedBetType());
        });
    };

    const onUndo = () => {
        if (!isBetting) return;

        if (isPendingBet) {
            playButtonAudio();
        }
        dispatch(gameSliceActions.onPopPendingBet());
    };

    return (
        <Stack
            sx={{
                marginTop: '331px',
                width: '540px',
                height: '556px',
                background: 'rgba(3, 64, 30, 1)',
            }}
        >
            <Box
                sx={{
                    width: '540px',
                    height: '556px',
                    position: 'relative',
                    scale: '1',
                }}
            >
                {/* Background Image */}
                <Box sx={betAreaBgStyle}>
                    {betAreaSvg}
                    {/* <BaseImage
                        className={getBetAreaBgImageClassName()}
                        scale={0.46}
                    /> */}
                </Box>

                {/* Tap Area */}
                <Box sx={betAreaStyle}>{tapArea}</Box>

                {/* Bet Area */}
                <Box sx={{ ...betAreaStyle, pointerEvents: 'none' }}>
                    {betArea}
                </Box>

                <Stack
                    position={'absolute'}
                    direction={'row'}
                    width={'540px'}
                    justifyContent={'center'}
                    sx={{
                        bottom: '9px',
                    }}
                >
                    <TotalBetAmount gameType={GameType} />
                </Stack>
                <Button
                    onClick={onChangeBetMode}
                    sx={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '5px',
                    }}
                >
                    <BaseImage className={getSwitchBetModeClassName()} />
                </Button>
                <Button
                    onClick={onChangeExpendMode}
                    sx={{ position: 'absolute', bottom: '75px', left: '5px' }}
                >
                    <BaseImage
                        className={getExpendModeClassName()}
                        opacity={isBetting ? 1 : 0.5}
                    />
                </Button>
                <Stack
                    position={'absolute'}
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    spacing={1}
                    sx={{ right: '0px', top: '10px' }}
                >
                    <ChipSelector />
                    <ChipList isHorizontal={false} />
                    {isShowReBet ? <ReBetButton /> : <DoubleBetButton />}
                    <Stack onClick={onUndo}>
                        <BaseImage
                            className="commonUI btnUndo"
                            opacity={isPendingBet && isBetting ? 1 : 0.5}
                        />
                    </Stack>
                </Stack>
                <Stack
                    position={'absolute'}
                    sx={{ bottom: '9px', right: '0px' }}
                >
                    <ConfirmPanel
                        onConfirmClick={handleConfirmClick}
                        onCancelClick={handleCancelClick}
                        scale={0.8}
                        isRowReverse={true}
                        isEnable={isBetting && isPendingBet}
                    />
                </Stack>
                <Stack
                    position={'absolute'}
                    sx={{
                        top: 228,
                        left: -5,
                        width: 68,
                    }}
                >
                    <RouletteHistoryInfoTable />
                </Stack>
            </Box>
        </Stack>
    );
};
