import { useContext, useEffect, useMemo, useState } from 'react';
import BaseImage from '../../../components/common/baseImage';
import { REPORT_COLORS, ROAD_MAP_TYPE } from '../../lobby/constants';
import './index.scss';
import BeadRoadCell from './cells/BeadRoadCell';
import PointRoadCell from './cells/PointRoadCell';
import BigSmallRoadCell from './cells/BigSmallRoadCell';
import EvenOddRoadCell from './cells/EvenOddRoadCell';
import { useRoadMapState } from './hook';
import { getMappedRoad } from './util';
import { AppBar, Tabs, Tab, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TabPanel } from '../../lobby/components/GameTables';
import TrialImage from '../../game/components/TrialImage';
import { AudioPlayContext } from '../../../contexts/AudioPlayContext';
type SedieRoadMapProps = {
    hostId: number;
    roadTypeSelected?: number;
};
export const SedieRoadMap = (props: SedieRoadMapProps) => {
    const { hostId } = props;
    const {
        beadRoadRecord,
        setBeadRoadRecord,
        evenOddRoadRecord,
        setEvenOddRoadRecord,
        isOneColumn,
        beadRoad,
        IsRest,
    } = useRoadMapState(hostId);

    const containerClass = useMemo(
        () =>
            isOneColumn
                ? 'sedie-oneColumnMainContainer'
                : 'sedie-twoColumnMainContainer',
        [isOneColumn]
    );
    const roadMapClass = useMemo(
        () => (isOneColumn ? 'road_andarBahar' : 'road_viewColumn'),
        [isOneColumn]
    );
    const beadRR = useMemo(
        () =>
            getMappedRoad(
                beadRoad,
                ROAD_MAP_TYPE.BEAD_ROAD,
                isOneColumn ? 6 : 16,
                6
            ),
        [beadRoad, isOneColumn]
    );
    const evenOddRR = useMemo(
        () => getMappedRoad(beadRoad, ROAD_MAP_TYPE.EVEN_ODD_ROAD, 10, 6),
        [beadRoad]
    );

    useEffect(() => {
        if (!IsRest) {
            setBeadRoadRecord(beadRR);
            setEvenOddRoadRecord(evenOddRR);
        } else {
            setBeadRoadRecord(undefined);
            setEvenOddRoadRecord(undefined);
        }
        console.log('roadmap::update', hostId);
    }, [beadRoad, isOneColumn, IsRest]);
    return (
        <div className={containerClass}>
            <BaseImage
                className={`lobby ${roadMapClass}`}
                scale={isOneColumn ? 1.12 : 1}
            />
            <div className="sedie-beadRoad">
                {beadRoadRecord?.map((record, idx) => (
                    <BeadRoadCell
                        size={isOneColumn ? 32 : 16}
                        record={record}
                        key={`bead-road-cell-${idx}`}
                    />
                ))}
            </div>
            {isOneColumn && (
                <div className="sedie-evenOddRoad">
                    {evenOddRoadRecord?.map((record, idx) => (
                        <EvenOddRoadCell
                            size={31.7}
                            record={record}
                            key={`even-odd-road-cell-${idx}`}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export const SedieSwitchTableMap = (props: SedieRoadMapProps) => {
    const { hostId } = props;
    const { beadRoad, IsRest } = useRoadMapState(hostId);
    const [evenOddRoadCells, setEvenOddRoadCells] = useState<JSX.Element>(
        <></>
    );

    useEffect(() => {
        const cells = new Array<JSX.Element>();
        if (!IsRest) {
            const evenOddRR = getMappedRoad(
                beadRoad,
                ROAD_MAP_TYPE.EVEN_ODD_ROAD,
                11,
                6
            );

            let index = 0;
            for (const record of evenOddRR) {
                const cell = (
                    <EvenOddRoadCell
                        size={15}
                        record={record}
                        key={`even-odd-road-cell-${index}`}
                    />
                );
                cells.push(cell);
                index++;
            }
        }
        setEvenOddRoadCells(<>{cells}</>);
    }, [beadRoad, IsRest]);

    return (
        <div className="sedie-SwitchTableMapMainContainer">
            <BaseImage
                className="tradbaccRoadmapBg hori_allroad_ver2_android"
                scale={1}
            />
            <div className="sedie-evenOddRoad">{evenOddRoadCells}</div>
        </div>
    );
};

export const SedieInGameMap = (props: SedieRoadMapProps) => {
    const { hostId, roadTypeSelected } = props;

    const roadMap = () => {
        switch (roadTypeSelected) {
            case ROAD_MAP_TYPE.BEAD_ROAD:
                return <SedieBeadRoadMap hostId={hostId} />;
            case ROAD_MAP_TYPE.POINT_ROAD:
                return <SediePointRoadMap hostId={hostId} />;
            case ROAD_MAP_TYPE.BIG_SMALL_ROAD:
                return <SedieBigSmallRoadMap hostId={hostId} />;
            case ROAD_MAP_TYPE.EVEN_ODD_ROAD:
                return <SedieEvenOddRoadMap hostId={hostId} />;
        }
    };

    return (
        <div className="sedie-SedieInGameMainContainer">
            <BaseImage
                className="tradbaccRoadmapBg bg_roadmap-markerroad"
                scale={0.5}
            />{' '}
            {roadMap()}
        </div>
    );
};

const SedieBeadRoadMap = (props: SedieRoadMapProps) => {
    const { hostId } = props;
    const { beadRoadRecord, setBeadRoadRecord, beadRoad, IsRest } =
        useRoadMapState(hostId);
    const beadRR = useMemo(
        () => getMappedRoad(beadRoad, ROAD_MAP_TYPE.BEAD_ROAD, 19, 6),
        [beadRoad]
    );

    useEffect(() => {
        if (!IsRest) {
            setBeadRoadRecord(beadRR);
        } else {
            setBeadRoadRecord(undefined);
        }
    }, [beadRoad, IsRest]);

    return (
        <div className="sedie-road">
            {beadRoadRecord?.map((record, idx) => (
                <BeadRoadCell
                    size={28}
                    record={record}
                    key={`bead-road-cell-${idx}`}
                />
            ))}
        </div>
    );
};

const SediePointRoadMap = (props: SedieRoadMapProps) => {
    const { hostId } = props;
    const { pointRoadRecord, setPointRoadRecord, beadRoad, IsRest } =
        useRoadMapState(hostId);
    const pointRR = useMemo(
        () => getMappedRoad(beadRoad, ROAD_MAP_TYPE.POINT_ROAD, 19, 6),
        [beadRoad]
    );

    useEffect(() => {
        if (!IsRest) {
            setPointRoadRecord(pointRR);
        } else {
            setPointRoadRecord(undefined);
        }
    }, [beadRoad, IsRest]);

    return (
        <div className="sedie-road">
            {pointRoadRecord?.map((record, idx) => (
                <PointRoadCell
                    size={28}
                    record={record}
                    key={`point-road-cell-${idx}`}
                />
            ))}
        </div>
    );
};

const SedieBigSmallRoadMap = (props: SedieRoadMapProps) => {
    const { hostId } = props;
    const { bigSmallRoadRecord, setBigSmallRoadRecord, beadRoad, IsRest } =
        useRoadMapState(hostId);
    const bigSmallRR = useMemo(
        () => getMappedRoad(beadRoad, ROAD_MAP_TYPE.BIG_SMALL_ROAD, 19, 6),
        [beadRoad]
    );

    useEffect(() => {
        if (!IsRest) {
            setBigSmallRoadRecord(bigSmallRR);
        } else {
            setBigSmallRoadRecord(undefined);
        }
    }, [beadRoad, IsRest]);

    return (
        <div className="sedie-road">
            {bigSmallRoadRecord?.map((record, idx) => (
                <BigSmallRoadCell
                    size={28}
                    record={record}
                    key={`big-small-road-cell-${idx}`}
                />
            ))}
        </div>
    );
};

const SedieEvenOddRoadMap = (props: SedieRoadMapProps) => {
    const { hostId } = props;
    const { evenOddRoadRecord, setEvenOddRoadRecord, beadRoad, IsRest } =
        useRoadMapState(hostId);
    const evenOddRR = useMemo(
        () => getMappedRoad(beadRoad, ROAD_MAP_TYPE.EVEN_ODD_ROAD, 19, 6),
        [beadRoad]
    );

    useEffect(() => {
        if (!IsRest) {
            setEvenOddRoadRecord(evenOddRR);
        } else {
            setEvenOddRoadRecord(undefined);
        }
    }, [beadRoad, IsRest]);

    return (
        <div className="sedie-road">
            {evenOddRoadRecord?.map((record, idx) => (
                <EvenOddRoadCell
                    size={28}
                    record={record}
                    key={`even-odd-road-cell-${idx}`}
                />
            ))}
        </div>
    );
};

export const SedieRoadMapPanel = (props: SedieRoadMapProps) => {
    const { hostId } = props;
    const { playButtonAudio } = useContext(AudioPlayContext);
    const { t } = useTranslation();

    const [tableInfoTab, setTableInfoTab] = useState<number>(0);
    const handleChange = (v: number) => {
        setTableInfoTab(v);
        playButtonAudio();
    };
    return (
        <>
            <Box className="sedie-road-map-table-info">
                <AppBar>
                    <Tabs
                        className="sedie-table-info-tabs"
                        value={tableInfoTab}
                        onChange={(_, v) => {
                            handleChange(v);
                        }}
                        aria-label="roadmap & table info."
                        sx={{ '& Button': { color: REPORT_COLORS.TITLE_TEXT } }}
                    >
                        <Tab
                            className="sedie-table-info-road-tab"
                            label={t('road.beads')}
                        />
                        <Tab
                            className="sedie-table-info-road-tab"
                            label={t('road.points')}
                        />
                        <Tab
                            className="sedie-table-info-road-tab"
                            label={t('sedie.big_small')}
                        />
                        <Tab
                            className="sedie-table-info-road-tab"
                            label={t('sedie.odd_even')}
                        />
                    </Tabs>
                </AppBar>
                <TabPanel value={tableInfoTab} index={0}>
                    <SedieInGameMap
                        hostId={hostId}
                        roadTypeSelected={ROAD_MAP_TYPE.BEAD_ROAD}
                    />
                </TabPanel>
                <TabPanel value={tableInfoTab} index={1}>
                    <SedieInGameMap
                        hostId={hostId}
                        roadTypeSelected={ROAD_MAP_TYPE.POINT_ROAD}
                    />
                </TabPanel>
                <TabPanel value={tableInfoTab} index={2}>
                    <SedieInGameMap
                        hostId={hostId}
                        roadTypeSelected={ROAD_MAP_TYPE.BIG_SMALL_ROAD}
                    />
                </TabPanel>
                <TabPanel value={tableInfoTab} index={3}>
                    <SedieInGameMap
                        hostId={hostId}
                        roadTypeSelected={ROAD_MAP_TYPE.EVEN_ODD_ROAD}
                    />
                </TabPanel>
                <TrialImage />
            </Box>
        </>
    );
};
