import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ResourceContext } from '../../../../contexts/ResourceContext';
import { useCurrentTime } from '../../../../hooks/useCurrentTime';
import { getGoodRoadHostIds } from '../../../host/slice';
import { getLobbyStateHallGameTypeMap } from '../../selector';
import { HallGameTypeMap } from '../../slice';
import TableCell from './TableCell';

type FeaturedTablesProps = {
    pullToRefresh: boolean;
};
export const FeaturedTables = (props: FeaturedTablesProps) => {
    const { pullToRefresh } = props;
    const { currentTimeStamp } = useCurrentTime();
    const { featureSection, featureNewTables } = useContext(ResourceContext);
    const goodRoadHostIds = useSelector(getGoodRoadHostIds);
    const gameTypeHallMap = useSelector(getLobbyStateHallGameTypeMap);
    const [display, setDisplay] = useState(<></>);

    useEffect(() => {
        const cells = new Array<JSX.Element>();
        const now = currentTimeStamp();
        const list = gameTypeHallMap
            .filter((h: HallGameTypeMap) => {
                if (!featureSection) return false;
                if (featureSection.length === 0) return false;
                if (featureSection.includes(h.hostId.toString())) return true;
                if (
                    featureNewTables &&
                    featureNewTables.find(
                        item => item.hostId == h.hostId && item.ts > now
                    )
                )
                    return true;
                if (
                    featureSection.includes('goodRoad') &&
                    goodRoadHostIds.includes(h.hostId)
                )
                    return true;
                return false;
            })
            .sort((a: HallGameTypeMap, b: HallGameTypeMap) => {
                const aHostId: string = a.hostId.toString();
                const bHostId: string = b.hostId.toString();
                if (!featureSection) return 0;

                if (
                    goodRoadHostIds.includes(a.hostId) &&
                    (featureSection.includes(bHostId) ||
                        featureNewTables.find(item => item.hostId == b.hostId))
                ) {
                    return 1;
                }

                if (
                    featureNewTables.find(item => item.hostId == a.hostId) &&
                    featureSection.includes(bHostId)
                ) {
                    return 1;
                }

                if (
                    featureNewTables.find(item => item.hostId == a.hostId) &&
                    featureNewTables.find(item => item.hostId == b.hostId)
                ) {
                    return (
                        featureNewTables.findIndex(
                            item => item.hostId == a.hostId
                        ) -
                        featureNewTables.findIndex(
                            item => item.hostId == b.hostId
                        )
                    );
                }

                if (
                    goodRoadHostIds.includes(a.hostId) &&
                    goodRoadHostIds.includes(b.hostId)
                ) {
                    return 0;
                }

                if (
                    featureSection.includes(aHostId) &&
                    featureSection.includes(bHostId)
                ) {
                    return (
                        featureSection.indexOf(aHostId) -
                        featureSection.indexOf(bHostId)
                    );
                }

                return -1;
            })
            .map(h => h.hostId);

        for (const l of list) {
            const cell = <TableCell key={`baccarat-cell-${l}`} hostId={l} />;
            cells.push(cell);
        }
        setDisplay(<>{cells}</>);
        console.log('app::feature::table', list, pullToRefresh);
    }, [pullToRefresh, gameTypeHallMap]);

    return (
        <>
            <Grid
                container
                alignContent="flex-start"
                justifyContent="space-between"
            >
                {display}
            </Grid>
        </>
    );
};
