import { Grid } from '@mui/material';
import { useContext } from 'react';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import { BlackjackInGameCountdown } from '../../../../countdown/BlackjackCountdown';
import { DoubleDownDecisionButton } from './Decision/DoubleDownDecisionButton';
import { HitDecisionButton } from './Decision/HitDecisionButton';
import { SplitDecisionButton } from './Decision/SplitDecisionButton';
import { StandDecisionButton } from './Decision/StandDecisionButton';
import { SurrenderDecisionButton } from './Decision/SurrenderDecisionButton';
import './DecisionPanel.scss';

export const DecisionPanel = () => (
    <Grid container className="blackjack-decision-panel">
        <Grid item xs={2}>
            <DecisionCountDown />
        </Grid>
        <Grid item xs={10}>
            <DecisionButtonGroup />
        </Grid>
    </Grid>
);

const DecisionCountDown = () => {
    const { hostId } = useContext(VideoPlayerContext);
    return <BlackjackInGameCountdown hostId={hostId} />;
};

const DecisionButtonGroup = () => (
    <Grid
        container
        justifyContent="space-around"
        flexWrap={'nowrap'}
        alignItems={'flex-start'}
    >
        <DoubleDownDecisionButton />
        <HitDecisionButton />
        <StandDecisionButton />
        <SurrenderDecisionButton />
        <SplitDecisionButton />
    </Grid>
);
