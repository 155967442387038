import { KeyboardArrowUp } from '@mui/icons-material';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Stack,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseImage from '../../../../../components/common/baseImage';
import { AudioPlayContext } from '../../../../../contexts/AudioPlayContext';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import { BetType } from '../../../../../models/games/sicbo/BetType';
import { RootState } from '../../../../../store/store';
import { ExchangeBetAmount } from '../../../../../utils/commonFunc';
import { limitRedFormatter } from '../../../../../utils/formatter';
import { getHostById } from '../../../../host/slice';
import { REPORT_COLORS } from '../../../../lobby/constants';
import {
    getMainLimitRedState,
    getMainUserState,
} from '../../../../main/selector';
import { CancelButton } from '../../../../setting/components/CancelButton';
import { getMaxBetByBetType } from '../util';

type InfoProps = {
    title: string;
    value: string | number;
};
type TableInfoTypeRow1 = {
    table: InfoProps;
    dealer: InfoProps;
    round: InfoProps;
    id: InfoProps;
    tableLimit: InfoProps;
};
type TableInfoTypeRow2 = {
    betLimit: InfoProps;
    maxLimit: InfoProps;
    minLimit: InfoProps;
    bigSmall: InfoProps;
    oddEven: InfoProps;
};
type TableInfoTypeRow3 = {
    anyTriple: InfoProps;
    speTriple: InfoProps;
    pair: InfoProps;
    numBet: InfoProps;
};
type TableInfoType = [TableInfoTypeRow1, TableInfoTypeRow2, TableInfoTypeRow3];
const initTableInfo = [
    {
        table: {
            title: 'tableinfo.table',
            value: '',
        },
        dealer: {
            title: 'tableinfo.dealer',
            value: '',
        },
        round: {
            title: 'tableinfo.shoe',
            value: '',
        },
        id: {
            title: 'tableinfo.round',
            value: '',
        },
        tableLimit: {
            title: 'tableinfo.tablelimit',
            value: '',
        },
    },
    {
        betLimit: {
            title: 'betLimit',
            value: '',
        },
        maxLimit: {
            title: 'tableinfo.maxlimit',
            value: '',
        },
        minLimit: {
            title: 'tableinfo.minlimit',
            value: '',
        },
        bigSmall: {
            title: 'tableinfo.big_small',
            value: '',
        },
        oddEven: {
            title: 'tableinfo.odd_even',
            value: '',
        },
    },
    {
        anyTriple: {
            title: 'tableinfo.any_triple',
            value: '',
        },
        speTriple: {
            title: 'tableinfo.specific_triple',
            value: '',
        },
        pair: {
            title: 'tableinfo.sicbo_pair',
            value: '',
        },
        numBet: {
            title: 'tableinfo.number_bet',
            value: '',
        },
    },
] as TableInfoType;

type TableInfoBetLimitType = {
    bigSmallOddEven: InfoProps;
    point516: InfoProps;
    anyTriple: InfoProps;
    point615: InfoProps;
    speTriple: InfoProps;
    point714: InfoProps;
    pair: InfoProps;
    point813: InfoProps;
    numBet: InfoProps;
    point912: InfoProps;
    speDouble: InfoProps;
    point1011: InfoProps;
    allOddEven: InfoProps;
    combo3Dices: InfoProps;
    oddEven21: InfoProps;
    pairSingle: InfoProps;
    point417: InfoProps;
    fourNum: InfoProps;
};
const initBetLimitInfo = {
    bigSmallOddEven: {
        title: 'tableinfo.big_small_odd_even',
        value: '',
    },
    point516: {
        title: 'tableinfo.point_5_16',
        value: '',
    },
    anyTriple: {
        title: 'tableinfo.any_triple',
        value: '',
    },
    point615: {
        title: 'tableinfo.point_6_15',
        value: '',
    },
    speTriple: {
        title: 'tableinfo.specific_triple',
        value: '',
    },
    point714: {
        title: 'tableinfo.point_7_14',
        value: '',
    },
    pair: {
        title: 'tableinfo.sicbo_pair',
        value: '',
    },
    point813: {
        title: 'tableinfo.point_8_13',
        value: '',
    },
    numBet: {
        title: 'tableinfo.number_bet',
        value: '',
    },
    point912: {
        title: 'tableinfo.point_9_12',
        value: '',
    },
    speDouble: {
        title: 'tableinfo.specific_double',
        value: '',
    },
    point1011: {
        title: 'tableinfo.point_10_11',
        value: '',
    },
    allOddEven: {
        title: 'tableinfo.all_odd_even',
        value: '',
    },
    combo3Dices: {
        title: 'tableinfo.combo_3_dices',
        value: '',
    },
    oddEven21: {
        title: 'tableinfo.odd_even_2_1',
        value: '',
    },
    pairSingle: {
        title: 'tableinfo.pair_single',
        value: '',
    },
    point417: {
        title: 'tableinfo.point_4_17',
        value: '',
    },
    fourNum: {
        title: 'tableinfo.four_number',
        value: '',
    },
} as TableInfoBetLimitType;
export const SicBoTableInfoTab = () => {
    const { t, i18n } = useTranslation();
    const { hostId } = useContext(VideoPlayerContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { CurrentResult, DealerList, TableLimit, GameType, MaxBet } = host;
    const { ExchangeRate, CurrencyName } = useSelector((state: RootState) =>
        getMainUserState(state)
    );
    const limitRedByGameType = useSelector(getMainLimitRedState);

    const [tableInfo, setTableInfo] = useState<TableInfoType>(initTableInfo);
    const [showBetLimit, setShowBetLimit] = useState<boolean>(false);
    const [betLimitInfo, setBetLimitInfo] =
        useState<TableInfoBetLimitType>(initBetLimitInfo);

    const [languageDir, setLanguageDir] = useState<string>('ltr');
    useEffect(() => {
        setLanguageDir(i18n.dir(i18n.language));
    }, [i18n.language]);
    const handleShowBetLimit = (v: boolean) => {
        playButtonAudio();
        setShowBetLimit(v);
    };
    useEffect(() => {
        if (MaxBet) {
            //row 2
            const newTableInfo: TableInfoType = Object.create(
                tableInfo ?? initTableInfo
            );
            newTableInfo[1].bigSmall.value = getMaxBetByBetType(
                BetType.SBBBig,
                MaxBet
            );
            newTableInfo[1].oddEven.value = getMaxBetByBetType(
                BetType.SBBOdd,
                MaxBet
            );
            //row 3
            newTableInfo[2].anyTriple.value = getMaxBetByBetType(
                BetType.SBBAllTriple,
                MaxBet
            );
            newTableInfo[2].speTriple.value = getMaxBetByBetType(
                BetType.SBBTripleOne,
                MaxBet
            );
            newTableInfo[2].pair.value = getMaxBetByBetType(
                BetType.SBBShortOne,
                MaxBet
            );
            newTableInfo[2].numBet.value = getMaxBetByBetType(
                BetType.SBBTripleArmyOne,
                MaxBet
            );
            setTableInfo(newTableInfo);

            //BetLimit
            // const newBetLimitInfo: TableInfoBetLimitType =
            //     Object.create(betLimitInfo ?? initBetLimitInfo);
            betLimitInfo.bigSmallOddEven.value = getMaxBetByBetType(
                BetType.SBBSmall,
                MaxBet
            );
            betLimitInfo.point516.value = getMaxBetByBetType(
                BetType.SBBPointFive,
                MaxBet
            );
            betLimitInfo.anyTriple.value = getMaxBetByBetType(
                BetType.SBBAllTriple,
                MaxBet
            );
            betLimitInfo.point615.value = getMaxBetByBetType(
                BetType.SBBPointSix,
                MaxBet
            );
            betLimitInfo.speTriple.value = getMaxBetByBetType(
                BetType.SBBTripleOne,
                MaxBet
            );
            betLimitInfo.point714.value = getMaxBetByBetType(
                BetType.SBBPointSeven,
                MaxBet
            );
            betLimitInfo.pair.value = getMaxBetByBetType(
                BetType.SBBShortOne,
                MaxBet
            );
            betLimitInfo.point813.value = getMaxBetByBetType(
                BetType.SBBPointEight,
                MaxBet
            );
            betLimitInfo.numBet.value = getMaxBetByBetType(
                BetType.SBBTripleArmyOne,
                MaxBet
            );
            betLimitInfo.point912.value = getMaxBetByBetType(
                BetType.SBBPointNine,
                MaxBet
            );
            betLimitInfo.speDouble.value = getMaxBetByBetType(
                BetType.SBBLongOneTwo,
                MaxBet
            );
            betLimitInfo.point1011.value = getMaxBetByBetType(
                BetType.SBBPointTen,
                MaxBet
            );

            betLimitInfo.allOddEven.value = getMaxBetByBetType(
                BetType.SBAllDicesOdd,
                MaxBet
            );
            betLimitInfo.combo3Dices.value = getMaxBetByBetType(
                BetType.SB_126,
                MaxBet
            );
            betLimitInfo.oddEven21.value = getMaxBetByBetType(
                BetType.SBTwoDicesOddOneDiceEven,
                MaxBet
            );
            betLimitInfo.pairSingle.value = getMaxBetByBetType(
                BetType.SB_112,
                MaxBet
            );

            betLimitInfo.point417.value = getMaxBetByBetType(
                BetType.SBBPointFour,
                MaxBet
            );
            betLimitInfo.fourNum.value = getMaxBetByBetType(
                BetType.SB_1_2_3_4,
                MaxBet
            );
            setBetLimitInfo(betLimitInfo);
        }
    }, [MaxBet]);

    useEffect(() => {
        if (
            hostId &&
            DealerList &&
            TableLimit &&
            CurrentResult &&
            limitRedByGameType.BetRuleByGameType
        ) {
            const newTableInfo: TableInfoType = Object.create(
                tableInfo ?? null
            );
            //row 1
            newTableInfo[0].table.value = t(`hostCodeNames.${hostId}`);
            newTableInfo[0].dealer.value = DealerList[0].DealerName;
            const shoes = Math.floor(CurrentResult.GameCount / 10000);
            const round = CurrentResult.GameCount % 10000;
            const row1Round = shoes && round ? `${shoes}-${round}` : round;
            newTableInfo[0].round.value = row1Round;
            newTableInfo[0].id.value = CurrentResult.GameID || 0;
            let infoTableLimit =
                ExchangeBetAmount(TableLimit, ExchangeRate) / 100;
            const digi = Math.pow(10, infoTableLimit.toString().length - 1);
            const tableLimitValue = (
                Math.round(infoTableLimit / digi) * digi
            ).toLocaleString('en-US', {
                maximumFractionDigits: 2,
            });
            newTableInfo[0].tableLimit.value = tableLimitValue;
            //row 2
            let betLimitV = '';
            const limitRedByGame = limitRedByGameType.BetRuleByGameType?.find(
                br => br.GameType === GameType
            );
            const BetRule = limitRedByGame?.BetRule;
            let selectedBetRule = BetRule?.find(r => r.Selected === 1);
            if (!selectedBetRule && BetRule) selectedBetRule = BetRule[0]; //有機會全部selected=0
            if (selectedBetRule) {
                betLimitV = limitRedFormatter(selectedBetRule, CurrencyName);
            }
            newTableInfo[1].betLimit.value = betLimitV;
            if (selectedBetRule) {
                newTableInfo[1].maxLimit.value = (
                    selectedBetRule.LimitRed / 100
                ).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                });
                newTableInfo[1].minLimit.value = (
                    selectedBetRule.MinBet / 100
                ).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                });
            }
            setTableInfo(newTableInfo);
        }
    }, [
        hostId,
        CurrentResult,
        DealerList,
        TableLimit,
        limitRedByGameType.BetRuleByGameType,
    ]);
    return (
        <>
            {tableInfo && (
                <>
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        sx={{
                            fontSize: '14px',
                            position: 'relative',
                            width: 540,
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                            }}
                        >
                            <BaseImage
                                className="tradbaccBg table_info_bg"
                                scale={1}
                                opacity={0.5}
                                // 14.5px = (190 - 161) / 2
                                // 1.226 = 190 / 161
                                transform={
                                    'translate(0, 14.5px) scale(1, 1.18)'
                                }
                            />
                        </Box>
                        {tableInfo.map((r, i) => {
                            const style =
                                i !== 1
                                    ? { width: 185, zIndex: '1' }
                                    : {
                                          width: 170,
                                          zIndex: '1',
                                          borderLeft:
                                              '2px solid rgba(255,255,255,0.2)',
                                          borderRight:
                                              '2px solid rgba(255,255,255,0.2)',
                                      };
                            return (
                                <Box key={`info-${i}`} sx={style}>
                                    <>
                                        {Object.keys(r).map((k, ii) => {
                                            const v = r[
                                                k as keyof typeof r
                                            ] as InfoProps;
                                            return (
                                                <Grid
                                                    key={`info-cell${ii}`}
                                                    item
                                                    container
                                                    justifyContent={
                                                        'space-between'
                                                    }
                                                    alignItems={'center'}
                                                    sx={{
                                                        height: 38,
                                                        padding:
                                                            '0 7.5px 0 7.5px',
                                                    }}
                                                >
                                                    <Grid
                                                        item
                                                        sx={{
                                                            color: REPORT_COLORS.TITLE_TEXT,
                                                        }}
                                                    >
                                                        {t(v.title)}
                                                    </Grid>
                                                    <Grid item>{v.value}</Grid>
                                                </Grid>
                                            );
                                        })}
                                    </>
                                </Box>
                            );
                        })}
                        <Button
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                width: 168,
                                height: 23,
                                minHeight: 23,
                                bgcolor: '#876F4D',
                                margin: '0 8px 10px 6px',
                                zIndex: 1,
                            }}
                            onClick={() => handleShowBetLimit(true)}
                        >
                            <KeyboardArrowUp sx={{ color: '#ffffff' }} />
                        </Button>
                        <Dialog
                            container={() =>
                                document.getElementById(
                                    'in-game-popup-container'
                                )
                            }
                            fullScreen
                            open={showBetLimit}
                            onClose={() => handleShowBetLimit(false)}
                            hideBackdrop={true}
                            disableEscapeKeyDown={true}
                            PaperProps={{
                                sx: {
                                    backgroundColor: 'rgba(0,0,0,0.85)',
                                    boxShadow: 'none',
                                },
                            }}
                        >
                            <DialogTitle
                                sx={{
                                    paddingTop: '100px',
                                    fontSize: '29px',
                                    textAlign: 'center',
                                }}
                            >
                                {t('betLimit2')}
                            </DialogTitle>

                            <DialogContent
                                sx={{
                                    justifyContent: 'center',
                                    // overflowY: 'visible',
                                    padding: '20px',
                                    fontSize: 18,
                                }}
                            >
                                <Grid
                                    item
                                    container
                                    direction={'column'}
                                    justifyContent={'center'}
                                    display={'grid'}
                                    gridTemplateColumns={'repeat(2, 250px)'}
                                    gridTemplateRows={'repeat(9, 90px)'}
                                    gridAutoFlow={'row'}
                                    sx={{
                                        width: 500,
                                        height: 480,
                                        fontSize: 18,
                                        overflowY: 'auto',
                                        scrollBehavior: 'smooth',
                                        scrollbarWidth: 'none',
                                        '&::-webkit-scrollbar': {
                                            display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                                        },
                                        '&-ms-overflow-style': {
                                            display: 'none', // Hide the scrollbar for IE
                                        },
                                    }}
                                    // padding={'100px 35px'}
                                >
                                    {betLimitInfo &&
                                        Object.values(betLimitInfo).map(
                                            (v, id) => {
                                                return (
                                                    <Stack
                                                        key={`bet-limit-cell${id}`}
                                                        direction={'column'}
                                                        sx={{
                                                            maxWidth: '250px',
                                                            marginLeft: '20px',
                                                            whiteSpace:
                                                                'pre-line',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                color: REPORT_COLORS.TITLE_TEXT,
                                                                direction:
                                                                    languageDir,
                                                                textAlign:
                                                                    'left',
                                                            }}
                                                        >
                                                            {t(v.title)}
                                                        </Box>
                                                        {v.value}
                                                    </Stack>
                                                );
                                            }
                                        )}
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction={'row'}
                                    justifyContent={'center'}
                                >
                                    <CancelButton
                                        onClick={() =>
                                            handleShowBetLimit(false)
                                        }
                                        image={'commonUI ic_close'}
                                    />
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </Stack>
                </>
            )}
        </>
    );
};
