import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LobbyUiContext } from '../../../contexts/LobbyUiContext';
import { BaccaratRoadmapRecord } from '../../../models/games/baccarat';
import { RootState } from '../../../store/store';
import { getHostById } from '../../host/slice';
import { ROAD_MAP_COLORS } from '../../lobby/constants';

export const RESOLUTION = 12;
export const STROKE_WIDTH = 1.5;
export type CommonCellProps = {
    size: number;
};

export type BaccaratRoadMapCellProps = {
    record: BaccaratRoadmapRecord;
};

export type BaccaratRoadMapCellColorText = {
    color: ROAD_MAP_COLORS;
    text: string;
};

export const useBaccaratRoadMapCellColorText = (
    win: number,
    winPoint?: number
) => {
    const { t } = useTranslation();
    let color = ROAD_MAP_COLORS.GREEN;
    let text = t('road.draw');
    switch (win) {
        case 0:
            color = ROAD_MAP_COLORS.RED;
            text = t('road.banker_win');
            break;
        case 1:
            color = ROAD_MAP_COLORS.BLUE;
            text = t('road.player_win');
            break;
    }
    if (winPoint == undefined || winPoint === -1) text = '';
    return { color, text };
};

export const useBaccaratPointRoadMapCellColorText = (
    win: number,
    winPoint?: number
) => {
    let color = ROAD_MAP_COLORS.GREEN;
    let text =
        winPoint == undefined || winPoint == -1 ? '' : winPoint.toString();
    switch (win) {
        case 0:
            color = ROAD_MAP_COLORS.RED;
            break;
        case 1:
            color = ROAD_MAP_COLORS.BLUE;
            break;
    }
    return { color, text };
};

export const useRoadMapState = (hostId: number) => {
    const { grid } = useContext(LobbyUiContext);
    const { IsRest } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [askType, setAskType] = useState<number>(-1);
    const [nextRoad, setNextRoad] = useState<Array<number>>([-1, -1, -1]);
    const isOneColumn = useMemo(() => grid === 12, [grid]);
    return {
        askType,
        setAskType,
        nextRoad,
        setNextRoad,
        isOneColumn,
        IsRest,
    };
};
