import { BetAreaProps } from '../../../components/common/betArea';

export const getBetAreaProps = (betType: number): BetAreaProps => {
    const idx = BetArea.findIndex(ba => ba.betType === betType);

    if (idx >= 0) {
        return BetArea[idx];
    } else {
        return Object.assign({
            betType: betType,
        }) as BetAreaProps;
    }
};

const BetArea: Array<BetAreaProps> = [
    {
        betType: 0,
        style: {
            width: '90px',
            height: '56px',
        },
        confirmPanelStyle: {
            top: '60px',
        },
    },
    {
        betType: 1,
        style: {
            width: '90px',
            height: '56px',
        },
        confirmPanelStyle: {
            top: '60px',
        },
    },
    {
        betType: 2,
        style: {
            width: '90px',
            height: '56px',
        },
        confirmPanelStyle: {
            top: '60px',
        },
    },
    {
        betType: 3,
        style: {
            width: '90px',
            height: '56px',
        },
        confirmPanelStyle: {
            top: '60px',
        },
        roundNumDisable: 60,
    },
    {
        betType: 4,
        style: {
            width: '90px',
            height: '56px',
        },
        confirmPanelStyle: {
            top: '60px',
        },
        roundNumDisable: 60,
    },
    {
        betType: 54,
        style: {
            width: '90px',
            height: '56px',
        },
        confirmPanelStyle: {
            top: '60px',
        },
        roundNumDisable: 50,
    },
    {
        betType: 28,
        style: {
            width: '90px',
            height: '56px',
        },
        confirmPanelStyle: {
            top: '60px',
        },
        roundNumDisable: 60,
    },
    {
        betType: 53,
        style: {
            width: '90px',
            height: '56px',
        },
        confirmPanelStyle: {
            top: '60px',
        },
        roundNumDisable: 50,
    },
    {
        betType: 29,
        style: {
            width: '90px',
            height: '56px',
        },
        confirmPanelStyle: {
            top: '60px',
        },
        roundNumDisable: 60,
    },
    {
        betType: 26,
        style: {
            width: '90px',
            height: '56px',
        },
        confirmPanelStyle: {
            top: '60px',
        },
    },
    {
        betType: 25,
        style: {
            width: '90px',
            height: '56px',
        },
        confirmPanelStyle: {
            top: '60px',
        },
    },
    {
        betType: 27,
        style: {
            width: '90px',
            height: '56px',
        },
        confirmPanelStyle: {
            top: '60px',
        },
    },
];
