import { useEffect, useMemo, useState } from 'react';
import BaseImage from '../../../components/common/baseImage';
import { ROAD_MAP_TYPE } from '../../lobby/constants';
import '../index.scss';
import './andarBahar.scss';
import BeadRoadCell from './cells/BeadRoadCell';
import BigRoadCell from './cells/BigRoadCell';
import PointRoadCell from './cells/PointRoadCell';

import TrialImage from '../../game/components/TrialImage';
import { useRoadMapState } from './hook';
import { getMappedRoad } from './util';
type AndarBaharRoadMapProps = {
    hostId: number;
    roadMapTypeSelected?: ROAD_MAP_TYPE;
};
export const AndarBaharRoadMap = (props: AndarBaharRoadMapProps) => {
    const { hostId } = props;
    const {
        beadRoadRecord,
        setBeadRoadRecord,
        bigRoadRecord,
        setBigRoadRecord,
        isOneColumn,
        beadRoad,
        otherRoad,
        IsRest,
    } = useRoadMapState(hostId);
    const containerClass = useMemo(
        () =>
            isOneColumn
                ? 'AndarBaharOneColumnMainContainer'
                : 'AndarBaharTwoColumnMainContainer',
        [isOneColumn]
    );
    const roadMapClass = useMemo(
        () => (isOneColumn ? 'road_andarBahar' : 'road_viewColumn'),
        [isOneColumn]
    );
    const beadRR = useMemo(
        () => getMappedRoad(beadRoad, otherRoad, ROAD_MAP_TYPE.BEAD_ROAD, 6, 6),
        [beadRoad, otherRoad]
    );
    const bigRR = useMemo(
        () =>
            getMappedRoad(
                beadRoad,
                otherRoad,
                ROAD_MAP_TYPE.BIG_ROAD,
                isOneColumn ? 10 : 16,
                6
            ),
        [beadRoad, otherRoad, isOneColumn]
    );
    useEffect(() => {
        if (!IsRest) {
            setBeadRoadRecord(beadRR);
            setBigRoadRecord(bigRR);
        } else {
            setBeadRoadRecord(undefined);
            setBigRoadRecord(undefined);
        }
        console.log('roadmap::update', hostId);
    }, [beadRoad, otherRoad, isOneColumn, IsRest]);
    return (
        <div className={containerClass}>
            <BaseImage
                className={`lobby ${roadMapClass}`}
                scale={isOneColumn ? 1.12 : 1}
            />
            <div className="bigRoad">
                {bigRoadRecord?.map((record, idx) => (
                    <BigRoadCell
                        size={isOneColumn ? 32 : 16}
                        record={record}
                        key={`big-road-cell-${idx}`}
                    />
                ))}
            </div>
            {isOneColumn && (
                <>
                    <div className="beadRoad">
                        {beadRoadRecord?.map((record, idx) => (
                            <BeadRoadCell
                                size={32}
                                record={record}
                                key={`bead-road-cell-${idx}`}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export const AndarBaharSwitchTableMap = (props: AndarBaharRoadMapProps) => {
    const { hostId } = props;
    const { beadRoad, otherRoad, IsRest } = useRoadMapState(hostId);

    const [bigRoadCells, setBigRoadCells] = useState<JSX.Element>(<></>);
    useEffect(() => {
        const cells = new Array<JSX.Element>();

        if (!IsRest) {
            const bs = getMappedRoad(
                beadRoad,
                otherRoad,
                ROAD_MAP_TYPE.BIG_ROAD,
                11,
                6
            );
            let index = 0;
            for (const b of bs) {
                const cell = (
                    <BigRoadCell
                        size={15}
                        record={b}
                        key={`big-road-cell-${index}`}
                    />
                );
                cells.push(cell);
                index++;
            }
        }
        setBigRoadCells(<>{cells}</>);
    }, [beadRoad, otherRoad, IsRest]);
    return (
        <div className="AndarBaharSwitchTableMapMainContainer">
            <BaseImage
                className="tradbaccRoadmapBg hori_allroad_ver2_android"
                scale={1}
            />
            <div className="bigEyeRoad">
                <div className="bigRoad">{bigRoadCells}</div>
            </div>
        </div>
    );
};

export const AndarBaharInGameRoadMap = (props: AndarBaharRoadMapProps) => {
    const { hostId, roadMapTypeSelected } = props;
    const {
        beadRoadRecord,
        setBeadRoadRecord,
        bigRoadRecord,
        setBigRoadRecord,
        beadRoad,
        otherRoad,
        /*Record,*/
        IsRest,
    } = useRoadMapState(hostId);
    const beadRR = useMemo(
        () =>
            getMappedRoad(
                beadRoad,
                otherRoad,
                ROAD_MAP_TYPE.BEAD_ROAD,
                6,
                6,
                -1
            ),
        [beadRoad, otherRoad]
    );
    const bigRR = useMemo(
        () =>
            getMappedRoad(
                beadRoad,
                otherRoad,
                ROAD_MAP_TYPE.BIG_ROAD,
                13,
                6,
                -1
            ),
        [beadRoad, otherRoad]
    );

    useEffect(() => {
        if (!IsRest) {
            setBeadRoadRecord(beadRR);
            setBigRoadRecord(bigRR);
        } else {
            setBeadRoadRecord(undefined);
            setBigRoadRecord(undefined);
        }
        console.log('roadmap::update', hostId);
    }, [beadRoad, otherRoad, IsRest]);

    return (
        <div className="AndarBaharInGameMainContainer">
            <BaseImage className="lobby road_andarBahar" scale={1} />
            <div className="bigRoad">
                {bigRoadRecord?.map((record, idx) => (
                    <BigRoadCell
                        size={28.25}
                        record={record}
                        key={`big-road-cell-${idx}`}
                    />
                ))}
            </div>
            <div className="beadRoad">
                {beadRoadRecord?.map((record, idx) =>
                    roadMapTypeSelected === ROAD_MAP_TYPE.POINT_ROAD ? (
                        <PointRoadCell
                            size={28}
                            record={record}
                            key={`bead-road-cell-${idx}`}
                        />
                    ) : (
                        <BeadRoadCell
                            size={28}
                            record={record}
                            key={`bead-road-cell-${idx}`}
                        />
                    )
                )}
            </div>
            <TrialImage />
        </div>
    );
};
