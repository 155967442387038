export enum Rating {
    Null = -1,
    VeryPoor = 1,
    Poor = 2,
    Fair = 3,
    Good = 4,
    Fantastic = 5,
}
export enum Reason {
    QualityOfTheVideo = 0,
    GameGraphicInterface = 1,
    NetworkConnectionSpeed = 2,
    DealersProfessionalism = 4,
    UserFriendliness = 7,
}
