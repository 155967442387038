import { Typography, TypographyProps } from '@mui/material';
import { CSSProperties, useEffect, useRef, useState } from 'react';

type BaseTypographyProps = TypographyProps & {
    resize?:{
        direction: 'vertical'|'horizontal';
        value: string;
    }
};

export const BaseTypography = (props?: BaseTypographyProps) => {
    const { children, sx, ...rest } = props || {};

    const typographyRef = useRef<HTMLDivElement | null>(null);
    const [typographySize, setTypographySize] = useState<number>(0);
    const [direction, setDirection] = useState<string>();
    const [resizeValue, setResizeValue] = useState<number>(0);
    const [scaleRatio, setScaleRatio] = useState<number>(1);
    const [customStyle, setCustomStyle] = useState<CSSProperties>({});

    useEffect(() => {
        if (props && props.resize) {
            if(props.resize.direction) setDirection(props.resize.direction);
            if(props.resize.value) setResizeValue(parseFloat(props.resize.value));
        }
    }, [props?.resize]);

    useEffect(() => {
        if (props && props.resize) {
            if(direction === 'horizontal'){
                setCustomStyle({ whiteSpace: 'nowrap' });
            } else {
                setCustomStyle({});
            }
        }
    }, [direction])

    useEffect(() => {
        if (props && props.resize) {
            //if (props && props.resizewidth && resizeWidth > 0 && typographyRef.current) {    
            if (typographyRef.current) {
                let size = 0;
                switch(direction){
                    case 'vertical':
                        size = typographyRef.current.offsetHeight;
                        break;
                    case 'horizontal':
                        size = typographyRef.current.offsetWidth;
                        break;
                }
                setTypographySize(size);
            }
        }
    }, [children, customStyle])

    useEffect(() => {
        if (props && props.resize && props.resize.value && resizeValue > 0 && typographySize > 0) {
            const ratio = typographySize > resizeValue ? resizeValue / typographySize:1;
            if (ratio !== scaleRatio) {
                setScaleRatio(ratio);
            }
        }
    }, [typographySize, resizeValue]);

    return (
        /*<Typography ref={typographyRef} sx={{ ...(sx as CSSProperties), transform: `scale(${scaleRatio})`, ...customStyle }} {...rest}>*/
        <Typography ref={typographyRef} sx={{ ...(sx as CSSProperties), zoom: scaleRatio, ...customStyle }} {...rest}>
            {children}
        </Typography>
    );
};
