import './index.scss';
import BaseImage from '../../baseImage';
import useConfig, { chipSetType } from '../../../../hooks/useConfig';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Chip as ChipPros } from '../../../../models/games/Chip';
import { shortAmountToString } from '../../../../utils/formatter';
import { useTranslation } from 'react-i18next';

const MAX_CHIP_IN_STACK: number = 5;

const Chip = (props: ChipPros) => {
    const { chipSet } = useConfig();
    const { t } = useTranslation();

    const {
        value,
        scale = 1,
        isSelected = false,
        isShowValue = false,
        isSettingSelected = false,
        opacity = 1,
        isChipStack = true,
        withHold = 0,
    } = props;
    const [chipStack, setChipStack] = useState<Array<chipSetType>>([]);
    const [useDefaultText, setUseDefaultText] = useState<boolean>(true);
    const chipStyle = isChipStack
        ? { transform: `scale(${scale})` }
        : { zoom: scale };

    const getChipStack = (value: number): Array<chipSetType> => {
        const stack: Array<chipSetType> = [];
        let remainder = value;
        setUseDefaultText(true);

        chipSet.forEach(chip => {
            const count = Math.floor(remainder / chip.value);

            if (count > 0) {
                for (let i: number = 0; i < count; i++) {
                    stack.push(chip);
                }
                remainder = remainder % chip.value;
            }
        });

        if (!isChipStack) {
            if (stack.length > 1) {
                stack.splice(0, stack.length);
                setUseDefaultText(false);

                const idx = chipSet.findIndex(
                    chip => chip.imgKey === 'chip chip_gold_brown_png'
                );
                stack.push(chipSet[idx]);
            }
        }

        return stack;
    };

    useEffect(() => {
        setChipStack(getChipStack(value));
    }, [value]);

    const [chipElements, setChipElements] = useState<Array<JSX.Element>>([]);

    useEffect(() => {
        const ce = new Array<JSX.Element>();
        for (
            let index = 0;
            index < Math.min(MAX_CHIP_IN_STACK, chipStack.length);
            index++
        ) {
            const element = (
                <Box key={index}>
                    <Box className="chipContainer" sx={{ opacity: opacity }}>
                        <BaseImage
                            className={chipStack[index].imgKey}
                            scale={1}
                        />
                        <Typography
                            sx={{
                                fontSize:
                                    chipStack[index].displayText.length >= 5
                                        ? '18px'
                                        : '22px',
                            }}
                            className="displayText"
                        >
                            {useDefaultText
                                ? chipStack[index].displayText
                                : shortAmountToString(value / 100)}
                        </Typography>
                        {isSelected && index == 0 && (
                            <Box className="selectedEffect">
                                <BaseImage
                                    className="chip chip_select_effect"
                                    scale={1.3}
                                />
                            </Box>
                        )}
                    </Box>
                    {isSettingSelected && (
                        <Box className="settingSelectedIcon">
                            <BaseImage
                                className="chip selected_icon"
                                scale={1}
                            />
                        </Box>
                    )}
                </Box>
            );
            ce.push(element);
        }
        setChipElements(ce);
    }, [chipStack, isSelected, isSettingSelected, opacity]);

    return (
        //<Box className="ChipContainer" style={{ zoom: scale }}></Box>
        //<Box className="ChipContainer" style={{ transform: `scale(${scale})` }}>
        <Box className="ChipContainer" style={chipStyle}>
            {chipStack && (
                <Box className="chipStack">
                    {chipElements}
                    {isShowValue && value > 0 && (
                        <Box className="totalValue">
                            <Box className="container">
                                <BaseImage
                                    className="chip bet_amount_box"
                                    scale={1}
                                />
                                <Typography
                                    sx={{ fontSize: '22px' }}
                                    className="displayText"
                                >
                                    {(value / 100).toLocaleString('en-US')}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {isShowValue && withHold > 0 && (
                        <Box className="withHoldValue">
                            <Box className="container">
                                <BaseImage
                                    className="chip bet_amount_box"
                                    scale={1}
                                />
                                <Typography
                                    sx={{ fontSize: '22px' }}
                                    className="displayText"
                                >
                                    {'('}
                                    {t('game.withHold')}
                                    {((value * withHold) / 100).toLocaleString(
                                        'en-US'
                                    )}
                                    {')'}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default Chip;
