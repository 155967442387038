import { Button } from '@mui/material';
import { useContext } from 'react';
import BaseImage from '../../../components/common/baseImage';
import { LobbyUiContext } from '../../../contexts/LobbyUiContext';

export const GridButton = () => {
    const { grid, toggleGrid } = useContext(LobbyUiContext);
    return (
        <Button
            sx={{
                padding: 0,
                margin: 0,
                position: 'fixed',
                top: '680px',
                right: 0,
            }}
            onClick={toggleGrid}
        >
            <BaseImage
                className={
                    grid === 12
                        ? 'commonUI ic_2col_switch'
                        : 'commonUI ic_1col_switch'
                }
                scale={0.576}
            />
        </Button>
    );
};
