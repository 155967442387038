import { Dialog, DialogContent, DialogTitle, Grid, Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import BaseImage from '../../../../../../components/common/baseImage';
import { Chip } from '../../../../../../components/common/chips';
import { VideoPlayerContext } from '../../../../../../contexts/VideoContext';
import { WebSocketContext } from '../../../../../../contexts/WebSocketContext';
import useConfig, { chipSetType } from '../../../../../../hooks/useConfig';
import { POPUP_TYPE } from '../../../../../../models/Popup';
import { CMDPsSetLimitRedToken } from '../../../../../../models/cmd/live';
import { RootState } from '../../../../../../store/store';
import { getHostById } from '../../../../../host/slice';
import {
    getMainLimitRedState,
    getMainUserState,
    getResultState,
} from '../../../../../main/selector';
import {
    LimitRed,
    limitRedSliceActions,
} from '../../../../../main/slice/limitRed';
import { resultSliceActions } from '../../../../../main/slice/result';
import { popupSliceActions } from '../../../../../popup/slice';
import { CancelButton } from '../../../../../setting/components/CancelButton';
import { TickButton } from '../../../../../setting/components/TickButton';
import { theme } from '../../../../../../theme';
import { GameType as GameTypeEnum } from '../../../../../../models/games/enums/GameType';
import { AudioPlayContext } from '../../../../../../contexts/AudioPlayContext';
import { BaseTypography } from '../../../../../../components/common/baseText/BaseTypography';

export const ChipSelector = () => {
    const [open, setOpen] = useState<boolean>(false);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const changeOpen = (b: boolean) => {
        setOpen(b);
        playButtonAudio();
    };
    const { hostId } = useContext(VideoPlayerContext);
    const { GameType } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );

    const selectButton = () => {
        switch (GameType) {
            case GameTypeEnum.Roulette:
                return <SelectorButtonStyleB onOpen={changeOpen} />;
            default:
                return <SelectorButtonStyleA onOpen={changeOpen} />;
        }
    };

    return (
        <>
            {selectButton()}
            {open && <ChipSelectorPopup open={open} changeOpen={changeOpen} />}
        </>
    );
};

type ButtonProps = {
    onOpen: (b: boolean) => void;
};

const SelectorButtonStyleA = (props: ButtonProps) => {
    const { onOpen } = props;
    const { t } = useTranslation();

    return (
        <Stack
            justifyContent={'space-between'}
            alignItems={'center'}
            direction={'column'}
            onClick={() => onOpen(true)}
            sx={{
                backgroundColor: 'rgba(0,0,0,0.3)',
                fontSize: '14px',
                width: '70px',
                height: '60px',
                padding: '8px 0 8px 0',
                zIndex: 1,
            }}
        >
            <BaseImage className="commonUI ic_custom_chip_brown" scale={1} />
            <BaseTypography resize={{ direction: 'horizontal', value: '65px' }}>
                {t('game.chip_setting')}
            </BaseTypography>
        </Stack>
    );
};

const SelectorButtonStyleB = (props: ButtonProps) => {
    const { onOpen } = props;

    return (
        <Stack
            justifyContent={'space-between'}
            alignItems={'center'}
            direction={'column'}
            onClick={() => onOpen(true)}
            sx={{
                width: '70px',
                height: '70px',
            }}
        >
            <BaseImage className="commonUI btn_custom" scale={0.6} />
        </Stack>
    );
};

type Props = {
    open: boolean;
    changeOpen: (b: boolean) => void;
};
const ChipSelectorPopup = (props: Props) => {
    const { chipSet } = useConfig();

    const { open, changeOpen } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //selector
    const limitRedByGameType = useSelector(getMainLimitRedState);
    const { MinimumToken } = useSelector((state: RootState) =>
        getMainUserState(state)
    );
    const { hostId } = useContext(VideoPlayerContext);
    const { GameType } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const { isSetChips } = useSelector((state: RootState) =>
        getResultState(state)
    );
    const { sendCommand } = useContext(WebSocketContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    //useState
    const [list, setList] = useState<number[]>();
    const [selectedLimitRed, setSelectedLimitRed] = useState<LimitRed>();
    const [customChipsList, setCustomChipsList] = useState<chipSetType[]>();

    // const screenRatio = useScreenRatio();

    const handleClose = () => {
        changeOpen(false);
    };
    const handleClickOk = () => {
        if (list && selectedLimitRed && list.length === 5) {
            if (customChipsList && selectedLimitRed.RuleID) {
                const cmd = new CMDPsSetLimitRedToken();
                cmd.GameType = GameType;
                cmd.RuleID = selectedLimitRed.RuleID;
                cmd.Token1 = list[0];
                cmd.Token2 = list[1];
                cmd.Token3 = list[2];
                cmd.Token4 = list[3];
                cmd.Token5 = list[4];
                sendCommand(cmd);
                dispatch(
                    popupSliceActions.open(
                        'baccarat.changing_chips',
                        undefined,
                        POPUP_TYPE.POPUP
                    )
                );
            }
        } else {
            dispatch(popupSliceActions.open('baccarat.title_select_5_chips'));
            return;
        }
    };
    const handleClickChip = (value: number) => {
        playButtonAudio();
        if (!list) return;
        let newList: number[] = [];
        if (list.includes(value)) {
            newList = list.filter(n => (n === value ? false : true));
        } else {
            if (list.length < 5) {
                newList = [...list, value].sort((a, b) => a - b);
            } else {
                return;
            }
        }
        setList(newList);
    };
    useEffect(() => {
        const limitRedByGame = limitRedByGameType.BetRuleByGameType?.find(
            l => l.GameType === GameType
        );
        const BetRule = limitRedByGame?.BetRule;
        if (BetRule) {
            const max = BetRule.map(v => {
                return v.LimitRed;
            }).reduce((p, v) => (p > v ? p : v));
            const customChips: chipSetType[] = chipSet
                .filter((v: chipSetType) => {
                    const data: number = v.value;
                    if (data >= MinimumToken && data <= max) {
                        return v;
                    }
                })
                .sort((a, b) => a.value - b.value);
            let selBetRule = BetRule.find(r => r.Selected === 1);
            if (!selBetRule) selBetRule = BetRule[0]; //有機會全部selected=0
            batch(() => {
                setCustomChipsList(customChips);
                if (selBetRule) {
                    setSelectedLimitRed(selBetRule);
                    setList(selBetRule.Token);
                }
            });
        }
    }, []);
    useEffect(() => {
        if (isSetChips) {
            batch(() => {
                dispatch(popupSliceActions.close());
                dispatch(resultSliceActions.reset());
                if (list && selectedLimitRed && list.length === 5) {
                    dispatch(
                        limitRedSliceActions.updateSelectedToken({
                            RuleID: selectedLimitRed.RuleID,
                            Token: list,
                            GameType: GameType,
                        })
                    );
                }
            });
            setList(list);
            handleClose();
        }
    }, [isSetChips]);
    return (
        <Dialog
            container={() => document.getElementById('in-game-popup-container')}
            fullScreen
            open={open}
            onClose={handleClose}
            hideBackdrop={true}
            disableEscapeKeyDown={true}
            PaperProps={{
                sx: {
                    backgroundColor: 'rgba(0,0,0,0.85)',
                    boxShadow: 'none',
                    zIndex: theme.zIndex.drawer + 100,
                },
            }}
        >
            <DialogTitle
                sx={{
                    paddingTop: '100px',
                    fontSize: '29px',
                    textAlign: 'center',
                }}
            >
                {t('baccarat.title_select_5_chips')}
            </DialogTitle>
            <DialogContent
                sx={{
                    overflowY: 'visible',
                    padding: '24px 15px 100px 5px',
                }}
            >
                <Grid
                    item
                    container
                    direction={'row'}
                    justifyContent={'flex-start'}
                    paddingBottom={'74px'}
                >
                    {list &&
                        customChipsList &&
                        customChipsList.map(data => {
                            const isSettingSelected = list.includes(data.value);
                            const opacity =
                                list.length < 5 && !isSettingSelected ? 1 : 0.3;
                            return (
                                <Grid
                                    item
                                    key={`chip-${data.value}`}
                                    sx={{ width: 94, marginLeft: '10px' }}
                                    onClick={() => handleClickChip(data.value)}
                                >
                                    <Chip
                                        value={data.value}
                                        scale={1}
                                        opacity={opacity}
                                        isSettingSelected={isSettingSelected}
                                    />
                                </Grid>
                            );
                        })}
                </Grid>
                <Grid
                    item
                    container
                    direction={'row'}
                    justifyContent={'center'}
                >
                    <CancelButton onClick={handleClose} />
                    <TickButton onClick={handleClickOk} />
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
