import { Typography, TypographyProps } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CdnContext } from '../../../contexts/CdnContext';
import { getGameSate } from '../../../modules/games/selector';
import './index.scss';
const NetState = (props: TypographyProps) => {
    /*
    1st 0 = cdn id, cdn.dat(base64 decode) -> cdn_id
    2nd 0 = current channel id -> cdn.dat(base64 decode) -> video -> setting -> id
        if video type == 2 {
            if video level == - 1
                + 'a'
            else 
                + 'm'
        }
    3rd 0 = net proxy id, cdn.dat(base64 decode) -> server -> ls -> 
        [
        "proxy1.stag2.slgaming.net,5",
        "proxy2.stag2.slgaming.net,23",
        "proxy3.stag2.slgaming.net,38"
        ]
        後面數字
     */
    const [cdn, setCdn] = useState(0);
    const [video, setVideo] = useState(0);
    const [proxy, setProxy] = useState(0);

    const { cdnId, videoID } = useContext(CdnContext);
    const { proxyID } = useSelector(getGameSate);

    useEffect(() => {
        if (proxyID !== undefined) setProxy(proxyID);
    }, [proxyID]);
    useEffect(() => {
        if (cdnId !== undefined) setCdn(cdnId);
    }, [cdnId]);
    useEffect(() => {
        const v = Number(videoID);
        if (!isNaN(v)) setVideo(v);
    }, [videoID]);

    return (
        <Typography
            fontSize={'12px'}
            color={'#FFFFFF'}
            {...props}
        >{`R${cdn}.${video}.${proxy}`}</Typography>
    );
};

export default NetState;
