import { useContext, useMemo, useState } from 'react';
import { LobbyUiContext } from '../../../contexts/LobbyUiContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { getHostById } from '../../host/slice';
import { getBeadRoad, getSummaryList } from './util';
import {
    RouletteRecord,
    RouletteRoadmapRecord,
} from '../../../models/games/roulette';
import { ROAD_MAP_TYPE } from '../../lobby/constants';
import { RouletteColor } from '../../../models/games/roulette/Record';
import { useTranslation } from 'react-i18next';

export enum ROAD_MAP_COLORS {
    RED = '#c70000',
    BLACK = '#000000',
    GREEN = '#009203',
}

export type RouletteRoadMapCellProps = {
    record: RouletteRoadmapRecord;
    size: number;
};

export const useRouletteRoadMapCellColorText = (
    roadmapType: ROAD_MAP_TYPE,
    win: number,
    winPoint?: number,
) => {
    const { t } = useTranslation();
    let color = ROAD_MAP_COLORS.GREEN;
    let text = '';
    switch (roadmapType) {
        case ROAD_MAP_TYPE.BEAD_ROAD:
        case ROAD_MAP_TYPE.RED_BLACK:
            if (win == RouletteColor.RED) {
                color = ROAD_MAP_COLORS.RED;
            } else if (win == RouletteColor.BLACK) {
                color = ROAD_MAP_COLORS.BLACK;
            }
            break;
        case ROAD_MAP_TYPE.BIG_SMALL_ROAD:
        case ROAD_MAP_TYPE.EVEN_ODD_ROAD:
            if (win == RouletteColor.RED) {
                color = ROAD_MAP_COLORS.BLACK;
            } else if (win == RouletteColor.BLACK) {
                color = ROAD_MAP_COLORS.RED;
            }
            break;
    }

    switch (roadmapType) {
        case ROAD_MAP_TYPE.BEAD_ROAD:
            text = '' + winPoint;
            break;
        case ROAD_MAP_TYPE.RED_BLACK:
            text = win == 0?t('road.red'):t('road.black');
            break;
        case ROAD_MAP_TYPE.BIG_SMALL_ROAD:
            text = win == 0?t('road.small'):t('road.big');
            break;
        case ROAD_MAP_TYPE.EVEN_ODD_ROAD:
            text = win == 0?t('road.even'):t('road.odd');
            break;
    }

    return { color, text };
};

export const useRoadMapState = (hostId: number) => {
    const { grid } = useContext(LobbyUiContext);
    const { Record, IsRest } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [beadRoadRecord, setBeadRoadRecord] =
        useState<Array<RouletteRoadmapRecord>>();

    const [redBlackRoadRecord, setRedBlackRoadRecord] =
        useState<Array<RouletteRoadmapRecord>>();
    const [bigSmallRoadRecord, setBigSmallRoadRecord] =
        useState<Array<RouletteRoadmapRecord>>();
    const [evenOddRoadRecord, setEvenOddRoadRecord] =
        useState<Array<RouletteRoadmapRecord>>();

    const beadRoad = useMemo(
        () => getBeadRoad(Record as RouletteRecord[]),
        [Record]
    );
    const isOneColumn = useMemo(() => grid === 12, [grid]);
    const summaryList = useMemo(() => getSummaryList(Record as RouletteRecord[]), [Record]);
    
    return {
        isOneColumn,
        IsRest,
        beadRoadRecord,
        setBeadRoadRecord,
        redBlackRoadRecord,
        setRedBlackRoadRecord,
        bigSmallRoadRecord,
        setBigSmallRoadRecord,
        evenOddRoadRecord,
        setEvenOddRoadRecord,
        beadRoad,
        summaryList,
    };
};
