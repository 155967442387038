import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getMainUserState } from '../modules/main/selector';
import { RootState } from '../store/store';

export const useCurrentTime = () => {
    const { UNIXTimestamp, LoginTime } = useSelector((state: RootState) =>
        getMainUserState(state)
    );
    const currentTimeStamp = useCallback(
        () => UNIXTimestamp + (Date.now().valueOf() - LoginTime),
        [UNIXTimestamp, LoginTime]
    );
    return { currentTimeStamp };
};
