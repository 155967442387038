import { memo } from 'react';
import { BigEyeBankerWin, BigEyePlayerWin, Empty } from '../svg';
type BigEyeCellProps = {
    win: number;
};
const BigEyeCell = ({ win }: BigEyeCellProps) => {
    if (win === 0) return <BigEyeBankerWin />;
    else if (win === 1) return <BigEyePlayerWin />;
    else return <Empty />;
};
export default memo(BigEyeCell);
