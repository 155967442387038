import { GameType as CommonGameType } from '../../models/games/enums/GameType';
import { RoadMapProps } from '../lobby/components/Roadmaps';
import { AndarBaharLobbyCountdown } from './AndarBaharCountdown';
import { BaccaratLobbyCountdown } from './BaccaratCountdown';
import { BlackjackLobbyCountdown } from './BlackjackCountdown';
import { DragonTigerLobbyCountdown } from './DragonTigerCountdown';
import { SedieLobbyCountdown } from './SedieCountdown';
import { SicBoLobbyCountdown } from './SicBoCountdown';
import { TeenPattiLobbyCountdown } from './TeenPattiCountdown';

export const LobbyCountdown = ({ hostId, gameType }: RoadMapProps) => {
    switch (gameType) {
        case CommonGameType.Baccarat:
            return <BaccaratLobbyCountdown hostId={hostId} />;
        case CommonGameType.Blackjack:
            return <BlackjackLobbyCountdown hostId={hostId} />;
        case CommonGameType.SeDie:
            return <SedieLobbyCountdown hostId={hostId} />;
        case CommonGameType.AndarBahar:
            return <AndarBaharLobbyCountdown hostId={hostId} />;
        case CommonGameType.Dragon:
            return <DragonTigerLobbyCountdown hostId={hostId} />;
        case CommonGameType.SicBo:
        case CommonGameType.PokDeng:
        case CommonGameType.Roulette:
            return <SicBoLobbyCountdown hostId={hostId} />;
        case CommonGameType.TeenPatti2020:
            return <TeenPattiLobbyCountdown hostId={hostId} />;
        default:
            return <></>;
    }
};
