import { Box, Stack, Typography } from '@mui/material';
import "../../../../assets/image/common/promotionTool/promotion_tool.scss";
import "../../../../assets/image/common/promotionTool/promotion_tool_figures.scss"
import BaseImage from '../../../../components/common/baseImage';
import { promotionToolSliceActions } from '../../../main/slice/promotiontool/promotionTool';
import { PromotionPage } from './PromotionDialog';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { RefObject, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMainUserState, getPromotionToolState } from '../../../main/selector';
import { RootState } from '../../../../store/store';
import { useDispatch } from 'react-redux';
import { ImageType, dateToString, dateToTimeLeftArray, getDisplayTimeZone, getImageJsonArray, parseDollarSign, timeToString } from '../PromotionUtil';
import { displayCurrencyName, mergeCurrencyName, numberFormat } from '../../../../utils/commonFunc';
import { useTranslation } from 'react-i18next';
import { CMDPsSuspendPromotionPopup } from '../../../../models/cmd/live';
import { WebSocketContext } from '../../../../contexts/WebSocketContext';
import { PromotionButton } from './PromotionButton';
import { CommonConfigContext } from '../../../../contexts/ConfigContext';

export const PromotionSplash = () => {
    const dispatch: Dispatch<AnyAction> = useDispatch();
    const { sendCommand } = useContext(WebSocketContext);
    const [showToday, setshowToday] = useState(false);
    const showTodayRef = useRef(false);
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const [adjScale, setAdjScale] = useState(1);
    const [time, setTime] = useState<string[]>(['']);
    const { StartTimestamp, EndTimestamp, Timezone, BudgetAmount } = useSelector((state: RootState) => getPromotionToolState(state));
    //Currency handling
    const { showCurrencyName } = useContext(CommonConfigContext);
    const { CurrencyName, LobbyCode } = useSelector((state: RootState) => getMainUserState(state));
    const currency = mergeCurrencyName(useSelector((state: RootState) => (getMainUserState(state)).CurrencyName));
    const isShowCurrency = displayCurrencyName(showCurrencyName, CurrencyName, LobbyCode);
    const displayTimezone = getDisplayTimeZone(Timezone);

    useEffect(() => {
        const updateTimer = () => setTime(getImageJsonArray(ImageType.Countdown, dateToTimeLeftArray(EndTimestamp, displayTimezone).join(':')));
        updateTimer();
        const timer = setInterval(() => {
            updateTimer();
        }, 1000)
        dispatch(promotionToolSliceActions.isLoading(false));
        adjustScale(ref, 443);
        return () => {
            clearInterval(timer);
            if (showTodayRef.current === true) {
                let cmd: CMDPsSuspendPromotionPopup = new CMDPsSuspendPromotionPopup();
                sendCommand(cmd);
            }
        }
    }, []);

    const displayCurrencySymbol = () => {
        let output = '';
        if(isShowCurrency) output = `${parseDollarSign(currency)} `;
        return output;
    }

    const displayCurrencyText = () => {
        let output = '';

        const isDollarSign = parseDollarSign(currency) !== currency;
        if(!isDollarSign && isShowCurrency) output = ` (${mergeCurrencyName(currency)})`;
        return output;
    }

    const numbers: string[] = getImageJsonArray(ImageType.Prize, `${displayCurrencySymbol()}${numberFormat(BudgetAmount, 0)}`);
    const periodText = useMemo<string>(() => {
        const startDate = new Date(StartTimestamp * 1000);
        const startPeriod = `${dateToString(startDate, false, "-")} ${timeToString(startDate, false, ":")}`;
        const endDate = new Date(EndTimestamp * 1000);
        const endPeriod = `${dateToString(endDate, false, "-")} ${timeToString(endDate, false, ":")}`;
        return `${startPeriod} - ${endPeriod} (UTC${displayTimezone >= 0 ? "+" + displayTimezone.toString() : displayTimezone.toString()})`;
    }, [StartTimestamp, EndTimestamp])

    const show = () => {
        setshowToday(prev => !prev);
        showTodayRef.current = !showTodayRef.current;
    }

    const adjustScale = (ref: RefObject<HTMLDivElement>, maxWidth: number) => {
        if (ref && ref.current) {
            if (ref.current.clientWidth > maxWidth) {
                setAdjScale(maxWidth / ref.current.clientWidth)
            }
        }
    }

    return (
        <>
            <Box className='total_prize_container'>
                <BaseImage className='promotion_tool event_total_prize_bg' scale={1} />
                <Box className='total_prize_title_container'>
                    <Box sx={{ position: 'relative', top: '-3px', scale: '0.75' }}>
                        <Box className='btn_black'></Box>
                        <Typography className='total_prize_title_text'>
                            {t('promotion_tool.total_prize') + displayCurrencyText()}
                        </Typography>
                    </Box>
                </Box>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} className='total_prize'>
                    <Box ref={ref} sx={{ display: 'inline-flex' }} >
                        {
                            numbers.map((value, index) => (<BaseImage className={value}
                                scale={adjScale}
                                key={`event-total-prize-${index}`} />))
                        }
                    </Box>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={2} className='event_date'>
                    <BaseImage className='promotion_tool event_caption_arrow' scale={1} />
                    <Typography>
                        {t('promotion_tool.event_date')}
                    </Typography>
                    <BaseImage className='promotion_tool event_caption_arrow rotate180' scale={1} />
                </Stack>
                <Box className='event_period'>{periodText}</Box>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} className='event_remain_time' spacing={1}>
                    {
                        time.map((value, index) => (<BaseImage className={value} scale={1} key={`event-remain-time-${index}`} />))
                    }
                </Stack>

                <Stack sx={{ position: 'relative', display: 'flex', top: '44px', color: '#C6C6C6' }}
                    justifyContent={'center'}
                    direction={'row'}
                    spacing={1}
                >
                    <Typography className='time_text'>
                        {t('promotion_tool.days')}
                    </Typography>
                    <Typography className='time_text'>
                        {t('promotion_tool.hours')}
                    </Typography>
                    <Typography className='time_text'>
                        {t('promotion_tool.minutes')}
                    </Typography>
                    <Typography className='time_text'>
                        {t('promotion_tool.seconds')}
                    </Typography>
                </Stack>
            </Box>
            <Stack direction={'row'} sx={{ position: 'absolute', top: '736px', width: '100%', height: '48px' }} justifyContent={'center'} spacing={'20px'}>
                <PromotionButton className='button_gold' onClick={() => {
                    dispatch(promotionToolSliceActions.updatePage(PromotionPage.Gacha));
                }}>
                    {t('promotion_tool.explore_rewards')}
                </PromotionButton>
                <PromotionButton className='button_black' onClick={() => {
                    dispatch(promotionToolSliceActions.updatePage(PromotionPage.Ranking));
                }}>
                    {t('promotion_tool.rankings')}
                </PromotionButton>
            </Stack>
            <Stack sx={{ position: 'absolute', width: '50%', top: '805px' }} direction={'row'} justifyContent={'start'}>
                <PromotionButton sx={{ padding: '0px' }} onClick={() => show()}>
                    <BaseImage
                        className={`promotion_tool ${showToday ? 'event_checkbox_selected' : 'event_checkbox'}`}
                        scale={1}
                    />
                    <Typography sx={{ marginLeft: '4px', color: '#DCDCDC', fontSize: '16px' }}>
                        {t('promotion_tool.ignore')}
                    </Typography>
                </PromotionButton>
            </Stack>
        </>
    )
}
