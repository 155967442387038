import { Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { gotoNewUI } from '../../../../utils/commonFunc';
import { getLandingState } from '../../../main/selector';
import { NewUIIcon } from './NewUIIcon';

export const NewUIFloating = () => {
    const { username } = useSelector(getLandingState);
    return (
        <>
            <Button
                sx={{
                    padding: 0,
                    margin: 0,
                    position: 'fixed',
                    top: '770px',
                    right: 0,
                }}
                onClick={() => {
                    gotoNewUI(username);
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <NewUIIcon />
                </Box>
                {/* </Box> */}
            </Button>
        </>
    );
};
