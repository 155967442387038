import { Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NetState from '../../../../components/common/netState';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import { RootState } from '../../../../store/store';
import { getHostById } from '../../../host/slice';
import { getLandingState } from '../../../main/selector';
import './index.scss';
import { theme } from '../../../../theme';

export const Footer = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { username } = useSelector(getLandingState);
    const { CurrentResult } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [gameID, setGameID] = useState<string>('');

    const { t } = useTranslation();

    useEffect(() => {
        setGameID(
            CurrentResult?.GameID !== undefined
                ? CurrentResult.GameID.toString()
                : ''
        );
    }, [CurrentResult?.GameID]);

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="FooterContainer"
                sx={{ zIndex: theme.zIndex.drawer - 300 }}
            >
                {`${t('game.game_id')} ${gameID}`}
                <div>{username}</div>
                <NetState />
            </Stack>
        </>
    );
};

export default Footer;
