import { Divider } from '@mui/material';
import { REPORT_COLORS } from '../../lobby/constants';

type Props = {
    isCell?: boolean;
};

export const ReportDivider = (props: Props) => {
    const { isCell = false } = props;
    return (
        <Divider
            sx={
                isCell
                    ? {
                          borderWidth: '1px',
                          borderColor: '#827256',
                      }
                    : {
                          borderWidth: '2px',
                          borderColor: REPORT_COLORS.TITLE_TEXT,
                      }
            }
        />
    );
};

export const DetailListDivider = ({ alpha = 0.3 }: { alpha?: number }) => {
    return (
        <Divider
            sx={{
                borderWidth: '2px',
                borderColor: `rgba(255, 255, 255, ${alpha})`,
            }}
        />
    );
};
