import {
    BaccaratRecord,
    BaccaratRoadmapRecord,
} from '../../../models/games/baccarat';
import { RoadmapRecord } from '../../../models/games/baccarat/Roadmap';
import { SupportedRecord } from '../../host/models';
import { ROAD_MAP_TYPE } from '../../lobby/constants';

export const getUnion = (list: Array<BaccaratRoadmapRecord>) => {
    let tmp: BaccaratRoadmapRecord = {
        LastWin: list[0].LastWin,
        Win: list[0].Win,
        WinPoint: list[0].WinPoint,
        BankerPair: list[0].BankerPair,
        PlayerPair: list[0].PlayerPair,
        SuperSix: list[0].SuperSix,
        // type: list[0]["type"],
        LastDraw: list[0].LastDraw,
        SpaceNum: list.length - 1,
        IsLast: false,
        Detail: list[0].Detail,
        AskType: list[0].AskType,
    };
    for (let i = 0; i < list.length; i++) {
        if (list[i].BankerPair) tmp.BankerPair = true;
        if (list[i].PlayerPair) tmp.PlayerPair = true;
        if (list[i].LastDraw) tmp.LastDraw = true;
        if (list[i].SuperSix) tmp.SuperSix = true;
        if (list[i].Detail) tmp.Detail = list[i].Detail;
        if (list[i].AskType > -1) tmp.AskType = list[i].AskType;
        if (list[i].IsLast) tmp.IsLast = true;
    }
    return tmp;
};

export const getBeadRoad = (
    oldResult: SupportedRecord[] | undefined,
    askType: number = -1
): Array<BaccaratRoadmapRecord> => {
    let record = new Array<BaccaratRoadmapRecord>();
    let otherRecord = new Array<Array<BaccaratRoadmapRecord>>();

    if (oldResult) {
        let curDataIdx: number = 0;
        let curWin: number = -1;
        let x: number = -1;
        let y: number = -1;
        oldResult.forEach((resultItem, idx) => {
            const result = resultItem as BaccaratRecord;
            let win = -1; //-1 empty, 0 banker, 1 player, 2 tie
            if (result.bankerWin == result.playerWin) {
                win = result.tie ? 2 : -1;
            } else {
                win = result.bankerWin ? 0 : 1;
            }

            let winPoint: number = 0;
            winPoint = win == 0 ? result.bankerPoint : result.playerPoint;

            if (win >= 0) {
                record.push({
                    LastWin: -1,
                    Win: win,
                    WinPoint: winPoint,
                    BankerPair: result.bankerPair,
                    PlayerPair: result.playerPair,
                    SuperSix: result.superSix,
                    Idx: idx,
                    // TODO: still need type?
                    LastDraw: false,
                    SpaceNum: 0,
                    IsLast: idx == oldResult.length - 1,
                    AskType: -1,
                    Detail: {
                        GameID: result.gameID,
                        Result: result.result,
                        Shoes: result.shoes,
                        Round: result.round,
                        Player1: result.player1,
                        Player2: result.player2,
                        Player3: result.player3,
                        Banker1: result.banker1,
                        Banker2: result.banker2,
                        Banker3: result.banker3,
                        PlayerWin: result.playerWin,
                        BankerWin: result.bankerWin,
                        Tie: result.tie,
                        PlayerPair: result.playerPair,
                        BankerPair: result.bankerPair,
                        PlayerNatural: result.playerNatural,
                        BankerNatural: result.bankerNatural,
                        SuperSix: result.superSix,
                        SuperSixPlayer: result.superSixPlayer,
                        SuperSixBanker: result.superSixBanker,
                        SuperSixTie: result.superSixTie,
                        SuperSixPlayerPair: result.superSixPlayerPair,
                        SuperSixBankerPair: result.superSixBankerPair,
                        SuperSixPlayerNatural: result.superSixPlayerNatural,
                        SuperSixBankerNatural: result.superSixBankerNatural,
                        PlayerCowWin: result.playerCowWin,
                        BankerCowWin: result.bankerCowWin,
                        CowTie: result.cowTie,
                        PlayerPoint: result.playerPoint,
                        BankerPoint: result.bankerPoint,
                    },
                });
            }

            if (askType >= 0 && idx == oldResult.length - 1) {
                //For ask road
                record.push({
                    LastWin: 0,
                    Win: askType,
                    WinPoint:
                        ROAD_MAP_TYPE.BEAD_ROAD || ROAD_MAP_TYPE.POINT_ROAD
                            ? -1
                            : 0,
                    BankerPair: false,
                    PlayerPair: false,
                    SuperSix: false,
                    LastDraw: false,
                    SpaceNum: 0,
                    IsLast: false,
                    AskType: -1,
                });
            }

            while (curDataIdx < record.length) {
                const curRecord = record[curDataIdx];
                if (curRecord.Win != curWin) {
                    if (curRecord.Win != 2) {
                        curWin = curRecord.Win;
                        x++;
                        y = 0;
                    } else {
                        if (x >= 0 && y >= 0) {
                            otherRecord[x][y].LastDraw = true;
                            if (curRecord.IsLast) {
                                otherRecord[x][y].IsLast = true;
                            }
                        }
                        curDataIdx++;
                        continue;
                    }
                } else {
                    y++;
                }

                fillEmptyBead(otherRecord, x, y);

                otherRecord[x][y] = record[curDataIdx];
                curDataIdx++;

                // if (curDataIdx == record.length) {
                //     let bX = 0;
                //     let bY = 0;
                //     let pX = 0;
                //     let pY = 0;
                //     if (curWin == 0) {
                //         bX = x + 1;
                //         bY = 0;
                //         pX = x;
                //         pY = y + 1;
                //     } else {
                //         pX = x + 1;
                //         pY = 0;
                //         bX = x;
                //         bY = y + 1;
                //     }

                //     fillEmptyBead(otherRecord, bX, bY);
                //     fillEmptyBead(otherRecord, pX, pY);

                //     otherRecord[bX][bY].AskType = 0;
                //     otherRecord[pX][pY].AskType = 1;
                // }
            }
        });
    }

    return record;
};

export const fillEmptyBead = (
    record: Array<Array<BaccaratRoadmapRecord>>,
    x: number,
    y: number
) => {
    while (record.length <= x) {
        let tmp: Array<BaccaratRoadmapRecord> = [];
        tmp.push({
            LastWin: 0,
            Win: -1,
            WinPoint: 0,
            BankerPair: false,
            PlayerPair: false,
            SuperSix: false,
            LastDraw: false,
            SpaceNum: 0,
            IsLast: false,
            AskType: -1,
        });
        record.push(tmp);
    }

    while (record[x].length <= y) {
        record[x].push({
            LastWin: 0,
            Win: -1,
            WinPoint: 0,
            BankerPair: false,
            PlayerPair: false,
            SuperSix: false,
            LastDraw: false,
            SpaceNum: 0,
            IsLast: false,
            AskType: -1,
        });
    }
};

export const getOtherRoad = (
    beadRoad: Array<BaccaratRoadmapRecord> | undefined
): Array<Array<BaccaratRoadmapRecord>> => {
    let record = new Array<Array<BaccaratRoadmapRecord>>();

    if (beadRoad) {
        let curDataIdx: number = 0;
        let curWin: number = -1;
        let x: number = -1;
        let y: number = -1;

        while (curDataIdx < beadRoad.length) {
            const curRecord = beadRoad[curDataIdx];
            if (curRecord.Win != curWin) {
                if (curRecord.Win != 2) {
                    curWin = curRecord.Win;
                    x++;
                    y = 0;
                } else {
                    if (x >= 0 && y >= 0) {
                        record[x][y].LastDraw = true;
                    }
                    curDataIdx++;
                    continue;
                }
            } else {
                y++;
            }

            while (record.length <= x) {
                let tmp = new Array<BaccaratRoadmapRecord>();
                tmp.push({
                    LastWin: 0,
                    Win: -1,
                    WinPoint: 0,
                    BankerPair: false,
                    PlayerPair: false,
                    SuperSix: false,
                    LastDraw: false,
                    SpaceNum: 0,
                    IsLast: false,
                    AskType: -1,
                });
                record.push(tmp);
            }

            while (record[x].length <= y) {
                record[x].push({
                    LastWin: 0,
                    Win: -1,
                    WinPoint: 0,
                    BankerPair: false,
                    PlayerPair: false,
                    SuperSix: false,
                    LastDraw: false,
                    SpaceNum: 0,
                    IsLast: false,
                    AskType: -1,
                });
            }

            record[x][y] = beadRoad[curDataIdx];
            curDataIdx++;
        }
    }

    return record;
};
export const getMappedRoad = (
    beadRoad: Array<BaccaratRoadmapRecord>,
    otherRoad: Array<Array<BaccaratRoadmapRecord>>,
    typeID: number,
    gridWidth: number,
    gridHeight: number,
    askRoad: number = -1
): Array<BaccaratRoadmapRecord> => {
    let record = new Array<BaccaratRoadmapRecord>();
    let offset: number = 0;
    // let tmpArr: Array<Array<number>>;
    let width: number = 0;
    let askRoadFix: boolean;
    let offsetFixed: boolean;
    let list: Array<number>;
    let markEmpty: boolean;
    let x: number = -1;
    let y: number = -1;
    let curDataIdx: number = -1;
    let tmpArr: Array<Array<number>>;

    switch (typeID) {
        case ROAD_MAP_TYPE.BEAD_ROAD:
            while (gridWidth * gridHeight <= beadRoad.length - offset) {
                offset += gridHeight;
            }

            if (askRoad >= 0) {
                if (beadRoad.length % gridHeight == 0) {
                    if (beadRoad.length >= gridWidth * gridHeight) {
                        offset -= gridHeight;
                        if (offset < 0) {
                            offset = 0;
                        }
                    }
                }
            }

            for (let i: number = offset; i < beadRoad.length; i++) {
                record.push(beadRoad[i]);
            }
            break;

        case ROAD_MAP_TYPE.BIG_ROAD:
            width = gridWidth;
            offset = 0;
            askRoadFix = false;
            offsetFixed = false;

            if (askRoad >= 0) {
                if (otherRoad.length > 1) {
                    if (otherRoad[otherRoad.length - 1].length > 6) {
                        width += 1;
                    } else if (otherRoad[otherRoad.length - 1].length == 1) {
                        width += 1;
                        askRoadFix = true; //for check if long road long than new column
                    }
                }
            }

            while (!offsetFixed) {
                let placed: boolean = true;
                for (let x: number = offset; x < otherRoad.length; x++) {
                    let start_pt = x - offset;
                    let turned: boolean = false;
                    let turned_pt: number = -1;
                    let spaceOne: boolean = false;

                    for (let y: number = 0; y < otherRoad[x].length; y++) {
                        let idx: number = start_pt * gridHeight + y;
                        const max: number = gridHeight;

                        if (y >= max && !turned) {
                            turned = true;
                            turned_pt = y - 1;
                            idx =
                                (start_pt + (y - (max - 1))) * gridHeight +
                                (max - 1);
                        }

                        if (spaceOne) {
                            // spaceCount++;
                        } else {
                            if (!turned) {
                                if (
                                    idx < record.length &&
                                    record[idx].Win >= 0
                                ) {
                                    turned = true;
                                    turned_pt = y - 1;

                                    if (
                                        record[idx].Win == otherRoad[x][y].Win
                                    ) {
                                        turned_pt--;
                                    }

                                    idx =
                                        (start_pt + (y - turned_pt)) *
                                            gridHeight +
                                        turned_pt;

                                    if (turned_pt == 0) {
                                        spaceOne = true;
                                    }
                                }

                                if (!turned) {
                                    if (
                                        idx + 1 < record.length &&
                                        record[idx + 1].Win ==
                                            otherRoad[x][y].Win
                                    ) {
                                        if (y == 0) {
                                            spaceOne = true;
                                            // spaceCount++;
                                        } else {
                                            idx += gridHeight - 1;

                                            if (idx % gridHeight == 0) {
                                                spaceOne = true;
                                                // spaceCount++;
                                            }
                                        }
                                    }
                                }
                            } else {
                                idx =
                                    (start_pt + (y - turned_pt)) * gridHeight +
                                    turned_pt;
                            }
                        }

                        if (spaceOne) {
                            idx = start_pt * gridHeight;
                        }

                        if (idx < 0) {
                            continue;
                        }

                        while (record.length <= idx) {
                            record.push({
                                LastWin: 0,
                                Win: -1,
                                WinPoint: 0,
                                BankerPair: false,
                                PlayerPair: false,
                                SuperSix: false,
                                LastDraw: false,
                                SpaceNum: 0,
                                IsLast: false,
                                AskType: -1,
                            });
                        }

                        if (spaceOne) {
                            record[idx].SpaceNum = y + 1;
                            otherRoad[x][y].SpaceNum = y;

                            let tmp = (start_pt + 1) * gridHeight;
                            if (tmp < record.length) {
                                record[tmp].Win = -1;
                            }

                            record[idx] = getUnion(otherRoad[x]);
                        } else {
                            otherRoad[x][y].SpaceNum = 0;
                            record[idx] = otherRoad[x][y];
                        }

                        if ((idx + 1) / gridHeight > width) {
                            placed = false;
                        }

                        if (askRoadFix && x == otherRoad.length - 2) {
                            //Fix have turned road longer than new line, cancel ask road width added
                            let lastColumnFirstOffset =
                                record.length % gridHeight == 0
                                    ? gridHeight
                                    : record.length % gridHeight;
                            if (
                                record[record.length - lastColumnFirstOffset]
                                    .Win == -1
                            ) {
                                askRoadFix = false;
                                width -= 1;
                                placed = false;
                            }
                        }
                    }
                }

                if (placed) {
                    offsetFixed = true;
                } else {
                    while (record.length / gridHeight > width) {
                        let haveLast = false;
                        for (let i = 0; i < gridHeight; i++) {
                            if (record[i]?.IsLast) {
                                haveLast = true;
                            }
                        }

                        if (haveLast) {
                            record = record.slice(0, record.length - 1);
                        } else {
                            record = record.slice(gridHeight);
                        }
                    }
                    offsetFixed = true;
                }
            }
            break;
        default:
            list = [];
            markEmpty = false;

            if (typeID == ROAD_MAP_TYPE.BIG_EYE_ROAD) {
                x = 1;
                y = 1;
                offset = 2;
            } else if (typeID == ROAD_MAP_TYPE.SMALL_ROAD) {
                x = 2;
                y = 1;
                offset = 3;
            } else if (typeID == ROAD_MAP_TYPE.COCKROACH_ROAD) {
                x = 3;
                y = 1;
                offset = 4;
            }
            width = gridWidth;

            while (x < otherRoad.length) {
                if (y >= otherRoad[x].length) {
                    x++;
                    y = 0;
                    markEmpty = false;
                }

                if (y == 0) {
                    if (
                        otherRoad[x - 1].length == otherRoad[x - offset].length
                    ) {
                        list.push(0);
                    } else {
                        list.push(1);
                    }
                } else {
                    if (y < otherRoad[x - offset + 1].length) {
                        list.push(0);
                    } else {
                        if (markEmpty) {
                            list.push(0);
                        } else {
                            markEmpty = true;
                            list.push(1);
                        }
                    }
                }
                y++;
            }

            curDataIdx = -1;
            tmpArr = [];
            for (let i: number = 0; i < list.length - 1; i++) {
                if (list[i] != curDataIdx) {
                    curDataIdx = list[i];
                    tmpArr.push([]);
                    tmpArr[tmpArr.length - 1].push(curDataIdx);
                } else {
                    tmpArr[tmpArr.length - 1].push(curDataIdx);
                }
            }

            // if(list.length > 1) {
            //     //this.askRoad = list[list.length - 2];
            //     this.nextRoad = list[list.length - 1];
            // } else {
            //     this.nextRoad = -1;
            // }

            offsetFixed = false;
            offset = 0;
            askRoadFix = false;

            if (askRoad >= 0) {
                if (tmpArr.length > 1) {
                    if (tmpArr[tmpArr.length - 1].length > 6) {
                        width += 1;
                    } else if (tmpArr[tmpArr.length - 1].length == 1) {
                        width += 1;
                        askRoadFix = true; //for check if long road long than new column
                    }
                }
            }

            while (!offsetFixed) {
                let placed = true;

                for (let x: number = offset; x < tmpArr.length; x++) {
                    let start_pt: number = x - offset;
                    let turned: boolean = false;
                    let turned_pt = -1;
                    let spaceOne: boolean = false;
                    for (let y: number = 0; y < tmpArr[x].length; y++) {
                        let idx: number = start_pt * gridHeight + y;
                        const max: number = gridHeight;

                        if (y >= max) {
                            idx =
                                (start_pt + (y - (max - 1))) * gridHeight +
                                (max - 1);
                        }

                        if (!spaceOne) {
                            if (!turned) {
                                if (
                                    idx < record.length &&
                                    record[idx].Win >= 0
                                ) {
                                    turned = true;
                                    turned_pt = y - 1;
                                    if (record[idx].Win == tmpArr[x][y]) {
                                        turned_pt--;
                                    }
                                    idx =
                                        (start_pt + (y - turned_pt)) *
                                            gridHeight +
                                        turned_pt;

                                    if (turned_pt <= 0) {
                                        spaceOne = true;
                                    }
                                }
                                if (!turned) {
                                    if (
                                        idx + 1 < record.length &&
                                        record[idx + 1].Win == tmpArr[x][y]
                                    ) {
                                        if (y == 0) {
                                            spaceOne = true;
                                        } else {
                                            turned = true;
                                            turned_pt = y - 1;
                                            idx += gridHeight - 1;
                                            if (idx % gridHeight == 0) {
                                                spaceOne = true;
                                            }
                                        }
                                    }
                                }
                            } else {
                                idx =
                                    (start_pt + (y - turned_pt)) * gridHeight +
                                    turned_pt;
                            }
                        }

                        if (y != 0 && idx % gridHeight == 0) {
                            spaceOne = true;
                        }

                        if (spaceOne) {
                            idx = start_pt * gridHeight;
                        }

                        while (record.length <= idx) {
                            record.push({
                                Win: -1, //-1 empty, 0 banker, 1 player, 2 draw
                                SpaceNum: 0,
                                IsLast: false,
                                AskType: -1,
                            });
                        }

                        record[idx].Win = tmpArr[x][y];
                        if (
                            x == tmpArr.length - 1 &&
                            y == tmpArr[x].length - 1
                        ) {
                            record[idx].IsLast = true;
                        }

                        if ((idx + 1) / gridHeight > width) {
                            placed = false;
                        }

                        if (askRoadFix && x == tmpArr.length - 2) {
                            //Fix have turned road longer than new line, cancel ask road width added
                            let lastColumnFirstOffset =
                                record.length % gridHeight == 0
                                    ? gridHeight
                                    : record.length % gridHeight;
                            if (
                                record[record.length - lastColumnFirstOffset]
                                    .Win == -1
                            ) {
                                askRoadFix = false;
                                width -= 1;
                                placed = false;
                            }
                        }
                    }
                }

                if (placed) {
                    offsetFixed = true;
                } else {
                    while (record.length / gridHeight > width) {
                        let haveLast = false;
                        for (let i = 0; i < gridHeight; i++) {
                            if (record[i]?.IsLast) {
                                haveLast = true;
                            }
                        }

                        if (haveLast) {
                            record = record.slice(0, record.length - 1);
                        } else {
                            record = record.slice(gridHeight);
                        }
                    }
                    offsetFixed = true;
                }
            }
            break;
    }
    return record;
};

export const getViewBeadRoadRecord = (
    road: Array<RoadmapRecord>,
    max: number
): Array<RoadmapRecord> => {
    if (!road) return [];
    let list = road;
    const over = Math.ceil((road.length - (max * 6 - 1)) / 6);
    if (over > 0) {
        list = road.slice(over * 6);
    }
    return list;
};
export const getViewRoadRecord = (
    road: Array<RoadmapRecord>,
    max: number
): Array<RoadmapRecord> => {
    if (!road) return [];
    let list = road;
    const over = Math.ceil((road.length - max * 6) / 6);
    if (over > 0) {
        let last = road.findIndex(r => r.IsLast === true);
        if (last < over * 6) {
            let lastPos = Math.floor(last / 6);
            list = road.slice(lastPos * 6, max * 6);
        } else {
            list = road.slice(over * 6);
        }
    }
    return list;
};

export const getAskIdx = (
    list: Array<BaccaratRoadmapRecord>,
    askType: number
) => {
    if (askType == -1) return -1;
    let askIdx = -1;
    const lastIdx = list.findIndex(r => r.IsLast);
    if (lastIdx == -1) {
        //empty list
        return 0;
    } else {
        if (list[lastIdx].Win === askType) {
            if (lastIdx % 6 === 5) {
                askIdx = lastIdx + 6;
            } else {
                if (lastIdx >= 6) {
                    if (list[lastIdx - 6].Win === askType) {
                        if (list[lastIdx - 1].Win !== askType) {
                            askIdx = lastIdx + 6;
                        } else {
                            askIdx = lastIdx + 1;
                        }
                    } else {
                        if (list[lastIdx + 1] && list[lastIdx + 1].Win === -1) {
                            //have long
                            if (
                                list[lastIdx + 2] &&
                                list[lastIdx + 2].Win === askType
                            ) {
                                askIdx = lastIdx + 6;
                            } else {
                                askIdx = lastIdx + 1;
                            }
                        } else {
                            if (!list[lastIdx + 1] && (lastIdx + 1) % 6 !== 0) {
                                askIdx = lastIdx + 1;
                            } else {
                                askIdx = lastIdx + 6;
                            }
                        }
                        if (askIdx % 6 === 0) {
                            //space blocked
                            askIdx = lastIdx;
                        }
                    }
                } else {
                    askIdx = lastIdx + 1;
                }
            }
        } else {
            //find next new line
            const nextNew = list.findIndex(
                (r, idx) => idx % 6 === 0 && r.Win === -1
            );
            if (nextNew === -1) {
                //no long road
                askIdx = Math.ceil(list.length / 6) * 6;
            } else {
                askIdx = nextNew;
            }
        }
    }
    return askIdx;
};
export const getNextRoad = (
    records: Array<BaccaratRoadmapRecord> | undefined,
    otherRoad: Array<Array<BaccaratRoadmapRecord>>,
    typeID: number
) => {
    const getNextRoadValue = (): number => {
        let offset: number = 0;
        let list: Array<number>;
        let markEmpty: boolean;
        let x: number = -1;
        let y: number = -1;
        let nextRoad: number = -1;

        list = [];
        markEmpty = false;
        if (typeID == ROAD_MAP_TYPE.BIG_EYE_ROAD) {
            x = 1;
            y = 1;
            offset = 2;
        } else if (typeID == ROAD_MAP_TYPE.SMALL_ROAD) {
            x = 2;
            y = 1;
            offset = 3;
        } else if (typeID == ROAD_MAP_TYPE.COCKROACH_ROAD) {
            x = 3;
            y = 1;
            offset = 4;
        }

        while (x < otherRoad.length) {
            if (y >= otherRoad[x].length) {
                x++;
                y = 0;
                markEmpty = false;
            }

            if (y == 0) {
                if (otherRoad[x - 1].length == otherRoad[x - offset].length) {
                    list.push(0);
                } else {
                    list.push(1);
                }
            } else {
                if (y < otherRoad[x - offset + 1].length) {
                    list.push(0);
                } else {
                    if (markEmpty) {
                        list.push(0);
                    } else {
                        markEmpty = true;
                        list.push(1);
                    }
                }
            }
            y++;
        }

        if (list.length > 1) {
            nextRoad = list[list.length - 1];
        } else {
            nextRoad = -1;
        }

        return nextRoad;
    };

    let nextRoad = getNextRoadValue();

    if (nextRoad < 0 || !records) {
        return -1;
    }
    let offset = 1;
    while (records.length - offset >= 0) {
        if (records[records.length - offset].Win == 0) {
            return nextRoad;
        } else if (records[records.length - offset].Win == 1) {
            if (nextRoad == 0) {
                return 1;
            } else {
                return 0;
            }
        }
        offset++;
    }
    return -1;
};

export const getSummaryList = (records: SupportedRecord[] | undefined) => {
    let bankerTotal = 0;
    let playerTotal = 0;
    let tieTotal = 0;
    let bankerPairTotal = 0;
    let playerPairTotal = 0;
    records &&
        records.forEach(r => {
            const record = r as BaccaratRecord;
            if (record.playerWin) playerTotal++;
            if (record.bankerWin) bankerTotal++;
            if (record.tie) tieTotal++;
            if (record.bankerPair) bankerPairTotal++;
            if (record.playerPair) playerPairTotal++;
        });
    return {
        bankerTotal,
        playerTotal,
        tieTotal,
        bankerPairTotal,
        playerPairTotal,
    };
};
