import { Box, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BaseImage from '../../../../components/common/baseImage';
import { Bet } from '../../../report/slice/betRecord';

type BaccGameResultRecordPanelProps = {
    bet: Bet;
};
export const BaccGameResultRecordPanel = (
    props: BaccGameResultRecordPanelProps
) => {
    const { bet } = props;
    const { t } = useTranslation();

    return (
        <>
            <Stack
                direction="column"
                justifyContent="space-between"
                alignItems={'flex-end'}
                sx={{ height: '86px' }}
            >
                <DetailResult
                    lbl={t('road.player_win')}
                    cards={bet.ResultPlayer}
                />
                <DetailResult
                    lbl={t('road.banker_win')}
                    cards={bet.ResultBanker}
                />
            </Stack>
        </>
    );
};
//Baccarat & Dragon Tiger use
export const DetailResult = ({ lbl, cards }: { lbl: string; cards: number[] }) => {
    return (
        <>
            <Stack direction="row" alignItems="center">
                <Grid item sx={{ marginRight: '3px' }}>
                    {lbl}
                </Grid>
                {cards.map(c => {
                    const suit = Math.floor(c / 13);
                    const rank = c % 13;
                    return (
                        <Grid key={`card-${c}`} item>
                            <Stack direction="row">
                                <BaseImage className="cardrank empty" />
                                <BaseImage
                                    className={`cardsuit c${suit}`}
                                    scale={1}
                                />
                                <Box sx={{ marginLeft: '-1px' }}>
                                    {suit % 2 == 0 ? (
                                        <BaseImage
                                            className={`cardrank c${rank}`}
                                            scale={1}
                                        />
                                    ) : (
                                        <BaseImage
                                            className={`cardrankred c${rank}`}
                                            scale={1}
                                        />
                                    )}
                                </Box>
                            </Stack>
                        </Grid>
                    );
                })}
            </Stack>
        </>
    );
};
