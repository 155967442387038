import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GameState as GameStateEnum } from '../models/games/enums/GameState';
import { getHostById } from '../modules/host/slice';
import { RootState } from '../store/store';
import { GameType } from '../models/games/enums/GameType';

export const useECard = (hostId: number) => {
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const [show, setShow] = useState(false);
    useEffect(() => {
        const currentState = host?.CurrentState;
        if (
            host.GameType == GameType.TeenPatti2020 ||
            host.GameType == GameType.PokDeng ||
            host.GameType == GameType.Dragon ||
            host.GameType == GameType.AndarBahar
        ) {
            switch (currentState) {
                case GameStateEnum.Idle:
                case GameStateEnum.Shuffle:
                case GameStateEnum.Betting:
                    setShow(false);
                    break;
                default:
                    setShow(true);
                    break;
            }
        } else if (host.GameType == GameType.SeDie) {
            switch (currentState) {
                case GameStateEnum.GameResult:
                    setShow(true);
                    break;
                default:
                    setShow(false);
                    break;
            }
        } else {
            setShow(
                currentState >= GameStateEnum.Started &&
                    currentState < GameStateEnum.Shuffle
            );
            // switch (currentState) {
            //     case GameStateEnum.Betting:
            //     case GameStateEnum.Shuffle:
            //         setShow(false);
            //         break;
            //     case GameStateEnum.Started:
            //     case GameStateEnum.GameResult:
            //         setShow(true);
            //         break;
            // }
        }
    }, [host?.CurrentState]);
    return show;
};
