import { SeatAreaProvider } from '../../../../contexts/SeatAreaContext';
import { GameType as GameTypeEnum } from '../../../../models/games/enums/GameType';
import { RoadMapProps } from '../../../lobby/components/Roadmaps';
import { AndarBaharBetAreaPanel } from './AndarBahar';
import { BaccaratBetAreaPanel } from './Baccarat';
import { BlackjackBetAreaPanel } from './Blackjack';
import DragonTigerBetAreaPanel from './DragonTiger';
import { PokDengBetAreaPanel } from './Pokdeng';
import { RouletteBetAreaPanel } from './Roulette';
import { SedieBetAreaPanel } from './Sedie';
import SicBoBetAreaPanel from './SicBo';
import { TeenPattiBetAreaPanel } from './TeenPatti';

export const BetAreaPanelContainer = ({ hostId, gameType }: RoadMapProps) => {
    switch (gameType) {
        case GameTypeEnum.Baccarat:
            return <BaccaratBetAreaPanel />;
        case GameTypeEnum.Dragon:
            return <DragonTigerBetAreaPanel />;
        case GameTypeEnum.Blackjack:
            return (
                <SeatAreaProvider hostId={hostId}>
                    <BlackjackBetAreaPanel />
                </SeatAreaProvider>
            );
        case GameTypeEnum.SeDie:
            return <SedieBetAreaPanel />;
        case GameTypeEnum.PokDeng:
            return <PokDengBetAreaPanel />;
        case GameTypeEnum.TeenPatti2020:
            return <TeenPattiBetAreaPanel />;
        case GameTypeEnum.AndarBahar:
            return <AndarBaharBetAreaPanel />;
        case GameTypeEnum.SicBo:
            return <SicBoBetAreaPanel />;
        case GameTypeEnum.Roulette:
            return <RouletteBetAreaPanel />;

        default:
            return <></>;
    }
};
