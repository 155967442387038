import { Moment } from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WebSocketContext } from '../../../contexts/WebSocketContext';
import { GameCategory as GameCategoryEnum } from '../../../models/GameCategory';
import { CMDPsPlayerReport } from '../../../models/cmd/live';
import { RootState } from '../../../store/store';
import { getMainUserState } from '../../main/selector';
import { DateRangePicker } from '../../report/components/DateRangePicker';
import { getBetStatisticsReportState } from '../../report/selector';
import { betStatisticsSliceActions } from '../../report/slice/betStatistics';
// import { TickButton } from './TickButton';
import { Box, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AudioPlayContext } from '../../../contexts/AudioPlayContext';
import { REPORT_COLORS, REPORT_SIZE } from '../../lobby/constants';
import { DetailListDivider } from './ReportDivider';
import { UsernameGrid } from './UsernameGrid';
import { GameCategorySelector } from './member/GameCategorySelector';

export const MemberReport = () => {
    const dispatch = useDispatch();
    const [start, setStart] = useState<Moment>();
    const [end, setEnd] = useState<Moment>();
    const [expanded, setExpanded] = useState<boolean>(false);
    const [category, setCategory] = useState<GameCategoryEnum>(
        GameCategoryEnum.GCAll
    );
    const { Username } = useSelector((state: RootState) =>
        getMainUserState(state)
    );
    const { Turnover, ValidBetAmount, WinLossAmount, TotalAmount } =
        useSelector((state: RootState) => getBetStatisticsReportState(state));

    const { sendCommand } = useContext(WebSocketContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const handleSubmit = (startDate: Moment, endDate: Moment) => {
        // if (start && end) {
        if (startDate && endDate) {
            const request = new CMDPsPlayerReport();
            request.FromDate = startDate
                .locale('en')
                .format('YYYY-MM-DD 12:00:00');
            request.ToDate = endDate.locale('en').format('YYYY-MM-DD 11:59:59');
            request.GameCategory = category;
            sendCommand(request);
        } else {
            console.warn('app::report::statics', 'please select date');
        }
    };
    useEffect(() => {
        return () => {
            dispatch(betStatisticsSliceActions.reset());
        };
    }, []);
    const { t } = useTranslation();
    return (
        <>
            <UsernameGrid username={Username} />
            <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                sx={{ height: '18vw' }}
            >
                <Grid
                    item
                    sx={{
                        color: REPORT_COLORS.TITLE_TEXT,
                        fontSize: REPORT_SIZE.TEXT,
                    }}
                >
                    {t('report.game_type')}
                </Grid>
                <GameCategorySelector
                    category={category}
                    setCategory={setCategory}
                    playButtonAudio={playButtonAudio}
                />
            </Grid>
            <DateRangePicker
                start={start}
                end={end}
                expanded={expanded}
                setStart={setStart}
                setEnd={setEnd}
                setExpanded={setExpanded}
                showPreset={true}
                handleSubmit={handleSubmit}
                playButtonAudio={playButtonAudio}
            />
            <Box overflow={'auto'}>
                <Grid
                    item
                    container
                    direction="column"
                    flexWrap="nowrap"
                    // alignContent="center"
                    sx={{
                        color: REPORT_COLORS.TITLE_TEXT,
                        fontSize: REPORT_SIZE.TEXT,
                    }}
                >
                    <ReportCell
                        text={t('report.bet_credit')}
                        amount={Turnover}
                    />
                    <DetailListDivider alpha={0.11} />
                    <ReportCell
                        text={t('report.consume')}
                        amount={ValidBetAmount}
                    />
                    <DetailListDivider alpha={0.11} />
                    <ReportCell
                        text={t('report.winner_loser_amount')}
                        amount={WinLossAmount}
                    />
                    <DetailListDivider alpha={0.11} />
                    <ReportCell
                        text={t('report.total_amount')}
                        amount={TotalAmount}
                    />
                    <DetailListDivider alpha={0.11} />
                </Grid>
            </Box>
        </>
    );
};

const ReportCell = ({ text, amount = 0 }: { text: string; amount: number }) => {
    return (
        <>
            <Stack
                direction="row"
                alignItems={'center'}
                justifyContent={'space-around'}
                sx={{ height: '25vw' }}
            >
                <Grid item sx={{ width: '25vw' }}>
                    {text}
                </Grid>
                <Grid
                    item
                    container
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                        width: '33.4vw',
                        height: '10vw',
                        backgroundColor: '#1c1c1c',
                        borderRadius: '1vw',
                    }}
                >
                    {amount?.toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}
                </Grid>
            </Stack>
        </>
    );
};
