import { AnyAction, Dispatch } from 'redux';
import {
    betRoomSliceActions,
    getBetByHostPlayer,
    getBetRoom,
} from '../../../modules/bet/room/slice';
import {
    getHostByGameId,
    hostsSliceActions,
} from '../../../modules/host/slice';
import store from '../../../store/store';
import { BetPayload, BetRoomSeatIndex } from '../../games/BetRoom';
import { Bet } from '../../host/BetAmount';
import { CMDScPlayerBet, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
import { gameSliceActions } from '../../../modules/games/slice';
import { getMainUserState } from '../../../modules/main/selector';
import { batch } from 'react-redux';
import { GameType } from '../../games/enums/GameType';

export class CMDScPlayerBetHandler extends AbstractHandler {
    _command: CMDScPlayerBet;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScPlayerBet;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const state = store.getState();
        const host = getHostByGameId(state, this._command.GameID);
        const { PlayerID } = getMainUserState(state);
        if (host && host.GameType === GameType.Blackjack) {
            const playerId = this._command.PlayerID;
            const seat = (this._command.Seat % 100) as BetRoomSeatIndex;
            const hostId = host.HostId;
            if (!seat && playerId !== PlayerID) {
                return;
            }

            let bets = new Array<Bet>();
            if (seat) {
                const currentBets = getBetByHostPlayer(state, hostId, seat);
                bets = bets.concat(currentBets);
            } else if (seat === 0 && playerId === PlayerID) {
                const currentBets = getBetRoom(state, hostId)?.Info[0].Bet;
                if (currentBets) bets = bets.concat(currentBets);
            }
            const newBets = this._command.Bet.map(
                b =>
                    ({
                        Type: b.betType,
                        Amount: b.betAmount,
                    }) as Bet
            );
            bets = bets.concat(newBets);
            const payload: BetPayload = {
                HostId: hostId,
                Seat: this._command.Seat,
                PlayerId: playerId,
                Bets: bets,
            };
            dispatch(betRoomSliceActions.bet(payload));

            if (playerId === PlayerID) {
                batch(() => {
                    dispatch(
                        hostsSliceActions.updateConfirmedBets({
                            hostId: hostId,
                            gameId: this._command.GameID,
                            bets: bets,
                        })
                    );
                    dispatch(
                        gameSliceActions.onConfirmedBetResult({
                            gameId: this._command.GameID,
                            bet: bets,
                        })
                    );
                });
            }
        }
    }
}
