import Radio, { RadioProps } from '@mui/material/Radio';
import BaseImage from '../../../../../components/common/baseImage';
import { Rating } from '../../../../../models/Rating';
type RatingIconProps = {
    r: number | Rating;
    isChecked: boolean;
};
const scale = 0.7;
const RatingIcon = ({ r, isChecked }: RatingIconProps) => {
    let classNames = 'fantastic';
    let checked = isChecked ? 'on' : 'off';
    switch (r) {
        case Rating.VeryPoor:
            classNames = 'verypoor';
            break;
        case Rating.Poor:
            classNames = 'poor';
            break;
        case Rating.Fair:
            classNames = 'fair';
            break;
        case Rating.Good:
            classNames = 'good';
            break;
        case Rating.Fantastic:
            classNames = 'fantastic';
            break;
    }
    return (
        <BaseImage
            className={`commonUI ic_feedback_${classNames}_${checked}`}
            scale={scale}
        />
    );
};
type RatingRadioProps = RadioProps;
export const RatingRadio = (props: RatingRadioProps) => {
    const { value } = props;
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<RatingIcon r={value as Rating} isChecked={true} />}
            icon={<RatingIcon r={value as Rating} isChecked={false} />}
            {...props}
        />
    );
};
