import { Button, Grid, ListItem } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseImage from '../../../../components/common/baseImage';
import { AudioPlayContext } from '../../../../contexts/AudioPlayContext';
import { CommonConfigContext } from '../../../../contexts/ConfigContext';
import { WebSocketContext } from '../../../../contexts/WebSocketContext';
import { useBalance } from '../../../../hooks/useBalance';
import { CMDPsTokenUpdate } from '../../../../models/cmd/live';
import { displayCurrencyName } from '../../../../utils/commonFunc';
import RefreshBalance from '../../../lobby/components/Header/RefreshBalanceButton';
import {
    getLandingState,
    getMainUserState,
    getPromotionToolState,
} from '../../../main/selector';
import { PromotionIndicator } from '../promotionIndicator';
import './index.scss';
type UserInformationProps = {
    isHeader: boolean;
    isInGame: boolean;
};
type InfoData = {
    justifyContent: string;
    alignItems: string;
    alignItemsBalance: string;
    spacing: number;
    showUserName: string;
    lastLogin: string;
    showPromotion: boolean;
};
const initData: InfoData = {
    justifyContent: '',
    alignItems: '',
    alignItemsBalance: '',
    spacing: 1,
    showUserName: '',
    lastLogin: '',
    showPromotion: false,
};
export const UserInformation = (props: UserInformationProps) => {
    const { isHeader, isInGame } = props;
    const { t } = useTranslation();
    const [data, setData] = useState<InfoData>(initData);
    const [curDisplayBalance, setCurDisplayBalance] = useState<string>();
    const {
        Username,
        LastLogin,
        CurrencyName: serCurrencyName,
        LobbyCode: serLobbyCode,
    } = useSelector(getMainUserState);
    const { username: paramUserName } = useSelector(getLandingState);
    const { Id: EventId } = useSelector(getPromotionToolState);
    const { showCurrencyName } = useContext(CommonConfigContext);
    const { displayBalance } = useBalance();
    const { playButtonAudio } = useContext(AudioPlayContext);
    useEffect(() => {
        if (Username) {
            const newData = {} as InfoData;
            if (isHeader) {
                newData.justifyContent = 'flex-end';
                newData.alignItems = 'flex-end';
                newData.alignItemsBalance = 'stretch';
                newData.spacing = 0;
            }
            newData.showUserName = paramUserName ? paramUserName : Username;
            newData.lastLogin = LastLogin ? LastLogin : '';
            newData.showPromotion = EventId > 0;
            setData(newData);
        }
    }, []);

    useEffect(() => {
        let isShowCurrencyName = displayCurrencyName(
            showCurrencyName,
            serCurrencyName,
            serLobbyCode
        );
        if (isShowCurrencyName) {
            setCurDisplayBalance(displayBalance);
        } else {
            setCurDisplayBalance(
                displayBalance.replace(/[\n]+([^\n]+)|\s.*/, '')
            );
        }
    }, [displayBalance]);

    const { sendCommand } = useContext(WebSocketContext);
    const [rotateNum, setRotateNum] = useState<number>(0);
    // const { showNewUI } = useSelector(getLandingState);

    const onClickButton = () => {
        if (!isHeader) return;
        playButtonAudio();
        setRotateNum(rotateNum - 720);
        const updateBalance = new CMDPsTokenUpdate();
        sendCommand(updateBalance);
    };
    return (
        <ListItem disablePadding className="UserInfoList">
            <Grid
                container
                display={'flex'}
                direction="column"
                justifyContent={data.justifyContent}
                alignItems={data.alignItems}
                gap={isInGame ? '0px' : '5px'}
                spacing={data.spacing}
            >
                {isHeader && (
                    <Grid item className="eventInfo">
                        {data.showPromotion && <PromotionIndicator />}
                        {/* {showNewUI && (
                            <NewUIButton height={isInGame ? 20 : 25} />
                        )} */}
                    </Grid>
                )}
                {!isHeader && (
                    <Grid item display={'flex'}>
                        {data.showUserName}
                    </Grid>
                )}
                <Button
                    sx={{
                        height: '22px',
                        color: 'white',
                        padding: 0,
                        justifyContent: 'flex-start',
                    }}
                    onClick={onClickButton}
                >
                    <Grid item className="balanceInfo">
                        <Grid item display={'flex'}>
                            <BaseImage
                                className="commonUI ic_balance_brown"
                                scale={1}
                            />
                        </Grid>
                        <Grid item whiteSpace={'nowrap'} paddingTop={'3px'}>
                            {curDisplayBalance}
                        </Grid>
                        {isHeader && (
                            <Grid item display={'flex'} className="refreshIcon">
                                <RefreshBalance rotateNum={rotateNum} />
                            </Grid>
                        )}
                    </Grid>
                </Button>

                {!isHeader && data.lastLogin != '' && (
                    <>
                        <Grid item>
                            <div>{t('last_login')}</div>
                            <div>{data.lastLogin}</div>
                        </Grid>
                    </>
                )}
            </Grid>
        </ListItem>
    );
};
export default UserInformation;
