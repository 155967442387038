import { TableInfoProvider } from '../../../../contexts/TableInfoContext';
import { GameType as GameTypeEnum } from '../../../../models/games/enums/GameType';
import { RoadMapProps } from '../../../lobby/components/Roadmaps';
import { BaccaratTableInfoPanel } from './BaccaratTableInfoPanel';
import { BlackjackTableInfoPanel } from './BlackjackTableInfoPanel';
import { DragonTigerTableInfoPanel } from './DragonTigerTableInfoPanel';
import { TableInfoPanel as RouletteTableInfoPanel } from './Roulette';
import { AndarBaharTableInfoPanel } from './andarBahar/AndarBaharTableInfoPanel';
import { PokDengTableInfoPanel } from './pokdeng/PokDengTableInfoPanel';
import { SedieTableInfoPanel } from './sedie/SedieTableInfoPanel';
import { SicBoTableInfoPanel } from './sicbo/SicBoTableInfoPanel';
import { TeenPattiTableInfoPanel } from './teenpatti/TeenPattiTableInfoPanel';

export const TableInfoPanel = ({ hostId, gameType }: RoadMapProps) => {
    switch (gameType) {
        case GameTypeEnum.Baccarat:
            return <BaccaratTableInfoPanel />;
        case GameTypeEnum.Dragon:
            return <DragonTigerTableInfoPanel />;
        case GameTypeEnum.PokDeng:
            return <PokDengTableInfoPanel />;
        case GameTypeEnum.Blackjack:
            return <BlackjackTableInfoPanel />;
        case GameTypeEnum.SeDie:
            return <SedieTableInfoPanel hostId={hostId} />;
        case GameTypeEnum.SicBo:
            return (
                <TableInfoProvider>
                    <SicBoTableInfoPanel />
                </TableInfoProvider>
            );
        case GameTypeEnum.TeenPatti2020:
            return <TeenPattiTableInfoPanel hostId={hostId} />;
        case GameTypeEnum.Roulette:
            return (
                <TableInfoProvider>
                    <RouletteTableInfoPanel />
                </TableInfoProvider>
            );
        case GameTypeEnum.AndarBahar:
            return <AndarBaharTableInfoPanel />;
        default:
            return <>TableInfoPanel</>;
    }
};
