import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { LocalStorageKey } from '../../../hooks/storage/useLocalStorage';
import {
    CountryMessage,
    popupSliceActions,
} from '../../../modules/popup/slice';
import { GameError } from '../../games/enums/GameError';
import { CMDSpLoginFail, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
export class LoginFailHandler extends AbstractHandler {
    _command: CMDSpLoginFail;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpLoginFail;
    }
    countryMessage(): CountryMessage | undefined {
        const reason = this._command.Reason;
        switch (reason) {
            case GameError.CurrencyNotAllowedInClientCountry:
                return {
                    messageKey: 'popup.RegionCurrencyError',
                    params: {
                        ip: this._command.IP,
                        countryCode: this._command.CountryCode,
                        currency: this._command.CurrencyName,
                    },
                };
            case GameError.IPBlocked:
                return {
                    messageKey: 'popup.RestrictedLocation',
                    params: {
                        ip: this._command.IP,
                        countryCode: this._command.CountryCode,
                        currency: this._command.CurrencyName,
                    },
                };
        }
        return undefined;
    }
    needRequestCountryName(): boolean {
        const reason = this._command.Reason;
        switch (reason) {
            case GameError.CurrencyNotAllowedInClientCountry:
            case GameError.IPBlocked:
                return true;
        }
        return false;
    }
    isMaxConnection(): boolean {
        return this._command.Reason === GameError.LobbyMaxConnection;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        console.log('hook::socket::closed', this._command.toString());
        const reason = this._command.Reason;
        switch (reason) {
            case GameError.LobbyMaxConnection:
                break;
            case GameError.TokenError:
                window.localStorage.removeItem(LocalStorageKey.FullParameter);
                if (!window.localStorage.getItem(LocalStorageKey.ClearReason)) {
                    window.localStorage.setItem(
                        LocalStorageKey.ClearReason,
                        (3000 + GameError.TokenError).toString()
                    );
                }
                dispatch(
                    popupSliceActions.open(
                        'popup.error26',
                        undefined,
                        undefined,
                        undefined,
                        true
                    )
                );
                break;
            case GameError.TokenExpire:
                window.localStorage.removeItem(LocalStorageKey.FullParameter);
                if (!window.localStorage.getItem(LocalStorageKey.ClearReason)) {
                    window.localStorage.setItem(
                        LocalStorageKey.ClearReason,
                        (3000 + GameError.TokenExpire).toString()
                    );
                }
                dispatch(
                    popupSliceActions.open(
                        ['popup.login_failed', '(', reason.toString(), ')'],
                        undefined,
                        undefined,
                        undefined,
                        true
                    )
                );
                break;
            case GameError.GamePlatformError:
                dispatch(
                    popupSliceActions.open(
                        'popup.game_platform',
                        undefined,
                        undefined,
                        undefined,
                        true
                    )
                );
                break;
            case GameError.HostListEmpty:
                dispatch(
                    popupSliceActions.open(
                        'popup.host_list_empty',
                        undefined,
                        undefined,
                        undefined,
                        true
                    )
                );
                break;
            case GameError.InMaintenance:
                dispatch(
                    popupSliceActions.open(
                        'popup.maintaining',
                        undefined,
                        undefined,
                        undefined,
                        true
                    )
                );
                break;
            case GameError.InActive:
                dispatch(
                    popupSliceActions.open(
                        [
                            'popup.login_failed',
                            '(',
                            'popup.account_locked',
                            ')',
                        ],
                        undefined,
                        undefined,
                        undefined,
                        true
                    )
                );
                break;
            case GameError.IPBlocked:
            case GameError.CurrencyNotAllowedInClientCountry:
                //Handle outside handler
                break;
            // case GameError.TokenError:
            // case GameError.LoginTimeout:
            // case GameError.CASH_WITHOUT_AT:
            // case GameError.CREDIT_WITH_AT:
            // dispatch(
            //     popupSliceActions.open([
            //         'popup.login_failed',
            //         '(',
            //         reason.toString(),
            //         ')',
            //     ])
            // );
            //     break;
            default:
                //TODO: isReconnect error popup
                dispatch(
                    popupSliceActions.open(
                        ['popup.login_failed', '(', reason.toString(), ')'],
                        undefined,
                        undefined,
                        undefined,
                        true
                    )
                );
        }
    }
}
