import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
    default as Backend,
    default as HttpBackend,
} from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { Locale } from '../../models/Locale';

i18n.use(Backend)
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(HttpBackend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: Locale.en_US.long,
        lng: Locale.en_US.long,
        debug: process.env.NODE_ENV === 'development',
        load: 'currentOnly',
        backend: {
            loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
        },
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        // react i18next special options (optional)
        // override if needed - omit if ok with defaults
        /*
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    }
    */
    });

export default i18n;
