import { useEffect, useState } from 'react';
import { batch, useSelector } from 'react-redux';
import { GameState as CommonGameState } from '../models/games/enums/GameState';
import { GameType as CommonGameType } from '../models/games/enums/GameType';
import { BetPlayerAmount } from '../models/host/BetAmount';
import { getHostById } from '../modules/host/slice';
import { getMainUserState } from '../modules/main/selector';
import { getTotalBets } from '../modules/totalBet/slice';
import { RootState } from '../store/store';
import { ExchangeBetAmount } from '../utils/commonFunc';

export const useTotalBet = (hostId: number, key: string) => {
    const { TotalBet } = useSelector((state: RootState) =>
        getTotalBets(state, hostId)
    );
    const { ExchangeRate } = useSelector(getMainUserState);
    const { CurrentState, GameType } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [clearCount, setClearCount] = useState(false);
    const [playerCount, setPlayerCount] = useState(0);
    const [betCount, setBetCount] = useState(0);
    const [percentage, setPercentage] = useState(0);
    useEffect(() => {
        const isClear =
            CurrentState === CommonGameState.Idle ||
            CurrentState === CommonGameState.Shuffle;
        setClearCount(isClear);
    }, [CurrentState]);
    useEffect(() => {
        let player = 0;
        let amount = 0;
        let p = 0;
        if (TotalBet && !clearCount) {
            const record = TotalBet as Record<string, BetPlayerAmount>;
            const target =
                record[key] ??
                ({ Amount: 0, BetPlayerCount: 0 } as BetPlayerAmount);
            player = target.BetPlayerCount;
            amount = target.Amount;
            let totalAmount = 0;
            try {
                switch (GameType) {
                    case CommonGameType.Baccarat:
                        totalAmount =
                            record['Player'].Amount +
                            record['Banker'].Amount +
                            record['Tie'].Amount;
                        break;
                    case CommonGameType.SeDie:
                        if (key === 'Small' || key === 'Big')
                            totalAmount =
                                record['Small'].Amount + record['Big'].Amount;
                        else if (key === 'Odd' || key === 'Even')
                            totalAmount =
                                record['Odd'].Amount + record['Even'].Amount;
                        break;
                    case CommonGameType.Roulette:
                        if (key === 'Small' || key === 'Big')
                            totalAmount =
                                record['Small'].Amount + record['Big'].Amount;
                        else if (key === 'Odd' || key === 'Even')
                            totalAmount =
                                record['Odd'].Amount + record['Even'].Amount;
                        else if (key === 'Black' || key === 'Red')
                            totalAmount =
                                record['Black'].Amount + record['Red'].Amount;
                        break;
                    case CommonGameType.Dragon:
                        totalAmount =
                            record['Dragon'].Amount +
                            record['Tiger'].Amount +
                            record['Tie'].Amount;
                        break;
                    case CommonGameType.TeenPatti2020:
                        totalAmount =
                            record['PlayerAWin'].Amount +
                            record['PlayerBWin'].Amount +
                            record['Tie'].Amount;
                        break;
                    case CommonGameType.SicBo:
                        if (key === 'Small' || key === 'Big')
                            totalAmount =
                                record['Small'].Amount + record['Big'].Amount;
                        else if (key === 'Odd' || key === 'Even')
                            totalAmount =
                                record['Odd'].Amount + record['Even'].Amount;
                        break;
                    case CommonGameType.PokDeng:
                        totalAmount =
                            record['Player1'].Amount +
                            record['Player2'].Amount +
                            record['Player3'].Amount +
                            record['Player4'].Amount +
                            record['Player5'].Amount;
                        break;
                }
            } catch (ex) {
                console.error('hook::total::bet', ex);
            }
            if (amount > 0 && totalAmount > 0)
                p = Math.round((amount / totalAmount) * 100);
            totalAmount = ExchangeBetAmount(totalAmount, ExchangeRate) / 100;
            amount = ExchangeBetAmount(amount, ExchangeRate) / 100;
        }
        batch(() => {
            setPlayerCount(player);
            setBetCount(amount);
            setPercentage(p);
        });
    }, [TotalBet]);
    return { playerCount, betCount, percentage };
};
