import { Button } from '@mui/material';
import { useContext, useState } from 'react';
import BaseImage from '../../../components/common/baseImage';
import { GameType as GameTypeEnum } from '../../../models/games/enums/GameType';
import '../../lobby/components/GameTables/GameTab.scss';
import './SwitchTableButton.scss';
import { VideoPlayerContext } from '../../../contexts/VideoContext';
import { TeenPattiOddsTableInfoPopup } from './TableInfoPanel/teenpatti/TeenPattiOddsTableInfoPopup';
import { AndarBaharOddsTableInfoPopup } from './TableInfoPanel/andarBahar/AndarBaharOddsTableInfoPopup';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { getHostById } from '../../host/slice';
import { AudioPlayContext } from '../../../contexts/AudioPlayContext';

export const GameInfoButton = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const { GameType } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [open, setOpen] = useState<boolean>(false);
    const changeOpen = (v: boolean) => {
        setOpen(v);
        playButtonAudio();
    };

    const getPopup = () => {
        switch (GameType) {
            case GameTypeEnum.TeenPatti2020:
                return (
                    <TeenPattiOddsTableInfoPopup
                        open={open}
                        changeOpen={changeOpen}
                    />
                );
            case GameTypeEnum.AndarBahar:
                return (
                    <AndarBaharOddsTableInfoPopup
                        open={open}
                        changeOpen={changeOpen}
                    />
                );
            default:
                return <></>;
        }
    };
    return (
        <>
            <Button
                sx={{
                    minWidth: '42px !important',
                    width: '42px',
                    height: '42px',
                    padding: '0 !important',
                    marginRight: '8px !important',
                }}
                onClick={() => changeOpen(true)}
            >
                <BaseImage className="commonUI info_btn" scale={0.63} />
            </Button>
            {open && getPopup()}
        </>
    );
};
