import { Box, Stack } from '@mui/material';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import { GameType as GameTypeEnum } from '../../../../models/games/enums/GameType';
import { RootState } from '../../../../store/store';
import { getHostById } from '../../../host/slice';
import VideoControlPanel from '../../../videoPlayer/components/videoControlPanel';
import { GameInfoButton } from '../GameInfoButton';
import { GameRulesButton } from '../GameRulesButton';
import { SwitchTableButton } from '../SwitchTableButton';
import { TotalBetAmount } from '../TotalBetAmount';
import { TableInfoHeader as RouletteTableInfoHeader } from './Roulette';

type TableInfoHeaderProps = {
    haveZoom: boolean;
    haveInfo: boolean;
    haveGameRule: boolean;
    haveVideoButtonBackground: boolean;
    haveTotalBet: boolean;
    haveSwitchTable: boolean;
};

export const TableInfoHeader = (props: TableInfoHeaderProps) => {
    const {
        haveZoom,
        haveInfo,
        haveGameRule,
        haveVideoButtonBackground = true,
        haveTotalBet,
        haveSwitchTable,
    } = props;
    const { hostId } = useContext(VideoPlayerContext);
    const { GameType } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );

    switch (GameType) {
        case GameTypeEnum.Roulette:
            return <RouletteTableInfoHeader isHorizontal={false} />;
        default:
            return (
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    sx={{
                        marginTop: '-3px',
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        bgcolor: haveVideoButtonBackground
                            ? '#000000'
                            : undefined,
                    }}
                >
                    <VideoControlPanel
                        haveZoom={haveZoom}
                        gameType={GameType}
                    />
                    {haveTotalBet && <TotalBetAmount gameType={GameType} />}
                    <Box
                        sx={{
                            direction: 'row',
                            gap: '10px',
                        }}
                    >
                        {haveInfo && <GameInfoButton />}
                        {haveGameRule && <GameRulesButton />}
                        {haveSwitchTable && <SwitchTableButton />}
                    </Box>
                </Stack>
            );
    }
};
