import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { WebSocketContext } from '../contexts/WebSocketContext';
import { CMDPsTokenUpdate } from '../models/cmd/live';
import { getMainPlayerState, getMainUserState } from '../modules/main/selector';
import {
    calcLowHigh,
    mergeCurrencyName,
    numberFormat,
} from '../utils/commonFunc';
export const useBalance = () => {
    const { Balance, HoldingMoney, BalanceValid } =
        useSelector(getMainPlayerState);
    const { CurrencyName } = useSelector(getMainUserState);
    const [displayBalance, setDisplayBalance] = useState('-');
    const [availableBalance, setAvailableBalance] = useState(0);
    const { sendCommand } = useContext(WebSocketContext);

    useEffect(() => {
        const curBalance = calcLowHigh(Balance);
        const curHoldingMoney = calcLowHigh(HoldingMoney);

        const showCurrencyName = mergeCurrencyName(CurrencyName);
        const bal = (curBalance - curHoldingMoney) / 100;
        if (bal < 0) {
            const updateBalance = new CMDPsTokenUpdate();
            sendCommand(updateBalance);
        }
        let db =
            BalanceValid && bal >= 0
                ? `${numberFormat(bal)} ${showCurrencyName}`
                : '-';
        if (db.length > 30) db = db.replace(' ', '\n');

        setDisplayBalance(db);
        if (BalanceValid) setAvailableBalance(bal);
    }, [Balance, HoldingMoney]);
    return { displayBalance, availableBalance };
};
