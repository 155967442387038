import { Stack } from '@mui/material';
import { amountFormatter } from '../../../../utils/formatter';
import { Bet } from '../../../report/slice/betRecord';
import { BetRecordDetailCell } from './BetRecordDetailsReport';
import { BetState } from '../../../../models/games/enums/BetState';

type SummaryProps = {
    bet: Bet;
    gameName: string;
};
export const Summary = (props: SummaryProps) => {
    const { bet, gameName } = props;
    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ fontSize: '3.33vw' }}
            >
                <BetRecordDetailCell
                    first={bet.Time}
                    second={gameName}
                    third={amountFormatter(bet.ResultAmount / 100 , false, '3.33vw')}
                    isTitle={false}
                    onGoing={bet.State == BetState.Ready}
                />
            </Stack>
        </>
    );
};
