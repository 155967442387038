import { CMDAndarBaharStatistics } from "./CMDAndarBaharStatistics"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpAndarBaharStatistics implements ICommand
{

	public HostID : number = 0;
	public Statistics : CMDAndarBaharStatistics;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.HostID = ba.readUint16();
		this.Statistics = new CMDAndarBaharStatistics();
		this.Statistics.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.HostID);
		let tempBuf1 = this.Statistics.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpAndarBaharStatistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'Statistics(CMDAndarBaharStatistics)='+this.Statistics+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50115;
	}

	public getCmdName() : string
	{
		return 'SpAndarBaharStatistics';
	}

}
