import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import {
    BlackjackHistory,
    BlackjackHistoryInfo,
    BlackjackRecord,
} from '../../../../models/games/blackjack';
import { GameState } from '../../../../models/games/enums/GameState';
import { RootState } from '../../../../store/store';
import {
    getBetResultByWinSlot,
    getHistoryByResult,
} from '../../../../utils/games/blackjack';
import { getHostById } from '../../../host/slice';
import TrialImage from '../TrialImage';
import './BlackjackHistoryInfoTable.scss';
import {
    BlackjackIcon,
    BustIcon,
    EmptyIcon,
    LoseIcon,
    PushIcon,
    WinIcon,
} from './HistoryIcon';
const MAX_HISTORY = 14;
export const BlackjackHistoryInfoTable = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { Record, CurrentState } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [isShuffling, setIsShuffling] = useState(false);
    const [historyInfos, setHistoryInfo] = useState<Array<BlackjackHistory>>(
        []
    );
    useEffect(() => {
        let infos = new Array<BlackjackHistory>();
        if (Record) {
            // const h = new Array<BlackjackHistory>();
            const start = Math.max(0, Record.length - MAX_HISTORY);
            const records = Record.slice(start).map(r => r as BlackjackRecord);
            infos = records
                .map(r => {
                    const result = getBetResultByWinSlot(
                        r.gameResult.Result.toString()
                    );
                    const history = getHistoryByResult(result, r.seatInfos);
                    history.DealerPoint = r.bankerHand?.point;
                    return history;
                })
                .reverse();
        }
        setHistoryInfo(infos);
    }, [Record]);
    useEffect(() => {
        setIsShuffling(CurrentState === GameState.Shuffle);
    }, [CurrentState]);
    return (
        <TableContainer
            component={Paper}
            className="blackjack-history"
            sx={{ maxHeight: '127px' }}
        >
            <Table
                size="small"
                stickyHeader
                sx={{
                    fontSize: '0.5rem',
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>D</TableCell>
                        <TableCell>P1</TableCell>
                        <TableCell>P2</TableCell>
                        <TableCell>P3</TableCell>
                        <TableCell>P5</TableCell>
                        <TableCell>P6</TableCell>
                        <TableCell>P7</TableCell>
                        <TableCell>P8</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isShuffling ? (
                        <Shuffling />
                    ) : (
                        historyInfos.map((h, index) => (
                            <TableRow key={`blackjack-history-item-${index}`}>
                                <BankerHistoryCell point={h.DealerPoint} />
                                <PlayerHistoryCell value={h.Player1} />
                                <PlayerHistoryCell value={h.Player2} />
                                <PlayerHistoryCell value={h.Player3} />
                                <PlayerHistoryCell value={h.Player5} />
                                <PlayerHistoryCell value={h.Player6} />
                                <PlayerHistoryCell value={h.Player7} />
                                <PlayerHistoryCell value={h.Player8} />
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
            <TrialImage color="white" />
        </TableContainer>
    );
};
type HistoryCellProps = {
    value: BlackjackHistoryInfo;
};
const BankerHistoryCell = ({ point }: { point?: number }) => {
    switch (point) {
        case 255:
            return (
                <TableCell>
                    <BlackjackIcon />
                </TableCell>
            );
        case 22:
            return (
                <TableCell>
                    <BustIcon />
                </TableCell>
            );
        default:
            return <TableCell className="banker-point-cell">{point}</TableCell>;
    }
};
const PlayerHistoryCell = ({ value }: HistoryCellProps) => {
    if (
        value >= BlackjackHistoryInfo.SPLIT_WIN_WIN &&
        value <= BlackjackHistoryInfo.SPLIT_BUST_BUST
    ) {
        const splitKey = BlackjackHistoryInfo[value].toString();
        const splitArray = splitKey.split('_');
        const split1 = splitArray.at(1) as SplitHistory | undefined;
        const split2 = splitArray.at(2) as SplitHistory | undefined;
        let color1 = '#FFFFFF',
            color2 = '#FFFFFF';
        if (split1 && split2) {
            color1 = getSplitHistoryColor(split1);
            color2 = getSplitHistoryColor(split2);
        }
        return (
            <TableCell>
                <svg width={20} height={20}>
                    <circle cx={5} cy={5} r={5} fill={color1} />
                    <circle cx={15} cy={15} r={5} fill={color2} />
                </svg>
            </TableCell>
        );
    }
    switch (value) {
        case BlackjackHistoryInfo.PUSH:
            return (
                <TableCell>
                    <PushIcon />
                </TableCell>
            );
        case BlackjackHistoryInfo.WIN:
            return (
                <TableCell>
                    <WinIcon />
                </TableCell>
            );
        case BlackjackHistoryInfo.LOSE:
            return (
                <TableCell>
                    <LoseIcon />
                </TableCell>
            );
        case BlackjackHistoryInfo.BUST:
            return (
                <TableCell>
                    <BustIcon />
                </TableCell>
            );
        case BlackjackHistoryInfo.BJ:
            return (
                <TableCell>
                    <BlackjackIcon />
                </TableCell>
            );
        case BlackjackHistoryInfo.NO_BET:
        default:
            return (
                <TableCell>
                    <EmptyIcon />
                </TableCell>
            );
    }
};

type SplitHistory = 'WIN' | 'LOSE' | 'PUSH' | 'BUST';
const getSplitHistoryColor = (h: SplitHistory) => {
    switch (h) {
        case 'WIN':
            return '#3A8076';
        case 'LOSE':
            return '#285FBA';
        case 'PUSH':
            return '#FFA902';
        case 'BUST':
            return '#E83323';
        default:
            return '#FFFFFF';
    }
};

const Shuffling = () => {
    const { t } = useTranslation();
    return (
        <TableRow>
            <TableCell
                colSpan={9}
                sx={{
                    fontSize: '4rem',
                }}
            >
                {t('game.shuffling')}
            </TableCell>
        </TableRow>
    );
};
