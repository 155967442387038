import { GAME_KEY } from '../../../../games/slice';
import { PHOST_KEY } from '../../../../host/slice';

import store from '../../../../../store/store';

// const calculateBetAmount = (
//     bets: Array<Bet>,
//     matchBetTypes: number
// ): number => {
//     let amount = 0;
//     amount = bets
//         .filter(item => item.type == matchBetTypes)
//         .reduce((sum, bet) => sum + bet.amount, 0);
//     return amount;
// };
const getTotalBetByBetTypes = (betType: number): number => {
    let amount = 0;
    const { pendingBets } = store.getState()[GAME_KEY];
    amount = pendingBets
        .filter(item => item.Type == betType)
        .reduce((sum, bet) => sum + bet.Amount, 0);

    const { confirmedBets } = store.getState()[GAME_KEY];
    amount += confirmedBets
        .filter(item => item.Type == betType)
        .reduce((sum, bet) => sum + bet.Amount, 0);

    return amount;
};

export const isOppositeBetting = (betType: number): boolean => {
    switch (betType) {
        default:
            return false;
    }
};

const getBetLimitByBetType = (betType: number, hostId: number): number => {
    const host = store.getState()[PHOST_KEY].entities[hostId];
    let limit = 0;

    if (host && host.MaxBet) {
        const maxBet = host.MaxBet.find(mb => mb.BetType === betType);
        if (maxBet) {
            return maxBet.MaxBet;
        }
    }

    // TODO: user.getCurBetRule(this.gametype).minBet;
    return limit;
};

export const amountOverBetLimit = (
    betType: number,
    hostId: number,
    chipAmount: number
): number => {
    let amount = 0;
    switch (betType) {
        default:
            amount =
                chipAmount +
                getTotalBetByBetTypes(betType) -
                getBetLimitByBetType(betType, hostId);
            break;
    }
    return amount;
};
