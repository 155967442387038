import { Backdrop, Grid, Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import { RootState } from '../../../../store/store';
import { getHostById } from '../../../host/slice';
import './TeenPattiECard.scss';
import { TeenPattiResult } from '../../../../models/games/teenpatti';
import { Poker } from '../../../../components/common/poker';
import { GameState as TeenPattiGameState } from '../../../../models/games/teenpatti/GameState';
import {
    getPattern,
    loadCardData,
    updatePattern,
} from '../../../../utils/games/teenpatti';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

type TeenPattiECardDisplay = {
    aCards: Array<number>;
    bCards: Array<number>;
};
type ResultDisplay = {
    winResult: number;
    playerAPattern: number;
    playerBPattern: number;
    sixCardPattern: number;
    sixCardPokers: Array<number>;
    playerALightArr: Array<number>;
    playerBLightArr: Array<number>;
    sixCardLightArr: Array<number>;
};
type TeenPattiECardProps = {
    show: boolean;
};
export const TeenPattiECard = (props: TeenPattiECardProps) => {
    const { show } = props;
    const { hostId } = useContext(VideoPlayerContext);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const [display, setDisplay] = useState<TeenPattiECardDisplay>();
    const [resultView, setResultView] = useState<ResultDisplay>();

    useEffect(() => {
        if (host.CurrentState == undefined) {
            setResultView(undefined);
            setDisplay(undefined);
            return;
        }
        const setCards = () => {
            const result = host?.CurrentResult as TeenPattiResult;
            if (result?.ResultString) {
                const { aCards, bCards } = loadCardData(result.ResultString);
                setDisplay({ aCards, bCards });
                host.ResultReleased && cardPattern();
            }
        };

        const cardPattern = () => {
            const result = host?.CurrentResult as TeenPattiResult;
            if (display && result?.FResult) {
                const {
                    winResult,
                    playerAPattern,
                    playerBPattern,
                    sixCardPattern,
                    sixCardPokers,
                } = getPattern(BigInt(result.FResult));

                setResultView({
                    winResult,
                    playerAPattern,
                    playerBPattern,
                    sixCardPattern,
                    sixCardPokers,
                    playerALightArr: updatePattern(
                        display.aCards,
                        playerAPattern,
                        false
                    ),
                    playerBLightArr: updatePattern(
                        display.bCards,
                        playerBPattern,
                        false
                    ),
                    sixCardLightArr: updatePattern(
                        sixCardPokers,
                        sixCardPattern,
                        true
                    ),
                });
            }
        };
        switch (host.CurrentState) {
            case TeenPattiGameState.Idle:
            case TeenPattiGameState.Shuffle:
            case TeenPattiGameState.Betting:
                setResultView(undefined);
                setDisplay(undefined);
                break;
            case TeenPattiGameState.PlayerAWin:
            case TeenPattiGameState.PlayerBWin:
            case TeenPattiGameState.Tie:
                setCards();
                break;
            default:
                setCards();
                break;
        }
        return () => {
            setDisplay(undefined);
            setResultView(undefined);
        };
    }, [host?.CurrentResult, host?.CurrentState]);

    const getOpacity = (index: number) => {
        if (!resultView) return 0;
        return resultView.sixCardLightArr.indexOf(index) >= 0 ? 1 : 0.4;
    };

    return (
        <Backdrop
            className={'teenpatti-poker-show-backdrop'}
            sx={{
                backgroundColor: 'rgba(0,0,0,0.9)',
                zIndex: theme => theme.zIndex.drawer + 1,
            }}
            open={show}
        >
            <Stack
                direction={'column'}
                alignItems={'center'}
                justifyContent={'flex-start'}
                width={'80%'}
                height={'100%'}
                gap={'7px'}
            >
                <Grid
                    container
                    sx={{
                        direction: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <PlayerCards
                        isPlayerA={true}
                        display={display}
                        resultView={resultView}
                    />
                    <PlayerCards
                        isPlayerA={false}
                        display={display}
                        resultView={resultView}
                    />
                </Grid>
                {resultView && (
                    <Grid container className="teenpatti-six-card">
                        <Grid
                            container
                            direction={'column'}
                            className="content"
                        >
                            <Grid
                                container
                                sx={{
                                    display: 'flex',
                                    direction: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '5px',
                                }}
                            >
                                {resultView.sixCardPokers.map((card, index) => (
                                    <Poker
                                        key={`six-poker-${index}`}
                                        scale={0.5}
                                        n={card}
                                        opacity={getOpacity(index)}
                                    />
                                ))}
                            </Grid>
                            <Grid className="six-card-pattern">
                                {t(
                                    'teenpatti.sixCardPattern.' +
                                        resultView.sixCardPattern
                                )}
                            </Grid>
                        </Grid>

                        <Grid className="six-card-bonus">
                            {t('teenpatti.sixCardBonus')}
                        </Grid>
                    </Grid>
                )}
            </Stack>
        </Backdrop>
    );
};

type PlayerCardsProps = {
    isPlayerA: boolean;
    display: TeenPattiECardDisplay | undefined;
    resultView: ResultDisplay | undefined;
};
const PlayerCards = (props: PlayerCardsProps) => {
    const { isPlayerA = true, display, resultView } = props;

    const { i18n } = useTranslation();
    const [languageDir, setLanguageDir] = useState<string>('ltr');
    useEffect(() => {
        setLanguageDir(i18n.dir(i18n.language));
    }, [i18n.language]);

    const getCard = () => {
        if (!display) return [-1, -1, -1];
        if (isPlayerA) return display.aCards;
        else return display.bCards;
    };

    const getOpacity = (index: number) => {
        if (!resultView) return 1;
        return (isPlayerA
            ? resultView.playerALightArr
            : resultView.playerBLightArr
        ).indexOf(index) >= 0
            ? 1
            : 0.4;
    };

    const getCardPatternTxt = () => {
        if (!resultView) return '';
        return t(
            'teenpatti.playerPattern.' +
                (isPlayerA
                    ? resultView.playerAPattern
                    : resultView.playerBPattern)
        );
    };

    const getBgColor = () => {
        if (!resultView) return 'black';
        if (isPlayerA && resultView.winResult == 1) return '#436FFF';
        if (!isPlayerA && resultView.winResult == 2) return '#D30000';
        return 'black';
    };

    const getBorderColor = () => {
        if (!resultView) return '#655840';
        if (isPlayerA && resultView.winResult == 1) return '#436FFF';
        if (!isPlayerA && resultView.winResult == 2) return '#D30000';
        return '#655840';
    };

    return (
        <Grid
            container
            sx={{
                direction: 'column',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'start',
                gap: '6px',
                width: '47%',
            }}
        >
            <Grid
                sx={{
                    fontSize: '28px',
                    justifyContent: 'center',
                    height: '30px',
                    marginBottom: '5px',
                    direction: languageDir,
                }}
                color={isPlayerA ? '#436fff' : '#d30000'}
            >
                {t(`betlog.bet_teenpatti.${isPlayerA ? 1 : 2}`)}
            </Grid>
            {display && (
                <Grid
                    container
                    sx={{
                        direction: 'row',
                        justifyContent: 'flex-start',
                        width: '148px',
                        gap: '8px',
                    }}
                >
                    {getCard().map((card, index) => (
                        <Poker
                            key={`poker-${index}`}
                            scale={0.7}
                            n={card}
                            opacity={getOpacity(index)}
                        />
                    ))}
                </Grid>
            )}
            {resultView && (
                <Grid
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '14px',
                        color: 'white',
                        width: '100%',
                        borderRadius: '5px',
                        height: '25px',
                    }}
                    bgcolor={getBgColor()}
                    border={`2px solid ${getBorderColor()}`}
                >
                    {getCardPatternTxt()}
                </Grid>
            )}
        </Grid>
    );
};
