import { memo } from 'react';
import { BaccaratRoadMapCellProps, RESOLUTION } from '../../baccarat/hook';
import { useDragonTigerRoadMapCellColorText } from '../hook';

const DTBeadRoadCell = (props: BaccaratRoadMapCellProps) => {
    const {
        record: { Win },
    } = props;
    const { color, text } = useDragonTigerRoadMapCellColorText(Win);
    return (
        <svg
            viewBox={`0 0 ${RESOLUTION} ${RESOLUTION}`}
            className="roadmap-cell"
        >
            {Win >= 0 && (
                <>
                    <circle
                        cx={RESOLUTION / 2}
                        cy={RESOLUTION / 2}
                        r={RESOLUTION / 2}
                        fill={color}
                    />
                    <text
                        x={RESOLUTION / 2}
                        y={RESOLUTION / 2 + 2}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill="#ffffff"
                        fontSize={RESOLUTION * 0.75}
                    >
                        {text}
                    </text>
                </>
            )}
        </svg>
    );
};
export default memo(DTBeadRoadCell);
