import { useTranslation } from "react-i18next";
import { useScreenRatio } from "../../../../../hooks/useScreenRatio";
import { Dialog, DialogContent, Grid } from "@mui/material";

const first3PatternData = {
    straightFlush: {
        title: 'andar_bahar.first_3_pattern.2',
        value: '120:1',
    },
    straight: {
        title: 'andar_bahar.first_3_pattern.1',
        value: '8:1',
    },
    flush: {
        title: 'andar_bahar.first_3_pattern.0',
        value: '5:1',
    }
};

const dataList = [
    { title: 'andar_bahar.first_3_caption', data: first3PatternData }
];

type AndarBaharOddsTableInfoPopupProps = {
    open: boolean;
    changeOpen: (b: boolean) => void;
};
export const AndarBaharOddsTableInfoPopup = (
    props: AndarBaharOddsTableInfoPopupProps
) => {
    const { open, changeOpen } = props;
    const { t } = useTranslation();

    const screenRatio = useScreenRatio();

    const handleClose = () => {
        changeOpen(false);
    };
    return (
        <div
            style={{
                width: '100%',
                height: '960',
                position: 'absolute',
                left: '50%',
                top: '50%',
                padding: '0',
                margin: '0',
                transform: `translate(-50%, -50%) scale(${screenRatio})`,
            }}
            onClick={() => handleClose()}
        >
            <Dialog
                container={() =>
                    document.getElementById('in-game-popup-container')
                }
                fullScreen
                open={open}
                onClose={handleClose}
                hideBackdrop={true}
                disableEscapeKeyDown={true}
                PaperProps={{
                    sx: {
                        boxShadow: 'none',
                        backgroundColor: 'rgba(0,0,0,0.6)',
                        padding: '2px',
                    },
                }}
            >
                <DialogContent
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflowY: 'visible',
                    }}
                >
                    <Grid
                        item
                        container
                        justifyContent={'center'}
                        sx={{
                            width: '400px',
                            height: '200px',
                            padding: '3px',
                            backdropFilter:
                                'contrast(90%) brightness(150%) blur(1px)',
                        }}
                        onClick={e => e.stopPropagation()}
                    >
                        <Grid
                            item
                            container
                            direction={'column'}
                            justifyContent={'center'}
                            gap={'30px'}
                            sx={{
                                backgroundColor: 'rgba(0,0,0,0.95)',
                            }}
                        >
                            {dataList.map((item, grpId) => {
                                return (
                                    <Grid
                                        item
                                        container
                                        key={`odds-group-cell${grpId}`}
                                        direction={'column'}
                                        alignSelf={'center'}
                                        width={'90%'}
                                        fontSize={'18px'}
                                    >
                                        <Grid
                                            item
                                            container
                                            width={'100%'}
                                            display={'flex'}
                                            direction={'row'}
                                            justifyContent={'center'}
                                            color={'#dabd73'}
                                            fontSize={'22px'}
                                        >
                                            <div>{t(item.title)}</div>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            width={'100%'}
                                            display={'flex'}
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                            color={'#dabd73'}
                                            padding={'0 20px'}
                                        >
                                            <div>{t('teenpatti.pattern')}</div>
                                            <div>{t('teenpatti.payout')}</div>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            width={'100%'}
                                            display={'flex'}
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                            color={'#dabd73'}
                                            padding={'0 20px'}
                                        >
                                        </Grid>
                                        {Object.values(item.data).map(
                                            (value, id) => {
                                                return (
                                                    <Grid
                                                        item
                                                        container
                                                        key={`odds-cell${id}`}
                                                        width={'100%'}
                                                        display={'flex'}
                                                        direction={'row'}
                                                        justifyContent={
                                                            'space-between'
                                                        }
                                                        color={'white'}
                                                        bgcolor={
                                                            id % 2 == 0
                                                                ? '#262626'
                                                                : ''
                                                        }
                                                        padding={'0 20px'}
                                                    >
                                                        <div>
                                                            {t(value.title)}
                                                        </div>
                                                        <div>{value.value}</div>
                                                    </Grid>
                                                );
                                            }
                                        )}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};
