import { Box, Stack, Typography } from '@mui/material'
import '../../../../assets/image/common/promotionTool/scratch_card.scss'
import PromotionAnimation from './PromotionAnimation'
import BaseImage from '../../../../components/common/baseImage'
import { ImageType, getImageJsonArray, parseDollarSign } from '../PromotionUtil'
import { Dispatch, useContext, useEffect, useState } from 'react'
import { PrizeListData, Prizes, ScratchCardError } from './PromotionGacha'
import { useTranslation } from 'react-i18next'
import { getMainUserState, getPromotionToolState } from '../../../main/selector'
import { RootState } from '../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { promotionToolSliceActions } from '../../../main/slice/promotiontool/promotionTool'
import { AnyAction } from 'redux'
import { displayCurrencyName, mergeCurrencyName, numberFormat } from '../../../../utils/commonFunc'
import { CommonConfigContext } from '../../../../contexts/ConfigContext'
import { BaseTypography } from '../../../../components/common/baseText/BaseTypography'

type props = {
    curPrize: number,
    prizeList: PrizeListData,
    dayLimit: number,
    scratchState: string,
    animDuration: number,
    eventEnded: boolean,
    overBudget: boolean
}

type scratchCardState = {
    scratchState: string
    bg: string,
    errorColor: string,
    errorTitle: string,
    errorMessage: string,
}

export const PromotionScratchCard = (props: props) => {
    const { t } = useTranslation();
    const dispatch: Dispatch<AnyAction> = useDispatch();
    const { RemainDayLimit, Result, ResultAmount, MaxDayLimit, IsOverBudget } = useSelector((state: RootState) => getPromotionToolState(state));
    const SUCCESS_CODE = 0xff;
    const errorSetting = {
        general: {
            bg: 'event_invalid_scratch_card_bg',
            errorColor: '#F06464',
            errorTitle: t('promotion_tool.redeem_error'),
            errorMessage: t('promotion_tool.retry')
        },
        betNow: {
            bg: 'event_invalid_scratch_card_bg',
            errorColor: '',
            errorTitle: t('promotion_tool.insufficient_points'),
            errorMessage: t('promotion_tool.accum_points'),
        },
        limitReached: {
            bg: 'event_invalid_scratch_card_bg',
            errorColor: '',
            errorTitle: t('promotion_tool.redeem_limit_reached'),
            errorMessage: t('promotion_tool.redeem_await_tmr')
        }, overBudget: {
            bg: 'event_invalid_scratch_card_bg',
            errorColor: '',
            errorTitle: t('promotion_tool.thanks'),
            errorMessage: t('promotion_tool.over_budget')
        }, eventEnded: {
            bg: 'event_invalid_scratch_card_bg',
            errorColor: '',
            errorTitle: t('promotion_tool.event_ended'),
            errorMessage: t('promotion_tool.next_event')
        }

    }

    const displayCurrencySymbol = () => {
        let output = '';
        if(isShowCurrency) output = `${parseDollarSign(currency)} `;
        return output;
    }

    const displayCurrencyText = () => {
        let output = '';

        const isDollarSign = parseDollarSign(currency) !== currency;
        if(!isDollarSign && isShowCurrency) output = ` (${mergeCurrencyName(currency)})`;
        return output;
    }
    
    //Currency handling
    const { showCurrencyName } = useContext(CommonConfigContext);
    const { CurrencyName, LobbyCode } = useSelector((state: RootState) => getMainUserState(state));
    const currency = mergeCurrencyName(useSelector((state: RootState) => (getMainUserState(state)).CurrencyName));
    const isShowCurrency = displayCurrencyName(showCurrencyName, CurrencyName, LobbyCode);

    const [state, setState] = useState<scratchCardState>({
        scratchState: props.scratchState,
        bg: 'event_3rd_scratch_card_bg',
        errorColor: '',
        errorTitle: '',
        errorMessage: '',
    });
    const winAmount: string[] = getImageJsonArray(ImageType.Prize, displayCurrencySymbol() + numberFormat((ResultAmount / 100), 0).toString());
    const [isPlay, setIsPlay] = useState(false);
    const [hideMask, setHideMask] = useState(false);

    const onPrizeChange = () => {
        switch (props.curPrize) {
            case Prizes.Gold:
                setState(prev => ({
                    ...prev, bg: 'event_1st_scratch_card_bg'
                }))
                setHideMask(false);
                setIsPlay(false);
                break;
            case Prizes.Silver:
                setState(prev => ({
                    ...prev, bg: 'event_2nd_scratch_card_bg'
                }))
                setHideMask(false);
                setIsPlay(false);
                break;
            case Prizes.Bronze:
                setState(prev => ({
                    ...prev, bg: 'event_3rd_scratch_card_bg'
                }))
                setHideMask(false);
                setIsPlay(false);
                break;
            case Prizes.AllDisabled:
                setState(prev => ({
                    ...prev, bg: 'event_invalid_scratch_card_bg',
                }))
                setHideMask(true);
                setIsPlay(false);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        onPrizeChange();
    }, [props.curPrize])

    useEffect(() => {
        if (!props.overBudget && !props.eventEnded) onLimitReached();
        return () => dispatch(promotionToolSliceActions.clearRedeemResult())
    }, [])

    useEffect(() => {
        if (Result > 0) {
            handleResult(Result);
        }
    }, [Result])

    useEffect(() => {
        updateState(props.scratchState);
    }, [props.scratchState])

    useEffect(() => {
        if (props.eventEnded) setState(prev => ({ ...prev, ...errorSetting.eventEnded }));
        else if (IsOverBudget > 0) {
            handleResult(ScratchCardError.OverBudget);
        }
    }, [props.eventEnded, IsOverBudget])

    const onLimitReached = () => {
        if (MaxDayLimit > 0 && props.dayLimit <= 0) {
            updateState('error');
            setState(prev => ({ ...prev, ...errorSetting.limitReached }));
            setHideMask(true);
            setIsPlay(false);
        }
    }

    const handleResult = (result: number) => {
        switch (result) {
            case SUCCESS_CODE:
                dispatch(promotionToolSliceActions.updateRemainDays(RemainDayLimit));
                updateState('scratching');
                break;
            case ScratchCardError.InsufficientPoints:
                updateState('error');
                setState(prev => ({ ...prev, ...errorSetting.betNow }))
                setHideMask(true);
                setIsPlay(false);
                break;
            case ScratchCardError.OverBudget:
                updateState('error');
                setState(prev => ({ ...prev, ...errorSetting.overBudget }))
                setHideMask(true);
                setIsPlay(false);
                break;
            case ScratchCardError.EventEnded:
                updateState('error');
                setState(prev => ({ ...prev, ...errorSetting.eventEnded }))
                setHideMask(true);
                setIsPlay(false);
                break;
            case ScratchCardError.OverMax:
                onLimitReached();
                break;
            default:
                updateState('error');
                errorSetting.general.errorTitle += ` (${result})`;
                setState(prev => ({ ...prev, ...errorSetting.general }))
                setHideMask(true);
                setIsPlay(false);
                break;
        }
    }

    const updateState = (s: string) => {
        switch (s) {
            case 'ready':
                setHideMask(false);
                setIsPlay(false);
                onPrizeChange();
                break;
            case 'scratching':
                setIsPlay(true);
                setHideMask(false);
                setTimeout(() => {
                    updateState('scratched');
                }, props.animDuration);
                break;
            case 'scratched':
                break;
            case 'error':
                setState(prev => ({ ...prev, scratchState: 'error' }));
                break;
            default:
                break;
        }
    }

    return (
        <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', top: '38px' }}>
            <Box className={`scratch_card ${state.bg}`}
                sx={{
                    position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'
                }}
            >
                <Box sx={{ width: '370px', height: '165px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    {
                        props.scratchState === 'scratching' || props.scratchState === 'scratched'
                            ?
                            <Box>
                                <Typography sx={{ lineHeight: '34px', color: '#FFE09D', fontSize: '28px', fontWeight: '700' }}
                                    className='promotion_tool event_congratulation_bg'
                                >
                                    {t('promotion_tool.congratulation')}
                                </Typography>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={2}>
                                    <BaseImage className='promotion_tool event_caption_arrow' scale={1} />
                                    <Box sx={{ color: '#BF9A5D', fontSize: '20px', fontWeight: '400', whiteSpace: 'nowrap' }}
                                    >
                                        {t('promotion_tool.event_win') + displayCurrencyText()}
                                    </Box>
                                    <BaseImage className='promotion_tool event_caption_arrow rotate180' scale={1} />
                                </Stack>
                                <Stack sx={{ height: '36px', marginTop: '10px' }} direction={'row'} alignItems={'center'} justifyContent={'center'}
                                >
                                    {
                                        winAmount.map((value, index) => (<BaseImage className={value} scale={0.7} key={`scratch-card-prize-${index}`} />))
                                    }
                                </Stack>
                            </Box>
                            :
                            props.scratchState === 'error'
                                ?
                                <Box sx={{ width: '300px' }}>
                                    <BaseTypography resize={{ direction:'horizontal', value:'300px' }}  sx={{ fontSize: '28px', color: state.errorColor, fontWeight: 600 }}>
                                        {state.errorTitle}
                                    </BaseTypography>
                                    <Typography>
                                        {state.errorMessage}
                                    </Typography>
                                </Box>
                                : <></>
                    }
                </Box>
            </Box>

            <Box sx={{ position: 'absolute' }}>
                {
                    hideMask ? <></> : isPlay ? <PromotionAnimation source='scratch_card event_scratch_card_scratch_1' endFrame={11} duration={props.animDuration} scale={1} /> :
                        <BaseImage className='scratch_card event_scratch_card_scratch_1' scale={1} />
                }
            </Box>
        </Box>
    )
}
