import { memo } from 'react';
import { SicBoRoadmapRecord } from '../../../../models/games/sicbo';
import { useSicBoRoadMapCellColorText } from '../hook';

const SicBoBigSmallRoadCell = ({
    size,
    BigSmallResult,
}: {
    size: number;
    BigSmallResult: SicBoRoadmapRecord['BigSmallResult'];
}) => {
    const { color, text } = useSicBoRoadMapCellColorText(BigSmallResult);
    return (
        <>
            <svg width={size} height={size}>
                {BigSmallResult !== '' && (
                    <>
                        <rect
                            width={size}
                            height={size}
                            style={{
                                fill: color,
                            }}
                        />
                        <text
                            x={size / 2}
                            y={size / 2 + 1}
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fill="#ffffff"
                            fontSize={size * 0.9}
                            fontWeight={'bold'}
                        >
                            {text}
                        </text>
                    </>
                )}
            </svg>
        </>
    );
};

export default memo(SicBoBigSmallRoadCell);