import bg_andar_bahar_mtable from '../../assets/image/common/video/bg_andar_bahar_mtable.jpg';
import bg_baccarat_atable from '../../assets/image/common/video/bg_baccarat_atable.jpg';
import bg_baccarat_ctable from '../../assets/image/common/video/bg_baccarat_ctable.jpg';
import bg_blackjack_atable from '../../assets/image/common/video/bg_blackjack_atable.jpg';
import bg_blackjack_atable_zoom from '../../assets/image/common/video/bg_blackjack_atable_zoom.jpg';
import bg_blackjack_mtable from '../../assets/image/common/video/bg_blackjack_mtable.jpg';
import bg_blackjack_mtable_zoom from '../../assets/image/common/video/bg_blackjack_mtable_zoom.jpg';
import bg_dragontiger_atable from '../../assets/image/common/video/bg_dragontiger_atable.jpg';
import bg_dragontiger_mtable from '../../assets/image/common/video/bg_dragontiger_mtable.jpg';
import bg_pokdeng_atable from '../../assets/image/common/video/bg_pokdeng_atable.jpg';
import bg_pokdeng_mtable from '../../assets/image/common/video/bg_pokdeng_mtable.jpg';
import bg_roulette_atable from '../../assets/image/common/video/bg_roulette_atable.jpg';
import bg_roulette_ctable from '../../assets/image/common/video/bg_roulette_ctable.jpg';
import bg_roulette_mtable from '../../assets/image/common/video/bg_roulette_mtable.jpg';
import bg_sedie_atable from '../../assets/image/common/video/bg_sedie_atable.jpg';
import bg_sedie_mtable from '../../assets/image/common/video/bg_sedie_mtable.jpg';
import bg_sicbo_atable from '../../assets/image/common/video/bg_sicbo_atable.jpg';
import bg_sicbo_mtable from '../../assets/image/common/video/bg_sicbo_mtable.jpg';
import bg_teen_patti_mtable from '../../assets/image/common/video/bg_teen_patti_mtable.jpg';

const VideoImages = {
    bg_andar_bahar_mtable: bg_andar_bahar_mtable,
    bg_baccarat_atable: bg_baccarat_atable,
    bg_baccarat_ctable: bg_baccarat_ctable,
    bg_blackjack_mtable: bg_blackjack_mtable,
    bg_blackjack_mtable_zoom: bg_blackjack_mtable_zoom,
    bg_blackjack_atable: bg_blackjack_atable,
    bg_blackjack_atable_zoom: bg_blackjack_atable_zoom,
    bg_dragontiger_mtable: bg_dragontiger_mtable,
    bg_dragontiger_atable: bg_dragontiger_atable,
    bg_pokdeng_mtable: bg_pokdeng_mtable,
    bg_pokdeng_atable: bg_pokdeng_atable,
    bg_roulette_mtable: bg_roulette_mtable,
    bg_roulette_atable: bg_roulette_atable,
    bg_sicbo_mtable: bg_sicbo_mtable,
    bg_sicbo_atable: bg_sicbo_atable,
    bg_teen_patti_mtable: bg_teen_patti_mtable,
    bg_sedie_mtable: bg_sedie_mtable,
    bg_sedie_atable: bg_sedie_atable,
    bg_roulette_ctable: bg_roulette_ctable,
};

export default VideoImages;
