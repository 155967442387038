import { Grid, SxProps, Theme, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import BaseImage from '../../../../../../components/common/baseImage';
import { SeatAreaContext } from '../../../../../../contexts/SeatAreaContext';
type PointDisplayProps = {
    points: Array<number>;
    isCurrentSeat: boolean;
    leftHand: boolean;
    showArrow: boolean;
    isBJ: boolean;
};
type PointDisplayTheme = {
    svg: string;
    font: string;
    background: string;
};
const theme1: PointDisplayTheme = {
    svg: 'black',
    font: 'black',
    background: 'rgba(255,184,0,0.8)',
};
const theme2: PointDisplayTheme = {
    svg: '#C2A377',
    font: 'white',
    background: 'rgba(0,0,0,0.8)',
};
const iconTheme: SxProps<Theme> = {
    position: 'relative',
    top: '4px',
    left: '4px',
};
export const PointDisplay = (props: PointDisplayProps) => {
    const { isSeatDecision, hasSplitted } = useContext(SeatAreaContext);
    const { isCurrentSeat, points, leftHand, showArrow, isBJ } = props;
    const [showBust, setShowBust] = useState(false);
    const [theme, setTheme] = useState(theme1);
    const [triangle, setTriangle] = useState<SxProps<Theme>>({});
    useEffect(() => {
        if (points.length === 1) setShowBust(points[0] > 21);
        else setShowBust(points[0] > 21 && points[1] > 21);
    }, [points]);
    useEffect(() => {
        const t = isCurrentSeat && isSeatDecision ? theme1 : theme2;
        setTheme(t);
    }, [isCurrentSeat, isSeatDecision]);
    useEffect(() => {
        if (showArrow) {
            const deg = leftHand ? -135 : 135;
            setTriangle({
                background: `linear-gradient(${deg}deg, rgba(255, 255, 255, 0.25) 6px, ${theme.background} 0);`,
            });
        }
    }, [leftHand, showArrow, theme]);
    return (
        <Grid container justifyContent={'center'} sx={{ marginTop: '3px' }}>
            <Grid
                item
                sx={{
                    background: theme.background,
                    borderRadius: '2px',
                    paddingX: '3px',
                    ...triangle,
                }}
                display={'flex'}
                alignItems={'center'}
            >
                <Typography color={theme.font} fontSize={'0.8rem'}>
                    {isBJ && !hasSplitted ? 'BJ' : points.join('/')}
                </Typography>
            </Grid>
            {isBJ && (
                <Grid sx={iconTheme}>
                    <BaseImage className={`bj_seats2 ic_bj`} scale={0.8} />
                </Grid>
            )}
            {showBust && !isBJ && (
                <Grid sx={iconTheme}>
                    <BaseImage className={`bj_seats2 ic_bust`} scale={0.8} />
                </Grid>
            )}
        </Grid>
    );
};
