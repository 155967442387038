import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { VideoPlayerContext } from '../../../../../../contexts/VideoContext';
import { RootState } from '../../../../../../store/store';
import { getHostById } from '../../../../../host/slice';
import { GamingSeatSymbol } from '../Seat/GamingSeatSymbol';

export const GamingSeatArea = () => {
    const [tableColor, setTableColor] = useState<string>('unset');
    const { hostId } = useContext(VideoPlayerContext);
    const { Group } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    useEffect(() => {
        if (Group === 'a') {
            setTableColor('#663C3B');
        } else {
            setTableColor('#1D3C2E');
        }
    }, [Group]);
    return (
        <>
            <Grid
                container
                justifyContent={'space-between'}
                sx={{ height: '100%' }}
            >
                <Grid
                    item
                    sx={{
                        position: 'relative',
                        top: '10%',
                    }}
                >
                    <GamingSeatSymbol sn={7} />
                </Grid>
                <Grid
                    item
                    sx={{
                        position: 'relative',
                        top: '30%',
                    }}
                >
                    <GamingSeatSymbol sn={6} />
                </Grid>
                <Grid
                    item
                    sx={{
                        position: 'relative',
                        top: '45%',
                    }}
                >
                    <GamingSeatSymbol sn={5} />
                </Grid>
                <Grid
                    item
                    sx={{
                        position: 'relative',
                        top: '50%',
                    }}
                >
                    <GamingSeatSymbol sn={4} />
                </Grid>
                <Grid
                    item
                    sx={{
                        position: 'relative',
                        top: '45%',
                    }}
                >
                    <GamingSeatSymbol sn={3} />
                </Grid>
                <Grid
                    item
                    sx={{
                        position: 'relative',
                        top: '30%',
                    }}
                >
                    <GamingSeatSymbol sn={2} />
                </Grid>
                <Grid
                    item
                    sx={{
                        position: 'relative',
                        top: '10%',
                    }}
                >
                    <GamingSeatSymbol sn={1} />
                </Grid>
            </Grid>
            <svg
                width="601"
                height="168"
                viewBox="0 0 1203 337"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="blackjack-mask gaming"
            >
                <g filter="url(#filter0_f_747_209390)">
                    <path
                        d="M237 103C192.5 103 104 0.5 74.5 0.5L0 36V337H1202.5V36L1158 0.5C1099 0.500057 1011 103 997.5 103C984 103 997.5 236 614.5 236C237 236 267 103 237 103Z"
                        fill={tableColor}
                    />
                </g>
                <path
                    d="M1115.5 93.5L1158 36V337H1134L1115.5 93.5Z"
                    fill={tableColor}
                />
                <path d="M90.5 277H1148V337H90.5V277Z" fill={tableColor} />
                <path d="M78 43.5L146.5 92.5V337H78V43.5Z" fill={tableColor} />
                <defs>
                    <filter
                        id="filter0_f_747_209390"
                        x="-50"
                        y="-49.5"
                        width="1302.5"
                        height="436.5"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                        />
                        <feGaussianBlur
                            stdDeviation="25"
                            result="effect1_foregroundBlur_747_209390"
                        />
                    </filter>
                </defs>
            </svg>
        </>
    );
};
