import { Box, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import BaseImage from '../../../../components/common/baseImage';
import { AudioPlayContext } from '../../../../contexts/AudioPlayContext';
import { InGamePopupKeyType } from '../../../../hooks/useInGamePopup';
import { useSelector } from 'react-redux';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import { RootState } from '../../../../store/store';
import { getHostById } from '../../../host/slice';
import { BaccaratRecord } from '../../../../models/games/baccarat';
import { GameType as GameTypeEnum } from '../../../../models/games/enums/GameType';
import { VoiceList } from '../../../../models/Voice';

export const BaccaratGameResultPopup = (props: InGamePopupKeyType) => {
    const { amount, bg, message, audioKey, playSound } = props;
    const { t } = useTranslation();
    const { audioPlay } = useContext(AudioPlayContext);
    const { hostId } = useContext(VideoPlayerContext);
    const { Record, CurrentResult, GameType } = useSelector(
        (state: RootState) => getHostById(state, hostId)
    );
    const ref = useRef<boolean>(false); //防止play 2 次
    useEffect(() => {
        if (!ref.current) {
            ref.current = true;
            if (audioKey && playSound) {
                let aKey = [];
                if (
                    GameType === GameTypeEnum.Baccarat &&
                    Record &&
                    CurrentResult
                ) {
                    const gameId = CurrentResult.GameID;
                    const curRecord: BaccaratRecord = Record.filter(
                        r => (r as BaccaratRecord).gameID === gameId
                    )[0] as BaccaratRecord;
                    if (curRecord) {
                        const bp = VoiceList.BankerPoint.replace(
                            '{point}',
                            curRecord.bankerPoint.toString()
                        );
                        const pp = VoiceList.PlayerPoint.replace(
                            '{point}',
                            curRecord.playerPoint.toString()
                        );
                        if (curRecord.bankerWin) {
                            aKey.push(bp);
                            aKey.push(pp);
                        } else {
                            aKey.push(pp);
                            aKey.push(bp);
                        }
                    }
                }
                aKey.push(audioKey);
                audioPlay(aKey);
            }
        }
    }, []);
    return (
        <Stack
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
                position: 'absolute',
                height: 90,
                width: 520,
                top: 378,
                left: 10,
                right: 10,
                fontSize: 40,
                textAlign: 'center',
                zIndex: theme => theme.zIndex.drawer + 80,
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: '-1',
                }}
            >
                <BaseImage
                    className={bg}
                    //-50 = (520-620) / 2
                    // 7.5 = (90 - 75) / 2
                    // 0.8387 = 520 / 620
                    // 1.2 = 90 / 75
                    transform={'translate(-58px, 7.5px) scale(0.8387, 1.2)'}
                    scale={1}
                />
            </Box>
            {t(message)}
            {amount && (
                <>
                    <Typography sx={{ fontSize: 28 }}>{amount}</Typography>
                </>
            )}
        </Stack>
    );
};
