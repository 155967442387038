import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CdnConfig, CdnContext } from '../contexts/CdnContext';
import { GameConfigContext } from '../contexts/ConfigContext';
import { EnterCountContext } from '../contexts/EnterCountContext';
import { GlobalDataContext } from '../contexts/GlobalDataContext';
import { ZoomFactor } from '../models/Video';
import { GameType as GameTypeEnum } from '../models/games/enums/GameType';
import { getGameSate } from '../modules/games/selector';
import { gameSliceActions } from '../modules/games/slice';
import { getHostById } from '../modules/host/slice';
import { getMainUserState } from '../modules/main/selector';
import { getSettingVolumeState } from '../modules/setting/selector';
import { RootState } from '../store/store';
import { GameConfigHostsType } from './useConfig';
import { useCurrentTime } from './useCurrentTime';
import { MessageCommand, sendToParent } from './useIframeMessage';
import { useNavigatorOnLine } from './useNavigatorOnLine';
import { useUserAgent } from './useUserAgent';
export type ResolutionType = 'HD' | 'SD' | 'Off';
type OpenPath = {
    VideoPath: string;
};
export const VIDEO_PLAYER_CONTAINER_ID = 'sa-video-player';
const getVideoUrlByHostId = (
    config: CdnConfig,
    hostConfig: GameConfigHostsType,
    hostId: number,
    vendor: string,
    quality: ResolutionType
): string | undefined => {
    if (quality === 'Off') return;

    const desk = `desk${hostId}${quality.toLocaleLowerCase()}`;
    try {
        if (config.videoType === 'FlvJs') {
            const flvChannel = config.flvChannel.find(c =>
                c.group.includes(hostConfig.group)
            )?.name;
            if (flvChannel) {
                const baseFlvUrl = config.flvUrl.replace('$a', flvChannel);
                return baseFlvUrl.replace('$d', desk);
            }
        } else if (config.videoType === 'AWS_IVS') {
            const ivsChannel = config.ivsChannel[hostId];
            if (ivsChannel)
                return config.ivsUrl.replace('$s', ivsChannel[quality]);
            /*
            const hlsChannel = config.hlsChannel.find(c => c.group.includes(hostConfig.group))?.name;
            if (hlsChannel) {
                const baseHlsUrl = config.hlsUrl.replace('$a', hlsChannel);
                return baseHlsUrl.replace('$h', desk);
            }
            */
        } else if (config.videoType === 'CMAFHlsjs') {
            const hlsChannel = config.hlsChannel.find(c =>
                c.group.includes(hostConfig.group)
            )?.name;
            if (hlsChannel) {
                const baseHlsUrl = config.hlsUrl.replace('$a', hlsChannel);
                return baseHlsUrl.replace('$h', desk);
            }
        }

        return undefined;
    } catch (err) {
        console.warn(
            'app::video::function',
            'cannot get video url of host:',
            hostId,
            vendor,
            quality,
            err
        );
        return undefined;
    }
};
export const getZoomFactor = (
    gameType: GameTypeEnum = GameTypeEnum.None,
    isZoom: boolean = false,
    isMultiBet: boolean = false
): ZoomFactor => {
    const f = (x1: number, x2: number, x3: number) =>
        ({ u: x1, v: x2, scale: x3 }) as ZoomFactor;
    const raw = f(0.5, 0.5, 1);
    console.log('app::video::function', 'zoom', gameType, isMultiBet);
    if (gameType !== GameTypeEnum.None) {
        switch (gameType) {
            case GameTypeEnum.Baccarat:
                return isZoom ? f(0.5, 0.5, 2.368) : f(0.5, 0, 1.48);
            case GameTypeEnum.SqueezeBaccarat:
                return isZoom ? f(0.5, 0.5, 2.368) : f(0.5, 0, 1.48);
            case GameTypeEnum.Dragon:
                return isZoom ? f(0.5, 0.6, 2.4) : f(0.5, 0, 1.28);
            case GameTypeEnum.PokDeng:
                return isZoom ? f(0.5, 0.7, 1.8) : f(0.5, 0, 1.11);
            case GameTypeEnum.Blackjack:
                return isZoom ? f(0.5, 0.5, 1.15) : f(0.5, 0, 1.15);
            case GameTypeEnum.TeenPatti2020:
                return isZoom ? f(0.5, 0.55, 2.4) : f(0.5, 0, 1.35);
            case GameTypeEnum.Roulette:
                return isZoom ? f(0.5, 1, 2.5) : f(0.5, 0, 1);
        }
    }
    return raw;
};
const useVideoPlayer = (hostId: number) => {
    const {
        device: { vendor },
    } = useUserAgent();
    const { GameType, IsRest } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const { Username } = useSelector(getMainUserState);
    const { Studio } = useSelector(getSettingVolumeState);
    // ivs
    const { innerWidth } = window;
    const player = useRef<MultiPlayer.VideoPlayer>();
    const cdnConfig = useContext(CdnContext);
    const gameConfig = useContext(GameConfigContext);
    const { resolution, setResolution, videoOffList, countryCode } =
        useContext(GlobalDataContext); //Issue List #104
    const [isAudioBlocked, setIsAudioBlocked] = useState<boolean>(false);
    const [isVideoOff, setIsVideoOff] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [zoomFactor, setZoomFactor] = useState<ZoomFactor>({
        u: 0.5,
        v: 0.5,
        scale: 1,
    });
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [retry, setRetry] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const dispatch = useDispatch();
    const { online: socketOnline } = useSelector(getGameSate);
    const onlineState = useNavigatorOnLine();

    const [initTime, setInitTime] = useState(0);
    const [playTime, setPlayTime] = useState(0);
    const { enterCount, addCount } = useContext(EnterCountContext);
    const currentVideoPath = useRef<string>();

    const resetTime = () => {
        setInitTime(0);
        setPlayTime(0);
    };
    useEffect(() => {
        if (playTime > 0 && initTime > 0) {
            const d = {
                hostId,
                game: initTime,
                video: playTime - initTime,
                count: enterCount,
            };
            sendToParent({
                type: MessageCommand.VIDEO_PERFORMANCE,
                data: JSON.stringify(d),
            });
            addCount();
            resetTime();
        }
    }, [playTime, initTime]);

    const { currentTimeStamp } = useCurrentTime();
    const initialPlayer = () => {
        if (!IsRest)
            try {
                if (document.getElementById(VIDEO_PLAYER_CONTAINER_ID)) {
                    setInitTime(moment().valueOf());
                    // video time
                    const dbTime = currentTimeStamp();
                    const TimestampInDBTimeZone =
                        Math.floor(dbTime / 1000) - 60 * 60 * 8; // TODO: issue #218
                    MultiPlayer.VideoPlayer.SetUsername(Username);
                    MultiPlayer.VideoPlayer.SetServerTime(
                        TimestampInDBTimeZone
                    );

                    const hostConfig = gameConfig.hosts.hostList.find(
                        h => h.hostId === hostId
                    );
                    const streamUrl = getVideoUrlByHostId(
                        cdnConfig,
                        hostConfig ?? ({} as GameConfigHostsType),
                        hostId,
                        vendor ?? 'Unknown',
                        resolution
                    );
                    console.log(
                        'app::video::channel',
                        vendor,
                        streamUrl,
                        refresh,
                        !videoOffList.includes(GameType)
                    );
                    if (streamUrl) {
                        currentVideoPath.current = streamUrl;
                        setIsAudioBlocked(false);
                        MultiPlayer.VideoPlayer.SetCountry(countryCode);
                        player.current = MultiPlayer.VideoPlayer.GetInstance();
                        player.current.EnableAutoFocusOutHandle(true);
                        player.current.SetMountTargetID(
                            VIDEO_PLAYER_CONTAINER_ID
                        );
                        player.current.Play(streamUrl, Studio / 100);
                        player.current.on(
                            MultiPlayer.Event.VideoEvent.Open,
                            onVideoOpen
                        );
                        player.current.on(
                            MultiPlayer.Event.VideoEvent.Play,
                            onVideoPlay
                        );
                        player.current.on(
                            MultiPlayer.Event.VideoEvent.Error,
                            OnPlayerError
                        );
                        player.current.on(
                            MultiPlayer.Event.VideoEvent.AudioBlock,
                            OnPlayerAudioBlock
                        );

                        dispatch(gameSliceActions.setLastVideo(streamUrl));
                    }
                }
            } catch (ex) {
                console.error(
                    'app::video::error',
                    cdnConfig.videoPlayerUrl,
                    ex
                );
            }
    };
    const killPlayer = () => {
        console.log('app::video::function', 'killPlayer');
        player.current?.Destroy();
    };
    const changeResolution = (type: ResolutionType) => {
        console.log('app::video::function', 'changeResolution', type);
        setResolution(type);
        setRefresh(v => v + 1);
    };
    const audioResume = () => {
        player.current?.AudioResume();
    };
    const doRefresh = () => {
        if (!videoOffList.includes(GameType)) {
            changeIsLoading(true);
            console.log('app::video::function', 'refresh');
            if (player.current) {
                player.current.off(
                    MultiPlayer.Event.VideoEvent.Open,
                    onVideoOpen
                );
                player.current?.off(
                    MultiPlayer.Event.VideoEvent.Play,
                    onVideoPlay
                );
                player.current?.off(
                    MultiPlayer.Event.VideoEvent.Error,
                    OnPlayerError
                );
                player.current?.off(
                    MultiPlayer.Event.VideoEvent.AudioBlock,
                    OnPlayerAudioBlock
                );

                player.current.EnableAutoFocusOutHandle(false);
                initialPlayer();
            }
        }
    };
    const zoom = (zf: ZoomFactor) => {
        if (player.current) {
            setZoomFactor(zf);
        }
    };

    const onVideoOpen = (path: OpenPath) => {
        if (path && currentVideoPath.current !== path.VideoPath) {
            console.log(
                `Open Path: ${path.VideoPath} \nCurrent Stream: ${currentVideoPath.current}`
            );
            player.current?.Destroy();
            setRefresh(v => v + 1);
        }
    };

    const onVideoPlay = () => {
        changeVideoOff(false);
        changeIsLoading(false);
        setPlayTime(moment().valueOf());
        setRetry(0);
    };

    const OnPlayerError = () => {
        player.current?.EnableAutoFocusOutHandle(false);
        setRetry(v => v + 1);
    };

    const OnPlayerAudioBlock = () => {
        setIsAudioBlocked(true);
    };

    const changeVideoOff = (b: boolean) => {
        setIsVideoOff(b);
    };
    const changeIsLoading = (b: boolean) => {
        setIsLoading(b);
    };

    useEffect(() => {
        if (refresh !== 0) {
            doRefresh();
        }
    }, [refresh]);
    useEffect(() => {
        if (!socketOnline || !onlineState) {
            if (player.current) killPlayer();
        }
    }, [socketOnline, onlineState]);
    useEffect(() => {
        if (retry === 0 || retry > 10) {
            return;
        }
        if (!videoOffList.includes(GameType)) initialPlayer();
    }, [retry]);
    useEffect(() => {
        if (!videoOffList.includes(GameType)) initialPlayer();
        return () => {
            player.current?.off(MultiPlayer.Event.VideoEvent.Play, onVideoPlay);
            player.current?.off(MultiPlayer.Event.VideoEvent.Open, onVideoOpen);
            player.current?.EnableAutoFocusOutHandle(false);
            player.current?.Stop();
            // changeVideoOff(true);
            changeIsLoading(true);
        };
    }, [hostId, IsRest, resolution, videoOffList]);
    useEffect(() => {
        const w = Number(innerWidth);
        setWidth(w);
        setHeight((w / 16) * 9);
    }, [innerWidth]);
    useEffect(() => {
        const zf = getZoomFactor(GameType);
        console.log('zoom::debug', hostId, GameType, zf);
        zoom(zf);
    }, [GameType, videoOffList]); //handle switch table -> set default video size
    useEffect(() => {
        const volume = Studio / 100;
        console.log('video::volume::change', volume);
        player.current?.SetVolume(volume);
    }, [Studio]);
    useEffect(() => {
        setZoomFactor(getZoomFactor(GameType));
        return () => {
            console.log('video::volume::destroy');
            player.current?.Destroy();
        };
    }, []);
    return {
        hostId,
        width,
        height,
        resolution,
        zoomFactor,
        isVideoOff,
        isLoading,
        isAudioBlocked,
        changeResolution,
        changeVideoOff,
        changeIsLoading,
        doRefresh,
        audioResume,
        killPlayer,
        zoom,
    };
};
export default useVideoPlayer;
export type useVideoPlayerState = ReturnType<typeof useVideoPlayer>;
