import { useContext } from 'react';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import {
    useInGamePopup,
    InGamePopupKeyType,
} from '../../../../hooks/useInGamePopup';
import { GameType } from '../../../../models/games/enums/GameType';
import { RouletteGameResultPopup } from './RouletteGameResultPopup';
import { BaccaratGameResultPopup } from './BaccaratGameResultPopup';
import { DragonTigerGameResultPopup } from './DragonTigerGameResultPopup';
import { SicBoGameResultPopup } from './SicBoGameResultPopup';
import { TeenPattiGameResultPopup } from './TeenPattiGameResultPopup';
import { AndarBaharGameResultPopup } from './AndarBaharGameResultPopup';
import { BlackjackGameResultPopup } from './BlackjackGameResultPopup';

const GameResultPanel = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { display, key } = useInGamePopup(hostId);
    return <>{display && key.message ? <GameResultPopup {...key} /> : <></>}</>;
};

const GameResultPopup = (props: InGamePopupKeyType) => {
    const { gameType } = props;
    switch (gameType) {
        case GameType.AndarBahar:
            return <AndarBaharGameResultPopup {...props} />;
        case GameType.Baccarat:
            return <BaccaratGameResultPopup {...props} />;
        case GameType.Dragon:
            return <DragonTigerGameResultPopup {...props} />;
        case GameType.SicBo:
            return <SicBoGameResultPopup {...props} />;
        case GameType.TeenPatti2020:
            return <TeenPattiGameResultPopup {...props} />;
        case GameType.Roulette:
            return <RouletteGameResultPopup {...props} />;
        case GameType.Blackjack:
            return <BlackjackGameResultPopup {...props} />;
        default:
            return <DragonTigerGameResultPopup {...props} />;
    }
};

export default GameResultPanel;
