import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { GAME_KEY, gameSliceActions } from '../../../modules/games/slice';
import {
    getHostByGameId,
    hostsSliceActions,
} from '../../../modules/host/slice';
import store from '../../../store/store';
import { GameState } from '../../games/enums/GameState';
import { CMDScShuffle, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScShuffleHandler extends AbstractHandler {
    _command: CMDScShuffle;

    constructor(command: ICommand) {
        super();
        this._command = command as CMDScShuffle;
    }

    override handle(dispatch: Dispatch<AnyAction>): void {
        const hostId = this._command.HostID;
        const state = store.getState();
        const gameState = state[GAME_KEY];
        const host = getHostByGameId(state, gameState.currentGameId);
        batch(() => {
            dispatch(
                hostsSliceActions.updateRecord({
                    hostId,
                    record: [],
                    gameType: host.GameType,
                })
            );
            dispatch(
                hostsSliceActions.updateConfirmedBets({
                    hostId,
                    gameId: 0,
                    bets: [],
                })
            );
            if (hostId == host.HostId) {
                dispatch(gameSliceActions.resetBets(gameState.currentGameId));
            }
            dispatch(
                hostsSliceActions.updateGameState({
                    hostId,
                    state: GameState.Shuffle,
                })
            );
        });
    }
}
