import { memo } from 'react';
import {
    AndarBaharRoadMapCellProps,
    CommonCellProps,
    STROKE_WIDTH,
    useAndarBaharPointRoadMapCellColorText,
} from '../hook';

const PointRoadCell = (props: AndarBaharRoadMapCellProps & CommonCellProps) => {
    const {
        record: { Win, Detail },
        size,
    } = props;
    const { color, text } = useAndarBaharPointRoadMapCellColorText(Win, Detail?.cardDealt);
    return (
        <svg width={size} height={size}>
            {Win >= 0 && (
                <>
                    <circle
                        cx={size / 2}
                        cy={size / 2}
                        r={size / 2 - STROKE_WIDTH / 2}
                        fill="transparent"
                        stroke={color}
                        strokeWidth={STROKE_WIDTH}
                    />
                    <text
                        x={size / 2}
                        y={size / 2 + 2}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill={color}
                        fontSize={size * 0.7}
                        fontWeight="bold"
                    >
                        {text}
                    </text>
                </>
            )}
        </svg>
    );
};
export default memo(PointRoadCell);