// import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { getHostById } from '../../host/slice';
import { Stack, Typography } from '@mui/material';
import { useInGamePopup } from '../../../hooks/useInGamePopup';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getInGamePopupSate } from '../../popup/selector';
import { useDispatch, useSelector } from 'react-redux';
import { StateMsgType as StateMsgTypeEnum } from '../../../models/Popup';
import BaseImage from '../../../components/common/baseImage';
import { inGamePopupSliceActions } from '../../popup/inGameSlice';
// import { getPopupState } from '../../popup/selector';

type Prop = {
    hostId: number;
};
export const MultiBetResultPanel = (props: Prop) => {
    const { hostId } = props;
    const { t } = useTranslation();
    const { CurrentResult } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const { display, key } = useInGamePopup(hostId);
    const popupData = useSelector(getInGamePopupSate);
    const dispatch = useDispatch();
    const [msgType, setMsgType] = useState(0);
    const [msgKey, setMsgKey] = useState('');
    const [isShow, setIsShow] = useState(false);
    const [icon, setIcon] = useState<string>('');
    const [textColor, setTextColor] = useState<string>('#ffffff');
    useEffect(() => {
        if (popupData.isOpen && popupData.gameID === CurrentResult?.GameID) {
            let msg: string[] = [];
            if (typeof popupData.key === 'string') {
                msg.push(popupData.key);
            } else {
                msg = popupData.key;
            }
            const transMsg = msg.map(m => {
                return t(m);
            });
            setMsgKey(transMsg.join(' '));
            setMsgType(popupData.stateMsgType);
            setIsShow(true);
        } else {
            setIsShow(false);
        }
    }, [popupData.isOpen, popupData.popupId]);
    useEffect(() => {
        if (isShow) {
            const timer = setTimeout(() => {
                clearTimeout(timer);
                dispatch(inGamePopupSliceActions.close());
                setIsShow(false);
            }, 1000);
        }
    }, [isShow]);
    useEffect(() => {
        if (display) {
            switch (key.message) {
                case 'game.betting':
                case 'game.start_bet':
                case 'popup.round_cancel':
                    setMsgType(StateMsgTypeEnum.betInfo_Normal);
                    setMsgKey(key.message);
                    setIsShow(true);
                    break;
            }
        }
    }, [display]);
    useEffect(() => {
        if (msgType !== StateMsgTypeEnum.betInfo_Normal) {
            switch (msgType) {
                case StateMsgTypeEnum.betInfo_Success:
                    setIcon('commonUI ic_success2');
                    setTextColor('#00ff00');
                    break;
                case StateMsgTypeEnum.betInfo_Fail:
                    setIcon('commonUI ic_failure2');
                    setTextColor('#ff0000');
                    break;
                case StateMsgTypeEnum.betInfo_Warning:
                    setIcon('commonUI ic_warning');
                    setTextColor('#868583');
                    break;
            }
        } else {
            setIcon('');
            setTextColor('#FFFFFF');
        }
    }, [msgType, msgKey]);
    return (
        <>
            {isShow && (
                <Stack
                    direction={'row'}
                    gap={'20px'}
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: '0',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        pointerEvents: 'none',
                        textAlign: 'center',
                    }}
                >
                    <Typography sx={{ fontSize: 28, color: textColor }}>
                        {t(msgKey)}
                    </Typography>
                    {icon && icon !== '' && (
                        <>
                            <BaseImage className={icon} scale={0.5} />
                        </>
                    )}
                </Stack>
            )}
        </>
    );
};
