import { Box, Grid, Stack } from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BaseImage from '../../../../../components/common/baseImage';
import { LobbyUiContext } from '../../../../../contexts/LobbyUiContext';
import { ResourceContext } from '../../../../../contexts/ResourceContext';
import { ROUTE_GAME } from '../../../../../models/Route';
import { GameType as GameTypeEnum } from '../../../../../models/games/enums/GameType';
import { GameCategoryTab as GameCategoryTabEnum } from '../../../../../models/lobby/GameCategoryTab';
import { RootState } from '../../../../../store/store';
import { PlayerCount } from '../../../../count/components/PlayerCount';
import { LobbyCountdown } from '../../../../countdown';
import { getHostById } from '../../../../host/slice';
import { getMainUserState } from '../../../../main/selector';
import { RoadMap } from '../../Roadmaps';
import { BetAmountStatistic } from './BetAmountStatistic';

type Props = {
    hostId: number;
};
type CellProps = {
    isNew: boolean;
    isFeatureTab: boolean;
    children: JSX.Element;
};
const TableCell = (props: Props) => {
    const { hostId } = props;
    const { GameType, IsRest } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const { featureNewTables } = useContext(ResourceContext);
    const { UNIXTimestamp, LoginTime } = useSelector(getMainUserState);
    const { grid, currentTab } = useContext(LobbyUiContext);
    const [isFeatureTab, setIsFeatureTab] = useState(false);
    const [isNew, setIsNew] = useState(false);

    const navigate = useNavigate();
    const handleOnClickGame = () => {
        if (IsRest) return;
        navigate(`${ROUTE_GAME}/${hostId}`);
    };
    const isShowFull = useMemo(() => grid === 12, [grid]);
    useEffect(() => {
        setIsFeatureTab(currentTab === GameCategoryTabEnum.Featured);
    }, [currentTab]);
    useEffect(() => {
        if (featureNewTables && UNIXTimestamp) {
            const now = UNIXTimestamp + (moment().valueOf() - LoginTime);
            const isShowNewIcon =
                featureNewTables &&
                featureNewTables.find(
                    item => item.hostId == hostId && item.ts > now
                )
                    ? true
                    : false;
            setIsNew(isShowNewIcon);
        }
    }, [featureNewTables, UNIXTimestamp]);

    return (
        <>
            <Box onClick={handleOnClickGame}>
                <Grid
                    item
                    xs={grid}
                    flexBasis="auto"
                    marginBottom="10px"
                    className={
                        isShowFull ? 'table1-col-cell' : 'table2-col-cell'
                    }
                >
                    <Stack
                        className={`table-cell ${
                            GameType === GameTypeEnum.Baccarat
                                ? 'baccarat-tab'
                                : 'other-games-tab'
                        }`}
                    >
                        {isShowFull ? (
                            <>
                                <Table1ColCell
                                    hostId={hostId}
                                    isNew={isNew}
                                    isFeatureTab={isFeatureTab}
                                >
                                    <>
                                        <PlayerCount hostId={hostId} />
                                        <LobbyCountdown
                                            hostId={hostId}
                                            gameType={GameType}
                                        />
                                    </>
                                </Table1ColCell>
                            </>
                        ) : (
                            <>
                                <Table2ColCell
                                    hostId={hostId}
                                    isNew={isNew}
                                    isFeatureTab={isFeatureTab}
                                >
                                    <>
                                        <PlayerCount hostId={hostId} />
                                        <LobbyCountdown
                                            hostId={hostId}
                                            gameType={GameType}
                                        />
                                    </>
                                </Table2ColCell>
                            </>
                        )}
                        <RoadMap hostId={hostId} gameType={GameType} />
                    </Stack>
                </Grid>
            </Box>
        </>
    );
};
const Table1ColCell = (props: Props & CellProps) => {
    const { children, hostId, isNew, isFeatureTab } = props;
    const {
        HallStateName: hallColor,
        IsGoodRoad,
        GameType,
    } = useSelector((state: RootState) => getHostById(state, hostId));
    const { t } = useTranslation();
    return (
        <>
            <Stack
                direction="row"
                alignContent="center"
                justifyContent="space-between"
                alignItems="center"
                className={`table-cell-info ${hallColor}`}
            >
                <Stack
                    direction="row"
                    alignContent="center"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{
                        paddingLeft: '8px',
                        '& div': { marginLeft: '8px' },
                        gap: '5px',
                    }}
                >
                    {t(`hostList.${hostId}`)}
                    {isNew && <BaseImage className="lobby icn_new" />}
                    {isFeatureTab && IsGoodRoad && (
                        <BaseImage className="lobby icn_goodroad" />
                    )}
                </Stack>
                <Stack
                    direction="row"
                    alignContent="center"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    sx={{
                        '& div.CountdownBox': {
                            width: '127px',
                        },
                    }}
                >
                    {children}
                </Stack>
            </Stack>
            {
                // hidden bet amount statistic when blackjack
                GameType !== GameTypeEnum.Blackjack && (
                    <Stack
                        direction="row"
                        alignContent="center"
                        justifyContent="flex-start"
                        alignItems="center"
                        className={`table-cell-info ${hallColor}2`}
                        // sx={{
                        //     '& svg': { marginLeft: '16px' },
                        //     '& p': { paddingLeft: '10px' },
                        // }}
                    >
                        <BetAmountStatistic hostId={hostId} />
                    </Stack>
                )
            }
        </>
    );
};

const Table2ColCell = (props: Props & CellProps) => {
    const { children, hostId, isNew, isFeatureTab } = props;
    const { HallStateName: hallColor, IsGoodRoad } = useSelector(
        (state: RootState) => getHostById(state, hostId)
    );
    const { t } = useTranslation();

    return (
        <>
            <Stack
                direction="row"
                alignContent="center"
                justifyContent="flex-start"
                alignItems="center"
                className={`table-cell-info ${hallColor}`}
                sx={{
                    paddingLeft: '8px',
                    '& div': { marginLeft: '8px' },
                    gap: '5px',
                }}
            >
                {t(`hostList.${hostId}`)}
                {isNew && <BaseImage className="lobby icn_new" />}
                {isFeatureTab && IsGoodRoad && (
                    <BaseImage className="lobby icn_goodroad" />
                )}
            </Stack>
            <Stack
                direction="row"
                alignContent="center"
                justifyContent="space-between"
                alignItems="center"
                className={`table-cell-info ${hallColor}2`}
                sx={{
                    paddingLeft: '8px',
                    '& div.CountdownBox': { width: '88px' },
                }}
            >
                {children}
            </Stack>
        </>
    );
};

export default TableCell;
