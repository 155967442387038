import { Box, Stack } from '@mui/material';
import { motion, useAnimationControls } from 'framer-motion';
import { CSSProperties, useContext, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AudioPlayContext } from '../../../contexts/AudioPlayContext';
import { BetChipContext } from '../../../contexts/BetChipContext';
import { VideoPlayerContext } from '../../../contexts/VideoContext';
import { WebSocketContext } from '../../../contexts/WebSocketContext';
import { useBalance } from '../../../hooks/useBalance';
import { useBetSource } from '../../../hooks/useBetSource';
import { StateMsgType } from '../../../models/Popup';
import { CMDPsBet, CMDPsRoomSeat } from '../../../models/cmd/live';
import { CMDBet } from '../../../models/cmd/live/CMDBet';
import { BetRoomSeatIndex } from '../../../models/games/BetRoom';
import { GameFeature as GameFeatureEnum } from '../../../models/games/enums/GameFeature';
import { GameState } from '../../../models/games/enums/GameState';
import { GameType as GameTypeEnum } from '../../../models/games/enums/GameType';
import { Bet } from '../../../models/host/BetAmount';
import {
    getPendingBets,
    getSelectedBetType,
    getWaitingBetResult,
    getWithHold,
} from '../../../modules/games/selector';
import { gameSliceActions } from '../../../modules/games/slice';
import { getHostById } from '../../../modules/host/slice';
import { getMainPlayerState } from '../../../modules/main/selector';
import { inGamePopupSliceActions } from '../../../modules/popup/inGameSlice';
import { CommonTotalBet } from '../../../modules/totalBet/slice';
import { RootState } from '../../../store/store';
import { isBitActive } from '../../../utils/bitwiseUtil';
import { calcLowHigh, checkBetsAvailed } from '../../../utils/commonFunc';
import { convertFResult as convertABFResult } from '../../../utils/games/andarBahar';
import {
    convertPDFResultToDetail,
    isPDBetTypeWin,
} from '../../../utils/games/pokdeng';
import BaseImage from '../baseImage';
import { Chip, PendingChip } from '../chips';
import ConfirmPanel from '../confirmPanel';
import { TotalBetAmount } from './TotalBetAmount';
import './index.scss';

export type BetAreaProps = {
    hid?: number;
    style?: CSSProperties;
    confirmPanelStyle?: CSSProperties;
    imageMargin?: string;
    imageName?: string;
    imageScale?: number;
    isMultiLang?: boolean;
    defaultLang?: { langList: string[]; default: string };
    betType: number;
    isMultiBet?: boolean;
    isShowBetAmount?: boolean;
    count?: number;
    amount?: number;
    total?: number;
    perColor?: string; //use rgb
    isOppositeBetting?: (
        betType: number,
        confirmedBets?: Array<Bet>
    ) => boolean;
    amountOverBetLimit?: (
        betType: number,
        hostId: number,
        chipAmount: number,
        confirmedBets?: Array<Bet>
    ) => number;
    roundNumDisable?: number;
    isFlash?: boolean;
    seat?: BetRoomSeatIndex;
    confirmedChipScale?: number;
    pendingChipScale?: number;
    isEnableConfirmPanel?: boolean;
    confirmedChipContainerStyle?: CSSProperties;
    pendingChipContainerStyle?: CSSProperties;
    betTypeKey?: string;
};
const BetArea = (props: BetAreaProps) => {
    const {
        hid,
        style,
        imageName,
        imageScale = 0.5,
        betType,
        isMultiBet = false,
        isShowBetAmount = false,
        perColor,
        confirmPanelStyle,
        imageMargin,
        isOppositeBetting,
        amountOverBetLimit,
        roundNumDisable,
        isMultiLang = true,
        // defaultLang = undefined,
        isFlash = false,
        confirmedChipScale = 0.7,
        pendingChipScale = 0.5,
        isEnableConfirmPanel = true,
        confirmedChipContainerStyle,
        pendingChipContainerStyle,
        betTypeKey,
    } = props;
    const dispatch = useDispatch();
    const [pendingAmount, setPendingAmount] = useState(0);
    const betSource = useBetSource(isMultiBet);
    const [isBetting, setIsBetting] = useState<boolean>(false);
    const { sendCommand } = useContext(WebSocketContext);
    const { hostId: videoHostID } = useContext(VideoPlayerContext);
    const hostId = useMemo(() => hid ?? videoHostID, [hid, videoHostID]);
    const { playButtonAudio, playChipBetAudio } = useContext(AudioPlayContext);
    const {
        IsRest,
        CurrentState,
        CurrentResult,
        ResultReleased,
        GameType,
        MaxBet,
        ConfirmedBets,
    } = useSelector((state: RootState) => getHostById(state, hostId));
    const [gameId, setGameId] = useState<number>(-1);
    const [disable, setDisable] = useState<boolean>(false);
    const {
        selectedBetAmount,
        selectedBetRule,
        allPendingBetsAmount,
        allPendingWithHoldAmount,
        currentGameId,
    } = useContext(BetChipContext);
    const allPendingBets = useSelector(getPendingBets);
    const withHold = useSelector(getWithHold);
    const currentSelectedBetType = useSelector(getSelectedBetType);
    const [isShowConfirmButton, setShowConfirmButton] =
        useState<boolean>(false);
    const { GameFeature } = useSelector(getMainPlayerState);
    const [isGameFeatureEnabled, setIsGameFeatureEnabled] =
        useState<boolean>(false);
    const controller = useAnimationControls();
    const anim = () => {
        controller.start({
            opacity: [0, 0.6, 0, 0.6, 0, 0.6, 0],
            transition: { duration: 3 },
        });
    };
    useEffect(() => {
        setShowConfirmButton(
            currentSelectedBetType === betType &&
                pendingAmount > 0 &&
                isEnableConfirmPanel
        );
    }, [currentSelectedBetType, pendingAmount, isEnableConfirmPanel]);
    useEffect(() => {
        if (
            isFlash &&
            CurrentState >= GameState.GameResult &&
            CurrentState < GameState.Shuffle &&
            ResultReleased &&
            CurrentResult?.Result
        ) {
            switch (GameType) {
                case GameTypeEnum.PokDeng:
                    if (CurrentResult.FResult) {
                        const pdResult = convertPDFResultToDetail(
                            CurrentResult.FResult
                        );
                        isPDBetTypeWin(betType, pdResult) && anim();
                    }
                    break;
                case GameTypeEnum.AndarBahar:
                    if (CurrentResult.FResult) {
                        const abResult = convertABFResult(
                            BigInt(CurrentResult.FResult)
                        );
                        abResult.BetTypeWin[betType] === true && anim();
                    }
                    break;
                default:
                    isBitActive(CurrentResult.Result, betType) && anim();
            }
        }
    }, [CurrentResult?.Result, CurrentResult?.FResult]);

    useEffect(() => {
        const curGameFeature = calcLowHigh(GameFeature);

        const isEnabled = isBitActive(
            curGameFeature,
            GameFeatureEnum.HedgeBetting
        );
        setIsGameFeatureEnabled(isEnabled);
    }, [GameFeature]);
    const [confirmedBet, setConfirmedBet] = useState<number>(0);
    useEffect(() => {
        if (
            ConfirmedBets &&
            ConfirmedBets.length &&
            CurrentState != GameState.Shuffle
        ) {
            const index = ConfirmedBets.findIndex(
                b => b.Type === betType && b.GameId === gameId
            );
            setConfirmedBet(index !== -1 ? ConfirmedBets[index].Amount : 0);
        } else {
            setConfirmedBet(0);
        }
    }, [ConfirmedBets, gameId]);
    const [withHoldAmount, setWithHoldAmount] = useState<number>(0);
    useEffect(() => {
        if (withHold) {
            const tmp = withHold.find(lr => lr.Type === betType);
            if (tmp) {
                setWithHoldAmount(tmp.Amount);
            } else {
                setWithHoldAmount(0);
            }
        } else {
            setWithHoldAmount(0);
        }
    }, [withHold]);
    useEffect(() => {
        if (currentGameId === gameId) {
            if (allPendingBets && allPendingBets.length > 0) {
                const index = allPendingBets.findIndex(
                    b => b.Type == betType && b.GameId == gameId
                );
                setPendingAmount(
                    index !== -1 ? allPendingBets[index].Amount : 0
                );
            } else {
                setPendingAmount(0);
            }
        } else {
            setPendingAmount(0);
        }
    }, [allPendingBets, withHold, currentGameId]);

    const isWaitingBetResult = useSelector((state: RootState) =>
        getWaitingBetResult(state)
    );
    const { availableBalance } = useBalance();
    const [blockConfirmButton, setBlockConfirmButton] =
        useState<boolean>(false);

    const handleRoundDisable = () => {
        if (roundNumDisable && roundNumDisable > 0 && CurrentResult) {
            const currentRound = CurrentResult.GameCount % 10000;
            setDisable(
                currentRound > roundNumDisable &&
                    CurrentState !== GameState.Shuffle
            );
        }
    };
    useEffect(() => {
        if (IsRest) {
            setIsBetting(false);
            return;
        }
        handleRoundDisable();
        setIsBetting(CurrentState === GameState.Betting);
    }, [IsRest, CurrentState, ResultReleased]);

    useEffect(() => {
        if (CurrentResult && CurrentResult.GameID !== gameId) {
            setGameId(CurrentResult.GameID);
            handleRoundDisable();
            setBlockConfirmButton(false);
        }
    }, [CurrentResult?.GameID]);

    useEffect(() => {
        // clear pending bet
        setPendingAmount(0);
        // dispatch(gameSliceActions.onCancelPendingBet());
    }, [isBetting]);

    useEffect(() => {
        if (allPendingBets && allPendingBets.length == 0) {
            setBlockConfirmButton(false);
        }
    }, [allPendingBets?.length]);

    useEffect(() => {
        setBlockConfirmButton(false);
    }, [confirmedBet]);

    const handleConfirmClick = (event: React.MouseEvent<SVGSVGElement>) => {
        if (!selectedBetRule) return;

        if (blockConfirmButton) return;

        event.stopPropagation();
        event.preventDefault();
        playButtonAudio();
        if (allPendingBets) {
            let belowBetLimit = false;
            const cmd = new CMDPsBet();
            cmd.gameID = gameId;
            cmd.BetSource = betSource;
            allPendingBets.forEach(pb => {
                let cmdBet = new CMDBet();
                cmdBet.betType = pb.Type;
                cmdBet.betAmount = pb.Amount;
                if (pb.Amount < selectedBetRule.MinBet) {
                    belowBetLimit = true;
                    if (
                        ConfirmedBets &&
                        ConfirmedBets.findIndex(cb => cb.Type === pb.Type) >= 0
                    ) {
                        belowBetLimit = false;
                    }
                }
                cmd.bets.push(cmdBet);
            });
            if (belowBetLimit) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'system.bet_below_win_bet',
                        GameType,
                        StateMsgType.betInfo_Fail,
                        gameId
                    )
                );
            } else {
                if (GameType === GameTypeEnum.Baccarat && isMultiBet) {
                    const seatCMD = new CMDPsRoomSeat();
                    seatCMD.HostID = hostId;
                    seatCMD.Seat = 100; //stand
                    seatCMD.IsMultiBet = 1;
                    sendCommand(seatCMD);
                }

                setBlockConfirmButton(true);
                sendCommand(cmd);
                batch(() => {
                    dispatch(gameSliceActions.setWaitingBetResult(true));
                    dispatch(gameSliceActions.resetSelectedBetType());
                });
            }
        } else {
            dispatch(gameSliceActions.resetSelectedBetType());
        }
    };

    const handleCancelClick = (event: React.MouseEvent<SVGSVGElement>) => {
        event.stopPropagation();
        event.preventDefault();

        playButtonAudio();
        batch(() => {
            dispatch(gameSliceActions.onCancelPendingBet());
            dispatch(gameSliceActions.resetSelectedBetType());
        });
    };

    const doPendingBet = (amount: number) => {
        if (disable) return;

        dispatch(inGamePopupSliceActions.close()); //close Bet result popup

        if (!selectedBetRule) {
            return;
        }

        // if (isBetting && chipList[selectedChip] > 0) {
        if (isBetting && amount > 0 && MaxBet) {
            // TODO: handle special bet (betType > 1000)

            //handle multibet pending
            const otherTable =
                allPendingBets &&
                allPendingBets.length > 0 &&
                allPendingBets[0].GameId !== gameId;
            const confirmBets = ConfirmedBets
                ? ConfirmedBets.filter(b => b.GameId === gameId)
                : [];
            if (
                !otherTable &&
                !isGameFeatureEnabled &&
                isOppositeBetting &&
                isOppositeBetting(betType, confirmBets)
            ) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'system.bet_opposite',
                        GameType,
                        StateMsgType.betInfo_Fail,
                        gameId
                    )
                );
                return;
            }

            const availableBets = checkBetsAvailed({
                hostId: hostId,
                bets: [
                    {
                        GameId: gameId,
                        Type: betType,
                        Amount: amount,
                        isMultiBet: isMultiBet,
                    },
                ],
                amountOverBetLimit: amountOverBetLimit,
                totalPendingBetAmount:
                    currentGameId === gameId ? allPendingBetsAmount : 0,
                totalPendingWithHoldAmount:
                    currentGameId === gameId ? allPendingWithHoldAmount : 0,
                availableBalance: availableBalance,
                withHold: withHold,
                confirmedBets: confirmBets,
            });
            if (availableBets.isNotEnoughMoney) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'system.not_enough_money',
                        GameType,
                        StateMsgType.betInfo_Fail,
                        gameId
                    )
                );
            } else if (availableBets.isOutOfBetLimit) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'system.bet_out_of_limit_red',
                        GameType,
                        StateMsgType.betInfo_Fail,
                        gameId
                    )
                );
            } else {
                if (availableBets.haveDisabledBetType) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.have_cannot_rebet_zone',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            gameId
                        )
                    );
                } else if (availableBets.haveBetLimitAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.bet_exceed_limit_allin',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            gameId
                        )
                    );
                } else if (availableBets.isAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.all_in',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            gameId
                        )
                    );
                }
                batch(() => {
                    dispatch(
                        gameSliceActions.onPendingBet(availableBets.bets[0])
                    );
                    dispatch(
                        gameSliceActions.setSelectedBetType({ type: betType })
                    );
                    playChipBetAudio();
                });
            }
        }
    };

    const handleOnClickBet = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();

        if (!isWaitingBetResult) {
            doPendingBet(selectedBetAmount);
        }
    };

    return (
        <>
            <Box
                style={style}
                position={'static'}
                className="betArea-container"
            >
                {isFlash && (
                    <motion.div animate={controller} initial={{ opacity: 0 }}>
                        <Box
                            position={'absolute'}
                            sx={{
                                width: `${style?.width}`,
                                height: `${style?.height}`,
                                borderRadius: `${style?.borderRadius}`,
                                backgroundColor: 'white',
                            }}
                        ></Box>
                    </motion.div>
                )}
                <Stack
                    position={'absolute'}
                    width={style?.width}
                    height={style?.height}
                    justifyContent={'center'}
                    alignItems={'center'}
                    padding={imageMargin}
                >
                    <BaseImage
                        className={imageName}
                        isMultiLang={isMultiLang}
                        // defaultLang={defaultLang}
                        scale={imageScale}
                    />
                </Stack>
                {isShowBetAmount && betTypeKey && perColor && (
                    <TotalBetAmount
                        style={style}
                        perColor={perColor}
                        betTypeKey={betTypeKey as keyof CommonTotalBet}
                    />
                )}
                <Stack
                    position={'absolute'}
                    width={style?.width}
                    height={style?.height}
                    justifyContent={'center'}
                    alignItems={'center'}
                    paddingLeft={confirmPanelStyle?.marginLeft}
                    paddingRight={confirmPanelStyle?.marginRight}
                >
                    {pendingAmount <= 0 && confirmedBet > 0 && (
                        <Stack
                            sx={{
                                ...confirmedChipContainerStyle,
                                position: 'absolute',
                                pointerEvents: 'none',
                            }}
                        >
                            <Chip
                                value={confirmedBet}
                                scale={confirmedChipScale}
                                isSelected={false}
                                isShowValue={true}
                                withHold={withHoldAmount}
                            />
                        </Stack>
                    )}
                    {pendingAmount > 0 && (
                        <Stack
                            sx={{
                                ...pendingChipContainerStyle,
                                position: 'absolute',
                                pointerEvents: 'none',
                            }}
                        >
                            <PendingChip
                                value={pendingAmount}
                                scale={pendingChipScale}
                            />
                        </Stack>
                    )}
                </Stack>
                <Stack
                    position={'relative'}
                    width={style?.width}
                    height={0}
                    top={confirmPanelStyle?.top}
                    paddingLeft={confirmPanelStyle?.marginLeft}
                    paddingRight={confirmPanelStyle?.marginRight}
                    alignItems={'center'}
                    zIndex={300}
                >
                    {isShowConfirmButton && (
                        <ConfirmPanel
                            onConfirmClick={handleConfirmClick}
                            onCancelClick={handleCancelClick}
                        />
                    )}
                </Stack>
                {disable && (
                    <Stack
                        sx={{
                            position: 'absolute',
                            margin: `${isMultiBet ? '0' : '-2px 0 0 -2px'}`,
                        }}
                    >
                        <Box
                            sx={{
                                bgcolor: 'rgba(0,0,0,0.5)',
                                width: style?.width,
                                height: style?.height,
                                borderRadius: style?.borderRadius,
                            }}
                        ></Box>
                    </Stack>
                )}
                <button
                    onClick={event => handleOnClickBet(event)}
                    style={{
                        position: 'absolute',
                        width: `${style?.width}`,
                        height: `${style?.height}`,
                        opacity: 0,
                    }}
                />
            </Box>
        </>
    );
};

export default BetArea;
