import './VolumeSlider.scss';
import { Slider, Stack } from '@mui/material';
type VolumeChangeHandler = (volume: number) => void;
type VolumeSliderProps = {
    volume: number;
    handleVolumeChange: VolumeChangeHandler;
    playButtonAudio: () => void;
};
export const VolumeSlider = (props: VolumeSliderProps) => {
    const { volume, handleVolumeChange, playButtonAudio } = props;
    return (
        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <Slider
                aria-label="Volume"
                value={volume}
                onChange={(_, value) => {
                    handleVolumeChange(value as number);
                }}
                onChangeCommitted={() => playButtonAudio()}
                valueLabelDisplay="auto"
                min={0}
                max={100}
                color="primary"
            />
        </Stack>
    );
};
