import { CMDScRequestFail } from "./CMDScRequestFail"
import { CMDScServer } from "./CMDScServer"
import { CMDScStartBet } from "./CMDScStartBet"
import { CMDScStartGame } from "./CMDScStartGame"
import { CMDScGameState } from "./CMDScGameState"
import { CMDScGameResult } from "./CMDScGameResult"
import { CMDScInitSync } from "./CMDScInitSync"
import { CMDScSyncData } from "./CMDScSyncData"
import { CMDScRemoveSync } from "./CMDScRemoveSync"
import { CMDScShuffle } from "./CMDScShuffle"
import { CMDScShuffleEnd } from "./CMDScShuffleEnd"
import { CMDScAnchorLogin } from "./CMDScAnchorLogin"
import { CMDScRoundReset } from "./CMDScRoundReset"
import { CMDScGameRest } from "./CMDScGameRest"
import { CMDScMaintenanceStart } from "./CMDScMaintenanceStart"
import { CMDScMaintenanceEnd } from "./CMDScMaintenanceEnd"
import { CMDScRoundCancel } from "./CMDScRoundCancel"
import { CMDScPokerShow } from "./CMDScPokerShow"
import { CMDScRestart } from "./CMDScRestart"
import { CMDScAck } from "./CMDScAck"
import { CMDScLotterySetting } from "./CMDScLotterySetting"
import { CMDScLotteryBallErase } from "./CMDScLotteryBallErase"
import { CMDScLotteryResultRevoke } from "./CMDScLotteryResultRevoke"
import { CMDScReportReject } from "./CMDScReportReject"
import { CMDScReportHostState } from "./CMDScReportHostState"
import { CMDScReportHostTicket } from "./CMDScReportHostTicket"
import { CMDScPlayerCount } from "./CMDScPlayerCount"
import { CMDScPlayerBet } from "./CMDScPlayerBet"
import { CMDScInitRoom } from "./CMDScInitRoom"
import { CMDScRoomSeat } from "./CMDScRoomSeat"
import { CMDScRoomLeft } from "./CMDScRoomLeft"
import { CMDScBlackjackUpdateSetting } from "./CMDScBlackjackUpdateSetting"
import { CMDScSkipSqueezeCountDown } from "./CMDScSkipSqueezeCountDown"
import { CMDScInitBaccarat } from "./CMDScInitBaccarat"
import { CMDScInitDragontiger } from "./CMDScInitDragontiger"
import { CMDScInitSicbo } from "./CMDScInitSicbo"
import { CMDScInitFantan } from "./CMDScInitFantan"
import { CMDScInitRoulette } from "./CMDScInitRoulette"
import { CMDScInitLottery } from "./CMDScInitLottery"
import { CMDScInitBlackjack } from "./CMDScInitBlackjack"
import { CMDScInitBaccaratSqueeze } from "./CMDScInitBaccaratSqueeze"
import { CMDScInitMoneywheel } from "./CMDScInitMoneywheel"
import { CMDScInitPokdeng } from "./CMDScInitPokdeng"
import { CMDScInitAndarBahar } from "./CMDScInitAndarBahar"
import { CMDScInitTeenPatti2020 } from "./CMDScInitTeenPatti2020"
import { CMDScInitXocdia } from "./CMDScInitXocdia"
import { CMDScTotalBetBaccarat } from "./CMDScTotalBetBaccarat"
import { CMDScTotalBetDragontiger } from "./CMDScTotalBetDragontiger"
import { CMDScTotalBetSicbo } from "./CMDScTotalBetSicbo"
import { CMDScTotalBetFantan } from "./CMDScTotalBetFantan"
import { CMDScTotalBetRoulette } from "./CMDScTotalBetRoulette"
import { CMDScTotalBetBlackjack } from "./CMDScTotalBetBlackjack"
import { CMDScTotalBetMoneywheel } from "./CMDScTotalBetMoneywheel"
import { CMDScTotalBetPokdeng } from "./CMDScTotalBetPokdeng"
import { CMDScTotalBetAndarBahar } from "./CMDScTotalBetAndarBahar"
import { CMDScTotalBetTeenPatti2020 } from "./CMDScTotalBetTeenPatti2020"
import { CMDScTotalBetXocdia } from "./CMDScTotalBetXocdia"
import { CMDScBlackjackSeatInfo } from "./CMDScBlackjackSeatInfo"
import { CMDCsRequestServer } from "./CMDCsRequestServer"
import { CMDCsAck } from "./CMDCsAck"
import { CMDCsReportAgent } from "./CMDCsReportAgent"
import { CMDSpLogin } from "./CMDSpLogin"
import { CMDSpLoginFail } from "./CMDSpLoginFail"
import { CMDSpBetResult } from "./CMDSpBetResult"
import { CMDSpBetWin } from "./CMDSpBetWin"
import { CMDSpGameResultInvalid } from "./CMDSpGameResultInvalid"
import { CMDSpRuleChange } from "./CMDSpRuleChange"
import { CMDSpBetLogQuery } from "./CMDSpBetLogQuery"
import { CMDSpHostList } from "./CMDSpHostList"
import { CMDSpInitBet } from "./CMDSpInitBet"
import { CMDSpServerReset } from "./CMDSpServerReset"
import { CMDSpBetCancel } from "./CMDSpBetCancel"
import { CMDSpKickTrialTimeout } from "./CMDSpKickTrialTimeout"
import { CMDSpLimitRedUpdate } from "./CMDSpLimitRedUpdate"
import { CMDSpPlayerReport } from "./CMDSpPlayerReport"
import { CMDSpInitMaxBet } from "./CMDSpInitMaxBet"
import { CMDSpBetLogQueryTotal } from "./CMDSpBetLogQueryTotal"
import { CMDSpDuplicateLogin } from "./CMDSpDuplicateLogin"
import { CMDSpInitEgame } from "./CMDSpInitEgame"
import { CMDSpSameIpLoginWarning } from "./CMDSpSameIpLoginWarning"
import { CMDSpMinigameAction } from "./CMDSpMinigameAction"
import { CMDSpLotteryStatistics } from "./CMDSpLotteryStatistics"
import { CMDSpLotteryFirstPrize } from "./CMDSpLotteryFirstPrize"
import { CMDSpLotteryOldResult } from "./CMDSpLotteryOldResult"
import { CMDSpSlotJackpot } from "./CMDSpSlotJackpot"
import { CMDSpLoginToken } from "./CMDSpLoginToken"
import { CMDSpEgameError } from "./CMDSpEgameError"
import { CMDSpTicketResult } from "./CMDSpTicketResult"
import { CMDSpRoomBet } from "./CMDSpRoomBet"
import { CMDSpTableLeft } from "./CMDSpTableLeft"
import { CMDSpMakeDecisionFail } from "./CMDSpMakeDecisionFail"
import { CMDSpBanSeat } from "./CMDSpBanSeat"
import { CMDSpForceStand } from "./CMDSpForceStand"
import { CMDSpLotteryTicketDetail } from "./CMDSpLotteryTicketDetail"
import { CMDSpSicboStatistics } from "./CMDSpSicboStatistics"
import { CMDSpKeyRefresh } from "./CMDSpKeyRefresh"
import { CMDSpMergeOdd } from "./CMDSpMergeOdd"
import { CMDSpRouletteStatistics } from "./CMDSpRouletteStatistics"
import { CMDSpPartnerGame } from "./CMDSpPartnerGame"
import { CMDSpFantanStatistics } from "./CMDSpFantanStatistics"
import { CMDSpPvpGame } from "./CMDSpPvpGame"
import { CMDSpPlayerBeDisabled } from "./CMDSpPlayerBeDisabled"
import { CMDSpClientConfig } from "./CMDSpClientConfig"
import { CMDSpMoneywheelStatistics } from "./CMDSpMoneywheelStatistics"
import { CMDSpMoneywheelTopResultPlayer } from "./CMDSpMoneywheelTopResultPlayer"
import { CMDSpSetLimitRedTokenResult } from "./CMDSpSetLimitRedTokenResult"
import { CMDSpAutoBetToken } from "./CMDSpAutoBetToken"
import { CMDSpLivePlayerCount } from "./CMDSpLivePlayerCount"
import { CMDSpSlotPlayerCount } from "./CMDSpSlotPlayerCount"
import { CMDSpTipsResult } from "./CMDSpTipsResult"
import { CMDSpActionReject } from "./CMDSpActionReject"
import { CMDSpBaccaratGoodRoad } from "./CMDSpBaccaratGoodRoad"
import { CMDSpPlayerPattern } from "./CMDSpPlayerPattern"
import { CMDSpQueryPatternExisted } from "./CMDSpQueryPatternExisted"
import { CMDSpRequestVideoToken } from "./CMDSpRequestVideoToken"
import { CMDSpGiftSetting } from "./CMDSpGiftSetting"
import { CMDSpBaccaratStatistics } from "./CMDSpBaccaratStatistics"
import { CMDSpPokdengStatistics } from "./CMDSpPokdengStatistics"
import { CMDSpDragontigerStatistics } from "./CMDSpDragontigerStatistics"
import { CMDSpSendBackLobby } from "./CMDSpSendBackLobby"
import { CMDSpAndarBaharStatistics } from "./CMDSpAndarBaharStatistics"
import { CMDSpTeenPatti2020Statistics } from "./CMDSpTeenPatti2020Statistics"
import { CMDSpXocdiaStatistics } from "./CMDSpXocdiaStatistics"
import { CMDSpChangePasswordReturn } from "./CMDSpChangePasswordReturn"
import { CMDSpPromotionEvent } from "./CMDSpPromotionEvent"
import { CMDSpRedeemResult } from "./CMDSpRedeemResult"
import { CMDSpPromotionRanking } from "./CMDSpPromotionRanking"
import { CMDSpPromotionRedeemLog } from "./CMDSpPromotionRedeemLog"
import { CMDSpSuspendPromotionPopup } from "./CMDSpSuspendPromotionPopup"
import { CMDSpPromotionState } from "./CMDSpPromotionState"
import { CMDSpRoomSeat } from "./CMDSpRoomSeat"
import { CMDSpPlayerRecentlyPlay } from "./CMDSpPlayerRecentlyPlay"
import { CMDSpBetLogSummaryQuery } from "./CMDSpBetLogSummaryQuery"
import { CMDSpChatroomAuthFail } from "./CMDSpChatroomAuthFail"
import { CMDSpChatroomAuth } from "./CMDSpChatroomAuth"
import { CMDSpChatroomInfo } from "./CMDSpChatroomInfo"
import { CMDPsLogin } from "./CMDPsLogin"
import { CMDPsBet } from "./CMDPsBet"
import { CMDPsRuleChange } from "./CMDPsRuleChange"
import { CMDPsBetLogQuery } from "./CMDPsBetLogQuery"
import { CMDPsTableEnter } from "./CMDPsTableEnter"
import { CMDPsRequestInitClient } from "./CMDPsRequestInitClient"
import { CMDPsPlayerReport } from "./CMDPsPlayerReport"
import { CMDPsSetPrefs } from "./CMDPsSetPrefs"
import { CMDPsTokenUpdate } from "./CMDPsTokenUpdate"
import { CMDPsEgameLogin } from "./CMDPsEgameLogin"
import { CMDPsRoomSeat } from "./CMDPsRoomSeat"
import { CMDPsLottery } from "./CMDPsLottery"
import { CMDPsLotteryStatistics } from "./CMDPsLotteryStatistics"
import { CMDPsLotteryOldResult } from "./CMDPsLotteryOldResult"
import { CMDPsEgameDebugJackpot } from "./CMDPsEgameDebugJackpot"
import { CMDPsRequestLoginToken } from "./CMDPsRequestLoginToken"
import { CMDPsRequestTicketResult } from "./CMDPsRequestTicketResult"
import { CMDPsDisconnect } from "./CMDPsDisconnect"
import { CMDPsMinigameAction } from "./CMDPsMinigameAction"
import { CMDPsMinigameActionDone } from "./CMDPsMinigameActionDone"
import { CMDPsMakeDecision } from "./CMDPsMakeDecision"
import { CMDPsStandUp } from "./CMDPsStandUp"
import { CMDPsLotteryTicketDetail } from "./CMDPsLotteryTicketDetail"
import { CMDPsChatroomAuth } from "./CMDPsChatroomAuth"
import { CMDPsRequestChatroom } from "./CMDPsRequestChatroom"
import { CMDPsSetLimitRedToken } from "./CMDPsSetLimitRedToken"
import { CMDPsRequestAutoBetToken } from "./CMDPsRequestAutoBetToken"
import { CMDPsDealerTips } from "./CMDPsDealerTips"
import { CMDPsPlayerPattern } from "./CMDPsPlayerPattern"
import { CMDPsQueryPatternExisted } from "./CMDPsQueryPatternExisted"
import { CMDPsRequestVideoToken } from "./CMDPsRequestVideoToken"
import { CMDPsPrizeRedeem } from "./CMDPsPrizeRedeem"
import { CMDPsLoginLaucherTime } from "./CMDPsLoginLaucherTime"
import { CMDPsChangePassword } from "./CMDPsChangePassword"
import { CMDPsSuspendPromotionPopup } from "./CMDPsSuspendPromotionPopup"
import { CMDPsQueryPromotionPoint } from "./CMDPsQueryPromotionPoint"
import { CMDPsQueryPromotionRanking } from "./CMDPsQueryPromotionRanking"
import { CMDPsQueryPromotionRedeemLog } from "./CMDPsQueryPromotionRedeemLog"
import { CMDPsQueryPromotionState } from "./CMDPsQueryPromotionState"
import { CMDPsPlayerRecentlyPlay } from "./CMDPsPlayerRecentlyPlay"
import { CMDPsBetLogSummaryQuery } from "./CMDPsBetLogSummaryQuery"
import { ICommand } from "./ICommand"
export const enum Command
{
		SC_REQUEST_FAIL = 30001,
		SC_SERVER = 30002,
		SC_START_BET = 30003,
		SC_START_GAME = 30004,
		SC_GAME_STATE = 30005,
		SC_GAME_RESULT = 30006,
		SC_INIT_SYNC = 30007,
		SC_SYNC_DATA = 30008,
		SC_REMOVE_SYNC = 30009,
		SC_SHUFFLE = 30010,
		SC_SHUFFLE_END = 30011,
		SC_ANCHOR_LOGIN = 30012,
		SC_ROUND_RESET = 30013,
		SC_GAME_REST = 30017,
		SC_MAINTENANCE_START = 30019,
		SC_MAINTENANCE_END = 30020,
		SC_ROUND_CANCEL = 30021,
		SC_POKER_SHOW = 30022,
		SC_RESTART = 30023,
		SC_ACK = 30024,
		SC_LOTTERY_SETTING = 30025,
		SC_LOTTERY_BALL_ERASE = 30026,
		SC_LOTTERY_RESULT_REVOKE = 30027,
		SC_REPORT_REJECT = 30028,
		SC_REPORT_HOST_STATE = 30029,
		SC_REPORT_HOST_TICKET = 30030,
		SC_PLAYER_COUNT = 30031,
		SC_PLAYER_BET = 30032,
		SC_INIT_ROOM = 30033,
		SC_ROOM_SEAT = 30034,
		SC_ROOM_LEFT = 30035,
		SC_BLACKJACK_UPDATE_SETTING = 30036,
		SC_SKIP_SQUEEZE_COUNT_DOWN = 30038,
		SC_INIT_BACCARAT = 30501,
		SC_INIT_DRAGONTIGER = 30502,
		SC_INIT_SICBO = 30503,
		SC_INIT_FANTAN = 30504,
		SC_INIT_ROULETTE = 30505,
		SC_INIT_LOTTERY = 30506,
		SC_INIT_BLACKJACK = 30507,
		SC_INIT_BACCARAT_SQUEEZE = 30508,
		SC_INIT_MONEYWHEEL = 30509,
		SC_INIT_POKDENG = 30510,
		SC_INIT_ANDAR_BAHAR = 30511,
		SC_INIT_TEEN_PATTI_20_20 = 30512,
		SC_INIT_XOCDIA = 30513,
		SC_TOTAL_BET_BACCARAT = 30751,
		SC_TOTAL_BET_DRAGONTIGER = 30752,
		SC_TOTAL_BET_SICBO = 30753,
		SC_TOTAL_BET_FANTAN = 30754,
		SC_TOTAL_BET_ROULETTE = 30755,
		SC_TOTAL_BET_BLACKJACK = 30756,
		SC_TOTAL_BET_MONEYWHEEL = 30757,
		SC_TOTAL_BET_POKDENG = 30758,
		SC_TOTAL_BET_ANDAR_BAHAR = 30759,
		SC_TOTAL_BET_TEEN_PATTI_20_20 = 30760,
		SC_TOTAL_BET_XOCDIA = 30771,
		SC_BLACKJACK_SEAT_INFO = 30772,
		CS_REQUEST_SERVER = 35001,
		CS_ACK = 35002,
		CS_REPORT_AGENT = 35003,
		SP_LOGIN = 50001,
		SP_LOGIN_FAIL = 50002,
		SP_BET_RESULT = 50003,
		SP_BET_WIN = 50004,
		SP_GAME_RESULT_INVALID = 50005,
		SP_RULE_CHANGE = 50006,
		SP_BET_LOG_QUERY = 50010,
		SP_HOST_LIST = 50012,
		SP_INIT_BET = 50013,
		SP_SERVER_RESET = 50014,
		SP_BET_CANCEL = 50015,
		SP_KICK_TRIAL_TIMEOUT = 50016,
		SP_LIMIT_RED_UPDATE = 50018,
		SP_PLAYER_REPORT = 50019,
		SP_INIT_MAX_BET = 50020,
		SP_BET_LOG_QUERY_TOTAL = 50025,
		SP_DUPLICATE_LOGIN = 50026,
		SP_INIT_EGAME = 50027,
		SP_SAME_IP_LOGIN_WARNING = 50030,
		SP_MINIGAME_ACTION = 50031,
		SP_LOTTERY_STATISTICS = 50032,
		SP_LOTTERY_FIRST_PRIZE = 50033,
		SP_LOTTERY_OLD_RESULT = 50034,
		SP_SLOT_JACKPOT = 50035,
		SP_LOGIN_TOKEN = 50036,
		SP_EGAME_ERROR = 50037,
		SP_TICKET_RESULT = 50038,
		SP_ROOM_BET = 50039,
		SP_TABLE_LEFT = 50040,
		SP_MAKE_DECISION_FAIL = 50042,
		SP_BAN_SEAT = 50043,
		SP_FORCE_STAND = 50044,
		SP_LOTTERY_TICKET_DETAIL = 50045,
		SP_SICBO_STATISTICS = 50046,
		SP_KEY_REFRESH = 50047,
		SP_MERGE_ODD = 50048,
		SP_ROULETTE_STATISTICS = 50049,
		SP_PARTNER_GAME = 50050,
		SP_FANTAN_STATISTICS = 50053,
		SP_PVP_GAME = 50065,
		SP_PLAYER_BE_DISABLED = 50067,
		SP_CLIENT_CONFIG = 50073,
		SP_MONEYWHEEL_STATISTICS = 50082,
		SP_MONEYWHEEL_TOP_RESULT_PLAYER = 50083,
		SP_SET_LIMIT_RED_TOKEN_RESULT = 50084,
		SP_AUTO_BET_TOKEN = 50085,
		SP_LIVE_PLAYER_COUNT = 50086,
		SP_SLOT_PLAYER_COUNT = 50087,
		SP_TIPS_RESULT = 50088,
		SP_ACTION_REJECT = 50089,
		SP_BACCARAT_GOOD_ROAD = 50090,
		SP_PLAYER_PATTERN = 50091,
		SP_QUERY_PATTERN_EXISTED = 50092,
		SP_REQUEST_VIDEO_TOKEN = 50093,
		SP_GIFT_SETTING = 50094,
		SP_BACCARAT_STATISTICS = 50095,
		SP_POKDENG_STATISTICS = 50099,
		SP_DRAGONTIGER_STATISTICS = 50100,
		SP_SEND_BACK_LOBBY = 50101,
		SP_ANDAR_BAHAR_STATISTICS = 50115,
		SP_TEEN_PATTI_20_20_STATISTICS = 50116,
		SP_XOCDIA_STATISTICS = 50117,
		SP_CHANGE_PASSWORD_RETURN = 50118,
		SP_PROMOTION_EVENT = 50119,
		SP_REDEEM_RESULT = 50120,
		SP_PROMOTION_RANKING = 50121,
		SP_PROMOTION_REDEEM_LOG = 50122,
		SP_SUSPEND_PROMOTION_POPUP = 50123,
		SP_PROMOTION_STATE = 50124,
		SP_ROOM_SEAT = 50125,
		SP_PLAYER_RECENTLY_PLAY = 50126,
		SP_BET_LOG_SUMMARY_QUERY = 50127,
		SP_CHATROOM_AUTH_FAIL = 52001,
		SP_CHATROOM_AUTH = 52002,
		SP_CHATROOM_INFO = 52003,
		PS_LOGIN = 55001,
		PS_BET = 55002,
		PS_RULE_CHANGE = 55003,
		PS_BET_LOG_QUERY = 55007,
		PS_TABLE_ENTER = 55008,
		PS_REQUEST_INIT_CLIENT = 55010,
		PS_PLAYER_REPORT = 55012,
		PS_SET_PREFS = 55015,
		PS_TOKEN_UPDATE = 55016,
		PS_EGAME_LOGIN = 55018,
		PS_ROOM_SEAT = 55023,
		PS_LOTTERY = 55024,
		PS_LOTTERY_STATISTICS = 55025,
		PS_LOTTERY_OLD_RESULT = 55026,
		PS_EGAME_DEBUG_JACKPOT = 55027,
		PS_REQUEST_LOGIN_TOKEN = 55028,
		PS_REQUEST_TICKET_RESULT = 55029,
		PS_DISCONNECT = 55031,
		PS_MINIGAME_ACTION = 55032,
		PS_MINIGAME_ACTION_DONE = 55033,
		PS_MAKE_DECISION = 55034,
		PS_STAND_UP = 55035,
		PS_LOTTERY_TICKET_DETAIL = 55036,
		PS_CHATROOM_AUTH = 55061,
		PS_REQUEST_CHATROOM = 55062,
		PS_SET_LIMIT_RED_TOKEN = 55064,
		PS_REQUEST_AUTO_BET_TOKEN = 55065,
		PS_DEALER_TIPS = 55066,
		PS_PLAYER_PATTERN = 55067,
		PS_QUERY_PATTERN_EXISTED = 55068,
		PS_REQUEST_VIDEO_TOKEN = 55069,
		PS_PRIZE_REDEEM = 55073,
		PS_LOGIN_LAUCHER_TIME = 55089,
		PS_CHANGE_PASSWORD = 55090,
		PS_SUSPEND_PROMOTION_POPUP = 55091,
		PS_QUERY_PROMOTION_POINT = 55092,
		PS_QUERY_PROMOTION_RANKING = 55093,
		PS_QUERY_PROMOTION_REDEEM_LOG = 55094,
		PS_QUERY_PROMOTION_STATE = 55095,
		PS_PLAYER_RECENTLY_PLAY = 55096,
		PS_BET_LOG_SUMMARY_QUERY = 55097,
	}

	export const getCommand = (ID : number) : ICommand | undefined =>
	{
		switch(ID)
		{
		case Command.SC_REQUEST_FAIL:
			return new CMDScRequestFail();
		case Command.SC_SERVER:
			return new CMDScServer();
		case Command.SC_START_BET:
			return new CMDScStartBet();
		case Command.SC_START_GAME:
			return new CMDScStartGame();
		case Command.SC_GAME_STATE:
			return new CMDScGameState();
		case Command.SC_GAME_RESULT:
			return new CMDScGameResult();
		case Command.SC_INIT_SYNC:
			return new CMDScInitSync();
		case Command.SC_SYNC_DATA:
			return new CMDScSyncData();
		case Command.SC_REMOVE_SYNC:
			return new CMDScRemoveSync();
		case Command.SC_SHUFFLE:
			return new CMDScShuffle();
		case Command.SC_SHUFFLE_END:
			return new CMDScShuffleEnd();
		case Command.SC_ANCHOR_LOGIN:
			return new CMDScAnchorLogin();
		case Command.SC_ROUND_RESET:
			return new CMDScRoundReset();
		case Command.SC_GAME_REST:
			return new CMDScGameRest();
		case Command.SC_MAINTENANCE_START:
			return new CMDScMaintenanceStart();
		case Command.SC_MAINTENANCE_END:
			return new CMDScMaintenanceEnd();
		case Command.SC_ROUND_CANCEL:
			return new CMDScRoundCancel();
		case Command.SC_POKER_SHOW:
			return new CMDScPokerShow();
		case Command.SC_RESTART:
			return new CMDScRestart();
		case Command.SC_ACK:
			return new CMDScAck();
		case Command.SC_LOTTERY_SETTING:
			return new CMDScLotterySetting();
		case Command.SC_LOTTERY_BALL_ERASE:
			return new CMDScLotteryBallErase();
		case Command.SC_LOTTERY_RESULT_REVOKE:
			return new CMDScLotteryResultRevoke();
		case Command.SC_REPORT_REJECT:
			return new CMDScReportReject();
		case Command.SC_REPORT_HOST_STATE:
			return new CMDScReportHostState();
		case Command.SC_REPORT_HOST_TICKET:
			return new CMDScReportHostTicket();
		case Command.SC_PLAYER_COUNT:
			return new CMDScPlayerCount();
		case Command.SC_PLAYER_BET:
			return new CMDScPlayerBet();
		case Command.SC_INIT_ROOM:
			return new CMDScInitRoom();
		case Command.SC_ROOM_SEAT:
			return new CMDScRoomSeat();
		case Command.SC_ROOM_LEFT:
			return new CMDScRoomLeft();
		case Command.SC_BLACKJACK_UPDATE_SETTING:
			return new CMDScBlackjackUpdateSetting();
		case Command.SC_SKIP_SQUEEZE_COUNT_DOWN:
			return new CMDScSkipSqueezeCountDown();
		case Command.SC_INIT_BACCARAT:
			return new CMDScInitBaccarat();
		case Command.SC_INIT_DRAGONTIGER:
			return new CMDScInitDragontiger();
		case Command.SC_INIT_SICBO:
			return new CMDScInitSicbo();
		case Command.SC_INIT_FANTAN:
			return new CMDScInitFantan();
		case Command.SC_INIT_ROULETTE:
			return new CMDScInitRoulette();
		case Command.SC_INIT_LOTTERY:
			return new CMDScInitLottery();
		case Command.SC_INIT_BLACKJACK:
			return new CMDScInitBlackjack();
		case Command.SC_INIT_BACCARAT_SQUEEZE:
			return new CMDScInitBaccaratSqueeze();
		case Command.SC_INIT_MONEYWHEEL:
			return new CMDScInitMoneywheel();
		case Command.SC_INIT_POKDENG:
			return new CMDScInitPokdeng();
		case Command.SC_INIT_ANDAR_BAHAR:
			return new CMDScInitAndarBahar();
		case Command.SC_INIT_TEEN_PATTI_20_20:
			return new CMDScInitTeenPatti2020();
		case Command.SC_INIT_XOCDIA:
			return new CMDScInitXocdia();
		case Command.SC_TOTAL_BET_BACCARAT:
			return new CMDScTotalBetBaccarat();
		case Command.SC_TOTAL_BET_DRAGONTIGER:
			return new CMDScTotalBetDragontiger();
		case Command.SC_TOTAL_BET_SICBO:
			return new CMDScTotalBetSicbo();
		case Command.SC_TOTAL_BET_FANTAN:
			return new CMDScTotalBetFantan();
		case Command.SC_TOTAL_BET_ROULETTE:
			return new CMDScTotalBetRoulette();
		case Command.SC_TOTAL_BET_BLACKJACK:
			return new CMDScTotalBetBlackjack();
		case Command.SC_TOTAL_BET_MONEYWHEEL:
			return new CMDScTotalBetMoneywheel();
		case Command.SC_TOTAL_BET_POKDENG:
			return new CMDScTotalBetPokdeng();
		case Command.SC_TOTAL_BET_ANDAR_BAHAR:
			return new CMDScTotalBetAndarBahar();
		case Command.SC_TOTAL_BET_TEEN_PATTI_20_20:
			return new CMDScTotalBetTeenPatti2020();
		case Command.SC_TOTAL_BET_XOCDIA:
			return new CMDScTotalBetXocdia();
		case Command.SC_BLACKJACK_SEAT_INFO:
			return new CMDScBlackjackSeatInfo();
		case Command.CS_REQUEST_SERVER:
			return new CMDCsRequestServer();
		case Command.CS_ACK:
			return new CMDCsAck();
		case Command.CS_REPORT_AGENT:
			return new CMDCsReportAgent();
		case Command.SP_LOGIN:
			return new CMDSpLogin();
		case Command.SP_LOGIN_FAIL:
			return new CMDSpLoginFail();
		case Command.SP_BET_RESULT:
			return new CMDSpBetResult();
		case Command.SP_BET_WIN:
			return new CMDSpBetWin();
		case Command.SP_GAME_RESULT_INVALID:
			return new CMDSpGameResultInvalid();
		case Command.SP_RULE_CHANGE:
			return new CMDSpRuleChange();
		case Command.SP_BET_LOG_QUERY:
			return new CMDSpBetLogQuery();
		case Command.SP_HOST_LIST:
			return new CMDSpHostList();
		case Command.SP_INIT_BET:
			return new CMDSpInitBet();
		case Command.SP_SERVER_RESET:
			return new CMDSpServerReset();
		case Command.SP_BET_CANCEL:
			return new CMDSpBetCancel();
		case Command.SP_KICK_TRIAL_TIMEOUT:
			return new CMDSpKickTrialTimeout();
		case Command.SP_LIMIT_RED_UPDATE:
			return new CMDSpLimitRedUpdate();
		case Command.SP_PLAYER_REPORT:
			return new CMDSpPlayerReport();
		case Command.SP_INIT_MAX_BET:
			return new CMDSpInitMaxBet();
		case Command.SP_BET_LOG_QUERY_TOTAL:
			return new CMDSpBetLogQueryTotal();
		case Command.SP_DUPLICATE_LOGIN:
			return new CMDSpDuplicateLogin();
		case Command.SP_INIT_EGAME:
			return new CMDSpInitEgame();
		case Command.SP_SAME_IP_LOGIN_WARNING:
			return new CMDSpSameIpLoginWarning();
		case Command.SP_MINIGAME_ACTION:
			return new CMDSpMinigameAction();
		case Command.SP_LOTTERY_STATISTICS:
			return new CMDSpLotteryStatistics();
		case Command.SP_LOTTERY_FIRST_PRIZE:
			return new CMDSpLotteryFirstPrize();
		case Command.SP_LOTTERY_OLD_RESULT:
			return new CMDSpLotteryOldResult();
		case Command.SP_SLOT_JACKPOT:
			return new CMDSpSlotJackpot();
		case Command.SP_LOGIN_TOKEN:
			return new CMDSpLoginToken();
		case Command.SP_EGAME_ERROR:
			return new CMDSpEgameError();
		case Command.SP_TICKET_RESULT:
			return new CMDSpTicketResult();
		case Command.SP_ROOM_BET:
			return new CMDSpRoomBet();
		case Command.SP_TABLE_LEFT:
			return new CMDSpTableLeft();
		case Command.SP_MAKE_DECISION_FAIL:
			return new CMDSpMakeDecisionFail();
		case Command.SP_BAN_SEAT:
			return new CMDSpBanSeat();
		case Command.SP_FORCE_STAND:
			return new CMDSpForceStand();
		case Command.SP_LOTTERY_TICKET_DETAIL:
			return new CMDSpLotteryTicketDetail();
		case Command.SP_SICBO_STATISTICS:
			return new CMDSpSicboStatistics();
		case Command.SP_KEY_REFRESH:
			return new CMDSpKeyRefresh();
		case Command.SP_MERGE_ODD:
			return new CMDSpMergeOdd();
		case Command.SP_ROULETTE_STATISTICS:
			return new CMDSpRouletteStatistics();
		case Command.SP_PARTNER_GAME:
			return new CMDSpPartnerGame();
		case Command.SP_FANTAN_STATISTICS:
			return new CMDSpFantanStatistics();
		case Command.SP_PVP_GAME:
			return new CMDSpPvpGame();
		case Command.SP_PLAYER_BE_DISABLED:
			return new CMDSpPlayerBeDisabled();
		case Command.SP_CLIENT_CONFIG:
			return new CMDSpClientConfig();
		case Command.SP_MONEYWHEEL_STATISTICS:
			return new CMDSpMoneywheelStatistics();
		case Command.SP_MONEYWHEEL_TOP_RESULT_PLAYER:
			return new CMDSpMoneywheelTopResultPlayer();
		case Command.SP_SET_LIMIT_RED_TOKEN_RESULT:
			return new CMDSpSetLimitRedTokenResult();
		case Command.SP_AUTO_BET_TOKEN:
			return new CMDSpAutoBetToken();
		case Command.SP_LIVE_PLAYER_COUNT:
			return new CMDSpLivePlayerCount();
		case Command.SP_SLOT_PLAYER_COUNT:
			return new CMDSpSlotPlayerCount();
		case Command.SP_TIPS_RESULT:
			return new CMDSpTipsResult();
		case Command.SP_ACTION_REJECT:
			return new CMDSpActionReject();
		case Command.SP_BACCARAT_GOOD_ROAD:
			return new CMDSpBaccaratGoodRoad();
		case Command.SP_PLAYER_PATTERN:
			return new CMDSpPlayerPattern();
		case Command.SP_QUERY_PATTERN_EXISTED:
			return new CMDSpQueryPatternExisted();
		case Command.SP_REQUEST_VIDEO_TOKEN:
			return new CMDSpRequestVideoToken();
		case Command.SP_GIFT_SETTING:
			return new CMDSpGiftSetting();
		case Command.SP_BACCARAT_STATISTICS:
			return new CMDSpBaccaratStatistics();
		case Command.SP_POKDENG_STATISTICS:
			return new CMDSpPokdengStatistics();
		case Command.SP_DRAGONTIGER_STATISTICS:
			return new CMDSpDragontigerStatistics();
		case Command.SP_SEND_BACK_LOBBY:
			return new CMDSpSendBackLobby();
		case Command.SP_ANDAR_BAHAR_STATISTICS:
			return new CMDSpAndarBaharStatistics();
		case Command.SP_TEEN_PATTI_20_20_STATISTICS:
			return new CMDSpTeenPatti2020Statistics();
		case Command.SP_XOCDIA_STATISTICS:
			return new CMDSpXocdiaStatistics();
		case Command.SP_CHANGE_PASSWORD_RETURN:
			return new CMDSpChangePasswordReturn();
		case Command.SP_PROMOTION_EVENT:
			return new CMDSpPromotionEvent();
		case Command.SP_REDEEM_RESULT:
			return new CMDSpRedeemResult();
		case Command.SP_PROMOTION_RANKING:
			return new CMDSpPromotionRanking();
		case Command.SP_PROMOTION_REDEEM_LOG:
			return new CMDSpPromotionRedeemLog();
		case Command.SP_SUSPEND_PROMOTION_POPUP:
			return new CMDSpSuspendPromotionPopup();
		case Command.SP_PROMOTION_STATE:
			return new CMDSpPromotionState();
		case Command.SP_ROOM_SEAT:
			return new CMDSpRoomSeat();
		case Command.SP_PLAYER_RECENTLY_PLAY:
			return new CMDSpPlayerRecentlyPlay();
		case Command.SP_BET_LOG_SUMMARY_QUERY:
			return new CMDSpBetLogSummaryQuery();
		case Command.SP_CHATROOM_AUTH_FAIL:
			return new CMDSpChatroomAuthFail();
		case Command.SP_CHATROOM_AUTH:
			return new CMDSpChatroomAuth();
		case Command.SP_CHATROOM_INFO:
			return new CMDSpChatroomInfo();
		case Command.PS_LOGIN:
			return new CMDPsLogin();
		case Command.PS_BET:
			return new CMDPsBet();
		case Command.PS_RULE_CHANGE:
			return new CMDPsRuleChange();
		case Command.PS_BET_LOG_QUERY:
			return new CMDPsBetLogQuery();
		case Command.PS_TABLE_ENTER:
			return new CMDPsTableEnter();
		case Command.PS_REQUEST_INIT_CLIENT:
			return new CMDPsRequestInitClient();
		case Command.PS_PLAYER_REPORT:
			return new CMDPsPlayerReport();
		case Command.PS_SET_PREFS:
			return new CMDPsSetPrefs();
		case Command.PS_TOKEN_UPDATE:
			return new CMDPsTokenUpdate();
		case Command.PS_EGAME_LOGIN:
			return new CMDPsEgameLogin();
		case Command.PS_ROOM_SEAT:
			return new CMDPsRoomSeat();
		case Command.PS_LOTTERY:
			return new CMDPsLottery();
		case Command.PS_LOTTERY_STATISTICS:
			return new CMDPsLotteryStatistics();
		case Command.PS_LOTTERY_OLD_RESULT:
			return new CMDPsLotteryOldResult();
		case Command.PS_EGAME_DEBUG_JACKPOT:
			return new CMDPsEgameDebugJackpot();
		case Command.PS_REQUEST_LOGIN_TOKEN:
			return new CMDPsRequestLoginToken();
		case Command.PS_REQUEST_TICKET_RESULT:
			return new CMDPsRequestTicketResult();
		case Command.PS_DISCONNECT:
			return new CMDPsDisconnect();
		case Command.PS_MINIGAME_ACTION:
			return new CMDPsMinigameAction();
		case Command.PS_MINIGAME_ACTION_DONE:
			return new CMDPsMinigameActionDone();
		case Command.PS_MAKE_DECISION:
			return new CMDPsMakeDecision();
		case Command.PS_STAND_UP:
			return new CMDPsStandUp();
		case Command.PS_LOTTERY_TICKET_DETAIL:
			return new CMDPsLotteryTicketDetail();
		case Command.PS_CHATROOM_AUTH:
			return new CMDPsChatroomAuth();
		case Command.PS_REQUEST_CHATROOM:
			return new CMDPsRequestChatroom();
		case Command.PS_SET_LIMIT_RED_TOKEN:
			return new CMDPsSetLimitRedToken();
		case Command.PS_REQUEST_AUTO_BET_TOKEN:
			return new CMDPsRequestAutoBetToken();
		case Command.PS_DEALER_TIPS:
			return new CMDPsDealerTips();
		case Command.PS_PLAYER_PATTERN:
			return new CMDPsPlayerPattern();
		case Command.PS_QUERY_PATTERN_EXISTED:
			return new CMDPsQueryPatternExisted();
		case Command.PS_REQUEST_VIDEO_TOKEN:
			return new CMDPsRequestVideoToken();
		case Command.PS_PRIZE_REDEEM:
			return new CMDPsPrizeRedeem();
		case Command.PS_LOGIN_LAUCHER_TIME:
			return new CMDPsLoginLaucherTime();
		case Command.PS_CHANGE_PASSWORD:
			return new CMDPsChangePassword();
		case Command.PS_SUSPEND_PROMOTION_POPUP:
			return new CMDPsSuspendPromotionPopup();
		case Command.PS_QUERY_PROMOTION_POINT:
			return new CMDPsQueryPromotionPoint();
		case Command.PS_QUERY_PROMOTION_RANKING:
			return new CMDPsQueryPromotionRanking();
		case Command.PS_QUERY_PROMOTION_REDEEM_LOG:
			return new CMDPsQueryPromotionRedeemLog();
		case Command.PS_QUERY_PROMOTION_STATE:
			return new CMDPsQueryPromotionState();
		case Command.PS_PLAYER_RECENTLY_PLAY:
			return new CMDPsPlayerRecentlyPlay();
		case Command.PS_BET_LOG_SUMMARY_QUERY:
			return new CMDPsBetLogSummaryQuery();
		}

		return undefined;
	}
