import {
    Stack,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { getHostById } from '../../../../host/slice';
import {
    getMainUserState,
    getMainLimitRedState,
} from '../../../../main/selector';
import BaseImage from '../../../../../components/common/baseImage';
import { useScreenRatio } from '../../../../../hooks/useScreenRatio';
import { BetType } from '../../../../../models/games/roulette/BetType';
import { ExchangeBetAmount } from '../../../../../utils/commonFunc';
import { limitRedFormatter } from '../../../../../utils/formatter';
import { REPORT_COLORS } from '../../../../lobby/constants';
import { CancelButton } from '../../../../setting/components/CancelButton';
import { AudioPlayContext } from '../../../../../contexts/AudioPlayContext';

type InfoProps = {
    title: string;
    value: string | number;
};
type TableInfoTypeRow1 = {
    table: InfoProps;
    dealer: InfoProps;
    round: InfoProps;
    id: InfoProps;
    tableLimit: InfoProps;
};
type TableInfoTypeRow2 = {
    betLimit: InfoProps;
    maxLimit: InfoProps;
    minLimit: InfoProps;
    redBlack: InfoProps;
    oddEven: InfoProps;
};
type TableInfoTypeRow3 = {
    bigSmall: InfoProps;
    set: InfoProps;
    row: InfoProps;
};
type BetLimitType = {
    bigSmall: InfoProps;
    evenOdd: InfoProps;
    redBlack: InfoProps;
    set: InfoProps;
    row: InfoProps;
    straight: InfoProps;
    twoNumber: InfoProps;
    threeNumber: InfoProps;
    fourNumber: InfoProps;
    sixNumber: InfoProps;
};
type TableInfoType = [TableInfoTypeRow1, TableInfoTypeRow2, TableInfoTypeRow3];
const initTableInfo = [
    {
        table: {
            title: 'tableinfo.table',
            value: '',
        },
        dealer: {
            title: 'tableinfo.dealer',
            value: '',
        },
        round: {
            title: 'tableinfo.shoe',
            value: '',
        },
        id: {
            title: 'tableinfo.round',
            value: '',
        },
        tableLimit: {
            title: 'tableinfo.tablelimit',
            value: '',
        },
    },
    {
        betLimit: {
            title: 'betLimit',
            value: '',
        },
        maxLimit: {
            title: 'tableinfo.maxlimit',
            value: '',
        },
        minLimit: {
            title: 'tableinfo.minlimit',
            value: '',
        },
        redBlack: {
            title: 'tableinfo.red_black',
            value: '',
        },
        oddEven: {
            title: 'tableinfo.odd_even',
            value: '',
        },
    },
    {
        bigSmall: {
            title: 'tableinfo.big_small',
            value: '',
        },
        set: {
            title: 'tableinfo.set',
            value: '',
        },
        row: {
            title: 'tableinfo.row',
            value: '',
        },
    },
] as TableInfoType;

const betLimitData = {
    bigSmall: {
        title: 'tableinfo.big_small',
        value: '',
    },
    evenOdd: {
        title: 'tableinfo.odd_even',
        value: '',
    },
    redBlack: {
        title: 'tableinfo.red_black',
        value: '',
    },
    set: {
        title: 'tableinfo.set',
        value: '',
    },
    row: {
        title: 'tableinfo.row',
        value: '',
    },
    straight: {
        title: 'tableinfo.straight_bet',
        value: '',
    },
    twoNumber: {
        title: 'tableinfo.two_number_bet',
        value: '',
    },
    threeNumber: {
        title: 'tableinfo.three_number_bet',
        value: '',
    },
    fourNumber: {
        title: 'tableinfo.four_number_bet',
        value: '',
    },
    sixNumber: {
        title: 'tableinfo.six_number_bet',
        value: '',
    },
} as BetLimitType;

type Props = {
    hostId: number;
};
export const RouletteTableInfoTab = (props: Props) => {
    const { hostId } = props;
    const { t } = useTranslation();
    const { playButtonAudio } = useContext(AudioPlayContext);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { CurrentResult, DealerList, TableLimit, GameType, MaxBet } = host;
    const { ExchangeRate } = useSelector((state: RootState) =>
        getMainUserState(state)
    );
    const limitRedByGameType = useSelector(getMainLimitRedState);
    const [tableInfo, setTableInfo] = useState<TableInfoType>(initTableInfo);
    const [betLimit, setBetLimit] = useState<BetLimitType>(betLimitData);
    const { CurrencyName } = useSelector(getMainUserState);
    const getMaxBetByBetType = (betType: number): string => {
        if (!MaxBet) return '0';
        const index = MaxBet.findIndex(d => {
            return d.BetType === betType;
        });
        return index !== -1
            ? (MaxBet[index].MaxBet / 100).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
              })
            : '0';
    };
    useEffect(() => {
        if (MaxBet) {
            let newTableInfo = Object.create(tableInfo);
            let newBetLimit = Object.create(betLimit);

            newBetLimit.redBlack.value = newTableInfo[1].redBlack.value =
                getMaxBetByBetType(BetType.RBRed);
            newBetLimit.evenOdd.value = newTableInfo[1].oddEven.value =
                getMaxBetByBetType(BetType.RBEven);
            //row 3
            newBetLimit.bigSmall.value = newTableInfo[2].bigSmall.value =
                getMaxBetByBetType(BetType.RB1To18);
            newBetLimit.set.value = newTableInfo[2].set.value =
                getMaxBetByBetType(BetType.RBSet1);
            newBetLimit.row.value = newTableInfo[2].row.value =
                getMaxBetByBetType(BetType.RBRow1);

            newBetLimit.straight.value = getMaxBetByBetType(BetType.RB_0);
            newBetLimit.twoNumber.value = getMaxBetByBetType(BetType.RB_0_1);
            newBetLimit.threeNumber.value = getMaxBetByBetType(
                BetType.RB_0_1_2
            );
            newBetLimit.fourNumber.value = getMaxBetByBetType(
                BetType.RB_0_1_2_3
            );
            newBetLimit.sixNumber.value = getMaxBetByBetType(
                BetType.RB_10_11_12_13_14_15
            );

            setBetLimit(betLimit);
            setTableInfo(newTableInfo);
        }
    }, [MaxBet]);

    useEffect(() => {
        if (
            hostId &&
            DealerList &&
            TableLimit &&
            CurrentResult &&
            limitRedByGameType.BetRuleByGameType
        ) {
            let newTableInfo = Object.create(tableInfo);
            //row 1
            newTableInfo[0].table.value = t(`hostCodeNames.${hostId}`);
            newTableInfo[0].dealer.value = DealerList[0].DealerName;
            const shoes = Math.floor(CurrentResult.GameCount / 10000);
            const round = CurrentResult.GameCount % 10000;
            const row1Round = shoes && round ? `${shoes}-${round}` : round;
            newTableInfo[0].round.value = row1Round;
            newTableInfo[0].id.value = CurrentResult.GameID || 0;
            //row 2
            const selectedBetRule = limitRedByGameType.BetRuleByGameType.find(
                br => br.GameType === GameType
            )?.BetRule.find(r => r.Selected === 1);
            let betLimitV = '';
            if (selectedBetRule) {
                betLimitV = limitRedFormatter(selectedBetRule, CurrencyName);
            }
            newTableInfo[1].betLimit.value = betLimitV;
            const betLimitValues = betLimitV.split(' - ');
            if (betLimitValues && betLimitValues.length > 1) {
                newTableInfo[1].minLimit.value = betLimitValues[0];
                newTableInfo[1].maxLimit.value = betLimitValues[1];
            }

            let infoTableLimit =
                ExchangeBetAmount(TableLimit, ExchangeRate) / 100;
            const digi = Math.pow(10, infoTableLimit.toString().length - 1);
            const tableLimitValue = (
                Math.round(infoTableLimit / digi) * digi
            ).toLocaleString('en-US', {
                maximumFractionDigits: 2,
            });
            newTableInfo[0].tableLimit.value = tableLimitValue;
            setTableInfo(newTableInfo);
        }
    }, [
        hostId,
        CurrentResult,
        DealerList,
        TableLimit,
        limitRedByGameType.BetRuleByGameType,
    ]);

    const [open, setOpen] = useState<boolean>(false);
    const changeOpen = (b: boolean) => {
        playButtonAudio();
        setOpen(b);
    };
    return (
        <>
            {tableInfo && (
                <>
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        sx={{
                            width: '540px',
                            fontSize: '14px',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                            }}
                        >
                            <BaseImage
                                className="tradbaccBg table_info_bg"
                                scale={1}
                                opacity={0.5}
                                transform={'translate(0, 24.5px) scale(1, 1.3)'}
                            />
                        </Box>
                        {tableInfo.map((r, i) => {
                            const style =
                                i !== 1
                                    ? { width: '33%', zIndex: '1' }
                                    : {
                                          width: '34%',
                                          zIndex: '1',
                                          borderLeft:
                                              '2px solid rgba(255,255,255,0.2)',
                                          borderRight:
                                              '2px solid rgba(255,255,255,0.2)',
                                      };
                            return (
                                <Box key={`info-${i}`} sx={style}>
                                    <>
                                        {Object.keys(r).map((k, ii) => {
                                            const v = r[
                                                k as keyof typeof r
                                            ] as InfoProps;
                                            return (
                                                <Grid
                                                    key={`info-cell${ii}`}
                                                    item
                                                    container
                                                    justifyContent={
                                                        'space-between'
                                                    }
                                                    alignItems={'center'}
                                                    height={'36px'}
                                                    padding={'0 7.5px 0 7.5px'}
                                                >
                                                    <Grid
                                                        item
                                                        sx={{
                                                            color: REPORT_COLORS.TITLE_TEXT,
                                                        }}
                                                    >
                                                        {t(v.title)}
                                                    </Grid>
                                                    <Grid item>{v.value}</Grid>
                                                </Grid>
                                            );
                                        })}
                                        {i == 2 && (
                                            <>
                                                <Grid
                                                    item
                                                    height={'36px'}
                                                ></Grid>
                                                <Grid
                                                    item
                                                    container
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    height={'36px'}
                                                    padding={'0 7.5px 0 7.5px'}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            height: '32px',
                                                            width: '99%',
                                                            backgroundColor:
                                                                '#8b7554',
                                                            padding: 0,
                                                            justifyContent:
                                                                'center',
                                                        }}
                                                        onClick={() =>
                                                            changeOpen(true)
                                                        }
                                                    >
                                                        <BaseImage
                                                            className="commonUI ic_arrow_down_white_10px"
                                                            transform={
                                                                'rotate(180deg)'
                                                            }
                                                            scale={1}
                                                        />
                                                    </Button>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                </Box>
                            );
                        })}
                    </Stack>
                </>
            )}
            {open && (
                <BetLimitPopup
                    open={open}
                    changeOpen={changeOpen}
                    betLimitData={betLimit}
                />
            )}
        </>
    );
};

type BetLimitPopupProps = {
    open: boolean;
    changeOpen: (b: boolean) => void;
    betLimitData: BetLimitType;
};
export const BetLimitPopup = (props: BetLimitPopupProps) => {
    const { open, changeOpen, betLimitData } = props;
    const { t } = useTranslation();

    const screenRatio = useScreenRatio();

    const handleClose = () => {
        changeOpen(false);
    };
    return (
        <div
            style={{
                width: '540',
                height: '960',
                position: 'absolute',
                left: '50%',
                top: '50%',
                padding: '0',
                margin: '0',
                transform: `translate(-50%, -50%) scale(${screenRatio})`,
            }}
        >
            <Dialog
                container={() =>
                    document.getElementById('in-game-popup-container')
                }
                fullScreen
                open={open}
                onClose={handleClose}
                hideBackdrop={true}
                disableEscapeKeyDown={true}
                PaperProps={{
                    sx: {
                        backgroundColor: 'rgba(0,0,0,0.85)',
                        boxShadow: 'none',
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        paddingTop: '100px',
                        fontSize: '29px',
                        textAlign: 'center',
                    }}
                >
                    {t('betLimit2')}
                </DialogTitle>
                <DialogContent
                    sx={{
                        justifyContent: 'center',
                        overflowY: 'visible',
                    }}
                >
                    <Grid
                        item
                        container
                        direction={'row'}
                        justifyContent={'center'}
                    >
                        <Grid
                            item
                            container
                            direction={'row'}
                            justifyContent={'center'}
                            display={'grid'}
                            gridTemplateColumns={'repeat(2, 1fr)'}
                            gridTemplateRows={'repeat(5, 1fr)'}
                            gridAutoFlow={'column'}
                            padding={'100px 35px'}
                        >
                            {Object.values(betLimitData).map((value, id) => {
                                return (
                                    <Grid
                                        item
                                        container
                                        key={`bet-limit-cell${id}`}
                                        direction={'column'}
                                        justifyContent={'center'}
                                        marginBottom={'30px'}
                                        fontSize={'18px'}
                                    >
                                        <div style={{ color: '#bea887' }}>
                                            {t(value.title)}
                                        </div>
                                        <div style={{ color: '#fff' }}>
                                            {'' + value.value}
                                        </div>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Grid
                            item
                            container
                            direction={'row'}
                            justifyContent={'center'}
                        >
                            <CancelButton
                                onClick={handleClose}
                                image={'commonUI ic_close'}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};
