import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Button, Grid, Menu, MenuItem } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLobbyGame } from '../../../../hooks/useLobbyGame';
import { GameCategory as GameCategoryEnum } from '../../../../models/GameCategory';
import { REPORT_SIZE } from '../../../lobby/constants';

type GameCategorySelectorProp = {
    category: GameCategoryEnum;
    setCategory: Dispatch<SetStateAction<GameCategoryEnum>>;
    playButtonAudio: () => void;
};
export const GameCategorySelector = (props: GameCategorySelectorProp) => {
    const { category, setCategory, playButtonAudio } = props;
    const id = 'game-category-menu';
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [dropdown, setDropdown] = useState<Array<GameCategoryEnum>>([
        GameCategoryEnum.GCAll,
    ]);
    const lobbyGame = useLobbyGame();
    const open = Boolean(anchorEl);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        playButtonAudio();
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChangeCategory = (c: GameCategoryEnum) => {
        playButtonAudio();
        setCategory(c);
        handleClose();
    };
    useEffect(() => {
        const newDropdown = new Array<GameCategoryEnum>();
        newDropdown.push(GameCategoryEnum.GCAll);
        if (lobbyGame.haveLiveGame) newDropdown.push(GameCategoryEnum.GCLive);
        setDropdown(newDropdown);
    }, [lobbyGame]);
    return (
        <Grid
            item
            className="game-category-selector"
            sx={{ marginLeft: '24px' }}
        >
            <Button
                sx={{
                    width: '45.7vw',
                    height: '10vw',
                    fontSize: REPORT_SIZE.TEXT,
                }}
                aria-controls={open ? id : undefined}
                aria-haspopup={true}
                aria-expanded={open ? true : undefined}
                variant="contained"
                disableElevation
                onClick={handleOpen}
                endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            >
                {t(getString(category))}
            </Button>
            <Menu
                id={id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {dropdown.map(c => (
                    <MenuItem
                        onClick={() =>
                            handleChangeCategory(c as GameCategoryEnum)
                        }
                        key={`game-category-selector-${c}`}
                        sx={{ fontSize: REPORT_SIZE.TEXT }}
                    >
                        {t(getString(c))}
                    </MenuItem>
                ))}
            </Menu>
        </Grid>
    );
};

const getString = (c: GameCategoryEnum) => `report.game_type_choice.${c}`;
