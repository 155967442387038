import { Dialog } from '@mui/material';
import { useWindowSize } from '@react-hook/window-size';
import { useEffect, useState } from 'react';
import BaseImage from '../../components/common/baseImage';
import { IsMobile } from '../../hooks/useScreenRatio';
import { useUserAgent } from '../../hooks/useUserAgent';

export const BlockLayer = () => {
    const [isOpen, setIsOpen] = useState(false);

    const [landscape, setLandscape] = useState<boolean>(
        window.innerWidth / window.innerHeight > 1.2
    );
    const [width, height] = useWindowSize();
    const { device } = useUserAgent();
    const updateRatio = (): void => {
        setLandscape(width / height > 1.2);
    };

    useEffect(() => {
        updateRatio();
    }, [width, height]);

    useEffect(() => {
        setIsOpen(landscape && IsMobile(device));
    }, [landscape]);

    return (
        <Dialog fullScreen open={isOpen} disableEscapeKeyDown>
            <div
                className="blockLayerDiv"
                style={{
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    margin: '0px',
                    padding: '0px',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                }}
            >
                <div>
                    <div
                        style={{
                            // left: 'calc(50% - 176px * 1.5)', //ic_Rotate = 176x176, scale x2
                            // top: 'calc(50% - 176px * 1.5)',
                            position: 'absolute',
                            left: 'calc(50% - 88px)',
                            top: 'calc(50% - 88px)',
                            // transform: 'translate(0px, 0px) scale(4)',
                        }}
                    >
                        <BaseImage className="commonUI ic_Rotate" scale={1} />
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default BlockLayer;
