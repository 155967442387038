import { Close as CloseIcon } from '@mui/icons-material';
import { Button, Dialog, Grid, IconButton, Stack } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BaseImage from '../../../components/common/baseImage';
import { AudioPlayContext } from '../../../contexts/AudioPlayContext';
import { CdnContext } from '../../../contexts/CdnContext';
import { GlobalDataContext } from '../../../contexts/GlobalDataContext';
import { VideoPlayerContext } from '../../../contexts/VideoContext';
import { POPUP_TYPE } from '../../../models/Popup';
import { GameType as GameTypeEnum } from '../../../models/games/enums/GameType';
import { RootState } from '../../../store/store';
import { theme } from '../../../theme';
import { getHostById } from '../../host/slice';
import { getLandingState } from '../../main/selector';
import { popupSliceActions } from '../../popup/slice';
import './liveEmojiButton.scss';
import { useLocation } from 'react-router-dom';
import { ROUTE_MULTIBET } from '../../../models/Route';

const LiveEmojiButton = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const { hostId } = useContext(VideoPlayerContext);
    const { disable } = useContext(GlobalDataContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const handleOpen = () => {
        setOpen(true);
        playButtonAudio();
    };
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        if (disable.length > 0) {
            const idx = disable.findIndex(
                (d: { hostId: number }) => d.hostId === hostId
            );
            setDisabled(idx > -1);
        } else {
            setDisabled(false);
        }
    }, [disable, hostId]);
    return (
        <>
            <Button
                disabled={disabled}
                variant="contained"
                disableElevation
                onClick={handleOpen}
                sx={{
                    minWidth: '40px',
                    width: '40px',
                    height: '40px',
                    fontWeight: 'bold',
                    fontSize: '15px',
                    padding: 0,
                    ': disabled': {
                        opacity: 0.5,
                        backgroundColor: theme.palette.primary.main,
                    },
                }}
            >
                <BaseImage
                    className="video_liveVerification btn_liveVerification_footer_over"
                    scale={0.7}
                />
            </Button>
            {open && (
                <EmojiPopup
                    handleClose={handleClose}
                    hostId={hostId}
                    playButtonAudio={playButtonAudio}
                />
            )}
        </>
    );
};
export default LiveEmojiButton;

type SendEmojiState = {
    hostID: string;
    userName: string;
    emoji: string;
};
type Props = {
    handleClose: () => void;
    playButtonAudio: () => void;
    hostId: number;
};

const EmojiPopup = (props: Props) => {
    const { handleClose, hostId, playButtonAudio } = props;
    const { t } = useTranslation();
    const location = useLocation();
    // const { hostId } = useContext(VideoPlayerContext);
    const { lvsAPI } = useContext(CdnContext);
    const { setDisableEmojiButtonData } = useContext(GlobalDataContext);
    const { username } = useSelector(getLandingState);
    const { GameType } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const dispatch = useDispatch();

    const isMultiBet = useMemo(
        () => location.pathname === ROUTE_MULTIBET,
        [location.pathname]
    );
    const id = useMemo(
        () => `emoji-button-${isMultiBet ? 'multibet' : GameType}`,
        [GameType, isMultiBet]
    );
    const [emojiList, setEmojiList] = useState(<></>);
    useEffect(() => {
        const cells = new Array<JSX.Element>();
        for (let k = 0; k < 5; k++) {
            const cell = (
                <Grid
                    item
                    key={`emoji-${k}`}
                    onClick={() => handleClickEmoji(k)}
                >
                    <BaseImage
                        className={`video_liveVerification emoji_icon${k + 1}`}
                    />
                </Grid>
            );
            cells.push(cell);
        }
        setEmojiList(<>{cells}</>);
    }, []);
    const doFailed = () => {
        dispatch(popupSliceActions.open('live_ver_failed'));
    };
    const handleClickEmoji = (idx: number) => {
        playButtonAudio();
        if (!hostId || !username || !lvsAPI) return;
        const request = {} as SendEmojiState;
        request.hostID = hostId.toString();
        request.userName = username;
        request.emoji = (idx + 1).toString();
        try {
            if (lvsAPI) {
                fetch(lvsAPI, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(request),
                }).then(response => {
                    response.json().then(data => {
                        if (data['success']) {
                            setDisableEmojiButtonData(
                                hostId,
                                data['countOfMessageInQueue']
                            );
                            dispatch(
                                popupSliceActions.open(
                                    'live_ver_success',
                                    undefined,
                                    POPUP_TYPE.ALERT,
                                    2000
                                )
                            );
                        } else {
                            setDisableEmojiButtonData(hostId, 1);
                            doFailed();
                        }
                        console.log('app::game::send emoji', data);
                    });
                });
            }
        } catch (e) {
            doFailed();
            setDisableEmojiButtonData(hostId, 1);
            console.error(
                'Live Verification System send ' +
                    JSON.stringify(request) +
                    ' Failed'
            );
        }
        handleClose();
    };
    const getDialogClassName = () => {
        switch (GameType) {
            case GameTypeEnum.Roulette:
                return 'live-roulette-emoji-popup';
            default:
                return 'live-emoji-popup';
        }
    };
    return (
        <>
            <Dialog
                container={() =>
                    document.getElementById('in-game-popup-container')
                }
                id={id}
                className={getDialogClassName()}
                sx={{
                    zIndex: theme => theme.zIndex.drawer + 200,
                }}
                open={true}
                onClose={handleClose}
                hideBackdrop={true}
            >
                <Stack alignItems={'center'} justifyContent={'center'}>
                    <IconButton
                        aria-label="close setting"
                        onClick={() => {
                            handleClose();
                            playButtonAudio();
                        }}
                        disableRipple={false}
                    >
                        <CloseIcon
                            sx={{
                                color: theme.palette.text.primary,
                                width: 23,
                                height: 23,
                            }}
                        />
                    </IconButton>
                    <Grid item margin="6px">
                        {t('live_ver')}
                    </Grid>
                    <Grid
                        item
                        container
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={1}
                    >
                        {emojiList}
                    </Grid>
                </Stack>
            </Dialog>
        </>
    );
};
