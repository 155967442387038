import { BetType as BetTypeEnum } from '../../../../../models/games/sedie/BetType';
// import { LimitRed } from '../../../../main/slice/limitRed';
import { GAME_KEY } from '../../../../games/slice';
import { PHOST_KEY } from '../../../../host/slice';

import store from '../../../../../store/store';
import { Bet } from '../../../../../models/host/BetAmount';

const ShareLimitType = [
    BetTypeEnum.SDBFourRed,
    BetTypeEnum.SDBThreeRed,
    BetTypeEnum.SDBTwoRedTwoWhite,
    BetTypeEnum.SDBThreeWhite,
    BetTypeEnum.SDBFourWhite,
    BetTypeEnum.SDBFourRedOrWhite,
];

const calculateBetAmount = (
    bets: Array<Bet>,
    matchBetTypes: number
): number => {
    let amount = 0;
    amount = bets
        .filter(item => item.Type == matchBetTypes)
        .reduce((sum, bet) => sum + bet.Amount, 0);
    return amount;
};

const getOddPendingBet = (): number => {
    const { pendingBets } = store.getState()[GAME_KEY];
    return calculateBetAmount(pendingBets, BetTypeEnum.SDBOdd);
};
const getOddConfirmedBetted = (): number => {
    const { confirmedBets } = store.getState()[GAME_KEY];
    return calculateBetAmount(confirmedBets, BetTypeEnum.SDBOdd);
};
const getOddTotalBet = (): number => {
    return getOddPendingBet() + getOddConfirmedBetted();
};

const getEvenPendingBet = (): number => {
    const { pendingBets } = store.getState()[GAME_KEY];
    return calculateBetAmount(pendingBets, BetTypeEnum.SDBEven);
};
const getEvenConfirmedBetted = (): number => {
    const { confirmedBets } = store.getState()[GAME_KEY];
    return calculateBetAmount(confirmedBets, BetTypeEnum.SDBEven);
};
const getEvenTotalBet = (): number => {
    return getEvenPendingBet() + getEvenConfirmedBetted();
};

const getSmallPendingBet = (): number => {
    const { pendingBets } = store.getState()[GAME_KEY];
    return calculateBetAmount(pendingBets, BetTypeEnum.SDBSmall);
};
const getSmallConfirmedBetted = (): number => {
    const { confirmedBets } = store.getState()[GAME_KEY];
    return calculateBetAmount(confirmedBets, BetTypeEnum.SDBSmall);
};
const getSmallTotalBet = (): number => {
    return getSmallPendingBet() + getSmallConfirmedBetted();
};

const getBigPendingBet = (): number => {
    const { pendingBets } = store.getState()[GAME_KEY];
    return calculateBetAmount(pendingBets, BetTypeEnum.SDBBig);
};
const getBigConfirmedBetted = (): number => {
    const { confirmedBets } = store.getState()[GAME_KEY];
    return calculateBetAmount(confirmedBets, BetTypeEnum.SDBBig);
};
const getBigTotalBet = (): number => {
    return getBigPendingBet() + getBigConfirmedBetted();
};

const getTotalBetByBetTypes = (betType: number): number => {
    let amount = 0;
    const { pendingBets } = store.getState()[GAME_KEY];
    amount = pendingBets
        .filter(item => item.Type == betType)
        .reduce((sum, bet) => sum + bet.Amount, 0);

    const { confirmedBets } = store.getState()[GAME_KEY];
    amount += confirmedBets
        .filter(item => item.Type == betType)
        .reduce((sum, bet) => sum + bet.Amount, 0);

    return amount;
};

export const isOppositeBetting = (betType: number): boolean => {
    switch (betType) {
        case BetTypeEnum.SDBOdd:
            return getEvenTotalBet() > 0;
        case BetTypeEnum.SDBEven:
            return getOddTotalBet() > 0;
        case BetTypeEnum.SDBSmall:
            return getBigTotalBet() > 0;
        case BetTypeEnum.SDBBig:
            return getSmallTotalBet() > 0;
        default:
            return false;
    }
};

const getBetLimitByBetType = (betType: number, hostId: number): number => {
    const host = store.getState()[PHOST_KEY].entities[hostId];
    let limit = 0;

    if (host && host.MaxBet) {
        const maxBet = host.MaxBet.find(mb => mb.BetType === betType);
        if (maxBet) {
            return maxBet.MaxBet;
        }
    }

    // TODO: user.getCurBetRule(this.gametype).minBet;
    return limit;
};

export const amountOverBetLimit = (
    betType: number,
    hostId: number,
    chipAmount: number
): number => {
    let amount = 0;
    switch (betType) {
        case BetTypeEnum.SDBSmall:
            amount =
                chipAmount +
                getSmallTotalBet() -
                getBigTotalBet() -
                getBetLimitByBetType(betType, hostId);
            break;
        case BetTypeEnum.SDBBig:
            amount =
                chipAmount +
                getBigTotalBet() -
                getSmallTotalBet() -
                getBetLimitByBetType(betType, hostId);
            break;
        case BetTypeEnum.SDBOdd:
            amount =
                chipAmount +
                getOddTotalBet() -
                getEvenTotalBet() -
                getBetLimitByBetType(betType, hostId);
            break;
        case BetTypeEnum.SDBEven:
            amount =
                chipAmount +
                getEvenTotalBet() -
                getOddTotalBet() -
                getBetLimitByBetType(betType, hostId);
            break;
        default:
            for (let i = 0; i < ShareLimitType.length; i++) {
                if (ShareLimitType[i] == betType) {
                    amount =
                        chipAmount +
                        getTotalBetByBetTypes(ShareLimitType[i]) -
                        getBetLimitByBetType(betType, hostId);
                    break;
                }
            }
    }
    return amount;
};
