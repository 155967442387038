import { Box, Divider, Grid, Stack } from '@mui/material';
import {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { getLocaleByCode } from '../../../models/Locale';
// import { Music as MusicEnum } from '../../../models/Music';
import { Voice as VoiceEnum } from '../../../models/Voice';
import { REPORT_COLORS, REPORT_SIZE } from '../../lobby/constants';
import { LanguageSelector } from '../../locales/components/LanguageSelector';
import { getSettingSoundState, getSettingVolumeState } from '../selector';
import { soundSliceActions } from '../slice/sound';
import { volumeSliceActions } from '../slice/volume';
import { TickButton } from './TickButton';
// import { MusicSelector } from './userSetting/MusicSelector';
import { VoiceSelector } from './userSetting/VoiceSelector';
import { VolumeSlider } from './userSetting/VolumeSlider';
import { AudioPlayContext } from '../../../contexts/AudioPlayContext';
import { updateLocalStorageLanguage } from '../../../utils/commonFunc';

type UserSettingProps = {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    closeSetting: number;
};
export const UserSetting = (props: UserSettingProps) => {
    const { setIsOpen, closeSetting } = props;
    const [language, setLanguage] = useState<string>('en-US');
    const [studioVolume, setStudioVolume] = useState(50);
    const [gameVolume, setGameVolume] = useState(0);
    // const [musicVolume, setMusicVolume] = useState(50);
    const [voiceSound, setVoiceSound] = useState<VoiceEnum>(VoiceEnum.English1);
    // const [musicSound, setMusicSound] = useState<MusicEnum>(MusicEnum.Music1);
    const { t, i18n } = useTranslation();
    const soundState = useSelector(getSettingSoundState);
    const volumeState = useSelector(getSettingVolumeState);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const dispatch = useDispatch();
    const ref = useRef<string>();
    useEffect(() => {
        // init
        ref.current = i18n.language;
        setLanguage(i18n.language);
        setStudioVolume(volumeState.Studio);
        setGameVolume(volumeState.Game);
        // setMusicVolume(volumeState.Music);

        setVoiceSound(soundState.Voice2);
        // setMusicSound(soundState.Music);
    }, []);
    useEffect(() => {
        i18n.changeLanguage(ref.current);
    }, [closeSetting]);
    const submitHandler = () => {
        updateLocalStorageLanguage(getLocaleByCode('long', language).short);
        ref.current = language;
        i18n.changeLanguage(language);
        batch(() => {
            dispatch(
                volumeSliceActions.updateAll({
                    Studio: studioVolume,
                    Game: gameVolume,
                    // Music: musicVolume,
                })
            );
            dispatch(
                soundSliceActions.updateAll({
                    // Music: musicSound,
                    Voice2: voiceSound,
                })
            );
        }, []);
        setIsOpen(false);
    };
    return (
        <Box overflow={'auto'}>
            <Grid
                container
                direction="column"
                sx={{
                    color: REPORT_COLORS.TITLE_TEXT,
                    fontSize: REPORT_SIZE.TEXT,
                }}
            >
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    margin={'7vw 10vw 7vw 10vw'}
                >
                    <Grid item>{t('setting.language')}</Grid>
                    <Grid item>
                        <LanguageSelector
                            playButtonAudio={playButtonAudio}
                            language={language}
                            handleChangeLanguage={lang => {
                                setLanguage(lang);
                                i18n.changeLanguage(lang);
                                setVoiceSound(
                                    getLocaleByCode('long', lang).voice
                                );
                            }}
                        />
                    </Grid>
                </Stack>
                <Divider />
                <Stack margin={'7vw 10vw 7vw 10vw'}>
                    <Grid marginBottom={'15px'} item>
                        {t('setting.studio_sound')}
                    </Grid>
                    <Grid item>
                        <VolumeSlider
                            playButtonAudio={playButtonAudio}
                            volume={studioVolume}
                            handleVolumeChange={v => {
                                setStudioVolume(v);
                                setGameVolume(0);
                            }}
                        />
                    </Grid>
                </Stack>
                <Divider />
                <Stack margin={'7vw 10vw 7vw 10vw'}>
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        marginBottom={'15px'}
                    >
                        <Grid item>{t('setting.game_volume')}</Grid>
                        <Grid item>
                            <VoiceSelector
                                voiceSound={voiceSound}
                                setVoiceSound={setVoiceSound}
                                playButtonAudio={playButtonAudio}
                            />
                        </Grid>
                    </Stack>
                    <VolumeSlider
                        playButtonAudio={playButtonAudio}
                        volume={gameVolume}
                        handleVolumeChange={v => {
                            setGameVolume(v);
                            setStudioVolume(0);
                        }}
                    />
                </Stack>
                <Divider />
                {/* <Stack margin={'7vw 10vw 7vw 10vw'}>
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        marginBottom={'15px'}
                    >
                        <Grid item>{t('setting.music')}</Grid>
                        <Grid item>
                            <MusicSelector
                                musicSound={musicSound}
                                setMusicSound={setMusicSound}
                            />
                        </Grid>
                    </Stack>

                    <VolumeSlider
                        volume={musicVolume}
                        handleVolumeChange={v => {
                            setMusicVolume(v);
                        }}
                    />
                </Stack>
                <Divider /> */}
                <Stack>
                    <TickButton
                        onClick={() => {
                            submitHandler();
                            playButtonAudio();
                        }}
                    />
                </Stack>
            </Grid>
        </Box>
    );
};
