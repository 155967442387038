import { combineReducers } from 'redux';
import {
    BET_PLAYERS_KEY,
    betPlayersSliceReducer,
} from '../modules/bet/players/slice';
import { BET_ROOM_KEY, betRoomSliceReducer } from '../modules/bet/room/slice';
import { PCOUNT_KEY, countsSliceReducer } from '../modules/count/slice';
import { GAME_KEY, gameSliceReducer } from '../modules/games/slice';
import { PHOST_KEY, hostsSliceReducer } from '../modules/host/slice';
import { LOBBY_KEY, lobbySliceReducer } from '../modules/lobby/slice';
import mainReducer, { MAIN_KEY } from '../modules/main/slice';
import {
    GAME_POPUP_KEY,
    inGamePopupSliceReducer,
} from '../modules/popup/inGameSlice';
import { POPUP_KEY, popupSliceReducer } from '../modules/popup/slice';
import progressReducer, { PROGRESS_KEY } from '../modules/progress/slice';
import reportReducer, { REPORT_KEY } from '../modules/report/slice';
import { ROADMAP_KEY, roadmapSliceReducer } from '../modules/roadmap/slice';
import { ROUTE_KEY, routeSliceReducer } from '../modules/routeController/slice';
import settingReducer, { SETTING_KEY } from '../modules/setting/slice';
import {
    TOTAL_BET_KEY,
    totalBetsSliceReducer,
} from '../modules/totalBet/slice';
const rootReducer = combineReducers({
    [MAIN_KEY]: mainReducer,
    [SETTING_KEY]: settingReducer,
    [REPORT_KEY]: reportReducer,
    [PROGRESS_KEY]: progressReducer,
    [POPUP_KEY]: popupSliceReducer,
    [GAME_POPUP_KEY]: inGamePopupSliceReducer,
    [GAME_KEY]: gameSliceReducer,
    [BET_ROOM_KEY]: betRoomSliceReducer,
    [BET_PLAYERS_KEY]: betPlayersSliceReducer,
    [PHOST_KEY]: hostsSliceReducer,
    [PCOUNT_KEY]: countsSliceReducer,
    [LOBBY_KEY]: lobbySliceReducer,
    [ROUTE_KEY]: routeSliceReducer,
    [ROADMAP_KEY]: roadmapSliceReducer,
    [TOTAL_BET_KEY]: totalBetsSliceReducer,
});
export default rootReducer;
