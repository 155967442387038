import { Stack } from '@mui/material';
import BaseImage from '../../../../components/common/baseImage';
import { useContext, useEffect, useState } from 'react';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import { useSelector } from 'react-redux';
import { getHostById } from '../../../host/slice';
import { RootState } from '../../../../store/store';
import { RouletteRecord } from '../../../../models/games/roulette';

export const RouletteHistoryInfoTable = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { Record } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [displayRecords, setDisplayRecords] = useState<Array<number>>([]);

    useEffect(() => {
        if (Record) {
            const records = Record.slice(Math.max(Record.length - 5, 0)).map(
                r => (r as RouletteRecord).resultPoint
            );
            setDisplayRecords(records);
        }
    }, [Record]);

    return (
        <Stack alignItems={'center'}>
            <BaseImage
                className="roulette bg_result_png"
                transform="rotate(90deg)"
                scale={0.6}
            />
            <Stack
                position={'absolute'}
                spacing={1}
                sx={{ bottom: -75 }}
                alignItems={'center'}
            >
                {displayRecords.map((r, i) => {
                    return (
                        <StatisticBall
                            val={r}
                            isScale={displayRecords.length == i + 1}
                            key={i}
                        />
                    );
                })}
            </Stack>
        </Stack>
    );
};

type StatisticBallProps = {
    val: number;
    isScale: boolean;
};

const StatisticBall = (props: StatisticBallProps) => {
    const { val, isScale } = props;
    const RedPoint = [
        1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36,
    ];
    const [bgColor, setBgColor] = useState<string>('#686868');

    useEffect(() => {
        setBgColor(RedPoint.indexOf(val) > -1 ? '#c70000' : '#686868');
    }, [val]);

    return (
        <>
            {isScale ? (
                <svg width={40} height={40}>
                    <circle cx={20} cy={20} r={20} fill={bgColor} />
                    <text
                        x={20}
                        y={20}
                        textAnchor="middle"
                        dominantBaseline={'middle'}
                        fill="#FFFFFF"
                        fontSize={'25px'}
                    >
                        {val}
                    </text>
                </svg>
            ) : (
                <svg width={30} height={30}>
                    <circle cx={15} cy={15} r={15} fill={bgColor} />
                    <text
                        x={15}
                        y={15}
                        textAnchor="middle"
                        dominantBaseline={'middle'}
                        fill="#FFFFFF"
                        fontSize={'20px'}
                    >
                        {val}
                    </text>
                </svg>
            )}
        </>
    );
};
