import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import { RootState } from '../../../../store/store';
import { getHostById } from '../../../host/slice';
import './index.scss';
import './DragonTigerECard.scss';
import { Backdrop, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { ROAD_MAP_COLORS } from '../../../lobby/constants';
import { Poker } from '../../../../components/common/poker';
import { convertResultStringToRecord } from '../../../../utils/games/dragonTiger';
import { DragonTigerResult } from '../../../../models/games/dragonTiger';
type DragonTigerECardDisplay = {
    dragon: number;
    tiger: number;
};
type DragonTigerECardProps = {
    show: boolean;
};
export const DragonTigerECard = (props: DragonTigerECardProps) => {
    const { show } = props;
    const { hostId } = useContext(VideoPlayerContext);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { CurrentResult, CurrentState } = host;
    const [display, setDisplay] = useState<DragonTigerECardDisplay>();
    const [dragonPoint, setDragonPoint] = useState(0);
    const [tigerPoint, setTigerPoint] = useState(0);
    useEffect(() => {
        const dtResult = CurrentResult as DragonTigerResult;
        if (dtResult) {
            if (CurrentResult?.ResultString) {
                const record = convertResultStringToRecord(
                    CurrentResult,
                    CurrentResult.ResultString
                );
                const d: DragonTigerECardDisplay = {
                    dragon: record.dragonPoker,
                    tiger: record.tigerPoker,
                };
                if (record.dragonPoker > -1) {
                    setDragonPoint(record.dragonPoint);
                }
                if (record.tigerPoker > -1) {
                    setTigerPoint(record.tigerPoint);
                }
                setDisplay(d);
            } else if (dtResult.DragonCard || dtResult.TigerCard) {
                const d: DragonTigerECardDisplay = {
                    dragon: dtResult.DragonCard,
                    tiger: dtResult.TigerCard,
                };
                setDisplay(d);
            } else {
                setDragonPoint(0);
                setTigerPoint(0);
                setDisplay(undefined);
            }
        }
        return () => {
            setDragonPoint(0);
            setTigerPoint(0);
            setDisplay(undefined);
        };
    }, [CurrentResult, CurrentState]);
    return (
        <>
            <Backdrop
                className={'bacc-poker-show-backdrop'}
                sx={{
                    zIndex: theme => theme.zIndex.drawer + 1,
                }}
                open={show}
            >
                <Stack
                    className={'poker-show'}
                    alignItems={'center'}
                    justifyContent={'space-evenly'}
                >
                    {display && (
                        <Stack direction={'row'} className="poker-box">
                            <Poker scale={1.09} n={display.dragon} />
                            <Poker scale={1.09} n={display.tiger} />
                        </Stack>
                    )}
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        className="point-box"
                    >
                        <Typography
                            sx={{
                                bgcolor: ROAD_MAP_COLORS.BLUE,
                            }}
                        >
                            {t('tableinfo.dragon')} {dragonPoint}
                        </Typography>
                        <Typography
                            sx={{
                                bgcolor: ROAD_MAP_COLORS.RED,
                            }}
                        >
                            {t('tableinfo.tiger')} {tigerPoint}
                        </Typography>
                    </Stack>
                </Stack>
            </Backdrop>
        </>
    );
};
