import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CdnContext } from '../contexts/CdnContext';
import { GameConfigContext } from '../contexts/ConfigContext';
import { WebSocketContext } from '../contexts/WebSocketContext';
import { getLandingState, getMainUserState } from '../modules/main/selector';

export const useLogin = () => {
    const { login } = useContext(WebSocketContext);
    const { lobbyServer, lobbyPort } = useContext(CdnContext);
    const { hosts } = useContext(GameConfigContext);
    const { token, isReconnect } = useSelector(getLandingState);
    const { LoginToken } = useSelector(getMainUserState);
    useEffect(() => {
        console.log('hook::login::flow', lobbyServer, lobbyPort, hosts);
        if (lobbyServer && lobbyPort && hosts?.hostList) {
            if (LoginToken && LoginToken != '') {
                login(LoginToken, true);
            } else if (token) {
                login(token, isReconnect);
            }
        }
    }, [lobbyServer, lobbyPort, hosts]);
};
