import { CMDBaccaratStatistics } from "./CMDBaccaratStatistics"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpBaccaratStatistics implements ICommand
{

	public HostID : number = 0;
	public Statistics : CMDBaccaratStatistics;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.HostID = ba.readUint16();
		this.Statistics = new CMDBaccaratStatistics();
		this.Statistics.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.HostID);
		let tempBuf1 = this.Statistics.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpBaccaratStatistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'Statistics(CMDBaccaratStatistics)='+this.Statistics+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 50095;
	}

	public getCmdName() : string
	{
		return 'SpBaccaratStatistics';
	}

}
