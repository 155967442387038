import { PokerShow } from '../../../models/games/PokerShow';
import {
    AndarBaharGameState,
    AndarBaharRecord,
    AndarBaharResult,
    AndarBaharStatistics,
} from '../../../models/games/andarBahar';
import {
    BaccaratGameState,
    BaccaratGoodRoad,
    BaccaratRecord,
    BaccaratResult,
    BaccaratStatistics,
} from '../../../models/games/baccarat';
import {
    BlackjackRecord,
    BlackjackResult,
} from '../../../models/games/blackjack';
import { GameDelay } from '../../../models/games/blackjack/GameDelay';
import {
    DragonTigerGameState,
    DragonTigerRecord,
    DragonTigerResult,
    DragonTigerStatistics,
} from '../../../models/games/dragonTiger';
import { GameState as CommonGameState } from '../../../models/games/enums/GameState';
import { GameType as GameTypeEnum } from '../../../models/games/enums/GameType';
import {
    PokDengRecord,
    PokDengResult,
    PokDengStatistics,
} from '../../../models/games/pokdeng';
import {
    RouletteGameState,
    RouletteRecord,
    RouletteResult,
    RouletteStatistics,
} from '../../../models/games/roulette';
import {
    SedieRecord,
    SedieResult,
    SedieStatistics,
} from '../../../models/games/sedie';
import {
    SicBoGameState,
    SicBoRecord,
    SicBoResult,
    SicBoStatistics,
} from '../../../models/games/sicbo';
import {
    TeenPattiGameState,
    TeenPattiRecord,
    TeenPattiResult,
    TeenPattiStatistics,
} from '../../../models/games/teenpatti';
import { Bet } from '../../../models/host/BetAmount';
import { BetTypeMaxBet } from '../../../models/host/MaxBet';
export type HallColorProps = {
    hallColor: 'aHall' | 'cHall' | 'eHall' | 'mHall' | 'tHall' | 'other'; //SA: HostSetting -> hallStateName -> game_config.xml
};
export type SupportedRecord =
    | BaccaratRecord
    | BlackjackRecord
    | DragonTigerRecord
    | PokDengRecord
    | SicBoRecord
    | SedieRecord
    | RouletteRecord
    | TeenPattiRecord
    | AndarBaharRecord;
export type SupportedGameResult =
    | BaccaratResult
    | DragonTigerResult
    | PokDengResult
    | SicBoResult
    | BlackjackResult
    | SedieResult
    | AndarBaharResult
    | RouletteResult
    | TeenPattiResult;
export type SupportedGoodRoad = BaccaratGoodRoad;
export type SupportedGameStatistics =
    | BaccaratStatistics
    | DragonTigerStatistics
    | PokDengStatistics
    | SicBoStatistics
    | SedieStatistics
    | TeenPattiStatistics
    | AndarBaharStatistics
    | RouletteStatistics;
export type SupportedGameState =
    | BaccaratGameState
    | AndarBaharGameState
    | RouletteGameState
    | DragonTigerGameState
    | SicBoGameState
    | TeenPattiGameState
    | CommonGameState;
export type SupportedGame = {
    CurrentResult?: SupportedGameResult;
    ResultReleased: boolean;
    CurrentState: SupportedGameState;
    OldResult: Array<SupportedGameResult>;
    GoodRoad?: SupportedGoodRoad;
    PokerShowResult?: Array<PokerShow>;
    IsRest: boolean;
    ConfirmedBets?: Array<Bet>;
    GameDelay?: GameDelay;
};
export type Dealer = {
    DealerId: number;
    DealerName: string;
};
export type BetPlayerAmount = {
    Amount: number;
    BetPlayerCount: number;
};
export type HostInfo = {
    AllowSideBet: boolean;
    CountDown: number;
    HostId: number;
    RemainTime: number;
    Rest: number;
    Seat: number;
    Setting: number;
    MaxBet?: Array<BetTypeMaxBet>;
    PendingTime?: number;
    Multiplier?: number;
};
export type HostConfig = {
    OrderBy: number;
    HallId: number;
    GameType: GameTypeEnum;
    Group: string;
    Video: string;
    Audio: boolean;
    Hls: string;
    Nano: string;
    HostName: string;
    DelayDraw: number;
    DelayResult: number;
    DelayZoom: number;
    enabled: boolean;
    TableLimit: number; //?唔確定係唔係跟game_config
    Time: number;
    Sai?: number;
    ShuffleDelay?: number;
    HallStateName?: HallColorProps['hallColor'];
    IsGoodRoad: boolean;
    IsTest?: boolean;
};

export type HostGame = {
    DealerList?: Array<Dealer>;
    Statistics?: SupportedGameStatistics;
    Record?: Array<SupportedRecord>;
    GameCount?: number;
    WinAmount: number;
    GameStateTimer?: Array<[NodeJS.Timeout, number]>;
    ShuffleTimer?: NodeJS.Timeout;
    IsRestart?: boolean;
    IsRoundCancel?: boolean;
    CanLeave?: boolean;
};

export type HostTable = HostInfo & HostConfig & HostGame & SupportedGame;
export const initialPHostTable = {} as HostTable;
