import { useContext, useEffect, useState } from 'react';
import { ResourceContext } from '../contexts/ResourceContext';
import {
    getSettingSoundState,
    getSettingVolumeState,
} from '../modules/setting/selector';
import { useSelector } from 'react-redux';
import { SoundList } from '../models/Voice';

const useAudioPlay = () => {
    const { audioLists } = useContext(ResourceContext);
    const { Game } = useSelector(getSettingVolumeState);
    const { Voice2 } = useSelector(getSettingSoundState);
    const volume = Game / 100;
    const [muted, setMuted] = useState<boolean>(false);
    const [playing, setPlaying] = useState<string>('');
    const [urlMap, setUrlMap] = useState<Map<string, string>>(new Map());
    const [indexMap, setIndexMap] = useState<Map<string, number>>(new Map());
    const loadWebAudio = async (name: string, url: string): Promise<number> => {
        return new Promise<number>(resolve => {
            if (!urlMap.has(name)) {
                setUrlMap(urlMap.set(name, url));
                webaudio.load(
                    url,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (_url: string, _soundBuffer: any, idx: number) => {
                        setIndexMap(indexMap.set(name, idx));
                        resolve(idx);
                    }
                );
            }
        });
    };
    const play = async (name: string, loop?: number): Promise<void> => {
        if (loop == undefined) loop = 1;
        // eslint-disable-next-line @typescript-eslint/ban-types
        const executor = (resolve: Function, reject: Function) => {
            if (loop == undefined) loop = 1;
            const isLoop = loop <= 0;
            const url = urlMap.get(name);
            console.log('app:sound:play', name);
            if (url == undefined) {
                const url = audioLists.get(name);
                if (url) {
                    loadWebAudio(name, url).then((idx: number) => {
                        webaudio.playIndex(idx, isLoop, resolve);
                        webaudio.setVolumnIndex(idx, volume);
                        setPlaying(name);
                    });
                } else {
                    reject();
                }
            } else {
                const index = indexMap.get(name);
                if (index == undefined) {
                    webaudio.stopUrl(url);
                    webaudio.playUrl(url, isLoop, resolve);
                    webaudio.setVolumnUrl(url, volume);
                } else {
                    webaudio.stopIndex(index);
                    webaudio.playIndex(index, isLoop, resolve);
                    webaudio.setVolumnIndex(index, volume);
                }
                setPlaying(name);
            }
        };
        return new Promise<void>(executor);
    };
    const audioPlay = async (key: string | string[]) => {
        if (volume > 0 && !muted) {
            if (typeof key === 'string') {
                play(key);
            } else {
                for await (const k of key) {
                    await play(k);
                }
            }
        }
    };
    const audioStop = () => {
        if (indexMap.has(playing)) {
            webaudio.stopIndex(indexMap.get(playing));
        } else {
            webaudio.stopUrl(urlMap.get(playing));
        }
    };
    const setAudioVolume = (val: number) => {
        if (indexMap.has(playing)) {
            webaudio.setVolumnIndex(indexMap.get(playing) as number, val);
        }
    };
    const mute = (): void => {
        setMuted(true);
        if (indexMap.has(playing)) {
            webaudio.setVolumnIndex(indexMap.get(playing) as number, 0);
        }
    };

    const unmute = (): void => {
        setMuted(false);
        if (indexMap.has(playing)) {
            webaudio.setVolumnIndex(indexMap.get(playing) as number, volume);
        }
    };
    const playButtonAudio = (): void => {
        audioPlay(SoundList.btnSound);
    };
    const playChipBetAudio = (): void => {
        audioPlay(SoundList.ChipsBet);
    };
    const handleVisibilitychange = () => {
        if (document.visibilityState === 'visible') {
            webaudio.setMasterVolumn(volume);
            unmute();
        } else {
            webaudio.setMasterVolumn(0);
            mute();
        }
    };
    useEffect(() => {
        window.addEventListener('visibilitychange', handleVisibilitychange);
        return () => {
            window.removeEventListener(
                'visibilitychange',
                handleVisibilitychange
            );
        };
    }, [playing]);
    useEffect(() => {
        setAudioVolume(volume);
        webaudio.setMasterVolumn(volume);
    }, [volume]);
    useEffect(() => {
        //set init
        webaudio.setMasterVolumn(0);
        setUrlMap(new Map());
        setIndexMap(new Map());
    }, [Voice2]);
    return {
        audioPlay,
        audioStop,
        setAudioVolume,
        mute,
        unmute,
        playButtonAudio,
        playChipBetAudio,
    };
};

export default useAudioPlay;
export type useAudioPlayState = ReturnType<typeof useAudioPlay>;
