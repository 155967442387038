import { Size } from '../../types/interface';

export const DESKTOP_SCENE_SIZE: Size = {
    width: 1300, // 1920
    height: 670, // 1080
};

export const MOBILE_SCENE_SIZE: Size = {
    width: 540,
    height: 960,
};
