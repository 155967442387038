import { Box, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BetArea from '../../../../../../components/common/betArea';
import { VideoPlayerContext } from '../../../../../../contexts/VideoContext';
import { AndarBaharTotalBet } from '../../../../../../models/games/andarBahar';
import { BetType } from '../../../../../../models/games/andarBahar/BetType';
import { RootState } from '../../../../../../store/store';
import { getTotalBets } from '../../../../../totalBet/slice';
import { amountOverBetLimit, isOppositeBetting } from '../utils';
import betAreaJson from './betArea.json';
const { betType } = betAreaJson;
export const AndarBaharBetArea = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { TotalBet } = useSelector((state: RootState) =>
        getTotalBets(state, hostId)
    );

    const [andarBetRate, setAndarBetRate] = useState<number>(0);
    const [baharBetRate, setBaharBetRate] = useState<number>(0);

    useEffect(() => {
        if (TotalBet) {
            const BetAmount = TotalBet as AndarBaharTotalBet;
            const andarAmount = BetAmount.Andar ? BetAmount.Andar.Amount : 0;
            const baharAmount = BetAmount.Bahar ? BetAmount.Bahar.Amount : 0;
            const totalAmount = andarAmount + baharAmount;

            setAndarBetRate(Math.round((andarAmount / totalAmount) * 100) || 0);
            setBaharBetRate(Math.round((baharAmount / totalAmount) * 100) || 0);
        }
    }, [TotalBet]);
    return (
        <>
            <Grid
                item
                container
                flexWrap={'nowrap'}
                justifyContent={'space-between'}
                sx={{ padding: '4px 5px 0px 5px' }}
            >
                <BetArea
                    style={betType[BetType.ABBFirstAndar].style}
                    confirmPanelStyle={
                        betType[BetType.ABBFirstAndar].confirmPanelStyle
                    }
                    confirmedChipContainerStyle={
                        betType[BetType.ABBFirstAndar]
                            .confirmedChipContainerStyle
                    }
                    imageName={betType[BetType.ABBFirstAndar].image}
                    betType={BetType.ABBFirstAndar}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                />
                <BetArea
                    style={betType[BetType.ABBFirst3].style}
                    confirmPanelStyle={
                        betType[BetType.ABBFirst3].confirmPanelStyle
                    }
                    confirmedChipContainerStyle={
                        betType[BetType.ABBFirst3].confirmedChipContainerStyle
                    }
                    imageName={betType[BetType.ABBFirst3].image}
                    betType={BetType.ABBFirst3}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                />
                <BetArea
                    style={betType[BetType.ABBFirstBahar].style}
                    confirmPanelStyle={
                        betType[BetType.ABBFirstBahar].confirmPanelStyle
                    }
                    confirmedChipContainerStyle={
                        betType[BetType.ABBFirstBahar]
                            .confirmedChipContainerStyle
                    }
                    imageName={betType[BetType.ABBFirstBahar].image}
                    betType={BetType.ABBFirstBahar}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                />
            </Grid>
            <Grid
                item
                container
                flexWrap={'nowrap'}
                justifyContent={'space-between'}
                sx={{ paddingLeft: '5px', paddingRight: '5px' }}
            >
                <Box sx={{ position: 'relative' }}>
                    <BetArea
                        style={betType[BetType.ABBAndarWin].style}
                        confirmPanelStyle={
                            betType[BetType.ABBAndarWin].confirmPanelStyle
                        }
                        imageName={betType[BetType.ABBAndarWin].image}
                        betType={BetType.ABBAndarWin}
                        isShowBetAmount={false}
                        perColor={'92,126,255'}
                        amountOverBetLimit={amountOverBetLimit}
                        isOppositeBetting={isOppositeBetting}
                        isFlash={true}
                        // defaultLang={betType[BetType.ABBAndarWin].defaultLang}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            right: '1px',
                            top: '1px',
                            width: '25%',
                            height: 'fit-content',
                            borderRadius: '0 0 0 10px',
                            backgroundColor: 'rgba(0, 0, 0, 0.35)',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '13px',
                                textAlign: 'right',
                                paddingTop: '4px',
                                paddingRight: '12px',
                                paddingBottom: '4px',
                            }}
                        >{`${andarBetRate}%`}</Typography>
                    </Box>
                </Box>
                <Box sx={{ position: 'relative' }}>
                    <BetArea
                        style={betType[BetType.ABBBaharWin].style}
                        confirmPanelStyle={
                            betType[BetType.ABBBaharWin].confirmPanelStyle
                        }
                        imageName={betType[BetType.ABBBaharWin].image}
                        betType={BetType.ABBBaharWin}
                        isShowBetAmount={false}
                        perColor={'94,193,58'}
                        amountOverBetLimit={amountOverBetLimit}
                        isOppositeBetting={isOppositeBetting}
                        isFlash={true}
                        // defaultLang={betType[BetType.ABBBaharWin].defaultLang}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            right: '1px',
                            top: '1px',
                            width: '25%',
                            height: 'fit-content',
                            borderRadius: '0 0 0 10px',
                            backgroundColor: 'rgba(0, 0, 0, 0.35)',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '13px',
                                textAlign: 'right',
                                paddingTop: '4px',
                                paddingRight: '12px',
                                paddingBottom: '4px',
                            }}
                        >{`${baharBetRate}%`}</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid
                item
                container
                flexWrap={'nowrap'}
                justifyContent={'space-between'}
                sx={{
                    padding: '4px 5px 0px 5px',
                }}
            >
                <BetArea
                    style={betType[BetType.ABB1To5].style}
                    confirmPanelStyle={
                        betType[BetType.ABB1To5].confirmPanelStyle
                    }
                    confirmedChipContainerStyle={
                        betType[BetType.ABB1To5].confirmedChipContainerStyle
                    }
                    imageName={betType[BetType.ABB1To5].image}
                    betType={BetType.ABB1To5}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                    isMultiLang={false}
                />
                <BetArea
                    style={betType[BetType.ABB6To10].style}
                    confirmPanelStyle={
                        betType[BetType.ABB6To10].confirmPanelStyle
                    }
                    confirmedChipContainerStyle={
                        betType[BetType.ABB6To10].confirmedChipContainerStyle
                    }
                    imageName={betType[BetType.ABB6To10].image}
                    betType={BetType.ABB6To10}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                    isMultiLang={false}
                />
                <BetArea
                    style={betType[BetType.ABB11To15].style}
                    confirmPanelStyle={
                        betType[BetType.ABB11To15].confirmPanelStyle
                    }
                    confirmedChipContainerStyle={
                        betType[BetType.ABB11To15].confirmedChipContainerStyle
                    }
                    imageName={betType[BetType.ABB11To15].image}
                    betType={BetType.ABB11To15}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                    isMultiLang={false}
                />
                <BetArea
                    style={betType[BetType.ABB16To20].style}
                    confirmPanelStyle={
                        betType[BetType.ABB16To20].confirmPanelStyle
                    }
                    confirmedChipContainerStyle={
                        betType[BetType.ABB16To20].confirmedChipContainerStyle
                    }
                    imageName={betType[BetType.ABB16To20].image}
                    betType={BetType.ABB16To20}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                    isMultiLang={false}
                />
                <BetArea
                    style={betType[BetType.ABB21To25].style}
                    confirmPanelStyle={
                        betType[BetType.ABB21To25].confirmPanelStyle
                    }
                    confirmedChipContainerStyle={
                        betType[BetType.ABB21To25].confirmedChipContainerStyle
                    }
                    imageName={betType[BetType.ABB21To25].image}
                    betType={BetType.ABB21To25}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                    isMultiLang={false}
                />
            </Grid>
            <Grid
                item
                container
                flexWrap={'nowrap'}
                justifyContent={'space-between'}
                sx={{
                    padding: '4px 5px 0px 5px',
                }}
            >
                <BetArea
                    style={betType[BetType.ABB26To30].style}
                    confirmPanelStyle={
                        betType[BetType.ABB26To30].confirmPanelStyle
                    }
                    confirmedChipContainerStyle={
                        betType[BetType.ABB26To30].confirmedChipContainerStyle
                    }
                    imageName={betType[BetType.ABB26To30].image}
                    betType={BetType.ABB26To30}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                    isMultiLang={false}
                />
                <BetArea
                    style={betType[BetType.ABB31To35].style}
                    confirmPanelStyle={
                        betType[BetType.ABB31To35].confirmPanelStyle
                    }
                    confirmedChipContainerStyle={
                        betType[BetType.ABB31To35].confirmedChipContainerStyle
                    }
                    imageName={betType[BetType.ABB31To35].image}
                    betType={BetType.ABB31To35}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                    isMultiLang={false}
                />
                <BetArea
                    style={betType[BetType.ABB36To40].style}
                    confirmPanelStyle={
                        betType[BetType.ABB36To40].confirmPanelStyle
                    }
                    confirmedChipContainerStyle={
                        betType[BetType.ABB36To40].confirmedChipContainerStyle
                    }
                    imageName={betType[BetType.ABB36To40].image}
                    betType={BetType.ABB36To40}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                    isMultiLang={false}
                />
                <BetArea
                    style={betType[BetType.ABB41To45].style}
                    confirmPanelStyle={
                        betType[BetType.ABB41To45].confirmPanelStyle
                    }
                    confirmedChipContainerStyle={
                        betType[BetType.ABB41To45].confirmedChipContainerStyle
                    }
                    imageName={betType[BetType.ABB41To45].image}
                    betType={BetType.ABB41To45}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                    isMultiLang={false}
                />
                <BetArea
                    style={betType[BetType.ABB46To49].style}
                    confirmPanelStyle={
                        betType[BetType.ABB46To49].confirmPanelStyle
                    }
                    confirmedChipContainerStyle={
                        betType[BetType.ABB46To49].confirmedChipContainerStyle
                    }
                    imageName={betType[BetType.ABB46To49].image}
                    betType={BetType.ABB46To49}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                    isMultiLang={false}
                />
            </Grid>
        </>
    );
};
