import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LobbyType } from '../../../models/LobbyType';
import {
    getLoginToken,
    updateLocalStorageReconnectKey,
} from '../../../utils/commonFunc';
import moment from 'moment';

export const USER_KEY = 'app::main::user';
export type UserState = {
    AllowLoginSource: number;
    CurrencyList: Array<Currency>;
    CurrencyName: string;
    CurrencyType: number;
    DuplicateLogin: boolean;
    ErrorMsgPack: string;
    FPlayerPrefs: string;
    FPlayerPrefs2: string;
    FPlayerPrefs3: string;
    FPlayerPrefs4: string;
    IsCommonWallet: boolean;
    LastLogin: string;
    LobbyCode: string;
    LobbyType: LobbyType;
    MinimumToken: number;
    NoChanceJackpot: number;
    PlayerID: number;
    PlayerPrefs: number;
    PlayerPrefs2: number;
    PlayerPrefs3: number;
    PlayerPrefs4: number;
    LoginToken: string;
    UNIXTimestamp: number;
    LoginTime: number;
    Username: string;
    VideoToken: string;
    ExchangeRate: number;
    IsTrial: boolean;
    NeedPrefsUpdate: boolean;
    IsDemo: boolean;
};
export type Currency = {
    CurrencyType: number;
    ExchangeRate: number;
};
export const initialUserState = {} as UserState;
const updateData = (state: UserState, action: PayloadAction<UserState>) => {
    state.AllowLoginSource = action.payload.AllowLoginSource;
    state.CurrencyList = action.payload.CurrencyList;
    state.CurrencyName = action.payload.CurrencyName;
    state.CurrencyType = action.payload.CurrencyType;
    state.ExchangeRate = action.payload.ExchangeRate;
    state.DuplicateLogin = action.payload.DuplicateLogin;
    state.ErrorMsgPack = action.payload.ErrorMsgPack;
    state.FPlayerPrefs = action.payload.FPlayerPrefs;
    state.FPlayerPrefs2 = action.payload.FPlayerPrefs2;
    state.FPlayerPrefs3 = action.payload.FPlayerPrefs3;
    state.FPlayerPrefs4 = action.payload.FPlayerPrefs4;
    state.IsCommonWallet = action.payload.IsCommonWallet;
    state.LastLogin = action.payload.LastLogin;
    state.LobbyCode = action.payload.LobbyCode;
    state.MinimumToken = action.payload.MinimumToken;
    state.NoChanceJackpot = action.payload.NoChanceJackpot;
    state.PlayerID = action.payload.PlayerID;
    state.PlayerPrefs = action.payload.PlayerPrefs;
    state.PlayerPrefs2 = action.payload.PlayerPrefs2;
    state.PlayerPrefs3 = action.payload.PlayerPrefs3;
    state.PlayerPrefs4 = action.payload.PlayerPrefs4;
    state.LoginToken = action.payload.LoginToken;
    state.UNIXTimestamp = action.payload.UNIXTimestamp;
    state.LoginTime = action.payload.LoginTime;
    state.Username = action.payload.Username;
    state.VideoToken = action.payload.VideoToken;
    state.IsTrial = action.payload.IsTrial;
    state.IsDemo = action.payload.IsDemo;
    state.NeedPrefsUpdate = false;
    updateLocalStorageReconnectKey(action.payload.LoginToken);
};

const updateReconnectKey = (
    state: UserState,
    action: PayloadAction<string>
) => {
    state.LoginToken = getLoginToken(state.LoginToken, action.payload);
    updateLocalStorageReconnectKey(state.LoginToken);
};

const updateUserPrefs = (
    state: UserState,
    action: PayloadAction<UserState>
) => {
    state.NeedPrefsUpdate =
        !state.IsTrial && state.FPlayerPrefs3 !== action.payload.FPlayerPrefs3;
    state.FPlayerPrefs3 = action.payload.FPlayerPrefs3;
};
const finishPrefUpdate = (state: UserState) => {
    state.NeedPrefsUpdate = false;
};
const updateNowTime = (state: UserState, action: PayloadAction<number>) => {
    const diff = moment().valueOf() - state.LoginTime;
    if (state.UNIXTimestamp != undefined) {
        console.log(
            'backdoor::time::change',
            moment(state.UNIXTimestamp + diff).format('YYYY-MM-DD HH:mm:ss'),
            'to',
            moment(action.payload).format('YYYY-MM-DD HH:mm:ss')
        );
        state.UNIXTimestamp = action.payload - diff;
    } else {
        console.log('backdoor::time::change::invalid::not::yet::login');
    }
};
const userSlice = createSlice({
    name: USER_KEY,
    initialState: initialUserState,
    reducers: {
        updateData,
        updateReconnectKey,
        updateUserPrefs,
        finishPrefUpdate,
        updateNowTime,
    },
});
export const userSliceReducer = userSlice.reducer;
export const userSliceActions = userSlice.actions;
