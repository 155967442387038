import { Grid, Paper, Typography, Box, Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Poker } from '../../../../../components/common/poker';
import { SeatAreaContext } from '../../../../../contexts/SeatAreaContext';
import { GameState as CommonState } from '../../../../../models/games/enums/GameState';
import { getBlackjackPoints } from '../../../../../utils/games/blackjack';
import './BankerResultDisplay.scss';
import { usePokerScroll } from '../../../../../hooks/usePokerScroll';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const BankerResultDisplay = () => {
    const [isShow, setIsShow] = useState(false);
    const { currentState, bankerResult } = useContext(SeatAreaContext);
    const [displayPoint, setDisplayPoint] = useState<Array<number>>([]);
    const { t } = useTranslation();
    useEffect(() => {
        if (currentState) setIsShow(currentState >= CommonState.Started);
    }, [currentState]);
    useEffect(() => {
        const pokers = bankerResult?.PK;
        let dp = new Array<number>();
        if (pokers) {
            const calculatedPoints = getBlackjackPoints(pokers);
            dp =
                calculatedPoints.find(p => p >= 17) !== undefined
                    ? calculatedPoints.splice(0, 1)
                    : calculatedPoints;
        }
        setDisplayPoint(dp);
    }, [bankerResult]);

    const {
        containerRef,
        scrollerRef,
        isScrollStart,
        isScrollEnd,
        isOverflow,
        onLeftClick,
        onRightClick,
        setCardChange,
    } = usePokerScroll();

    useEffect(() => {
        if (bankerResult && bankerResult.PK) {
            setCardChange(bankerResult.PK.length);
        }
    }, [bankerResult?.PK.length]);

    return isShow ? (
        <Grid
            component={Paper}
            container
            direction={'column'}
            sx={{
                padding: 1,
                width: 160,
                height: 100,
                backgroundColor: 'rgba(0,0,0,0.6)',
            }}
        >
            <Grid item>
                <Grid
                    container
                    justifyContent={'space-between'}
                    className="blackjack-banker-point"
                >
                    <Typography className="item-title" color={'#C2A377'}>
                        {t('tableinfo.dealer')}
                    </Typography>
                    <Typography className="item-value">
                        {displayPoint.length > 0 ? displayPoint.join('/') : 0}
                    </Typography>
                </Grid>
            </Grid>

            {bankerResult && bankerResult.PK && (
                <Stack
                    height={'54px'}
                    justifyContent={'space-between'}
                    direction={'row'}
                    width={145}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 32,
                            left: 0,
                            zIndex: 1,
                        }}
                    >
                        {isOverflow && !isScrollStart && (
                            <Box
                                onClick={onLeftClick}
                                sx={{
                                    width: '20px',
                                    height: '54px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: `linear-gradient(to left, #1D3C2E00 0%, #1D3C2E) 100%`,
                                }}
                            >
                                <ArrowBackIosNewIcon
                                    fontSize="medium"
                                    sx={{
                                        color: '#fff',
                                        display: 'inline-block',
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                    <Box
                        ref={containerRef}
                        sx={{
                            overflowX: 'scroll',
                            scrollSnapType: 'x mandatory',
                            whiteSpace: 'nowrap',
                            scrollBehavior: 'smooth',
                            scrollbarWidth: 'none',
                            '&::-webkit-scrollbar': {
                                display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                            },
                            '&-ms-overflow-style': {
                                display: 'none', // Hide the scrollbar for IE
                            },
                            position: 'relative',
                        }}
                    >
                        <Box ref={scrollerRef} display={'inline-block'}>
                            <Box
                                className="blackjack-banker-poker"
                                width={bankerResult.PK.length * 27.5 + 20}
                            >
                                {bankerResult?.PK?.map((p, index) => (
                                    <Poker
                                        key={`blackjack-banker-poker-${index}`}
                                        n={p}
                                        scale={0.66}
                                    />
                                ))}
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '20px',
                            height: '54px',
                            position: 'absolute',
                            top: 32,
                            right: 1,
                            zIndex: 1,
                        }}
                    >
                        {isOverflow && !isScrollEnd && (
                            <Box
                                onClick={onRightClick}
                                sx={{
                                    width: '20px',
                                    height: '54px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: `linear-gradient(to right, #1D3C2E00 0%, #1D3C2E) 100%`,
                                }}
                            >
                                <ArrowForwardIosIcon
                                    fontSize="medium"
                                    sx={{
                                        color: '#fff',
                                        display: 'inline-block',
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Stack>
            )}
        </Grid>
    ) : (
        <></>
    );
};
