import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GameType as GameTypeEnum } from '../../../../models/games/enums/GameType';
import { getLobbyStateHallGameTypeMap } from '../../selector';
import TableCell from './TableCell';

export const OtherGamesTables = () => {
    const hallGameMap = useSelector(getLobbyStateHallGameTypeMap);
    const [display, setDisplay] = useState<Array<number>>([]);
    useEffect(() => {
        const otherGameHosts = hallGameMap
            .filter(h => h.gameType !== GameTypeEnum.Baccarat)
            .map(h => h.hostId);
        setDisplay(otherGameHosts);
    }, [hallGameMap]);
    return (
        <>
            <Grid
                container
                alignContent="flex-start"
                justifyContent="space-between"
            >
                {display.map(hostId => (
                    <TableCell
                        key={`other-game-cell-${hostId}`}
                        hostId={hostId}
                    />
                ))}
            </Grid>
        </>
    );
};
