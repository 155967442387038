import { Grid } from '@mui/material';
import BetArea from '../../../../../../components/common/betArea';
import { BetType } from '../../../../../../models/games/sedie/BetType';
import { amountOverBetLimit, isOppositeBetting } from '../utils';
import betAreaJson from './betArea.json';

const { betType } = betAreaJson;
export const SedieBetArea = () => (
    <>
        <Grid
            item
            container
            flexWrap={'nowrap'}
            justifyContent={'space-between'}
            sx={{
                marginBottom: '4px',
            }}
        >
            <BetArea
                style={betType[BetType.SDBFourRedOrWhite].style}
                confirmPanelStyle={
                    betType[BetType.SDBFourRedOrWhite].confirmPanelStyle
                }
                imageName={betType[BetType.SDBFourRedOrWhite].image}
                isMultiLang={false}
                betType={BetType.SDBFourRedOrWhite}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                isFlash={true}
            />
            <BetArea
                style={betType[BetType.SDBFourWhite].style}
                confirmPanelStyle={
                    betType[BetType.SDBFourWhite].confirmPanelStyle
                }
                imageName={betType[BetType.SDBFourWhite].image}
                isMultiLang={false}
                betType={BetType.SDBFourWhite}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                isFlash={true}
            />
            <BetArea
                style={betType[BetType.SDBThreeWhite].style}
                confirmPanelStyle={
                    betType[BetType.SDBThreeWhite].confirmPanelStyle
                }
                imageName={betType[BetType.SDBThreeWhite].image}
                isMultiLang={false}
                betType={BetType.SDBThreeWhite}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                isFlash={true}
            />
            <BetArea
                style={betType[BetType.SDBTwoRedTwoWhite].style}
                confirmPanelStyle={
                    betType[BetType.SDBTwoRedTwoWhite].confirmPanelStyle
                }
                imageName={betType[BetType.SDBTwoRedTwoWhite].image}
                isMultiLang={false}
                betType={BetType.SDBTwoRedTwoWhite}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                isFlash={true}
            />
            <BetArea
                style={betType[BetType.SDBThreeRed].style}
                confirmPanelStyle={
                    betType[BetType.SDBThreeRed].confirmPanelStyle
                }
                imageName={betType[BetType.SDBThreeRed].image}
                isMultiLang={false}
                betType={BetType.SDBThreeRed}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                isFlash={true}
            />
            <BetArea
                style={betType[BetType.SDBFourRed].style}
                confirmPanelStyle={
                    betType[BetType.SDBFourRed].confirmPanelStyle
                }
                imageName={betType[BetType.SDBFourRed].image}
                isMultiLang={false}
                betType={BetType.SDBFourRed}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                isFlash={true}
            />
        </Grid>
        <Grid item container flexWrap={'nowrap'} justifyContent={'center'}>
            <BetArea
                style={betType[BetType.SDBSmall].style}
                confirmPanelStyle={betType[BetType.SDBSmall].confirmPanelStyle}
                imageName={betType[BetType.SDBSmall].image}
                betType={BetType.SDBSmall}
                isShowBetAmount={true}
                betTypeKey="Small"
                perColor={'92,126,255'}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                isFlash={true}
            />
            <BetArea
                style={betType[BetType.SDBBig].style}
                confirmPanelStyle={betType[BetType.SDBBig].confirmPanelStyle}
                imageName={betType[BetType.SDBBig].image}
                betType={BetType.SDBBig}
                isShowBetAmount={true}
                betTypeKey="Big"
                perColor={'231,51,35'}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                isFlash={true}
            />
            <BetArea
                style={betType[BetType.SDBOdd].style}
                confirmPanelStyle={betType[BetType.SDBOdd].confirmPanelStyle}
                imageName={betType[BetType.SDBOdd].image}
                betType={BetType.SDBOdd}
                isShowBetAmount={true}
                betTypeKey="Odd"
                perColor={'92,126,255'}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                isFlash={true}
            />
            <BetArea
                style={betType[BetType.SDBEven].style}
                confirmPanelStyle={betType[BetType.SDBEven].confirmPanelStyle}
                imageName={betType[BetType.SDBEven].image}
                betType={BetType.SDBEven}
                isShowBetAmount={true}
                betTypeKey="Even"
                perColor={'231,51,35'}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                isFlash={true}
            />
        </Grid>
    </>
);
