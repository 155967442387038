import { useEffect, useState } from 'react';
import BaseImage from '../..';

type Props = {
    source: string;
    endFrame: number;
    duration?: number;
    intervalTime?: number;
    // scale?: number;
    // repeat?: number; //0 = loop; 1 ~ n
    // state?: stateType;
};

//type stateType = 'play' | 'stop' | 'pause';

const AnimationImage = (props: Props) => {
    const {
        source,
        endFrame,
        duration = 1000,
        intervalTime = 0,
        //state = 'play',
        // repeat = 0,
        // scale = 0.5,
    } = props;
    const [imageName, setImageName] = useState<string>(source);
    //const secFrame = duration / (endFrame + 1);

    useEffect(() => {
        let num = 0;
        const waitTime = intervalTime * endFrame;
        const secFrame = duration / endFrame;
        const interval = setInterval(() => {
            num++;
            if (num < endFrame) {
                const newSource = source.slice(0, -1) + num;
                setImageName(newSource);
            } else {
                if ((num - endFrame) > (waitTime)) {
                    num = 0;
                }
                setImageName(source);
            }
        }, secFrame);

        return () => clearInterval(interval);
    }, []);

    // const sleep = async (ms: number) => {
    //     return new Promise(resolve => {
    //         const sleepId = setTimeout(() => {
    //             resolve(sleepId);
    //         }, ms);
    //     });
    // };

    // useEffect(() => {
    //     let repeatCount = 1;
    //     const setAnim = async () => {
    //         const promise1 = (async () => {
    //             for await (const num of [...Array(endFrame + 1).keys(), 0]) {
    //                 (await sleep(secFrame)) as Promise<NodeJS.Timeout>;
    //                 const newSource = source.slice(0, -1) + num;
    //                 setImageName(newSource);
    //             }
    //             repeatCount++;
    //             return Promise.resolve('play frame');
    //         })();
    //         const promise2 = (async () => {
    //             if (state === 'play') {
    //                 if (intervalTime) {
    //                     await sleep(intervalTime);
    //                 }
    //                 if (repeat === 0 || repeat >= repeatCount) {
    //                     setAnim();
    //                     return Promise.resolve('play');
    //                 } else {
    //                     return Promise.reject('end repeat count');
    //                 }
    //             }
    //         })();
    //         await Promise.all([promise1, promise2]);
    //     };
    //     if (state === 'play') {
    //         setAnim();
    //     } else if (state === 'pause') {
    //         //clear all time out
    //         //TODO: pause replay
    //         // console.log(sleepIds);
    //     } else {
    //         //clear all time out
    //         //set default source
    //         setImageName(source);
    //     }
    //     return () => {
    //         //clear all time out
    //         // console.log(sleepIds);
    //         // for (const id of sleepIds) {
    //         //     clearTimeout(id);
    //         // }
    //         // setSleepIds([setTimeout(() => {}, 0)]);
    //         let id: NodeJS.Timeout = setTimeout(() => {}, 0);
    //         while ((id as unknown as number) --) {
    //             clearTimeout(id); // will do nothing if no timeout with id is present
    //         }
    //     };
    // }, [state]);

    return (
        <>
            <BaseImage className={imageName} />
        </>
    );
};

export default AnimationImage;
