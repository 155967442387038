import { Stack, Typography } from '@mui/material';
import { InGamePopupKeyType } from '../../../../hooks/useInGamePopup';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { getHostById } from '../../../host/slice';
import { RouletteColor } from '../../../../models/games/roulette/Record';
import BaseImage from '../../../../components/common/baseImage';
import { AudioPlayContext } from '../../../../contexts/AudioPlayContext';
import { VoiceList } from '../../../../models/Voice';
import { LocalState as LocalStateEnum } from '../../../../models/games/enums/LocalState';
import { resultToColor } from '../../../../utils/games/roulette';

const ROULETTE_SEQ = [
    0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5,
    24, 16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26, 0,
];
type ResultType = {
    bg: string;
    displayNumbers: Array<string>;
};
export const RouletteGameResultPopup = (props: InGamePopupKeyType) => {
    const { amount, bg, message, audioKey, localState, playSound } = props;
    const { t } = useTranslation();
    const { audioPlay } = useContext(AudioPlayContext);
    const { hostId } = useContext(VideoPlayerContext);
    const { CurrentResult } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [result, setResult] = useState<ResultType>();
    const ref = useRef<boolean>(false); //防止play 2 次

    useEffect(() => {
        if (localState === LocalStateEnum.BETTING) {
            setResult(undefined);
        }
    }, [localState]);

    useEffect(() => {
        if (!ref.current) {
            ref.current = true;
            let aKey = [];
            if (audioKey && playSound) {
                aKey.push(audioKey);
            } else {
                if (CurrentResult) {
                    let bgClassName: string = '';
                    let displayNumbers: Array<string> = ['', '', ''];
                    const resultColor = resultToColor(
                        CurrentResult.Result,
                        Number(CurrentResult.ResultString)
                    );
                    if (resultColor === RouletteColor.GREEN) {
                        bgClassName = 'roulette h5_result_bg_3';
                        displayNumbers = ['26', '0', '32'];
                    } else {
                        let idx = ROULETTE_SEQ.indexOf(
                            Number(CurrentResult.ResultString)
                        );
                        if (idx > 0) {
                            for (let i = 0; i < 3; i++) {
                                displayNumbers[i] =
                                    ROULETTE_SEQ[idx - 1 + i].toString();
                            }
                        }
                        if (displayNumbers[0] == '0') {
                            bgClassName = 'roulette h5_result_bg_4';
                        } else if (displayNumbers[2] == '0') {
                            bgClassName = 'roulette h5_result_bg_5';
                        } else if (resultColor == RouletteColor.BLACK) {
                            bgClassName = 'roulette h5_result_bg_2';
                        } else if (resultColor == RouletteColor.RED) {
                            bgClassName = 'roulette h5_result_bg_1';
                        }
                    }
                    // let resultPoint: string = curRecord.resultPoint.toString();
                    setResult({
                        bg: bgClassName,
                        displayNumbers: displayNumbers,
                    });
                    if (displayNumbers[1]) {
                        aKey.push(
                            VoiceList.RoulettePoint.replace(
                                '{point}',
                                displayNumbers[1]
                            )
                        );
                    }
                }
            }
            if (aKey && aKey.length > 0) {
                audioPlay(aKey);
            }
        }
    }, []);

    if (message !== 'game.result') {
        return (
            <Stack
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    position: 'absolute',
                    height: 150,
                    width: 400,
                    top: 402,
                    left: 70,
                    right: 70,
                    fontSize: 35,
                    borderRadius: '4px',
                    backgroundColor: bg,
                    textAlign: 'center',
                    lineHeight: 1.5,
                    zIndex: theme => theme.zIndex.drawer + 80,
                }}
            >
                {t(message)}
            </Stack>
        );
    } else {
        return (
            <>
                {result && (
                    <Stack
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{
                            position: 'absolute',
                            width: 540,
                            height: 150,
                            top: 405,
                            bgcolor: 'rgba(0,0,0,0.75)',
                            zIndex: theme => theme.zIndex.drawer + 80,
                        }}
                    >
                        <Stack>
                            <BaseImage className={result.bg} />
                        </Stack>
                        <Stack
                            paddingLeft={'2px'}
                            direction={'row'}
                            justifyContent={'space-between'}
                            height="80px"
                            width="192px"
                            sx={{ position: 'absolute', top: 20 }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 40,
                                    width: '55px',
                                    paddingTop: '17px',
                                    textAlign: 'center',
                                    color: '#bbb',
                                    transform: 'Rotate(-25deg)',
                                }}
                            >
                                {result.displayNumbers[0]}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 50,
                                    width: '55px',
                                    textAlign: 'center',
                                }}
                            >
                                {result.displayNumbers[1]}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 40,
                                    width: '55px',
                                    paddingTop: '17px',
                                    textAlign: 'center',
                                    color: '#bbb',
                                    transform: 'Rotate(25deg)',
                                }}
                            >
                                {result.displayNumbers[2]}
                            </Typography>
                        </Stack>
                        <Typography
                            sx={{
                                fontSize: 30,
                                height: '20px',
                                textAlign: 'center',
                            }}
                        >
                            {amount}
                        </Typography>
                    </Stack>
                )}
            </>
        );
    }
};

// type ResultPopupProps = {
//     amount: string;
// };
// type ResultType = {
//     bg: string;
//     displayNumbers: Array<string>;
// };
// const ResultPopup = (props: ResultPopupProps) => {
//     const { amount } = props;

//     const { hostId } = useContext(VideoPlayerContext);
//     const { Record } = useSelector((state: RootState) =>
//         getHostById(state, hostId)
//     );
//     const [result, setResult] = useState<ResultType>();
//     const ref = useRef<boolean>(false); //防止play 2 次
//     useEffect(() => {
//         if (!ref.current) {
//             ref.current = true;
//             if (Record && Record.length > 0) {
//                 const curRecord: RouletteRecord = Record[
//                     Record.length - 1
//                 ] as RouletteRecord;

//                 let bgClassName: string = '';
//                 let displayNumbers: Array<string> = ['', '', ''];
//                 if (curRecord.color === RouletteColor.GREEN) {
//                     bgClassName = 'roulette h5_result_bg_3';
//                     displayNumbers = ['26', '0', '32'];
//                 } else {
//                     let idx = ROULETTE_SEQ.indexOf(curRecord.resultPoint);
//                     if (idx > 0) {
//                         for (let i = 0; i < 3; i++) {
//                             displayNumbers[i] =
//                                 ROULETTE_SEQ[idx - 1 + i].toString();
//                         }
//                     }
//                     if (displayNumbers[0] == '0') {
//                         bgClassName = 'roulette h5_result_bg_4';
//                     } else if (displayNumbers[2] == '0') {
//                         bgClassName = 'roulette h5_result_bg_5';
//                     } else if (curRecord.color == RouletteColor.BLACK) {
//                         bgClassName = 'roulette h5_result_bg_2';
//                     } else if (curRecord.color == RouletteColor.RED) {
//                         bgClassName = 'roulette h5_result_bg_1';
//                     }
//                 }
//                 // let resultPoint: string = curRecord.resultPoint.toString();
//                 setResult({ bg: bgClassName, displayNumbers: displayNumbers });
//             }
//         }
//     }, []);

//     return (
//         <>
//             {result && (
//                 <Stack
//                     justifyContent={'center'}
//                     alignItems={'center'}
//                     sx={{
//                         position: 'absolute',
//                         width: 540,
//                         height: 150,
//                         top: 405,
//                         bgcolor: 'rgba(0,0,0,0.75)',
//                         zIndex: theme => theme.zIndex.drawer + 80,
//                     }}
//                 >
//                     <Stack>
//                         <BaseImage className={result.bg} />
//                     </Stack>
//                     <Stack
//                         paddingLeft={'2px'}
//                         direction={'row'}
//                         justifyContent={'space-between'}
//                         height="80px"
//                         width="192px"
//                         sx={{ position: 'absolute', top: 20 }}
//                     >
//                         <Typography
//                             sx={{
//                                 fontSize: 40,
//                                 width: '55px',
//                                 paddingTop: '17px',
//                                 textAlign: 'center',
//                                 color: '#bbb',
//                                 transform: 'Rotate(-25deg)',
//                             }}
//                         >
//                             {result.displayNumbers[0]}
//                         </Typography>
//                         <Typography
//                             sx={{
//                                 fontSize: 50,
//                                 width: '55px',
//                                 textAlign: 'center',
//                             }}
//                         >
//                             {result.displayNumbers[1]}
//                         </Typography>
//                         <Typography
//                             sx={{
//                                 fontSize: 40,
//                                 width: '55px',
//                                 paddingTop: '17px',
//                                 textAlign: 'center',
//                                 color: '#bbb',
//                                 transform: 'Rotate(25deg)',
//                             }}
//                         >
//                             {result.displayNumbers[2]}
//                         </Typography>
//                     </Stack>
//                     <Typography
//                         sx={{
//                             fontSize: 30,
//                             height: '20px',
//                             textAlign: 'center',
//                         }}
//                     >
//                         {amount}
//                     </Typography>
//                 </Stack>
//             )}
//         </>
//     );
// };
