import { CloseOutlined, RefreshOutlined } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Divider,
    Grid,
    Stack,
    styled,
} from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import moment, { Moment } from 'moment/moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../theme';
import { REPORT_COLORS } from '../../lobby/constants';
import { ReportDivider } from '../../setting/components/ReportDivider';
import './DateRangePicker.scss';

interface CustomPickerDayProps extends PickersDayProps<Moment> {
    isSelected: boolean;
    isHovered: boolean;
    isFirstSelected: boolean;
    isLastSelected: boolean;
}

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: prop =>
        prop !== 'isSelected' &&
        prop !== 'isHovered' &&
        prop !== 'isFirstSelected' &&
        prop !== 'isLastSelected',
})<CustomPickerDayProps>(
    ({ theme, isSelected, isFirstSelected, isLastSelected }) => ({
        borderRadius: 0,
        ...(isFirstSelected && {
            borderTopLeftRadius: '30%',
            borderBottomLeftRadius: '30%',
        }),
        ...(isLastSelected && {
            borderTopRightRadius: '30%',
            borderBottomRightRadius: '30%',
        }),
        ...(isSelected && {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.secondary.main,
            },
        }),
    })
) as React.ComponentType<CustomPickerDayProps>;

type DayProps = PickersDayProps<Moment> & {
    selectedDay?: Array<Moment>;
    hoveredDay?: Moment;
};
const Day = (props: DayProps) => {
    const { day, selectedDay, hoveredDay, ...other } = props;
    let isSelected = false;
    let isHovered = false;
    let isFirstSelected = false;
    let isLastSelected = false;
    if (selectedDay) {
        isSelected = selectedDay.some(d => d.isSame(day, 'date'));
        // assume selected moment array is sorted
        isFirstSelected = day.isSame(selectedDay[0], 'date');
        isLastSelected = day.isSame(
            selectedDay[selectedDay.length - 1],
            'date'
        );
    }
    if (hoveredDay) {
        isHovered = day.isSame(hoveredDay, 'date');
    }
    return (
        <CustomPickersDay
            {...other}
            sx={{ px: 2.5, width: '12vw', height: '12vw', fontSize: '5vw' }}
            disableMargin
            day={day}
            selected={false}
            isSelected={isSelected}
            isHovered={isHovered}
            isFirstSelected={isFirstSelected}
            isLastSelected={isLastSelected}
        />
    );
};

type DateRangeDate = Moment | undefined;
type DateRangePickerGetProps = {
    start: DateRangeDate;
    end: DateRangeDate;
    expanded: boolean;
};
type DateRangePickerSetProps = {
    setStart: Dispatch<SetStateAction<DateRangeDate>>;
    setEnd: Dispatch<SetStateAction<DateRangeDate>>;
    setExpanded: Dispatch<SetStateAction<boolean>>;
    handleSubmit?: (startDate: Moment, endDate: Moment) => void;
    playButtonAudio: () => void;
};
type DateRangePickerOwnProps = {
    showPreset: boolean;
};
type DateRangePickerProps = DateRangePickerOwnProps &
    DateRangePickerGetProps &
    DateRangePickerSetProps;

const getSAToday = () => {
    const now = moment();
    let l = moment().set({ hour: 12, minute: 0, second: 0 });
    if (now.isAfter(l)) l = l.add(1, 'day');
    return l;
};

export const DateRangePicker = (props: DateRangePickerProps) => {
    const {
        start,
        end,
        expanded,
        setStart,
        setEnd,
        setExpanded,
        showPreset,
        handleSubmit,
        playButtonAudio,
    } = props;
    const [hoveredDay, setHoveredDay] = useState<Moment>();
    const [limit, setLimit] = useState<Moment>();
    const [selectedDay, setSelectedDay] = useState<Array<Moment>>([]);
    useEffect(() => {
        const l = getSAToday();
        setLimit(l);

        //set default
        const now = getSAToday(); //moment().add(1, 'day');
        const last = moment(now).subtract(7, 'day');
        setStart(last);
        setEnd(now);
        handleSubmit?.(last, now);
    }, []);
    useEffect(() => {
        const s = new Array<Moment>();
        if (start && end) {
            for (
                let m = moment(start);
                m.isSameOrBefore(end, 'date');
                m = m.add(1, 'day')
            ) {
                s.push(moment(m));
            }
        } else if (start) {
            s.push(start);
        }

        setSelectedDay(s);
    }, [start, end]);
    const reset = () => {
        setStart(undefined);
        setEnd(undefined);
        setSelectedDay([]);
    };
    const handleChangeDate = (d: Moment) => {
        if (start && end) {
            reset();
            setStart(d);
        } else if (start) {
            setEnd(d);
            handleSubmit?.(start, d);
        } else {
            setStart(d);
        }
    };
    return (
        <>
            <Stack>
                <Accordion expanded={expanded}>
                    <AccordionSummary>
                        <Grid
                            item
                            container
                            // justifyContent="center"
                            direction="column"
                        >
                            <Grid
                                item
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                flexWrap="nowrap"
                                onClick={() => {
                                    setExpanded(!expanded);
                                    playButtonAudio();
                                }}
                            >
                                <DateRangeButton
                                    time={start}
                                    label="12:00:00"
                                />
                                <Divider
                                    sx={{
                                        width: '3vw',
                                        borderWidth: '2px',
                                        borderColor: REPORT_COLORS.TITLE_TEXT,
                                    }}
                                />
                                <DateRangeButton time={end} label="11:59:59" />
                            </Grid>
                            {showPreset && (
                                <Grid
                                    item
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                    flexWrap="nowrap"
                                    sx={{
                                        margin: '12px 0 0 0',
                                    }}
                                >
                                    <PresetRangeButton
                                        Last={1}
                                        setStart={setStart}
                                        setEnd={setEnd}
                                        setExpanded={setExpanded}
                                        handleSubmit={handleSubmit}
                                        playButtonAudio={playButtonAudio}
                                    />
                                    <PresetRangeButton
                                        Last={7}
                                        setStart={setStart}
                                        setEnd={setEnd}
                                        setExpanded={setExpanded}
                                        handleSubmit={handleSubmit}
                                        playButtonAudio={playButtonAudio}
                                    />
                                    <PresetRangeButton
                                        Last={14}
                                        setStart={setStart}
                                        setEnd={setEnd}
                                        setExpanded={setExpanded}
                                        handleSubmit={handleSubmit}
                                        playButtonAudio={playButtonAudio}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: '0' }}>
                        <DateCalendar
                            views={['day']}
                            maxDate={limit}
                            onChange={value => {
                                const m = moment(value);
                                handleChangeDate(m);
                            }}
                            sx={{ overflowY: 'auto' }}
                            slots={{
                                day: Day,
                            }}
                            slotProps={{
                                day: ownerState => ({
                                    selectedDay,
                                    hoveredDay,
                                    onPointerEnter: () =>
                                        setHoveredDay(ownerState.day),
                                    onPointerLeave: () =>
                                        setHoveredDay(undefined),
                                }),
                            }}
                            showDaysOutsideCurrentMonth
                        />
                        <ReportDivider />
                        <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="space-evenly"
                            sx={{
                                margin: '12px 0',
                            }}
                        >
                            <Button
                                onClick={() => {
                                    reset();
                                    playButtonAudio();
                                }}
                                variant="outlined"
                                sx={{
                                    width: '30vw',
                                    height: '8.5vw',
                                    fontSize: '2.78vw',
                                    color: theme.palette.text.primary,
                                }}
                            >
                                <RefreshOutlined />
                            </Button>
                            <Button
                                onClick={() => {
                                    setExpanded(false);
                                    playButtonAudio();
                                }}
                                variant="outlined"
                                sx={{
                                    width: '30vw',
                                    height: '8.5vw',
                                    fontSize: '2.78vw',
                                    color: theme.palette.text.primary,
                                }}
                            >
                                <CloseOutlined />
                            </Button>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Stack>
            <ReportDivider />
        </>
    );
};
type PresetRangeButtonOwnProps = {
    Last: 1 | 7 | 14;
};
type PresetRangeButtonProps = DateRangePickerSetProps &
    PresetRangeButtonOwnProps;

const PresetRangeButton = (props: PresetRangeButtonProps) => {
    const {
        Last,
        setStart,
        setEnd,
        setExpanded,
        handleSubmit,
        playButtonAudio,
    } = props;
    const notSingle = Last !== 1;
    const { t, i18n } = useTranslation();
    const [languageDir, setLanguageDir] = useState<string>('ltr');
    useEffect(() => {
        setLanguageDir(i18n.dir(i18n.language));
    }, [i18n.language]);
    return (
        <Button
            sx={{
                width: '30vw',
                height: '8.5vw',
                fontSize: '2.78vw',
                color: theme.palette.text.primary,
                textTransform: 'none',
                direction: languageDir,
            }}
            variant="outlined"
            onClick={() => {
                const now = getSAToday();
                // const now = Last > 1 ? moment().add(1, 'day') : getSAToday();
                const last = moment(now).subtract(Last, 'day');
                setStart(last);
                setEnd(now);
                setExpanded(false);
                handleSubmit?.(last, now);
                playButtonAudio();
            }}
        >
            {t(`betlog.${!notSingle ? 'today' : `last${Last}days`}`)}
        </Button>
    );
};

type DateRangeButtonProps = {
    time: DateRangeDate;
    label: string;
};
const DateRangeButton = (props: DateRangeButtonProps) => {
    const { time, label } = props;
    return (
        <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{
                borderColor: '#787878',
                border: '1px solid',
                borderRadius: '0.5vw',
                width: '45vw',
                height: '8.84vw',
                backgroundColor: '#1b1b1b',
            }}
        >
            <Grid
                item
                sx={{
                    fontSize: '3.33vw',
                    marginLeft: '2vw',
                }}
            >
                {moment(time).locale('en').format('YYYY/MM/DD')}
            </Grid>
            <Grid
                item
                sx={{
                    fontSize: '2.78vw',
                    color: '#787878',
                    marginRight: '2vw',
                }}
            >
                {label}
                <br />
                (UTC+8)
            </Grid>
        </Grid>
    );
};
