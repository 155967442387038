import {
    Dispatch,
    SetStateAction,
    createContext,
    useEffect,
    useState,
} from 'react';
import { useLocalStorage } from '../hooks/storage/useLocalStorage';
type liteUIState = {
    isLite: boolean;
    setIsLite: Dispatch<SetStateAction<boolean>>;
};
export const LiteUIContext = createContext({} as liteUIState);
const { Provider } = LiteUIContext;
type LiteUIProviderProps = {
    children: JSX.Element;
};
export const LiteUIProvider = ({ children }: LiteUIProviderProps) => {
    const [isLite, setIsLite] = useLocalStorage('lite', false);
    const [cn, setCn] = useState('');
    useEffect(() => {
        // local storage may return a non boolean value
        setCn(isLite === true ? 'lite-ui' : '');
    }, [isLite]);
    return (
        <Provider value={{ isLite, setIsLite }}>
            <div className={cn}>{children}</div>
        </Provider>
    );
};
