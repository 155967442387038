import { useContext, useEffect, useMemo, useState } from 'react';
import '../index.scss';
import { useRoadMapState } from './hook';
import './sicbo.scss';
import { getHistory, getMappedRoad } from './util';
import HistoryCell from './cell/HistoryCell';
import { SicBoRoadmapRecord } from '../../../models/games/sicbo';
import BaseImage from '../../../components/common/baseImage';
import SicBoBigSmallRoadCell from './cell/SicBoBigSmallRoadCell';
import { REPORT_COLORS, ROAD_MAP_TYPE } from '../../lobby/constants';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TabPanel } from '../../lobby/components/GameTables';
import SicBoPointRoadCell from './cell/SicBoPointRoadCell';
import SicBoOddEvenRoadCell from './cell/SicBoOddEvenRoadCell';
import { MobPref } from '../../../hooks/storage/useLocalStorage';
import TrialImage from '../../game/components/TrialImage';
import { AudioPlayContext } from '../../../contexts/AudioPlayContext';

type SicBoRoadmapProps = {
    hostId: number;
    inGame?: boolean;
};
export const SicBoRoadMap = (props: SicBoRoadmapProps) => {
    const { hostId, inGame = false } = props;
    const {
        isOneColumn,
        IsRest,
        roadmapRecord,
        history,
        columnNum,
        setHistory,
    } = useRoadMapState(hostId, inGame);
    const containerClass = useMemo(
        () =>
            isOneColumn
                ? 'sic-bo one-column-main-container'
                : 'sic-bo two-column-main-container',
        [isOneColumn]
    );
    const historyRR = useMemo(
        () => getHistory(roadmapRecord, isOneColumn ? 10 : 7),
        [roadmapRecord, isOneColumn]
    );
    useEffect(() => {
        if (!IsRest) {
            setHistory(historyRR);
        } else {
            setHistory(undefined);
        }
        console.log('roadmap::update', hostId);
    }, [roadmapRecord, IsRest, isOneColumn]);
    return (
        <>
            <div className={containerClass}>
                <div className="history-road">
                    {[...'-'.repeat(columnNum)].map((_, index) => {
                        const r = history?.at(index) as
                            | SicBoRoadmapRecord
                            | undefined;
                        let isLast = false;
                        if (history) {
                            isLast = index === history.length - 1;
                        }

                        return (
                            <HistoryCell
                                record={r}
                                isOneColumn={isOneColumn}
                                isLast={isLast}
                                key={`game-id-${hostId}-${index}`}
                            />
                        );
                    })}
                </div>
            </div>
            {inGame && <TrialImage />}
        </>
    );
};

export const SicBoSwitchTableMap = (props: SicBoRoadmapProps) => {
    const { hostId } = props;
    const { IsRest, roadmapRecord } = useRoadMapState(hostId);
    const [bigSmallRoadCells, setBigSmallRoadCells] = useState<JSX.Element>(
        <></>
    );
    useEffect(() => {
        const cells = new Array<JSX.Element>();
        if (!IsRest) {
            const bigSmallRR = getMappedRoad(
                roadmapRecord,
                ROAD_MAP_TYPE.BIG_SMALL_ROAD,
                11,
                6
            );

            let index = 0;
            for (const record of bigSmallRR) {
                const cell = (
                    <SicBoBigSmallRoadCell
                        size={15}
                        BigSmallResult={record.BigSmallResult}
                        key={`sic-bo-big-small-road-cell-${index}`}
                    />
                );
                cells.push(cell);
                index++;
            }
        }
        setBigSmallRoadCells(<>{cells}</>);
    }, [roadmapRecord, IsRest]);
    return (
        <>
            <div className="sicbo-switch-table-map-main-container">
                <BaseImage
                    className="tradbaccRoadmapBg hori_allroad_ver2"
                    scale={1}
                />
                <div className="big-small-road">{bigSmallRoadCells}</div>
            </div>
        </>
    );
};

export const SicBoRoadMapPanel = (props: SicBoRoadmapProps) => {
    const { hostId } = props;
    const { playButtonAudio } = useContext(AudioPlayContext);
    const { t } = useTranslation();
    const [curRoadmapTab, setRoadmapCurTab] = useState<number>(0);
    const handleChange = (v: number) => {
        playButtonAudio();
        setRoadmapCurTab(v);
        localStorage.setItem(
            'MobPref_' + MobPref.SICBO_ROADMAP_PORT_MODE,
            JSON.stringify(v)
        );
    };
    useEffect(() => {
        const lsSicBoTab = localStorage.getItem(
            `MobPref_${MobPref.SICBO_ROADMAP_PORT_MODE}`
        );
        if (lsSicBoTab) {
            setRoadmapCurTab(Number(lsSicBoTab));
        }
    }, []);
    return (
        <>
            <Box className="sicbo-roadmap-container">
                <Tabs
                    className="roadmap-tabs"
                    value={curRoadmapTab}
                    onChange={(_, v) => {
                        handleChange(v);
                    }}
                    aria-label="roadmap"
                    sx={{ '& Button': { color: REPORT_COLORS.TITLE_TEXT } }}
                >
                    <Tab
                        value={0}
                        label={t('tableinfo.points')}
                        onClick={() => setRoadmapCurTab(0)}
                    />
                    <Tab
                        value={1}
                        label={t('tableinfo.big_small')}
                        onClick={() => setRoadmapCurTab(1)}
                    />
                    <Tab
                        value={2}
                        label={t('tableinfo.odd_even')}
                        onClick={() => setRoadmapCurTab(2)}
                    />
                </Tabs>
                <Box className="roadmap-tab">
                    <TabPanel value={curRoadmapTab} index={0}>
                        <SicBoPointRoadMap hostId={hostId} />
                    </TabPanel>
                    <TabPanel value={curRoadmapTab} index={1}>
                        <SicBoBigSmallRoadMap hostId={hostId} />
                    </TabPanel>
                    <TabPanel value={curRoadmapTab} index={2}>
                        <SicBoOddEvenRoadMap hostId={hostId} />
                    </TabPanel>
                </Box>
                <RoadMapSummaryList hostId={hostId} />
                <TrialImage />
            </Box>
        </>
    );
};
const RoadMapSummaryList = (props: SicBoRoadmapProps) => {
    const { hostId } = props;
    const { t } = useTranslation();
    const { summaryList } = useRoadMapState(hostId);
    return (
        <>
            <Stack
                className="summary-list"
                direction={'row'}
                justifyContent={'flex-start'}
                alignItems={'center'}
            >
                {summaryList &&
                    summaryList.map((s, idx) => {
                        let txt = s;
                        let cn = '';
                        if (typeof s === 'string') {
                            txt = t(s);
                            cn = 'summary ' + s;
                        }
                        return (
                            <p key={`summary-${idx}`} className={cn}>
                                {txt}
                            </p>
                        );
                    })}
            </Stack>
        </>
    );
};

const SicBoPointRoadMap = (props: SicBoRoadmapProps) => {
    const { hostId } = props;
    const { IsRest, roadmapRecord, pointRoadRecord, setPointRoadRecord } =
        useRoadMapState(hostId);
    const pointRR = useMemo(
        () => getMappedRoad(roadmapRecord, ROAD_MAP_TYPE.POINT_ROAD, 24, 6),
        [roadmapRecord]
    );
    useEffect(() => {
        if (!IsRest) {
            setPointRoadRecord(pointRR);
        } else {
            setPointRoadRecord(undefined);
        }
        console.log('roadmap::update', hostId);
    }, [roadmapRecord, IsRest]);
    return (
        <>
            <BaseImage className="tradbaccRoadmapBg marker_roadmap" scale={1} />
            <div className="point-road">
                {pointRoadRecord?.map((record, idx) => (
                    <SicBoPointRoadCell
                        size={22}
                        BigSmallResult={record.BigSmallResult}
                        PointResult={record.PointResult}
                        key={`point-road-cell-${idx}`}
                    />
                ))}
            </div>
        </>
    );
};
const SicBoBigSmallRoadMap = (props: SicBoRoadmapProps) => {
    const { hostId } = props;
    const { IsRest, roadmapRecord, bigSmallRoadRecord, setBigSmallRoadRecord } =
        useRoadMapState(hostId);
    const bigSmallRR = useMemo(
        () => getMappedRoad(roadmapRecord, ROAD_MAP_TYPE.BIG_SMALL_ROAD, 24, 6),
        [roadmapRecord]
    );
    useEffect(() => {
        if (!IsRest) {
            setBigSmallRoadRecord(bigSmallRR);
        } else {
            setBigSmallRoadRecord(undefined);
        }
        console.log('roadmap::update', hostId);
    }, [roadmapRecord, IsRest]);
    return (
        <>
            <BaseImage className="tradbaccRoadmapBg marker_roadmap" scale={1} />
            <div className="big-small-road">
                {bigSmallRoadRecord?.map((r, idx) => (
                    <SicBoBigSmallRoadCell
                        size={22}
                        BigSmallResult={r.BigSmallResult}
                        key={`sic-bo-big-small-road-cell-${idx}`}
                    />
                ))}
            </div>
        </>
    );
};
const SicBoOddEvenRoadMap = (props: SicBoRoadmapProps) => {
    const { hostId } = props;
    const { IsRest, roadmapRecord, evenOddRoadRecord, setEvenOddRoadRecord } =
        useRoadMapState(hostId);
    const evenOddRR = useMemo(
        () => getMappedRoad(roadmapRecord, ROAD_MAP_TYPE.EVEN_ODD_ROAD, 24, 6),
        [roadmapRecord]
    );
    useEffect(() => {
        if (!IsRest) {
            setEvenOddRoadRecord(evenOddRR);
        } else {
            setEvenOddRoadRecord(undefined);
        }
        console.log('roadmap::update', hostId);
    }, [roadmapRecord, IsRest]);
    return (
        <>
            <BaseImage className="tradbaccRoadmapBg marker_roadmap" scale={1} />
            <div className="odd-even-road">
                {evenOddRoadRecord?.map((r, idx) => (
                    <SicBoOddEvenRoadCell
                        size={22}
                        OddEventRResult={r.OddEventRResult}
                        key={`sic-bo-odd-even-road-cell-${idx}`}
                    />
                ))}
            </div>
        </>
    );
};
