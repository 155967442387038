// import { Done as DoneIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import BaseImage from '../../../components/common/baseImage';
type CancelButtonProps = {
    onClick: () => void;
    scale?: number;
    image?: string;
};
export const CancelButton = (props: CancelButtonProps) => {
    const { onClick, scale = 1, image = 'commonUI btnCancel' } = props;
    return (
        <IconButton onClick={() => onClick()}>
            <BaseImage className={image} scale={scale} />
        </IconButton>
    );
};
