import { memo } from 'react';
import { Empty, SmallBankerWin, SmallPlayerWin } from '../svg';
type SmallRoadCellProps = {
    win: number;
};
const SmallRoadCell = ({ win }: SmallRoadCellProps) => {
    if (win === 0) return <SmallBankerWin />;
    else if (win === 1) return <SmallPlayerWin />;
    else return <Empty />;
};
export default memo(SmallRoadCell);
