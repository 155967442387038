import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { getRoadmap } from '../../slice';
import BigRoadCell from '../cells/BigRoadCell';
import { useRoadMapState } from '../hook';
import { getViewRoadRecord } from '../util';
import { BaccaratRoadMapProps } from './index';
import { GameState } from '../../../../models/games/enums/GameState';
import { getHostById } from '../../../host/slice';

export const SwitchTableMap = (props: BaccaratRoadMapProps) => {
    const size = 15.4;
    const { hostId } = props;
    const { IsRest } = useRoadMapState(hostId);
    const { BigRoad } = useSelector((state: RootState) =>
        getRoadmap(state, hostId)
    );
    const { CurrentState } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [showRoadMap, setShowRoadMap] = useState<boolean>(false);

    const [bigRoadCells, setBigRoadCells] = useState<JSX.Element>(<></>);

    useEffect(() => {
        setShowRoadMap(!IsRest && CurrentState !== GameState.Shuffle);
    }, [IsRest, CurrentState]);
    useEffect(() => {
        if (showRoadMap) {
            const list = getViewRoadRecord(BigRoad, 11);
            const brc = list?.map((record, idx) => (
                <BigRoadCell record={record} key={`big-road-cell-${idx}`} />
            ));
            setBigRoadCells(<>{brc}</>);
        } else {
            setBigRoadCells(<></>);
        }
    }, [BigRoad, showRoadMap]);
    return (
        <div className="SwitchTableMapMainContainer">
            <div className="switch-grid">
                <svg width="100%" height="100%">
                    <defs>
                        <pattern
                            id={`grid${size}`}
                            width={size}
                            height={size}
                            patternUnits="userSpaceOnUse"
                        >
                            <path
                                d={`M ${size} 0 L 0 0 0 ${size}`}
                                fill="none"
                                stroke="#DCDCDC"
                                strokeWidth="2"
                            />
                        </pattern>
                    </defs>

                    <rect
                        width="100%"
                        height="100%"
                        fill={`url(#grid${size})`}
                    />
                </svg>
            </div>
            <div className="bigEyeRoad">
                <div className="bigRoad">{bigRoadCells}</div>
            </div>
        </div>
    );
};
