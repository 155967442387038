import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { Poker, PokerType } from '../../../../../components/common/poker';
import {
    AndarBaharFirst3Pattern,
    convertFResult,
} from '../../../../../utils/games/andarBahar';
import { AndarBaharResultRecordPanelProps } from './AndarBaharResultRecordPanel';

export const AndarBaharResultDetailPanel = (
    props: AndarBaharResultRecordPanelProps
) => {
    const { bet } = props;
    const getCardContent = () => {
        const cards = bet.Result.split(',').map(s => Number(s));
        const andars: number[] = [];
        const bahars: number[] = [];
        for (let i = 1; i < cards.length; i++) {
            if (i % 2 == 1) {
                andars.push(cards[i]);
            } else {
                bahars.push(cards[i]);
            }
        }

        return {
            cardsDealt: cards.length - 1,
            andarCards: andars,
            baharCards: bahars,
        };
    };
    const cards = getCardContent();
    const result = convertFResult(BigInt(bet.WinSlot));
    const resultView = {
        gameCard: result.GameCard,
        cardsDealt: cards.cardsDealt,
        firstAndarCard: cards.andarCards[0],
        firstBaharCard: cards.baharCards ? cards.baharCards[0] : undefined,
        remainAndarCards: cards.andarCards.slice(1),
        remainBaharCards: cards.baharCards.slice(1),
        first3Pattern: result.First3Result,
    };
    const containerRef = useRef<HTMLDivElement>(null);

    const getAndarPokerType = (position: number): PokerType => {
        if (resultView.remainAndarCards) {
            if (position === resultView.remainAndarCards.length - 1) {
                return PokerType.AndarBahar2;
            }
        }
        return PokerType.AndarBahar1;
    };

    const getBaharPokerType = (position: number): PokerType => {
        if (resultView.remainBaharCards) {
            if (position === resultView.remainBaharCards.length - 1) {
                return PokerType.AndarBahar2;
            }
        }
        return PokerType.AndarBahar1;
    };

    const getAndarPokerScaleFactor = (position: number): number => {
        let scale: number;
        if (position === resultView.remainAndarCards.length - 1) {
            //Card Type 2
            if (
                resultView.remainAndarCards.length >
                resultView.remainBaharCards.length
            ) {
                scale = 0.5;
            } else {
                scale = 0.37;
            }
            return scale;
        } else {
            //Card Type 1
            scale = 0.55;
        }

        return scale;
    };

    const getBaharPokerScaleFactor = (position: number): number => {
        let scale: number;
        if (position === resultView.remainBaharCards.length - 1) {
            //Card Type 2
            if (
                resultView.remainAndarCards.length >
                resultView.remainBaharCards.length
            ) {
                scale = 0.37;
            } else {
                scale = 0.5;
            }
        } else {
            //Card Type 1
            scale = 0.55;
        }

        return scale;
    };

    const getFirst3ResultPatternMessage = (): string => {
        let msg = '';
        if (resultView.first3Pattern) {
            switch (resultView.first3Pattern) {
                case AndarBaharFirst3Pattern.ABF3StraightFlush:
                    msg = t('andar_bahar.straight_flush');
                    break;
                case AndarBaharFirst3Pattern.ABF3Straight:
                    msg = t('andar_bahar.straight');
                    break;
                case AndarBaharFirst3Pattern.ABF3Flush:
                    msg = t('andar_bahar.flush');
                    break;
                // case AndarBaharFirst3Pattern.ABF3None:
                //     msg = t('andar_bahar.no_win');
                //     break;
                default:
                    msg = '';
            }
        }
        return msg;
    };

    const [isScrollStart, setIsScrollStart] = useState(false);
    const [isScrollEnd, setIsScrollEnd] = useState(false);
    useEffect(() => {
        const containerElement = containerRef.current;

        const handleScroll = () => {
            if (containerElement) {
                setIsScrollStart(containerElement.scrollLeft === 0);
                setIsScrollEnd(
                    containerElement.scrollLeft +
                        containerElement.clientWidth >=
                        containerElement.scrollWidth
                );
            }
        };

        containerElement?.addEventListener('scroll', handleScroll);
        handleScroll(); // Check initial scroll position

        return () => {
            containerElement?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Stack direction="column" spacing={0}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                    padding: '0 16px 0 16px',
                }}
            >
                <Stack direction={'column'} spacing={6}>
                    <Typography>{t('andar_bahar.andar')}</Typography>
                    <Typography>{t('andar_bahar.bahar')}</Typography>
                </Stack>
                <Stack
                    direction={'row'}
                    spacing={0.5}
                    sx={{ alignItems: 'center' }}
                >
                    <Box sx={{ minWidth: '24px' }}>
                        {!isScrollStart && (
                            <ArrowBackIosNewIcon
                                fontSize="medium"
                                sx={{
                                    color: '#c3a475',
                                    display: 'inline-block',
                                }}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            width: '46vw',
                            height: '20vh',
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            ref={containerRef}
                            sx={{
                                overflowY: 'hidden',
                                display: 'flex',
                                flexWrap: 'wrap',
                                flexDirection: 'column',
                                height: '100%',
                                '&::-webkit-scrollbar': {
                                    display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                                },
                                '&-ms-overflow-style': {
                                    display: 'none', // Hide the scrollbar for IE
                                },
                            }}
                        >
                            {resultView.remainAndarCards !== undefined && (
                                <Stack
                                    direction={'row-reverse'}
                                    spacing={-2.5}
                                    sx={{ alignItems: 'center', height: '50%' }}
                                >
                                    {resultView.remainAndarCards.map(
                                        (card, index) => (
                                            <Box
                                                key={`andar-ecard-${index}`}
                                                sx={{
                                                    zIndex: index + 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Poker
                                                    n={card + 1}
                                                    type={getAndarPokerType(
                                                        index
                                                    )}
                                                    scale={getAndarPokerScaleFactor(
                                                        index
                                                    )}
                                                />
                                            </Box>
                                        )
                                    )}
                                </Stack>
                            )}
                            {resultView.remainBaharCards !== undefined && (
                                <Stack
                                    direction={'row-reverse'}
                                    spacing={-2.5}
                                    sx={{ alignItems: 'center', height: '50%' }}
                                >
                                    {resultView.remainBaharCards.map(
                                        (card, index) => (
                                            <Box
                                                key={`bahar-ecard-${index}`}
                                                sx={{
                                                    zIndex: index + 1,
                                                    display: 'flex',
                                                    height: '50px',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Poker
                                                    n={card + 1}
                                                    type={getBaharPokerType(
                                                        index
                                                    )}
                                                    scale={getBaharPokerScaleFactor(
                                                        index
                                                    )}
                                                />
                                            </Box>
                                        )
                                    )}
                                </Stack>
                            )}
                        </Box>
                    </Box>
                    <Box sx={{ minWidth: '24px' }}>
                        {!isScrollEnd && (
                            <ArrowForwardIosIcon
                                fontSize="medium"
                                sx={{
                                    color: '#c3a475',
                                    display: 'inline-block',
                                }}
                            />
                        )}
                    </Box>
                </Stack>
                <Stack
                    direction={'column'}
                    spacing={0}
                    sx={{
                        margin: '0 10px 0 10px',
                        paddingBottom: '1.9vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography fontSize={13} sx={{ whiteSpace: 'nowrap' }}>
                        {t('betlog.1st')}
                    </Typography>
                    <Box sx={{ minHeight: '56px' }}>
                        {resultView.firstAndarCard !== undefined && (
                            <Poker
                                n={resultView.firstAndarCard + 1}
                                type={PokerType.AndarBahar2}
                                scale={0.37}
                            />
                        )}
                    </Box>
                    <Box sx={{ minHeight: '20px' }}>
                        {resultView.firstBaharCard !== undefined && (
                            <Typography
                                fontSize={13}
                                sx={{ whiteSpace: 'nowrap' }}
                            >
                                {t('betlog.1st')}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ height: '56px' }}>
                        {resultView.firstBaharCard !== undefined && (
                            <Poker
                                n={resultView.firstBaharCard + 1}
                                type={PokerType.AndarBahar2}
                                scale={0.37}
                            />
                        )}
                    </Box>
                </Stack>
                <Stack
                    direction={'column'}
                    spacing={0}
                    sx={{ justifyContent: 'center', alignItems: 'center' }}
                >
                    <Typography
                        fontSize={12}
                        sx={{ textAlign: 'center', color: '#c3a475' }}
                    >
                        {t('andar_bahar.game_card')}
                    </Typography>
                    {resultView.gameCard !== undefined && (
                        <Poker
                            n={resultView.gameCard + 1}
                            type={PokerType.AndarBahar2}
                            scale={0.5}
                        />
                    )}
                    <Typography fontSize={12} sx={{ textAlign: 'center' }}>
                        {t('andar_bahar.card_dealt')}
                    </Typography>
                    <Typography
                        fontSize={12}
                    >{`${resultView.cardsDealt}`}</Typography>
                </Stack>
            </Box>
            {resultView.first3Pattern > AndarBaharFirst3Pattern.ABF3None && (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Box
                        sx={{
                            width: 'fit-content',
                            height: 'fit-content',
                            borderRadius: '5px 5px 5px 5px',
                            backgroundColor: '#65573E',
                            margin: '0 16px 8px 0',
                        }}
                    >
                        <Typography
                            fontSize={14}
                            sx={{
                                whiteSpace: 'nowrap',
                                padding: '0 10px 0 10px',
                            }}
                        >
                            {`${t('andar_bahar.first_3_caption')} ${getFirst3ResultPatternMessage()}`}
                        </Typography>
                    </Box>
                </Box>
            )}
        </Stack>
    );
};
