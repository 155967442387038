export enum GameFeature {
    // AutoBet = 1 << 0,
    // Chat = 1 << 1,
    // Tips = 1 << 2,
    // HideMessage = 1 << 3,
    // SAApp = 1 << 4,
    // AndarBaharSideBet = 1 << 5,
    // HedgeBetting = 1 << 6,
    // FeatureTab = 1 << 7,
    
    //SA -> Model.ts -> 0 ~ 7
    AutoBet,
    Chat,
    Tips,
    HideMessage,
    SAApp,
    AndarBaharSideBet,
    HedgeBetting,
    FeatureTab,
}
