import { RoadMapProps } from '.';
import { GameType as CommonGameType } from '../../../../models/games/enums/GameType';
import { AndarBaharInGameRoadMap } from '../../../roadmap/andarBahar';
import { BaccaratInGameMap } from '../../../roadmap/baccarat/map';
import { BlackjackSwitchTableMap } from '../../../roadmap/blackjack';
import {
    DragonTigerInGameMarkerRoadRoadMap,
    DragonTigerInGameRoadMap,
} from '../../../roadmap/dragonTiger';
import { PokDengInGameRoadMap } from '../../../roadmap/pokdeng';
import { RouletteRoadMapPanel } from '../../../roadmap/roulette';
import { SedieRoadMapPanel } from '../../../roadmap/sedie';
import { SicBoRoadMapPanel } from '../../../roadmap/sicbo';
import { TeenPattiInGameMap } from '../../../roadmap/teenpatti';
import { ROAD_MAP_TYPE } from '../../constants';

export type InGameRoadMapProps = {
    isMarker?: boolean;
    roadMapType?: ROAD_MAP_TYPE;
} & RoadMapProps;
export const InGameRoadMap = (props: InGameRoadMapProps) => {
    const { hostId, roadMapType, isMarker = false, gameType } = props;
    switch (gameType) {
        case CommonGameType.Baccarat:
            return (
                <BaccaratInGameMap
                    hostId={hostId}
                    roadMapTypeSelected={roadMapType}
                />
            );
        case CommonGameType.Dragon:
            return isMarker ? (
                <DragonTigerInGameMarkerRoadRoadMap hostId={hostId} />
            ) : (
                <DragonTigerInGameRoadMap hostId={hostId} />
            );
        case CommonGameType.Blackjack:
            return <BlackjackSwitchTableMap hostId={hostId} />;
        case CommonGameType.PokDeng:
            return <PokDengInGameRoadMap hostId={hostId} />;
        case CommonGameType.SeDie:
            return <SedieRoadMapPanel hostId={hostId} />;
        case CommonGameType.SicBo:
            return <SicBoRoadMapPanel hostId={hostId} />;
        case CommonGameType.Roulette:
            return <RouletteRoadMapPanel hostId={hostId} />;
        case CommonGameType.AndarBahar:
            return (
                <AndarBaharInGameRoadMap
                    hostId={hostId}
                    roadMapTypeSelected={roadMapType}
                />
            );
        case CommonGameType.TeenPatti2020:
            return <TeenPattiInGameMap hostId={hostId} />;
        default:
            return <>Host no exist</>;
    }
};
