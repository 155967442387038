import BScroll from '@better-scroll/core';
import { BScrollConstructor } from '@better-scroll/core/dist/types/BScroll';
import Slide from '@better-scroll/slide';
import { AppBar, Box, Grid, Stack, Tab, Tabs } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseImage from '../../../../../components/common/baseImage';
import { AudioPlayContext } from '../../../../../contexts/AudioPlayContext';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import { useECard } from '../../../../../hooks/useECard';
import {
    TableInfoKeyType,
    useTableInfo,
} from '../../../../../hooks/useTableInfo';
import { GameType } from '../../../../../models/games/enums/GameType';
import { BetType } from '../../../../../models/games/sedie/BetType';
import { RootState } from '../../../../../store/store';
import { ExchangeBetAmount } from '../../../../../utils/commonFunc';
import { limitRedFormatter } from '../../../../../utils/formatter';
import { getHostById } from '../../../../host/slice';
import { InGameRoadMap } from '../../../../lobby/components/Roadmaps';
import { REPORT_COLORS } from '../../../../lobby/constants';
import {
    getMainLimitRedState,
    getMainUserState,
} from '../../../../main/selector';
import { SedieECard } from '../../ECard/SedieECard';
import './SedieTableInfoPanel.scss';
import { SedieStatisticsTab } from './SedieWinStatisticsPanel';

type Props = {
    hostId: number;
};
export const SedieTableInfoPanel = (props: Props) => {
    const { hostId } = props;
    const { t } = useTranslation();
    const show = useECard(hostId);
    const { playButtonAudio } = useContext(AudioPlayContext);
    //useState
    const [tableInfoTab, setTableInfoTab] = useState<number>(0);

    const { tableInfo, setTableInfo } = useTableInfo();
    const ref = useRef<HTMLDivElement>(null);
    const [scrollObj, setScrollObj] = useState<BScrollConstructor>();

    const handleChange = (v: number) => {
        playButtonAudio();
        if (scrollObj) {
            setTableInfoTab(v);
            let gKey: TableInfoKeyType = 'roadmap';
            if (v === 1) {
                gKey = 'tableInfo';
            } else if (v === 2) {
                gKey = 'winning';
            }
            setTableInfo(gKey);
            scrollObj.goToPage(v, 0);
        }
    };

    useEffect(() => {
        if (ref && ref.current) {
            let numLsTab = 0;
            if (tableInfo === 'tableInfo') {
                numLsTab = 1;
            } else if (tableInfo === 'winning') {
                numLsTab = 2;
            } else {
                numLsTab = 0;
            }
            setTableInfoTab(numLsTab);
            BScroll.use(Slide);
            const newBScroll = new BScroll(ref.current as HTMLDivElement, {
                scrollX: true,
                scrollY: false,
                click: true,
                slide: {
                    loop: false,
                    autoplay: false,
                    threshold: 100,
                    startPageXIndex: numLsTab,
                },
                momentum: false,
            });
            newBScroll.on('slidePageChanged', (page: { pageX: number }) => {
                if (page) {
                    const tab = page.pageX;
                    setTableInfoTab(tab);
                    let gKey: TableInfoKeyType = 'roadmap';
                    if (tab === 1) {
                        gKey = 'tableInfo';
                    } else if (tab === 2) {
                        gKey = 'winning';
                    }
                    setTableInfo(gKey);
                }
            });
            setScrollObj(newBScroll);
        }
        return () => {
            if (ref && ref.current && scrollObj) {
                scrollObj.destroy();
            }
        };
    }, [ref]);
    const getRoundIcon = () => {
        return tableInfoTab === 2
            ? 'commonUI icn_50round'
            : 'commonUI icn_50round_deactivate';
    };
    return (
        <>
            <Box className="sedie-table-info">
                <AppBar>
                    <Tabs
                        className="sedie-table-info-tabs"
                        value={tableInfoTab}
                        onChange={(_, v) => {
                            handleChange(v);
                        }}
                        aria-label="roadmap & table info."
                        sx={{ '& Button': { color: REPORT_COLORS.TITLE_TEXT } }}
                    >
                        <Tab
                            className="sedie-table-info-tab"
                            label={t('road_map')}
                        />
                        <Tab
                            className="sedie-table-info-tab"
                            label={t('tableinfo.name')}
                        />
                        <Tab
                            className="sedie-table-info-tab"
                            label={t('tableinfo.win_stat')}
                            icon={
                                <>
                                    <Box
                                        sx={{
                                            paddingRight: '5px',
                                        }}
                                    >
                                        <BaseImage className={getRoundIcon()} />
                                    </Box>
                                </>
                            }
                            iconPosition="start"
                        />
                    </Tabs>
                </AppBar>
                <Box
                    ref={ref}
                    sx={{
                        overflow: 'hidden',
                    }}
                >
                    <Box sx={{ display: 'inline-block' }}>
                        <Box
                            sx={{
                                display: 'inline-block',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <InGameRoadMap
                                hostId={hostId}
                                gameType={GameType.SeDie}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'inline-block',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <SedieTableInfoTab />
                        </Box>
                        <Box
                            sx={{
                                display: 'inline-block',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <SedieStatisticsTab />
                        </Box>
                    </Box>
                </Box>
                <SedieECard show={show} />
            </Box>
        </>
    );
};

type InfoProps = {
    title: string;
    imageData?: {
        image: string;
        scale?: number;
        isMultiLang?: boolean;
    };
    value: string | number;
};
type TableInfoTypeRow1 = {
    table: InfoProps;
    dealer: InfoProps;
    round: InfoProps;
    id: InfoProps;
    tableLimit: InfoProps;
};
type TableInfoTypeRow2 = {
    betLimit: InfoProps;
    oddEven: InfoProps;
    bigSmall: InfoProps;
    fourWhite: InfoProps;
    threeWhite: InfoProps;
};
type TableInfoTypeRow3 = {
    twoRed: InfoProps;
    threeRed: InfoProps;
    fourRed: InfoProps;
    fourRedOrWhite: InfoProps;
};
type TableInfoType = [TableInfoTypeRow1, TableInfoTypeRow2, TableInfoTypeRow3];
const initTableInfoData = [
    {
        table: {
            title: 'tableinfo.table',
            value: '',
        },
        dealer: {
            title: 'tableinfo.dealer',
            value: '',
        },
        round: {
            title: 'tableinfo.shoe',
            value: '',
        },
        id: {
            title: 'tableinfo.round',
            value: '',
        },
        tableLimit: {
            title: 'tableinfo.tablelimit',
            value: '',
        },
    },
    {
        betLimit: {
            title: 'betLimit',
            value: '',
        },
        oddEven: {
            title: 'sedie.odd_even',
            value: '',
        },
        bigSmall: {
            title: 'sedie.big_small',
            value: '',
        },
        fourWhite: {
            imageData: {
                image: 'sedie_sheet sd_4w',
                scale: 0.3,
            },
            title: '',
            value: '',
        },
        threeWhite: {
            imageData: {
                image: 'sedie_sheet sd_3w1r',
                scale: 0.3,
            },
            title: '',
            value: '',
        },
    },
    {
        twoRed: {
            imageData: {
                image: 'sedie_sheet sd_2w2r',
                scale: 0.3,
            },
            title: '',
            value: '',
        },
        threeRed: {
            imageData: {
                image: 'sedie_sheet sd_1w3r',
                scale: 0.3,
            },
            title: '',
            value: '',
        },
        fourRed: {
            imageData: {
                image: 'sedie_sheet sd_4r',
                scale: 0.3,
            },
            title: '',
            value: '',
        },
        fourRedOrWhite: {
            imageData: {
                image: 'sedie_sheet sd_4w4r',
                scale: 0.3,
            },
            title: '',
            value: '',
        },
    },
] as TableInfoType;
const SedieTableInfoTab = () => {
    const { t } = useTranslation();
    const { hostId } = useContext(VideoPlayerContext);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { CurrentResult, DealerList, TableLimit, GameType, MaxBet } = host;
    const { ExchangeRate } = useSelector((state: RootState) =>
        getMainUserState(state)
    );
    const limitRedByGameType = useSelector(getMainLimitRedState);
    const [tableInfo, setTableInfo] =
        useState<TableInfoType>(initTableInfoData);
    const { CurrencyName } = useSelector(getMainUserState);

    const getMaxBetByBetType = (betType: number): string => {
        if (!MaxBet) return '0';
        const index = MaxBet.findIndex(d => {
            return d.BetType === betType;
        });
        return index !== -1
            ? (MaxBet[index].MaxBet / 100).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
              })
            : '0';
    };
    useEffect(() => {
        if (MaxBet) {
            let newTableInfo = Object.create(tableInfo);
            newTableInfo[1].oddEven.value = getMaxBetByBetType(BetType.SDBOdd);
            newTableInfo[1].bigSmall.value = getMaxBetByBetType(BetType.SDBBig);
            newTableInfo[1].fourWhite.value = getMaxBetByBetType(
                BetType.SDBFourWhite
            );
            newTableInfo[1].threeWhite.value = getMaxBetByBetType(
                BetType.SDBThreeWhite
            );
            //row 3
            newTableInfo[2].twoRed.value = getMaxBetByBetType(
                BetType.SDBTwoRedTwoWhite
            );
            newTableInfo[2].threeRed.value = getMaxBetByBetType(
                BetType.SDBThreeRed
            );
            newTableInfo[2].fourRed.value = getMaxBetByBetType(
                BetType.SDBFourRed
            );
            newTableInfo[2].fourRedOrWhite.value = getMaxBetByBetType(
                BetType.SDBFourRedOrWhite
            );
            setTableInfo(newTableInfo);
        }
    }, [MaxBet]);
    useEffect(() => {
        if (
            hostId &&
            DealerList &&
            TableLimit &&
            CurrentResult &&
            limitRedByGameType.BetRuleByGameType
        ) {
            let newTableInfo = Object.create(tableInfo);
            //row 1
            newTableInfo[0].table.value = t(`hostCodeNames.${hostId}`);
            newTableInfo[0].dealer.value = DealerList[0].DealerName;
            const shoes = Math.floor(CurrentResult.GameCount / 10000);
            const round = CurrentResult.GameCount % 10000;
            const row1Round = shoes && round ? `${shoes}-${round}` : round;
            newTableInfo[0].round.value = row1Round;
            newTableInfo[0].id.value = CurrentResult.GameID || 0;
            //row 2
            const selectedBetRule = limitRedByGameType.BetRuleByGameType.find(
                br => br.GameType === GameType
            )?.BetRule.find(r => r.Selected === 1);
            let betLimitV = '';
            if (selectedBetRule) {
                betLimitV = limitRedFormatter(selectedBetRule, CurrencyName);
            }
            newTableInfo[1].betLimit.value = betLimitV;
            let infoTableLimit =
                ExchangeBetAmount(TableLimit, ExchangeRate) / 100;
            const digi = Math.pow(10, infoTableLimit.toString().length - 1);
            const tableLimitValue = (
                Math.round(infoTableLimit / digi) * digi
            ).toLocaleString('en-US', {
                maximumFractionDigits: 2,
            });
            newTableInfo[0].tableLimit.value = tableLimitValue;
            setTableInfo(newTableInfo);
        }
    }, [
        hostId,
        CurrentResult,
        DealerList,
        TableLimit,
        limitRedByGameType.BetRuleByGameType,
    ]);
    return (
        <>
            {tableInfo && (
                <>
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        sx={{ fontSize: '14px' }}
                        height={'225px'}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                            }}
                        >
                            <BaseImage
                                className="tradbaccBg table_info_bg"
                                scale={1}
                                opacity={0.5}
                                // 18px = (196 - 161) / 2
                                // 1.226 = 196 / 161
                                transform={
                                    'translate(0, 17.5px) scale(1, 1.226)'
                                }
                            />
                        </Box>
                        {tableInfo.map((r, i) => {
                            const style =
                                i !== 1
                                    ? { width: '33%', zIndex: '1' }
                                    : {
                                          width: '34%',
                                          zIndex: '1',
                                          borderLeft:
                                              '2px solid rgba(255,255,255,0.2)',
                                          borderRight:
                                              '2px solid rgba(255,255,255,0.2)',
                                      };
                            return (
                                <Box key={`info-${i}`} sx={style}>
                                    <>
                                        {Object.keys(r).map((k, ii) => {
                                            const v = r[
                                                k as keyof typeof r
                                            ] as InfoProps;
                                            return (
                                                <Grid
                                                    key={`info-cell${ii}`}
                                                    item
                                                    container
                                                    justifyContent={
                                                        'space-between'
                                                    }
                                                    alignItems={'center'}
                                                    height={
                                                        i == 2
                                                            ? '49px'
                                                            : '39.5px'
                                                    }
                                                    padding={'0 7.5px 0 7.5px'}
                                                >
                                                    <Grid
                                                        item
                                                        sx={{
                                                            color: REPORT_COLORS.TITLE_TEXT,
                                                        }}
                                                    >
                                                        {v.imageData ? (
                                                            <BaseImage
                                                                className={
                                                                    v.imageData
                                                                        .image
                                                                }
                                                                isMultiLang={
                                                                    v.imageData
                                                                        .isMultiLang
                                                                }
                                                                scale={
                                                                    v.imageData
                                                                        .scale ??
                                                                    1
                                                                }
                                                            />
                                                        ) : (
                                                            t(v.title)
                                                        )}
                                                    </Grid>
                                                    <Grid item>{v.value}</Grid>
                                                </Grid>
                                            );
                                        })}
                                    </>
                                </Box>
                            );
                        })}
                    </Stack>
                </>
            )}
        </>
    );
};
