import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const enum MobPref {
    BACC_LOBBY_MODE,
    OTHER_LOBBY_MODE,
    BACC_BET_MODE,
    SICBO_BET_LAND_MODE,
    SICBO_BET_PORT_MODE,
    SICBO_BET_NUM_MODE,
    ROUL_BET_MODE,
    FANTAN_BET_PORT_MODE,
    BACC_ROADMAP_MODE,
    MW_ROADMAP_MODE,
    SICBO_ROADMAP_PORT_MODE,
    ROUL_ROADMAP_PORT_MODE,
    FANTAN_ROADMAP_PORT_MODE,
    DT_ROADMAP_MODE,
    BET_POOL_OPEN,
    GAME_MENU_TAB,
    ANDAR_BAHAR_BET_MODE,
    SEDIE_ROADMAP_PORT_MODE,
}

export const LocalStorageKey = {
    FullParameter: 'fullParameter',
    ReconnectKey: window.btoa('nrk'), // = 'cms='
    Logger: 'logger',
    ClearReason: 'clearReason',
};

const getLocalStorageValue = <T>(key: string, defaultValue: T): T => {
    const savedValue = localStorage.getItem(key);
    try {
        if (savedValue) {
            const initialValue = JSON.parse(savedValue);
            return (initialValue as T) || defaultValue;
        }
    } catch {
        console.warn(
            'app::hook::localstorage',
            'use default value',
            key,
            defaultValue
        );
    }
    return null || defaultValue;
};
const setLocalStorageValue = <T>(key: string, value: T): void => {
    if (!value) return;
    localStorage.setItem(key, JSON.stringify(value));
};

export const useLocalStorage = <T>(
    key: string,
    defaultValue: T
): [T, Dispatch<SetStateAction<T>>] => {
    const [value, setValue] = useState<T>(() =>
        getLocalStorageValue<T>(key, defaultValue)
    );
    useEffect(() => {
        setLocalStorageValue(key, value);
    }, [key, value]);
    return [value, setValue];
};
