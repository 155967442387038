import { Stack } from "@mui/material";
import BaseImage from "../../../../components/common/baseImage";
import { Bet } from "../../../report/slice/betRecord";

export const SicBoResultRecordPanel = ({bet}: {bet: Bet}) => {
    const dices: number[] = bet.Result.split(',').map(str => Number(str));
    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{'& div': {
                    marginLeft: '6px'
                }}}
            >
                {dices.map(d => {
                    return (
                        <BaseImage
                            key={`dice-${d}`}
                            className={`dices_stroke dice${d}`}
                            scale={1}
                        />
                    );
                })}
            </Stack>
        </>
    );
}