import { RootState } from '../../store/store';
import { REPORT_KEY, ReportState } from './slice';
import { BET_RECORD_KEY, BetRecordState } from './slice/betRecord';
import { BET_STATISTICS_KEY, BetStatisticsState } from './slice/betStatistics';

export const getReportState = (state: RootState): ReportState =>
    state[REPORT_KEY];
export const getBetStatisticsReportState = (
    state: RootState
): BetStatisticsState => getReportState(state)[BET_STATISTICS_KEY];
export const getBetRecordReportState = (state: RootState): BetRecordState =>
    getReportState(state)[BET_RECORD_KEY];
