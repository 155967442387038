import { Stack, Grid, Box } from '@mui/material';
import BaseImage from '../../../../../components/common/baseImage';
import { Bet } from '../../../../report/slice/betRecord';
import { useTranslation } from 'react-i18next';

export type AndarBaharResultRecordPanelProps = {
    bet: Bet;
};
export const AndarBaharResultRecordPanel = (props: AndarBaharResultRecordPanelProps) => {
    const { t } = useTranslation();
    const { bet } = props;
    
    const cards: number[] = bet.Result.split(',').map(str => Number(str));
    const andars:number[] = [];
    const bahars:number[] = [];
    for(let i = 1; i < cards.length;i++){
        if(i % 2 == 1){
            andars.push(cards[i]);
        }else {
            bahars.push(cards[i]);
        }
    }
    const getWinCard = () => {
        if(andars.length == bahars.length){
            return {
                title:t('andar_bahar.bahar'),
                card:bahars[bahars.length -1]
            };
        }
        return {
            title:t('andar_bahar.andar'),
            card:andars[andars.length -1]
        }
    }

    return (
        <Stack
            direction="column"
            justifyContent="space-between"
            alignItems={'flex-end'}
            sx={{ height: '86px' }}
        >
            <DetailResult lbl={getWinCard().title} cards={[getWinCard().card]} />
            <DetailResult lbl={t('andar_bahar.game_card')} cards={[cards[0]]} />
        </Stack>
    )
};

export const DetailResult = ({
    lbl,
    cards,
}: {
    lbl: string;
    cards: number[];
}) => {
    return (
        <>
            <Stack direction="row" alignItems="center">
                <Grid
                    item
                    sx={{
                        marginRight: '3px',
                        marginBottom: '5px',
                    }}
                >
                    {lbl}
                </Grid>
                {cards.map(c => {
                    const suit = Math.floor(c / 13);
                    const rank = c % 13;
                    return (
                        <Grid key={`card-${c}`} item>
                            <Stack direction="row">
                                <BaseImage className="cardrank empty" />
                                <BaseImage
                                    className={`cardsuit c${suit}`}
                                    scale={1}
                                />
                                <Box sx={{ marginLeft: '-1px' }}>
                                    {suit % 2 == 0 ? (
                                        <BaseImage
                                            className={`cardrank c${rank}`}
                                            scale={1}
                                        />
                                    ) : (
                                        <BaseImage
                                            className={`cardrankred c${rank}`}
                                            scale={1}
                                        />
                                    )}
                                </Box>
                            </Stack>
                        </Grid>
                    );
                })}
            </Stack>
        </>
    );
};