import {
    Description as DescriptionIcon,
    Help as HelpIcon,
    Mail as MailIcon,
    PowerSettingsNew as PowerSettingsNewIcon,
    QueryBuilder as QueryBuilderIcon,
    Settings as SettingsIcon,
    Lock as LockIcon,
} from '@mui/icons-material';
import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    SxProps,
    Theme,
} from '@mui/material';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonConfigContext } from '../../../contexts/ConfigContext';
import { useDispatch, useSelector } from 'react-redux';
import { theme } from '../../../theme';
import { popupSliceActions } from '../../popup/slice';
import { POPUP_TYPE } from '../../../models/Popup';
import { RootState } from '../../../store/store';
import { getMainUserState, getPromotionToolState } from '../../main/selector';
import { LobbyType as LobbyTypeEnum } from '../../../models/LobbyType';
import { promotionToolSliceActions } from '../../main/slice/promotiontool/promotionTool';
import { PromotionPage } from '../../popup/promotiontool/components/PromotionDialog';

export type SettingMenuDialogProps = {
    setIsBetRecordOpen: Dispatch<SetStateAction<boolean>>;
    setIsMemberReportOpen: Dispatch<SetStateAction<boolean>>;
    setIsGameRulesOpen: Dispatch<SetStateAction<boolean>>;
    setIsUserSettingOpen: Dispatch<SetStateAction<boolean>>;
    setIsFeedBackOpen: Dispatch<SetStateAction<boolean>>;
    setIsChangePasswordOpen: Dispatch<SetStateAction<boolean>>;
    playButtonAudio: () => void;
};

export const SettingMenu = (props: SettingMenuDialogProps) => {
    const {
        setIsBetRecordOpen,
        setIsMemberReportOpen,
        setIsGameRulesOpen,
        setIsUserSettingOpen,
        setIsFeedBackOpen,
        setIsChangePasswordOpen,
        playButtonAudio,
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { disableLogoutLobby, hideFeedback } =
        useContext(CommonConfigContext);
    const { LobbyType } = useSelector((state: RootState) =>
        getMainUserState(state)
    );
    const isShowEvent = useSelector(
        (state: RootState) => getPromotionToolState(state).Id > 0
    );
    const logout = () => {
        dispatch(
            popupSliceActions.open(
                'system.logout_confirm',
                undefined,
                POPUP_TYPE.CONFIRM,
                undefined,
                true
            )
        );
    };
    const activeStyle: SxProps<Theme> = {
        ':active': {
            backgroundColor: 'secondary.main',
        },
    };

    const iconStyle = {
        color: theme.palette.text.primary,
        width: 30,
        height: 30,
    };
    return (
        <>
            {isShowEvent ? (
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => {
                            dispatch(
                                promotionToolSliceActions.updatePage(
                                    PromotionPage.Gacha
                                )
                            );
                            dispatch(
                                promotionToolSliceActions.isOpenDialog(true)
                            );
                            playButtonAudio();
                        }}
                        sx={activeStyle}
                    >
                        <ListItemIcon>
                            <svg
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14.9983 7.5L16.4307 12.5191L21.4935 11.25L17.863 15L21.4935 18.75L16.4307 17.481L14.9983 22.5001L13.5659 17.481L8.5031 18.75L12.1335 15L8.5031 11.25L13.5659 12.5191L14.9983 7.5Z"
                                    fill="white"
                                />
                                <path
                                    d="M1.5271 15L8.3888 3.80209H21.6112L28.4729 15L21.6112 26.198H8.3888L1.5271 15Z"
                                    stroke="white"
                                    strokeWidth="2.60417"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </ListItemIcon>
                        <ListItemText
                            primary={t('promotion_tool.explore_rewards')}
                        />
                    </ListItemButton>
                </ListItem>
            ) : (
                <></>
            )}
            <ListItem disablePadding>
                <ListItemButton
                    onClick={() => {
                        setIsBetRecordOpen(true);
                        playButtonAudio();
                    }}
                    sx={activeStyle}
                >
                    <ListItemIcon>
                        <QueryBuilderIcon sx={iconStyle} />
                    </ListItemIcon>
                    <ListItemText primary={t('menu.bet_record')} />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton
                    onClick={() => {
                        setIsMemberReportOpen(true);
                        playButtonAudio();
                    }}
                    sx={activeStyle}
                >
                    <ListItemIcon>
                        <DescriptionIcon sx={iconStyle} />
                    </ListItemIcon>
                    <ListItemText primary={t('menu.member_report')} />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton
                    onClick={() => {
                        setIsGameRulesOpen(true);
                        playButtonAudio();
                    }}
                    sx={activeStyle}
                >
                    <ListItemIcon>
                        <HelpIcon sx={iconStyle} />
                    </ListItemIcon>
                    <ListItemText primary={t('menu.game_rules')} />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton
                    onClick={() => {
                        setIsUserSettingOpen(true);
                        playButtonAudio();
                    }}
                    sx={activeStyle}
                >
                    <ListItemIcon>
                        <SettingsIcon sx={iconStyle} />
                    </ListItemIcon>
                    <ListItemText primary={t('menu.setting')} />
                </ListItemButton>
            </ListItem>
            {LobbyType == LobbyTypeEnum.Credit && (
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => {
                            setIsChangePasswordOpen(true);
                            playButtonAudio();
                        }}
                        sx={activeStyle}
                    >
                        <ListItemIcon>
                            <LockIcon sx={iconStyle} />
                        </ListItemIcon>
                        <ListItemText primary={t('menu.change_password')} />
                    </ListItemButton>
                </ListItem>
            )}
            {!hideFeedback && (
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => {
                            setIsFeedBackOpen(true);
                            playButtonAudio();
                        }}
                        sx={activeStyle}
                    >
                        <ListItemIcon>
                            <MailIcon sx={iconStyle} />
                        </ListItemIcon>
                        <ListItemText primary={t('menu.feedback')} />
                    </ListItemButton>
                </ListItem>
            )}
            {!disableLogoutLobby && (
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => {
                            logout();
                            playButtonAudio();
                        }}
                        sx={activeStyle}
                    >
                        <ListItemIcon>
                            <PowerSettingsNewIcon sx={iconStyle} />
                        </ListItemIcon>
                        <ListItemText primary={t('menu.logout')} />
                    </ListItemButton>
                </ListItem>
            )}
        </>
    );
};
