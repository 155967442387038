import { useEffect, useMemo, useState } from 'react';
import BaseImage from '../../../components/common/baseImage';
import { ROAD_MAP_TYPE } from '../../lobby/constants';
import './index.scss';
import BeadRoadCell from './cells/BeadRoadCell';
import BigRoadCell from './cells/BigRoadCell';
import { useRoadMapState } from './hook';
import { getMappedRoad } from './util';
import TrialImage from '../../game/components/TrialImage';
type TeenPattiRoadMapProps = {
    hostId: number;
};
export const TeenPattiRoadMap = (props: TeenPattiRoadMapProps) => {
    const { hostId } = props;
    const {
        beadRoadRecord,
        setBeadRoadRecord,
        bigRoadRecord,
        setBigRoadRecord,
        isOneColumn,
        beadRoad,
        IsRest,
    } = useRoadMapState(hostId);

    const containerClass = useMemo(
        () =>
            isOneColumn
                ? 'teenpatti-oneColumnMainContainer'
                : 'teenpatti-twoColumnMainContainer',
        [isOneColumn]
    );
    const roadMapClass = useMemo(
        () => (isOneColumn ? 'road_andarBahar' : 'road_viewColumn'),
        [isOneColumn]
    );
    const beadRR = useMemo(
        () => getMappedRoad(beadRoad, ROAD_MAP_TYPE.BEAD_ROAD, 6, 6),
        [beadRoad]
    );
    const bigRR = useMemo(
        () =>
            getMappedRoad(
                beadRoad,
                ROAD_MAP_TYPE.BIG_ROAD,
                isOneColumn ? 10 : 16,
                6
            ),
        [beadRoad, isOneColumn]
    );

    useEffect(() => {
        if (!IsRest) {
            setBeadRoadRecord(beadRR);
            setBigRoadRecord(bigRR);
        } else {
            setBeadRoadRecord(undefined);
            setBigRoadRecord(undefined);
        }
    }, [beadRoad, isOneColumn, IsRest]);
    return (
        <div className={containerClass}>
            <BaseImage
                className={`lobby ${roadMapClass}`}
                scale={isOneColumn ? 1.12 : 1}
            />
            {isOneColumn && (
                <div className="teenpatti-beadRoad">
                    {beadRoadRecord?.map((record, idx) => (
                        <BeadRoadCell
                            size={isOneColumn ? 32 : 16}
                            record={record}
                            key={`bead-road-cell-${idx}`}
                        />
                    ))}
                </div>
            )}
            <div className="teenpatti-evenOddRoad">
                {bigRoadRecord?.map((record, idx) => (
                    <BigRoadCell
                        size={isOneColumn ? 31.7 : 16}
                        record={record}
                        key={`even-odd-road-cell-${idx}`}
                    />
                ))}
            </div>
        </div>
    );
};

export const TeenPattiSwitchTableMap = (props: TeenPattiRoadMapProps) => {
    const { hostId } = props;
    const { beadRoad, IsRest } = useRoadMapState(hostId);
    const [bigRoadCells, setBigRoadCells] = useState<JSX.Element>(<></>);

    useEffect(() => {
        const cells = new Array<JSX.Element>();
        if (!IsRest) {
            const bigRR = getMappedRoad(
                beadRoad,
                ROAD_MAP_TYPE.BIG_ROAD,
                11,
                6
            );

            let index = 0;
            for (const record of bigRR) {
                const cell = (
                    <BigRoadCell
                        size={15}
                        record={record}
                        key={`big-road-cell-${index}`}
                    />
                );
                cells.push(cell);
                index++;
            }
        }
        setBigRoadCells(<>{cells}</>);
    }, [beadRoad, IsRest]);

    return (
        <div className="teenpatti-SwitchTableMapMainContainer">
            <BaseImage
                className="tradbaccRoadmapBg hori_allroad_ver2_android"
                scale={1}
            />
            <div className="teenpatti-evenOddRoad">{bigRoadCells}</div>
        </div>
    );
};

export const TeenPattiInGameMap = (props: TeenPattiRoadMapProps) => {
    const { hostId } = props;

    return (
        <div className="teenpatti-TeenPattiInGameMainContainer">
            <BaseImage className={'lobby road_andarBahar'} scale={1.2} />
            <div className="teenpatti-beadRoad">
                <TeenPattiBeadRoadMap hostId={hostId} />
            </div>

            <div className="teenpatti-bigRoad">
                <TeenPattiBigRoadMap hostId={hostId} />
            </div>
            <TrialImage />
        </div>
    );
};

const TeenPattiBeadRoadMap = (props: TeenPattiRoadMapProps) => {
    const { hostId } = props;
    const { beadRoadRecord, setBeadRoadRecord, beadRoad, IsRest } =
        useRoadMapState(hostId);
    const beadRR = useMemo(
        () => getMappedRoad(beadRoad, ROAD_MAP_TYPE.BEAD_ROAD, 6, 6),
        [beadRoad]
    );

    useEffect(() => {
        if (!IsRest) {
            setBeadRoadRecord(beadRR);
        } else {
            setBeadRoadRecord(undefined);
        }
    }, [beadRoad, IsRest]);

    return (
        <div className="teenpatti-bead-road">
            {beadRoadRecord?.map((record, idx) => (
                <BeadRoadCell
                    size={32}
                    record={record}
                    key={`bead-road-cell-${idx}`}
                />
            ))}
        </div>
    );
};

const TeenPattiBigRoadMap = (props: TeenPattiRoadMapProps) => {
    const { hostId } = props;
    const { bigRoadRecord, setBigRoadRecord, beadRoad, IsRest } =
        useRoadMapState(hostId);
    const bigSmallRR = useMemo(
        () => getMappedRoad(beadRoad, ROAD_MAP_TYPE.BIG_SMALL_ROAD, 9, 6),
        [beadRoad]
    );

    useEffect(() => {
        if (!IsRest) {
            setBigRoadRecord(bigSmallRR);
        } else {
            setBigRoadRecord(undefined);
        }
    }, [beadRoad, IsRest]);

    return (
        <div className="teenpatti-big-road">
            {bigRoadRecord?.map((record, idx) => (
                <BigRoadCell
                    size={32.2}
                    record={record}
                    key={`big-small-road-cell-${idx}`}
                />
            ))}
        </div>
    );
};
