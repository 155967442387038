import { memo } from 'react';
import { SicBoRoadMapCellProps } from '../hook';
import BaseImage from '../../../../components/common/baseImage';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const HistoryCell = (props: SicBoRoadMapCellProps) => {
    const { record, isOneColumn, isLast } = props;
    const { t } = useTranslation();
    //dices_stroke_json.dice1 //25*25
    return (
        <Grid
            container
            className={`column ${isLast && 'last'}`}
            direction={'column'}
        >
            <Grid
                item
                container
                direction={'column'}
                flexWrap={'nowrap'}
                alignItems={'center'}
                alignContent={'center'}
                // justifyContent={'center'}
                className="column-top"
            >
                {record && (
                    <>
                        <BaseImage
                            className={`dices_stroke dice${record.Dice1}`}
                            scale={isOneColumn ? 0.5 : 0.4}
                        />
                        <BaseImage
                            className={`dices_stroke dice${record.Dice2}`}
                            scale={isOneColumn ? 0.5 : 0.4}
                        />
                        <BaseImage
                            className={`dices_stroke dice${record.Dice3}`}
                            scale={isOneColumn ? 0.5 : 0.4}
                        />
                        {isOneColumn && record.PointResult}
                    </>
                )}
            </Grid>
            <Grid item className="column-bottom">
                {record && (
                    <>
                        {isOneColumn &&
                            (record.OddEventRResult !== 'dice_triple' ||
                                record.BigSmallResult !== 'dice_triple') && (
                                <Grid
                                    item
                                    container
                                    alignItems={'center'}
                                    alignContent={'center'}
                                    justifyContent={'center'}
                                    className={record.OddEventRResult}
                                >
                                    {t(`road.${record.OddEventRResult}`)}
                                </Grid>
                            )}
                        <Grid
                            item
                            container
                            alignItems={'center'}
                            alignContent={'center'}
                            justifyContent={'center'}
                            className={record.BigSmallResult}
                        >
                            {t(`road.${record.BigSmallResult}`)}
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
};
export default memo(HistoryCell);
