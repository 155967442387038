import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SeatAreaContext } from '../contexts/SeatAreaContext';
import { BetRoomSeatIndex } from '../models/games/BetRoom';
import {
    BlackjackBetType,
    BlackjackDecision,
    BlackjackHistoryInfo,
} from '../models/games/blackjack';
import { BetResultKey } from '../models/games/blackjack/BetResult';
import { GameState as CommonState } from '../models/games/enums/GameState';
import { Bet } from '../models/host/BetAmount';
import { BetPlayer, getPlayer } from '../modules/bet/players/slice';
import { getMainUserState } from '../modules/main/selector';
import store, { RootState } from '../store/store';
import { ExchangeBetAmountByCurrency, calcLowHigh } from '../utils/commonFunc';
import { getBlackjackPoints } from '../utils/games/blackjack';

export const useSeatBet = (sn?: BetRoomSeatIndex) => {
    // bet amount
    const [split1Amount, setSplit1Amount] = useState(0);
    const [split2Amount, setSplit2Amount] = useState(0);
    const [pairAmount, setPairAmount] = useState(0);
    // bet behind
    const [behindSplit1Amount, setBehindSplit1Amount] = useState(0);
    const [behindSplit2Amount, setBehindSplit2Amount] = useState(0);
    const [behindPairAmount, setBehindPairAmount] = useState(0);
    const [isBehind, setIsBehind] = useState<BetRoomSeatIndex>();
    // result
    const [hand1Point, setHand1Point] = useState<Array<number>>([]);
    const [hand2Point, setHand2Point] = useState<Array<number>>([]);
    const [decision, setDecision] = useState(BlackjackDecision.MaxDecision);
    const [boughtInsurance, setBoughtInsurance] = useState(false);
    const [isBJ, setIsBJ] = useState(false);

    const [isHand1, setIsHand1] = useState(false);
    const [isHand2, setIsHand2] = useState(false);

    const [player, setPlayer] = useState<BetPlayer>();
    const [bets, setBets] = useState<Array<Bet>>([]);
    const [behindBet, setBehindBet] = useState<Array<Bet>>([]);

    const [isMainWin, setIsMainWin] = useState(false);
    const [isPairWin, setIsPairWin] = useState(false);

    const {
        playerResult,
        currentState,
        room,
        currentSeat,
        handNumber,
        inSeat,
        betResult,
    } = useContext(SeatAreaContext);
    const { CurrencyList, CurrencyType, ExchangeRate } = useSelector(
        (state: RootState) => getMainUserState(state)
    );
    useEffect(() => {
        if (room && sn) {
            const info = room.Info[sn];
            if (info) {
                const state = store.getState();
                const p = getPlayer(state, info.PlayerId);
                const b = info.Bet;

                setPlayer(p);
                setBets(b);
            }
        }
        setBehindBet(room?.Info[0].Bet ?? []);
    }, [sn, room]);
    useEffect(() => {
        if (sn) {
            const h1 = currentSeat === sn && handNumber === 1;
            const h2 = currentSeat === sn && handNumber === 2;
            setIsHand1(h1);
            setIsHand2(h2);
        }
    }, [sn, currentSeat, handNumber]);

    useEffect(() => {
        if (
            currentState !== undefined &&
            currentState > CommonState.GameResult
        ) {
            setSplit1Amount(0);
            setSplit2Amount(0);
            setPairAmount(0);
            setBehindSplit1Amount(0);
            setBehindSplit2Amount(0);
            setBehindPairAmount(0);
            setHand1Point([]);
            setHand2Point([]);
            setBoughtInsurance(false);
            setIsBehind(undefined);
            setIsMainWin(false);
            setIsPairWin(false);
        }
    }, [currentState]);

    useEffect(() => {
        if (sn) {
            const currentSeatResult = playerResult?.find(pr => pr.SN === sn);
            if (currentSeatResult) {
                const hand1 = currentSeatResult.H[0];
                const hand2 = currentSeatResult.H[1];
                const isSplitted = currentSeatResult.H.length === 2;
                if (hand1) {
                    const hand = currentSeatResult.H[0];
                    const points = getBlackjackPoints(hand.PK, !isSplitted);
                    setHand1Point(points);
                    setIsBJ(points.at(0) === 255);
                }
                if (hand2) {
                    const hand = currentSeatResult.H[1];
                    const points = getBlackjackPoints(hand.PK, !isSplitted);
                    setHand2Point(points);
                    setIsBJ(false);
                }
                setDecision(currentSeatResult.D);
            }
        }
    }, [sn, playerResult, inSeat]);

    useEffect(() => {
        let exchangeRate = ExchangeRate;
        let split1 = 0;
        let split2 = 0;
        let pair = 0;
        let behindSplit1 = 0;
        let behindSplit2 = 0;
        let behindPair = 0;

        if (sn) {
            const split1MainBet = BlackjackBetType[`BJB${sn}_Split1`];
            const split2MainBet = BlackjackBetType[`BJB${sn}_Split2`];
            const doubleDownBet = BlackjackBetType[`BJB${sn}_DoubleDown`];
            const pairBet = BlackjackBetType[`BJB${sn}_Pair`];

            if (behindBet.length > 0) {
                setIsBehind(sn);
                for (const bet of behindBet) {
                    switch (bet.Type) {
                        case split1MainBet:
                        case doubleDownBet:
                            behindSplit1 += bet.Amount;
                            break;
                        case split2MainBet:
                            behindSplit2 += bet.Amount;
                            break;
                        case pairBet:
                            behindPair += bet.Amount;
                            break;
                    }
                }
            }

            if (bets && player) {
                // exchange the currency rate
                const playerCurrencyType = calcLowHigh(player.currencyType);
                if (playerCurrencyType !== CurrencyType) {
                    const currency = CurrencyList.find(
                        c => c.CurrencyType === playerCurrencyType
                    );
                    if (currency) exchangeRate = currency.ExchangeRate;
                }
                for (const bet of bets) {
                    switch (bet.Type) {
                        case BlackjackBetType.BJB_Insurance:
                            setBoughtInsurance(true);
                            break;
                        case split1MainBet:
                        case doubleDownBet:
                            split1 += ExchangeBetAmountByCurrency(
                                bet.Amount,
                                exchangeRate,
                                ExchangeRate
                            );
                            break;
                        case split2MainBet:
                            split2 += ExchangeBetAmountByCurrency(
                                bet.Amount,
                                exchangeRate,
                                ExchangeRate
                            );
                            break;
                        case pairBet:
                            pair += ExchangeBetAmountByCurrency(
                                bet.Amount,
                                exchangeRate,
                                ExchangeRate
                            );
                            break;
                    }
                }
            }
        }
        setSplit1Amount(split1);
        setSplit2Amount(split2);
        setPairAmount(pair);
        setBehindSplit1Amount(behindSplit1);
        setBehindSplit2Amount(behindSplit2);
        setBehindPairAmount(behindPair);
    }, [sn, bets, behindBet, player]);
    useEffect(() => {
        if (betResult && sn) {
            const keyS1: BetResultKey = `BJR${sn}_S1`;
            const keyS2: BetResultKey = `BJR${sn}_S2`;

            const mw =
                betResult[keyS1] === BlackjackHistoryInfo.WIN ||
                betResult[keyS2] === BlackjackHistoryInfo.WIN;

            if (mw) {
                const hand = playerResult.find(p => p.SN === sn)?.H;
                if (hand) {
                    const card1 = hand[0].PK[0];
                    const card2 =
                        hand.length === 2 ? hand[1].PK[0] : hand[0].PK[1];
                    if (card1 === card2) {
                        setIsPairWin(true);
                    }
                }
            }
            setIsMainWin(mw);
        }
    }, [betResult]);
    return {
        split1Amount,
        split2Amount,
        pairAmount,

        behindSplit1Amount,
        behindSplit2Amount,
        behindPairAmount,
        isBehind,

        hand1Point,
        hand2Point,
        decision,
        player,
        boughtInsurance,
        isHand1,
        isHand2,

        isPairWin,
        isMainWin,
        isBJ,
    };
};
