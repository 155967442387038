import {
    Close as CloseIcon,
    Description as DescriptionIcon,
    Lock as LockIcon,
    Mail as MailIcon,
    QueryBuilder as QueryBuilderIcon,
    Settings as SettingsIcon,
} from '@mui/icons-material';
import {
    Divider,
    Drawer,
    Grid,
    IconButton,
    List,
    Button as MuiButton,
    Stack,
    SxProps,
    Theme,
    Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import packageJson from '../../../package.json';
import BaseImage from '../../components/common/baseImage';
import { AudioPlayContext } from '../../contexts/AudioPlayContext';
import { RootState } from '../../store/store';
import { gotoNewUI } from '../../utils/commonFunc';
import { getLandingState, getPromotionToolState } from '../main/selector';
import { BetRecord } from './components/BetRecord';
import { MemberReport } from './components/MemberReport';
import { SettingMenu } from './components/SettingMenu';
import { UserSetting } from './components/UserSetting';
import ChangePassword from './components/changePassword';
import { FeedbackForm } from './components/feedbackForm';
import { GameRules } from './components/gameRules';
import { SettingDialog } from './components/settingDialog';
import { UserInformation } from './components/userInformation';
import './index.scss';
import { getSettingState } from './selector';
type SettingProps = {
    isLobby?: boolean;
};

export const Setting = (props: SettingProps) => {
    const { isLobby } = props;
    const { playButtonAudio } = useContext(AudioPlayContext);
    const [isOpen, setIsOpen] = useState(false);
    const [isBetRecordOpen, setIsBetRecordOpen] = useState(false);
    const [isMemberReportOpen, setIsMemberReportOpen] = useState(false);
    const [isGameRulesOpen, setIsGameRulesOpen] = useState(false);
    const [isUserSettingOpen, setIsUserSettingOpen] = useState(false);
    const [isFeedBackOpen, setIsFeedBackOpen] = useState(false);
    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
    const IconTheme: SxProps<Theme> = { mr: 2, color: 'inherit' };
    const userSettingState = useSelector(getSettingState);
    const isOpenPromotionDialog = useSelector((state: RootState) =>
        getPromotionToolState(state)
    ).IsOpenDialog;
    const [closeSetting, setCloseSetting] = useState(0);
    const { showNewUI, username } = useSelector(getLandingState);
    const { t } = useTranslation();
    // const screenRatio = ResizeScreenRatio();
    useEffect(() => {
        if (!isUserSettingOpen) {
            // when close setting page, save setting to  local storage
            const json = JSON.stringify(userSettingState);
            console.log('app::main::setting', json);
            window.localStorage.setItem('user.setting', json);
            setIsOpen(false);
        }
    }, [isUserSettingOpen]);

    useEffect(() => {
        if (isOpenPromotionDialog) {
            setIsOpen(false);
        }
    }, [isOpenPromotionDialog]);

    return (
        <>
            {/* <Divider
                orientation="vertical"
                flexItem
                sx={{ borderColor: 'rgba(255, 255, 255, 0.11)' }}
            /> */}
            <div
                className={
                    isLobby ? 'LobbySettingContainer' : 'SettingContainer'
                }
            >
                <MuiButton
                    onClick={() => {
                        setIsOpen(true);
                        playButtonAudio();
                    }}
                    sx={{ borderLeft: '2px solid rgba(255, 255, 255, 0.11)' }}
                >
                    <BaseImage className="commonUI ic_menu_brown" scale={1} />
                </MuiButton>

                {/* setting menu */}
                <Drawer
                    // container={() =>
                    //     document.getElementById('UpperLayerContainer')
                    // }
                    variant="temporary"
                    anchor="right"
                    open={isOpen}
                    className="SettingDrawer"
                    onClose={() => setIsOpen(false)}
                >
                    <List className="SettingList">
                        <Stack direction={'row'}>
                            <UserInformation
                                isHeader={false}
                                isInGame={false}
                            />
                            <IconButton
                                onClick={() => {
                                    setIsOpen(false);
                                    playButtonAudio();
                                }}
                                sx={{
                                    marginBottom: 'auto',
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                        <Divider
                            sx={{
                                borderWidth: '3px',
                                borderStyle: 'solid',
                                borderColor: 'rgba(255,255,255,0.3)',
                            }}
                        />
                        <SettingMenu
                            setIsBetRecordOpen={setIsBetRecordOpen}
                            setIsMemberReportOpen={setIsMemberReportOpen}
                            setIsGameRulesOpen={setIsGameRulesOpen}
                            setIsUserSettingOpen={setIsUserSettingOpen}
                            setIsFeedBackOpen={setIsFeedBackOpen}
                            setIsChangePasswordOpen={setIsChangePasswordOpen}
                            playButtonAudio={playButtonAudio}
                        />
                    </List>
                    <Grid
                        sx={{
                            width: '100%',
                            marginLeft: 'auto',
                            marginTop: 'auto',
                            display: 'flex',
                            justifyContent: showNewUI ? 'space-between' : 'end',
                        }}
                    >
                        {showNewUI && (
                            <IconButton
                                sx={{ padding: '20px' }}
                                disableRipple={true}
                                onClick={() => {
                                    gotoNewUI(username);
                                }}
                            >
                                <Typography>
                                    <div
                                        style={{
                                            color: 'rgba(200, 162, 115, 1)',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        {t('new_ui.new_experience')}
                                    </div>
                                </Typography>
                            </IconButton>
                        )}
                        <Typography>v{packageJson.version}</Typography>
                    </Grid>
                </Drawer>
                {/* bet record report */}
                <SettingDialog
                    isOpen={isBetRecordOpen}
                    setIsOpen={setIsBetRecordOpen}
                    onClose={() => {
                        setIsOpen(false);
                        playButtonAudio();
                    }}
                    title={'menu.bet_record'}
                    icon={<QueryBuilderIcon sx={IconTheme} />}
                >
                    <BetRecord
                        setIsOpen={setIsOpen}
                        setIsBetRecordOpen={setIsBetRecordOpen}
                    />
                </SettingDialog>
                {/* member report */}
                <SettingDialog
                    isOpen={isMemberReportOpen}
                    setIsOpen={setIsMemberReportOpen}
                    onClose={() => {
                        setIsOpen(false);
                        playButtonAudio();
                    }}
                    title={'menu.member_report'}
                    icon={<DescriptionIcon sx={IconTheme} />}
                >
                    <MemberReport />
                </SettingDialog>
                {/* Game Rules popup | iframe */}
                <SettingDialog
                    isOpen={isGameRulesOpen}
                    setIsOpen={setIsGameRulesOpen}
                    onClose={() => {
                        setIsOpen(false);
                        playButtonAudio();
                    }}
                    title={'menu.game_rules'}
                    icon={<DescriptionIcon sx={IconTheme} />}
                >
                    <GameRules
                        setIsOpen={setIsGameRulesOpen}
                        playButtonAudio={playButtonAudio}
                    />
                </SettingDialog>
                {/* user setting */}
                <SettingDialog
                    isOpen={isUserSettingOpen}
                    setIsOpen={setIsUserSettingOpen}
                    onClose={() => {
                        setIsOpen(false);
                        playButtonAudio();
                        setCloseSetting(closeSetting + 1);
                    }}
                    title={'menu.setting'}
                    icon={<SettingsIcon sx={IconTheme} />}
                >
                    <UserSetting
                        setIsOpen={setIsUserSettingOpen}
                        closeSetting={closeSetting}
                    />
                </SettingDialog>
                {/*
                    feedback form 
                    common_config.xml -> hide -> feedback
                */}
                <SettingDialog
                    isOpen={isFeedBackOpen}
                    setIsOpen={setIsFeedBackOpen}
                    onClose={() => {
                        setIsOpen(false);
                        playButtonAudio();
                    }}
                    title={'menu.feedback'}
                    icon={<MailIcon sx={IconTheme} />}
                >
                    <FeedbackForm setIsOpen={setIsFeedBackOpen} />
                </SettingDialog>
                <SettingDialog
                    isOpen={isChangePasswordOpen}
                    setIsOpen={setIsChangePasswordOpen}
                    onClose={() => {
                        setIsOpen(false);
                        playButtonAudio();
                    }}
                    title={'menu.change_password'}
                    icon={<LockIcon sx={IconTheme} />}
                >
                    <ChangePassword setIsOpen={setIsChangePasswordOpen} />
                </SettingDialog>
            </div>
        </>
    );
};
