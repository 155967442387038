import { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { AndarBaharRecord } from '../../../../../models/games/andarBahar';
import { getPokerByIndex } from '../../../../../utils/commonFunc';

type AndarBaharLatest30RecordsPagerProps = {
    recs: AndarBaharRecord[]|undefined;
};
export const AndarBaharLatest30RecordsPager = ({
    recs,
}: AndarBaharLatest30RecordsPagerProps) => {
    const carouselRef = useRef<HTMLDivElement>(null);
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    const [pageList, setPageList] = useState<Array<string>>(['1-10']);
    const [initialRender, setInitialRender] = useState<boolean>(true);

    const currentPage = (key: string): JSX.Element => {
        if(!recs) return <></>;
        switch (key) {
            case '21-30':
                return recordList(recs?.slice(-Math.min(30, recs.length), -20));
            case '11-20':
                return recordList(recs?.slice(-Math.min(20, recs.length), -10));
            case '1-10':
            default:
                return recordList(recs?.slice(-Math.min(10, recs.length)));
        }
    };
    const recordList = (recs: AndarBaharRecord[]) => {
        if(!recs) return <></>;
        recs = recs.reverse();
        const getBackgroundColor = (andarWin: boolean): string => {
            if (andarWin) {
                return '#004596';
            }
            return '#ad0000';
        };

        const getGamePokerPoint = (gameCard: number): string => {
            const poker = getPokerByIndex(gameCard);
            if (poker) return poker.text;
            return '';
        };

        return (
            <Stack
                spacing={0.4}
                direction="row-reverse"
                sx={{
                    alignItems: 'flex-end',
                    paddingTop: '5px',
                    height: '24px',
                    minHeight: '24px',
                }}
            >
                {recs.map((record, index) => (
                    <Box
                        key={index}
                        sx={{
                            width:
                                currentPageIndex === pageList.length - 1 &&
                                index === 0
                                    ? '24px'
                                    : '18px',
                            height:
                                currentPageIndex === pageList.length - 1 &&
                                index === 0
                                    ? '24px'
                                    : '18px',
                            borderRadius: '5px',
                            backgroundColor: getBackgroundColor(
                                record.andarWin
                            ),
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant="caption"
                            fontSize={
                                index === 0 &&
                                currentPageIndex === pageList.length - 1
                                    ? '15px'
                                    : '12px'
                            }
                            fontWeight={'bold'}
                        >
                            {getGamePokerPoint(record.gameCard)}
                        </Typography>
                    </Box>
                ))}
            </Stack>
        );
    };

    const handleLeft = () => {
        if (currentPageIndex > 0) {
            if (carouselRef && carouselRef.current) {
                setCurrentPageIndex(currentPageIndex-1);
                //console.error('[AB]click left - currentPageIndex:', currentPageIndex);
                const scrollLeft = carouselRef.current.scrollLeft;
                const width = carouselRef.current.offsetWidth;
                carouselRef.current.scrollTo(scrollLeft - width, 0);
            }
        }
    };
    const handleRight = () => {
        if (currentPageIndex < pageList.length - 1) {
            if (carouselRef && carouselRef.current ) {
                setCurrentPageIndex(currentPageIndex+1);
                //console.error('[AB]click right - currentPageIndex:', currentPageIndex);
                const scrollLeft = carouselRef.current.scrollLeft;
                const width = carouselRef.current.offsetWidth;
                carouselRef.current.scrollTo(scrollLeft + width, 0);
            }
        }
    };

    useEffect(() => {
        if(recs){
            const totalPages = Math.min(Math.ceil(recs.length / 10), 3);
            const newPageList = [];
            if (totalPages >= 1) {
                newPageList.push('1-10');
            }
            if (totalPages >= 2) {
                newPageList.push('11-20');
            }
            if (totalPages >= 3) {
                newPageList.push('21-30');
            }
            setPageList(newPageList.reverse());
            if (carouselRef && carouselRef.current && initialRender) {
                setCurrentPageIndex(totalPages - 1);
                //console.error('[AB]init CurrentPageIndex:', totalPages - 1);
                setInitialRender(false);
            }
        }
    }, [recs, initialRender]);

    useEffect(() => {
        if (!initialRender && carouselRef && carouselRef.current) {
            const width = carouselRef.current.offsetWidth;
            carouselRef.current.scrollTo({
                left: currentPageIndex * width,
                top: 0,
                behavior: 'instant' as ScrollBehavior,
            });
        }
    }, [initialRender]);

    return (
        <Grid
            item
            container
            sx={{
                flexWrap: 'nowrap',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Button
                onClick={() => handleLeft()}
                disabled={currentPageIndex === 0}
                sx={{ minWidth: '15px', width: '15px', height: '15px' }}
            >
                <ArrowBackIosNewIcon
                    fontSize='medium'
                    sx={{ color: '#ffffff', display: currentPageIndex === 0 ? 'none' : 'inline-block' }}
                />
            </Button>
            <Box
                ref={carouselRef}
                sx={{
                    width: '75%',
                    height: 'auto',
                    overflowX: 'hidden',
                    scrollSnapType: 'x mandatory',
                    whiteSpace: 'nowrap',
                    scrollBehavior: 'smooth',
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                        display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                    },
                    '&-ms-overflow-style': {
                        display: 'none', // Hide the scrollbar for IE
                    },
                    marginLeft: '2px',
                    marginRight: '2px',
                }}
            >
                {pageList &&
                    pageList.map((dataSet, index) => {
                        return (
                            <Box
                                key={`tab-${index}`}
                                sx={{
                                    display: 'inline-flex',
                                    scrollSnapAlign: 'start',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    width: '100%',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                {currentPage(dataSet)}
                            </Box>
                        );
                    })}
            </Box>
            <Button
                onClick={() => handleRight()}
                disabled={currentPageIndex === pageList.length - 1}
                sx={{ minWidth: '15px', width: '15px', height: '15px' }}
            >
                <ArrowForwardIosIcon
                    fontSize='medium'
                    sx={{ color: '#ffffff', display: currentPageIndex === pageList.length - 1 ? 'none' : 'inline-block' }}
                />
            </Button>
        </Grid>
    );
};

export default AndarBaharLatest30RecordsPager;
