import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useBenchmark } from '../../hooks/useBenchmark';
import { useProgress } from '../../hooks/useProgress';
import { LoadingProgress } from './LoadingProgress';
import { getResultProgressState } from './selector';

type ProgressProps = {
    loadEndRoute?: string;
};
export const Progress = (props: ProgressProps) => {
    const { loadEndRoute } = props;
    const { percentage } = useProgress();
    const navigate = useNavigate();
    const resultState = useSelector(getResultProgressState);
    useBenchmark();
    useEffect(() => {
        const loaded = !Object.entries(resultState)
            .map(v => v[1])
            .includes(false);
        if (loaded && loadEndRoute) {
            console.log('app::progress::end', loadEndRoute);
            navigate(loadEndRoute);
        }
    }, [percentage, loadEndRoute]);
    return <LoadingProgress />;
};
