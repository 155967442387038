import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BetRoomSeatIndex } from '../models/games/BetRoom';
import { BlackjackBetResult, BlackjackResult } from '../models/games/blackjack';
import { HandResult, PlayerResult } from '../models/games/blackjack/GameResult';
import { GameState as CommonState } from '../models/games/enums/GameState';
import { Bet } from '../models/host/BetAmount';
import { getPlayers } from '../modules/bet/players/slice';
import { betRoomSliceActions, getBetRoom } from '../modules/bet/room/slice';
import { getHostById } from '../modules/host/slice';
import { getMainUserState } from '../modules/main/selector';
import { RootState } from '../store/store';
import {
    getBetResultByWinSlot,
    getCurrentSeatNo,
    getGameState,
    getHandNumber,
    isWaitingDecision,
    isWaitingFollow,
    isWaitingInsurance,
} from '../utils/games/blackjack';
import { GetBaccaratPoint } from '../utils/pokerCount';
import { useVideoZoom } from './useVideoZoom';

export const useSeatArea = (hostId: number) => {
    const room = useSelector((state: RootState) => getBetRoom(state, hostId));
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const players = useSelector(getPlayers);
    const { PlayerID } = useSelector(getMainUserState);
    const dispatch = useDispatch();

    //show action
    const [showInsurance, setShowInsurance] = useState(false);
    const [showDecision, setShowDecision] = useState(false);
    const [showBet, setShowBet] = useState(false);
    const [showFollow, setShowFollow] = useState(false);

    //show icon
    const [hasDoubleBet, setHasDoubleBet] = useState(false);
    const [hasSplitted, setHasSplitted] = useState(false);
    const [hasSurrender, setHasSurrender] = useState(false);
    const [hasSeated, setHasSeated] = useState(false);
    const [hasBet, setHasBet] = useState(false);
    const [isFirstDecision, setIsFirstDecision] = useState(true);
    const [canSurrender, setCanSurrender] = useState(false);
    const [canSplit, setCanSplit] = useState(false);
    const [canLeave, setCanLeave] = useState(false);

    //seat state
    const [inSeat, setInSeat] = useState<BetRoomSeatIndex>();
    const [currentSeat, setCurrentSeat] = useState<BetRoomSeatIndex>();
    const [selectedSeat, setSelectedSeat] = useState<BetRoomSeatIndex>();
    const [playerResult, setPlayerResult] = useState<Array<PlayerResult>>([]);
    const [bankerResult, setBankerResult] = useState<HandResult>();
    const [currentState, setCurrentState] = useState<CommonState>();
    const [gameId, setGameId] = useState<number>(0);
    const [betResult, setBetResult] = useState<BlackjackBetResult>();
    const [hasBetBehind, setHasBetBehind] = useState(false);
    const [isBetBehind, setIsBetBehind] = useState(false);
    const [isUserTurn, setIsUserTurn] = useState(false);
    const [isSeatDecision, setIsSeatDecision] = useState(false);
    const [handNumber, setHandNumber] = useState(1);

    const isVideoZoom = useVideoZoom(hostId);

    const hasBetBehindBySeat = (seat?: BetRoomSeatIndex): boolean => {
        if (!hasBetBehind || !seat) {
            return false;
        }
        const bet = room.Info[0].Bet.findIndex(
            b => b.Type === Number(seat - 1)
        );
        return bet >= 0;
    };

    useEffect(() => {
        if (host && room) {
            const disablePlayer = players.filter(p => p.disable).map(p => p.id);
            for (let i = 1; i <= 7; i++) {
                const sn = i as BetRoomSeatIndex;
                const rid = room.Info[sn].PlayerId;
                if (disablePlayer.includes(rid)) {
                    dispatch(
                        betRoomSliceActions.stand({
                            HostId: host.HostId,
                            Seat: sn,
                        })
                    );
                }
            }
        }
    }, [players]);
    useEffect(() => {
        if (host.CurrentResult?.ResultString) {
            const result: BlackjackResult = JSON.parse(
                host.CurrentResult?.ResultString
            );
            if (result) {
                setPlayerResult(result.P);
                setBankerResult(result.B);
                if (result.B.PK && result.B.PK.length == 1) {
                    setCanSurrender(
                        GetBaccaratPoint(result.B.PK[0]) != 10 &&
                            GetBaccaratPoint(result.B.PK[0]) != 1
                    );
                } else {
                    setCanSurrender(false);
                }

                const currentResult = result.P.find(p => p.SN === selectedSeat);
                if (currentResult) {
                    const hand1 = currentResult.H[0];
                    const hand2 = currentResult.H[1];
                    if (hand1 && !hand2) {
                        const points = hand1.PK.map(pk => GetBaccaratPoint(pk));
                        const cp =
                            points.length === 2 &&
                            points[0] === points[1] &&
                            !hasSplitted;
                        setCanSplit(cp);

                        setIsFirstDecision(points.length === 2);
                    } else {
                        setCanSplit(false);
                        setIsFirstDecision(false);
                    }
                }
            }
        }
    }, [host.CurrentResult?.ResultString, host.CurrentResult, selectedSeat]);
    useEffect(() => {
        const r = host.CurrentResult?.FResult;
        if (r && r !== '0' && host.ResultReleased) {
            const winSlot = r;
            const result = getBetResultByWinSlot(winSlot);
            setBetResult(result);
        }
    }, [host.CurrentResult?.FResult, host.ResultReleased]);
    useEffect(() => {
        if (host.CurrentResult?.GameID) setGameId(host.CurrentResult.GameID);
    }, [host.CurrentResult?.GameID]);

    useEffect(() => {
        const commonState = host.CurrentState;
        if (commonState) {
            const seat = getCurrentSeatNo(commonState);
            setCurrentState(commonState as CommonState);
            setCurrentSeat(seat);

            const gameState = getGameState(commonState);
            setHandNumber(getHandNumber(gameState));
            const { mainState } = gameState;
            // check is player turn
            let userTurn = false;
            if (room) {
                const currentSeat = getCurrentSeatNo(
                    gameState
                ) as BetRoomSeatIndex;
                const currentInfo = room.Info[currentSeat];

                userTurn = currentInfo?.PlayerId === PlayerID;
            }
            setIsUserTurn(userTurn);
            const isMakingDecision = isWaitingDecision(gameState);
            const isMakingInsurance = isWaitingInsurance(gameState);
            const isMakingFollow =
                isWaitingFollow(gameState) &&
                isBetBehind &&
                hasBetBehindBySeat(currentSeat);
            if (!showDecision && isMakingDecision) {
                setIsSeatDecision(true);
            }
            if (mainState === CommonState.Betting) {
                if (!hasSeated) {
                    setSelectedSeat(undefined);
                }

                setShowBet(true);
                setHasDoubleBet(false);
                setHasSplitted(false);
                setHasSurrender(false);
                setBetResult(undefined);
                setPlayerResult([]);
                setBankerResult(undefined);
                setIsSeatDecision(false);
                setHasBetBehind(false);
            } else if (mainState === CommonState.Shuffle) {
                setPlayerResult([]);
                setBankerResult(undefined);
            } else if (mainState >= CommonState.Started) {
                setShowDecision(false);
                setShowBet(false);
                setShowInsurance(false);
            }
            setShowInsurance(isMakingInsurance);
            setShowDecision(isMakingDecision);
            setShowFollow(isMakingFollow);
        }
    }, [host.CurrentState]);
    useEffect(() => {
        let p = 0;
        let b = new Array<Bet>();
        if (room) {
            for (let i = 1; i <= 7; i++) {
                const sn = i as BetRoomSeatIndex;
                const info = room.Info[sn];
                const playerId = info.PlayerId;
                if (playerId === PlayerID) {
                    p = playerId;
                    b = b.concat(info.Bet);
                    setInSeat(sn);
                    break;
                }
            }
        }
        setHasSeated(p !== 0);
        setHasBet(b.length > 0);
    }, [room]);
    useEffect(() => {
        let bb = false;
        let l = false;
        if (room && selectedSeat) {
            const info = room.Info[selectedSeat];
            if (info) {
                bb =
                    info.PlayerId !== 0 &&
                    info.PlayerId !== PlayerID &&
                    info.Bet.length > 0;
                l = info.PlayerId === PlayerID && host.CanLeave === true;
            }
            setHasBetBehind(room.Info[0].Bet.length > 0);
        }
        setIsBetBehind(bb);
        setCanLeave(l);
    }, [room, selectedSeat, host.CanLeave]);
    return {
        room,
        players,
        gameId,
        isVideoZoom,
        showBet,
        showDecision,
        showInsurance,
        currentSeat,
        selectedSeat,
        playerResult,
        bankerResult,
        currentState,
        setSelectedSeat,
        setShowDecision,
        setShowInsurance,
        // decision icon
        hasDoubleBet,
        setHasDoubleBet,
        hasSplitted,
        setHasSplitted,
        hasSurrender,
        setHasSurrender,
        hasBet,
        hasSeated,
        betResult,
        isFirstDecision,
        canSurrender,
        canSplit,
        hasBetBehind,
        isBetBehind,
        canLeave,
        handNumber,
        PlayerID,
        isUserTurn,
        isSeatDecision,
        inSeat,
        showFollow,
        setShowFollow,
    };
};
export type useSeatAreaState = ReturnType<typeof useSeatArea>;
