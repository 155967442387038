import { Grid } from '@mui/material';
import BetArea from '../../../components/common/betArea';
import { BetType } from '../../../models/games/baccarat/BetType';
import {
    amountOverBetLimit,
    isOppositeBetting,
} from '../../game/components/BetAreaPanels/Baccarat/utils';
import { getBetAreaProps } from './betAreaInfo';
import BaseImage from '../../../components/common/baseImage';
import { getHostById } from '../../host/slice';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';

type Prop = {
    hostId: number;
};
export const MultiBetSuperSixBetArea = ({ hostId }: Prop) => {
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { Group } = host;
    const bgImg = `multibet_game multibet_bacc_supersix_${Group[0]}hall`;
    return (
        <Grid position={'relative'}>
            <Grid position={'absolute'}>
                <BaseImage className={bgImg} scale={0.437} />
            </Grid>
            <Grid position={'absolute'}>
                <BaseImage
                    className="multibet_betarea baccarat"
                    isMultiLang={true}
                    scale={0.437}
                />
            </Grid>
            <Grid
                item
                container
                flexWrap={'nowrap'}
                justifyContent={'space-between'}
                sx={{
                    marginBottom: '3px',
                    width: 540,
                }}
            >
                <BetArea
                    hid={hostId}
                    style={getBetAreaProps(BetType.BBSSPlayerPair).style}
                    confirmPanelStyle={
                        getBetAreaProps(BetType.BBSSPlayerPair)
                            .confirmPanelStyle
                    }
                    betType={BetType.BBSSPlayerPair}
                    isMultiBet={true}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    roundNumDisable={
                        getBetAreaProps(BetType.BBSSPlayerPair).roundNumDisable
                    }
                />
                <BetArea
                    hid={hostId}
                    style={getBetAreaProps(BetType.BBSSPlayerWin).style}
                    confirmPanelStyle={
                        getBetAreaProps(BetType.BBSSPlayerWin).confirmPanelStyle
                    }
                    betType={BetType.BBSSPlayerWin}
                    isMultiBet={true}
                    perColor={'92,126,255'}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                />
                <BetArea
                    hid={hostId}
                    style={getBetAreaProps(BetType.BBSSTie).style}
                    confirmPanelStyle={
                        getBetAreaProps(BetType.BBSSTie).confirmPanelStyle
                    }
                    betType={BetType.BBSSTie}
                    isMultiBet={true}
                    perColor={'94,193,58'}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                />
                <BetArea
                    hid={hostId}
                    style={getBetAreaProps(BetType.BBSSSLuckySix).style}
                    confirmPanelStyle={
                        getBetAreaProps(BetType.BBSSSLuckySix).confirmPanelStyle
                    }
                    betType={BetType.BBSSSLuckySix}
                    isMultiBet={true}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    roundNumDisable={
                        getBetAreaProps(BetType.BBSSSLuckySix).roundNumDisable
                    }
                />
                <BetArea
                    hid={hostId}
                    style={getBetAreaProps(BetType.BBSSBankerWin).style}
                    confirmPanelStyle={
                        getBetAreaProps(BetType.BBSSBankerWin).confirmPanelStyle
                    }
                    betType={BetType.BBSSBankerWin}
                    isMultiBet={true}
                    perColor={'231,51,35'}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                />
                <BetArea
                    hid={hostId}
                    style={getBetAreaProps(BetType.BBSSBankerPair).style}
                    confirmPanelStyle={
                        getBetAreaProps(BetType.BBSSBankerPair)
                            .confirmPanelStyle
                    }
                    betType={BetType.BBSSBankerPair}
                    isMultiBet={true}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    roundNumDisable={
                        getBetAreaProps(BetType.BBSSBankerPair).roundNumDisable
                    }
                />
            </Grid>
        </Grid>
    );
};
