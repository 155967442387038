import { useContext, useEffect, useState, Dispatch } from "react";
import { useScreenRatio } from "../../../../hooks/useScreenRatio";
import { Box, CircularProgress, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import BaseImage from "../../../../components/common/baseImage";
import "./index.scss";
import "../../../../assets/image/common/promotionTool/promotion_tool.scss";
import { PromotionSplash } from "./PromotionSplash";
import { PromotionGacha } from "./PromotionGacha";
import { PromotionRanking } from "./PromotionRanking";
import { PromotionRecord } from "./PromotionRecord";
import { useDispatch, useSelector } from "react-redux";
import { getPromotionToolState } from "../../../main/selector";
import { RootState } from "../../../../store/store";
import { AnyAction } from "redux";
import { popupSliceActions } from "../../slice";
import { POPUP_CONFIRM_ACTION } from "../../../../models/Popup";
import { checkPromotionUrl } from "../PromotionUtil";
import i18n from "../../../locales/i18n";
import { getLocaleByCode } from "../../../../models/Locale";
import { CdnContext } from "../../../../contexts/CdnContext";
import preloadBG from '../../../../assets/image/common/promotionTool/event_splash_preload_bg.jpg';
import { AudioPlayContext } from "../../../../contexts/AudioPlayContext";
import { promotionToolSliceActions } from "../../../main/slice/promotiontool/promotionTool";
import { useTranslation } from "react-i18next";
import { PromotionDetail } from "../../../setting/components/promotionDetail";
import { PromotionButton } from "./PromotionButton";

export enum PromotionPage {
    Splash,
    Gacha,
    Ranking,
    Record
}

export const PromotionDialog = () => {
    const { playButtonAudio } = useContext(AudioPlayContext);
    const dispatch: Dispatch<AnyAction> = useDispatch();
    const { t } = useTranslation();
    const screenRatio = useScreenRatio();
    const { Popup, Page, IsLoading, Id, IsOpenDialog, MoreDetailUrl, Language:PromotionLanguage } = useSelector((state: RootState) => getPromotionToolState(state));
    const [banner, setbanner] = useState(`url(${preloadBG})`);

    const handleClose = () => {
        dispatch(promotionToolSliceActions.resetPage());
        dispatch(promotionToolSliceActions.isOpenDialog(false));
    }

    const { promotionCdn } = useContext(CdnContext);
    useEffect(() => {
        if (IsLoading) dispatch(popupSliceActions.triggerConfirm(POPUP_CONFIRM_ACTION.NONE));
    }, [IsLoading])

    useEffect(() => {
        let targetPage: PromotionPage = (Page === PromotionPage.Splash && !Popup) ? PromotionPage.Gacha : Page;
        if (Page == PromotionPage.Splash) {
            const bannerURL = checkPromotionUrl(promotionCdn, Id, "JSON");
            if (bannerURL) {
                const currentCode = i18n.language;
                const curLang = getLocaleByCode('long', currentCode).short;
                fetch(bannerURL).then(response => {
                    if (response.status === 200) {
                        response.json().then(data => {
                            let langExist = Object.hasOwnProperty.call(data, curLang);
                            const languageKey = langExist ? curLang : "default";
                            if (langExist) dispatch(promotionToolSliceActions.updateEventLanguage(curLang));
                            if (data[languageKey]["mobile"]) setbanner(`url('${promotionCdn + data[languageKey]["mobile"]}')`);
                        })
                    }
                })
            }
        }
        else setbanner('linear-gradient(180deg, #062021 0%, #063734 54.5%, #0E4C4B 88%, #0F5150 100%)');
        renderCurPage(targetPage);
    }, [Page]);

    const [openMoreDetail, setOpenMoreDetail] = useState<boolean>(false);
    const [moreDetailUrl, setMoreDetailUrl] = useState<string>('');
    const handleMoreDetail = () => {
        let url = MoreDetailUrl;
        let replacement = url.indexOf("{lang}") >= 0;
        if(replacement) url = url.replace("{lang}", `_${PromotionLanguage}`);
        else url += `_${PromotionLanguage}`;

        // if (os.name?.toLowerCase() === 'ios' && webView) {
        //     url += '&parenturl=' + encodeURIComponent(window.location.href);
        // }

        setMoreDetailUrl(url);
        setOpenMoreDetail(true);
    }

    const renderCurPage = (page: PromotionPage) => {
        switch (page) {
            case PromotionPage.Splash:
                return <PromotionSplash />
            case PromotionPage.Gacha:
                return <PromotionGacha />
            case PromotionPage.Ranking:
                return <PromotionRanking />
            case PromotionPage.Record:
                return <PromotionRecord />
            default:
                return <></>
        }
    }
    return (
        <Dialog
            // container={() => document.getElementsByClassName('container')[0]}
            fullScreen
            open={IsOpenDialog}
            //onClose={}
            hideBackdrop={true}
            disableEscapeKeyDown={true}
            PaperProps={{
                sx: {
                    backgroundColor: 'rgba(0,0,0,0.85)',
                    boxShadow: 'none',
                    // zIndex: theme.zIndex.drawer + 100,
                },
            }}
        >

            <DialogContent
            sx={{
                // overflowY: 'visible',
                padding: '0px',
            }}
            >
                <Box className="event-container"
                    sx={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        padding: '0',
                        margin: '0',
                        transform: `translate(-50%, -50%) scale(${screenRatio})`
                    }}
                    >
                    <PromotionButton className='btn_close' onClick={handleClose} sx={{ zIndex: 1 }}>
                        <BaseImage className='promotion_tool event_btn_close' scale={1} />
                    </PromotionButton>
                    <Box className='panel' sx={{
                        position: 'absolute',
                        background: banner,
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat'
                    }}>
                        {renderCurPage(Page)}
                        {MoreDetailUrl !== '' && <Stack sx={{ position: 'absolute', left: '50%', width: '50%', top: '805px' }} direction={'row'} justifyContent={'end'}>
                            <PromotionButton onClick={handleMoreDetail} sx={{ padding: '0px' }}>
                                <BaseImage className='promotion_tool event_ic_details' scale={1} />
                                <Typography sx={{ marginLeft: '2px', color: '#DCDCDC', textDecoration: 'underline' }}>{t('promotion_tool.details')}</Typography>
                            </PromotionButton>
                        </Stack>}
                    </Box>
                </Box>
                {openMoreDetail && 
                    <Box sx={{ backgroundColor: 'rgba(0,0,0,0.85)', position:'absolute', display:'flex', flexDirection:'column', width:'100%', height:'100%', top:'0px' }} >
                        <PromotionDetail setIsOpen={setOpenMoreDetail} url={moreDetailUrl} playButtonAudio={playButtonAudio}/>
                    </Box>
                }
                {
                    IsLoading ?
                        <Box sx={{ position: 'absolute', top: '0', left: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress size="6rem" sx={{ color: '#98805d' }} />
                        </Box> : <></>
                }
            </DialogContent>
        </Dialog >
    )
}