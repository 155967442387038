import { SupportedGame } from '../../../modules/host/models';
import { hostsSliceActions } from '../../../modules/host/slice';
import { CMDScInitRoulette, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
import { convertResultToRecord } from '../../../utils/games/roulette';

import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { CMDRoulette } from '../live/CMDRoulette';
import { RouletteResult } from '../../games/roulette';

import { batch } from 'react-redux';

export class CMDScInitRouletteHandler extends AbstractHandler {
    _command: CMDScInitRoulette;

    constructor(command: ICommand) {
        super();
        this._command = command as CMDScInitRoulette;
    }

    override convertResult(result: CMDRoulette): RouletteResult {
        const newResult = Object.assign(
            this.convertBaseResult(result.BaseResult),
            {
                ResultPoint: result.Detail.Number,
            }
        ) as RouletteResult;
        return newResult;
    }

    override handle(dispatch: Dispatch<AnyAction>): void {
        const game = {} as SupportedGame;
        const info = this.convertInformation(this._command.Information);
        const hostId = info.HostId;
        game.CurrentState = this._command.CurrentState;
        game.CurrentResult = this.convertResult(this._command.CurrentResult);
        game.OldResult = this._command.OldResult.map(o =>
            this.convertResult(o)
        );
        game.OldResult = game.OldResult.filter(
            (arr, index, self) =>
                index === self.findIndex(t => t.GameID === arr.GameID) && game.OldResult[index].Result !== 0
        );
        game.ResultReleased = false;
        game.IsRest = this._command.Information.Rest != 0;

        const convertedRecord = game.OldResult.map(r =>
            convertResultToRecord(r as RouletteResult)
        );

        batch(() => {
            dispatch(hostsSliceActions.gameInit({ hostId, game }));
            dispatch(hostsSliceActions.updateInformation(info));
            dispatch(
                hostsSliceActions.updateRecord({
                    hostId,
                    record: convertedRecord,
                })
            );
        });
    }
}
