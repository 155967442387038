import { KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material';
import { Box, Button, Dialog } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseImage from '../../../components/common/baseImage';
import { AudioPlayContext } from '../../../contexts/AudioPlayContext';
import { VideoPlayerContext } from '../../../contexts/VideoContext';
import { theme } from '../../../theme';
import '../../lobby/components/GameTables/GameTab.scss';
import { getLobbyStateHallGameTypeMap } from '../../lobby/selector';
import './SwitchTableButton.scss';
import { SwitchTableCell } from './SwitchTableCell';

export const SwitchTableButton = () => {
    const [open, setOpen] = useState<boolean>(false);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const changeOpen = (v: boolean) => {
        setOpen(v);
        playButtonAudio();
    };
    return (
        <>
            <Button
                variant="contained"
                className="switch-table-button"
                onClick={() => changeOpen(true)}
            >
                <BaseImage className="commonUI ic_switchtable" />
            </Button>
            {open && <SwitchTablePopup open={open} changeOpen={changeOpen} />}
        </>
    );
};

type Props = {
    open: boolean;
    changeOpen: (b: boolean) => void;
};
const SwitchTablePopup = (props: Props) => {
    const { open, changeOpen } = props;
    const { t } = useTranslation();
    const { hostId: curHostId } = useContext(VideoPlayerContext);
    const hostList = useSelector(getLobbyStateHallGameTypeMap);
    const [display, setDisplay] = useState(<></>);
    useEffect(() => {
        const eles = new Array<JSX.Element>();
        const hIds = hostList.map(h => h.hostId);
        for (const hid of hIds) {
            eles.push(
                <SwitchTableCell
                    key={`switch-table-${hid}`}
                    hostId={hid}
                    changeOpen={changeOpen}
                />
            );
        }
        setDisplay(<>{eles}</>);
    }, [hostList]);
    const handleClickClose = () => {
        changeOpen(false);
    };
    const [ref, attachRef] = useState<HTMLDivElement>();
    useEffect(() => {
        if (ref) {
            const hIds = hostList.map(h => h.hostId);
            const idx = hIds.findIndex(h => h === curHostId) + 1;
            if (idx > 4) {
                //122 + 5 = table cell height + margin-bottom
                const scrollY = (idx - 4) * 127;
                ref.scrollTo({
                    left: 0,
                    top: scrollY,
                    behavior: 'instant' as ScrollBehavior,
                });
            }
        }
    }, [ref, hostList, display]);

    return (
        <>
            <Dialog
                container={() =>
                    document.getElementById('in-game-popup-container')
                }
                sx={{ zIndex: theme.zIndex.drawer + 200 }}
                className="switch-table-button-dialog"
                open={open}
                onClose={handleClickClose}
                hideBackdrop={true}
                // hidden={!ref.current}
            >
                <Box
                    className="switch-table-close"
                    bgcolor={theme.palette.primary.main}
                    onClick={handleClickClose}
                >
                    <KeyboardArrowRightIcon
                        fontSize="large"
                        sx={{ color: '#ffffff' }}
                    />
                </Box>
                <Box className="switch-table-box">
                    <Box className="title" bgcolor={theme.palette.primary.main}>
                        {t('switch_table')}
                    </Box>
                    <Box
                        ref={attachRef}
                        className="table-cell-list"
                        // direction={'column'}
                        sx={{
                            overflowX: 'hidden',
                            overflowY: 'auto',
                            width: 205,
                            height: 915,
                            '&::-webkit-scrollbar': {
                                display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                            },
                            '&-ms-overflow-style': {
                                display: 'none', // Hide the scrollbar for IE
                            },
                        }}
                    >
                        {display}
                    </Box>
                </Box>
            </Dialog>
        </>
    );
};
