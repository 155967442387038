import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { LobbyUiContext } from '../../../contexts/LobbyUiContext';
import { RootState } from '../../../store/store';
import { getHostById } from '../../host/slice';
import { SicBoRoadmapRecord } from '../../../models/games/sicbo';
import { getRoadmapRecord, getSummaryList } from './util';
import { useTranslation } from 'react-i18next';
// export type SicBoRoadMapSummaryListProp = {
//     text: string
//     value: number
// };
export type SicBoRoadMapCellProps = {
    record: SicBoRoadmapRecord | undefined;
    isOneColumn: boolean;
    isLast: boolean;
};
const RoadmapColor = {
    '': '',
    odd: '#0000FF',
    even: '#FE0000',
    big: '#FE0000',
    small: '#0000FF',
    dice_triple: '#009204',
};
export const useSicBoRoadMapCellColorText = (
    result:
        | SicBoRoadmapRecord['BigSmallResult']
        | SicBoRoadmapRecord['OddEventRResult']
) => {
    const { t } = useTranslation();
    let color, text = '';
    if(result) {
        color = RoadmapColor[result];
        text = t(`road.${result}`);
    }
    return { color, text };
};
export const useRoadMapState = (hostId: number, inGame: boolean = false) => {
    const { grid } = useContext(LobbyUiContext);

    const { Record, IsRest } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [history, setHistory] = useState<SicBoRoadmapRecord[]>();
    const [pointRoadRecord, setPointRoadRecord] =
        useState<SicBoRoadmapRecord[]>();
    const [bigSmallRoadRecord, setBigSmallRoadRecord] =
        useState<SicBoRoadmapRecord[]>();
    const [evenOddRoadRecord, setEvenOddRoadRecord] =
        useState<SicBoRoadmapRecord[]>();
    const isOneColumn = useMemo(
        () => grid === 12 || inGame === true,
        [grid, inGame]
    );
    const columnNum = useMemo(() => (isOneColumn ? 10 : 7), [isOneColumn]);
    const roadmapRecord = useMemo(() => getRoadmapRecord(Record), [Record]);
    const summaryList = useMemo(() => getSummaryList(Record), [Record]);
    return {
        isOneColumn,
        columnNum,
        roadmapRecord,
        summaryList,
        IsRest,
        history,
        pointRoadRecord,
        bigSmallRoadRecord,
        evenOddRoadRecord,
        setHistory,
        setPointRoadRecord,
        setBigSmallRoadRecord,
        setEvenOddRoadRecord,
    };
};
