import { useTranslation } from "react-i18next";
import { Bet } from '../../../report/slice/betRecord';
import { Stack } from "@mui/material";
import { DetailResult } from "./BaccGameResultRecordPanel";

export const DragonTigerResultRecordPanel = ({bet}: {bet: Bet}) => {
    const { t } = useTranslation();
    const tokens: number[] = bet.Result.split(',').map(str => Number(str));
    return (
        <>
            <Stack
                direction="column"
                justifyContent="space-between"
                alignItems={'flex-end'}
                sx={{ height: '86px' }}
            >
                <DetailResult
                    lbl={t('road.dragon_win')}
                    cards={[tokens[0]]}
                />
                <DetailResult
                    lbl={t('road.tiger_win')}
                    cards={[tokens[1]]}
                />
            </Stack>
        </>
    );
}