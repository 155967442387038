import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { CSSProperties, useContext } from 'react';
import { VideoPlayerContext } from '../../../contexts/VideoContext';
import { useTotalBet } from '../../../hooks/useTotalBet';
import { shortAmountToString } from '../../../utils/formatter';
import BaseImage from '../baseImage';

type TotalBetAmountProps = {
    style?: CSSProperties;
    perColor: string;
    betTypeKey: string;
};
export const TotalBetAmount = (props: TotalBetAmountProps) => {
    const { style, perColor, betTypeKey } = props;
    const { hostId } = useContext(VideoPlayerContext);
    const { playerCount, betCount, percentage } = useTotalBet(
        hostId,
        betTypeKey
    );
    return (
        <Stack
            className={'bet-statistics'}
            width={style?.width}
            height={style?.height}
        >
            <Stack className="s1">
                <Box>
                    <Box
                        sx={{
                            border: `2px solid rgba(${perColor},0.2)`,
                        }}
                    >
                        <Typography
                            variant="caption"
                            component="div"
                        >{`${percentage}%`}</Typography>
                    </Box>
                    <CircularProgress
                        variant="determinate"
                        size={45}
                        value={percentage}
                        sx={{
                            color: `rgba(${perColor})`,
                        }}
                    />
                </Box>
            </Stack>
            <Stack
                className="s2"
                direction={'row'}
                justifyContent={'flex-end'}
                alignItems={'center'}
            >
                <BaseImage className={'tradbaccRoadmapBg icn_player_amount'} />
                {playerCount.toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                })}
            </Stack>
            <Stack
                className="s3"
                direction={'row'}
                justifyContent={'flex-end'}
                alignItems={'center'}
            >
                <BaseImage className={'tradbaccRoadmapBg icn_bet_amount'} />
                {shortAmountToString(betCount) || '0'}
            </Stack>
        </Stack>
    );
};
