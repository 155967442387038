import { memo } from 'react';
import {
    AndarBaharRoadMapCellProps,
    useAndarBaharRoadMapCellColorText,
    STROKE_WIDTH,
} from '../hook';

const BigRoadCell = (props: AndarBaharRoadMapCellProps) => {
    const {
        record: { Win },
        size,
    } = props;
    const { color } = useAndarBaharRoadMapCellColorText(Win);

    return (
        <svg width={size} height={size}>
            {Win >= 0 && (
                <>
                    <circle
                        cx={size / 2}
                        cy={size / 2}
                        r={size / 2 - STROKE_WIDTH / 2}
                        fill="transparent"
                        stroke={color}
                        strokeWidth={STROKE_WIDTH}
                    />
                </>
            )}
        </svg>
    );
};
export default memo(BigRoadCell);