import { Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { batch, useSelector } from 'react-redux';
import BaseImage from '../../../../../../components/common/baseImage';
import BetArea from '../../../../../../components/common/betArea';
import { VideoPlayerContext } from '../../../../../../contexts/VideoContext';
import { TeenPattiTotalBet } from '../../../../../../models/games/teenpatti';
import { BetType } from '../../../../../../models/games/teenpatti/BetType';
import { RootState } from '../../../../../../store/store';
import { getTotalBets } from '../../../../../totalBet/slice';
import { amountOverBetLimit, isOppositeBetting } from '../utils';
import betAreaJson from './betArea.json';

const { betType } = betAreaJson;
export const TeenPattiBetArea = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { TotalBet } = useSelector((state: RootState) =>
        getTotalBets(state, hostId)
    );
    // const [playerACount, setPlayerACount] = useState<number>(0);
    // const [playerBCount, setPlayerBCount] = useState<number>(0);
    // const [tieCount, setTieCount] = useState<number>(0);

    const [playerAPerValue, setPlayerAPerValue] = useState<number>(0);
    const [playerBPerValue, setPlayerBPerValue] = useState<number>(0);
    const [tiePerValue, setTiePerValue] = useState<number>(0);
    useEffect(() => {
        if (TotalBet) {
            const BetAmount = TotalBet as TeenPattiTotalBet;
            const playerAAmount = BetAmount.PlayerAWin
                ? BetAmount.PlayerAWin.Amount
                : 0;
            const playerBAmount = BetAmount.PlayerBWin
                ? BetAmount.PlayerBWin.Amount
                : 0;
            const tieAmount = BetAmount.Tie ? BetAmount.Tie.Amount : 0;
            const totalAmount =
                BetAmount.PlayerAWin?.Amount +
                BetAmount.PlayerBWin?.Amount +
                BetAmount.Tie?.Amount;

            batch(() => {
                setPlayerAPerValue(
                    Math.round((playerAAmount / totalAmount) * 100) || 0
                );
                setPlayerBPerValue(
                    Math.round((playerBAmount / totalAmount) * 100) || 0
                );
                setTiePerValue(
                    Math.round((tieAmount / totalAmount) * 100) || 0
                );
            });
        }
    }, [TotalBet]);

    return (
        <>
            <Grid
                item
                container
                flexWrap={'nowrap'}
                justifyContent={'space-between'}
                sx={{
                    marginBottom: '4px',
                    marginLeft: '5px',
                }}
            >
                <Grid
                    item
                    container
                    direction={'column'}
                    justifyContent={'flex-start'}
                    sx={{
                        position: 'absolute',
                        width: 530,
                        height: 240,
                        alignContent: 'center',
                    }}
                >
                    <Grid item height={'72px'}>
                        <BaseImage
                            className={'teenPatti bg_side_bet'}
                            scale={0.5}
                            transform="scale(1,1.35)"
                            transformOrigin="top"
                        />
                    </Grid>
                    <Grid item>
                        <BaseImage
                            className={'teenPatti bg_main_bet'}
                            scale={0.5}
                            transform="scale(1,1.32)"
                            transformOrigin="top"
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    direction={'row'}
                    sx={{
                        position: 'absolute',
                        height: '20px',
                        marginTop: '83px',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        width="40px"
                        marginLeft={'134px'}
                        textAlign={'center'}
                    >{`${playerAPerValue}%`}</Typography>
                    <Typography
                        variant="caption"
                        component="div"
                        width="40px"
                        marginLeft={'135px'}
                        textAlign={'center'}
                    >{`${tiePerValue}%`}</Typography>
                    <Typography
                        variant="caption"
                        component="div"
                        width="40px"
                        marginLeft={'140px'}
                        textAlign={'center'}
                    >{`${playerBPerValue}%`}</Typography>
                </Grid>
                <BetArea
                    style={betType[BetType.TPBPlayerAPairPlus].style}
                    confirmPanelStyle={
                        betType[BetType.TPBPlayerAPairPlus].confirmPanelStyle
                    }
                    imageName={betType[BetType.TPBPlayerAPairPlus].image}
                    betType={BetType.TPBPlayerAPairPlus}
                    confirmedChipContainerStyle={{ bottom: '0px' }}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                />
                <BetArea
                    style={betType[BetType.TPB6CardBonus].style}
                    confirmPanelStyle={
                        betType[BetType.TPB6CardBonus].confirmPanelStyle
                    }
                    imageName={betType[BetType.TPB6CardBonus].image}
                    betType={BetType.TPB6CardBonus}
                    confirmedChipContainerStyle={{ bottom: '0px' }}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                />
                <BetArea
                    style={betType[BetType.TPBPlayerBPairPlus].style}
                    confirmPanelStyle={
                        betType[BetType.TPBPlayerBPairPlus].confirmPanelStyle
                    }
                    imageName={betType[BetType.TPBPlayerBPairPlus].image}
                    betType={BetType.TPBPlayerBPairPlus}
                    confirmedChipContainerStyle={{ bottom: '0px' }}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                />
            </Grid>
            <Grid item container flexWrap={'nowrap'} justifyContent={'center'}>
                <BetArea
                    style={betType[BetType.TPBPlayerAWin].style}
                    confirmPanelStyle={
                        betType[BetType.TPBPlayerAWin].confirmPanelStyle
                    }
                    imageMargin={betType[BetType.TPBPlayerAWin].imageMargin}
                    imageName={betType[BetType.TPBPlayerAWin].image}
                    betType={BetType.TPBPlayerAWin}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                />
                <BetArea
                    style={betType[BetType.TPBPlayerBWin].style}
                    confirmPanelStyle={
                        betType[BetType.TPBPlayerBWin].confirmPanelStyle
                    }
                    imageMargin={betType[BetType.TPBPlayerBWin].imageMargin}
                    imageName={betType[BetType.TPBPlayerBWin].image}
                    betType={BetType.TPBPlayerBWin}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                />
            </Grid>
            <Grid
                item
                container
                position={'absolute'}
                width={'170px'}
                height={'100px'}
                borderRadius={'6px 6px 44px 44px'}
                margin={'74px 0 0 185px'}
            >
                <BetArea
                    style={betType[BetType.TPBTie].style}
                    confirmPanelStyle={
                        betType[BetType.TPBTie].confirmPanelStyle
                    }
                    imageName={betType[BetType.TPBTie].image}
                    betType={BetType.TPBTie}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                />
            </Grid>
        </>
    );
};
