import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GameState as CommonState } from '../models/games/enums/GameState';
import { getHostById } from '../modules/host/slice';
import { RootState } from '../store/store';
import { getGameState } from '../utils/games/blackjack';

export const useVideoZoom = (hostId: number) => {
    const { CurrentState, ResultReleased, DelayZoom } = useSelector(
        (state: RootState) => getHostById(state, hostId)
    );
    const [isInited, setIsInited] = useState(false);
    const [isVideoZoom, setIsVideoZoom] = useState(false);
    useEffect(() => {
        if (ResultReleased) {
            const timer = setTimeout(() => {
                clearTimeout(timer);
                setIsVideoZoom(false);
            }, DelayZoom);
        }
    }, [ResultReleased]);
    useEffect(() => {
        const { mainState } = getGameState(CurrentState);
        if (!isInited) {
            setIsInited(true);
            setIsVideoZoom(
                mainState != CommonState.Idle &&
                    mainState != CommonState.Shuffle &&
                    mainState != CommonState.Betting
            );
        } else {
            if (mainState === CommonState.Betting) {
                setIsVideoZoom(false);
            } else if (mainState === CommonState.Started) {
                setIsVideoZoom(true);
            } else if (mainState === CommonState.Shuffle) {
                setIsVideoZoom(false);
            }
        }
    }, [CurrentState]);
    return isVideoZoom;
};
