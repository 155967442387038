import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { LobbyUiContext } from '../../../contexts/LobbyUiContext';
import {
    PokDengGameResultState,
    PokDengRoadmapRecord,
} from '../../../models/games/pokdeng';
import { RootState } from '../../../store/store';
import { getHostById } from '../../host/slice';
import { getPokDengRecordList } from './util';

export type PokDengRoadMapCellProps = {
    idx: number;
    record: PokDengRoadmapRecord;
};
export type PokDengRoadMapSingleCellProps = {
    isBanker: boolean;
    gameResult: PokDengGameResultState;
    point: number;
    isPair: boolean;
    isFlush: boolean;
};
export type PokDengCellProps = {
    size: number;
    havePoint: boolean;
};

export const useRoadMapState = (hostId: number) => {
    const { grid } = useContext(LobbyUiContext);
    const { Record, IsRest } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [pokDengRoadRecord, setPokDengRoadRecord] =
        useState<Array<PokDengRoadmapRecord>>();
    const isOneColumn = useMemo(() => grid === 12, [grid]);
    useEffect(() => {
        const record = getPokDengRecordList(Record);
        setPokDengRoadRecord(record);
        console.log('roadmap::update', hostId);
    }, [isOneColumn, Record]);

    return {
        isOneColumn,
        pokDengRoadRecord,
        IsRest,
    };
};
