import { RoadMapProps } from '.';
import { GameType as CommonGameType } from '../../../../models/games/enums/GameType';
import { AndarBaharRoadMap } from '../../../roadmap/andarBahar';
import { BaccaratRoadMap } from '../../../roadmap/baccarat/map';
import { BlackjackRoadMap } from '../../../roadmap/blackjack';
import { DragonTigerRoadMap } from '../../../roadmap/dragonTiger';
import { PokDengRoadMap } from '../../../roadmap/pokdeng';
import { RouletteRoadMap } from '../../../roadmap/roulette';
import { SedieRoadMap } from '../../../roadmap/sedie';
import { SicBoRoadMap } from '../../../roadmap/sicbo';
import { TeenPattiRoadMap } from '../../../roadmap/teenpatti';

export const LobbyRoadMap = (props: RoadMapProps) => {
    const { hostId, gameType } = props;
    switch (gameType) {
        case CommonGameType.Baccarat:
            return <BaccaratRoadMap hostId={hostId} />;
        case CommonGameType.Blackjack:
            return <BlackjackRoadMap hostId={hostId} />;
        case CommonGameType.Dragon:
            return <DragonTigerRoadMap hostId={hostId} />;
        case CommonGameType.PokDeng:
            return <PokDengRoadMap hostId={hostId} />;
        case CommonGameType.SeDie:
            return <SedieRoadMap hostId={hostId} />;
        case CommonGameType.AndarBahar:
            return <AndarBaharRoadMap hostId={hostId} />;
        case CommonGameType.Roulette:
            return <RouletteRoadMap hostId={hostId} />;
        case CommonGameType.SicBo:
            return <SicBoRoadMap hostId={hostId} />;
        case CommonGameType.TeenPatti2020:
            return <TeenPattiRoadMap hostId={hostId} />;

        default:
            return <>Host no exist</>;
    }
};
