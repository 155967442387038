import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import { EnterCountProvider } from './contexts/EnterCountContext';
import { WebSocketContext } from './contexts/WebSocketContext';
import { useIframeMessage } from './hooks/useIframeMessage';
import { useLogin } from './hooks/useLogin';
import { useScreenRatio } from './hooks/useScreenRatio';
import { useStartTime } from './hooks/useStartTime';
import { LVT_KEY, useVersion } from './hooks/useVersion';
import {
    ROUTE_GAME,
    ROUTE_LOBBY,
    ROUTE_MULTIBET,
    ROUTE_RELOAD_LOBBY,
    ROUTE_ROOT,
    ROUTE_THANKS4PLAY,
} from './models/Route';
import BlockLayer from './modules/blockLayer';
import { GameScene } from './modules/game';
import { Landing } from './modules/landing';
import { Lobby } from './modules/lobby';
import { NewUIPopup } from './modules/lobby/components/NewUI/NewUIPopup';
import { Lobbyw } from './modules/lobbyw';
import { getPromotionToolState } from './modules/main/selector';
import { promotionToolSliceActions } from './modules/main/slice/promotiontool/promotionTool';
import { MultiBetScene } from './modules/multibet';
import { Popup } from './modules/popup';
import { PromotionDialog } from './modules/popup/promotiontool/components/PromotionDialog';
import ReconnectLayer from './modules/reconnectLayer';
import { RouteController } from './modules/routeController';
import ThanksForPlaying from './modules/thanksForPlaying';
import { RootState } from './store/store';
// import { useTranslation } from 'react-i18next';

const App = () => {
    const dispatch = useDispatch();
    useStartTime();
    useLogin();
    useVersion();
    useIframeMessage();
    const screenRatio = useScreenRatio();
    // const { i18n } = useTranslation();
    const {
        hasLogin: { current },
    } = useContext(WebSocketContext);

    //For Right to Left layout (e.g.Arabic)
    // useEffect(() => {
    //     document.body.dir = i18n.dir();
    // }, [i18n, i18n.language]);
    const {
        Popup: PromotionPopup,
        Id,
        IsOpenDialog,
    } = useSelector((state: RootState) => getPromotionToolState(state));
    useEffect(() => {
        const current = moment().valueOf().toString();
        window.localStorage.setItem(LVT_KEY, current);
    }, []);
    useEffect(() => {
        dispatch(
            promotionToolSliceActions.isOpenDialog(PromotionPopup > 0 && Id > 0)
        );
    }, [PromotionPopup, Id]);
    return (
        <div
            className="container"
            style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                padding: '0',
                margin: '0',
                transform: `translate(-50%, -50%) scale(${screenRatio})`,
            }}
        >
            <EnterCountProvider>
                <BrowserRouter>
                    <Routes>
                        <Route index path={ROUTE_ROOT} element={<Landing />} />
                        <Route
                            path={ROUTE_THANKS4PLAY}
                            element={<ThanksForPlaying />}
                        />
                        <Route
                            path={ROUTE_LOBBY}
                            element={
                                current ? (
                                    <Lobby />
                                ) : (
                                    <Navigate to={ROUTE_ROOT} />
                                )
                            }
                        />
                        <Route
                            path={ROUTE_GAME + '/:hostId'}
                            element={
                                current ? (
                                    <GameScene />
                                ) : (
                                    <Navigate to={ROUTE_ROOT} />
                                )
                            }
                        />
                        <Route
                            path={ROUTE_MULTIBET}
                            element={
                                current ? (
                                    <MultiBetScene />
                                ) : (
                                    <Navigate to={ROUTE_ROOT} />
                                )
                            }
                        />
                        <Route
                            path={ROUTE_RELOAD_LOBBY}
                            element={
                                current ? (
                                    <Lobbyw />
                                ) : (
                                    <Navigate to={ROUTE_ROOT} />
                                )
                            }
                        />
                    </Routes>
                    {IsOpenDialog ? <PromotionDialog /> : <></>}
                    <Popup />
                    <NewUIPopup />
                    <RouteController />
                    <ReconnectLayer />
                    <BlockLayer />
                </BrowserRouter>
            </EnterCountProvider>
        </div>
    );
};

export default App;
