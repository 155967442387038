import { memo } from 'react';
import { ROAD_MAP_COLORS, ROAD_MAP_TYPE } from '../../../lobby/constants';
import {
    TeenPattiRoadMapCellProps,
    CommonCellProps,
    STROKE_WIDTH,
    useTeenPattiRoadMapCellColorText,
} from '../hook';

const LastDrawLine = memo(function LastDrawLine({ size }: CommonCellProps) {
    return (
        <line
            x1={size * 0.35}
            y1={size}
            x2={size}
            y2={size * 0.35}
            stroke={ROAD_MAP_COLORS.GREEN}
            strokeWidth={STROKE_WIDTH}
        />
    );
});
const BigRoadCell = (props: TeenPattiRoadMapCellProps) => {
    const {
        record: { Win, LastDraw },
        size,
    } = props;

    const { color } = useTeenPattiRoadMapCellColorText(
        ROAD_MAP_TYPE.BIG_ROAD,
        Win
    );

    return (
        <svg width={size} height={size}>
            {Win >= 0 && (
                <>
                    <circle
                        cx={size / 2}
                        cy={size / 2}
                        r={size / 2 - STROKE_WIDTH / 2}
                        fill="transparent"
                        stroke={color}
                        strokeWidth={STROKE_WIDTH}
                    />
                    {LastDraw && <LastDrawLine size={size} />}
                </>
            )}
        </svg>
    );
};
export default memo(BigRoadCell);
