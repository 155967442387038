import { Epic } from 'redux-observable';
import { concat, filter, switchMap } from 'rxjs';
import { Roadmap } from '../models/Roadmap';
import {
    BaccaratRecord,
    BaccaratRoadmapRecord,
} from '../models/games/baccarat';
import { DragonTigerRecord } from '../models/games/dragonTiger';
import { GameType as CommonGameType } from '../models/games/enums/GameType';
import { SupportedRecord } from '../modules/host/models';
import { hostsSliceActions } from '../modules/host/slice';
import { ROAD_MAP_TYPE } from '../modules/lobby/constants';
import {
    getSummaryList as getBaccaratSummaryList,
    getBeadRoad,
    getMappedRoad,
    getOtherRoad,
} from '../modules/roadmap/baccarat/util';
import { getDTSummaryList } from '../modules/roadmap/dragonTiger/util';
import { roadmapSliceActions } from '../modules/roadmap/slice';
const ROADMAP_WIDTH = 60;
const ROADMAP_HEIGHT = 6;
export const SupportedGameType =
    CommonGameType.Baccarat | CommonGameType.Dragon;
export const roadmapEpic: Epic = action$ =>
    action$.pipe(
        filter(hostsSliceActions.updateRecord.match),
        switchMap(action => {
            const outs = [];
            const hostId = action.payload.hostId;
            const gameType = action.payload.gameType;
            const rm: Roadmap<BaccaratRoadmapRecord> = {
                HostId: hostId,
                Bead: [],
                Other: [],
                BigRoad: [],
                BigEyeRoad: [],
                SmallRoad: [],
                BeadRoad: [],
                CockroachRoad: [],
                Summary: {},
            };
            console.log('epic::roadmap', gameType, hostId);
            if (gameType !== undefined)
                if ((SupportedGameType & gameType) > 0) {
                    let record = action.payload
                        .record as Array<SupportedRecord>;
                    // convert the dragon tiger result
                    if (gameType === CommonGameType.Dragon) {
                        record = record.map(r => {
                            const dtr = r as DragonTigerRecord;
                            const data = {} as BaccaratRecord;
                            data.gameID = dtr.gameID;
                            data.result = dtr.result;
                            data.shoes = dtr.shoes;
                            data.round = dtr.round;
                            data.tie = dtr.tie;
                            data.resultPoint = dtr.resultPoint;

                            data.playerWin = dtr.dragonWin;
                            data.playerPoint = dtr.dragonPoint;
                            data.bankerWin = dtr.tigerWin;
                            data.bankerPoint = dtr.tigerPoint;
                            return data;
                        });
                    }
                    const bead = getBeadRoad(record);
                    const other = getOtherRoad(bead);
                    const big = getMappedRoad(
                        bead,
                        other,
                        ROAD_MAP_TYPE.BIG_ROAD,
                        ROADMAP_WIDTH,
                        ROADMAP_HEIGHT
                    );
                    const bigEye = getMappedRoad(
                        bead,
                        other,
                        ROAD_MAP_TYPE.BIG_EYE_ROAD,
                        ROADMAP_WIDTH,
                        ROADMAP_HEIGHT
                    );
                    const small = getMappedRoad(
                        bead,
                        other,
                        ROAD_MAP_TYPE.SMALL_ROAD,
                        ROADMAP_WIDTH,
                        ROADMAP_HEIGHT
                    );
                    const cockroach = getMappedRoad(
                        bead,
                        other,
                        ROAD_MAP_TYPE.COCKROACH_ROAD,
                        ROADMAP_WIDTH,
                        ROADMAP_HEIGHT
                    );
                    const beadR = getMappedRoad(
                        bead,
                        other,
                        ROAD_MAP_TYPE.BEAD_ROAD,
                        ROADMAP_WIDTH,
                        ROADMAP_HEIGHT
                    );
                    rm.Bead = bead;
                    rm.Other = other;
                    rm.BigRoad = big;
                    rm.SmallRoad = small;
                    rm.BigEyeRoad = bigEye;
                    rm.CockroachRoad = cockroach;
                    rm.BeadRoad = beadR;
                    switch (gameType) {
                        case CommonGameType.Baccarat:
                            rm.Summary = getBaccaratSummaryList(record);
                            break;
                        case CommonGameType.Dragon:
                            rm.Summary = getDTSummaryList(record);
                            break;
                    }
                    outs.push(roadmapSliceActions.update(rm));
                }
            return concat(outs);
        })
    );
