import BScroll from '@better-scroll/core';
import { BScrollConstructor } from '@better-scroll/core/dist/types/BScroll';
import Slide from '@better-scroll/slide';
import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Stack,
    Tab,
    Tabs,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseImage from '../../../../../components/common/baseImage';
import { AudioPlayContext } from '../../../../../contexts/AudioPlayContext';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import { useECard } from '../../../../../hooks/useECard';
import { useScreenRatio } from '../../../../../hooks/useScreenRatio';
import {
    TableInfoKeyType,
    useTableInfo,
} from '../../../../../hooks/useTableInfo';
import { GameType } from '../../../../../models/games/enums/GameType';
import { BetType } from '../../../../../models/games/teenpatti/BetType';
import { RootState } from '../../../../../store/store';
import { ExchangeBetAmount } from '../../../../../utils/commonFunc';
import { limitRedFormatter } from '../../../../../utils/formatter';
import { getHostById } from '../../../../host/slice';
import { InGameRoadMap } from '../../../../lobby/components/Roadmaps';
import { REPORT_COLORS } from '../../../../lobby/constants';
import {
    getMainLimitRedState,
    getMainUserState,
} from '../../../../main/selector';
import { CancelButton } from '../../../../setting/components/CancelButton';
import { TeenPattiECard } from '../../ECard/TeenPattiECard';
import './TeenPattiTableInfoPanel.scss';
import { TeenPattiStatisticsTab } from './TeenPattiWinStatisticsPanel';

type Props = {
    hostId: number;
};
export const TeenPattiTableInfoPanel = (props: Props) => {
    const { hostId } = props;
    const { playButtonAudio } = useContext(AudioPlayContext);
    const { t } = useTranslation();
    const show = useECard(hostId);
    //useState
    const [tableInfoTab, setTableInfoTab] = useState<number>(0);
    const ref = useRef<HTMLDivElement>(null);
    const [scrollObj, setScrollObj] = useState<BScrollConstructor>();
    const { tableInfo, setTableInfo } = useTableInfo();

    const handleChange = (v: number) => {
        playButtonAudio();
        if (scrollObj) {
            setTableInfoTab(v);
            let gKey: TableInfoKeyType = 'roadmap';
            if (v === 1) {
                gKey = 'tableInfo';
            } else if (v === 2) {
                gKey = 'winning';
            }
            setTableInfo(gKey);
            scrollObj.goToPage(v, 0);
        }
    };
    useEffect(() => {
        if (ref && ref.current) {
            let numLsTab = 0;
            if (tableInfo === 'tableInfo') {
                numLsTab = 1;
            } else if (tableInfo === 'winning') {
                numLsTab = 2;
            } else {
                numLsTab = 0;
            }
            setTableInfoTab(numLsTab);
            BScroll.use(Slide);
            const newBScroll = new BScroll(ref.current as HTMLDivElement, {
                scrollX: true,
                scrollY: false,
                click: true,
                slide: {
                    loop: false,
                    autoplay: false,
                    threshold: 100,
                    startPageXIndex: numLsTab,
                },
                momentum: false,
            });
            newBScroll.on('slidePageChanged', (page: { pageX: number }) => {
                if (page) {
                    const tab = page.pageX;
                    setTableInfoTab(tab);
                    let gKey: TableInfoKeyType = 'roadmap';
                    if (tab === 1) {
                        gKey = 'tableInfo';
                    } else if (tab === 2) {
                        gKey = 'winning';
                    }
                    setTableInfo(gKey);
                }
            });
            setScrollObj(newBScroll);
        }
        return () => {
            if (ref && ref.current && scrollObj) {
                scrollObj.destroy();
            }
        };
    }, [ref]);
    const getRoundIcon = () => {
        return tableInfoTab === 2
            ? 'commonUI icn_50round'
            : 'commonUI icn_50round_deactivate';
    };
    return (
        <>
            <Box className="teenpatti-table-info">
                <AppBar>
                    <Tabs
                        className="teenpatti-table-info-tabs"
                        value={tableInfoTab}
                        onChange={(_, v) => {
                            handleChange(v);
                        }}
                        aria-label="roadmap & table info."
                        sx={{ '& Button': { color: REPORT_COLORS.TITLE_TEXT } }}
                    >
                        <Tab
                            className="teenpatti-table-info-tab"
                            label={t('road_map')}
                        />
                        <Tab
                            className="teenpatti-table-info-tab"
                            label={t('tableinfo.name')}
                        />
                        <Tab
                            className="teenpatti-table-info-tab"
                            label={t('tableinfo.win_stat')}
                            icon={
                                <>
                                    <Box
                                        sx={{
                                            paddingRight: '5px',
                                        }}
                                    >
                                        <BaseImage className={getRoundIcon()} />
                                    </Box>
                                </>
                            }
                            iconPosition="start"
                        />
                    </Tabs>
                </AppBar>
                <Box
                    ref={ref}
                    sx={{
                        overflow: 'hidden',
                    }}
                >
                    <Box sx={{ display: 'inline-block' }}>
                        <Box
                            sx={{
                                display: 'inline-block',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <InGameRoadMap
                                hostId={hostId}
                                gameType={GameType.TeenPatti2020}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'inline-block',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <TeenPattiTableInfoTab />
                        </Box>
                        <Box
                            sx={{
                                display: 'inline-block',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <TeenPattiStatisticsTab />
                        </Box>
                    </Box>
                </Box>
                <TeenPattiECard show={show} />
            </Box>
        </>
    );
};

type InfoProps = {
    title: string;
    value: string | number;
};
type TableInfoTypeRow1 = {
    table: InfoProps;
    dealer: InfoProps;
    round: InfoProps;
    id: InfoProps;
    tableLimit: InfoProps;
};
type TableInfoTypeRow2 = {
    betLimit: InfoProps;
    playerA: InfoProps;
    playerB: InfoProps;
    tie: InfoProps;
};
type BetLimitType = {
    betLimit: InfoProps;
    playerA: InfoProps;
    playerB: InfoProps;
    tie: InfoProps;
    pairPlus: InfoProps;
    sixCardBonus: InfoProps;
};
type TableInfoType = [TableInfoTypeRow1, TableInfoTypeRow2];
const initTableInfoData = [
    {
        table: {
            title: 'tableinfo.table',
            value: '',
        },
        dealer: {
            title: 'tableinfo.dealer',
            value: '',
        },
        round: {
            title: 'tableinfo.shoe',
            value: '',
        },
        id: {
            title: 'tableinfo.round',
            value: '',
        },
        tableLimit: {
            title: 'tableinfo.tablelimit',
            value: '',
        },
    },
    {
        betLimit: {
            title: 'betLimit',
            value: '',
        },
        playerA: {
            title: 'betlog.bet_teenpatti.1',
            value: '',
        },
        playerB: {
            title: 'betlog.bet_teenpatti.2',
            value: '',
        },
        tie: {
            title: 'betlog.bet_teenpatti.0',
            value: '',
        },
    },
] as TableInfoType;

const betLimitData = {
    playerA: {
        title: 'betlog.bet_teenpatti.1',
        value: '',
    },
    playerB: {
        title: 'betlog.bet_teenpatti.2',
        value: '',
    },
    tie: {
        title: 'betlog.bet_teenpatti.0',
        value: '',
    },
    pairPlus: {
        title: 'teenpatti.pairplus',
        value: '',
    },
    sixCardBonus: {
        title: 'teenpatti.sixCardBonus',
        value: '',
    },
} as BetLimitType;

const TeenPattiTableInfoTab = () => {
    const { t, i18n } = useTranslation();
    const [languageDir, setLanguageDir] = useState<string>('ltr');
    useEffect(() => {
        setLanguageDir(i18n.dir(i18n.language));
    }, [i18n.language]);
    const { hostId } = useContext(VideoPlayerContext);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { CurrentResult, DealerList, TableLimit, GameType, MaxBet } = host;
    const { ExchangeRate } = useSelector((state: RootState) =>
        getMainUserState(state)
    );
    const limitRedByGameType = useSelector(getMainLimitRedState);
    const [tableInfo, setTableInfo] =
        useState<TableInfoType>(initTableInfoData);
    const [betLimit, setBetLimit] = useState<BetLimitType>(betLimitData);
    const { CurrencyName } = useSelector(getMainUserState);

    const getMaxBetByBetType = (betType: number): string => {
        if (!MaxBet) return '0';
        const index = MaxBet.findIndex(d => {
            return d.BetType === betType;
        });
        return index !== -1
            ? (MaxBet[index].MaxBet / 100).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
              })
            : '0';
    };
    useEffect(() => {
        if (MaxBet) {
            betLimit.playerA.value = tableInfo[1].playerA.value =
                getMaxBetByBetType(BetType.TPBPlayerAWin);
            betLimit.playerB.value = tableInfo[1].playerB.value =
                getMaxBetByBetType(BetType.TPBPlayerBWin);
            betLimit.tie.value = tableInfo[1].tie.value = getMaxBetByBetType(
                BetType.TPBTie
            );

            betLimit.pairPlus.value = getMaxBetByBetType(
                BetType.TPBPlayerAPairPlus
            );
            betLimit.sixCardBonus.value = getMaxBetByBetType(
                BetType.TPB6CardBonus
            );
            setBetLimit(betLimit);
            setTableInfo(tableInfo);
        }
    }, [MaxBet]);
    useEffect(() => {
        if (
            hostId &&
            DealerList &&
            TableLimit &&
            CurrentResult &&
            limitRedByGameType.BetRuleByGameType
        ) {
            //row 1
            tableInfo[0].table.value = t(`hostList.${hostId}`);
            tableInfo[0].dealer.value = DealerList[0].DealerName;
            const shoes = Math.floor(CurrentResult.GameCount / 10000);
            const round = CurrentResult.GameCount % 10000;
            const row1Round = shoes && round ? `${shoes}-${round}` : round;
            tableInfo[0].round.value = row1Round;
            tableInfo[0].id.value = CurrentResult.GameID || 0;
            //row 2
            const selectedBetRule = limitRedByGameType.BetRuleByGameType.find(
                br => br.GameType === GameType
            )?.BetRule.find(r => r.Selected === 1);
            let betLimitV = '';
            if (selectedBetRule) {
                betLimitV = limitRedFormatter(selectedBetRule, CurrencyName);
            }
            tableInfo[1].betLimit.value = betLimitV;
            let infoTableLimit =
                ExchangeBetAmount(TableLimit, ExchangeRate) / 100;
            const digi = Math.pow(10, infoTableLimit.toString().length - 1);
            const tableLimitValue = (
                Math.round(infoTableLimit / digi) * digi
            ).toLocaleString('en-US', {
                maximumFractionDigits: 2,
            });
            tableInfo[0].tableLimit.value = tableLimitValue;
            setTableInfo(tableInfo);
        }
    }, [
        hostId,
        CurrentResult,
        DealerList,
        TableLimit,
        limitRedByGameType.BetRuleByGameType,
    ]);

    const [open, setOpen] = useState<boolean>(false);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const changeOpen = (b: boolean) => {
        setOpen(b);
        playButtonAudio();
    };
    return (
        <>
            {tableInfo && (
                <>
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        sx={{ fontSize: '14px' }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                            }}
                        >
                            <BaseImage
                                className="tradbaccBg table_info_bg"
                                scale={1}
                                opacity={0.5}
                                // 18px = (196 - 161) / 2
                                // 1.226 = 196 / 161
                                transform={'translate(0, 24.5px) scale(1, 1.3)'}
                            />
                        </Box>
                        {tableInfo.map((r, i) => {
                            const style =
                                i !== 1
                                    ? { width: '50%', zIndex: '1' }
                                    : {
                                          width: '51%',
                                          zIndex: '1',
                                          borderLeft:
                                              '2px solid rgba(255,255,255,0.2)',
                                      };
                            return (
                                <Box key={`info-${i}`} sx={style}>
                                    <>
                                        {Object.keys(r).map((k, ii) => {
                                            const v = r[
                                                k as keyof typeof r
                                            ] as InfoProps;
                                            return (
                                                <Grid
                                                    key={`info-cell${ii}`}
                                                    item
                                                    container
                                                    justifyContent={
                                                        'space-between'
                                                    }
                                                    alignItems={'center'}
                                                    height={'41px'}
                                                    padding={'0 7.5px 0 7.5px'}
                                                >
                                                    <Grid
                                                        item
                                                        sx={{
                                                            color: REPORT_COLORS.TITLE_TEXT,
                                                            direction:
                                                                languageDir,
                                                        }}
                                                    >
                                                        {t(v.title)}
                                                    </Grid>
                                                    <Grid item>{v.value}</Grid>
                                                </Grid>
                                            );
                                        })}
                                        {i == 1 && (
                                            <Grid
                                                item
                                                container
                                                justifyContent={'center'}
                                                alignItems={'center'}
                                                height={'41px'}
                                                padding={'0 7.5px 0 7.5px'}
                                            >
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        height: '32px',
                                                        width: '99%',
                                                        backgroundColor:
                                                            '#8b7554',
                                                        padding: 0,
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                    onClick={() =>
                                                        changeOpen(true)
                                                    }
                                                >
                                                    <BaseImage
                                                        className="commonUI ic_arrow_down_white_10px"
                                                        transform={
                                                            'rotate(180deg)'
                                                        }
                                                        scale={1}
                                                    />
                                                </Button>
                                            </Grid>
                                        )}
                                    </>
                                </Box>
                            );
                        })}
                    </Stack>
                </>
            )}
            {open && (
                <BetLimitPopup
                    open={open}
                    changeOpen={changeOpen}
                    betLimitData={betLimit}
                />
            )}
        </>
    );
};

type BetLimitPopupProps = {
    open: boolean;
    changeOpen: (b: boolean) => void;
    betLimitData: BetLimitType;
};
export const BetLimitPopup = (props: BetLimitPopupProps) => {
    const { open, changeOpen, betLimitData } = props;
    const { t } = useTranslation();

    const screenRatio = useScreenRatio();

    const handleClose = () => {
        changeOpen(false);
    };
    return (
        <div
            style={{
                width: '540',
                height: '960',
                position: 'absolute',
                left: '50%',
                top: '50%',
                padding: '0',
                margin: '0',
                transform: `translate(-50%, -50%) scale(${screenRatio})`,
            }}
        >
            <Dialog
                container={() =>
                    document.getElementById('in-game-popup-container')
                }
                fullScreen
                open={open}
                onClose={handleClose}
                hideBackdrop={true}
                disableEscapeKeyDown={true}
                PaperProps={{
                    sx: {
                        backgroundColor: 'rgba(0,0,0,0.85)',
                        boxShadow: 'none',
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        paddingTop: '100px',
                        fontSize: '29px',
                        textAlign: 'center',
                    }}
                >
                    {t('betLimit2')}
                </DialogTitle>
                <DialogContent
                    sx={{
                        justifyContent: 'center',
                        overflowY: 'visible',
                    }}
                >
                    <Grid
                        item
                        container
                        direction={'row'}
                        justifyContent={'center'}
                    >
                        <Grid
                            item
                            container
                            direction={'row'}
                            justifyContent={'center'}
                            display={'grid'}
                            gridTemplateColumns={'repeat(2, 1fr)'}
                            gridTemplateRows={'repeat(3, 1fr)'}
                            gridAutoFlow={'column'}
                            padding={'100px 35px'}
                        >
                            {Object.values(betLimitData).map((value, id) => {
                                return (
                                    <Grid
                                        item
                                        container
                                        key={`bet-limit-cell${id}`}
                                        direction={'column'}
                                        justifyContent={'center'}
                                        marginBottom={'30px'}
                                        fontSize={'18px'}
                                    >
                                        <div style={{ color: '#bea887' }}>
                                            {t(value.title)}
                                        </div>
                                        <div style={{ color: '#fff' }}>
                                            {'' + value.value}
                                        </div>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Grid
                            item
                            container
                            direction={'row'}
                            justifyContent={'center'}
                        >
                            <CancelButton
                                onClick={handleClose}
                                image={'commonUI ic_close'}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};
