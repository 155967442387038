import BScroll from '@better-scroll/core';
import { BScrollConstructor } from '@better-scroll/core/dist/types/BScroll';
import Slide from '@better-scroll/slide';
import { AppBar, Box, Stack, Tab, Tabs } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseImage from '../../../../../components/common/baseImage';
import { Poker, PokerType } from '../../../../../components/common/poker';
import { AudioPlayContext } from '../../../../../contexts/AudioPlayContext';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import { useECard } from '../../../../../hooks/useECard';
import {
    TableInfoKeyType,
    useTableInfo,
} from '../../../../../hooks/useTableInfo';
import { AndarBaharResult } from '../../../../../models/games/andarBahar';
import { GameState } from '../../../../../models/games/andarBahar/GameState';
import { GameType } from '../../../../../models/games/enums/GameType';
import { RootState } from '../../../../../store/store';
import { getHostById } from '../../../../host/slice';
import { InGameRoadMap } from '../../../../lobby/components/Roadmaps';
import { REPORT_COLORS, ROAD_MAP_TYPE } from '../../../../lobby/constants';
import { AndarBaharECard } from '../../ECard/AndarBaharECard';
import { AndarBaharBetLimitInfoTab } from './AndarBaharBetLimitInfoTab';
import './AndarBaharTableInfoPanel.scss';
import { AndarBaharStatisticsTab } from './AndarBaharWinStatisticPanel';
import { BaseTypography } from '../../../../../components/common/baseText/BaseTypography';

export const AndarBaharTableInfoPanel = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const { tableInfo, setTableInfo } = useTableInfo();
    const { t } = useTranslation();
    const show = useECard(hostId);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { CurrentResult, CurrentState } = host;
    //useState
    const [gameCard, setGameCard] = useState(-1);
    const [gameCardDisplay, setGameCardDisplay] = useState(false);
    const [tableInfoTab, setTableInfoTab] = useState<number>(1);
    const [roadMapType, setRoadMapType] = useState<ROAD_MAP_TYPE>(
        ROAD_MAP_TYPE.BEAD_ROAD
    );
    const ref = useRef<HTMLDivElement>(null);
    const [scrollObj, setScrollObj] = useState<BScrollConstructor>();
    const handleChange = (v: number) => {
        if (v === 0) {
            //RoadmapTypeButton
            // setRoadMapType(1);
        } else {
            //setTableInfoTab(v);
            playButtonAudio();
            if (scrollObj) {
                setTableInfoTab(v);
                let gKey: TableInfoKeyType = 'roadmap';
                if (v === 2) {
                    gKey = 'tableInfo';
                } else if (v === 3) {
                    gKey = 'winning';
                }
                setTableInfo(gKey);
                scrollObj.goToPage(v - 1, 0);
            }
        }
    };
    const handleOnClick = () => {
        setRoadMapType(roadMapType =>
            roadMapType == ROAD_MAP_TYPE.BEAD_ROAD
                ? ROAD_MAP_TYPE.POINT_ROAD
                : ROAD_MAP_TYPE.BEAD_ROAD
        );
    };
    const getRoadmapTypeIcon = () => {
        if (roadMapType == ROAD_MAP_TYPE.BEAD_ROAD) {
            return 'tradbaccRoadmapBg button_number';
        } else {
            return 'andarBaharSheet btn_switch_AB';
        }
    };
    const getRoundIcon = () => {
        return tableInfoTab === 3
            ? 'commonUI icn_50round'
            : 'commonUI icn_50round_deactivate';
    };

    useEffect(() => {
        setGameCardDisplay(false);
        if (CurrentResult && CurrentState === GameState.Betting) {
            const result = CurrentResult as AndarBaharResult;
            const gameCard = Number(result.ResultString?.split(',')[0]);
            const validGameCard = isNaN(gameCard) ? -1 : gameCard;
            setGameCard(validGameCard);
            setGameCardDisplay(validGameCard > -1);
        }
    }, [CurrentResult]);
    useEffect(() => {
        if (ref && ref.current) {
            let numLsTab = 0;
            if (tableInfo === 'tableInfo') {
                numLsTab = 1;
            } else if (tableInfo === 'winning') {
                numLsTab = 2;
            } else {
                numLsTab = 0;
            }
            setTableInfoTab(numLsTab + 1);
            BScroll.use(Slide);
            const newBScroll = new BScroll(ref.current as HTMLDivElement, {
                scrollX: true,
                scrollY: false,
                click: true,
                slide: {
                    loop: false,
                    autoplay: false,
                    threshold: 100,
                    startPageXIndex: numLsTab,
                },
                momentum: false,
            });
            newBScroll.on('slidePageChanged', (page: { pageX: number }) => {
                if (page) {
                    const tab = page.pageX;
                    setTableInfoTab(tab + 1);
                    let gKey: TableInfoKeyType = 'roadmap';
                    if (tab === 1) {
                        gKey = 'tableInfo';
                    } else if (tab === 2) {
                        gKey = 'winning';
                    }
                    setTableInfo(gKey);
                }
            });
            setScrollObj(newBScroll);
        }
        return () => {
            if (ref && ref.current && scrollObj) {
                scrollObj.destroy();
            }
        };
    }, [ref]);
    return (
        <>
            {gameCardDisplay ? (
                <Box
                    sx={{
                        position: 'absolute',
                        left: '2%',
                        top: '19%',
                        width: '101px',
                        height: '97px',
                        backgroundColor: 'rgba(0,0,0,0.9)',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Stack
                        direction="column"
                        spacing={1}
                        sx={{ justifyContent: 'center', alignItems: 'center' }}
                    >
                        <BaseTypography
                            color={'#c3a475'}
                            fontSize={'14px'}
                            resize={{ direction: 'horizontal', value: '95px' }}
                        >
                            {t('andar_bahar.game_card')}
                        </BaseTypography>
                        <Poker
                            scale={0.5}
                            n={gameCard + 1}
                            type={PokerType.AndarBahar2}
                        />
                    </Stack>
                </Box>
            ) : (
                <></>
            )}
            <Box className="andar-bahar-table-info">
                <AppBar sx={{ position: 'relative' }}>
                    <Tabs
                        className="andar-bahar-table-info-tabs"
                        value={tableInfoTab}
                        onChange={(_, v) => {
                            handleChange(v);
                        }}
                        aria-label="roadmap & table info."
                        sx={{ '& Button': { color: REPORT_COLORS.TITLE_TEXT } }}
                    >
                        <Tab
                            className="roadmap-type-button"
                            icon={
                                <>
                                    <Box onClick={handleOnClick}>
                                        <BaseImage
                                            className={getRoadmapTypeIcon()}
                                        />
                                    </Box>
                                </>
                            }
                        />
                        <Tab
                            className="andar-bahar-table-info-tab"
                            label={t('road_map')}
                        />
                        <Tab
                            className="andar-bahar-table-info-tab"
                            label={t('tableinfo.name')}
                        />
                        <Tab
                            className="andar-bahar-table-info-win-stat-tab"
                            label={t('tableinfo.win_stat')}
                            icon={
                                <>
                                    <Box
                                        sx={{
                                            paddingRight: '5px',
                                        }}
                                    >
                                        <BaseImage className={getRoundIcon()} />
                                    </Box>
                                </>
                            }
                            iconPosition="start"
                        />
                    </Tabs>
                </AppBar>
                <Box
                    ref={ref}
                    sx={{
                        overflow: 'hidden',
                    }}
                >
                    <Box sx={{ display: 'inline-block' }}>
                        <Box
                            sx={{
                                display: 'inline-block',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <>
                                <InGameRoadMap
                                    hostId={hostId}
                                    roadMapType={roadMapType}
                                    gameType={GameType.AndarBahar}
                                />
                                <button
                                    id="box-to-click"
                                    onClick={handleOnClick}
                                    style={{
                                        top: 0,
                                        position: 'absolute',
                                        width: '100%',
                                        height: '172px',
                                        opacity: 0,
                                    }}
                                ></button>
                            </>
                        </Box>
                        <Box
                            sx={{
                                display: 'inline-block',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <AndarBaharBetLimitInfoTab />
                        </Box>
                        <Box
                            sx={{
                                display: 'inline-block',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <AndarBaharStatisticsTab />
                        </Box>
                    </Box>
                </Box>
                <AndarBaharECard show={show} />
            </Box>
        </>
    );
};
