import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { mask } from '../../../../../utils/formatter';
import { BetPlayer } from '../../../../bet/players/slice';
import { getMainUserState } from '../../../../main/selector';

type PlayerNameProps = {
    player: BetPlayer;
};
export const PlayerName = (props: PlayerNameProps) => {
    const { PlayerID } = useSelector(getMainUserState);
    const { player } = props;
    return PlayerID === player.id ? (
        <Box
            sx={{
                backgroundColor: '#6f5f45',
                borderRadius: '4px',
            }}
        >
            <Typography align="center" color={'white'} fontSize={'0.8rem'}>
                {player.name.slice(0, 6).concat('..')}
            </Typography>
        </Box>
    ) : (
        <Typography align="center" color={'white'} fontSize={'0.8rem'}>
            {mask(player.name)}
        </Typography>
    );
};
