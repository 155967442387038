import { Grid, IconButton } from '@mui/material';
import Stack from '@mui/material/Stack/Stack';
import moment from 'moment';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { WebSocketContext } from '../../../../contexts/WebSocketContext';
import { ROUTE_NEW_UI } from '../../../../models/Route';
import { getLandingState } from '../../../main/selector';
import { routeSliceActions } from '../../../routeController/slice';
import './NewUIPopup.scss';
import { PopupCloseIcon } from './PopupCloseIcon';

const newPopupKey = 'last_show_new_ui_popup';
export const NewUIPopup = () => {
    const { i18n } = useTranslation();
    const [show, setShow] = useState(false);
    // const [isChecked, setIsChecked] = useState(false);
    const [image, setImage] = useState<ReactNode>(<></>);
    const { showNewUI } = useSelector(getLandingState);
    const dispatch = useDispatch();
    const {
        hasLogin: { current },
    } = useContext(WebSocketContext);
    useEffect(() => {
        if (current) {
            let path = `${process.env.PUBLIC_URL}/resource/assets/image/new_ui/en.jpg`;
            switch (i18n.language) {
                case 'th-TH':
                    path = path.replace('/en', '/th');
                    break;
                case 'ko-KR':
                    path = path.replace('/en', '/kr');
                    break;
                case 'vi-VN':
                    path = path.replace('/en', '/vn');
                    break;
                case 'zh-CN':
                    path = path.replace('/en', '/sc');
                    break;
                case 'zh-TW':
                    path = path.replace('/en', '/tc');
                    break;
                case 'ms-MY':
                    path = path.replace('/en', '/my');
                    break;
                case 'id-ID':
                    path = path.replace('/en', '/in');
                    break;
                case 'ja-JP':
                    path = path.replace('/en', '/jp');
                    break;
                case 'es-ES':
                    path = path.replace('/en', '/sp');
                    break;
                case 'pt-PT':
                    path = path.replace('/en', '/pt');
                    break;
                case 'pt-BR':
                    path = path.replace('/en', '/br');
                    break;
                case 'hi-IN':
                    path = path.replace('/en', '/hi');
                    break;
                case 'ar-001':
                    path = path.replace('/en', '/ar');
                    break;
                case 'fa-IR':
                    path = path.replace('/en', '/fa');
                    break;
                case 'bn-BN':
                    path = path.replace('/en', '/bn');
                    break;
                case 'te-IN':
                    path = path.replace('/en', '/te');
                    break;
                default:
                    break;
            }
            setImage(<img src={path} />);
        }
    }, [i18n.language, current]);
    const handleClose = () => {
        const noon = moment().hour(12).minute(0).second(0);
        const afternoon = moment().isAfter(noon);
        const next = moment(noon.add(afternoon? 8:7, 'days')).valueOf();

        // if (isChecked) {
        // const next = moment().valueOf() + 168 * 60 * 60000;
        window.localStorage.setItem(newPopupKey, next.toString());
        // }
        setShow(false);
    };
    useEffect(() => {
        if (showNewUI) {
            const tmp = Number(window.localStorage.getItem(newPopupKey));
            if (tmp === undefined) {
                setShow(true);
            } else {
                const now = moment().valueOf();
                setShow(now > tmp);
            }
        }
    }, [showNewUI]);
    const gotoNewUI = () => {
        // if (isChecked) {
        //     const today = moment().date() + '_' + moment().month();
        //     window.localStorage.setItem(newPopupKey, today);
        // }

        const next = moment().valueOf() + 168 * 60 * 60000;
        window.localStorage.setItem(newPopupKey, next.toString());
        dispatch(routeSliceActions.goto(ROUTE_NEW_UI));
    };
    return (
        <>
            {show && (
                <div className="new-ui-popup">
                    <Grid className="new-ui-container">
                        <Stack>
                            <Grid className="new-ui-header">
                                <IconButton
                                    sx={{
                                        height: '2.333rem',
                                        width: '2.333rem',
                                    }}
                                    onClick={handleClose}
                                >
                                    <PopupCloseIcon />
                                </IconButton>
                            </Grid>
                            <div className="new-ui-try-button">
                                <IconButton
                                    sx={{ height: '100%', width: '100%' }}
                                    onClick={gotoNewUI}
                                >
                                    {image}
                                </IconButton>
                            </div>
                            {/* <Stack>
                                <Checkbox
                                    label="new_ui.skip_popup"
                                    isChecked={isChecked}
                                    setIsChecked={setIsChecked}
                                />
                            </Stack> */}
                        </Stack>
                    </Grid>
                </div>
            )}
        </>
    );
};
