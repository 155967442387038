import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './rootEpic';
import rootReducer from './rootReducer';

const epicMiddleware = createEpicMiddleware();

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(epicMiddleware),
    devTools: process.env.NODE_ENV === 'development',
});

epicMiddleware.run(rootEpic);

export default store;
export type RootState = ReturnType<typeof store.getState>;
