import { createTheme } from '@mui/material';

// default theme value
// https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette
export const theme = createTheme({
    palette: {
        mode: 'dark',
        // global main color
        primary: {
            light: '#978365',
            main: '#8B7554',
            dark: '#7d694c',
        },
        secondary: {
            light: '#1c9606',
            main: '#196260',
            dark: '#009303',
        },
        // components color
        text: {
            primary: '#fff',
            secondary: 'rgba(255, 255, 255, 0.7)',
            disabled: 'rgba(255, 255, 255, 0.5)',
        },
        // button
        action: {
            active: '#fff',
            hover: 'rgba(255, 255, 255, 0.08)',
            selected: 'rgba(255, 255, 255, 0.16)',
            disabled: 'rgba(255, 255, 255, 0.3)',
            disabledBackground: 'rgba(255, 255, 255, 0.12)',
        },
        // default background color
        background: {
            default: '#000000',
            paper: '#000000',
        },
        // divider
        divider: 'rgba(255, 255, 255, 0.12)',
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'unset',
                },
            },
        },
    },
});
