import { memo } from 'react';
import { ROAD_MAP_COLORS } from '../../../lobby/constants';
import { PokDengCellProps, PokDengRoadMapSingleCellProps } from '../hook';
import { GameResultState as PokDengGameResultState } from '../../../../models/games/pokdeng/GameState';
const PokDengSingleCell = (
    props: PokDengRoadMapSingleCellProps & PokDengCellProps
) => {
    const {
        size: size,
        isBanker: isBanker,
        gameResult: gameResult,
        point: point,
        isPair: isPair,
        isFlush: isFlush,
        havePoint: havePoint,
    } = props;
    let color = ROAD_MAP_COLORS.RED;
    if (!isBanker) {
        switch (gameResult) {
            case PokDengGameResultState.PDRS_Tie:
                color = ROAD_MAP_COLORS.GREEN;
                break;
            case PokDengGameResultState.PDRS_PlayerWin:
                color = ROAD_MAP_COLORS.LIGHT_BLUE;
                break;
            case PokDengGameResultState.PDRS_BankerWin:
                color = ROAD_MAP_COLORS.GRAY;
                break;
        }
    }
    const fontsize: number =
        point.toString().length == 1 ? size * 0.7 : size * 0.45;
    const border: number = havePoint ? 2 : 1;
    return (
        <svg width={size} height={size}>
            <circle
                cx={size / 2}
                cy={size / 2}
                r={size / 2 - border}
                fill={color}
            />
            {isFlush && (
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={size / 2 - 2 - border}
                    fill={color}
                    stroke={ROAD_MAP_COLORS.WHITE}
                />
            )}
            {isPair && (
                <circle
                    cx={size * 0.15}
                    cy={size * 0.15}
                    r={size * 0.15}
                    fill={ROAD_MAP_COLORS.ORANGE}
                    stroke={ROAD_MAP_COLORS.WHITE}
                />
            )}
            {havePoint && (
                <text
                    x={size / 2}
                    y={size / 2 + 2}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fill="#ffffff"
                    fontSize={fontsize}
                >
                    {point}
                </text>
            )}
        </svg>
    );
};
const areEquals = (
    prev: PokDengRoadMapSingleCellProps & PokDengCellProps,
    next: PokDengRoadMapSingleCellProps & PokDengCellProps
) => {
    const isSamePosition = prev.isBanker === next.isBanker;
    const isSameGameResult = prev.gameResult === next.gameResult;
    const isSamePoint = prev.point === next.point;
    const isSamePair = prev.isPair === next.isPair;
    const isSameDouble = prev.isFlush === next.isFlush;
    const isSameType = prev.havePoint === next.havePoint;
    const isSameSize = prev.size === next.size;
    return (
        isSamePosition &&
        isSameGameResult &&
        isSamePoint &&
        isSameDouble &&
        isSamePair &&
        isSameType &&
        isSameSize
    );
};
export default memo(PokDengSingleCell, areEquals);
