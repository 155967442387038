import { Box, Stack, Typography } from "@mui/material"
import BaseImage from "../../../../components/common/baseImage"
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { promotionToolSliceActions } from "../../../main/slice/promotiontool/promotionTool";
import { PromotionPage } from "./PromotionDialog";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { WebSocketContext } from "../../../../contexts/WebSocketContext";
import { RootState } from "../../../../store/store";
import { getPromotionToolState } from "../../../main/selector";
import { getDisplayTimeZone, startQuery, stopQuery } from "../PromotionUtil";
import { popupSliceActions } from "../../slice";
import { POPUP_CONFIRM_ACTION, POPUP_TYPE } from "../../../../models/Popup";
import { CMDPsQueryPromotionRedeemLog } from "../../../../models/cmd/live";
import { getPopupState } from "../../selector";
import { PromotionRecordCell } from "./PromotionRecordCell";
import { PromotionButton } from "./PromotionButton";

export type RedeemProps = {
    RedeemID:number,
    RedeemTime:number,
    PrizeRank:number,
    ResultAmount:number,
    LastRecord:boolean
};
export const PromotionRecord = () => {
    const dispatch: Dispatch<AnyAction> = useDispatch();
    const { sendCommand } = useContext(WebSocketContext);
    const { Record, EndTimestamp, Timezone } = useSelector((state: RootState) => getPromotionToolState(state));
    const displayTimezone = getDisplayTimeZone(Timezone);
    const confirmFunc = useSelector(getPopupState).confirmValue;
    const [record, setRecord] = useState<RedeemProps[]>([]);
    const { t } = useTranslation();

    const onTimeout = () =>{
        stopQuery();
        dispatch(promotionToolSliceActions.isLoading(false));
        dispatch(
            popupSliceActions.open(
                'promotion_tool.query_timeout',
                POPUP_CONFIRM_ACTION.RETRY,
                POPUP_TYPE.CONFIRM,
                undefined
            )
        );
    }

    const doQuery = () => {
        dispatch(promotionToolSliceActions.isLoading(true));
        const cmd = new CMDPsQueryPromotionRedeemLog();
        sendCommand(cmd);
        startQuery(onTimeout);

        if (eventEnded()) {
            //get last data when event ended
            updateRecordData();
        }
    };
    
    useEffect(() => {
        doQuery();
    }, [])

    useEffect(() => {
        updateRecordData();
    }, [Record])

    useEffect(() => {
        if(confirmFunc === POPUP_CONFIRM_ACTION.RETRY) {
            doQuery();
        }
    }, [confirmFunc])

    const updateRecordData = () => {
        const isEmptyData = (!Record || Record.length == 0);
        if(Record) {
            dispatch(promotionToolSliceActions.isLoading(false));
            stopQuery();
        }

        let rec:RedeemProps[] = [];
        if(!isEmptyData){
            //update
            Record.forEach((str, index) => {
                const data:RedeemProps = JSON.parse(str);
                data.LastRecord = index === Record.length - 1;
                rec.push(data);
            })
        }
        setRecord(rec);
    }

    const eventEnded = (): boolean => {
        const timezone = -displayTimezone;
        const timeDifference = EndTimestamp * 1000 + timezone * 60 * 60 * 1000 - Date.now();
        return timeDifference < 0;
    }

    const destroy = () => {
        stopQuery();
        dispatch(promotionToolSliceActions.clearRedeemLog());
    }

    return (
        <Box>
            <Box sx={{  position: 'relative', top: '-43px',display:'flex', justifyContent:'center' }}>
                <BaseImage className={'event_title event_title'} isMultiLang={true} scale={1}/>
            </Box>

            <Box  sx={{  position: 'relative', top: '-27px'}}>                
                <Box sx={{ backgroundColor:'#bea57b', width:'100%', height:'2px' }}></Box>
                <Box sx={{ backgroundColor:'rgba(0, 0, 0, 0.5)', width:'100%', height:'40px', display:'flex', justifyContent:'center', alignItems:'center' }}>
                    <Typography sx={{ color: '#bf9a5d', textAlign: 'center', fontSize:'18px' }}>
                        {t('promotion_tool.records')}
                    </Typography>
                </Box>
                <Box sx={{ backgroundColor:'#bea57b', width:'100%', height:'2px' }}></Box>
                <Box sx={{ width:'100%', minHeight:'600px', maxHeight:'600px', overflowY: 'auto', margin:'4px 0 16px 0'}}>
                    {   
                        record.map((rec, index) => (
                        <PromotionRecordCell record={rec} key={`promotiontool-record-${index}`}  />
                    ))}
                </Box>
            </Box>
            <Stack direction={'row'} sx={{ position: 'absolute', bottom: '2%', left:'50%', transform:'translateX(-50%)' }} spacing={'20px'}>
                <PromotionButton className='button_gold' onClick={() => {
                    destroy();
                    dispatch(promotionToolSliceActions.updatePage(PromotionPage.Gacha));
                }}>{t('promotion_tool.explore_rewards')}</PromotionButton>
                <PromotionButton className='button_black' onClick={() => {
                    destroy();
                    dispatch(promotionToolSliceActions.updatePage(PromotionPage.Ranking));
                }}>{t('promotion_tool.rankings')}</PromotionButton>
            </Stack>
            {Record && record.length < 1? 
                <Typography sx={{ position:'absolute', width:'100%', top:'160px', color: '#FFFFFF', textAlign: 'center', fontSize:'28px'}}>
                    {t('promotion_tool.no_records')}
                </Typography>:<></>
            }
        </Box>
        
    )
}