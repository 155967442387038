import { Stack } from '@mui/material';
import { GameType as GameTypeEnum } from '../../../models/games/enums/GameType';
import { BlackjackLimitInfo } from '../../game/components/TableInfoHeader/Blackjack';
import VideoQualityButton from './videoQualityButton';
// import VideoZoomButton from './videoZoomButton';

type VideoControlPanelOwnProps = {
    haveZoom: boolean;
    isHorizontal?: boolean;
    gameType?: GameTypeEnum;
};
type VideoControlPanelProps = VideoControlPanelOwnProps;
const VideoControlPanel = (props: VideoControlPanelProps) => {
    const { haveZoom, isHorizontal = true, gameType } = props;
    return (
        <>
            <Stack
                direction={isHorizontal ? 'row' : 'column'}
                sx={{
                    '& button': {
                        marginLeft: '10px',
                        marginBottom: isHorizontal ? '0px' : ' 10px',
                    },
                }}
            >
                <VideoQualityButton haveZoom={haveZoom} />
                {/* {haveZoom && <VideoZoomButton />} */}
                {gameType === GameTypeEnum.Blackjack && <BlackjackLimitInfo />}
            </Stack>
        </>
    );
};

export default VideoControlPanel;
