import { useTranslation } from 'react-i18next';
import { PokDengCellProps } from '../hook';
import { memo } from 'react';
const PokDengRoadmapGrid = (props: PokDengCellProps) => {
    const { size: size } = props;
    const gridD: string = `M ${size} 0 L 0 0 0 ${size}`;
    const fontsize: string = size * 0.75 + 'px';
    const gridID: string = `grid${size}`;
    const gridName: string = `url(#grid${size})`;
    const { t } = useTranslation();
    return (
        <>
            <div className="pokdeng-grid">
                <svg width="100%" height="100%">
                    <defs>
                        <pattern
                            id={gridID}
                            width={size}
                            height={size}
                            patternUnits="userSpaceOnUse"
                        >
                            <path
                                d={gridD}
                                fill="none"
                                stroke="#DCDCDC"
                                strokeWidth="2"
                            />
                        </pattern>
                    </defs>

                    <rect width="100%" height="100%" fill={gridName} />
                </svg>
            </div>
            <div className="pokdeng-label">
                <svg width="100%" height="100%" fill="none">
                    <rect
                        x="2"
                        y="1"
                        width="90%"
                        height={size - 2}
                        rx="3"
                        fill="#D30000"
                    />
                    <rect
                        x="2"
                        y={1 + size}
                        width="90%"
                        height={size - 2}
                        rx="3"
                        fill="#005CCA"
                    />
                    <rect
                        x="2"
                        y={1 + size * 2}
                        width="90%"
                        height={size - 2}
                        rx="3"
                        fill="#005CCA"
                    />
                    <rect
                        x="2"
                        y={1 + size * 3}
                        width="90%"
                        height={size - 2}
                        rx="3"
                        fill="#005CCA"
                    />
                    <rect
                        x="2"
                        y={1 + size * 4}
                        width="90%"
                        height={size - 2}
                        rx="3"
                        fill="#005CCA"
                    />
                    <rect
                        x="2"
                        y={1 + size * 5}
                        width="90%"
                        height={size - 2}
                        rx="3"
                        fill="#005CCA"
                    />
                    <text
                        x="50%"
                        y={size * 0.8}
                        fill="#FFFFFF"
                        fontSize={fontsize}
                        textAnchor="middle"
                    >
                        {t('pokdeng.dealer')}
                    </text>
                    <text
                        x="50%"
                        y={size * 1.8}
                        fill="#FFFFFF"
                        fontSize={fontsize}
                        textAnchor="middle"
                    >
                        {t('pokdeng.player.0')}
                    </text>
                    <text
                        x="50%"
                        y={size * 2.8}
                        fill="#FFFFFF"
                        fontSize={fontsize}
                        textAnchor="middle"
                    >
                        {t('pokdeng.player.1')}
                    </text>
                    <text
                        x="50%"
                        y={size * 3.8}
                        fill="#FFFFFF"
                        fontSize={fontsize}
                        textAnchor="middle"
                    >
                        {t('pokdeng.player.2')}
                    </text>
                    <text
                        x="50%"
                        y={size * 4.8}
                        fill="#FFFFFF"
                        fontSize={fontsize}
                        textAnchor="middle"
                    >
                        {t('pokdeng.player.3')}
                    </text>
                    <text
                        x="50%"
                        y={size * 5.8}
                        fill="#FFFFFF"
                        fontSize={fontsize}
                        textAnchor="middle"
                    >
                        {t('pokdeng.player.4')}
                    </text>
                    <rect y={size} width="100%" height="1" fill="#DCDCDC" />
                    <rect y={size * 2} width="100%" height="1" fill="#DCDCDC" />
                    <rect y={size * 3} width="100%" height="1" fill="#DCDCDC" />
                    <rect y={size * 4} width="100%" height="1" fill="#DCDCDC" />
                    <rect y={size * 5} width="100%" height="1" fill="#DCDCDC" />
                </svg>
            </div>
        </>
    );
};
const areEquals = (prev: PokDengCellProps, next: PokDengCellProps) => {
    const isSameSize = prev.size === next.size;
    return isSameSize;
};
export default memo(PokDengRoadmapGrid, areEquals);
