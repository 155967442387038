import { Stack, Typography } from '@mui/material';
import { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseImage from '../../../../components/common/baseImage';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import { InGamePopupKeyType } from '../../../../hooks/useInGamePopup';
import { SicBoRecord } from '../../../../models/games/sicbo';
import { RootState } from '../../../../store/store';
import { getHostById } from '../../../host/slice';
import { AudioPlayContext } from '../../../../contexts/AudioPlayContext';
import { VoiceList } from '../../../../models/Voice';

export const SicBoGameResultPopup = (props: InGamePopupKeyType) => {
    const { amount, bg, message, audioKey, playSound } = props;
    const { t, i18n } = useTranslation();
    //selector
    const { hostId } = useContext(VideoPlayerContext);
    const { audioPlay } = useContext(AudioPlayContext);
    const { Record, CurrentResult } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    //use
    const [result, setResult] = useState<Array<string | number>>();
    const ref = useRef<boolean>(false); //防止play 2 次
    useEffect(() => {
        if (!ref.current) {
            ref.current = true;
            let aKey = [];
            if (audioKey && playSound) {
                aKey.push(audioKey);
            } else {
                if (Record && Record.length > 0 && CurrentResult) {
                    // const curRecord: SicBoRecord = Record[
                    //     Record.length - 1
                    // ] as SicBoRecord;
                    const gameId = CurrentResult.GameID;
                    const curRecord: SicBoRecord = Record.filter(
                        r => (r as SicBoRecord).gameID === gameId
                    )[0] as SicBoRecord;
                    if (curRecord && curRecord.resultPoint) {
                        const resultPoint: string =
                            curRecord.resultPoint.toString();
                        let rpText = resultPoint;
                        switch (i18n.language) {
                            case 'zh-TW':
                                rpText += '點';
                                break;
                            case 'zh-CN':
                                // resultPoint += t('sicbo.pts');
                                rpText += '点';
                                break;
                        }
                        let sResult: string = '',
                            vrKey: string = '';
                        if (curRecord.SRBigSmall === 1) {
                            sResult = t('sicbo.big');
                            vrKey = VoiceList.SicBoBigPoint.replace(
                                '{point}',
                                curRecord.SRPoint.toString()
                            );
                        } else if (curRecord.SRBigSmall === 2) {
                            sResult = t('sicbo.small');
                            vrKey = VoiceList.SicBoSmallPoint.replace(
                                '{point}',
                                curRecord.SRPoint.toString()
                            );
                        } else if (curRecord.SRTriple) {
                            sResult = t('sicbo.triple');
                            vrKey = VoiceList.SicBoTriplePoint.replace(
                                '{point}',
                                curRecord.SRTriple.toString()
                            );
                        }
                        if (curRecord) {
                            setResult([
                                curRecord.Dice1,
                                curRecord.Dice2,
                                curRecord.Dice3,
                                rpText,
                                sResult,
                            ]);
                            if (!curRecord.SRAllTriple) {
                                aKey.push(
                                    VoiceList.SicBoPoint.replace(
                                        '{point}',
                                        curRecord.Dice1.toString()
                                    )
                                );
                                aKey.push(
                                    VoiceList.SicBoPoint.replace(
                                        '{point}',
                                        curRecord.Dice2.toString()
                                    )
                                );
                                aKey.push(
                                    VoiceList.SicBoPoint.replace(
                                        '{point}',
                                        curRecord.Dice3.toString()
                                    )
                                );
                            }
                            if (vrKey) {
                                aKey.push(vrKey);
                            }
                        }
                    }
                }
            }
            if (aKey && aKey.length > 0) {
                audioPlay(aKey);
            }
        }
    }, []);

    if (message !== 'game.result') {
        return (
            <Stack
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    position: 'absolute',
                    height: 150,
                    width: 400,
                    top: 302,
                    left: 70,
                    right: 70,
                    fontSize: 35,
                    borderRadius: '4px',
                    backgroundColor: bg,
                    textAlign: 'center',
                    lineHeight: 1.5,
                    zIndex: theme => theme.zIndex.drawer + 80,
                }}
            >
                {t(message)}
                {amount && (
                    <>
                        <Typography
                            sx={{
                                fontSize: 28,
                                marginLeft: '10px',
                            }}
                        >
                            {amount}
                        </Typography>
                    </>
                )}
            </Stack>
        );
    } else {
        // return <SicBoResultPopup amount={amount} />;
        return (
            <>
                {result && (
                    <>
                        <Stack
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{
                                position: 'absolute',
                                width: 540,
                                height: 150,
                                top: 405,
                                left: 0,
                                right: 0,
                                fontSize: 30,
                                bgcolor: 'rgba(0,0,0,0.75)',
                                zIndex: theme => theme.zIndex.drawer + 80,
                            }}
                        >
                            {result && (
                                <>
                                    <Stack direction={'row'} spacing={2}>
                                        <BaseImage
                                            className={`dices_stroke dice${result[0]}`}
                                            scale={0.94}
                                        />
                                        <BaseImage
                                            className={`dices_stroke dice${result[1]}`}
                                            scale={0.94}
                                        />
                                        <BaseImage
                                            className={`dices_stroke dice${result[2]}`}
                                            scale={0.94}
                                        />
                                        <Typography fontSize={30}>
                                            {result[3]}
                                        </Typography>
                                        <Typography fontSize={30}>
                                            {result[4]}
                                        </Typography>
                                    </Stack>
                                </>
                            )}
                            {amount && (
                                <>
                                    <Typography sx={{ fontSize: 30 }}>
                                        {amount}
                                    </Typography>
                                </>
                            )}
                        </Stack>
                    </>
                )}
            </>
        );
    }
};

// const SicBoResultPopup = ({ amount }: { amount: string }) => {
//     //selector
//     const { hostId } = useContext(VideoPlayerContext);
//     const { Record } = useSelector((state: RootState) =>
//         getHostById(state, hostId)
//     );
//     const { t, i18n } = useTranslation();
//     //use
//     const [result, setResult] = useState<Array<string | number>>();
//     useEffect(() => {
//         if (Record && Record.length > 0) {
//             const curRecord: SicBoRecord = Record[
//                 Record.length - 1
//             ] as SicBoRecord;
//             let resultPoint: string = curRecord.resultPoint.toString();
//             switch (i18n.language) {
//                 case 'zh-TW':
//                     resultPoint += '點';
//                     break;
//                 case 'zh-CN':
//                     // resultPoint += t('sicbo.pts');
//                     resultPoint += '点';
//                     break;
//             }
//             let sResult: string = '';
//             if (curRecord.SRBigSmall === 1) {
//                 sResult = t('sicbo.big');
//             } else if (curRecord.SRBigSmall === 2) {
//                 sResult = t('sicbo.small');
//             } else if (curRecord.SRTriple) {
//                 sResult = t('sicbo.triple');
//             }
//             if (curRecord) {
//                 setResult([
//                     curRecord.Dice1,
//                     curRecord.Dice2,
//                     curRecord.Dice3,
//                     resultPoint,
//                     sResult,
//                 ]);
//             }
//         }
//     }, []);
//     return (
//         <>
//             {result && (
//                 <>
//                     <Stack
//                         justifyContent={'center'}
//                         alignItems={'center'}
//                         sx={{
//                             position: 'absolute',
//                             width: 540,
//                             height: 150,
//                             top: 405,
//                             left: 0,
//                             right: 0,
//                             fontSize: 30,
//                             bgcolor: 'rgba(0,0,0,0.75)',
//                             zIndex: theme => theme.zIndex.drawer + 80,
//                         }}
//                     >
//                         {result && (
//                             <>
//                                 <Stack direction={'row'} spacing={2}>
//                                     <BaseImage
//                                         className={`dices_stroke dice${result[0]}`}
//                                         scale={0.94}
//                                     />
//                                     <BaseImage
//                                         className={`dices_stroke dice${result[1]}`}
//                                         scale={0.94}
//                                     />
//                                     <BaseImage
//                                         className={`dices_stroke dice${result[2]}`}
//                                         scale={0.94}
//                                     />
//                                     <Typography fontSize={30}>
//                                         {result[3]}
//                                     </Typography>
//                                     <Typography fontSize={30}>
//                                         {result[4]}
//                                     </Typography>
//                                 </Stack>
//                             </>
//                         )}
//                         {amount && (
//                             <>
//                                 <Typography sx={{ fontSize: 30 }}>
//                                     {amount}
//                                 </Typography>
//                             </>
//                         )}
//                     </Stack>
//                 </>
//             )}
//         </>
//     );
// };
