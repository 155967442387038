import { Stack } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BaseImage from '../../../../../../components/common/baseImage';
import { BaseTypography } from '../../../../../../components/common/baseText/BaseTypography';
import { AudioPlayContext } from '../../../../../../contexts/AudioPlayContext';
import { VideoPlayerContext } from '../../../../../../contexts/VideoContext';
import { GameType as GameTypeEnum } from '../../../../../../models/games/enums/GameType';
import { RootState } from '../../../../../../store/store';
import { gameSliceActions } from '../../../../../games/slice';
import { getHostById } from '../../../../../host/slice';

export const ReBetButton = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const { GameType } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const dispatch = useDispatch();

    const onClick = () => {
        playButtonAudio();
        dispatch(gameSliceActions.onReBet({ onClick: true }));
    };

    switch (GameType) {
        case GameTypeEnum.Roulette:
            return <ButtonStyleB onClick={onClick} />;
        default:
            return <ButtonStyleA onClick={onClick} />;
    }
};

type ButtonProps = {
    onClick: () => void;
};

const ButtonStyleA = (props: ButtonProps) => {
    const { onClick } = props;
    const { t } = useTranslation();

    return (
        <Stack
            justifyContent={'space-between'}
            alignItems={'center'}
            direction={'column'}
            flexWrap={'nowrap'}
            sx={{
                backgroundColor: 'rgba(0,0,0,0.3)',
                width: '70px',
                height: '60px',
                fontSize: '14px',
                padding: '8px 0 8px 0',
                color: '#ffffff',
            }}
            onClick={onClick}
        >
            <BaseImage className="commonUI rebet" />
            <BaseTypography resize={{ direction: 'horizontal', value: '70px' }}>
                {t('game.rebet')}
            </BaseTypography>
        </Stack>
    );
};

const ButtonStyleB = (props: ButtonProps) => {
    const { onClick } = props;

    return (
        <Stack
            justifyContent={'space-between'}
            alignItems={'center'}
            direction={'column'}
            flexWrap={'nowrap'}
            sx={{
                width: '56px',
                height: '56px',
            }}
            onClick={onClick}
        >
            <BaseImage className="commonUI btn_rebet" />
        </Stack>
    );
};
