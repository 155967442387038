import { Check } from '@mui/icons-material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
    Button,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseImage from '../../../components/common/baseImage';
import { AudioPlayContext } from '../../../contexts/AudioPlayContext';
import { GlobalDataContext } from '../../../contexts/GlobalDataContext';
import { VideoPlayerContext } from '../../../contexts/VideoContext';
import { ResolutionType } from '../../../hooks/useVideoPlayer';
import { RootState } from '../../../store/store';
import { getHostById } from '../../host/slice';
import { getLandingState } from '../../main/selector';
import LiveEmojiButton from './liveEmojiButton';
import VideoZoomButton from './videoZoomButton';

type ButtonType = ResolutionType | 'Off' | 'Refresh';
const MenuList = [
    { key: 'HD', text: 'video.HD' },
    { key: 'SD', text: 'video.SD' },
    {
        key: 'Off',
        text: (
            <>
                <BaseImage className="video video_menu_off" />
            </>
        ),
    },
    {
        key: 'Refresh',
        text: (
            <>
                <BaseImage className="video video_menu_refresh" />
            </>
        ),
    },
] as { key: ButtonType; text: string | JSX.Element }[];

const MenuList2 = [
    {
        key: 'Off',
        text: (
            <>
                <BaseImage className="video video_menu_off" />
            </>
        ),
    },
    {
        key: 'Refresh',
        text: (
            <>
                <BaseImage className="video video_menu_refresh" />
            </>
        ),
    },
] as { key: ButtonType; text: string | JSX.Element }[];

const RefreshButton = () => (
    <motion.div
        animate={{ rotate: 1080 }}
        style={{ lineHeight: 0 }}
        transition={{
            repeat: Infinity,
            repeatType: 'loop',
            duration: 2,
        }}
    >
        <AutorenewIcon />
    </motion.div>
);

type Props = {
    haveZoom: boolean;
};
const VideoQualityButton = ({ haveZoom }: Props) => {
    const { t, i18n } = useTranslation();
    const {
        resolution,
        hostId,
        isVideoOff,
        isLoading,
        changeResolution,
        changeVideoOff,
        changeIsLoading,
        killPlayer,
        doRefresh,
    } = useContext(VideoPlayerContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const { GameType } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const { lite } = useSelector(getLandingState);
    const { videoOffList, saveVideoOffList, removeVideoOffList } =
        useContext(GlobalDataContext);
    const id = `video-player-resolution-${hostId}`;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [buttonEl, setButtonEl] = useState<JSX.Element>();
    const [isShowEmoji, setIsShowEmoji] = useState<boolean>(false);
    const [isShowZoom, setIsShowZoom] = useState<boolean>(false);
    const [qualityMenu, setQualityMenu] = useState<Array<JSX.Element>>([]);
    const open = Boolean(anchorEl);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        playButtonAudio();
        if (isVideoOff) {
            removeVideoOffList(GameType);
            doRefresh();
            setButtonEl(<RefreshButton />);
            changeVideoOff(false);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChangeResolution = (type: ButtonType) => {
        playButtonAudio();
        switch (type) {
            case 'Off':
                changeVideoOff(true);
                killPlayer();
                saveVideoOffList(GameType);
                break;
            case 'Refresh':
                doRefresh();
                break;
            default:
                changeResolution(type);
                break;
        }
        handleClose();
    };
    useEffect(() => {
        // lite mode force SD
        if (lite) {
            console.warn('app::video::resolution force SD since lite');
            changeResolution('SD');
        }
    }, []);
    useEffect(() => {
        let btn = <></>;
        if (!videoOffList.includes(GameType)) {
            if (isVideoOff) {
                if (isLoading) {
                    btn = <RefreshButton />;
                    setIsShowEmoji(true);
                    setIsShowZoom(haveZoom);
                } else {
                    btn = <BaseImage className="video video_on" scale={1} />;
                    setIsShowEmoji(false);
                    setIsShowZoom(false);
                }
            } else {
                if (isLoading) {
                    btn = <RefreshButton />;
                } else {
                    btn = (
                        <BaseImage
                            className={`video btn_video_${resolution.toLocaleLowerCase()}`}
                        />
                    );
                }
                setIsShowEmoji(true);
                setIsShowZoom(haveZoom);
            }
        } else {
            changeVideoOff(true);
            changeIsLoading(false);
            killPlayer();
            setIsShowEmoji(false);
            setIsShowZoom(false);
            btn = <BaseImage className="video video_on" scale={1} />;
        }
        setButtonEl(btn);
    }, [resolution, isVideoOff, isLoading, GameType]);
    useEffect(() => {
        const cells = new Array<JSX.Element>();
        const list = isLoading ? MenuList2 : MenuList;
        let index = 0;
        for (const m of list) {
            const label = typeof m.text === 'string' ? t(m.text) : m.text;
            const cell = (
                <MenuItem
                    key={`menu-item-${index}`}
                    onClick={() => handleChangeResolution(m.key)}
                >
                    <ListItemIcon>
                        {resolution === m.key && (
                            <Check sx={{ width: 24, height: 24 }} />
                        )}
                    </ListItemIcon>
                    <ListItemText>{label}</ListItemText>
                </MenuItem>
            );
            cells.push(cell);
            index++;
        }
        setQualityMenu(cells);
    }, [isLoading, i18n.language, resolution]);

    return (
        <>
            {isShowEmoji && <LiveEmojiButton />}
            <Button
                variant="contained"
                disableElevation
                onClick={handleOpen}
                sx={{
                    minWidth: '40px',
                    width: '40px',
                    height: '40px',
                    fontWeight: 'bold',
                    fontSize: '15px',
                    padding: 0,
                }}
            >
                {buttonEl}
            </Button>
            {isShowZoom && <VideoZoomButton />}
            <Menu
                id={id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                sx={{ fontSize: 24 }}
            >
                {qualityMenu}
            </Menu>
        </>
    );
};

export default VideoQualityButton;
