import { memo, useEffect, useState } from 'react';
import { ROAD_MAP_COLORS } from '../../../lobby/constants';
import {
    BaccaratRoadMapCellProps,
    RESOLUTION,
    useBaccaratRoadMapCellColorText,
} from '../hook';
import { motion, useAnimationControls } from 'framer-motion';
const BankerPairCircle = (
    <circle
        cx={RESOLUTION * 0.15}
        cy={RESOLUTION * 0.15}
        r={RESOLUTION * 0.15}
        fill={ROAD_MAP_COLORS.RED}
        stroke={ROAD_MAP_COLORS.WHITE}
        strokeWidth={RESOLUTION * 0.05}
    />
);
const PlayerPairCircle = (
    <circle
        cx={RESOLUTION - RESOLUTION * 0.15}
        cy={RESOLUTION - RESOLUTION * 0.15}
        r={RESOLUTION * 0.15}
        fill={ROAD_MAP_COLORS.BLUE}
        stroke={ROAD_MAP_COLORS.WHITE}
        strokeWidth={RESOLUTION * 0.05}
    />
);
const BeadRoadCell = (props: BaccaratRoadMapCellProps) => {
    const {
        record: { Win, BankerPair, PlayerPair, WinPoint, AskType },
    } = props;
    const { color, text } = useBaccaratRoadMapCellColorText(Win, WinPoint);
    const [askColor, setAskColor] = useState(ROAD_MAP_COLORS.RED);
    const askRoadAnimation = useAnimationControls();
    const anim = () => {
        askRoadAnimation.start({
            opacity: [1, 1, 0, 1, 1, 0],
            transition: { duration: 1 },
        });
    };
    useEffect(() => {
        if (AskType === 0) {
            setAskColor(ROAD_MAP_COLORS.RED);
        } else if (AskType === 1) {
            setAskColor(ROAD_MAP_COLORS.BLUE);
        }
        if (AskType >= 0) {
            anim();
        }
    }, [AskType]);
    return (
        <svg
            viewBox={`0 0 ${RESOLUTION} ${RESOLUTION}`}
            className="roadmap-cell"
        >
            {Win >= 0 && (
                <>
                    <circle
                        cx={RESOLUTION / 2}
                        cy={RESOLUTION / 2}
                        r={RESOLUTION / 2}
                        fill={color}
                    />
                    <text
                        x={RESOLUTION / 2}
                        y={RESOLUTION / 2 + 1}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill="#ffffff"
                        fontSize={RESOLUTION * 0.75}
                    >
                        {text}
                    </text>
                    {BankerPair && BankerPairCircle}
                    {PlayerPair && PlayerPairCircle}
                </>
            )}
            {AskType >= 0 && (
                <motion.circle
                    animate={askRoadAnimation}
                    initial={{ opacity: 0 }}
                    cx={RESOLUTION / 2}
                    cy={RESOLUTION / 2}
                    r={RESOLUTION / 2}
                    fill={askColor}
                />
            )}
        </svg>
    );
};
const areEquals = (
    prev: BaccaratRoadMapCellProps,
    next: BaccaratRoadMapCellProps
) => {
    const isSameWin = prev.record.Win === next.record.Win;
    const isSamePoint = prev.record.WinPoint === next.record.WinPoint;
    const isSamePlayerPair = prev.record.PlayerPair === next.record.PlayerPair;
    const isSameBankerPair = prev.record.BankerPair === next.record.BankerPair;
    return isSameWin && isSamePoint && isSamePlayerPair && isSameBankerPair;
};
export default memo(BeadRoadCell, areEquals);
