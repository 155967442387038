import { Stack } from '@mui/material';
import VideoControlPanel from '../../../../videoPlayer/components/videoControlPanel';
import { SwitchTableButton } from '../../SwitchTableButton';
import { useSelector } from 'react-redux';
import { getLandingState } from '../../../../main/selector';
import { useContext } from 'react';
import { CommonConfigContext } from '../../../../../contexts/ConfigContext';

type VideoControlPanelOwnProps = {
    isHorizontal: boolean;
};

export const TableInfoHeader = (props: VideoControlPanelOwnProps) => {
    const { lite } = useSelector(getLandingState);
    const { isHorizontal } = props;
    const { hideSwitchTable } = useContext(CommonConfigContext);

    return (
        <>
            <Stack
                sx={{
                    position: 'absolute',
                    top: 345,
                    width: 100,
                }}
            >
                <VideoControlPanel
                    haveZoom={false}
                    isHorizontal={isHorizontal}
                />
            </Stack>
            <Stack
                sx={{
                    position: 'absolute',
                    top: '285px',
                    right: '10px',
                    width: '40px',
                    height: '40px',
                }}
            >
                {!lite && !hideSwitchTable && <SwitchTableButton />}
            </Stack>
        </>
    );
};
