import { Box, Grid } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Chip } from '../../../../../components/common/chips';
import { BetChipContext } from '../../../../../contexts/BetChipContext';
import { SeatAreaContext } from '../../../../../contexts/SeatAreaContext';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import { WebSocketContext } from '../../../../../contexts/WebSocketContext';
import { useBalance } from '../../../../../hooks/useBalance';
import { useBetSource } from '../../../../../hooks/useBetSource';
import { useSeatBet } from '../../../../../hooks/useSeatBet';
import { StateMsgType } from '../../../../../models/Popup';
import {
    CMDPsBet,
    CMDPsRoomSeat,
    CMDPsStandUp,
} from '../../../../../models/cmd/live';
import { CMDBet } from '../../../../../models/cmd/live/CMDBet';
import { BlackjackBetType } from '../../../../../models/games/blackjack';
import { GameState as CommonState } from '../../../../../models/games/enums/GameState';
import { GameType } from '../../../../../models/games/enums/GameType';
import { RootState } from '../../../../../store/store';
import { getHostById } from '../../../../host/slice';
import { inGamePopupSliceActions } from '../../../../popup/inGameSlice';
import { BetAmount } from './BetAmount';
import './BetOptions.scss';
import { SeatLabel } from './Seat/SeatLabel';

const newBet = (betType: BlackjackBetType, betAmount: number) => {
    const command = new CMDBet();
    command.betType = betType;
    command.betAmount = betAmount * 100;
    return command;
};

export const BetOptions = (): JSX.Element => {
    const dispatch = useDispatch();
    const { hostId } = useContext(VideoPlayerContext);
    const { selectedSeat, showBet, currentState, isBetBehind, inSeat } =
        useContext(SeatAreaContext);
    const { selectedBetAmount, selectedBetRule } = useContext(BetChipContext);
    const { sendCommand } = useContext(WebSocketContext);
    const { t } = useTranslation();
    const { CurrentResult, MaxBet, Multiplier } = useSelector(
        (state: RootState) => getHostById(state, hostId)
    );
    const currentMaxBet = useMemo(() => MaxBet ?? [], [MaxBet]);
    const betSource = useBetSource();
    const { availableBalance } = useBalance();
    const { split1Amount, pairAmount, behindSplit1Amount, behindPairAmount } =
        useSeatBet(selectedSeat);
    const [mainBet, setMainBet] = useState(0);
    const [pairBet, setPairBet] = useState(0);

    const [enablePairBet, setEnablePairBet] = useState(false);
    const [isFinishBet, setIsFinishBet] = useState(false);
    const [mainBetType, setMainBetType] = useState<BlackjackBetType>();
    const [pairBetType, setPairBetType] = useState<BlackjackBetType>();

    const [hasAllIn, setHasAllIn] = useState(false);
    const [hasOverLimit, setHasOverLimit] = useState(false);
    const [hasPairOverLimit, setHasPairOverLimit] = useState(false);
    const clearBet = () => {
        setHasOverLimit(false);
        setHasAllIn(false);
        setHasPairOverLimit(false);

        setMainBet(0);
        setPairBet(0);
        setIsFinishBet(false);
    };
    const placeBet = () => {
        const multiplier = Multiplier ?? 1;
        const seatChange = inSeat !== selectedSeat;
        if (seatChange) {
            const command = new CMDPsStandUp();
            command.HostID = hostId;
            sendCommand(command);
        }

        const bets = new Array<CMDBet>();
        if (mainBet > 0 && mainBetType !== undefined) {
            bets.push(newBet(mainBetType, mainBet / 100));
        }
        if (pairBet > 0 && pairBetType) {
            bets.push(newBet(pairBetType, pairBet / 100));
        }
        if (selectedSeat && CurrentResult && bets.length > 0) {
            if (selectedBetRule) {
                for (let bet of bets) {
                    const isSeatBet =
                        !isBetBehind && bet.betType === mainBetType;
                    const minBet = isSeatBet
                        ? selectedBetRule.MinBet * multiplier
                        : selectedBetRule.MinBet;
                    const isLowerThenMinBet = bet.betAmount < minBet;
                    console.log('blackjack::test', isBetBehind);
                    if (isLowerThenMinBet) {
                        if (isSeatBet)
                            dispatch(
                                inGamePopupSliceActions.open(
                                    'system.bet_multiplier',
                                    GameType.Blackjack,
                                    StateMsgType.betInfo_Normal,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    {
                                        minBet: (minBet / 100).toLocaleString(
                                            'en-US'
                                        ),
                                    }
                                )
                            );
                        else
                            dispatch(
                                inGamePopupSliceActions.open(
                                    'system.bet_below_win_bet',
                                    GameType.Blackjack,
                                    StateMsgType.betInfo_Fail
                                )
                            );
                        return;
                    }
                }
            }
            const seatCMD = new CMDPsRoomSeat();
            seatCMD.HostID = hostId;
            seatCMD.Seat = 100 + selectedSeat;
            seatCMD.IsMultiBet = 0;

            const betCMD = new CMDPsBet();
            betCMD.gameID = CurrentResult.GameID;
            betCMD.BetSource = betSource;
            betCMD.bets = bets;
            sendCommand(seatCMD);
            sendCommand(betCMD);
            clearBet();
        }
    };
    const getMaxBetAmount = (
        isMainBet: boolean,
        amount: number,
        ignoreHas: boolean = false
    ) => {
        if (amount > 0) {
            const betType = isMainBet ? mainBetType : pairBetType;
            const limit = currentMaxBet[betType ?? 0]?.MaxBet ?? 0;
            const balance = availableBalance * 100;
            const allInAmount = Math.min(
                Math.floor(availableBalance) * 100,
                limit
            );
            const gameType = GameType.Blackjack;

            const isOverBalance = selectedBetAmount > balance;
            const isTotalBetOverLimit = amount > limit;
            const isTotalBetOverBalance = mainBet + pairBet + amount > balance;

            const isHasOverLimit = isMainBet ? hasOverLimit : hasPairOverLimit;
            const isFullBet =
                Math.floor(availableBalance) * 100 === mainBet + pairBet;

            const m = isMainBet ? pairBet : mainBet;
            const mAmount = Math.min(
                Math.floor((balance - m) / 100) * 100,
                limit
            );
            if (isOverBalance || isTotalBetOverBalance || isTotalBetOverLimit) {
                if ((hasAllIn || isFullBet) && !ignoreHas) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.not_enough_money',
                            gameType,
                            StateMsgType.betInfo_Fail
                        )
                    );
                    return 0;
                } else if (isHasOverLimit && !ignoreHas) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.bet_out_of_limit_red',
                            gameType,
                            StateMsgType.betInfo_Fail
                        )
                    );
                    return allInAmount;
                } else if (isTotalBetOverLimit) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.bet_exceed_limit_allin',
                            gameType,
                            StateMsgType.betInfo_Warning
                        )
                    );
                    isMainBet
                        ? setHasOverLimit(true)
                        : setHasPairOverLimit(true);
                    return mAmount;
                } else if (isOverBalance || isTotalBetOverBalance) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.all_in',
                            gameType,
                            StateMsgType.betInfo_Warning
                        )
                    );
                    setHasAllIn(true);
                    if (isTotalBetOverBalance) {
                        return mAmount;
                    }
                    return allInAmount;
                }
            }
        }
        return amount;
    };
    useEffect(() => {
        if (currentState === CommonState.Betting) {
            clearBet();
        }
    }, [currentState]);
    useEffect(() => {
        // reset
        clearBet();
        if (selectedSeat) {
            // set bet type
            setMainBetType(BlackjackBetType[`BJB${selectedSeat}_Split1`]);
            setPairBetType(BlackjackBetType[`BJB${selectedSeat}_Pair`]);
            // set bet amount
            if (!isBetBehind) {
                const amount = getMaxBetAmount(true, selectedBetAmount, true);
                if (amount > 0) setMainBet(amount);
            }
        }
    }, [selectedSeat, isBetBehind]);
    useEffect(() => {
        const e = mainBet > 0 || split1Amount > 0 || isBetBehind;
        setEnablePairBet(e);
    }, [mainBet, split1Amount]);
    useEffect(() => {
        const isBehindFinish = behindSplit1Amount > 0 && behindPairAmount > 0;
        const isMainFinish = split1Amount > 0 && pairAmount > 0;
        const fb = isBetBehind ? isBehindFinish : isMainFinish;
        setIsFinishBet(fb);
    }, [
        isBetBehind,
        split1Amount,
        pairAmount,
        behindSplit1Amount,
        behindPairAmount,
    ]);
    return showBet &&
        selectedSeat &&
        mainBetType !== undefined &&
        pairBetType !== undefined &&
        !isFinishBet ? (
        <Box
            sx={{
                position: 'absolute',
                width: '100%',
                top: '470px',
            }}
        >
            <Grid container justifyContent={'center'}>
                <div className={'bet-options property-1-default'}>
                    <SeatLabel sn={selectedSeat} />
                    <div className="bet-options__bet-area-container">
                        <svg
                            className="bet-options__bet-area"
                            width="120"
                            height="113"
                            viewBox="0 0 120 113"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                onClick={() => {
                                    if (
                                        split1Amount === 0 ||
                                        (isBetBehind &&
                                            behindSplit1Amount === 0)
                                    ) {
                                        const amount = getMaxBetAmount(
                                            true,
                                            mainBet + selectedBetAmount
                                        );
                                        if (amount > 0) setMainBet(amount);
                                    }
                                }}
                            >
                                <path
                                    d="M104 71C104 93.9198 84.5244 112.5 60.5 112.5C36.4756 112.5 17 93.9198 17 71C17 48.0802 36.4756 30.25 60.5 30.25C84.5244 30.25 104 48.0802 104 71Z"
                                    fill="#078743"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M60.5 109.5C83.0022 109.5 101 92.1315 101 71C101 49.8685 83.0022 32.5 60.5 32.5C37.9978 32.5 20 49.8685 20 71C20 92.1315 37.9978 109.5 60.5 109.5ZM60.5 112.5C84.5244 112.5 104 93.9198 104 71C104 48.0802 84.5244 29.5 60.5 29.5C36.4756 29.5 17 48.0802 17 71C17 93.9198 36.4756 112.5 60.5 112.5Z"
                                    fill="#BAA378"
                                />
                                {isBetBehind && (
                                    <foreignObject
                                        x={30}
                                        y={50}
                                        width={60}
                                        height={45}
                                        style={{ textAlign: 'center' }}
                                    >
                                        <p style={{ marginTop: 0 }}>
                                            {t('blackjack.bet.behind')}
                                        </p>
                                    </foreignObject>
                                )}
                            </g>
                            <g
                                onClick={() => {
                                    if (enablePairBet) {
                                        const amount = getMaxBetAmount(
                                            false,
                                            pairBet + selectedBetAmount
                                        );
                                        if (amount > 0) setPairBet(amount);
                                    }
                                }}
                            >
                                <path
                                    d="M0 31.8767L25 52.5C25 52.5 37.5 33.0834 61 33.2342C84.5 33.3851 95.5 51.8676 95.5 51.8676L120 33.0834C109.606 21.0155 90.5263 0.5 60.4724 0.5C27.3684 0.5 8.8189 22.2224 0 31.8767Z"
                                    fill={enablePairBet ? '#078743' : '#0B4527'}
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M95.5 51.8676C95.5 51.8676 94.9273 50.9053 93.7641 49.4182C89.8295 44.3876 79.138 33.3507 61 33.2342C42.6705 33.1166 31.0331 44.9031 26.7994 50.0953C25.6051 51.56 25 52.5 25 52.5L0 31.8767C0.307487 31.5401 0.626802 31.1888 0.958152 30.8243C1.2999 30.4484 1.65445 30.0583 2.02203 29.6557C11.5196 19.2543 29.7131 0.5 60.4724 0.5C88.7155 0.5 107.267 18.6178 118.026 30.8164C118.716 31.5985 119.374 32.3563 120 33.0834L95.5 51.8676ZM96.145 47.5928L115.639 32.6464C104.97 20.5799 87.2354 3.5 60.4724 3.5C31.1744 3.5 13.7975 21.2297 4.34003 31.5679L24.4852 48.1863C24.6562 47.9767 24.838 47.7577 25.0306 47.5301C26.6684 45.5956 29.1031 43.0284 32.3169 40.4652C38.7433 35.3396 48.3906 30.1532 61.0193 30.2343C73.6101 30.3151 82.8764 35.3212 88.9588 40.2828C91.994 42.7587 94.2474 45.2306 95.7498 47.0947C95.8878 47.2659 96.0195 47.432 96.145 47.5928Z"
                                    fill="#BAA378"
                                />

                                {enablePairBet && (
                                    <text
                                        x={40}
                                        y={25}
                                        className="bet-options__bet-area__pair-label"
                                    >
                                        {t('blackjack.bet.pair').toUpperCase()}
                                    </text>
                                )}
                            </g>
                        </svg>
                        {mainBet > 0 && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: '37px',
                                    top: '63px',
                                    pointerEvents: 'none',
                                }}
                            >
                                <BetAmount amount={mainBet / 100} />
                            </Box>
                        )}
                        {pairBet > 0 && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '25px',
                                    left: '53px',
                                    pointerEvents: 'none',
                                }}
                            >
                                <BetAmount amount={pairBet / 100} scale={0.5} />
                            </Box>
                        )}
                        {split1Amount > 0 && !isBetBehind && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: '6px',
                                    top: '26px',
                                    pointerEvents: 'none',
                                }}
                            >
                                <Chip value={split1Amount} isShowValue={true} />
                            </Box>
                        )}
                        {behindSplit1Amount > 0 && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: '6px',
                                    top: '26px',
                                    pointerEvents: 'none',
                                }}
                            >
                                <Chip
                                    value={behindSplit1Amount}
                                    isShowValue={true}
                                />
                            </Box>
                        )}
                    </div>
                    <div className="bet-options__button-panel">
                        {mainBet > 0 || pairBet > 0 ? (
                            <>
                                <div
                                    className="bet-options__button"
                                    onClick={clearBet}
                                >
                                    <div className="bet-options__clear">
                                        <div className="bet-options__icon-close">
                                            <svg
                                                className="bet-options__group"
                                                width="40"
                                                height="41"
                                                viewBox="0 0 40 41"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M19.9973 18.1436L28.2473 9.89355L30.604 12.2502L22.354 20.5002L30.604 28.7502L28.2473 31.1069L19.9973 22.8569L11.7473 31.1069L9.39062 28.7502L17.6406 20.5002L9.39062 12.2502L11.7473 9.89355L19.9973 18.1436Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="bet-options__ellipse-10"></div>
                                </div>
                                <div
                                    className="bet-options__button"
                                    onClick={placeBet}
                                >
                                    <div className="bet-options__confirm">
                                        <div className="bet-options__icon-check">
                                            <svg
                                                className="bet-options__group2"
                                                width="27"
                                                height="20"
                                                viewBox="0 0 27 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M9.9998 14.6115L24.4433 0.166504L26.6667 2.38833L9.9998 19.0552L0 9.05539L2.22183 6.83356L9.9998 14.6115Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="bet-options__ellipse-102"></div>
                                </div>
                            </>
                        ) : (
                            <div className="bet-options__button-panel-empty"></div>
                        )}
                    </div>
                </div>
            </Grid>
        </Box>
    ) : (
        <></>
    );
};
