import { Grid } from '@mui/material';

type UsernameGridProps = {
    username: string;
};

export const UsernameGrid = (props: UsernameGridProps) => {
    const { username } = props;
    return (
        <Grid
            item
            container
            justifyContent="center"
            alignItems={"center"}
            sx={{ backgroundColor: '#231f16', height: '30px'}}
        >
            {username}
        </Grid>
    );
};
