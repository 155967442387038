import BScroll from '@better-scroll/core';
import { BScrollConstructor } from '@better-scroll/core/dist/types/BScroll';
import Slide from '@better-scroll/slide';
import { AppBar, Box, Grid, Stack, Tab, Tabs } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseImage from '../../../../components/common/baseImage';
import { AudioPlayContext } from '../../../../contexts/AudioPlayContext';
import { GlobalDataContext } from '../../../../contexts/GlobalDataContext';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import { useECard } from '../../../../hooks/useECard';
import { useTableInfo } from '../../../../hooks/useTableInfo';
import { BetType } from '../../../../models/games/baccarat/BetType';
import { GameType } from '../../../../models/games/enums/GameType';
import { RootState } from '../../../../store/store';
import { ExchangeBetAmount } from '../../../../utils/commonFunc';
import { limitRedFormatter } from '../../../../utils/formatter';
import { getHostById } from '../../../host/slice';
import { InGameRoadMap } from '../../../lobby/components/Roadmaps';
import { REPORT_COLORS, ROAD_MAP_TYPE } from '../../../lobby/constants';
import { getMainLimitRedState, getMainUserState } from '../../../main/selector';
import { BaccaratECard } from '../ECard/BaccaratECard';
import './BaccaratTableInfoPanel.scss';
import { getMaxBetByBetType } from './util';

export const BaccaratTableInfoPanel = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { baccaratRoadMapType, setBaccaratRoadMapType } =
        useContext(GlobalDataContext);
    const { t, i18n } = useTranslation();
    const show = useECard(hostId);
    //useState
    const [tableInfoTab, setTableInfoTab] = useState<number>(1);
    const { tableInfo, setTableInfo } = useTableInfo();
    // const [roadMapType, setRoadMapType] = useState<ROAD_MAP_TYPE>(
    //     ROAD_MAP_TYPE.BEAD_ROAD
    // );issue #104
    const ref = useRef<HTMLDivElement>(null);
    const [scrollObj, setScrollObj] = useState<BScrollConstructor>();
    const { playButtonAudio } = useContext(AudioPlayContext);
    const handleChange = (v: number) => {
        if (v === 0) {
            //RoadmapTypeButton
            // setRoadMapType(1);
        } else {
            playButtonAudio();
            if (scrollObj) {
                setTableInfoTab(v);
                setTableInfo(v == 1 ? 'roadmap' : 'tableInfo');
                scrollObj.goToPage(v - 1, 0);
            }
        }
    };
    const handleOnClick = () => {
        setBaccaratRoadMapType((baccaratRoadMapType: ROAD_MAP_TYPE) =>
            baccaratRoadMapType == ROAD_MAP_TYPE.BEAD_ROAD
                ? ROAD_MAP_TYPE.POINT_ROAD
                : ROAD_MAP_TYPE.BEAD_ROAD
        );
    };
    const getRoadmapTypeIcon = () => {
        if (baccaratRoadMapType == ROAD_MAP_TYPE.BEAD_ROAD) {
            return 'tradbaccRoadmapBg button_number';
        } else {
            if (i18n.language == 'zh-CN') {
                return 'tradbaccRoadmapBg button_bigroad_sc';
            } else {
                return 'tradbaccRoadmapBg button_bigroad_en';
            }
        }
    };

    useEffect(() => {
        if (ref && ref.current) {
            let numLsTab = 0;
            if (tableInfo === 'tableInfo') {
                numLsTab = 1;
            } else {
                setTableInfo('roadmap');
            }
            setTableInfoTab(numLsTab + 1);
            BScroll.use(Slide);
            const newBScroll = new BScroll(ref.current as HTMLDivElement, {
                scrollX: true,
                scrollY: false,
                click: true,
                slide: {
                    loop: false,
                    autoplay: false,
                    threshold: 100,
                    startPageXIndex: numLsTab,
                },
                momentum: false,
            });
            newBScroll.on('slidePageChanged', (page: { pageX: number }) => {
                if (page) {
                    const tab = page.pageX;
                    setTableInfoTab(tab + 1);
                    setTableInfo(tab == 0 ? 'roadmap' : 'tableInfo');
                }
            });
            setScrollObj(newBScroll);
        }
        return () => {
            if (ref && ref.current && scrollObj) {
                scrollObj.destroy();
            }
        };
    }, [ref]);
    return (
        <>
            <Box className="table-info">
                <AppBar>
                    <Tabs
                        className="table-info-tabs"
                        value={tableInfoTab}
                        onChange={(_, v) => {
                            handleChange(v);
                        }}
                        aria-label="roadmap & table info."
                        sx={{ '& Button': { color: REPORT_COLORS.TITLE_TEXT } }}
                    >
                        <Tab
                            className="roadmap-type-button"
                            icon={
                                <>
                                    <Box
                                        onClick={handleOnClick}
                                        sx={{
                                            minWidth: '35px',
                                            width: '35px',
                                            height: '28px',
                                            padding: 0,
                                        }}
                                    >
                                        <BaseImage
                                            className={getRoadmapTypeIcon()}
                                        />
                                    </Box>
                                </>
                            }
                        />
                        <Tab
                            className="table-info-tab1"
                            label={t('road_map')}
                        />
                        <Tab
                            className="table-info-tab2"
                            label={t('tableinfo.name')}
                        />
                    </Tabs>
                </AppBar>
                <Box
                    ref={ref}
                    sx={{
                        overflow: 'hidden',
                    }}
                >
                    <Box sx={{ display: 'inline-block' }}>
                        <Box
                            sx={{
                                display: 'inline-block',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <>
                                <InGameRoadMap
                                    hostId={hostId}
                                    roadMapType={baccaratRoadMapType}
                                    gameType={GameType.Baccarat}
                                />
                                <button
                                    onClick={handleOnClick}
                                    style={{
                                        top: 0,
                                        position: 'absolute',
                                        width: '100%',
                                        height: 168,
                                        opacity: 0,
                                    }}
                                />
                            </>
                        </Box>

                        <Box
                            sx={{
                                display: 'inline-block',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <BaccaratTableInfoTab />
                        </Box>
                    </Box>
                </Box>
                <BaccaratECard show={show} isMultiBet={false} />
            </Box>
        </>
    );
};

type InfoProps = {
    title: string;
    value: string | number;
};
type TableInfoTypeRow1 = {
    table: InfoProps;
    dealer: InfoProps;
    round: InfoProps;
    id: InfoProps;
};
type TableInfoTypeRow2 = {
    betLimit: InfoProps;
    tableLimit: InfoProps;
    banker: InfoProps;
    player: InfoProps;
};
type TableInfoTypeRow3 = {
    tie: InfoProps;
    pair: InfoProps;
    luckySix: InfoProps;
    natural: InfoProps;
};
type TableInfoType = [TableInfoTypeRow1, TableInfoTypeRow2, TableInfoTypeRow3];
const initTableInfoData = [
    {
        table: {
            title: 'tableinfo.table',
            value: '',
        },
        dealer: {
            title: 'tableinfo.dealer',
            value: '',
        },
        round: {
            title: 'tableinfo.shoe',
            value: '',
        },
        id: {
            title: 'tableinfo.round',
            value: '',
        },
    },
    {
        betLimit: {
            title: 'betLimit',
            value: '',
        },
        tableLimit: {
            title: 'tableinfo.tablelimit',
            value: '',
        },
        banker: {
            title: 'betlog.bet_bacc.2',
            value: '',
        },
        player: {
            title: 'betlog.bet_bacc.1',
            value: '',
        },
    },
    {
        tie: {
            title: 'betlog.bet_bacc.0',
            value: '',
        },
        pair: {
            title: 'tableinfo.pair',
            value: '',
        },
        luckySix: {
            title: 'betlog.bet_bacc.54',
            value: '',
        },
        natural: {
            title: 'tableinfo.natural',
            value: '',
        },
    },
] as TableInfoType;
const BaccaratTableInfoTab = () => {
    const { t } = useTranslation();
    const { hostId } = useContext(VideoPlayerContext);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { CurrentResult, DealerList, TableLimit, GameType, MaxBet } = host;
    const { ExchangeRate, CurrencyName } = useSelector((state: RootState) =>
        getMainUserState(state)
    );
    const limitRedByGameType = useSelector(getMainLimitRedState);
    const [tableInfo, setTableInfo] =
        useState<TableInfoType>(initTableInfoData);

    useEffect(() => {
        if (MaxBet) {
            let newTableInfo: TableInfoType = Object.create(tableInfo);

            newTableInfo[1].banker.value = getMaxBetByBetType(
                BetType.BBBankerWin,
                MaxBet
            );
            newTableInfo[1].player.value = getMaxBetByBetType(
                BetType.BBPlayerWin,
                MaxBet
            );
            //row 3
            newTableInfo[2].tie.value = getMaxBetByBetType(
                BetType.BBTie,
                MaxBet
            );
            newTableInfo[2].pair.value = getMaxBetByBetType(
                BetType.BBPlayerPair,
                MaxBet
            );
            newTableInfo[2].luckySix.value = getMaxBetByBetType(
                BetType.BBSLuckySix,
                MaxBet
            );
            newTableInfo[2].natural.value = getMaxBetByBetType(
                BetType.BBBankerNatural,
                MaxBet
            );
            setTableInfo(newTableInfo);
        }
    }, [MaxBet]);
    useEffect(() => {
        if (
            hostId &&
            DealerList &&
            TableLimit &&
            CurrentResult &&
            limitRedByGameType.BetRuleByGameType
        ) {
            let newTableInfo: TableInfoType = Object.create(tableInfo);
            //row 1
            newTableInfo[0].table.value = t(`hostCodeNames.${hostId}`);
            newTableInfo[0].dealer.value = DealerList[0].DealerName;
            const shoes = Math.floor(CurrentResult.GameCount / 10000);
            const round = CurrentResult.GameCount % 10000;
            const row1Round = shoes && round ? `${shoes}-${round}` : round;
            newTableInfo[0].round.value = row1Round;
            newTableInfo[0].id.value = CurrentResult.GameID || 0;
            //row 2
            let betLimitV = '';
            const limitRedByGame = limitRedByGameType.BetRuleByGameType?.find(
                br => br.GameType === GameType
            );
            if (limitRedByGame) {
                const BetRule = limitRedByGame.BetRule;
                let selectedBetRule = BetRule.find(r => r.Selected === 1);
                if (!selectedBetRule) selectedBetRule = BetRule[0]; //有機會全部selected=0
                if (selectedBetRule) {
                    betLimitV = limitRedFormatter(
                        selectedBetRule,
                        CurrencyName
                    );
                }
            }
            newTableInfo[1].betLimit.value = betLimitV;
            let infoTableLimit =
                ExchangeBetAmount(TableLimit, ExchangeRate) / 100;
            const digi = Math.pow(10, infoTableLimit.toString().length - 1);
            const tableLimitValue = (
                Math.round(infoTableLimit / digi) * digi
            ).toLocaleString('en-US', {
                maximumFractionDigits: 2,
            });
            newTableInfo[1].tableLimit.value = tableLimitValue;

            setTableInfo(newTableInfo);
        }
    }, [
        hostId,
        CurrentResult,
        DealerList,
        TableLimit,
        limitRedByGameType.BetRuleByGameType,
    ]);
    return (
        <>
            {tableInfo && (
                <>
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        sx={{ fontSize: '14px' }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                            }}
                        >
                            <BaseImage
                                className="tradbaccBg table_info_bg"
                                scale={1}
                                opacity={0.5}
                                // 18px = (196 - 161) / 2
                                // 1.226 = 196 / 161
                                transform={
                                    'translate(0, 17.5px) scale(1, 1.226)'
                                }
                            />
                        </Box>
                        {tableInfo.map((r, i) => {
                            const style =
                                i !== 1
                                    ? { width: '33%', zIndex: '1' }
                                    : {
                                          width: '34%',
                                          zIndex: '1',
                                          borderLeft:
                                              '2px solid rgba(255,255,255,0.2)',
                                          borderRight:
                                              '2px solid rgba(255,255,255,0.2)',
                                      };
                            return (
                                <Box key={`info-${i}`} sx={style}>
                                    <>
                                        {Object.keys(r).map((k, ii) => {
                                            const v = r[
                                                k as keyof typeof r
                                            ] as InfoProps;
                                            return (
                                                <Grid
                                                    key={`info-cell${ii}`}
                                                    item
                                                    container
                                                    justifyContent={
                                                        'space-between'
                                                    }
                                                    alignItems={'center'}
                                                    sx={{
                                                        height: '49px',
                                                        padding:
                                                            '0 7.5px 0 7.5px',
                                                    }}
                                                >
                                                    <Grid
                                                        item
                                                        sx={{
                                                            color: REPORT_COLORS.TITLE_TEXT,
                                                        }}
                                                    >
                                                        {t(v.title)}
                                                    </Grid>
                                                    <Grid item>{v.value}</Grid>
                                                </Grid>
                                            );
                                        })}
                                    </>
                                </Box>
                            );
                        })}
                    </Stack>
                </>
            )}
        </>
    );
};
