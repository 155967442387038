import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GameType } from '../models/games/enums/GameType';
import { getLobbyStateHallGameTypeMap } from '../modules/lobby/selector';
type LobbyGame = {
    haveSpecialGame: boolean;
    haveAutoBaccaratGame: boolean;
    haveOtherGame: boolean;
    havePokDeng: boolean;
    haveMultiBet: boolean;
    haveLiveGame: boolean;
    haveEGame: boolean;
    haveMultiPlayer: boolean;
};
const initLobbyGame: LobbyGame = {
    haveSpecialGame: false,
    haveAutoBaccaratGame: false,
    haveOtherGame: false,
    havePokDeng: false,
    haveMultiBet: false,
    haveLiveGame: false,
    haveEGame: false,
    haveMultiPlayer: false,
};
// all logic is form egret version game client
// LiveGameClientPremiumMobile/src/scenes/components/lobby/LobbyGameMenu3.ts
export const useLobbyGame = () => {
    const [lobbyGame, setLobbyGame] = useState(initLobbyGame);
    const hosts = useSelector(getLobbyStateHallGameTypeMap);
    useEffect(() => {
        const lg = initLobbyGame;
        const gameTypes = hosts.map(h => h.gameType);
        lobbyGame.haveLiveGame = hosts.length > 0;
        // exclude baccarat
        lobbyGame.haveOtherGame =
            gameTypes.filter(g => g !== GameType.Baccarat).length > 0;
        // include pok deng
        lobbyGame.havePokDeng = gameTypes.includes(GameType.PokDeng);
        // include baccarat, dragon tiger, sic bo, roulette or pok deng
        lobbyGame.haveMultiBet = gameTypes.includes(GameType.Baccarat);
        console.log('app::hook::lobbygame', lg);
        setLobbyGame(lg);
    }, [hosts]);
    return lobbyGame;
};
