import { Grid } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { LobbyUiContext } from '../../../contexts/LobbyUiContext';
import { BlackjackRecord } from '../../../models/games/blackjack';
import { RootState } from '../../../store/store';
import { getHostById } from '../../host/slice';
import CardCell from './cell/CardCell';
import PointCell from './cell/PointCell';
import './index.scss';
type BlackjackRoadMapProps = {
    hostId: number;
};
export const BlackjackRoadMap = ({ hostId }: BlackjackRoadMapProps) => {
    const { Record, IsRest } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const { grid } = useContext(LobbyUiContext);
    const isOneColumn = useMemo(() => grid === 12, [grid]);
    const columnNum = useMemo(() => (isOneColumn ? 10 : 6), [isOneColumn]);
    const [display, setDisplay] = useState(<></>);
    useEffect(() => {
        let r = new Array<BlackjackRecord>();
        const cells = new Array<JSX.Element>();
        if (Record) {
            for (const cr of Record) {
                const bjr = cr as BlackjackRecord;
                r.push(bjr);
            }
        }
        r = r.length > columnNum ? r.slice(-columnNum) : r;
        const lastIndex = Math.min(r.length, columnNum);
        for (let index = 0; index < columnNum; index++) {
            const record = IsRest
                ? undefined
                : (r.at(index) as BlackjackRecord | undefined);
            const isLast = lastIndex === index + 1 && !IsRest;
            const cell = (
                <BlackjackRoadMapColumn
                    record={record}
                    isLast={isLast}
                    show={isOneColumn ? 6 : 3}
                    key={`game-id-${hostId}-${index}`}
                />
            );
            cells.push(cell);
        }

        setDisplay(<>{cells}</>);
    }, [Record, columnNum, IsRest]);
    return (
        <div
            className={
                isOneColumn
                    ? 'one-column-main-container'
                    : 'two-column-main-container'
            }
        >
            <div className="blackjack-road">{display}</div>
        </div>
    );
};
export const BlackjackSwitchTableMap = ({ hostId }: BlackjackRoadMapProps) => {
    const columnNum = 6;
    const { Record, IsRest } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [display, setDisplay] = useState(<></>);
    useEffect(() => {
        let r = new Array<BlackjackRecord>();
        const cells = new Array<JSX.Element>();
        if (Record) {
            for (const cr of Record) {
                const bjr = cr as BlackjackRecord;
                r.push(bjr);
            }
        }
        r = r.length > columnNum ? r.slice(-columnNum) : r;
        const lastIndex = Math.min(r.length, columnNum);
        for (let index = 0; index < columnNum; index++) {
            const record = IsRest
                ? undefined
                : (r.at(index) as BlackjackRecord | undefined);
            const isLast = lastIndex === index + 1 && !IsRest;
            const cell = (
                <BlackjackRoadMapColumn
                    record={record}
                    isLast={isLast}
                    show={4}
                    key={`game-id-${hostId}-${index}`}
                />
            );
            cells.push(cell);
        }

        setDisplay(<>{cells}</>);
    }, [Record, IsRest]);
    return (
        <div className={'switch-table-container'}>
            <div className="blackjack-road">{display}</div>
        </div>
    );
};

type BlackjackRoadMapColumnProps = {
    record: BlackjackRecord | undefined;
    isLast: boolean;
    show: number;
};
const BlackjackRoadMapColumn = ({
    record,
    isLast,
    show,
}: BlackjackRoadMapColumnProps) => {
    return (
        <Grid
            container
            direction={'column'}
            className={`blackjack-column ${isLast && 'blackjack-column-last'}`}
            justifyContent={'space-between'}
        >
            <Grid>
                {record?.bankerHand?.pokers &&
                    record.bankerHand.pokers.map((p, i) => {
                        if (show > i) {
                            return (
                                <CardCell
                                    key={`blackjack-row-${i}`}
                                    cardIndex={record.bankerHand?.pokers.at(i)}
                                />
                            );
                        }
                    })}
            </Grid>
            <Grid>
                <PointCell point={record?.bankerHand?.point} />
            </Grid>
        </Grid>
    );
};
