import { Stack, Typography } from '@mui/material';

type AndarBaharBetRatePanelProps = {
    type: BetRateType;
    andarTitle: string;
    baharTitle: string;
    andarWinPercentage: string;
    baharWinPercentage: string;
    gameCard?: number | undefined;
};

export enum BetRateType {
    ABWinRate,
    GameCardABRate,
}

export const AndarBaharBetRatePanel = ({
    type,
    andarTitle,
    baharTitle,
    andarWinPercentage,
    baharWinPercentage
}: AndarBaharBetRatePanelProps) => {
    const andarWinRate:number = parseInt(andarWinPercentage, 10) / 100;
    const baharWinRate:number = parseInt(baharWinPercentage, 10) / 100;

    return (
        <Stack className="andarbahar-bet-rate">
            <Stack direction={'row'} spacing={0} sx={{ justifyContent:'space-between' }}>
                <Stack
                    direction="column"
                    width={andarWinPercentage}
                    spacing={type === BetRateType.ABWinRate? -1 : 0}
                    className={type === BetRateType.ABWinRate? 'andarbahar-andar-win-item' : 'andarbahar-andar-gamecard-win-item'}
                    sx={{ 
                        justifyContent: type === BetRateType.ABWinRate? 'normal':'flex-end', 
                        borderRadius: (andarWinRate < 1)? '5px 0 0 5px':'5px 5px 5px 5px'
                    }}
                >
                    <Typography 
                        variant='caption' 
                        fontSize='15px' 
                        zIndex={1}
                        fontWeight='bold'
                        whiteSpace={'nowrap'}
                        color={ type === BetRateType.GameCardABRate? '#436fff':'#ffffff' }>
                        {andarTitle}
                    </Typography>
                    <Typography
                        variant='caption' 
                        fontSize='12px'
                        zIndex={1}
                        sx={{ 
                            paddingLeft: type === BetRateType.GameCardABRate? '5px':'0',
                            marginBottom: type === BetRateType.GameCardABRate? '-2px':'0'
                        }}>
                        {andarWinPercentage}
                    </Typography>
                </Stack>
                <Stack
                    direction="column"
                    width={baharWinPercentage}
                    spacing={type === BetRateType.ABWinRate? -1 : 0}
                    className={type === BetRateType.ABWinRate? "andarbahar-bahar-win-item" : "andarbahar-bahar-gamecard-win-item"}
                    sx={{ 
                        justifyContent: type === BetRateType.ABWinRate? 'normal':'flex-end',
                        borderRadius: (baharWinRate < 1)? '0 5px 5px 0':'5px 5px 5px 5px',
                    }}
                    >
                    <Typography 
                        variant='caption' 
                        fontSize='15px' 
                        zIndex={1}
                        fontWeight='bold'
                        whiteSpace={'nowrap'}
                        color={ type === BetRateType.GameCardABRate? '#d30000':'#ffffff' }> 
                        {baharTitle}
                    </Typography>
                    <Typography 
                        variant='caption' 
                        fontSize='12px'
                        zIndex={1}
                        sx={{ 
                            paddingRight: type === BetRateType.GameCardABRate? '5px':'0', 
                            marginBottom: type === BetRateType.GameCardABRate? '-2px':'0'
                        }}>
                        {baharWinPercentage}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};
