import './index.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import { Box, Grid, Stack, Tab, Tabs } from '@mui/material';
import BaseImage from '../../../../../components/common/baseImage';
import { REPORT_COLORS } from '../../../../lobby/constants';
import { useTranslation } from 'react-i18next';
import { DoubleBetArea } from './BetArea/DoubleBetArea';
import { FeatureBetArea } from './BetArea/FeatureBetArea';
import { PopularBetArea } from './BetArea/PopularBetArea';
import { ChipList } from '../Baccarat/ChipList';
import { ChipSelector } from '../Baccarat/ChipSelector';
import { DoubleBetButton } from '../Baccarat/DoubleBetButton';
import { ReBetButton } from '../Baccarat/ReBetButton';
import { batch, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import {
    getCurrentGameId,
    getGameSate,
    getTotalPendingBetsAmount,
    isReBetOn,
} from '../../../../games/selector';
import { getHostById } from '../../../../host/slice';
import { GameState as GameStateEnum } from '../../../../../models/games/enums/GameState';
import { gameSliceActions } from '../../../../games/slice';
import { BetType } from '../../../../../models/games/sicbo/BetType';
import { MobPref } from '../../../../../hooks/storage/useLocalStorage';
import { Bet } from '../../../../../models/host/BetAmount';
import BScroll from '@better-scroll/core';
import Slide from '@better-scroll/slide';
import { BScrollConstructor } from '@better-scroll/core/dist/types/BScroll';
import { AudioPlayContext } from '../../../../../contexts/AudioPlayContext';
import { useBalance } from '../../../../../hooks/useBalance';
import { StateMsgType } from '../../../../../models/Popup';
import { inGamePopupSliceActions } from '../../../../popup/inGameSlice';
import { amountOverBetLimit } from './utils';
import { checkBetsAvailed } from '../../../../../utils/commonFunc';

const betPages = ['popular', 'feature', 'double'];
const currentBetArea = (key: string): JSX.Element => {
    //'popular', 'feature', 'double'
    switch (key) {
        case 'popular':
            return <PopularBetArea />;
        case 'feature':
            return <FeatureBetArea />;
        case 'double':
            return <DoubleBetArea />;
        default:
            return <></>;
    }
};
const getTabIndex = (betType: number): number => {
    let tabIndex: number = -1;

    const tabs = [
        [
            BetType.SBBSmall,
            BetType.SBBBig,
            BetType.SBBOdd,
            BetType.SBBEven,
            BetType.SBBAllTriple,
            BetType.SBBPointFour,
            BetType.SBBPointFive,
            BetType.SBBPointSix,
            BetType.SBBPointSeven,
            BetType.SBBPointEight,
            BetType.SBBPointNine,
            BetType.SBBPointTen,
            BetType.SBBPointEleven,
            BetType.SBBPointTwelve,
            BetType.SBBPointThirteen,
            BetType.SBBPointForteen,
            BetType.SBBPointFiveteen,
            BetType.SBBPointSixteen,
            BetType.SBBPointSeventeen,
        ],
        [
            BetType.SBBTripleArmyOne,
            BetType.SBBTripleArmyTwo,
            BetType.SBBTripleArmyThree,
            BetType.SBBTripleArmyFour,
            BetType.SBBTripleArmyFive,
            BetType.SBBTripleArmySix,
            BetType.SBBTripleOne,
            BetType.SBBTripleTwo,
            BetType.SBBTripleThree,
            BetType.SBBTripleFour,
            BetType.SBBTripleFive,
            BetType.SBBTripleSix,
        ],
        [
            BetType.SBBLongOneTwo,
            BetType.SBBLongOneThree,
            BetType.SBBLongOneFour,
            BetType.SBBLongOneFive,
            BetType.SBBLongOneSix,
            BetType.SBBLongTwoThree,
            BetType.SBBLongTwoFour,
            BetType.SBBLongTwoFive,
            BetType.SBBLongTwoSix,
            BetType.SBBLongThreeFour,
            BetType.SBBLongThreeFive,
            BetType.SBBLongThreeSix,
            BetType.SBBLongFourFive,
            BetType.SBBLongFourSix,
            BetType.SBBLongFiveSix,
            BetType.SBBShortOne,
            BetType.SBBShortTwo,
            BetType.SBBShortThree,
            BetType.SBBShortFour,
            BetType.SBBShortFive,
            BetType.SBBShortSix,
        ],
    ];

    tabs.forEach((tab, idx) => {
        if (tab.indexOf(betType) > -1) {
            tabIndex = idx;
        }
    });

    for (let i = 0; i < tabs.length; i++) {
        if (tabs[i].indexOf(betType) > -1) {
            return i;
        }
    }

    return tabIndex;
};

const SicBoBetAreaPanel = () => {
    //selector
    const { t } = useTranslation();
    const { hostId } = useContext(VideoPlayerContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const reBetButtonEnable = useSelector((state: RootState) =>
        isReBetOn(state)
    );
    const { IsRest, CurrentState, ResultReleased, GameType } = useSelector(
        (state: RootState) => getHostById(state, hostId)
    );
    
    const [doubleBets, setDoubleBets] = useState<Array<Bet>>([]);
    const { doubleBet, reBet, prevBets, pendingBets, confirmedBets } = useSelector(getGameSate);

    useEffect(() => {
        const bets = new Array<Bet>();
        for (const cb of confirmedBets) {
            bets.push({ Type: cb.Type, Amount: cb.Amount, GameId: cb.GameId });
        }
        for (const pb of pendingBets) {
            const index = bets.findIndex(cb => cb.Type === pb.Type);
            if (index === -1) bets.push(pb);
            else bets[index].Amount += pb.Amount;
        }
        setDoubleBets(bets);
    }, [pendingBets, confirmedBets]);

    const addingBet = useRef(false);
    const [toAddBets, setToAddBets] = useState<Array<Bet>>();
    const allPendingBetsAmount = useSelector((state: RootState) =>
        getTotalPendingBetsAmount(state)
    );
    const { availableBalance } = useBalance();
    const currentGameId = useSelector((state: RootState) =>
        getCurrentGameId(state)
    );
    //use
    const [betAreaTab, setBetAreaTabTab] = useState<number>(0);
    const [isShowReBet, setIsShowReBet] = useState<boolean>(false);
    const [isBetting, setIsBetting] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    const [scrollObj, setScrollObj] = useState<BScrollConstructor>();
    const dispatch = useDispatch();
    //function
    const doPreBet = (bets: Array<Bet>) => {
        batch(() => {
            bets.forEach(bet => {
                dispatch(
                    gameSliceActions.onPendingBet({
                        GameId: currentGameId,
                        Type: bet.Type,
                        Amount: bet.Amount,
                    })
                );
            });
        });
    };
    const handleChange = (v: number) => {
        if (betAreaTab < betPages.length) {
            playButtonAudio();
            if (scrollObj) {
                if (v !== betAreaTab) {
                    setBetAreaTabTab(v);
                    scrollObj.goToPage(v, 0);
                }
            }
        }
    };
    useEffect(() => {
        if (doubleBet) {
            if (doubleBets && !toAddBets && !addingBet.current) {
                addingBet.current = true;
                setToAddBets(doubleBets);
            }
            dispatch(gameSliceActions.onDoubleBet({ onClick: false }));
        }
    }, [doubleBet, doubleBets]);
    useEffect(() => {
        if (reBet) {
            if (prevBets && !toAddBets && !addingBet.current) {
                addingBet.current = true;
                setToAddBets(prevBets);
            }
            dispatch(gameSliceActions.onReBet({ onClick: false }));
        }
    }, [reBet, prevBets]);
    useEffect(() => {
        if (toAddBets) {
            const availableBets = checkBetsAvailed({
                hostId: hostId,
                bets: toAddBets,
                amountOverBetLimit: amountOverBetLimit,
                totalPendingBetAmount: allPendingBetsAmount,
                totalPendingWithHoldAmount: 0,
                availableBalance: availableBalance,
            });
            if (availableBets.isNotEnoughMoney) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'system.not_enough_money',
                        GameType,
                        StateMsgType.betInfo_Fail,
                        currentGameId
                    )
                );
            } else if (availableBets.isOutOfBetLimit) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'system.bet_out_of_limit_red',
                        GameType,
                        StateMsgType.betInfo_Fail,
                        currentGameId
                    )
                );
            } else {
                const toBets = availableBets.bets;
                const betTypes = toBets.map((bet: Bet) => ({
                    type: bet.Type,
                }));

                let isCurrTab = false;
                let lastBetType: number = betTypes.length - 1;
                for (let i = betTypes.length - 1; i >= 0; i--) {
                    const destTab = getTabIndex(betTypes[i].type);
                    if (betAreaTab === destTab) {
                        lastBetType = i;
                        isCurrTab = true;
                        break;
                    }
                }

                let delay: number = 0;
                if (!isCurrTab) {
                    const destTab = getTabIndex(betTypes[lastBetType].type);
                    if (scrollObj) {
                        setBetAreaTabTab(destTab);
                        scrollObj.goToPage(destTab, 0);
                        delay = Math.abs(betAreaTab - destTab) * 800;
                    }
                }

                if (delay > 0) {
                    setTimeout(() => doPreBet(toBets), delay);
                } else {
                    doPreBet(toBets);
                }
                if (availableBets.haveDisabledBetType) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.have_cannot_rebet_zone',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                } else if (availableBets.haveBetLimitAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.bet_exceed_limit_allin',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                } else if (availableBets.isAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.all_in',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                }

                dispatch(
                    gameSliceActions.setSelectedBetType(betTypes[lastBetType])
                );
            }
            setToAddBets(undefined);
            addingBet.current = false;
        }
    }, [toAddBets]);
    useEffect(() => {
        if (ref && ref.current) {
            const lsSicBoTab = localStorage.getItem(
                `MobPref_${MobPref.SICBO_BET_PORT_MODE}`
            );
            const numLsTab = Number(lsSicBoTab) ?? 0;
            setBetAreaTabTab(numLsTab);
            BScroll.use(Slide);
            const newBScroll = new BScroll(ref.current as HTMLDivElement, {
                scrollX: true,
                scrollY: false,
                click: true,
                // useTransition: false,
                slide: {
                    loop: false,
                    autoplay: false,
                    threshold: 100,
                    startPageXIndex: numLsTab,
                },
                // bounce: false,
                momentum: false,
                // momentumLimitDistance: 1,
                //probeType: 3,
            });
            newBScroll.on('slidePageChanged', (page: { pageX: number }) => {
                if (page) {
                    const tab = page.pageX;
                    setBetAreaTabTab(tab);
                    dispatch(gameSliceActions.onCancelPendingBet());
                    localStorage.setItem(
                        'MobPref_' + MobPref.SICBO_BET_PORT_MODE,
                        JSON.stringify(tab)
                    );
                }
            });
            setScrollObj(newBScroll);
        }
        return () => {
            if (ref && ref.current && scrollObj) {
                scrollObj.destroy();
            }
        };
    }, [ref]);
    useEffect(() => {
        setIsBetting(IsRest ? false : CurrentState === GameStateEnum.Betting);
    }, [IsRest, CurrentState, ResultReleased]);

    useEffect(() => {
        setIsShowReBet(isBetting ? reBetButtonEnable : false);
    }, [reBetButtonEnable, isBetting]);
    return (
        <>
            <Stack
                className="sicbo-betarea-box"
                sx={{
                    marginTop: '328px',
                    width: '540px',
                    height: '510px', //28 (tab) + 412 (betarea) + 70 (chip list)
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        left: '0px',
                        zIndex: '-1',
                    }}
                >
                    {/*
                    93 = (500 - (628 * 0.5))
                    1.5923 = 500 / (628 * 0.5)
                    */}
                    <BaseImage
                        className={'sicbo bg_vertical'}
                        transform={'translate(0, 183px) scale(1, 1.5923)'}
                    />
                </Box>
                <Tabs
                    className="betarea-tabs"
                    value={betAreaTab}
                    onChange={(_, v) => {
                        handleChange(v);
                    }}
                    aria-label="betarea tabs"
                    sx={{ '& Button': { color: REPORT_COLORS.TITLE_TEXT } }}
                >
                    {betPages &&
                        betPages.map((k, idx) => (
                            <Tab
                                value={idx}
                                key={`betarea-tab-${idx}`}
                                label={t(`sicbo.${k}`)}
                            />
                        ))}
                </Tabs>
                <Box sx={{ zIndex: 1 }}>
                    <Box
                        ref={ref}
                        sx={{
                            width: 540,
                            position: 'relative',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            touchAction: 'pan-x',
                        }}
                    >
                        <Box sx={{ display: 'inline-block' }}>
                            {betPages &&
                                betPages.map((k, idx) => (
                                    <Box
                                        key={`tab-panel-${idx}`}
                                        sx={{
                                            display: 'inline-block',
                                            height: 473,
                                            position: 'relative',
                                        }}
                                    >
                                        {currentBetArea(k)}
                                    </Box>
                                ))}
                        </Box>
                    </Box>

                    <Grid
                        item
                        container
                        sx={{
                            position: 'absolute',
                            bottom: 2,
                            paddingLeft: '5px',
                            paddingRight: '5px',
                        }}
                    >
                        <ChipSelector />
                        <ChipList />
                        {isShowReBet ? <ReBetButton /> : <DoubleBetButton />}
                    </Grid>
                </Box>
            </Stack>
        </>
    );
};

export default SicBoBetAreaPanel;
