import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider as StoreProvider } from 'react-redux';
import App from './App';
import { WebSocketProvider } from './contexts/WebSocketContext';
import './index.scss';
// config i18n
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AudioPlayProvider } from './contexts/AudioPlayContext';
import { CdnProvider } from './contexts/CdnContext';
import { ConfigProvider } from './contexts/ConfigContext';
import { DatePickerLocalizationProvider } from './contexts/DatePickerLocalizationContext';
import { GlobalDataProvider } from './contexts/GlobalDataContext';
import { LiteUIProvider } from './contexts/LiteUIContext';
import { ResourceProvider } from './contexts/ResourceContext';
import i18n from './modules/locales/i18n';
import { overrideFunctionArrayAt } from './override';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import store from './store/store';
import { theme } from './theme';

overrideFunctionArrayAt();
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <StoreProvider store={store}>
            <I18nextProvider i18n={i18n}>
                <DatePickerLocalizationProvider>
                    <CdnProvider>
                        <ConfigProvider>
                            <GlobalDataProvider>
                                <ResourceProvider>
                                    <AudioPlayProvider>
                                        <WebSocketProvider>
                                            <LiteUIProvider>
                                                <ThemeProvider theme={theme}>
                                                    <CssBaseline />
                                                    <App />
                                                </ThemeProvider>
                                            </LiteUIProvider>
                                        </WebSocketProvider>
                                    </AudioPlayProvider>
                                </ResourceProvider>
                            </GlobalDataProvider>
                        </ConfigProvider>
                    </CdnProvider>
                </DatePickerLocalizationProvider>
            </I18nextProvider>
        </StoreProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
