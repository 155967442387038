import { Help } from '@mui/icons-material';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
type PasswordScoreProps = {
    password: string;
};
export const PasswordScoreBar = (props: PasswordScoreProps) => {
    const { t } = useTranslation();
    const { password } = props;
    const [score, setScore] = useState(0);
    const [strengthStr, setStrengthStr] = useState('');
    const [strengthColor, setStrengthColor] = useState('#EB2414');
    const [isHintOpen, setHintOpen] = useState(false);
    console.log(t);
    useEffect(() => {
        let i = 0;
        let character;
        let numCount = 0;
        let upCount = 0;
        let lowCount = 0;
        while (i < password.length) {
            character = password.charAt(i);
            if (!isNaN(Number(character))) {
                numCount++;
            } else {
                if (character == character.toUpperCase()) {
                    upCount++;
                }
                if (character == character.toLowerCase()) {
                    lowCount++;
                }
            }
            i++;
        }

        let score = 0;
        if (password.length < 8) {
            score -= 2;
        } else if (password.length <= 12) {
            score += 1;
        } else if (password.length <= 15) {
            score += 2;
        }
        if (numCount <= 0) {
            score -= 2;
        } else if (numCount == 1) {
            score += 1;
        } else {
            score += 2;
        }
        if (upCount <= 0) {
            score -= 2;
        } else if (upCount == 1) {
            score += 1;
        } else {
            score += 2;
        }
        if (lowCount <= 0) {
            score -= 2;
        } else if (lowCount == 1) {
            score += 1;
        } else {
            score += 2;
        }
        if (password.length == 0) {
            setStrengthStr('');
        } else if (score <= 4) {
            setStrengthColor('#EB2414');
            setStrengthStr('password.password_weak');
        } else if (score <= 6) {
            setStrengthColor('#FDAA20');
            setStrengthStr('password.password_mid');
        } else {
            setStrengthColor('#01B403');
            setStrengthStr('password.password_strong');
        }
        setScore(score);
    }, [password]);
    // const handleClose = () => {
    //     setHintOpen(false);
    // };
    const handleOpen = () => {
        setHintOpen(!isHintOpen);
    };
    return (
        <Stack paddingTop={'20px'} maxWidth={'423px'}>
            <Stack direction="row" gap="6px" alignItems={'center'}>
                <Typography
                    sx={{
                        width: '30px',
                        height: '6px',
                        backgroundColor: score >= 1 ? '#EB2414' : '#c0c0c0',
                        borderRadius: '1vw',
                    }}
                ></Typography>
                <Typography
                    sx={{
                        width: '30px',
                        height: '6px',
                        backgroundColor: score >= 2 ? '#EB2414' : '#c0c0c0',
                        borderRadius: '1vw',
                    }}
                ></Typography>
                <Typography
                    sx={{
                        width: '30px',
                        height: '6px',
                        backgroundColor: score >= 3 ? '#EB2414' : '#c0c0c0',
                        borderRadius: '1vw',
                    }}
                ></Typography>
                <Typography
                    sx={{
                        width: '30px',
                        height: '6px',
                        backgroundColor: score >= 4 ? '#EB2414' : '#c0c0c0',
                        borderRadius: '1vw',
                    }}
                ></Typography>
                <Typography
                    sx={{
                        width: '30px',
                        height: '6px',
                        backgroundColor: score >= 5 ? '#FDAA20' : '#c0c0c0',
                        borderRadius: '1vw',
                    }}
                ></Typography>
                <Typography
                    sx={{
                        width: '30px',
                        height: '6px',
                        backgroundColor: score >= 6 ? '#FDAA20' : '#c0c0c0',
                        borderRadius: '1vw',
                    }}
                ></Typography>
                <Typography
                    sx={{
                        width: '30px',
                        height: '6px',
                        backgroundColor: score >= 7 ? '#01B403' : '#c0c0c0',
                        borderRadius: '1vw',
                    }}
                ></Typography>
                <Typography
                    sx={{
                        width: '30px',
                        height: '6px',
                        backgroundColor: score >= 8 ? '#01B403' : '#c0c0c0',
                        borderRadius: '1vw',
                    }}
                ></Typography>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'row'}>
                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip
                            open={isHintOpen}
                            title={
                                t('password.good_password_title') +
                                '\n' +
                                t('password.good_password_content')
                            }
                        >
                            <IconButton onClick={handleOpen}>
                                <Help />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                    <Typography>
                        <pre>{t('password.password_strength')}</pre>
                    </Typography>
                </Stack>
                <Typography
                    position={'absolute'}
                    right={'0px'}
                    color={strengthColor}
                    sx={{ textAlign: 'right' }}
                >
                    <pre>{t(strengthStr)}</pre>
                </Typography>
            </Stack>
        </Stack>
    );
};
