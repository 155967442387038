import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { isPlayerSeated } from '../../../modules/bet/room/slice';
import { SupportedGameResult } from '../../../modules/host/models';
import { getHostById, hostsSliceActions } from '../../../modules/host/slice';
import { getMainUserState } from '../../../modules/main/selector';
import store from '../../../store/store';
import { updateRemainTime } from '../../../utils/commonFunc';
import { GameType as GameTypeEnum } from '../../games/enums/GameType';
import { CMDScStartBet, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';
import { GameState } from '../../games/enums/GameState';

export class CMDScStartBetHandler extends AbstractHandler {
    _command: CMDScStartBet;

    constructor(command: ICommand) {
        super();
        this._command = command as CMDScStartBet;
    }
    forceRun() {
        const state = store.getState();
        const host = getHostById(state, this._command.HostID);
        if (host.CurrentResult) {
            return host.CurrentResult.GameID.toString();
        }
        return '';
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const newResult = {} as SupportedGameResult;
        newResult.GameID = this._command.GameID;
        newResult.GameCount = this._command.GameCount;
        const state = store.getState();
        const { PlayerID } = getMainUserState(state);
        const host = getHostById(state, this._command.HostID);
        host.ShuffleTimer && clearTimeout(host.ShuffleTimer);

        if (host) {
            console.log(`hook::socket::run::${host.HostId}`, this._command);
            const { GameType } = host;
            switch (GameType) {
                case GameTypeEnum.Baccarat:
                case GameTypeEnum.PokDeng:
                case GameTypeEnum.SeDie:
                case GameTypeEnum.SicBo:
                case GameTypeEnum.TeenPatti2020:
                case GameTypeEnum.AndarBahar:
                case GameTypeEnum.Roulette:
                case GameTypeEnum.Dragon:
                    dispatch(
                        hostsSliceActions.startBet({
                            hostId: this._command.HostID,
                            result: newResult,
                            remainTime: updateRemainTime(
                                this._command.RemainTime - 1
                            ),
                        })
                    );
                    //fast cutoff
                    setTimeout(
                        () => {
                            dispatch(
                                hostsSliceActions.updateGameState({
                                    hostId: host.HostId,
                                    state: GameState.Started,
                                })
                            );
                        },
                        (this._command.RemainTime - 2) * 1000
                    );
                    break;
                case GameTypeEnum.Blackjack:
                    if (isPlayerSeated(state, this._command.HostID, PlayerID)) {
                        dispatch(
                            hostsSliceActions.startBet({
                                hostId: this._command.HostID,
                                result: newResult,
                                remainTime: updateRemainTime(
                                    host.GameDelay
                                        ? host.GameDelay.kickNoBetPlayer / 1000
                                        : 0
                                ),
                                countDown: host.GameDelay
                                    ? host.GameDelay.kickNoBetPlayer
                                    : 0,
                                pendingTime:
                                    this._command.RemainTime * 1000 -
                                    (host.GameDelay
                                        ? host.GameDelay.kickNoBetPlayer
                                        : 0),
                                canLeave: true,
                            })
                        );
                    } else {
                        dispatch(
                            hostsSliceActions.startBet({
                                hostId: this._command.HostID,
                                result: newResult,
                                remainTime: updateRemainTime(
                                    this._command.RemainTime
                                ),
                                countDown: this._command.RemainTime * 1000,
                                canLeave: false,
                            })
                        );
                    }
                    break;
            }
        } else {
            console.warn(
                'hook::socket::warning::cannot::find::host',
                this._command.GameID
            );
        }
    }
}
