import { memo, useEffect } from 'react';
import { motion, useAnimationControls } from 'framer-motion';
import { ROAD_MAP_COLORS } from '../../../lobby/constants';
type CockroachRoadCellProps = {
    win: number;
};
const CockroachRoadAnimatedCell = ({ win }: CockroachRoadCellProps) => {
    const askRoadAnimation = useAnimationControls();
    const anim = () => {
        askRoadAnimation.start({
            opacity: [1, 1, 0, 1, 1, 0],
            transition: { duration: 1 },
        });
    };
    useEffect(() => {
        anim();
    }, []);
    return (
        <svg viewBox="0 0 12 12" className="roadmap-cell">
            <motion.line
                animate={askRoadAnimation}
                initial={{ opacity: 0 }}
                x1="2"
                y1="10"
                x2="10"
                y2="2"
                stroke={win == 0 ? ROAD_MAP_COLORS.RED : ROAD_MAP_COLORS.BLUE}
                strokeWidth="1.5"
            />
        </svg>
    );
};
export default memo(CockroachRoadAnimatedCell);
