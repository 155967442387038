import { CSSProperties } from 'react';
import './index.scss';
//import useAudioPlay from '../../../hooks/useAudioPlay';
import { SoundList } from '../../../models/Voice';
// import { ResourceContext } from '../../../contexts/ResourceContext';
import useAudioPlay from '../../../hooks/useAudioPlay';

type Props = {
    onClick?: (data: object) => void;
    className?: string;
    style?: CSSProperties;
    children: JSX.Element;
};

const Button = (props: Props) => {
    let { className, style, children } = props;
    className = className + ' buttonDiv';
    const { audioPlay } = useAudioPlay();
    // const { audioLists } = useContext(ResourceContext);
    // let elementStyle: React.CSSProperties;
    // elementStyle = {...style, ...{transform: 'translate(0px, 0px) scale(0.5)', transformOrigin: '0px 0px'}};

    const onClickButton = () => {
        //audioPlay([SoundList.btnSound, SoundList.Beep, 'bet.StartBetting', 'bet.StopBetting']);
        audioPlay([SoundList.btnSound]);
        if (props.onClick) props.onClick({});
    };

    return (
        <div className={className} onClick={onClickButton} style={style}>
            {children}
        </div>
    );
};

export default Button;
