import { useContext, useEffect, useRef, useState } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useSelector } from 'react-redux';
import { getHostById } from '../../../host/slice';
import { RootState } from '../../../../store/store';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import { Backdrop, Box, Stack, Typography } from '@mui/material';
import { Poker, PokerType } from '../../../../components/common/poker';
import { AndarBaharResult } from '../../../../models/games/andarBahar';
import { t } from 'i18next';
import './AndarBaharECard.scss';
import { GameState } from '../../../../models/games/andarBahar/GameState';
import {
    AndarBaharFirst3Pattern,
    convertFResult,
} from '../../../../utils/games/andarBahar';

type AndarBaharECardDisplay = {
    gameCard: number;
    first3: (number | undefined)[];
    first3Result: number | undefined;
    andar: number[];
    bahar: number[];
};
type AndarBaharECardProps = {
    show: boolean;
};

export const AndarBaharECard = (props: AndarBaharECardProps) => {
    const { show } = props;
    const { hostId } = useContext(VideoPlayerContext);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const [display, setDisplay] = useState<AndarBaharECardDisplay>();
    const [first3ResultDisplay, setFirst3ResultDisplay] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const scrollerRef = useRef<HTMLDivElement>(null);
    const [isScrollStart, setIsScrollStart] = useState(false);
    const [isScrollEnd, setIsScrollEnd] = useState(false);
    const [isOverflow, setIsOverflow] = useState(false);
    
    useEffect(() => {
        const result = host?.CurrentResult as AndarBaharResult;
        if (result) {
            let cards = result.ResultString
                ? result.ResultString.split(',').map(Number)
                : [];
            const joker = result.ResultString ? cards[0] : result.Joker;
            let first3Cards: (number | undefined)[];
            if (result.ResultString) {
                first3Cards = cards
                    .slice(0, Math.min(cards.length, 3))
                    .map(Number);
            } else {
                let firstAndar = undefined;
                let firstBahar = undefined;
                if (result.AndarBahar) {
                    cards = result.AndarBahar;
                    firstAndar = result.AndarBahar[0]
                        ? result.AndarBahar[0]
                        : firstAndar;
                    firstBahar = result.AndarBahar[1]
                        ? result.AndarBahar[1]
                        : firstBahar;
                }
                first3Cards = [joker, firstAndar, firstBahar];
            }

            let first3Pattern: AndarBaharFirst3Pattern | undefined = undefined;
            if (result.FResult)
                first3Pattern = convertFResult(
                    BigInt(result.FResult)
                ).First3Result;

            const d: AndarBaharECardDisplay = {
                gameCard: joker,
                first3: first3Cards,
                first3Result: first3Pattern,
                andar: [],
                bahar: [],
            };

            if (cards.length > 0) {
                if (result.ResultString) {
                    cards = cards.slice(1);
                }

                let andar: number[] = [];
                let bahar: number[] = [];
                for (let i = 0; i < cards.length; i++) {
                    if (i % 2 == 0) {
                        andar.push(cards[i]);
                    } else {
                        bahar.push(cards[i]);
                    }
                }
                d.andar = andar;
                d.bahar = bahar;
            }

            setDisplay(d);

            const fResultCardIndexSection: number = 51;
            switch (host.CurrentState) {
                case GameState.AndarWin:
                case GameState.BaharWin:
                    if (
                        result &&
                        result.FResult &&
                        BigInt(result.FResult) > fResultCardIndexSection
                    ) {
                        setFirst3ResultDisplay(true);
                    } else {
                        setFirst3ResultDisplay(false);
                    }
                    break;
                default:
                    setFirst3ResultDisplay(false);
            }
        } else {
            setDisplay(undefined);
        }
    }, [host.CurrentResult]);

    const getAndarPokerType = (position: number): PokerType => {
        if (display) {
            if (position === display.andar.length - 1) {
                return PokerType.AndarBahar2;
            }
        }
        return PokerType.AndarBahar1;
    };

    const getBaharPokerType = (position: number): PokerType => {
        if (display) {
            if (position === display.bahar.length - 1) {
                return PokerType.AndarBahar2;
            }
        }
        return PokerType.AndarBahar1;
    };

    const getAndarPokerScaleFactor = (position: number): number => {
        let scale: number;
        if (display) {
            if (position === display.andar.length - 1) {
                //Card Type 2
                if (display.andar.length > display.bahar.length) {
                    scale = 0.5;
                } else {
                    scale = 0.37;
                }
                return scale;
            } else {
                //Card Type 1
                scale = 0.55;
            }
        } else {
            scale = 0.55;
        }

        return scale;
    };

    useEffect(() => {
        const scroller = scrollerRef.current;
        const containerElement = containerRef.current;
        if(scroller && containerElement){
            const overflow = scroller.offsetWidth > containerElement.offsetWidth;
            setIsOverflow(overflow);
            setIsScrollStart(containerElement.scrollLeft === 0);
            setIsScrollEnd(containerElement.scrollLeft + containerElement.clientWidth === containerElement.scrollWidth);
        }
    }, [scrollerRef.current, containerRef.current, display?.andar])

    useEffect(() => {
        const containerElement = containerRef.current;
        
        if (containerElement) {
            const handleScroll = () => {
                setIsScrollStart(containerElement.scrollLeft === 0);
                setIsScrollEnd(containerElement.scrollLeft + containerElement.clientWidth === containerElement.scrollWidth);
            };
                
            containerElement.addEventListener('scroll', handleScroll);
            handleScroll(); // Check initial scroll position
                
            return () => {
                containerElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    
    const getBaharPokerScaleFactor = (position: number): number => {
        let scale: number;
        if (display) {
            if (position === display.bahar.length - 1) {
                //Card Type 2
                if (display.andar.length > display.bahar.length) {
                    scale = 0.37;
                } else {
                    scale = 0.5;
                }
            } else {
                //Card Type 1
                scale = 0.55;
            }
        } else {
            scale = 0.55;
        }

        return scale;
    };

    const getFirst3ResultPatternMessage = (): string => {
        let msg = '';
        if (display) {
            switch (display.first3Result) {
                case AndarBaharFirst3Pattern.ABF3StraightFlush:
                    msg = t('andar_bahar.straight_flush');
                    break;
                case AndarBaharFirst3Pattern.ABF3Straight:
                    msg = t('andar_bahar.straight');
                    break;
                case AndarBaharFirst3Pattern.ABF3Flush:
                    msg = t('andar_bahar.flush');
                    break;
                case AndarBaharFirst3Pattern.ABF3None:
                    msg = t('andar_bahar.no_win');
                    break;
                default:
                    msg = '';
            }
        }
        return msg;
    };

    return (
        <Backdrop
            className={'andar-bahar-poker-show-backdrop'}
            sx={{ zIndex: theme => theme.zIndex.drawer + 1, width: '100%' }}
            open={show}
        >
            <Typography
                sx={{ position: 'absolute', left: '5%', top: '5%' }}
                color={'#436fff'}
            >
                {t('andar_bahar.andar')}
            </Typography>
            <Typography
                sx={{ position: 'absolute', left: '5%', bottom: '5%' }}
                color={'#d30000'}
            >
                {t('andar_bahar.bahar')}
            </Typography>
            {display && (
                <Typography
                    sx={{
                        position: 'absolute',
                        bottom: '3%',
                        right: '40%',
                        textAlign: 'right',
                    }}
                >
                    {`${t('andar_bahar.card_dealt')} ${
                        display.andar.length + display.bahar.length
                    }`}
                </Typography>
            )}

            <Box sx={{ display:'flex', alignItems:'center', width:'100%', height:'100%' }}>
                <Stack direction={'row'} spacing={0.5} sx={{ alignItems:'center', width:'100%', height:'100%' }}>
                    <Box sx={{ minWidth:'24px' }}>
                        {isOverflow && !isScrollStart && <ArrowBackIosNewIcon fontSize='medium' sx={{ color: '#c3a475', display:'inline-block' }}/>}
                    </Box>
                    <Box sx={{ width:'55%', height:'70%', display:'flex', flexDirection:'row-reverse', alignItems:'center' }}>
                        <Box ref={containerRef} sx={{ 
                            overflowY:'hidden',
                            display:'flex', 
                            flexWrap:'wrap', 
                            flexDirection:'column', 
                            height:'100%', 
                            '&::-webkit-scrollbar': {
                                display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                            },
                            '&-ms-overflow-style': {
                                display: 'none', // Hide the scrollbar for IE
                            }, }}>
                            {display && (
                                <Stack ref={scrollerRef} direction={'row-reverse'} spacing={-2.5} sx={{ alignItems: 'center', height:'50%' }}>
                                    {display.andar.map((card, index) => (
                                        <Box key={`andar-ecard-${index}`} sx={{ zIndex: index + 1, display:'flex', alignItems: 'center' }}>
                                            <Poker 
                                                n={card+1}
                                                type={ getAndarPokerType(index) }
                                                scale={ getAndarPokerScaleFactor(index) } />  
                                            </Box>
                                    ))}
                                </Stack>
                            )}
                            {display && (
                                <Stack direction={'row-reverse'} spacing={-2.5} sx={{ alignItems: 'center', height:'50%' }}>
                                    {display.bahar.map((card, index) => (
                                        <Box key={`bahar-ecard-${index}`} sx={{ zIndex: index + 1, display:'flex', height:'50px', alignItems: 'center' }}>
                                            <Poker 
                                                n={card+1}
                                                type={ getBaharPokerType(index) }
                                                scale={ getBaharPokerScaleFactor(index) } />
                                        </Box>
                                    ))}
                                </Stack>
                            )}
                        </Box>
                    </Box>
                    <Box sx={{ minWidth:'24px' }}>
                        {isOverflow && !isScrollEnd && <ArrowForwardIosIcon fontSize='medium' sx={{ color: '#c3a475', display:'inline-block' }}/>}
                    </Box>
                </Stack>
            </Box>
            <Stack
                direction={'column'}
                spacing={-1}
                sx={{
                    right: '5%',
                    position: 'absolute',
                    width: '152.5px',
                    height: '100%',
                    justifyContent: 'space-evenly',
                }}
            >
                {display && (
                    <Stack
                        direction="column"
                        spacing={0}
                        sx={{ justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Typography color={'#c3a475'} fontSize={'14px'}>
                            {t('andar_bahar.game_card')}
                        </Typography>
                        <Poker
                            scale={0.4}
                            n={display.gameCard + 1}
                            type={PokerType.AndarBahar2}
                        />
                    </Stack>
                )}
                {display && (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100px',
                            borderRadius: '10px',
                            border: '2px solid #65573E',
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={0.5}
                            sx={{ width: '100%', alignItems: 'center' }}
                        >
                            <Box
                                sx={{
                                    display: 'table',
                                    height: '19px',
                                    borderRadius: '0 0 5px 5px',
                                    backgroundColor: '#65573E',
                                }}
                            >
                                <Typography
                                    fontSize={'14px'}
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        padding: '0 5px 0 5px',
                                    }}
                                >
                                    {t('andar_bahar.first_3_caption')}
                                </Typography>
                            </Box>
                            <Stack
                                direction={'row-reverse'}
                                spacing={1}
                                sx={{
                                    width: '90%',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                {display.first3.map(
                                    (card, index) =>
                                        card !== undefined && (
                                            <Box
                                                key={`bahar-first3card-${index}`}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Poker
                                                    n={card + 1}
                                                    type={PokerType.AndarBahar2}
                                                    scale={0.35}
                                                />
                                            </Box>
                                        )
                                )}
                            </Stack>
                            <Box
                                sx={{
                                    width: '85%',
                                    height: 'auto',
                                    maxHeight: '24px',
                                    borderRadius: '5px',
                                    backgroundColor: '#65573E',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography fontSize={'14px'}>
                                    {first3ResultDisplay
                                        ? getFirst3ResultPatternMessage()
                                        : ''}
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>
                )}
            </Stack>
        </Backdrop>
    );
};
