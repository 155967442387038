import { PayloadAction, createSlice } from '@reduxjs/toolkit';
//import { ByteArray } from '../../../models/cmd';

export const PLAYER_KEY = 'app::main::player';
export type PlayerState = {
    Id: number;
    Balance: Range; 
    HoldingMoney: Range;
    CurrencyType: Range;
    BalanceValid: boolean;
    AllowTips: boolean;
    AutoBetBalance: Range;
    GameFeature: Range;
};
export type Range = {
    Low: number;
    High: number;
};
const initialPlayerState = {
    Balance: {Low: 0, High: 0},
    HoldingMoney: {Low: 0, High: 0},
} as unknown as PlayerState;

const updatePlayerId = (state: PlayerState, action: PayloadAction<number>) => {
    state.Id = action.payload;
};
const updateBalanceLow = (
    state: PlayerState,
    action: PayloadAction<number>
) => {
    if (!state.Balance) state.Balance = {} as Range;
    state.Balance.Low = action.payload;
};
const updateHoldingMoneyLow = (
    state: PlayerState,
    action: PayloadAction<number>
) => {
    if (!state.HoldingMoney) state.HoldingMoney = {} as Range;
    state.HoldingMoney.Low = action.payload;
};
const updateCurrencyTypeLow = (
    state: PlayerState,
    action: PayloadAction<number>
) => {
    if (!state.CurrencyType) state.CurrencyType = {} as Range;
    state.CurrencyType.Low = action.payload;
};
const updateAutoBetBalanceLow = (
    state: PlayerState,
    action: PayloadAction<number>
) => {
    if (!state.AutoBetBalance) state.AutoBetBalance = {} as Range;
    state.AutoBetBalance.Low = action.payload;
};
const updateGameFeatureLow = (
    state: PlayerState,
    action: PayloadAction<number>
) => {
    if (!state.GameFeature) state.GameFeature = {} as Range;
    state.GameFeature.Low = action.payload;
};

const updateBalanceHigh = (
    state: PlayerState,
    action: PayloadAction<number>
) => {
    if (!state.Balance) state.Balance = {} as Range;
    state.Balance.High = action.payload;
};
const updateHoldingMoneyHigh = (
    state: PlayerState,
    action: PayloadAction<number>
) => {
    if (!state.HoldingMoney) state.HoldingMoney = {} as Range;
    state.HoldingMoney.High = action.payload;
};
const updateCurrencyTypeHigh = (
    state: PlayerState,
    action: PayloadAction<number>
) => {
    if (!state.CurrencyType) state.CurrencyType = {} as Range;
    state.CurrencyType.High = action.payload;
};
const updateAutoBetBalanceHigh = (
    state: PlayerState,
    action: PayloadAction<number>
) => {
    if (!state.AutoBetBalance) state.AutoBetBalance = {} as Range;
    state.AutoBetBalance.High = action.payload;
};
const updateGameFeatureHigh = (
    state: PlayerState,
    action: PayloadAction<number>
) => {
    if (!state.GameFeature) state.GameFeature = {} as Range;
    state.GameFeature.High = action.payload;
};

const updateBalanceValid = (
    state: PlayerState,
    action: PayloadAction<boolean>
) => {
    state.BalanceValid = action.payload;
};
const updateAllowTips = (
    state: PlayerState,
    action: PayloadAction<boolean>
) => {
    state.AllowTips = action.payload;
};
const playerSlice = createSlice({
    name: PLAYER_KEY,
    initialState: initialPlayerState,
    reducers: {
        updatePlayerId,

        updateBalanceLow,
        updateHoldingMoneyLow,
        updateCurrencyTypeLow,
        updateAutoBetBalanceLow,
        updateGameFeatureLow,

        updateBalanceHigh,
        updateHoldingMoneyHigh,
        updateCurrencyTypeHigh,
        updateAutoBetBalanceHigh,
        updateGameFeatureHigh,

        updateBalanceValid,
        updateAllowTips,
    },
});
export const playerSliceReducer = playerSlice.reducer;
export const playerSliceActions = playerSlice.actions;
