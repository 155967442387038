import { useEffect, useState } from 'react';
import UAParser, { DEVICE } from 'ua-parser-js';

import { useWindowSize } from '@react-hook/window-size';
import { MOBILE_SCENE_SIZE } from '../assets/configs/constants';
import { Size } from '../types/interface';

export const useScreenRatio = (): number => {
    const [ratio, setRatio] = useState<number>(
        Math.min(
            window.innerWidth / GetSceneSize().width,
            window.innerHeight / GetSceneSize().height
        )
    );

    const [width, height] = useWindowSize();
    const updateRatio = (): void => {
        setRatio(
            Math.min(
                width / GetSceneSize().width,
                height / GetSceneSize().height
            )
        );
    };

    useEffect(() => {
        updateRatio();
    }, [width, height]);

    return ratio;
};

export const IsMobile = (device: UAParser.IDevice): boolean =>
    device.type === DEVICE.MOBILE || device.type === DEVICE.TABLET;

export const IsLandscape = (): boolean => {
    return window.innerWidth / window.innerHeight > 1.2;
};

export const GetSceneSize = (): Size => {
    return MOBILE_SCENE_SIZE;
};
