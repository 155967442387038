import { Button, Grid } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SeatAreaContext } from '../../../../../../contexts/SeatAreaContext';
import { WebSocketContext } from '../../../../../../contexts/WebSocketContext';
import { useBetSource } from '../../../../../../hooks/useBetSource';
import { CMDPsMakeDecision } from '../../../../../../models/cmd/live';
import { Decision } from '../../../../../../models/games/blackjack/enums/Decision';
import { BaseTypography } from '../../../../../../components/common/baseText/BaseTypography';

export const DoubleDownDecisionButton = () => {
    const { t } = useTranslation();
    const betSource = useBetSource();
    const { sendCommand } = useContext(WebSocketContext);
    const {
        setShowDecision,
        gameId,
        setHasDoubleBet,
        isFirstDecision,
        hasDoubleBet,
    } = useContext(SeatAreaContext);
    return (
        <Button
            className="blackjack-decision-button"
            onClick={() => {
                const command = new CMDPsMakeDecision();
                command.GameID = gameId;
                command.BetSource = betSource;
                command.Decision = Decision.DoubleDown;

                sendCommand(command);
                setShowDecision(false);
                setHasDoubleBet(true);
            }}
            disabled={!isFirstDecision || hasDoubleBet}
        >
            <Grid>
                <svg
                    width="57"
                    height="56"
                    viewBox="0 0 57 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="0.199951"
                        width="56"
                        height="56"
                        rx="28"
                        fill="#C3A035"
                    />
                    <g filter="url(#filter0_d_246_291817)">
                        <path
                            d="M15.2511 37V34.7159L21.3107 28.7756C21.8903 28.1903 22.3732 27.6705 22.7596 27.2159C23.146 26.7614 23.4357 26.321 23.6289 25.8949C23.8221 25.4687 23.9187 25.0142 23.9187 24.5312C23.9187 23.9801 23.7937 23.5085 23.5437 23.1165C23.2937 22.7188 22.95 22.4119 22.5125 22.196C22.075 21.9801 21.5778 21.8722 21.021 21.8722C20.4471 21.8722 19.9443 21.9915 19.5125 22.2301C19.0806 22.4631 18.7454 22.7955 18.5068 23.2273C18.2738 23.6591 18.1573 24.1733 18.1573 24.7699H15.1488C15.1488 23.6619 15.4017 22.6989 15.9073 21.8807C16.413 21.0625 17.109 20.429 17.9954 19.9801C18.8875 19.5312 19.9102 19.3068 21.0636 19.3068C22.234 19.3068 23.2625 19.5256 24.1488 19.9631C25.0352 20.4006 25.7227 21 26.2113 21.7614C26.7056 22.5227 26.9528 23.392 26.9528 24.3693C26.9528 25.0227 26.8278 25.6648 26.5778 26.2955C26.3278 26.9261 25.8875 27.625 25.2568 28.392C24.6318 29.1591 23.7539 30.0881 22.6232 31.179L19.6147 34.2386V34.358H27.217V37H15.2511ZM32.7142 23.9091L35.3562 28.7415L38.0409 23.9091H41.3051L37.359 30.4545L41.3732 37H38.1261L35.3562 32.2869L32.6119 37H29.3392L33.3278 30.4545L29.4414 23.9091H32.7142Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_246_291817"
                            x="11.1489"
                            y="15.3066"
                            width="34.2244"
                            height="25.6934"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_246_291817"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_246_291817"
                                result="shape"
                            />
                        </filter>
                    </defs>
                </svg>
                <BaseTypography
                    color={'white'}
                    fontSize={18}
                    resize={{ direction: 'horizontal', value: '90px' }}
                >
                    {t('blackjack.decision.double_down')}
                </BaseTypography>
            </Grid>
        </Button>
    );
};
