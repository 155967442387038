import { Backdrop, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import { RootState } from '../../../../store/store';
import { getHostById } from '../../../host/slice';
// import { ROAD_MAP_COLORS } from '../../../lobby/constants';
import './SedieECard.scss';
import { SedieResult } from '../../../../models/games/sedie';
import BaseImage from '../../../../components/common/baseImage';
import { AudioPlayContext } from '../../../../contexts/AudioPlayContext';
import { VoiceList } from '../../../../models/Voice';

type SedieECardProps = {
    show: boolean;
};
export const SedieECard = (props: SedieECardProps) => {
    const { show } = props;
    const { t } = useTranslation();
    const { hostId } = useContext(VideoPlayerContext);
    const { audioPlay } = useContext(AudioPlayContext);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const [image, setImage] = useState('');
    const [label, setLabel] = useState('');
    const [winAmount, setWinAmount] = useState('');
    const [showResult, setShowResult] = useState(false);
    useEffect(() => {
        const result = host.CurrentResult as SedieResult;
        if (host.ResultReleased && result.ResultString) {
            // Handle delay
            setTimeout(() => {
                let aKey: Array<string> = [];
                switch (result.ResultString) {
                    case '0':
                        setImage('sedie_sheet sd_4w');
                        setLabel(t('sedie.result_small_even'));
                        aKey = [
                            VoiceList.SeDiePoint.replace('{point}', '0'),
                            VoiceList.SeDieSmall,
                        ];
                        break;
                    case '1':
                        setImage('sedie_sheet sd_3w1r');
                        setLabel(t('sedie.result_small_odd'));
                        aKey = [
                            VoiceList.SeDiePoint.replace('{point}', '1'),
                            VoiceList.SeDieSmall,
                        ];
                        break;
                    case '2':
                        setImage('sedie_sheet sd_2w2r');
                        setLabel(t('sedie.result_even'));
                        aKey = [
                            VoiceList.SeDiePoint.replace('{point}', '2'),
                            VoiceList.SeDieEven,
                        ];
                        break;
                    case '3':
                        setImage('sedie_sheet sd_1w3r');
                        setLabel(t('sedie.result_big_odd'));
                        aKey = [
                            VoiceList.SeDiePoint.replace('{point}', '3'),
                            VoiceList.SeDieOdd,
                        ];
                        break;
                    case '4':
                        setImage('sedie_sheet sd_4r');
                        setLabel(t('sedie.result_big_even'));
                        aKey = [
                            VoiceList.SeDiePoint.replace('{point}', '4'),
                            VoiceList.SeDieBig,
                        ];
                        break;
                }
                setShowResult(true);
                if (aKey && aKey.length > 0) {
                    audioPlay(aKey);
                }
            }, host.DelayDraw);
        } else {
            setShowResult(false);
        }
        return () => {
            setImage('');
            setLabel('');
            setWinAmount('');
            setShowResult(false);
        };
    }, [host?.ResultReleased]);

    useEffect(() => {
        if (host.WinAmount != undefined) {
            const showAmount = (host.WinAmount / 100).toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            });
            if (host.WinAmount >= 0) {
                setWinAmount(`+ ${showAmount}`);
            } else {
                setWinAmount(`${showAmount.replace('-', '- ')}`);
            }
        }
    }, [host?.WinAmount]);

    return (
        <Backdrop
            className={'sedie-poker-show-backdrop'}
            sx={{
                backgroundColor: 'rgba(0,0,0,0.8)',
                zIndex: theme => theme.zIndex.drawer + 1,
            }}
            open={show && showResult}
        >
            <Stack
                direction={'column'}
                alignItems={'center'}
                justifyContent={'space-evenly'}
                gap={'15px'}
            >
                <BaseImage className={image} scale={0.7} />
                <Typography
                    sx={{
                        fontSize: '32px',
                    }}
                >
                    {label}
                </Typography>
                <Typography
                    sx={{
                        fontSize: '40px',
                    }}
                >
                    {winAmount}
                </Typography>
            </Stack>
        </Backdrop>
    );
};
