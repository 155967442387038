import {
    KeyboardArrowDown,
    KeyboardArrowUp,
    LanguageOutlined,
} from '@mui/icons-material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useContext, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { CommonConfigContext } from '../../../contexts/ConfigContext';
import { Locale, getLocaleByCode } from '../../../models/Locale';
import { RootState } from '../../../store/store';
import { REPORT_SIZE } from '../../lobby/constants';
import { getMainUserState } from '../../main/selector';
type HandleChangeLanguage = (language: string) => void;
type LanguageSelectorProps = {
    language: string;
    handleChangeLanguage: HandleChangeLanguage;
    playButtonAudio: () => void;
};
export const LanguageSelector = (props: LanguageSelectorProps) => {
    const { language, handleChangeLanguage, playButtonAudio } = props;
    const btnLanguageText = getLocaleByCode('long', language).fullName;
    const id = 'language-menu';
    // const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { langOnly, langExclude } = useContext(CommonConfigContext);
    const { LobbyCode: serLobbyCode } = useSelector((state: RootState) =>
        getMainUserState(state)
    );

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        playButtonAudio();
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onLanguageChange = (lang: string) => {
        handleChangeLanguage(lang);
        handleClose();
        playButtonAudio();
    };
    return (
        <Box className="language-selector">
            <Button
                aria-controls={open ? id : undefined}
                aria-haspopup={true}
                aria-expanded={open ? true : undefined}
                variant="contained"
                startIcon={
                    <LanguageOutlined sx={{ width: '20px', height: '20px' }} />
                }
                disableElevation
                onClick={handleOpen}
                endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                sx={{
                    width: '45.4vw',
                    height: '10vw',
                    justifyContent: 'space-between',
                    textTransform: 'none',
                    fontSize: REPORT_SIZE.TEXT,
                    lineHeight: '1',
                }}
            >
                {btnLanguageText}
            </Button>
            <Menu
                id={id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {Object.values(Locale)
                    .filter(l => {
                        const shortLang = l.short;
                        if (
                            langExclude[shortLang]?.lobby?.includes(
                                serLobbyCode
                            ) ||
                            langExclude[shortLang]?.lobby?.includes('all')
                        )
                            return false;
                        if (langOnly[l.short]) {
                            if (langOnly[l.short]?.includes(serLobbyCode)) {
                                return true;
                            }
                            return false;
                        }
                        return true;
                    })
                    .map(l => {
                        return (
                            <MenuItem
                                onClick={() => onLanguageChange(l.long)}
                                key={`language-selector-${l.short}`}
                            >
                                {l.fullName}
                            </MenuItem>
                        );
                    })}
            </Menu>
        </Box>
    );
};
