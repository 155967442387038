import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ResourceContext } from '../../../../contexts/ResourceContext';
import { getLandingState } from '../../../main/selector';
const NO_IMAGE =
    'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
export const LobbyLogo = () => {
    const { hideLogo } = useSelector(getLandingState);
    const [imageSrc, setImageSrc] = useState(NO_IMAGE);
    const { logo70h } = useContext(ResourceContext);
    useEffect(() => {
        // pre check the cdn link is valid
        if (logo70h)
            fetch(logo70h)
                .then(respond => {
                    const contentType = respond.headers.get('Content-Type');
                    const isImage = contentType?.startsWith('image') ?? false;
                    const src =
                        respond.ok && isImage && !hideLogo ? logo70h : NO_IMAGE;
                    setImageSrc(src);
                })
                .catch(e => {
                    console.error('lobby::logo::error', logo70h, e);
                });
    }, [logo70h]);
    return <img src={imageSrc} alt="logo" className="logo" />;
};
