import { GameState as GameStateEnum } from '../../models/games/enums/GameState';
import { Bet } from '../../models/host/BetAmount';
import { RootState } from '../../store/store';
import { getHostByGameId } from '../host/slice';
import { GAME_KEY, GameState } from './slice';

export const getGameSate = (state: RootState) => state[GAME_KEY] as GameState;
export const getPendingBets = (state: RootState): Array<Bet> | undefined => {
    const gameState = getGameSate(state);
    return gameState.pendingBets;
};
export const getTotalPendingBetsAmount = (state: RootState): number => {
    const gameState = getGameSate(state);
    let amount = 0;
    if (gameState.pendingBets) {
        gameState.pendingBets.forEach(bet => {
            amount += bet.Amount;
        });
    }
    return amount;
};
export const getTotalPendingWithHoldAmount = (state: RootState): number => {
    const gameState = getGameSate(state);
    let amount = 0;
    if (gameState.pendingBets) {
        gameState.pendingBets.forEach(bet => {
            amount += getWithHoldByBetType(state, bet.Type) * bet.Amount;
        });
    }
    return amount;
};

export const getPendingBetByBetType = (
    state: RootState,
    betType: number
): Bet | undefined => {
    const gameState = getGameSate(state);
    const index = gameState.pendingBets.findIndex(b => b.Type == betType);
    return index !== -1 ? gameState.pendingBets[index] : undefined;
};
export const getConfirmBets = (state: RootState): Array<Bet> | undefined => {
    const gameState = getGameSate(state);
    return gameState.confirmedBets;
};
export const getConfirmedBetByBetType = (
    state: RootState,
    betType: number,
    gameId: number
): Bet | undefined => {
    const host = getHostByGameId(state, gameId);
    if (
        host &&
        host.ConfirmedBets &&
        host.CurrentState !== GameStateEnum.Shuffle
    ) {
        const index = host.ConfirmedBets.findIndex(
            b => b.Type === betType && b.GameId === gameId
        );
        return index !== -1 ? host.ConfirmedBets[index] : undefined;
    }
    return undefined;
};
export const getWithHold = (state: RootState): Array<Bet> | undefined => {
    const gameState = getGameSate(state);
    return gameState.betTypeWithHold;
};
export const getWithHoldByBetType = (
    state: RootState,
    betType: number
): number => {
    const gameState = getGameSate(state);
    if (!gameState.betTypeWithHold) {
        return 0;
    }
    const bets = gameState.betTypeWithHold.find(lr => lr.Type === betType);
    if (bets) {
        return bets.Amount;
    }
    return 0;
};

export const getDoubleBets = (state: RootState): Array<Bet> | undefined => {
    const gameState = getGameSate(state);
    let bets = gameState.confirmedBets.map(
        b =>
            ({
                Type: b.Type,
                Amount: b.Amount,
                GameId: b.GameId,
            }) as Bet
    );
    gameState.pendingBets.forEach(bet => {
        const index = bets.findIndex(cb => cb.Type === bet.Type);
        if (index == -1) {
            bets.push(bet);
        } else {
            bets[index].Amount += bet.Amount;
        }
    });
    return bets;
};
export const getPreBets = (state: RootState): Array<Bet> | undefined => {
    const gameState = getGameSate(state);
    return gameState.prevBets;
};
export const getPrevBetByBetType = (
    state: RootState,
    betType: number
): Bet | undefined => {
    const gameState = getGameSate(state);
    const index = gameState.prevBets.findIndex(b => b.Type === betType);
    return index !== -1 ? gameState.prevBets[index] : undefined;
};

export const getSelectedBetType = (state: RootState): number => {
    const gameState = getGameSate(state);
    return gameState.currentBetType;
};
export const getCurrentGameId = (state: RootState): number => {
    const gameState = getGameSate(state);
    return gameState.currentGameId;
};
export const getAllConfirmedBetAmount = (state: RootState): number => {
    const gameState = getGameSate(state);
    const amount =
        gameState.confirmedBets
            .map(b => b.Amount)
            .reduce((partialSum, a) => partialSum + a, 0) || 0;
    return amount;
};
export const getAllWithHoldBetAmount = (state: RootState): number => {
    const gameState = getGameSate(state);
    if (!gameState.betTypeWithHold) {
        return 0;
    }
    let amount = 0;
    for (let i = 0; i < gameState.confirmedBets.length; i++) {
        const bets = gameState.betTypeWithHold.find(
            lr => lr.Type === gameState.confirmedBets[i].Type
        );
        if (bets) {
            amount += gameState.confirmedBets[i].Amount * bets.Amount;
        }
    }
    return amount / 100;
};
export const getOnDoubleBet = (state: RootState): boolean => {
    const gameState = getGameSate(state);
    return gameState.doubleBet;
};
export const isReBetOn = (state: RootState): boolean => {
    const gameState = getGameSate(state);
    return (
        gameState.prevBets.length > 0 &&
        gameState.confirmedBets.length === 0 &&
        gameState.pendingBets.length === 0
    );
};
export const getOnReBet = (state: RootState): boolean => {
    const gameState = getGameSate(state);
    return gameState.reBet;
};
export const getWaitingBetResult = (state: RootState): boolean => {
    const gameState = getGameSate(state);
    return gameState.waitingBetResult;
};
