import { Grid } from '@mui/material';
import BetArea from '../../../../../../components/common/betArea';
import { BetType } from '../../../../../../models/games/baccarat/BetType';
import { amountOverBetLimit, isOppositeBetting } from '../utils';
import { getBetAreaProps } from './betAreaInfo';

const BB_PLAYER_PAIR = getBetAreaProps(BetType.BBPlayerPair);
const BB_PLAYER_NATURAL = getBetAreaProps(BetType.BBPlayerNatural);
const BB_LUCKY_SIX = getBetAreaProps(BetType.BBSLuckySix);
const BB_BANKER_NATURAL = getBetAreaProps(BetType.BBBankerNatural);
const BB_BANKER_PAIR = getBetAreaProps(BetType.BBBankerPair);
const BB_PLAYER_WIN = getBetAreaProps(BetType.BBPlayerWin);
const BB_TIE = getBetAreaProps(BetType.BBTie);
const BB_BANKER_WIN = getBetAreaProps(BetType.BBBankerWin);
export const BaccaratBetArea = () => (
    <>
        <Grid
            item
            container
            flexWrap={'nowrap'}
            justifyContent={'space-between'}
            sx={{
                marginTop: '2px',
                marginBottom: '3px',
                width: 540,
            }}
        >
            <BetArea
                style={BB_PLAYER_PAIR.style}
                confirmPanelStyle={BB_PLAYER_PAIR.confirmPanelStyle}
                imageName={BB_PLAYER_PAIR.imageName}
                betType={BetType.BBPlayerPair}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                roundNumDisable={BB_PLAYER_PAIR.roundNumDisable}
            />
            <BetArea
                style={BB_PLAYER_NATURAL.style}
                confirmPanelStyle={BB_PLAYER_NATURAL.confirmPanelStyle}
                imageName={BB_PLAYER_NATURAL.imageName}
                betType={BetType.BBPlayerNatural}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                roundNumDisable={BB_PLAYER_NATURAL.roundNumDisable}
            />
            <BetArea
                style={BB_LUCKY_SIX.style}
                confirmPanelStyle={BB_LUCKY_SIX.confirmPanelStyle}
                imageName={BB_LUCKY_SIX.imageName}
                betType={BetType.BBSLuckySix}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                roundNumDisable={BB_LUCKY_SIX.roundNumDisable}
            />
            <BetArea
                style={BB_BANKER_NATURAL.style}
                confirmPanelStyle={BB_BANKER_NATURAL.confirmPanelStyle}
                imageName={BB_BANKER_NATURAL.imageName}
                betType={BetType.BBBankerNatural}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                roundNumDisable={BB_BANKER_NATURAL.roundNumDisable}
            />
            <BetArea
                style={BB_BANKER_PAIR.style}
                confirmPanelStyle={BB_BANKER_PAIR.confirmPanelStyle}
                imageName={BB_BANKER_PAIR.imageName}
                betType={BetType.BBBankerPair}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                roundNumDisable={BB_BANKER_PAIR.roundNumDisable}
            />
        </Grid>
        <Grid
            item
            container
            flexWrap={'nowrap'}
            justifyContent={'center'}
            width={540}
        >
            <BetArea
                style={BB_PLAYER_WIN.style}
                confirmPanelStyle={BB_PLAYER_WIN.confirmPanelStyle}
                imageName={BB_PLAYER_WIN.imageName}
                betType={BetType.BBPlayerWin}
                isShowBetAmount={true}
                betTypeKey="Player"
                perColor={'92,126,255'}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
            />
            <BetArea
                style={BB_TIE.style}
                confirmPanelStyle={BB_TIE.confirmPanelStyle}
                imageName={BB_TIE.imageName}
                betType={BetType.BBTie}
                isShowBetAmount={true}
                betTypeKey="Tie"
                perColor={'94,193,58'}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
            />
            <BetArea
                style={BB_BANKER_WIN.style}
                confirmPanelStyle={BB_BANKER_WIN.confirmPanelStyle}
                imageName={BB_BANKER_WIN.imageName}
                betType={BetType.BBBankerWin}
                isShowBetAmount={true}
                betTypeKey="Banker"
                perColor={'231,51,35'}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
            />
        </Grid>
    </>
);
