import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BaseImage from '../../../../components/common/baseImage';
import { useECard } from '../../../../hooks/useECard';
import { RootState } from '../../../../store/store';
import { BaccaratECard } from '../../../game/components/ECard/BaccaratECard';
import { getRoadmap } from '../../slice';
import BigEyeCell from '../cells/BigEyeCell';
import BigRoadCell from '../cells/BigRoadCell';
import CockroachRoadCell from '../cells/CockroachRoadCell';
import SmallRoadCell from '../cells/SmallRoadCell';
import { useRoadMapState } from '../hook';
import { BaccaratRoadMapProps } from './index';
import { getViewRoadRecord } from '../util';
import { getHostById } from '../../../host/slice';
import { GameState } from '../../../../models/games/enums/GameState';

export const BaccaratMultiBetMap = (props: BaccaratRoadMapProps) => {
    const { hostId } = props;
    const showECard = useECard(hostId);
    const { IsRest } = useRoadMapState(hostId);
    const { BigRoad, BigEyeRoad, SmallRoad, CockroachRoad } = useSelector(
        (state: RootState) => getRoadmap(state, hostId)
    );
    const { CurrentState } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [showRoadMap, setShowRoadMap] = useState<boolean>(false);
    // ui
    const [bigCells, setBigCells] = useState<Array<JSX.Element>>([]);
    const [bigEyeCells, setBigEyeCells] = useState<Array<JSX.Element>>([]);
    const [smallCells, setSmallCells] = useState<Array<JSX.Element>>([]);
    const [cockroachCells, setCockroachCells] = useState<Array<JSX.Element>>(
        []
    );
    useEffect(() => {
        setShowRoadMap(!IsRest && CurrentState !== GameState.Shuffle);
    }, [IsRest, CurrentState]);
    useEffect(() => {
        if (showRoadMap) {
            const list = getViewRoadRecord(BigRoad, 51);
            const bc = list?.map((record, idx) => {
                const key = `big-road-cell-${idx}`;
                return <BigRoadCell record={record} key={key} />;
            });
            setBigCells(bc);
        } else {
            setBigCells([]);
        }
    }, [BigRoad, showRoadMap]);
    useEffect(() => {
        if (showRoadMap) {
            const bc = BigEyeRoad?.map((record, idx) => {
                const key = `big-eye-cell-${idx}`;
                return <BigEyeCell win={record.Win} key={key} />;
            });
            setBigEyeCells(bc);
        } else {
            setBigEyeCells([]);
        }
    }, [BigEyeRoad, showRoadMap]);
    useEffect(() => {
        if (showRoadMap) {
            const list = getViewRoadRecord(SmallRoad, 51);
            const sc = list?.map((record, idx) => {
                const key = `small-road-cell-${idx}`;
                return <SmallRoadCell win={record.Win} key={key} />;
            });
            setSmallCells(sc);
        } else {
            setSmallCells([]);
        }
    }, [SmallRoad, showRoadMap]);
    useEffect(() => {
        if (showRoadMap) {
            const list = getViewRoadRecord(CockroachRoad, 51);
            const cc = list?.map((record, idx) => {
                const key = `cockroach-road-cell-${idx}`;
                return <CockroachRoadCell win={record.Win} key={key} />;
            });
            setCockroachCells(cc);
        } else {
            setCockroachCells([]);
        }
    }, [CockroachRoad, showRoadMap]);

    return (
        <div className="BaccaratMultiBetMainContainer">
            <div className="roadContainer">
                <BaseImage
                    className="multibet_game roadmap_multibet_baccarat"
                    scale={1}
                />
                <div className="bigRoad">{bigCells}</div>
                <div className="bigEyeRoad">{bigEyeCells}</div>
                <div className="smallRoad">{smallCells}</div>
                <div className="cockroachRoad">{cockroachCells}</div>
            </div>
            <BaccaratECard show={showECard} isMultiBet={true} hid={hostId} />
        </div>
    );
};
