import { BetType as BetTypeEnum } from '../../../../../models/games/sicbo/BetType';
import { Bet } from '../../../../../models/host/BetAmount';
import store from '../../../../../store/store';
import { GAME_KEY } from '../../../../games/slice';
import { PHOST_KEY } from '../../../../host/slice';

const calculateBetAmount = (
    bets: Array<Bet>,
    matchBetTypes: number
): number => {
    let amount = 0;
    amount = bets
        .filter(item => item.Type == matchBetTypes)
        .reduce((sum, bet) => sum + bet.Amount, 0);
    return amount;
};
const getTotalBet = (betType: BetTypeEnum) => {
    const { pendingBets, confirmedBets } = store.getState()[GAME_KEY];
    return (
        calculateBetAmount(pendingBets, betType) +
        calculateBetAmount(confirmedBets, betType)
    );
};

const getBetLimitByBetType = (betType: number, hostId: number): number => {
    const host = store.getState()[PHOST_KEY].entities[hostId];
    let limit = 0;
    if (host && host.MaxBet) {
        const maxBet = host.MaxBet.find(mb => mb.BetType === betType);
        if (maxBet) {
            return maxBet.MaxBet;
        }
    }
    return limit;
};

export const isOverBetLimit = (
    betType: number,
    hostId: number,
    chipAmount: number
): boolean => {
    return amountOverBetLimit(betType, hostId, chipAmount) > 0;
};

export const amountOverBetLimit = (
    betType: number,
    hostId: number,
    chipAmount: number
): number => {
    let amount = 0;
    amount =
        chipAmount +
        getTotalBet(betType) -
        getBetLimitByBetType(betType, hostId);

    return amount;
};

export const isOppositeBetting = (betType: number): boolean => {
    switch (betType) {
        case BetTypeEnum.SBBSmall:
            return getTotalBet(BetTypeEnum.SBBBig) > 0;
        case BetTypeEnum.SBBBig:
            return getTotalBet(BetTypeEnum.SBBSmall) > 0;
        case BetTypeEnum.SBBOdd:
            return getTotalBet(BetTypeEnum.SBBEven) > 0;
        case BetTypeEnum.SBBEven:
            return getTotalBet(BetTypeEnum.SBBOdd) > 0;
        default:
            return false;
    }
};
