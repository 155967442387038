import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InGamePopupKeyType } from '../../../../hooks/useInGamePopup';
import { useContext, useEffect, useRef } from 'react';
// import { useSelector } from 'react-redux';
import { AudioPlayContext } from '../../../../contexts/AudioPlayContext';
// import { VideoPlayerContext } from '../../../../contexts/VideoContext';
// import { RootState } from '../../../../store/store';
// import { getHostById } from '../../../host/slice';

export const BlackjackGameResultPopup = (props: InGamePopupKeyType) => {
    const { amount, bg, message, audioKey, playSound } = props;
    const { t } = useTranslation();
    const { audioPlay } = useContext(AudioPlayContext);
    // const { hostId } = useContext(VideoPlayerContext);
    // const { Record, CurrentResult } = useSelector((state: RootState) =>
    //     getHostById(state, hostId)
    // );
    const ref = useRef<boolean>(false); //防止play 2 次
    useEffect(() => {
        if (!ref.current) {
            ref.current = true;
            if (audioKey && playSound) {
                audioPlay(audioKey);
            }
        }
    }, []);

    return (
        <>
            {amount ? (
                <Stack
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                        position: 'absolute',
                        height: 100,
                        width: 540,
                        top: 602,
                        left: 0,
                        right: 0,
                        fontSize: 40,
                        borderRadius: '4px',
                        backgroundColor: bg,
                        textAlign: 'center',
                        zIndex: theme => theme.zIndex.drawer + 80,
                    }}
                >
                    {t(message)}
                    {amount && (
                        <>
                            <Typography
                                sx={{ fontSize: 28, marginLeft: '10px' }}
                            >
                                {amount}
                            </Typography>
                        </>
                    )}
                </Stack>
            ) : (
                <Stack
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                        position: 'absolute',
                        height: 150,
                        width: 400,
                        top: 502,
                        left: 70,
                        right: 70,
                        fontSize: 35,
                        borderRadius: '4px',
                        backgroundColor: bg,
                        lineHeight: 1.5,
                        textAlign: 'center',
                        zIndex: theme => theme.zIndex.drawer + 80,
                    }}
                >
                    {t(message)}
                </Stack>
            )}
        </>
    );
};
