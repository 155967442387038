import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StateMsgType } from '../../models/Popup';
import { GameType as GameTypeEnum } from '../../models/games/enums/GameType';
const open = {
    reducer(state: InGamePopupState, action: PayloadAction<InGamePopupState>) {
        state.isOpen = action.payload.isOpen;
        state.key = action.payload.key;
        state.gameID = action.payload.gameID;
        state.gameType = action.payload.gameType;
        state.stateMsgType = action.payload.stateMsgType;

        state.dismiss = action.payload.dismiss;
        state.popupId = state.popupId + 1;
        state.confirmFunc = action.payload.confirmFunc;
        state.params = action.payload.params;
    },
    prepare(
        payload: string | Array<string>,
        gameType: GameTypeEnum = GameTypeEnum.Baccarat,
        stateMsgType: StateMsgType = StateMsgType.betInfo_Normal,
        gameID: number = 0,
        dismiss: number = 2000, //default 2000
        confirmFunc?: () => void,
        popupId: number = 0,
        params: Record<string, string> = {}
    ) {
        return {
            payload: {
                isOpen: true,
                key: payload,
                gameID: gameID,
                gameType: gameType,
                stateMsgType: stateMsgType,
                confirmFunc: confirmFunc?.toString(),
                dismiss: dismiss,
                popupId: popupId,
                params: params,
            },
        };
    },
};
const close = (state: InGamePopupState) => {
    state.isOpen = false;
    state.popupId = state.popupId + 1;
};
export type InGamePopupState = {
    isOpen: boolean;
    key: string | Array<string>;
    gameType: GameTypeEnum;
    stateMsgType: StateMsgType;
    gameID: number;
    dismiss: number; //ms, set timeout close,
    popupId: number;
    confirmFunc?: string; //() => void | undefined;
    params?: Record<string, string>;
};
const initialInGamePopupState = {
    isOpen: false,
    key: '',
    gameType: GameTypeEnum.Baccarat,
    stateMsgType: StateMsgType.betInfo_Normal,
    gameID: 0,
    dismiss: 2000,
    popupId: 0,
    params: undefined,
} as InGamePopupState;
export const GAME_POPUP_KEY = 'app::game::popup';
const inGamePopupSlice = createSlice({
    name: GAME_POPUP_KEY,
    initialState: initialInGamePopupState,
    reducers: {
        open,
        close,
    },
});
export const inGamePopupSliceReducer = inGamePopupSlice.reducer;
export const inGamePopupSliceActions = inGamePopupSlice.actions;
