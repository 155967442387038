import { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StateMsgType as StateMsgTypeEnum } from '../../../../models/Popup';
import { getInGamePopupSate } from '../../../popup/selector';
import { Stack, Typography } from '@mui/material';
import BaseImage from '../../../../components/common/baseImage';
import { AudioPlayContext } from '../../../../contexts/AudioPlayContext';
import { SoundList, VoiceList } from '../../../../models/Voice';

export const DragonTigerBetResultPopup = () => {
    const { t, i18n } = useTranslation();
    const popupData = useSelector(getInGamePopupSate);
    const { key, stateMsgType } = popupData;
    const { audioPlay } = useContext(AudioPlayContext);

    const [message, setMessage] = useState<string>('');
    const [icon, setIcon] = useState<string>('');
    const [textColor, setTextColor] = useState<string>('#ffffff');
    const ref = useRef<boolean>(false); //防止play 2 次
    useEffect(() => {
        if (key) {
            let msg: string[] = [];
            if (typeof key === 'string') {
                msg.push(key);
            } else {
                msg = key;
            }
            const transMsg = msg.map(m => {
                return t(m);
            });
            setMessage(transMsg.join(' '));

            if (!ref.current) {
                ref.current = true;
                let aKey: Array<string> = [];
                switch (key) {
                    case 'system.bet_success':
                        aKey = [VoiceList.BettingSuccess];
                        break;
                    case 'system.not_enough_money':
                        aKey = [VoiceList.NotEnoughCredit];
                        break;
                    case 'system.bet_exceed_limit_allin':
                        aKey = [SoundList.ChipsBet];
                        break;
                    case 'system.all_in':
                        aKey = [VoiceList.AllIn];
                        break;
                }
                if (aKey && aKey.length > 0) {
                    audioPlay(aKey);
                }
            }
        }
    }, [key, i18n.language]);
    useEffect(() => {
        if (stateMsgType !== StateMsgTypeEnum.betInfo_Normal) {
            switch (stateMsgType) {
                case StateMsgTypeEnum.betInfo_Success:
                    setIcon('commonUI ic_success2');
                    setTextColor('#00ff00');
                    break;
                case StateMsgTypeEnum.betInfo_Fail:
                    setIcon('commonUI ic_failure2');
                    setTextColor('#ff0000');
                    break;
                case StateMsgTypeEnum.betInfo_Warning:
                    setIcon('commonUI ic_warning');
                    setTextColor('#868583');
                    break;
            }
        }
    }, [stateMsgType]);

    return (
        <>
            {message && (
                <>
                    <Stack
                        justifyContent={'center'}
                        alignItems={'center'}
                        spacing={1}
                        sx={{
                            position: 'absolute',
                            minWidth: '400px',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'rgba(0,0,0,0.75)',
                            borderRadius: '4px',
                            padding: '15px 0 10px 0',
                            zIndex: theme => theme.zIndex.drawer + 100,
                        }}
                    >
                        {icon && (
                            <>
                                <BaseImage className={icon} scale={1} />
                            </>
                        )}
                        <Typography
                            sx={{
                                color: textColor,
                                fontSize: 35,
                                textAlign: 'center'
                            }}
                        >
                            {message}
                        </Typography>
                    </Stack>
                </>
            )}
        </>
    );
};
