import { Button } from '@mui/material';

export type PopupButtonProps = {
    onClickHandler: (o: boolean) => void;
};
export const PopupButton = ({ onClickHandler }: PopupButtonProps) => {
    return (
        <Button
            onClick={() => onClickHandler(true)}
            sx={{ marginLeft: '10px', padding: 0, minWidth: 0 }}
        >
            <svg
                width="40"
                height="40"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs></defs>
                <path
                    d="M 72.5 0.5 L 7.5 0.5 C 3.358 0.5 0 3.817 0 7.909 L 0 72.122 C 0 76.216 3.358 79.53 7.5 79.53 L 72.5 79.53 C 76.642 79.53 80 76.216 80 72.122 L 80 7.909 C 80 3.817 76.642 0.5 72.5 0.5 Z"
                    fill="#735F41"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M 31.875 30.66 L 23.625 38.838 L 31.948 38.91 L 40.125 38.838 L 31.875 30.66 Z"
                    fill="white"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M 29.625 52.466 L 34.125 52.466 L 34.125 33.66 L 29.625 33.66 L 29.625 52.466 Z"
                    fill="white"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M 23.625 27.66 L 23.625 30.66 L 40.125 30.66 L 40.125 27.66 L 23.625 27.66 Z"
                    fill="white"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M 49.125 55.62 L 57.375 47.442 L 49.052 47.37 L 40.875 47.442 L 49.125 55.62 Z"
                    fill="white"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M 51.375 33.814 L 46.875 33.814 L 46.875 52.62 L 51.375 52.62 L 51.375 33.814 Z"
                    fill="white"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M 57.375 58.62 L 57.375 55.62 L 40.875 55.62 L 40.875 58.62 L 57.375 58.62 Z"
                    fill="white"
                ></path>
            </svg>
        </Button>
    );
};
