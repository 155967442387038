import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { getHostById } from '../../../../host/slice';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import {
    getMainLimitRedState,
    getMainUserState,
} from '../../../../main/selector';
import { getMaxBetByBetType } from '../util';
import { BetType } from '../../../../../models/games/andarBahar/BetType';
import { ExchangeBetAmount } from '../../../../../utils/commonFunc';
import { limitRedFormatter } from '../../../../../utils/formatter';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Stack,
} from '@mui/material';
import BaseImage from '../../../../../components/common/baseImage';
import { REPORT_COLORS } from '../../../../lobby/constants';
import { KeyboardArrowUp } from '@mui/icons-material';
import { CancelButton } from '../../../../setting/components/CancelButton';
import { AudioPlayContext } from '../../../../../contexts/AudioPlayContext';

type InfoProps = {
    title: string;
    value: string | number;
};
type TableInfoTypeRow1 = {
    table: InfoProps;
    dealer: InfoProps;
    round: InfoProps;
    id: InfoProps;
    tableLimit: InfoProps;
};
type TableInfoTypeRow2 = {
    betLimit: InfoProps;
    andar: InfoProps;
    bahar: InfoProps;
};
type TableInfoType = [TableInfoTypeRow1, TableInfoTypeRow2];
const initTableInfoData = [
    {
        table: {
            title: 'tableinfo.table',
            value: '',
        },
        dealer: {
            title: 'tableinfo.dealer',
            value: '',
        },
        round: {
            title: 'tableinfo.shoe',
            value: '',
        },
        id: {
            title: 'tableinfo.round',
            value: '',
        },
        tableLimit: {
            title: 'tableinfo.tablelimit',
            value: '',
        },
    },
    {
        betLimit: {
            title: 'betLimit',
            value: '',
        },
        andar: {
            title: 'betlog.bet_andarbahar.0',
            value: '',
        },
        bahar: {
            title: 'betlog.bet_andarbahar.1',
            value: '',
        },
    },
] as TableInfoType;

type TableInfoTypePopup = {
    andar: InfoProps;
    bahar: InfoProps;
    c1To5: InfoProps;
    c6To10: InfoProps;
    c11To15: InfoProps;
    c16To20: InfoProps;
    c21To25: InfoProps;
    c26To30: InfoProps;
    c31To35: InfoProps;
    c36To40: InfoProps;
    c41To45: InfoProps;
    c46To49: InfoProps;
    first3: InfoProps;
    firstAndar: InfoProps;
    firstBahar: InfoProps;
};
const initPopupInfo = {
    andar: {
        title: 'andar_bahar.andar',
        value: '',
    },
    bahar: {
        title: 'andar_bahar.bahar',
        value: '',
    },
    c1To5: {
        title: '1-5',
        value: '',
    },
    c6To10: {
        title: '6-10',
        value: '',
    },
    c11To15: {
        title: '11-15',
        value: '',
    },
    c16To20: {
        title: '16-20',
        value: '',
    },
    c21To25: {
        title: '21-25',
        value: '',
    },
    c26To30: {
        title: '26-30',
        value: '',
    },
    c31To35: {
        title: '31-35',
        value: '',
    },
    c36To40: {
        title: '36-40',
        value: '',
    },
    c41To45: {
        title: '41-45',
        value: '',
    },
    c46To49: {
        title: '46-49',
        value: '',
    },
    first3: {
        title: 'andar_bahar.first_3',
        value: '',
    },
    firstAndar: {
        title: 'andar_bahar.1st_andar',
        value: '',
    },
    firstBahar: {
        title: 'andar_bahar.1st_bahar',
        value: '',
    },
} as TableInfoTypePopup;
export const AndarBaharBetLimitInfoTab = () => {
    const { t } = useTranslation();
    const { hostId } = useContext(VideoPlayerContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { CurrentResult, DealerList, TableLimit, GameType, MaxBet } = host;
    const { ExchangeRate, CurrencyName } = useSelector((state: RootState) =>
        getMainUserState(state)
    );
    const limitRedByGameType = useSelector(getMainLimitRedState);
    const [tableInfo, setTableInfo] =
        useState<TableInfoType>(initTableInfoData);
    const [popupTableInfo, setPopupTableInfo] =
        useState<TableInfoTypePopup>(initPopupInfo);
    const [showBetLimit, setShowBetLimit] = useState<boolean>(false);

    const handleShowBetLimit = (v: boolean) => {
        setShowBetLimit(v);
        playButtonAudio();
    };

    const needTranslate = (value: string): boolean => {
        return /^[A-Za-z]/.test(value);
    };

    useEffect(() => {
        if (MaxBet) {
            tableInfo[1].andar.value = getMaxBetByBetType(
                BetType.ABBAndarWin,
                MaxBet
            );
            tableInfo[1].bahar.value = getMaxBetByBetType(
                BetType.ABBBaharWin,
                MaxBet
            );
            setTableInfo(tableInfo);

            popupTableInfo.andar.value = getMaxBetByBetType(
                BetType.ABBAndarWin,
                MaxBet
            );
            popupTableInfo.bahar.value = getMaxBetByBetType(
                BetType.ABBBaharWin,
                MaxBet
            );
            popupTableInfo.c1To5.value = getMaxBetByBetType(
                BetType.ABB1To5,
                MaxBet
            );
            popupTableInfo.c6To10.value = getMaxBetByBetType(
                BetType.ABB6To10,
                MaxBet
            );
            popupTableInfo.c11To15.value = getMaxBetByBetType(
                BetType.ABB11To15,
                MaxBet
            );
            popupTableInfo.c16To20.value = getMaxBetByBetType(
                BetType.ABB16To20,
                MaxBet
            );
            popupTableInfo.c21To25.value = getMaxBetByBetType(
                BetType.ABB21To25,
                MaxBet
            );
            popupTableInfo.c26To30.value = getMaxBetByBetType(
                BetType.ABB26To30,
                MaxBet
            );
            popupTableInfo.c31To35.value = getMaxBetByBetType(
                BetType.ABB31To35,
                MaxBet
            );
            popupTableInfo.c36To40.value = getMaxBetByBetType(
                BetType.ABB36To40,
                MaxBet
            );
            popupTableInfo.c41To45.value = getMaxBetByBetType(
                BetType.ABB41To45,
                MaxBet
            );
            popupTableInfo.c46To49.value = getMaxBetByBetType(
                BetType.ABB46To49,
                MaxBet
            );
            popupTableInfo.first3.value = getMaxBetByBetType(
                BetType.ABBFirst3,
                MaxBet
            );
            popupTableInfo.firstAndar.value = getMaxBetByBetType(
                BetType.ABBFirstAndar,
                MaxBet
            );
            popupTableInfo.firstBahar.value = getMaxBetByBetType(
                BetType.ABBFirstBahar,
                MaxBet
            );
            setPopupTableInfo(popupTableInfo);
        }
    }, [MaxBet]);
    useEffect(() => {
        if (
            hostId &&
            DealerList &&
            TableLimit &&
            CurrentResult &&
            limitRedByGameType.BetRuleByGameType
        ) {
            //row 1
            tableInfo[0].table.value = t(`hostList.${hostId}`);
            tableInfo[0].dealer.value = DealerList[0].DealerName;
            const shoes = Math.floor(CurrentResult.GameCount / 10000);
            const round = CurrentResult.GameCount % 10000;
            const row1Round = shoes && round ? `${shoes}-${round}` : round;
            tableInfo[0].round.value = row1Round;
            tableInfo[0].id.value = CurrentResult.GameID || 0;
            let infoTableLimit =
                ExchangeBetAmount(TableLimit, ExchangeRate) / 100;
            const digi = Math.pow(10, infoTableLimit.toString().length - 1);
            setTableInfo(tableInfo);
            const tableLimitValue = (
                Math.round(infoTableLimit / digi) * digi
            ).toLocaleString('en-US', {
                maximumFractionDigits: 2,
            });
            tableInfo[0].tableLimit.value = tableLimitValue;
            //row 2
            let betLimitV = '';
            const limitRedByGame = limitRedByGameType.BetRuleByGameType?.find(
                br => br.GameType === GameType
            );
            if (limitRedByGame) {
                const BetRule = limitRedByGame.BetRule;
                let selectedBetRule = BetRule.find(r => r.Selected === 1);
                if (!selectedBetRule) selectedBetRule = BetRule[0]; //有機會全部selected=0
                if (selectedBetRule) {
                    betLimitV = limitRedFormatter(
                        selectedBetRule,
                        CurrencyName
                    );
                }
            }
            tableInfo[1].betLimit.value = betLimitV;
        }
    }, [
        hostId,
        CurrentResult,
        DealerList,
        TableLimit,
        limitRedByGameType.BetRuleByGameType,
    ]);
    return (
        <>
            {tableInfo && (
                <>
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        sx={{ fontSize: '14px', height: '172px' }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                            }}
                        >
                            <BaseImage
                                className="tradbaccBg table_info_bg"
                                scale={1}
                                opacity={0.5}
                                // 18px = (196 - 161) / 2
                                // 1.226 = 196 / 161
                                transform={
                                    'translate(0, 17.5px) scale(1, 1.226)'
                                }
                            />
                        </Box>
                        {tableInfo.map((r, i) => {
                            const style =
                                i !== 1
                                    ? { width: '50%', zIndex: '1' }
                                    : {
                                          width: '50%',
                                          zIndex: '1',
                                          borderLeft:
                                              '2px solid rgba(255,255,255,0.2)',
                                          borderRight:
                                              '2px solid rgba(255,255,255,0.2)',
                                      };
                            return (
                                <Box key={`info-${i}`} sx={style}>
                                    <>
                                        {Object.keys(r).map((k, ii) => {
                                            const v = r[
                                                k as keyof typeof r
                                            ] as InfoProps;
                                            return (
                                                <Grid
                                                    key={`info-cell${ii}`}
                                                    item
                                                    container
                                                    justifyContent={
                                                        'space-between'
                                                    }
                                                    alignItems={'center'}
                                                    sx={{
                                                        height: '35px',
                                                        padding:
                                                            '0 7.5px 0 7.5px',
                                                    }}
                                                >
                                                    <Grid
                                                        item
                                                        sx={{
                                                            color: REPORT_COLORS.TITLE_TEXT,
                                                        }}
                                                    >
                                                        {needTranslate(v.title)
                                                            ? t(v.title)
                                                            : v.title}
                                                    </Grid>
                                                    <Grid item>{v.value}</Grid>
                                                </Grid>
                                            );
                                        })}
                                    </>
                                </Box>
                            );
                        })}
                        <Button
                            sx={{
                                position: 'absolute',
                                bottom: '6px',
                                right: '8px',
                                width: '47%',
                                height: 32,
                                minHeight: 32,
                                bgcolor: '#876F4D',
                                zIndex: 1,
                            }}
                            onClick={() => handleShowBetLimit(true)}
                        >
                            <KeyboardArrowUp sx={{ color: '#ffffff' }} />
                        </Button>
                        <Dialog
                            container={() =>
                                document.getElementById(
                                    'in-game-popup-container'
                                )
                            }
                            fullScreen
                            open={showBetLimit}
                            onClose={() => handleShowBetLimit(false)}
                            hideBackdrop={true}
                            disableEscapeKeyDown={true}
                            PaperProps={{
                                sx: {
                                    backgroundColor: 'rgba(0,0,0,0.85)',
                                    boxShadow: 'none',
                                },
                            }}
                        >
                            <DialogTitle
                                sx={{
                                    paddingTop: '100px',
                                    fontSize: '29px',
                                    textAlign: 'center',
                                }}
                            >
                                {t('betLimit2')}
                            </DialogTitle>

                            <DialogContent
                                sx={{
                                    justifyContent: 'center',
                                    // overflowY: 'visible',
                                    padding: '20px',
                                    fontSize: 18,
                                }}
                            >
                                <Grid
                                    item
                                    container
                                    justifyContent={'center'}
                                    display={'grid'}
                                    gridAutoFlow={'column'}
                                    gridTemplateColumns={'repeat(2, 250px)'}
                                    gridTemplateRows={'repeat(8, 70px)'}
                                    sx={{
                                        width: 500,
                                        fontSize: 18,
                                        overflowY: 'auto',
                                        whiteSpace: 'nowrap',
                                        scrollBehavior: 'smooth',
                                        scrollbarWidth: 'none',
                                        '&::-webkit-scrollbar': {
                                            display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                                        },
                                        '&-ms-overflow-style': {
                                            display: 'none', // Hide the scrollbar for IE
                                        },
                                    }}
                                >
                                    {popupTableInfo &&
                                        Object.values(popupTableInfo).map(
                                            (v, id) => {
                                                return (
                                                    <Grid
                                                        item
                                                        container
                                                        key={`bet-limit-cell${id}`}
                                                        direction={'column'}
                                                        justifyContent={
                                                            'flex-start'
                                                        }
                                                        alignItems={
                                                            'flex-start'
                                                        }
                                                        width={250}
                                                        height={70}
                                                        marginLeft={'20px'}
                                                    >
                                                        <Grid
                                                            item
                                                            sx={{
                                                                color: REPORT_COLORS.TITLE_TEXT,
                                                            }}
                                                        >
                                                            {needTranslate(
                                                                v.title
                                                            )
                                                                ? t(v.title)
                                                                : v.title}
                                                        </Grid>
                                                        <Grid item>
                                                            {v.value}
                                                        </Grid>
                                                    </Grid>
                                                );
                                            }
                                        )}
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction={'column'}
                                    justifyContent={'center'}
                                >
                                    <CancelButton
                                        onClick={() =>
                                            handleShowBetLimit(false)
                                        }
                                        image={'commonUI ic_close'}
                                    />
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </Stack>
                </>
            )}
        </>
    );
};
