import { Grid, Typography } from '@mui/material';
import NetState from '../../components/common/netState';
import {
    GamingCuracaoLogo,
    GamingLabLogo,
    SaLoadingLogo,
    SaLoadingOverlayLogo,
    SaLogo,
} from '../../components/images';
import { useProgress } from '../../hooks/useProgress';
import './index.scss';

export const LoadingProgressOverlay = () => (
    <div className="progress loading overlay">
        <SaLoadingOverlayLogo />
    </div>
);

export const LoadingProgress = () => {
    return (
        <>
            <Grid
                container
                width="100%"
                height="100%"
                justifyContent={'center'}
            >
                <LoadingLogo />
                <CertLogoGroup />
                <VersionLabel />
            </Grid>
        </>
    );
};

const LoadingLogo = () => (
    <div className="progress loading">
        <SaLogo />
        <SaLoadingLogo />
    </div>
);

const VersionLabel = () => {
    const { loadingCount, resultCount } = useProgress();
    return (
        <Grid container justifyContent={'center'} gap={'3px'}>
            <NetState fontSize={'18px'} color={'white'} />
            <Typography fontSize={'18px'} color={'white'}>
                ({loadingCount}.{resultCount})
            </Typography>
        </Grid>
    );
};

const CertLogoGroup = () => (
    <Grid container justifyContent={'space-evenly'}>
        <Grid item>
            <GamingCuracaoLogo width={140} />
        </Grid>
        <Grid item>
            <GamingLabLogo width={140} />
        </Grid>
    </Grid>
);
