import './SicBoStatisticsButton.scss';
import { Box, Button } from '@mui/material';
import {
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';
import { useContext, useState } from 'react';
import BaseImage from '../../../../../../components/common/baseImage';
import { SicboStatisticPoint } from './SicboStatisticPoint';
import { SicboStatisticBetType } from './SicboStatisticBetType';
import { SicboStatisticDice } from './SicboStatisticDice';
import { theme } from '../../../../../../theme';
import { AudioPlayContext } from '../../../../../../contexts/AudioPlayContext';
import { CommonConfigContext } from '../../../../../../contexts/ConfigContext';

type Props = {
    handleOpenStatistics: (b: boolean) => void;
    openStatistics: boolean;
};
export const SicBoStatisticsButton = (props: Props) => {
    const { handleOpenStatistics, openStatistics } = props;
    const { hideSwitchTable } = useContext(CommonConfigContext);
    return (
        <>
            <Button
                variant="contained"
                className="sicbo-statistics-button"
                disableElevation
                disableRipple
                onClick={() => handleOpenStatistics(!openStatistics)}
                sx={{
                    right: hideSwitchTable ? '0px' : '50px',
                }}
            >
                <BaseImage
                    className={`commonUI btnStatistic_${
                        openStatistics ? 'on' : 'off'
                    }`}
                    // 24px = (40 - 88) / 2
                    // 0.4545 = 40 / 88
                    // -14.5px = (40 - 69) / 2
                    // 0.4545 = 40 / 69
                    //transform={'translate(-24px,-14.5px) scale(0.4545,0.5797)'}
                    transform={'translate(-12px) scale(0.909,1.1594)'}
                />
            </Button>
            {openStatistics && <SicBoStatisticsPopup />}
        </>
    );
};

const SicBoStatisticsPopup = () => {
    const [curTab, setCurTab] = useState<number>(0);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const handleLeftButton = (tab: number) => {
        playButtonAudio();
        tab--;
        if (tab >= 0) {
            setCurTab(tab);
        }
    };
    const handleRightButton = (tab: number) => {
        playButtonAudio();
        tab++;
        if (tab <= 2) {
            setCurTab(tab);
        }
    };
    return (
        <>
            <Box
                className="sicbo-statistics-popup"
                zIndex={theme.zIndex.drawer - 350}
            >
                <Box className="statistics">
                    <SicBoStatistics curTab={curTab} />
                </Box>
                <Button
                    className="left-btn"
                    onClick={() => handleLeftButton(curTab)}
                    disableRipple
                >
                    <KeyboardArrowLeftIcon />
                </Button>
                <Button
                    className="right-btn"
                    onClick={() => handleRightButton(curTab)}
                    disableRipple
                >
                    <KeyboardArrowRightIcon />
                </Button>
            </Box>
        </>
    );
};

const SicBoStatistics = ({ curTab }: { curTab: number }) => {
    switch (curTab) {
        case 0:
            return <SicboStatisticPoint />;
        case 1:
            return <SicboStatisticDice />;
        case 2:
            return <SicboStatisticBetType />;
        default:
            return <></>;
    }
};
