import { Box, Grid, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import betAreaDtBorder from '../../../../../assets/image/common/dt/betAreaDtBorder.png';
import BetArea from '../../../../../components/common/betArea';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import { useBalance } from '../../../../../hooks/useBalance';
import { StateMsgType } from '../../../../../models/Popup';
import { DragonTigerTotalBet } from '../../../../../models/games/dragonTiger';
import { BetType } from '../../../../../models/games/dragonTiger/BetType';
import { GameState as GameStateEnum } from '../../../../../models/games/enums/GameState';
import { Bet } from '../../../../../models/host/BetAmount';
import { RootState } from '../../../../../store/store';
import { checkBetsAvailed } from '../../../../../utils/commonFunc';
import {
    getCurrentGameId,
    getGameSate,
    getTotalPendingBetsAmount,
    isReBetOn,
} from '../../../../games/selector';
import { gameSliceActions } from '../../../../games/slice';
import { getHostById } from '../../../../host/slice';
import { inGamePopupSliceActions } from '../../../../popup/inGameSlice';
import { getTotalBets } from '../../../../totalBet/slice';
import { ChipList } from '../Baccarat/ChipList';
import { ChipSelector } from '../Baccarat/ChipSelector';
import { DoubleBetButton } from '../Baccarat/DoubleBetButton';
import { ReBetButton } from '../Baccarat/ReBetButton';
import betAreaJson from './betArea.json';
import { amountOverBetLimit, isOppositeBetting } from './utils';

export const getDTTableBetAreaColor = (g: string) => {
    switch (g) {
        case 'e':
        case 'm':
            return '3,66,31'; //03421f
        case 'a':
            return '100,62,64'; //643E40
        default:
            return '20,46,20'; //142e14
    }
};
const { betType } = betAreaJson;
const DragonTigerBetAreaPanel = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { IsRest, CurrentState, ResultReleased, Group } = useSelector(
        (state: RootState) => getHostById(state, hostId)
    );
    const reBetButtonEnable = useSelector((state: RootState) =>
        isReBetOn(state)
    );
    const [isShowReBet, setIsShowReBet] = useState<boolean>(false);
    const [tableColor, setTableColor] = useState<string>('');
    const [isBetting, setIsBetting] = useState<boolean>(false);

    const [doubleBets, setDoubleBets] = useState<Array<Bet>>([]);

    const { doubleBet, reBet, prevBets, pendingBets, confirmedBets } =
        useSelector(getGameSate);
    useEffect(() => {
        const bets = new Array<Bet>();
        for (const cb of confirmedBets) {
            bets.push({ Type: cb.Type, Amount: cb.Amount, GameId: cb.GameId });
        }
        for (const pb of pendingBets) {
            const index = bets.findIndex(cb => cb.Type === pb.Type);
            if (index === -1) bets.push(pb);
            else bets[index].Amount += pb.Amount;
        }
        setDoubleBets(bets);
    }, [pendingBets, confirmedBets]);

    const addingBet = useRef(false);
    const [toAddBets, setToAddBets] = useState<Array<Bet>>();
    const allPendingBetsAmount = useSelector((state: RootState) =>
        getTotalPendingBetsAmount(state)
    );
    const { availableBalance } = useBalance();
    const currentGameId = useSelector((state: RootState) =>
        getCurrentGameId(state)
    );
    const dispatch = useDispatch();

    useEffect(() => {
        setIsBetting(IsRest ? false : CurrentState === GameStateEnum.Betting);
        if (
            CurrentState === GameStateEnum.Shuffle ||
            CurrentState === GameStateEnum.Idle
        ) {
            setDragonPerValue(0);
            setTigerPerValue(0);
            setTiePerValue(0);
        }
    }, [IsRest, CurrentState, ResultReleased]);
    useEffect(() => {
        setIsShowReBet(isBetting ? reBetButtonEnable : false);
    }, [reBetButtonEnable, isBetting]);
    useEffect(() => {
        if (doubleBet) {
            if (doubleBets && !toAddBets && !addingBet.current) {
                addingBet.current = true;
                setToAddBets(doubleBets);
            }
            dispatch(gameSliceActions.onDoubleBet({ onClick: false }));
        }
    }, [doubleBet, doubleBets]);
    useEffect(() => {
        if (reBet) {
            if (prevBets && !toAddBets && !addingBet.current) {
                addingBet.current = true;
                setToAddBets(prevBets);
            }
            dispatch(gameSliceActions.onReBet({ onClick: false }));
        }
    }, [reBet, prevBets]);
    useEffect(() => {
        if (Group) {
            setTableColor(getDTTableBetAreaColor(Group[0]));
        }
    }, [Group]);

    const { GameType } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const { TotalBet } = useSelector((state: RootState) =>
        getTotalBets(state, hostId)
    );
    const [dragonPerValue, setDragonPerValue] = useState<number>(0);
    const [tigerPerValue, setTigerPerValue] = useState<number>(0);
    const [tiePerValue, setTiePerValue] = useState<number>(0);
    useEffect(() => {
        if (TotalBet) {
            const BetAmount = TotalBet as DragonTigerTotalBet;
            const dragonAmount = BetAmount.Dragon ? BetAmount.Dragon.Amount : 0;
            const tigerAmount = BetAmount.Tiger ? BetAmount.Tiger.Amount : 0;
            const tieAmount = BetAmount.Tie ? BetAmount.Tie.Amount : 0;
            const totalAmount = BetAmount.Total ? BetAmount.Total.Amount : 0;

            batch(() => {
                setDragonPerValue(
                    Math.round((dragonAmount / totalAmount) * 100) || 0
                );
                setTigerPerValue(
                    Math.round((tigerAmount / totalAmount) * 100) || 0
                );
                setTiePerValue(
                    Math.round((tieAmount / totalAmount) * 100) || 0
                );
            });
        }
    }, [TotalBet]);

    const doPreBet = (bets: Array<Bet>) => {
        batch(() => {
            bets.forEach(bet => {
                dispatch(
                    gameSliceActions.onPendingBet({
                        GameId: currentGameId,
                        Type: bet.Type,
                        Amount: bet.Amount,
                    })
                );
            });
        });
    };
    useEffect(() => {
        if (toAddBets) {
            const availableBets = checkBetsAvailed({
                hostId: hostId,
                bets: toAddBets,
                amountOverBetLimit: amountOverBetLimit,
                totalPendingBetAmount: allPendingBetsAmount,
                totalPendingWithHoldAmount: 0,
                availableBalance: availableBalance,
            });
            if (availableBets.isNotEnoughMoney) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'system.not_enough_money',
                        GameType,
                        StateMsgType.betInfo_Fail,
                        currentGameId
                    )
                );
            } else if (availableBets.isOutOfBetLimit) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'system.bet_out_of_limit_red',
                        GameType,
                        StateMsgType.betInfo_Fail,
                        currentGameId
                    )
                );
            } else {
                const toBets = availableBets.bets;
                const betTypes = toBets.map((bet: Bet) => ({
                    type: bet.Type,
                }));
                const lastBetType: number = betTypes.length - 1;
                doPreBet(toBets);

                if (availableBets.haveDisabledBetType) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.have_cannot_rebet_zone',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                } else if (availableBets.haveBetLimitAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.bet_exceed_limit_allin',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                } else if (availableBets.isAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.all_in',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                }
                dispatch(
                    gameSliceActions.setSelectedBetType(betTypes[lastBetType])
                );
            }
            setToAddBets(undefined);
            addingBet.current = false;
        }
    }, [toAddBets]);
    return (
        <>
            <Stack
                sx={{
                    marginTop: '318px',
                    width: '540px',
                    height: '347px',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        width: '540px',
                        height: '347px',
                        zIndex: -3,
                        background: `rgba(${tableColor})`,
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        width: '540px',
                        height: '347px',
                        marginTop: '-20px',
                        //[0,50,255]   23.5% = (50 / 255 * 100)
                        background: `linear-gradient(to bottom, rgba(${tableColor},0) 0%, rgba(${tableColor},1) 19.6%, rgba(${tableColor},1)) 100%`,
                    }}
                />
                {Group[0] === 'c' && (
                    <Box
                        sx={{
                            position: 'absolute',
                            width: '540px',
                            height: '347px',
                            marginTop: '-20px',
                            background: `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.32) 23.5%, rgba(0,0,0,0.8)) 100%`,
                        }}
                    />
                )}
                <Grid
                    item
                    container
                    flexWrap={'nowrap'}
                    justifyContent={'space-between'}
                    sx={{
                        marginTop: '20px',
                    }}
                >
                    <img
                        style={{
                            position: 'absolute',
                            margin: '0 5px 0 5px',
                            width: 530,
                            height: 274,
                        }}
                        src={betAreaDtBorder}
                    />
                    <Grid
                        item
                        container
                        direction={'row'}
                        sx={{
                            position: 'absolute',
                            height: '20px',
                            marginTop: '3.5px',
                        }}
                    >
                        <Typography
                            variant="caption"
                            component="div"
                            width="40px"
                            marginLeft={'175px'}
                            textAlign={'center'}
                            bgcolor={'#0004'}
                            borderRadius={'0 0 0 10px'}
                        >{`${dragonPerValue}%`}</Typography>
                        <Typography
                            variant="caption"
                            component="div"
                            width="40px"
                            marginLeft={'34px'}
                            textAlign={'center'}
                            bgcolor={'#0004'}
                            borderRadius={'0 0 10px 10px'}
                        >{`${tiePerValue}%`}</Typography>
                        <Typography
                            variant="caption"
                            component="div"
                            width="40px"
                            marginLeft={'32.5px'}
                            textAlign={'center'}
                            bgcolor={'#0004'}
                            borderRadius={'0 0 10px'}
                        >{`${tigerPerValue}%`}</Typography>
                    </Grid>
                    <BetArea
                        style={betType[BetType.DTBDragonWin].style}
                        confirmPanelStyle={
                            betType[BetType.DTBDragonWin].confirmPanelStyle
                        }
                        imageName={betType[BetType.DTBDragonWin].image}
                        imageScale={betType[BetType.DTBDragonWin].scale}
                        betType={BetType.DTBDragonWin}
                        amountOverBetLimit={amountOverBetLimit}
                        isOppositeBetting={isOppositeBetting}
                    />
                    <BetArea
                        style={betType[BetType.DTBTie].style}
                        confirmPanelStyle={
                            betType[BetType.DTBTie].confirmPanelStyle
                        }
                        imageName={betType[BetType.DTBTie].image}
                        imageScale={betType[BetType.DTBTie].scale}
                        betType={BetType.DTBTie}
                        amountOverBetLimit={amountOverBetLimit}
                    />
                    <BetArea
                        style={betType[BetType.DTBTigerWin].style}
                        confirmPanelStyle={
                            betType[BetType.DTBTigerWin].confirmPanelStyle
                        }
                        imageName={betType[BetType.DTBTigerWin].image}
                        imageScale={betType[BetType.DTBTigerWin].scale}
                        betType={BetType.DTBTigerWin}
                        amountOverBetLimit={amountOverBetLimit}
                        isOppositeBetting={isOppositeBetting}
                    />
                </Grid>
                <Grid
                    item
                    container
                    sx={{
                        paddingTop: '0px',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        marginTop: '-10px',
                        zIndex: 1,
                    }}
                >
                    <ChipSelector />
                    <ChipList />
                    {isShowReBet ? <ReBetButton /> : <DoubleBetButton />}
                </Grid>
            </Stack>
        </>
    );
};

export default DragonTigerBetAreaPanel;
