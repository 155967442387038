const ICON_WIDTH = 25;
const ICON_HEIGHT = 25;

export const BustIcon = () => (
    <svg
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="40" height="40" rx="20" fill="#E83323" />
        <path
            d="M13.0859 11.3203H19.9531C21.3125 11.3203 22.3242 11.3789 22.9883 11.4961C23.6602 11.6055 24.2578 11.8398 24.7812 12.1992C25.3125 12.5586 25.7539 13.0391 26.1055 13.6406C26.457 14.2344 26.6328 14.9023 26.6328 15.6445C26.6328 16.4492 26.4141 17.1875 25.9766 17.8594C25.5469 18.5312 24.9609 19.0352 24.2188 19.3711C25.2656 19.6758 26.0703 20.1953 26.6328 20.9297C27.1953 21.6641 27.4766 22.5273 27.4766 23.5195C27.4766 24.3008 27.293 25.0625 26.9258 25.8047C26.5664 26.5391 26.0703 27.1289 25.4375 27.5742C24.8125 28.0117 24.0391 28.2812 23.1172 28.3828C22.5391 28.4453 21.1445 28.4844 18.9336 28.5H13.0859V11.3203ZM16.5547 14.1797V18.1523H18.8281C20.1797 18.1523 21.0195 18.1328 21.3477 18.0938C21.9414 18.0234 22.4062 17.8203 22.7422 17.4844C23.0859 17.1406 23.2578 16.6914 23.2578 16.1367C23.2578 15.6055 23.1094 15.1758 22.8125 14.8477C22.5234 14.5117 22.0898 14.3086 21.5117 14.2383C21.168 14.1992 20.1797 14.1797 18.5469 14.1797H16.5547ZM16.5547 21.0117V25.6055H19.7656C21.0156 25.6055 21.8086 25.5703 22.1445 25.5C22.6602 25.4062 23.0781 25.1797 23.3984 24.8203C23.7266 24.4531 23.8906 23.9648 23.8906 23.3555C23.8906 22.8398 23.7656 22.4023 23.5156 22.043C23.2656 21.6836 22.9023 21.4219 22.4258 21.2578C21.957 21.0938 20.9336 21.0117 19.3555 21.0117H16.5547Z"
            fill="white"
        />
    </svg>
);
export const EmptyIcon = () => (
    <svg
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.3516 30.8594L21.7578 9.03906H25.6719L18.2422 30.8594H14.3516Z"
            fill="white"
        />
    </svg>
);
export const PushIcon = () => (
    <svg
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="40" height="40" rx="20" fill="#FFA902" />
        <path
            d="M13.7422 28.5V11.3203H19.3086C21.418 11.3203 22.793 11.4062 23.4336 11.5781C24.418 11.8359 25.2422 12.3984 25.9062 13.2656C26.5703 14.125 26.9023 15.2383 26.9023 16.6055C26.9023 17.6602 26.7109 18.5469 26.3281 19.2656C25.9453 19.9844 25.457 20.5508 24.8633 20.9648C24.2773 21.3711 23.6797 21.6406 23.0703 21.7734C22.2422 21.9375 21.043 22.0195 19.4727 22.0195H17.2109V28.5H13.7422ZM17.2109 14.2266V19.1016H19.1094C20.4766 19.1016 21.3906 19.0117 21.8516 18.832C22.3125 18.6523 22.6719 18.3711 22.9297 17.9883C23.1953 17.6055 23.3281 17.1602 23.3281 16.6523C23.3281 16.0273 23.1445 15.5117 22.7773 15.1055C22.4102 14.6992 21.9453 14.4453 21.3828 14.3438C20.9688 14.2656 20.1367 14.2266 18.8867 14.2266H17.2109Z"
            fill="black"
        />
    </svg>
);
export const BlackjackIcon = () => (
    <svg
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <rect width="40" height="40" rx="20" fill="#FEFF04" />
        <path
            d="M20.0001 8L19.8937 8.11083C15.5509 14.0353 10.9208 17.864 10.8782 17.9043C9.72867 18.9723 9.12196 20.4937 9.20711 22.0554C9.33484 24.4332 11.0059 26.5491 13.3689 27.3048C13.635 27.3955 13.9117 27.4559 14.1885 27.5063C16.115 27.8489 18.0735 27.2343 19.3827 25.8942C18.9038 29.5819 17.0517 31.3249 17.0411 31.3451L16.9559 31.4156L17.5733 32L17.6371 31.9698C17.6371 31.9698 18.5206 31.194 20.0001 31.2141C21.469 31.194 22.3418 31.9597 22.3631 31.9698L22.4269 32L23.0443 31.4156L22.9591 31.3451C22.9591 31.3451 21.0964 29.5819 20.6174 25.8942C21.9267 27.2343 23.8851 27.8489 25.8117 27.5063C26.0884 27.466 26.3652 27.3955 26.6313 27.3048C28.9836 26.5491 30.6547 24.4332 30.7931 22.0554C30.8676 20.4937 30.2609 18.9824 29.122 17.9043C29.0794 17.8539 24.4493 14.0252 20.1172 8.11083L20.0107 8H20.0001Z"
            fill="black"
            style={{ mixBlendMode: 'multiply' }}
        />
        <path
            d="M20.0001 8L19.8937 8.11083C15.5509 14.0353 10.9208 17.864 10.8782 17.9043C9.72867 18.9723 9.12196 20.4937 9.20711 22.0554C9.33484 24.4332 11.0059 26.5491 13.3689 27.3048C13.635 27.3955 13.9117 27.4559 14.1885 27.5063C16.115 27.8489 18.0735 27.2343 19.3827 25.8942C18.9038 29.5819 17.0517 31.3249 17.0411 31.3451L16.9559 31.4156L17.5733 32L17.6371 31.9698C17.6371 31.9698 18.5206 31.194 20.0001 31.2141C21.469 31.194 22.3418 31.9597 22.3631 31.9698L22.4269 32L23.0443 31.4156L22.9591 31.3451C22.9591 31.3451 21.0964 29.5819 20.6174 25.8942C21.9267 27.2343 23.8851 27.8489 25.8117 27.5063C26.0884 27.466 26.3652 27.3955 26.6313 27.3048C28.9836 26.5491 30.6547 24.4332 30.7931 22.0554C30.8676 20.4937 30.2609 18.9824 29.122 17.9043C29.0794 17.8539 24.4493 14.0252 20.1172 8.11083L20.0107 8H20.0001Z"
            stroke="#FFD800"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="12.7988"
            y="16.4"
            width="13.2"
            height="8.4"
            fill="url(#pattern0)"
        />
        <defs>
            <pattern
                id="pattern0"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1"
            >
                <use
                    xlinkHref="#image0_790_150365"
                    transform="matrix(0.0454545 0 0 0.0714286 0 -0.0357143)"
                />
            </pattern>
            <image
                id="image0_790_150365"
                width="22"
                height="15"
                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAPCAYAAADgbT9oAAAA+UlEQVQ4jaWU2xGCMBBFD4wN0AItYAlaAi1gCVgClgAlSAlQgvz7oy1Ygn7s4qwh8RF3JgOBzcndm4XkfsZGpaPQ+RU4AB3hyIBB1+zm3NQktDoK8yzXZ00AugFOzhosuAJKYAskwBq4mbxalbkbDnq/CAs+AKPOJ5276lB1F8SmXaASVnrtWfp4wx8TUpXdLAh21cFr6TPw60jfvLMH0iOl/w3OkcMEURr08lfw3F4T0ikhv38C14jaMRbqA5eI2s6BlkiLRYELpOn3LD2tiDy8HDgiLdYAd2dsYsCZQr2fpgmf1+6aZ++niH+Ln8gHcI1U0jo5c7XtA0CVNw8fUFF3AAAAAElFTkSuQmCC"
            />
        </defs>
    </svg>
);
export const WinIcon = () => (
    <svg
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="40" height="40" rx="20" fill="#3A8076" />
        <path
            d="M12.8516 28.5L8.75 11.3203H12.3008L14.8906 23.1211L18.0312 11.3203H22.1562L25.168 23.3203L27.8047 11.3203H31.2969L27.125 28.5H23.4453L20.0234 15.6562L16.6133 28.5H12.8516Z"
            fill="white"
        />
    </svg>
);
export const LoseIcon = () => (
    <svg
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="40" height="40" rx="20" fill="#285FBA" />
        <path
            d="M14.5039 28.5V11.4609H17.9727V25.6055H26.5977V28.5H14.5039Z"
            fill="white"
        />
    </svg>
);
