import BScroll from '@better-scroll/core';
import { BScrollConstructor } from '@better-scroll/core/dist/types/BScroll';
import Slide from '@better-scroll/slide';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import { motion, useAnimationControls } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AudioPlayContext } from '../../../../../contexts/AudioPlayContext';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import {
    TableInfoKeyType,
    useTableInfo,
} from '../../../../../hooks/useTableInfo';
import { GameType } from '../../../../../models/games/enums/GameType';
import { theme } from '../../../../../theme';
import { InGameRoadMap } from '../../../../lobby/components/Roadmaps';
import { REPORT_COLORS } from '../../../../lobby/constants';
import { SicBoRoadMap } from '../../../../roadmap/sicbo';
import './SicBoTableInfoPanel.scss';
import { SicBoTableInfoTab } from './SicBoTableInfoTab';
import { SicBoStatisticsButton } from './statistics/SicBoStatisticsButton';

const tabList = ['road_map', 'tableinfo.history', 'tableinfo.name'];
export const SicBoTableInfoPanel = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const { t } = useTranslation();
    const { setTableInfo } = useTableInfo();

    const [openStatistics, setOpenStatistics] = useState<boolean>(false);
    const handleOpenStatistics = (b: boolean) => {
        playButtonAudio();
        setOpenStatistics(b);
    };
    const [tableInfoTab, setTableInfoTab] = useState<number>(-1);
    const ref = useRef<HTMLDivElement>(null);
    const [scrollObj, setScrollObj] = useState<BScrollConstructor>();
    const controller = useAnimationControls();
    const animUp = () => {
        controller.start({
            y: [0, -189, -189],
        });
    };
    const animDown = () => {
        controller.start({
            y: [-189, 0, 0],
            zIndex: theme.zIndex.drawer - 400,
        });
    };
    const infoDown = () => {
        animDown();
        setTableInfoTab(-1);
    };
    const handleTab = (tab: number) => {
        playButtonAudio();
        if (tableInfoTab === -1) {
            animUp();
            handleTabScrollTo(tab, false);
            if (openStatistics) {
                setOpenStatistics(false);
            }
        } else {
            if (tab === tableInfoTab) {
                infoDown();
            } else {
                handleTabScrollTo(tab);
            }
        }
    };
    const handleTabScrollTo = (v: number, isSmooth: boolean = true) => {
        if (scrollObj) {
            if (v !== tableInfoTab) {
                setTableInfoTab(v);
                if (isSmooth) {
                    scrollObj.goToPage(v, 0);
                } else {
                    scrollObj.goToPage(v, 0, 0);
                }
            }
        }
    };
    useEffect(() => {
        if (ref && ref.current) {
            BScroll.use(Slide);
            const newBScroll = new BScroll(ref.current as HTMLDivElement, {
                scrollX: true,
                scrollY: false,
                click: true,
                slide: {
                    loop: false,
                    autoplay: false,
                    threshold: 100,
                },
                momentum: false,
            });
            newBScroll.on('slidePageChanged', (page: { pageX: number }) => {
                if (page) {
                    const tab = page.pageX;
                    setTableInfoTab(tab);
                    let gKey: TableInfoKeyType = 'roadmap';
                    if (tab === 2) {
                        gKey = 'tableInfo';
                    }
                    setTableInfo(gKey);
                }
            });
            setScrollObj(newBScroll);
        }
        return () => {
            if (ref && ref.current && scrollObj) {
                scrollObj.destroy();
            }
        };
    }, [ref]);
    return (
        <>
            <SicBoStatisticsButton
                openStatistics={openStatistics}
                handleOpenStatistics={handleOpenStatistics}
            />
            <motion.div
                animate={controller}
                initial={{
                    y: 0,
                    width: 540,
                    height: 54,
                    position: 'absolute',
                    zIndex: theme.zIndex.drawer - 400,
                }}
            >
                <Box
                    sx={{ height: 243, bgcolor: '#251c12' }}
                    className={'sicbo-table-info-container'}
                >
                    <Tabs
                        className="table-info-tabs"
                        value={tableInfoTab}
                        aria-label="table info."
                        sx={{
                            '& Button': { color: REPORT_COLORS.TITLE_TEXT },
                        }}
                    >
                        {/*fix bug Mui Tab value -1 */}
                        <Tab
                            value={-1}
                            sx={{
                                width: 0,
                                minWidth: 0,
                                padding: 0,
                                margin: 0,
                            }}
                        />
                        {tabList &&
                            tabList.map((n, idx) => (
                                <Tab
                                    key={`tab-${idx}}`}
                                    className={`table-info-tab${idx}`}
                                    label={t(n)}
                                    value={idx}
                                    icon={
                                        tableInfoTab === -1 ? (
                                            <KeyboardArrowUp />
                                        ) : (
                                            <KeyboardArrowDown
                                                sx={{
                                                    opacity:
                                                        tableInfoTab === idx
                                                            ? 1
                                                            : 0,
                                                }}
                                            />
                                        )
                                    }
                                    iconPosition="end"
                                    onClick={() => handleTab(idx)}
                                />
                            ))}
                    </Tabs>

                    <Box
                        ref={ref}
                        sx={{
                            overflow: 'hidden',
                        }}
                    >
                        <Box sx={{ display: 'inline-block' }}>
                            <Box
                                sx={{
                                    display: 'inline-block',
                                    position: 'relative',
                                    overflow: 'hidden',
                                }}
                            >
                                <InGameRoadMap
                                    hostId={hostId}
                                    gameType={GameType.SicBo}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'inline-block',
                                    position: 'relative',
                                    overflow: 'hidden',
                                }}
                            >
                                <>
                                    <Box className={'history-tab'}>
                                        <Stack
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            sx={{
                                                height: 29,
                                                bgcolor: '#6a573d',
                                                fontSize: 14,
                                            }}
                                        >
                                            {t('tableinfo.history_title')}
                                        </Stack>
                                        <SicBoRoadMap
                                            hostId={hostId}
                                            inGame={true}
                                        />
                                    </Box>
                                </>
                            </Box>
                            <Box
                                sx={{
                                    display: 'inline-block',
                                    position: 'relative',
                                    overflow: 'hidden',
                                }}
                            >
                                <SicBoTableInfoTab />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </motion.div>
        </>
    );
};
