import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import BetArea from '../../../../../../components/common/betArea';
import { amountOverBetLimit, isOppositeBetting } from '../utils';
import betAreaJson from './PopularBetArea.json';

const { betType: betTypeList } = betAreaJson;
export const PopularBetArea = () => {
    const [display, setDisplay] = useState(<></>);
    useEffect(() => {
        const dp = new Array<JSX.Element>();
        if (betTypeList) {
            const keys = Object.keys(betTypeList);
            for (const key of keys) {
                const betType = betTypeList[key as keyof typeof betTypeList];
                dp.push(
                    <Box
                        key={`popular-betarea-${key}`}
                        sx={{
                            position: 'absolute',
                            left: betType.xy[0],
                            top: betType.xy[1],
                        }}
                    >
                        <BetArea
                            style={betType.style}
                            confirmPanelStyle={betType.confirmPanelStyle}
                            isMultiLang={betType.isMultiLang}
                            imageName={betType.image}
                            betType={Number(key)}
                            amountOverBetLimit={amountOverBetLimit}
                            isOppositeBetting={isOppositeBetting}
                        />
                    </Box>
                );
            }
        }
        setDisplay(<>{dp}</>);
    }, [betTypeList]);
    return (
        <>
            <Grid item sx={{ width: 540, height: 473, overflow: 'hidden' }}>
                {display}
            </Grid>
        </>
    );
};
