import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import '../index.scss';
import { MultiBetSuperSixBetArea } from './MultiBetSuperSixBetArea';
import { MultiBetBaccaratBetArea } from './MultiBetBaccaratBetArea';
import { MultiBetResultPanel } from '../GameMessage/MultiBetResultPanel';
import { gameSliceActions } from '../../games/slice';
import { useDispatch, useSelector } from 'react-redux';
import { getHostById } from '../../host/slice';
import { RootState } from '../../../store/store';
import { BetChipContext } from '../../../contexts/BetChipContext';
import { useSession } from '../../../hooks/storage/useSession';

type Prop = {
    hostId: number;
};
export const MultiBetAreaPanel = ({ hostId }: Prop) => {
    const [tableInfoTab, setTableInfoTab] = useSession<number>(
        `tab_hostId_${hostId}`,
        0
    );
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [baccBetArea, setBaccBetArea] = useState<JSX.Element>(<></>);
    const [ssBetArea, setSSBetArea] = useState<JSX.Element>(<></>);
    const [currentBetArea, setCurrentBetArea] = useState<JSX.Element>(<></>);
    const { CurrentResult } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const { currentGameId } = useContext(BetChipContext);
    useEffect(() => {
        setBaccBetArea(<MultiBetBaccaratBetArea hostId={hostId} />);
        setSSBetArea(<MultiBetSuperSixBetArea hostId={hostId} />);
    }, [hostId]);
    useEffect(() => {
        switch (tableInfoTab) {
            case 0:
                setCurrentBetArea(baccBetArea);
                break;
            case 1:
                setCurrentBetArea(ssBetArea);
                break;
            default:
                setCurrentBetArea(<></>);
                break;
        }
        if (CurrentResult?.GameID === currentGameId) {
            dispatch(gameSliceActions.onCancelPendingBet());
        }
    }, [tableInfoTab, baccBetArea, ssBetArea]);

    return (
        <Stack>
            <Tabs
                className="multi-bet-baccarat-tabs"
                value={tableInfoTab}
                onChange={(_, v) => {
                    setTableInfoTab(v);
                }}
                aria-label="multibet baccarat & NC"
                sx={{
                    minHeight: '28px',
                }}
            >
                <Tab
                    className="multi-bet-baccarat-tab"
                    label={t('multibet.baccarat')}
                />
                <Tab
                    className="multi-bet-baccarat-tab"
                    label={t('multibet.no_commission')}
                />
            </Tabs>
            <Box
                sx={{
                    position: 'relative',
                }}
            >
                {currentBetArea}
                <MultiBetResultPanel hostId={hostId} />
            </Box>
        </Stack>
    );
};
