import { useEffect, useRef, useState } from 'react';

export const usePokerScroll = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const scrollerRef = useRef<HTMLDivElement>(null);
    const [isScrollStart, setIsScrollStart] = useState(false);
    const [isScrollEnd, setIsScrollEnd] = useState(false);
    const [isOverflow, setIsOverflow] = useState(false);
    const [cardChange, setCardChange] = useState(0);

    useEffect(() => {
        const scroller = scrollerRef.current;
        const containerElement = containerRef.current;

        if (scroller && containerElement) {
            const overflow =
                scroller.offsetWidth > containerElement.offsetWidth;

            setIsOverflow(overflow);
            setIsScrollStart(containerElement.scrollLeft === 0);
            setIsScrollEnd(
                containerElement.scrollLeft + containerElement.clientWidth ===
                    containerElement.scrollWidth
            );
        }
    }, [scrollerRef.current, containerRef.current, cardChange]);

    useEffect(() => {
        const containerElement = containerRef.current;
        if (containerElement) {
            const handleScroll = () => {
                setIsScrollStart(containerElement.scrollLeft === 0);
                setIsScrollEnd(
                    containerElement.scrollLeft +
                        containerElement.clientWidth ===
                        containerElement.scrollWidth
                );
            };
            containerElement.addEventListener('scroll', handleScroll);
            handleScroll(); // Check initial scroll position

            return () => {
                containerElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [containerRef.current]);

    const onLeftClick = () => {
        const containerElement = containerRef.current;
        if (containerElement) containerElement.scrollLeft = 0;
    };

    const onRightClick = () => {
        const containerElement = containerRef.current;
        if (containerElement)
            containerElement.scrollLeft = containerElement.clientWidth;
    };
    return {
        containerRef,
        scrollerRef,
        isScrollStart,
        isScrollEnd,
        isOverflow,
        onLeftClick,
        onRightClick,
        setCardChange,
    };
};
