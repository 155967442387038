import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BaseTypography } from '../../components/common/baseText/BaseTypography';

export const ThanksForPlaying = () => {
    const { t } = useTranslation();
    return (
        <>
            <Grid
                container
                className="ThanksForPlayingContainer"
                alignContent="center"
                justifyContent="center"
                justifyItems="center"
                sx={{
                    // backgroundImage: `url(./resource/assets/image/common/thanks_for_playing.jpg)`,
                    height: '100%',
                }}
            >
                <Grid
                    item
                    container
                    alignContent="center"
                    justifyContent="center"
                    justifyItems="center"
                    sx={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/resource/assets/image/common/thanks_for_playing_popup.png)`,
                        backgroundSize: '472px 277px',
                        width: '472px',
                        height: '277px',
                    }}
                >
                    <BaseTypography
                        fontSize={'36px'}
                        color={'#BF9A55'}
                        resize={{ direction: 'horizontal', value: '382px' }}
                    >
                        {t('system.thanks_for_playing')}
                    </BaseTypography>
                </Grid>
            </Grid>
        </>
    );
};

export default ThanksForPlaying;
