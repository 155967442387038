import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    PaperProps,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AudioPlayContext } from '../../contexts/AudioPlayContext';
import { WebSocketContext } from '../../contexts/WebSocketContext';
import { LocalStorageKey } from '../../hooks/storage/useLocalStorage';
import { POPUP_CONFIRM_ACTION, POPUP_TYPE } from '../../models/Popup';
import { ROUTE_THANKS4PLAY } from '../../models/Route';
import { gameSliceActions } from '../games/slice';
import { getMainLimitRedState } from '../main/selector';
import { limitRedSliceActions } from '../main/slice/limitRed';
import { routeSliceActions } from '../routeController/slice';
import { CancelButton } from '../setting/components/CancelButton';
import { TickButton } from '../setting/components/TickButton';
import { getPopupState } from './selector';
import { popupSliceActions } from './slice';
import { GameError } from '../../models/games/enums/GameError';

const GlassmorphismPaper = (props: PaperProps) => {
    const overrideProps = {
        ...props,
        ...{ sx: { margin: '2px !important' } },
    };
    // console.log(
    //     'GlassmorphismPaper',
    //     overrideProps.className,
    //     overrideProps.sx
    // );
    return (
        <Paper
            sx={{
                backgroundColor: 'unset',
                backdropFilter: 'contrast(90%) brightness(300%) blur(1px)',
                marginX: theme => theme.spacing(6),
            }}
        >
            <Paper {...overrideProps} />
        </Paper>
    );
};

export const Popup = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { disconnect } = useContext(WebSocketContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const { ChangePopup } = useSelector(getMainLimitRedState);
    const popupData = useSelector(getPopupState);
    const {
        isOpen,
        key,
        type,
        dismiss,
        confirmKey,
        leaveFromConfirm,
        clearLoginDataWhenLeave,
        params,
    } = popupData;
    const [message, setMessage] = useState<string>('');
    const timer = useRef<NodeJS.Timeout | null>();
    const [languageDir, setLanguageDir] = useState<string>('ltr');
    useEffect(() => {
        setLanguageDir(i18n.dir(i18n.language));
    }, [i18n.language]);
    const handleConfirm = () => {
        playButtonAudio();
        if (leaveFromConfirm) {
            dispatch(routeSliceActions.goto(ROUTE_THANKS4PLAY));
            disconnect(true);
            if (!window.localStorage.getItem(LocalStorageKey.ClearReason)) {
                window.localStorage.setItem(
                    LocalStorageKey.ClearReason,
                    GameError.LOGOUTTED.toString()
                );
            }
        } else if (confirmKey && confirmKey > POPUP_CONFIRM_ACTION.NONE) {
            //TODO: popupSliceActions function?
            //string -> function
            //const func = eval('const func = ' + confirmFunc);
            // const func = new Function('return ' + confirmFunc)();
            // if (func && typeof func === 'function') {
            //     func();
            // }
            dispatch(popupSliceActions.triggerConfirm(confirmKey));
        }
        dispatch(popupSliceActions.close());
    };
    const handleCancel = () => {
        playButtonAudio();
        dispatch(popupSliceActions.close());
    };
    useEffect(() => {
        if (ChangePopup) {
            dispatch(popupSliceActions.open('popup.server_limit_changed'));
            dispatch(limitRedSliceActions.clearPopup());
        }
    }, [ChangePopup]);
    useEffect(() => {
        if (key) {
            let msg: string[] = [];
            if (typeof key === 'string') {
                msg.push(key);
            } else {
                msg = key;
            }
            const transMsg = msg.map(m => {
                if (params) {
                    return t(m, params);
                } else {
                    return t(m);
                }
            });
            setMessage(transMsg.join(' '));
        }
    }, [key, i18n.language]);
    useEffect(() => {
        if (isOpen) {
            if (leaveFromConfirm && type != POPUP_TYPE.CONFIRM) {
                if (clearLoginDataWhenLeave) {
                    // dispatch(routeSliceActions.goto(ROUTE_THANKS4PLAY));
                    // disconnect();
                    window.localStorage.removeItem(
                        LocalStorageKey.FullParameter
                    );
                    window.localStorage.removeItem(
                        LocalStorageKey.ReconnectKey
                    );
                }
                dispatch(
                    gameSliceActions.setNetworkState({
                        notToReconnect: true,
                    })
                );
            }
        }
        if (dismiss && isOpen) {
            timer.current = setTimeout(() => {
                dispatch(popupSliceActions.close());
            }, dismiss);
        } else {
            if (timer.current) clearTimeout(timer.current);
        }
        return () => {
            if (timer.current) clearTimeout(timer.current);
        };
    }, [isOpen, dismiss]);
    return message ? (
        <Dialog
            open={isOpen}
            className={`PopupDialog`}
            disableEscapeKeyDown
            PaperComponent={GlassmorphismPaper}
        >
            <Box
                sx={{
                    backdropFilter: 'contrast(130%) brightness(120%) blur(1px)',
                    minHeight: 96,
                }}
            >
                <DialogTitle></DialogTitle>
                <DialogContent
                    sx={{
                        paddingBottom: 0,
                    }}
                >
                    <DialogContentText
                        textAlign={'center'}
                        sx={{
                            fontSize: '1.3rem',
                            color: theme => theme.palette.text.primary,
                            direction: languageDir,
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {message}
                    </DialogContentText>
                </DialogContent>

                {type !== POPUP_TYPE.POPUP && (
                    <>
                        <DialogActions sx={{ justifyContent: 'center' }}>
                            {type === POPUP_TYPE.CONFIRM && (
                                <>
                                    <CancelButton
                                        scale={0.8}
                                        onClick={handleCancel}
                                    />
                                </>
                            )}
                            <TickButton scale={0.8} onClick={handleConfirm} />
                        </DialogActions>
                    </>
                )}
            </Box>
        </Dialog>
    ) : (
        <></>
    );
};
