import { memo } from 'react';
import { ROAD_MAP_TYPE } from '../../../lobby/constants';
import {
    CommonCellProps,
    SedieRoadMapCellProps,
    useSedieRoadMapCellColorText,
} from '../hook';
const EvenOddRoadCell = (props: SedieRoadMapCellProps & CommonCellProps) => {
    const {
        record: { Win, WinPoint },
        size,
    } = props;
    const { color, text } = useSedieRoadMapCellColorText(
        ROAD_MAP_TYPE.EVEN_ODD_ROAD,
        WinPoint
    );
    return (
        <svg width={size} height={size}>
            {Win >= 0 && (
                <>
                    <circle
                        cx={size / 2}
                        cy={size / 2}
                        r={size / 2}
                        fill={color}
                    />
                    <text
                        x={size / 2}
                        y={size / 2 + 2}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill="#ffffff"
                        fontSize={size * 0.75}
                    >
                        {text}
                    </text>
                </>
            )}
        </svg>
    );
};
const areEquals = (
    prev: SedieRoadMapCellProps,
    next: SedieRoadMapCellProps
) => {
    const isSameWin = prev.record.Win === next.record.Win;
    const isSamePoint = prev.record.WinPoint === next.record.WinPoint;
    const isSameSize = prev.size === next.size;
    return isSameWin && isSamePoint && isSameSize;
};
export default memo(EvenOddRoadCell, areEquals);
