import { useContext, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { BetChipContext } from '../../../../../../../contexts/BetChipContext';
import { VideoPlayerContext } from '../../../../../../../contexts/VideoContext';
import { useBalance } from '../../../../../../../hooks/useBalance';
import { IsMobile } from '../../../../../../../hooks/useScreenRatio';
import { useUserAgent } from '../../../../../../../hooks/useUserAgent';
import { StateMsgType } from '../../../../../../../models/Popup';
import { GameState } from '../../../../../../../models/games/enums/GameState';
import { RootState } from '../../../../../../../store/store';
import {
    getCurrentGameId,
    getTotalPendingBetsAmount,
    getWaitingBetResult,
} from '../../../../../../games/selector';
import { gameSliceActions } from '../../../../../../games/slice';
import { getHostById } from '../../../../../../host/slice';
import { inGamePopupSliceActions } from '../../../../../../popup/inGameSlice';
import { amountOverBetLimit } from '../../utils';

// const fillVal: Array<string> = ['#F40906', '#000000', '#009003', '#FFFFFF'];
const fillVal = '#FFFFFF';

export const TapAreaContainer = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { selectedBetAmount, selectedBetRule } = useContext(BetChipContext);
    const { IsRest, CurrentState, ResultReleased, GameType, MaxBet } =
        useSelector((state: RootState) => getHostById(state, hostId));
    const currentGameId = useSelector((state: RootState) =>
        getCurrentGameId(state)
    );
    const allPendingBetsAmount = useSelector((state: RootState) =>
        getTotalPendingBetsAmount(state)
    );
    const isWaitingBetResult = useSelector((state: RootState) =>
        getWaitingBetResult(state)
    );
    const dispatch = useDispatch();

    const [isBetting, setIsBetting] = useState<boolean>(false);

    const { availableBalance } = useBalance();

    const [onOverBetTypes, setOnOverBetTypes] = useState<Array<number>>([]);
    const [selectedName, setSelectedName] = useState<string>('');

    const { device } = useUserAgent();

    useEffect(() => {
        if (IsRest) {
            setIsBetting(false);
            resetSelectedTypes();
        }
        setIsBetting(CurrentState === GameState.Betting);

        if (CurrentState !== GameState.Betting) {
            resetSelectedTypes();
        }
    }, [IsRest, CurrentState, ResultReleased]);

    const resetSelectedTypes = () => {
        setSelectedName('');
        setOnOverBetTypes([]);
    };

    const onTap = (name: string | null) => {
        // TODO: DRY
        // if (selectedName.length == 0) {
        //     resetSelectedTypes();
        //     return;
        // }

        if (!selectedBetRule) {
            // dispatch(
            //     inGamePopupSliceActions.open(
            //         'system.error_m226.17',
            //         GameType,
            //         StateMsgType.betInfo_Fail
            //     )
            // );
            resetSelectedTypes();
            return;
        }

        if (!isBetting || !MaxBet) {
            resetSelectedTypes();
            return;
        }

        if (isWaitingBetResult) return;

        const betTypeVal = name ? name.split('_')[0] : '';
        const containNumVal = name ? name.split('_')[1] : '';
        // console.log('roulette::tap', betTypeVal, containNumVal);

        let amount: number = selectedBetAmount;
        let pendingBetAmount: number = 0;
        let isAllIn: boolean = false;
        let haveBetLimitAllIn = false;

        const betTypes =
            Number(betTypeVal) >= 1000
                ? containNumVal.split(',')
                : [Number(betTypeVal)];

        betTypes.forEach(betType => {
            // check over bet limit
            const overLimit = amountOverBetLimit(
                Number(betType),
                hostId,
                amount
            );
            if (overLimit > 0) {
                if (amount <= overLimit) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.bet_out_of_limit_red',
                            GameType,
                            StateMsgType.betInfo_Fail,
                            currentGameId
                        )
                    );
                    resetSelectedTypes();
                    return;
                } else {
                    amount -= overLimit;
                    haveBetLimitAllIn = true;
                }
            }

            pendingBetAmount = amount;
            if (
                allPendingBetsAmount + pendingBetAmount >
                availableBalance * 100
            ) {
                pendingBetAmount =
                    Math.floor(availableBalance) * 100 - allPendingBetsAmount;
                if (pendingBetAmount <= 0) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.not_enough_money',
                            GameType,
                            StateMsgType.betInfo_Fail,
                            currentGameId
                        )
                    );
                    resetSelectedTypes();
                    return;
                }
                isAllIn = true;
            }
            batch(() => {
                if (haveBetLimitAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.bet_exceed_limit_allin',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                } else if (isAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.all_in',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                }

                dispatch(
                    gameSliceActions.onPendingBet({
                        GameId: currentGameId,
                        Type: Number(betType),
                        Amount: pendingBetAmount,
                    })
                );
            });
        });

        dispatch(
            gameSliceActions.onPendingBetHistory([
                {
                    GameId: currentGameId,
                    Type: Number(betTypeVal),
                    Amount: pendingBetAmount,
                    ContainNum: containNumVal.split(',').map(n => Number(n)),
                },
            ])
        );
        resetSelectedTypes();
    };

    const onCancel = () => {
        if (selectedName.length > 0) {
            onTap(selectedName);
        }
    };

    const onMouseOver = (event: React.MouseEvent) => {
        if (IsMobile(device)) {
            return;
        }

        if (!isBetting || isWaitingBetResult) {
            return;
        }

        const target = document.elementFromPoint(event.clientX, event.clientY);

        if (target && target.hasAttribute('name')) {
            const name = target.getAttribute('name');
            updateSelectedName(name);
        }
    };

    const onMouseEnd = () => {
        if (IsMobile(device)) {
            return;
        }

        if (selectedName.length > 0) {
            onTap(selectedName);
        }
    };

    const onEnd = () => {
        if (selectedName.length > 0) {
            onTap(selectedName);
        }
    };

    // const onClick = (event: React.MouseEvent) => {
    //     if (selectedName.length === 0) {
    //         if (!selectedBetRule) {
    //             dispatch(
    //                 inGamePopupSliceActions.open(
    //                     'system.error_m226.17',
    //                     GameType,
    //                     StateMsgType.betInfo_Fail
    //                 )
    //             );
    //             return;
    //         }

    //         if (!isBetting) {
    //             return;
    //         }

    //         const target = event.target as SVGElement;
    //         const name = target.getAttribute('name');

    //         updateSelectedName(name);
    //         onTap(name);
    //     }
    // };

    const onOver = (event: React.TouchEvent) => {
        if (!isBetting || isWaitingBetResult) {
            return;
        }

        const touch = event.touches[0];
        const target = document.elementFromPoint(touch.clientX, touch.clientY);

        if (target && target.hasAttribute('name')) {
            const name = target.getAttribute('name');
            updateSelectedName(name);
        }
    };

    const updateSelectedName = (name: string | null) => {
        if (name) {
            const betTypeVal = name ? name.split('_')[0] : '';
            const containNumVal = name ? name.split('_')[1] : '';

            if (betTypeVal.length > 0) {
                // const betTypes = [
                //     Number(betTypeVal),
                //     ...containNumVal.split(',').map(n => Number(n)),
                // ].filter((value, index, self) => {
                //     return self.indexOf(value) === index;
                // });

                const betTypes = [
                    Number(betTypeVal),
                    ...containNumVal.split(',').map(n => Number(n)),
                ];
                setOnOverBetTypes(betTypes);
                setSelectedName(name);
                // console.log('onOver', betTypes);
            }
        } else {
            setOnOverBetTypes([]);
            setSelectedName('');
        }
    };

    const getOpacity = (ownType: Array<number>): number => {
        if (!isBetting) {
            return 0;
        }

        // TODO: better way to handle following bet types
        // workaround other
        const otherTypes: Array<number> = [1500, 1501, 1502, 1503];
        let otherContainNum: Array<number> = [];
        if (otherTypes.find(otherType => otherType === onOverBetTypes[0])) {
            switch (onOverBetTypes[0]) {
                case 1503:
                    otherContainNum = [
                        5, 8, 10, 11, 13, 16, 23, 24, 27, 30, 33, 36,
                    ];
                    break;
                case 1502:
                    otherContainNum = [1, 6, 9, 14, 17, 20, 31, 34];
                    break;
                case 1501:
                    otherContainNum = [
                        0, 2, 3, 4, 7, 12, 15, 18, 21, 19, 22, 25, 26, 28, 29,
                        32, 35,
                    ];
                    break;
                case 1500:
                    otherContainNum = [0, 3, 12, 15, 26, 32, 35];
                    break;
            }

            for (let i = 0; i < ownType.length; i++) {
                if (
                    otherContainNum.find(overType => overType === ownType[i]) !=
                    undefined
                ) {
                    return 0.5;
                }
            }
        }

        const convertBetTypeBase: number =
            onOverBetTypes[0] >= 1000 && onOverBetTypes[0] < 1500 ? 1000 : 0;

        for (let i = 0; i < ownType.length; i++) {
            if (
                onOverBetTypes.find(
                    overType => overType + convertBetTypeBase === ownType[i]
                ) != undefined
            ) {
                return 0.5;
            }
        }

        return 0;
    };
    return (
        <>
            <svg
                width={330}
                height={688}
                viewBox="0 0 330 688"
                fill="none"
                // onClick={onClick}
                onMouseDown={onMouseOver}
                onMouseMove={onMouseOver}
                onMouseUp={onMouseEnd}
                onTouchStart={onOver}
                onTouchMove={onOver}
                onTouchEnd={onEnd}
                onTouchCancel={onCancel}
            >
                {/* one number */}
                <path
                    name="0_0"
                    width={92.58}
                    height={36.23}
                    d="M199.454 29.1358L101.237 2.3324L3.01904 29.1358V75.9164H199.454V29.1358Z"
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    fill={fillVal}
                    fillOpacity={getOpacity([0])}
                    transform="scale(0.455, 0.46) translate(168,236)"
                />
                {/* 1 to 36 */}
                <rect
                    key={'tap-2-1'}
                    name={'1_1'}
                    width={28}
                    height={33}
                    x={78 + 30 * (0 % 3)}
                    y={144 + 33.7 * Math.floor(0 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([1])}
                />
                <rect
                    key={'tap-2-2'}
                    name={'2_2'}
                    width={28}
                    height={33}
                    x={78 + 30 * (1 % 3)}
                    y={144 + 33.7 * Math.floor(1 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([2])}
                />
                <rect
                    key={'tap-2-3'}
                    name={'3_3'}
                    width={28}
                    height={33}
                    x={78 + 30 * (2 % 3)}
                    y={144 + 33.7 * Math.floor(2 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([3])}
                />
                <rect
                    key={'tap-2-4'}
                    name={'4_4'}
                    width={28}
                    height={33}
                    x={78 + 30 * (3 % 3)}
                    y={144 + 33.7 * Math.floor(3 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([4])}
                />
                <rect
                    key={'tap-2-5'}
                    name={'5_5'}
                    width={28}
                    height={33}
                    x={78 + 30 * (4 % 3)}
                    y={144 + 33.7 * Math.floor(4 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([5])}
                />
                <rect
                    key={'tap-2-6'}
                    name={'6_6'}
                    width={28}
                    height={33}
                    x={78 + 30 * (5 % 3)}
                    y={144 + 33.7 * Math.floor(5 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([6])}
                />
                <rect
                    key={'tap-2-7'}
                    name={'7_7'}
                    width={28}
                    height={33}
                    x={78 + 30 * (6 % 3)}
                    y={144 + 33.7 * Math.floor(6 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([7])}
                />
                <rect
                    key={'tap-2-8'}
                    name={'8_8'}
                    width={28}
                    height={33}
                    x={78 + 30 * (7 % 3)}
                    y={144 + 33.7 * Math.floor(7 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([8])}
                />
                <rect
                    key={'tap-2-9'}
                    name={'9_9'}
                    width={28}
                    height={33}
                    x={78 + 30 * (8 % 3)}
                    y={144 + 33.7 * Math.floor(8 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([9])}
                />
                <rect
                    key={'tap-2-10'}
                    name={'10_10'}
                    width={28}
                    height={33}
                    x={78 + 30 * (9 % 3)}
                    y={144 + 33.7 * Math.floor(9 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([10])}
                />
                <rect
                    key={'tap-2-11'}
                    name={'11_11'}
                    width={28}
                    height={33}
                    x={78 + 30 * (10 % 3)}
                    y={144 + 33.7 * Math.floor(10 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([11])}
                />
                <rect
                    key={'tap-2-12'}
                    name={'12_12'}
                    width={28}
                    height={33}
                    x={78 + 30 * (11 % 3)}
                    y={144 + 33.7 * Math.floor(11 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([12])}
                />
                <rect
                    key={'tap-2-13'}
                    name={'13_13'}
                    width={28}
                    height={33}
                    x={78 + 30 * (12 % 3)}
                    y={144 + 33.7 * Math.floor(12 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([13])}
                />
                <rect
                    key={'tap-2-14'}
                    name={'14_14'}
                    width={28}
                    height={33}
                    x={78 + 30 * (13 % 3)}
                    y={144 + 33.7 * Math.floor(13 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([14])}
                />
                <rect
                    key={'tap-2-15'}
                    name={'15_15'}
                    width={28}
                    height={33}
                    x={78 + 30 * (14 % 3)}
                    y={144 + 33.7 * Math.floor(14 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([15])}
                />
                <rect
                    key={'tap-2-16'}
                    name={'16_16'}
                    width={28}
                    height={33}
                    x={78 + 30 * (15 % 3)}
                    y={144 + 33.7 * Math.floor(15 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([16])}
                />
                <rect
                    key={'tap-2-17'}
                    name={'17_17'}
                    width={28}
                    height={33}
                    x={78 + 30 * (16 % 3)}
                    y={144 + 33.7 * Math.floor(16 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([17])}
                />
                <rect
                    key={'tap-2-18'}
                    name={'18_18'}
                    width={28}
                    height={33}
                    x={78 + 30 * (17 % 3)}
                    y={144 + 33.7 * Math.floor(17 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([18])}
                />
                <rect
                    key={'tap-2-19'}
                    name={'19_19'}
                    width={28}
                    height={33}
                    x={78 + 30 * (18 % 3)}
                    y={144 + 33.7 * Math.floor(18 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([19])}
                />
                <rect
                    key={'tap-2-20'}
                    name={'20_20'}
                    width={28}
                    height={33}
                    x={78 + 30 * (19 % 3)}
                    y={144 + 33.7 * Math.floor(19 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([20])}
                />
                <rect
                    key={'tap-2-21'}
                    name={'21_21'}
                    width={28}
                    height={33}
                    x={78 + 30 * (20 % 3)}
                    y={144 + 33.7 * Math.floor(20 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([21])}
                />
                <rect
                    key={'tap-2-22'}
                    name={'22_22'}
                    width={28}
                    height={33}
                    x={78 + 30 * (21 % 3)}
                    y={144 + 33.7 * Math.floor(21 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([22])}
                />
                <rect
                    key={'tap-2-23'}
                    name={'23_23'}
                    width={28}
                    height={33}
                    x={78 + 30 * (22 % 3)}
                    y={144 + 33.7 * Math.floor(22 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([23])}
                />
                <rect
                    key={'tap-2-24'}
                    name={'24_24'}
                    width={28}
                    height={33}
                    x={78 + 30 * (23 % 3)}
                    y={144 + 33.7 * Math.floor(23 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([24])}
                />
                <rect
                    key={'tap-2-25'}
                    name={'25_25'}
                    width={28}
                    height={33}
                    x={78 + 30 * (24 % 3)}
                    y={144 + 33.7 * Math.floor(24 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([25])}
                />
                <rect
                    key={'tap-2-26'}
                    name={'26_26'}
                    width={28}
                    height={33}
                    x={78 + 30 * (25 % 3)}
                    y={144 + 33.7 * Math.floor(25 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([26])}
                />
                <rect
                    key={'tap-2-27'}
                    name={'27_27'}
                    width={28}
                    height={33}
                    x={78 + 30 * (26 % 3)}
                    y={144 + 33.7 * Math.floor(26 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([27])}
                />
                <rect
                    key={'tap-2-28'}
                    name={'28_28'}
                    width={28}
                    height={33}
                    x={78 + 30 * (27 % 3)}
                    y={144 + 33.7 * Math.floor(27 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([28])}
                />
                <rect
                    key={'tap-2-29'}
                    name={'29_29'}
                    width={28}
                    height={33}
                    x={78 + 30 * (28 % 3)}
                    y={144 + 33.7 * Math.floor(28 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([29])}
                />
                <rect
                    key={'tap-2-30'}
                    name={'30_30'}
                    width={28}
                    height={33}
                    x={78 + 30 * (29 % 3)}
                    y={144 + 33.7 * Math.floor(29 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([30])}
                />
                <rect
                    key={'tap-2-31'}
                    name={'31_31'}
                    width={28}
                    height={33}
                    x={78 + 30 * (30 % 3)}
                    y={144 + 33.7 * Math.floor(30 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([31])}
                />
                <rect
                    key={'tap-2-32'}
                    name={'32_32'}
                    width={28}
                    height={33}
                    x={78 + 30 * (31 % 3)}
                    y={144 + 33.7 * Math.floor(31 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([32])}
                />
                <rect
                    key={'tap-2-33'}
                    name={'33_33'}
                    width={28}
                    height={33}
                    x={78 + 30 * (32 % 3)}
                    y={144 + 33.7 * Math.floor(32 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([33])}
                />
                <rect
                    key={'tap-2-34'}
                    name={'34_34'}
                    width={28}
                    height={33}
                    x={78 + 30 * (33 % 3)}
                    y={144 + 33.7 * Math.floor(33 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([34])}
                />
                <rect
                    key={'tap-2-35'}
                    name={'35_35'}
                    width={28}
                    height={33}
                    x={78 + 30 * (34 % 3)}
                    y={144 + 33.7 * Math.floor(34 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([35])}
                />
                <rect
                    key={'tap-2-36'}
                    name={'36_36'}
                    width={28}
                    height={33}
                    x={78 + 30 * (35 % 3)}
                    y={144 + 33.7 * Math.floor(35 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([36])}
                />
                {/* other */}
                <path
                    name="1503_48,55,61,77,84,94"
                    width={187}
                    height={70.5}
                    fill={fillVal}
                    fillOpacity={getOpacity([1503])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M141.818 0C63.4942 0 0 63.4945 0 141.818V394.677L429.818 205.636V141.818C429.818 63.4942 366.324 0 288 0H141.818Z"
                    transform="scale(0.455, 0.46) translate(154,148)"
                />
                <path
                    name="1502_1,63,68,49,91"
                    width={187}
                    height={70.5}
                    fill={fillVal}
                    fillOpacity={getOpacity([1502])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M0 467.203V189.444L429.818 0.40332V467.203H0Z"
                    transform="scale(0.455, 0.46) translate(154,354)"
                />
                <path
                    name="1501_46,59,69,71,93,98,98,127,127"
                    width={187}
                    height={70.5}
                    fill={fillVal}
                    fillOpacity={getOpacity([1501])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M429.818 0.566345H0V387C0 406.005 3.73813 424.136 10.5192 440.699L82.6509 392.672C100.918 380.51 122.374 374.02 144.32 374.02H293.017C312.707 374.02 332.045 379.245 349.055 389.161L422.532 431.993C427.258 417.856 429.818 402.727 429.818 387V0.566345Z"
                    transform="scale(0.455, 0.46) translate(154,826)"
                />
                <path
                    name="1500_26,39,59,93"
                    width={187}
                    height={70.5}
                    fill={fillVal}
                    fillOpacity={getOpacity([1500])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M409.057 58.1843L334.858 14.9312C318.514 5.40381 299.935 0.383911 281.017 0.383911H132.32C111.235 0.383911 90.6202 6.6189 73.0694 18.3044L0.265137 66.7788C22.3861 116.304 72.0715 150.818 129.818 150.818H276C337.032 150.818 389.059 112.265 409.057 58.1843Z"
                    transform="scale(0.455, 0.46) translate(164,1200)"
                />
                {/* five number */}
                <path
                    name="1000_15,32,0,26,3"
                    width={103}
                    height={129}
                    fill={fillVal}
                    fillOpacity={getOpacity([1000])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M95.7506 127.152L100.317 6.44526H68.9566C59.0767 6.44526 49.3797 5.26823 40.0205 2.99377L2.79816 117.931C23.8269 123.865 46.0182 127.042 68.9566 127.042L95.7506 127.152Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(178.8,1144.99)"
                />
                <path
                    name="1003_12,35,3,26,0"
                    width={96}
                    height={134}
                    fill={fillVal}
                    fillOpacity={getOpacity([1003])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M16.0479 11.445H2.31247L6.93597 132.042H16.0479C44.8786 132.042 72.5275 127.014 98.1735 117.808L60.9608 2.90308C46.8633 8.50481 31.6832 11.445 16.0479 11.445Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(352.31,1139.9)"
                />
                <path
                    name="1004_2,21,4,19,15"
                    width={126}
                    height={85}
                    fill={fillVal}
                    fillOpacity={getOpacity([1004])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.77301 82.5355H123.865V2.60498H2.77301V82.5355Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(2.77,939.6)"
                />
                <path
                    name="1005_23,10,5,24,16"
                    width={124}
                    height={138}
                    fill={fillVal}
                    fillOpacity={getOpacity([1005])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M56.1011 134.988L120.959 32.8167C90.4657 15.6666 55.871 4.91056 19.001 2.37079L2.66211 122.378C21.5173 122.471 39.7004 126.825 56.1011 134.988Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(366.66,3.37)"
                />
                <path
                    name="1008_10,23,8,30,11"
                    width={116}
                    height={147}
                    fill={fillVal}
                    fillOpacity={getOpacity([1008])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M113.878 122.164L97.5798 2.45892C62.7599 8.57802 30.5157 22.1162 2.52896 41.3599L67.3336 143.445C81.2046 133.049 97.0216 125.83 113.878 122.164Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(107.53,6.46)"
                />
                <path
                    name="1023_5,10,23,8,30"
                    width={95}
                    height={129}
                    fill={fillVal}
                    fillOpacity={getOpacity([1023])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M44.9562 123.37H92.5804V2.77319H44.9562C30.4991 2.77319 16.3399 4.04067 2.58022 6.45864L18.8779 126.163C27.3483 124.322 36.0778 123.37 44.9562 123.37Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(202.58,2.77)"
                />
                <path
                    name="1024_10,5,24,16,33"
                    width={152}
                    height={145}
                    fill={fillVal}
                    fillOpacity={getOpacity([1024])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M34.6726 127.692C39.2656 132.266 43.4372 137.149 47.1838 142.284L149.039 76.9184C128.109 46.4628 100.352 21.0361 67.9595 2.8175L3.1004 104.987C14.6356 110.728 25.2892 118.347 34.6726 127.692Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(420.1,33.82)"
                />
                <path
                    name="1026_35,3,26,0,32"
                    width={90}
                    height={125}
                    fill={fillVal}
                    fillOpacity={getOpacity([1026])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.69308 123.042H87.936L83.3125 2.44531H7.31658L2.69308 123.042Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(271.69,1148.45)"
                />
                <path
                    name="1030_23,8,30,11,36"
                    width={141}
                    height={146}
                    fill={fillVal}
                    fillOpacity={getOpacity([1030])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M125.331 116.692C129.433 112.608 133.78 108.858 138.333 105.445L73.5296 3.35986C45.1869 22.8496 21.218 48.1906 3.39331 77.644L105.26 143.018C110.638 133.489 117.358 124.633 125.331 116.692Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(37.39,45.36)"
                />
                <path
                    name="1032_19,15,32,0,26"
                    width={138}
                    height={146}
                    fill={fillVal}
                    fillOpacity={getOpacity([1032])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M85.4223 2.64734L3.06305 91.1029C29.9551 114.928 62.2042 132.886 97.7979 142.931L135.02 27.9944C116.807 23.5661 99.8819 14.9567 85.4223 2.64734Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(84.06,1119.65)"
                />
                <path
                    name="1035_28,12,35,3,26"
                    width={131}
                    height={150}
                    fill={fillVal}
                    fillOpacity={getOpacity([1035])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M43.6727 5.12353C31.8578 16.8902 18.0267 25.9181 2.96048 31.9045L40.1732 146.808C73.6005 134.809 103.612 115.697 128.37 91.2998L45.9553 2.78516C45.2057 3.57265 44.4465 4.35292 43.6727 5.12353Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(410.96,1110.79)"
                />
                <path
                    name="1001_16,33,1,20,14"
                    width={126}
                    height={84}
                    fill={fillVal}
                    fillOpacity={getOpacity([1001])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.13956 82.0935H123.231V2.16422H2.13956V82.0935Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(487.14,300.16)"
                />
                <path
                    name="1011_8,30,11,36,13"
                    width={140}
                    height={117}
                    fill={fillVal}
                    fillOpacity={getOpacity([1011])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M137.26 68.018L35.3932 2.64471C18.4949 30.5653 7.11227 62.1702 2.75781 95.9675L122.5 114.543C124.184 98.049 129.214 82.2774 137.26 68.018Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(4.76,119.64)"
                />
                <path
                    name="1036_30,11,36,13,27"
                    width={127}
                    height={93}
                    fill={fillVal}
                    fillOpacity={getOpacity([1036])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M123.865 33.9673C123.865 29.7886 124.081 25.6449 124.5 21.5434L4.7577 2.96664C3.45112 13.1173 2.77301 23.4633 2.77301 33.9673V90.1643H123.865V33.9673Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(2.77,212.97)"
                />
                <path
                    name="1016_5,24,16,33,1"
                    width={147}
                    height={125}
                    fill={fillVal}
                    fillOpacity={getOpacity([1016])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M25.0327 122.607L144.694 104.044C139.288 66.9239 125.398 32.5453 105.038 2.91821L3.18414 68.2842C14.8913 84.3357 22.3651 102.902 25.0327 122.607Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(464.18,107.92)"
                />
                <path
                    name="1033_24,16,33,1,20"
                    width={128}
                    height={97}
                    fill={fillVal}
                    fillOpacity={getOpacity([1033])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M4.13965 37.9672V94.1642H125.231V37.9672C125.231 26.1053 124.356 14.4472 122.694 3.04352L3.03287 21.607C3.76063 26.9857 4.13965 32.4475 4.13965 37.9672Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(486.03,209.04)"
                />
                <path
                    name="1012_7,28,12,35,3"
                    width={144}
                    height={136}
                    fill={fillVal}
                    fillOpacity={getOpacity([1012])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.9559 44.7852L85.3709 133.3C109.328 109.693 128.361 81.1452 140.804 49.3136L29.0197 2.77039C23.424 18.2599 14.6231 32.5349 2.9559 44.7852Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(453.96,1068.77)"
                />
                <path
                    name="1028_29,7,28,12,35"
                    width={134}
                    height={101}
                    fill={fillVal}
                    fillOpacity={getOpacity([1028])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M10.1395 10.848C10.1395 25.0205 7.69827 38.8192 3.01929 51.7702L114.805 98.3134C125.405 71.1959 131.231 41.7003 131.231 10.848V2.534H10.1395V10.848Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(480.02,1019.53)"
                />
                <path
                    name="1019_21,4,19,15,32"
                    width={136}
                    height={107}
                    fill={fillVal}
                    fillOpacity={getOpacity([1019])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M123.865 10.848V2.534H2.77301V10.848C2.77301 44.0966 9.52992 75.7726 21.7469 104.592L133.473 58.0726C127.177 43.3356 123.865 27.3468 123.865 10.848Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(2.77,1019.53)"
                />
                <path
                    name="1015_4,19,15,32,0"
                    width={151}
                    height={139}
                    fill={fillVal}
                    fillOpacity={getOpacity([1015])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M140.331 41.1233C129.174 30.0115 120.47 17.11 114.473 3.07251L2.74704 49.5916C16.876 82.9186 38.3213 112.411 65.0632 136.103L147.423 47.6476C144.989 45.5745 142.62 43.4026 140.331 41.1233Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(21.75,1075.07)"
                />
                <path
                    name="1002_17,25,2,21,4"
                    width={126}
                    height={85}
                    fill={fillVal}
                    fillOpacity={getOpacity([1002])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.77301 82.6744H123.865V2.74512H2.77301V82.6744Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(2.77,779.75)"
                />
                <path
                    name="1010_8,23,10,5,24"
                    width={96}
                    height={126}
                    fill={fillVal}
                    fillOpacity={getOpacity([1010])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M76.0479 123.37C76.2526 123.37 76.4572 123.376 76.6631 123.377L93.0007 3.37015C87.399 2.98424 81.7489 2.77319 76.0479 2.77319H2.58041V123.37H76.0479Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(292.58,2.77)"
                />
                <path
                    name="1006_13,27,6,34,17"
                    width={126}
                    height={84}
                    fill={fillVal}
                    fillOpacity={getOpacity([1006])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.77301 81.9546H123.865V2.02405H2.77301V81.9546Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(2.77,460.02)"
                />
                <path
                    name="1007_18,29,7,28,12"
                    width={126}
                    height={85}
                    fill={fillVal}
                    fillOpacity={getOpacity([1007])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.77301 81.9546H123.865V2.02405H2.77301V81.9546Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(487.14,939.6)"
                />
                <path
                    name="1009_14,31,9,22,18"
                    width={126}
                    height={84}
                    fill={fillVal}
                    fillOpacity={getOpacity([1009])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.13956 81.8144H123.231V1.88391H2.13956V81.8144Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(487.14,619.88)"
                />
                <path
                    name="1013_11,36,13,27,6"
                    width={126}
                    height={84}
                    fill={fillVal}
                    fillOpacity={getOpacity([1013])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.77301 82.0935H123.865V2.16422H2.77301V82.0935Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(2.77,300.16)"
                />
                <path
                    name="1014_1,20,14,31,9"
                    width={126}
                    height={84}
                    fill={fillVal}
                    fillOpacity={getOpacity([1014])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.13956 81.9546H123.231V2.02405H2.13956V81.9546Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(487.14,460.02)"
                />
                <path
                    name="1017_6,34,17,25,2"
                    width={126}
                    height={84}
                    fill={fillVal}
                    fillOpacity={getOpacity([1017])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.77301 81.8144H123.865V1.88391H2.77301V81.8144Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(2.77,619.88)"
                />
                <path
                    name="1018_9,22,18,29,7"
                    width={126}
                    height={85}
                    fill={fillVal}
                    fillOpacity={getOpacity([1018])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.13956 82.6744H123.231V2.74512H2.13956V82.6744Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(487.14,779.75)"
                />
                <path
                    name="1020_33,1,20,14,31"
                    width={126}
                    height={85}
                    fill={fillVal}
                    fillOpacity={getOpacity([1020])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.13956 82.0242H123.231V2.09369H2.13956V82.0242Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(487.14,380.09)"
                />
                <path
                    name="1021_25,2,21,4,19"
                    width={126}
                    height={85}
                    fill={fillVal}
                    fillOpacity={getOpacity([1021])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.77301 82.6047H123.865V2.67413H2.77301V82.6047Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(2.77,859.67)"
                />
                <path
                    name="1022_31,9,22,18,29"
                    width={126}
                    height={85}
                    fill={fillVal}
                    fillOpacity={getOpacity([1022])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.13956 82.7453H123.231V2.81476H2.13956V82.7453Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(487.14,698.81)"
                />
                <path
                    name="1025_34,17,25,2,21"
                    width={126}
                    height={85}
                    fill={fillVal}
                    fillOpacity={getOpacity([1025])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.77301 82.7453H123.865V2.81476H2.77301V82.7453Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(2.77,698.81)"
                />
                <path
                    name="1027_36,13,27,6,34"
                    width={126}
                    height={84}
                    fill={fillVal}
                    fillOpacity={getOpacity([1027])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.77301 82.0242H123.865V2.09369H2.77301V82.0242Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(2.77,380.09)"
                />
                <path
                    name="1029_22,18,29,7,28"
                    width={126}
                    height={85}
                    fill={fillVal}
                    fillOpacity={getOpacity([1029])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.13956 82.6047H123.231V2.67413H2.13956V82.6047Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(487.14,859.67)"
                />
                <path
                    name="1031_20,14,31,9,22"
                    width={126}
                    height={84}
                    fill={fillVal}
                    fillOpacity={getOpacity([1031])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.13956 81.8842H123.231V1.9549H2.13956V81.8842Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(487.14,539.95)"
                />
                <path
                    name="1034_27,6,34,17,25"
                    width={126}
                    height={84}
                    fill={fillVal}
                    fillOpacity={getOpacity([1034])}
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M2.77301 81.8842H123.865V1.9549H2.77301V81.8842Z"
                    stroke="#808080"
                    strokeWidth={3.69745}
                    strokeOpacity={0}
                    transform="scale(0.543, 0.541) translate(2.77,539.95)"
                />
            </svg>
        </>
    );
};
