import { Dispatch, SetStateAction, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { getLandingState } from '../../../main/selector';
import { CancelButton } from '../CancelButton';

type Props = {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    url: string;
    playButtonAudio: () => void;
};

export const PromotionDetail = (props: Props) => {
    const { setIsOpen, playButtonAudio } = props;
    let url = props.url;
    const { webView } = useSelector((state: RootState) =>
        getLandingState(state)
    );

    if (url && window.location.protocol == 'https:') {
        url = url.replace('http://', 'https://');
    }

    const handleCancel = () => {
        setIsOpen(false);
        playButtonAudio();
    };
    useEffect(() => {
        if (!webView) {
            handleCancel();
        }
    }, [webView, setIsOpen]);

    if (webView) {
        return (
            <>
                <iframe
                    style={{ width: '100%', height: '90%' }}
                    src={url}
                />
                <CancelButton
                    onClick={handleCancel}
                    image={'commonUI ic_close'}
                />
            </>
        );
    } else {
        window.open(url, 'PromotionDetails');
        return <></>;
    }
};