import { AppBar, Box, Grid, Stack, Tab, Tabs } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import { useECard } from '../../../../../hooks/useECard';
import { BetType } from '../../../../../models/games/pokdeng/BetType';
import { RootState } from '../../../../../store/store';
import { ExchangeBetAmount } from '../../../../../utils/commonFunc';
import { limitRedFormatter } from '../../../../../utils/formatter';
import { getHostById } from '../../../../host/slice';
import { REPORT_COLORS } from '../../../../lobby/constants';
import {
    getMainLimitRedState,
    getMainUserState,
} from '../../../../main/selector';
import { getMaxBetByBetType } from '../util';
import './PokDengTableInfoPanel.scss';
// import BaseImage from '../../../../../components/common/baseImage';
import { AudioPlayContext } from '../../../../../contexts/AudioPlayContext';
import {
    TableInfoKeyType,
    useTableInfo,
} from '../../../../../hooks/useTableInfo';
import { PokDengInGameRoadMap } from '../../../../roadmap/pokdeng';
import { PokDengECard } from '../../ECard/PokDengECard';

export const PokDengTableInfoPanel = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const { t } = useTranslation();
    const { tableInfo, setTableInfo } = useTableInfo();
    const [tableInfoTab, setTableInfoTab] = useState<number>(0);
    const show = useECard(hostId);
    const ref = useRef<HTMLDivElement>(null);
    const handleChange = (v: number, behavior: ScrollBehavior = 'smooth') => {
        if (ref && ref.current) {
            if (v !== tableInfoTab) {
                let left = 0;
                if (behavior === ('instant' as ScrollBehavior)) {
                    left = ref.current.offsetWidth * v;
                } else {
                    const scrollLeft = ref.current.scrollLeft;
                    const width = ref.current.offsetWidth * (v - tableInfoTab);
                    left = scrollLeft + width;
                }
                setTableInfoTab(v);
                ref.current.scrollTo({
                    left: left,
                    top: 0,
                    behavior: behavior,
                });
            }
        }
    };
    useEffect(() => {
        const handleScroll = (event: Event) => {
            event.preventDefault();
            if (ref && ref.current) {
                const scrollLeft = ref.current.scrollLeft;
                const width = ref.current.offsetWidth;
                if (scrollLeft % width === 0) {
                    const tab = Math.floor((scrollLeft - width) / width + 1);
                    setTableInfoTab(tab);
                    let gKey: TableInfoKeyType = 'roadmap';
                    if (tab === 1) {
                        gKey = 'tableInfo';
                    }
                    setTableInfo(gKey);
                }
            }
        };
        if (ref && ref.current) {
            ref.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (ref && ref.current)
                ref.current.removeEventListener('scroll', handleScroll);
        };
    }, [ref]);
    useEffect(() => {
        if (tableInfo === 'tableInfo') {
            handleChange(1, 'instant' as ScrollBehavior);
        } else {
            handleChange(0, 'instant' as ScrollBehavior);
        }
    }, []);
    return (
        <>
            <Box className="pokdeng-table-info">
                <AppBar>
                    <Tabs
                        className="pokdeng-table-info-tabs"
                        value={tableInfoTab}
                        onChange={(_, v) => {
                            handleChange(v);
                            playButtonAudio();
                        }}
                        aria-label="roadmap & table info."
                        sx={{ '& Button': { color: REPORT_COLORS.TITLE_TEXT } }}
                    >
                        <Tab
                            className="pokdeng-table-info-tab"
                            label={t('road_map')}
                        />
                        <Tab
                            className="pokdeng-table-info-tab"
                            label={t('tableinfo.name')}
                        />
                    </Tabs>
                </AppBar>
                {/* <Box sx={{ zIndex: 1 }}> */}
                <Box
                    ref={ref}
                    sx={{
                        overflowX: 'auto',
                        scrollSnapType: 'x mandatory',
                        whiteSpace: 'nowrap',
                        scrollBehavior: 'smooth',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                        },
                        '&-ms-overflow-style': {
                            display: 'none', // Hide the scrollbar for IE
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: 'inline-block',
                            scrollSnapAlign: 'start',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                    >
                        <PokDengInGameRoadMap hostId={hostId} />
                    </Box>
                    <Box
                        sx={{
                            display: 'inline-block',
                            scrollSnapAlign: 'start',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                    >
                        <PokDengTableInfoTab />
                    </Box>
                </Box>
                <PokDengECard show={show} />
            </Box>
        </>
    );
};
type InfoProps = {
    title: string;
    value: string | number;
};
type TableInfoTypeRow1 = {
    table: InfoProps;
    dealer: InfoProps;
    round: InfoProps;
    id: InfoProps;
    tableLimit: InfoProps;
};
type TableInfoTypeRow2 = {
    betLimit: InfoProps;
    maxLimit: InfoProps;
    minLimit: InfoProps;
    playerLimit: InfoProps;
    pairLimit: InfoProps;
};
type TableInfoType = [TableInfoTypeRow1, TableInfoTypeRow2];
const initTableInfoData = [
    {
        table: {
            title: 'tableinfo.table',
            value: '',
        },
        dealer: {
            title: 'tableinfo.dealer',
            value: '',
        },
        round: {
            title: 'tableinfo.shoe',
            value: '',
        },
        id: {
            title: 'tableinfo.round',
            value: '',
        },
        tableLimit: {
            title: 'tableinfo.tablelimit',
            value: '',
        },
    },
    {
        betLimit: {
            title: 'betLimit',
            value: '',
        },
        maxLimit: {
            title: 'tableinfo.maxlimit',
            value: '',
        },
        minLimit: {
            title: 'tableinfo.minlimit',
            value: '',
        },
        playerLimit: {
            title: 'tableinfo.player',
            value: '',
        },
        pairLimit: {
            title: 'tableinfo.pair',
            value: '',
        },
    },
] as TableInfoType;
const PokDengTableInfoTab = () => {
    const { t } = useTranslation();
    const { hostId } = useContext(VideoPlayerContext);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { CurrentResult, DealerList, TableLimit, GameType, MaxBet } = host;
    const { ExchangeRate, CurrencyName } = useSelector((state: RootState) =>
        getMainUserState(state)
    );
    const limitRedByGameType = useSelector(getMainLimitRedState);
    const [tableInfo, setTableInfo] =
        useState<TableInfoType>(initTableInfoData);

    useEffect(() => {
        if (MaxBet) {
            //row 2
            tableInfo[1].playerLimit.value = getMaxBetByBetType(
                BetType.PDBPlayer1Win,
                MaxBet
            );
            tableInfo[1].pairLimit.value = getMaxBetByBetType(
                BetType.PDBPlayer1Pair,
                MaxBet
            );
            setTableInfo(tableInfo);
        }
    }, [MaxBet]);
    useEffect(() => {
        if (hostId && TableLimit && limitRedByGameType.BetRuleByGameType) {
            //row 1
            tableInfo[0].table.value = t(`hostList.${hostId}`);
            tableInfo[0].dealer.value = DealerList
                ? DealerList[0].DealerName
                : '';
            if (CurrentResult) {
                const shoes = Math.floor(CurrentResult.GameCount / 10000);
                const round = CurrentResult.GameCount % 10000;
                const row1Round = shoes && round ? `${shoes}-${round}` : round;
                tableInfo[0].round.value = row1Round;
                tableInfo[0].id.value = CurrentResult.GameID || 0;
            }
            let infoTableLimit =
                ExchangeBetAmount(TableLimit, ExchangeRate) / 100;
            const digi = Math.pow(10, infoTableLimit.toString().length - 1);
            const tableLimitValue = (
                Math.round(infoTableLimit / digi) * digi
            ).toLocaleString('en-US', {
                maximumFractionDigits: 2,
            });
            tableInfo[0].tableLimit.value = tableLimitValue;
            //row 2
            let betLimitV = '';
            const limitRedByGame = limitRedByGameType.BetRuleByGameType?.find(
                br => br.GameType === GameType
            );
            const BetRule = limitRedByGame?.BetRule;
            let selectedBetRule = BetRule?.find(r => r.Selected === 1);
            if (!selectedBetRule && BetRule) selectedBetRule = BetRule[0]; //有機會全部selected=0
            if (selectedBetRule) {
                betLimitV = limitRedFormatter(selectedBetRule, CurrencyName);
            }
            tableInfo[1].betLimit.value = betLimitV;
            if (selectedBetRule) {
                tableInfo[1].maxLimit.value = (
                    selectedBetRule.LimitRed / 100
                ).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                });
                tableInfo[1].minLimit.value = (
                    selectedBetRule.MinBet / 100
                ).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                });
            }
            setTableInfo(tableInfo);
        }
    }, [
        hostId,
        CurrentResult?.GameCount,
        CurrentResult?.GameID,
        DealerList,
        TableLimit,
        limitRedByGameType.BetRuleByGameType,
    ]);
    return (
        <>
            {tableInfo && (
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    sx={{ fontSize: '14px', width: 540 }}
                >
                    {tableInfo.map((r, i) => {
                        const style =
                            i !== 1
                                ? { width: 270, zIndex: '1' }
                                : {
                                      width: 270,
                                      zIndex: '1',
                                      borderLeft:
                                          '2px solid rgba(255,255,255,0.2)',
                                      borderRight:
                                          '2px solid rgba(255,255,255,0.2)',
                                  };
                        return (
                            <Box key={`info-${i}`} sx={style}>
                                <>
                                    {Object.keys(r).map((k, ii) => {
                                        const v = r[
                                            k as keyof typeof r
                                        ] as InfoProps;
                                        return (
                                            <Grid
                                                key={`info-cell${ii}`}
                                                item
                                                container
                                                justifyContent={'space-between'}
                                                alignItems={'center'}
                                                sx={{
                                                    height: '32px',
                                                    padding: '0 7.5px 0 7.5px',
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    sx={{
                                                        color: REPORT_COLORS.TITLE_TEXT,
                                                    }}
                                                >
                                                    {t(v.title)}
                                                </Grid>
                                                <Grid item>{v.value}</Grid>
                                            </Grid>
                                        );
                                    })}
                                </>
                            </Box>
                        );
                    })}
                </Stack>
            )}
        </>
    );
};
