import { Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import { ShoeRound } from '../../../../models/games/ShoeRound';
import { Dealer } from '../../../../models/host/Dealer';
import { RootState } from '../../../../store/store';
import { getShoeRoundByGameCount } from '../../../../utils/commonFunc';
import { getHostById } from '../../../host/slice';
import { BlackjackHistoryInfoTable } from '../HistoryInfo/BlackjackHistoryInfoTable';
import './BlackjackTableInfoPanel.scss';
export const BlackjackTableInfoPanel = () => {
    return (
        <Grid container className="bj-table-info">
            <Grid item xs={4}>
                <TableInfo />
            </Grid>
            <Grid item xs={8}>
                <BlackjackHistoryInfoTable />
            </Grid>
        </Grid>
    );
};
type TableInfoDisplayProps = {
    title: string;
    value: string;
};
export const TableInfoDisplay = ({ title, value }: TableInfoDisplayProps) => {
    return (
        <Grid item xs={3}>
            <Grid
                container
                justifyContent={'space-between'}
                className="blackjack-table-info-item"
            >
                <Typography className="item-title">{title}</Typography>
                <Typography className="item-value">{value}</Typography>
            </Grid>
        </Grid>
    );
};
const TableInfo = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { t } = useTranslation();
    const { DealerList, CurrentResult } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [round, setRound] = useState<ShoeRound>();
    const [dealer, setDealer] = useState<Dealer>();
    const [gameId, setGameId] = useState('');
    useEffect(() => {
        if (CurrentResult) {
            const sr = getShoeRoundByGameCount(CurrentResult.GameCount);
            const g = CurrentResult.GameID.toString();
            setRound(sr);
            setGameId(g);
        }
    }, [CurrentResult]);
    useEffect(() => {
        if (DealerList) {
            const d = DealerList.at(0);
            setDealer(d);
        }
    }, [DealerList]);
    return (
        <Grid container direction={'column'} className="blackjack-table-info">
            <TableInfoDisplay
                title={t('tableinfo.table')}
                value={t(`hostList.${hostId}`)}
            />
            <TableInfoDisplay
                title={t('tableinfo.dealer')}
                value={dealer?.DealerName ?? ''}
            />
            <TableInfoDisplay
                title={t('game.round')}
                value={`${round?.Shoe}-${round?.Round}`}
            />
            <TableInfoDisplay title={t('game.game_id')} value={gameId} />
        </Grid>
    );
};
