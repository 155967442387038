import { useEffect, useState } from 'react';
import BaseImage from '../../../../components/common/baseImage';

type Props = {
    source: string;
    endFrame: number;
    duration?: number;
    scale?: number;
};

const PromotionAnimation = (props: Props) => {
    const {
        source,
        endFrame,
        duration = 1000,
        scale = 0.5,
    } = props;
    const [imageName, setImageName] = useState<string>(source);

    useEffect(() => {
        let num = 0;
        const secFrame = duration / endFrame;
        const interval = setInterval(() => {
            num++;
            if (num < endFrame) {
                const newSource = source.slice(0, -1) + num;
                setImageName(newSource);
            } else {
                const newSource = source.slice(0, -1) + num;
                setImageName(newSource);
                clearInterval(interval);
            }
        }, secFrame);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <BaseImage className={imageName} scale={scale} />
        </>
    );
};

export default PromotionAnimation;