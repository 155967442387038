import { Badge, BadgeProps, Box, Grid, Stack, styled } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Poker } from '../../../../../components/common/poker';
import { SeatAreaContext } from '../../../../../contexts/SeatAreaContext';
import { BlackjackHandResult } from '../../../../../models/games/blackjack';
import { PlayerResult } from '../../../../../models/games/blackjack/GameResult';
// import { GameState as CommonGameState } from '../../../../../models/games/enums/GameState';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { usePokerScroll } from '../../../../../hooks/usePokerScroll';
import { useSeatBet } from '../../../../../hooks/useSeatBet';
import { isPlaying } from '../../../../../models/games/blackjack/states/Phase';
import {
    getBlackjackPoints,
    getGameState,
} from '../../../../../utils/games/blackjack';
import './PlayerResultDisplay.scss';
import { SeatLabel } from './Seat/SeatLabel';

export const PlayerResultDisplay = () => {
    const {
        playerResult,
        currentState,
        bankerResult,
        currentSeat,
        showDecision,
    } = useContext(SeatAreaContext);
    const { isHand1, isHand2 } = useSeatBet(currentSeat);
    const [displayHand, setDisplayHand] = useState<PlayerResult>();
    const [isSplit, setIsSplit] = useState(false);
    useEffect(() => {
        let currentHand: PlayerResult | undefined = undefined;
        if (currentState) {
            const { mainState } = getGameState(currentState);
            if (
                isPlaying(mainState) &&
                bankerResult &&
                bankerResult.PK.length > 0
            ) {
                if (currentSeat && currentSeat > 0 && currentSeat <= 7) {
                    currentHand = playerResult.find(p => p.SN === currentSeat);
                    if (currentHand) {
                        setIsSplit(currentHand.H.length > 1);
                    }
                }
            }
        }
        setDisplayHand(currentHand);
    }, [currentState, playerResult]);
    return displayHand && displayHand.H[0]?.PK?.length > 0 ? (
        <Grid container direction={'column'}>
            <Grid container justifyContent={'center'}>
                <SeatLabel sn={displayHand.SN} />
            </Grid>
            <Grid
                container
                justifyContent={'space-around'}
                sx={{ marginTop: 1 }}
            >
                {isSplit && (
                    <Grid item>
                        <CurrentHandArrowIcon show={isHand2 && showDecision} />
                        <HandDisplay hand={displayHand.H[1]} />
                    </Grid>
                )}

                <Grid item>
                    <CurrentHandArrowIcon
                        show={isHand1 && isSplit && showDecision}
                    />
                    <HandDisplay hand={displayHand.H[0]} />
                </Grid>
            </Grid>
        </Grid>
    ) : (
        <></>
    );
};
const CustomBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
        background: 'rgba(0,0,0,0.7)',
        borderRadius: 0,
        fontSize: '1.3rem',
    },
}));
type HandDisplayProps = {
    hand?: BlackjackHandResult;
};
const HandDisplay = (props: HandDisplayProps) => {
    const { hand } = props;

    const {
        containerRef,
        scrollerRef,
        isScrollStart,
        isScrollEnd,
        isOverflow,
        onLeftClick,
        onRightClick,
        setCardChange,
    } = usePokerScroll();

    useEffect(() => {
        if (hand) {
            setCardChange(hand.PK.length);
        }
    }, [hand?.PK.length]);

    return (
        <>
            {hand && (
                <>
                    <CustomBadge
                        badgeContent={
                            <>
                                {hand?.R === 255 && (
                                    <Box>
                                        <svg
                                            width="13"
                                            height="14"
                                            viewBox="0 0 15 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M7.57154 1.3306C7.57154 1.3306 11.6798 6.29372 13.3739 8.30808C14.2061 9.29759 14.1755 10.8346 13.3561 11.8491C12.744 12.6067 11.6927 13.0565 10.6475 13.0565C10.3063 13.0565 9.96573 13.0086 9.64074 12.9077C9.19591 12.7694 8.89043 12.5604 8.11782 12.2437L9.14741 15.181H5.71471L6.67128 12.2796C5.93267 12.7387 5.07701 12.9348 4.27597 12.9348C3.91698 12.9348 3.56858 12.8953 3.24694 12.823C1.01496 12.3202 0.371124 9.86383 1.71009 8.15833C3.29488 6.14033 7.57154 1.3306 7.57154 1.3306ZM7.59718 0L6.8714 0.815833C6.6958 1.0129 2.55906 5.66767 0.977058 7.68308C0.542257 8.23685 0.258522 8.88265 0.157626 9.54977C0.0589597 10.2013 0.134214 10.8606 0.376141 11.4555C0.820976 12.55 1.78869 13.3383 3.0301 13.618C3.43034 13.708 3.84953 13.7537 4.27597 13.7537C4.61545 13.7537 4.9488 13.7257 5.27322 13.67L4.85236 14.9465L4.50508 16H10.3799L10.0059 14.9325L9.59113 13.7496C9.93061 13.8333 10.2851 13.8754 10.6475 13.8754C11.9981 13.8754 13.3142 13.2847 14.0818 12.3337C14.5919 11.7024 14.8767 10.9007 14.8846 10.0765C14.8924 9.23519 14.6103 8.4308 14.0891 7.81099C12.4162 5.82211 8.33188 0.888109 8.29119 0.838192L7.59718 0Z"
                                                fill={'#C2A377'}
                                            />
                                            <path
                                                d="M4.45003 10.9811C4.343 10.9811 4.24155 10.9839 4.14511 10.9889C4.04867 10.9939 3.95001 11.0051 3.848 11.0213L3.80786 10.7325C4.49797 10.0424 5.03478 9.39745 5.41718 8.79821C5.79958 8.19897 5.99133 7.6131 5.99133 7.04006C5.99133 6.89568 5.98186 6.76469 5.96346 6.64651C5.94451 6.52889 5.90995 6.42855 5.85922 6.3455C5.80849 6.26244 5.7377 6.20001 5.64628 6.15708C5.55542 6.11416 5.43501 6.09298 5.28506 6.09298C5.01192 6.09298 4.80623 6.15039 4.66687 6.26578C4.52751 6.38117 4.45281 6.51886 4.44222 6.6794C4.62395 6.74908 4.71536 6.86112 4.71536 7.01665C4.71536 7.30038 4.58938 7.44197 4.33798 7.44197C4.08658 7.44197 3.96896 7.26025 3.96896 6.89624C3.96896 6.48987 4.10163 6.16712 4.36641 5.92909C4.63119 5.69107 5.03645 5.57178 5.58273 5.57178C5.82354 5.57178 6.0454 5.60411 6.24887 5.66821C6.45233 5.73232 6.62848 5.82485 6.77843 5.94526C6.92838 6.06566 7.04433 6.21394 7.12738 6.39065C7.21044 6.56736 7.25169 6.76803 7.25169 6.99268C7.25169 7.34609 7.17421 7.67498 7.01868 7.9799C6.86316 8.28481 6.66527 8.57133 6.42446 8.8389C6.18365 9.10647 5.91496 9.35676 5.61785 9.58921C5.32074 9.82222 5.0331 10.0457 4.75494 10.2592H5.99133C6.11954 10.2592 6.23047 10.2514 6.32468 10.2353C6.41833 10.2191 6.49971 10.1873 6.56939 10.1388C6.63907 10.0909 6.70039 10.024 6.7539 9.93816C6.80742 9.85232 6.85814 9.74027 6.90664 9.60091L7.44456 9.64885L7.12348 10.9811H4.45058H4.45003Z"
                                                fill={'#C2A377'}
                                            />
                                            <path
                                                d="M8.00541 10.9819V10.6447C8.32091 10.6447 8.55225 10.6057 8.69997 10.5282C8.84713 10.4507 8.92071 10.337 8.92071 10.187V6.78333C8.92071 6.6016 8.84044 6.51018 8.6799 6.51018H7.97363V6.1813C8.1771 6.14896 8.35882 6.11831 8.51936 6.08876C8.6799 6.05922 8.82818 6.02187 8.96475 5.97616C9.10132 5.93101 9.23065 5.87303 9.35384 5.80336C9.47703 5.73368 9.60246 5.6456 9.73122 5.53857L10.229 5.61884C10.1811 5.84349 10.1565 6.02577 10.1565 6.16457V10.1859C10.1565 10.4908 10.4643 10.6436 11.0797 10.6436V10.9808H8.00541V10.9819Z"
                                                fill={'#C2A377'}
                                            />
                                        </svg>
                                    </Box>
                                )}
                                {getBlackjackPoints(hand.PK).join('/')}
                            </>
                        }
                    >
                        <Stack
                            justifyContent={'space-between'}
                            direction={'row'}
                            width={
                                (hand.PK.length > 5 ? 5 : hand.PK.length) * 40 +
                                30
                            }
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: -1,
                                    left: -1,
                                    zIndex: 1,
                                }}
                            >
                                {isOverflow && !isScrollStart && (
                                    <Box
                                        onClick={onLeftClick}
                                        sx={{
                                            width: '30px',
                                            height: '82px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            background: `linear-gradient(to left, #1D3C2E00 0%, #1D3C2E) 100%`,
                                        }}
                                    >
                                        <ArrowBackIosNewIcon
                                            fontSize="medium"
                                            sx={{
                                                color: '#fff',
                                                display: 'inline-block',
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                            <Box
                                ref={containerRef}
                                sx={{
                                    overflowX: 'scroll',
                                    scrollSnapType: 'x mandatory',
                                    whiteSpace: 'nowrap',
                                    scrollBehavior: 'smooth',
                                    scrollbarWidth: 'none',
                                    '&::-webkit-scrollbar': {
                                        display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                                    },
                                    '&-ms-overflow-style': {
                                        display: 'none', // Hide the scrollbar for IE
                                    },
                                    position: 'relative',
                                }}
                            >
                                <Box ref={scrollerRef} display={'inline-block'}>
                                    <Box
                                        className="blackjack-player-poker"
                                        width={hand.PK.length * 40 + 30}
                                    >
                                        {hand.PK.map((pk, i) => (
                                            <Poker
                                                n={pk}
                                                key={`blackjack-hand-${i}`}
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: '30px',
                                    height: '80px',
                                    position: 'absolute',
                                    top: -1,
                                    right: -1,
                                    zIndex: 1,
                                }}
                            >
                                {isOverflow && !isScrollEnd && (
                                    <Box
                                        onClick={onRightClick}
                                        sx={{
                                            width: '30px',
                                            height: '82px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            background: `linear-gradient(to right, #1D3C2E00 0%, #1D3C2E) 100%`,
                                        }}
                                    >
                                        <ArrowForwardIosIcon
                                            fontSize="medium"
                                            sx={{
                                                color: '#fff',
                                                display: 'inline-block',
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Stack>
                    </CustomBadge>
                </>
            )}
        </>
    );
};
type CurrentHandArrowIconProps = {
    show: boolean;
};
const CurrentHandArrowIcon = ({ show }: CurrentHandArrowIconProps) => (
    <Box
        display={'flex'}
        justifyContent={'center'}
        className="blackjack-current-hand-arrow-container"
        sx={{
            position: 'relative',
        }}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="21"
            viewBox="0 0 30 21"
            fill="none"
        >
            {show && (
                <>
                    <g filter="url(#filter0_d_198_6351)">
                        <path d="M15 15L6 6L24 6L15 15Z" fill="#CE9F07" />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_198_6351"
                            x="0"
                            y="0"
                            width="30"
                            height="21"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="8" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 1 0 0 0 0 0.763333 0 0 0 0 0.15 0 0 0 0.6 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_198_6351"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_198_6351"
                                result="shape"
                            />
                        </filter>
                    </defs>
                </>
            )}
        </svg>
    </Box>
);
