import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LocaleCode } from '../../../models/Locale';
export const LANDING_KEY = 'app::main::landing';
//Parameter : https://saapiservice.com/api-document/#live-game-3
export type LandingState = {
    username: string | null;
    token: string | null;
    lobby: string | null;
    returnUrl: string | null;
    lang: LocaleCode | null;
    mobile: boolean | null;
    options: string | null; //options=defaulttable=830,hidelogo=1,lite=1
    lite: boolean; // lite mode(remove multi-bet, switch table feature):
    hideLogo: boolean;
    defaultTable: number | null;
    webView: boolean; //game rule popup: 1 = iframe open; 0 = window.open(url)
    ts: string | null;
    // use reconnect
    isReconnect: boolean;
    isSupportStorage: boolean;
    showNewUI: boolean;
    referer: string | null;
};
const initialLandingState = {} as LandingState;
const updateLanding = (
    state: LandingState,
    action: PayloadAction<LandingState>
) => {
    state.token = action.payload.token;
    state.lobby = action.payload.lobby;
    state.username = action.payload.username;
    state.lang = action.payload.lang;
    state.mobile = action.payload.mobile;
    state.returnUrl = action.payload.returnUrl;
    state.options = action.payload.options;
    state.lite = action.payload.lite;
    state.hideLogo = action.payload.hideLogo;
    state.defaultTable = action.payload.defaultTable;
    state.webView = action.payload.webView;
    state.isReconnect = action.payload.isReconnect;
    state.isSupportStorage = action.payload.isSupportStorage;
    state.showNewUI = action.payload.showNewUI;
    state.referer = action.payload.referer;
};
export const landingSlice = createSlice({
    name: LANDING_KEY,
    initialState: initialLandingState,
    reducers: {
        updateLanding,
    },
});
export const landingSliceReducer = landingSlice.reducer;
export const landingSliceActions = landingSlice.actions;
