import { Grid, Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import { RootState } from '../../../../../store/store';
import { getHostById } from '../../../../host/slice';
import './TeenPattiWinStatisticsPanel.scss';
import { TeenPattiStatistics } from '../../../../../models/games/teenpatti';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

type WinStatisticsProps = {
    title: string;
    value: string;
    color: string;
};
type WinStatisticsType = {
    playerA: WinStatisticsProps;
    playerB: WinStatisticsProps;
    tie: WinStatisticsProps;
};
const initWinStatisticsData = {
    playerA: {
        title: 'betlog.bet_teenpatti.1',
        color: '#436FFF',
        value: '0%',
    },
    playerB: {
        title: 'betlog.bet_teenpatti.2',
        color: '#D30000',
        value: '0%',
    },
    tie: {
        title: 'betlog.bet_teenpatti.0',
        color: '#009304',
        value: '0%',
    },
};
export const TeenPattiStatisticsTab = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { i18n } = useTranslation();
    const [languageDir, setLanguageDir] = useState<string>('ltr');
    useEffect(() => {
        setLanguageDir(i18n.dir(i18n.language));
    }, [i18n.language]);

    const [winStatistics, setWinStatistics] = useState<WinStatisticsType>(
        initWinStatisticsData
    );

    const updateStatistics = () => {
        if (host.Statistics) {
            const statistics = host.Statistics as TeenPattiStatistics;
            const changeToRate = (value: number) => Math.round(value * 100);
            const totalCount =
                statistics.PlayerAWinCount +
                statistics.PlayerBWinCount +
                statistics.TieCount;
            let newWinStatistics = JSON.parse(JSON.stringify(winStatistics));
            newWinStatistics.playerA.value =
                totalCount == 0
                    ? '0%'
                    : changeToRate(statistics.PlayerAWinCount / totalCount) +
                      '%';
            newWinStatistics.playerB.value =
                totalCount == 0
                    ? '0%'
                    : changeToRate(statistics.PlayerBWinCount / totalCount) +
                      '%';
            newWinStatistics.tie.value =
                totalCount == 0
                    ? '0%'
                    : changeToRate(statistics.TieCount / totalCount) + '%';

            setWinStatistics(newWinStatistics);
        }
    };

    useEffect(() => {
        updateStatistics();
    }, [host.Statistics]);

    return (
        <>
            <Stack
                justifyContent={'center'}
                sx={{
                    fontSize: '18px',
                    height: '198px',
                }}
            >
                <Grid
                    container
                    direction="column"
                    className="teenpatti-statistics"
                    gap={'25px'}
                    fontSize={'16px'}
                >
                    {Object.values(winStatistics).map((statistics, id) => {
                        return (
                            <Grid
                                container
                                key={`statistics-cell${id}`}
                                justifyContent={'flex-end'}
                                alignItems={'center'}
                                direction="row"
                                gap={'10px'}
                            >
                                <Grid
                                    item
                                    color={statistics.color}
                                    fontWeight={'bold'}
                                    sx={{
                                        direction: languageDir,
                                    }}
                                >
                                    {t(statistics.title)}
                                </Grid>
                                <Grid container className="bar">
                                    <Grid
                                        item
                                        width={statistics.value}
                                        bgcolor={statistics.color}
                                    ></Grid>
                                </Grid>
                                <Grid item color={'#fff'} width={'130px'}>
                                    {statistics.value}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Stack>
        </>
    );
};
