import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
// import { RootState } from '../../../store/store';
// import { getLandingState } from '../../main/selector';
import { useContext, useEffect, useRef, useState } from 'react';
import {
    Box,
    CircularProgress,
    Dialog,
    Stack,
    Typography,
} from '@mui/material';
import { GlobalDataContext } from '../../../contexts/GlobalDataContext';
import { useTranslation } from 'react-i18next';
import { getSettingVolumeState } from '../../setting/selector';
import { theme } from '../../../theme';
import { VideoPlayerContext } from '../../../contexts/VideoContext';
// import { getLandingState } from '../../main/selector';

export const VideoUnmutePanel = () => {
    const { t } = useTranslation();
    // const { defaultTable } = useSelector(getLandingState);
    const { isDefaultTable, setIsDefaultTable } = useContext(GlobalDataContext);
    const { Studio } = useSelector(getSettingVolumeState);

    const [show, setShow] = useState<boolean>(false);
    const Ref = useRef<NodeJS.Timeout>();
    const [timer, setTimer] = useState<number>(5);
    const { isLoading, isAudioBlocked, audioResume } =
        useContext(VideoPlayerContext);
    const onClick = () => {
        try {
            //After 6.3.12 version
            audioResume();
        } catch (e) {
            console.warn(e);
        }
        handleShow(false);
    };
    const handleShow = (v: boolean) => {
        setShow(v);
        setIsDefaultTable(false);
    };
    useEffect(() => {
        //isAudioBlocked After 6.3.13 version
        if (isAudioBlocked && isDefaultTable && !isLoading && Studio > 0) {
            handleShow(true);
        }
    }, [isLoading, isAudioBlocked]);
    useEffect(() => {
        const clearTimer = () => {
            if (Ref.current) clearInterval(Ref.current);
            const id = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);
            Ref.current = id;
        };
        if (timer >= 0) {
            clearTimer();
        } else {
            handleShow(false);
        }
        return () => {
            if (Ref.current) clearInterval(Ref.current);
        };
    }, [timer]);
    return (
        <>
            {show && (
                <Dialog
                    container={() =>
                        document.getElementById('in-game-popup-container')
                    }
                    // fullScreen
                    open={show}
                    onClose={() => handleShow(false)}
                    onClick={onClick}
                    hideBackdrop={true}
                    disableEscapeKeyDown={true}
                    sx={{
                        '& .MuiDialog-container': { alignItems: 'flex-end' },
                    }}
                    PaperProps={{
                        sx: {
                            bottom: 75,
                            width: 400,
                            height: 72,
                            bgcolor: 'rgba(41,41,41,0.9)',
                            margin: 0,
                            overflow: 'hidden',
                            boxShadow: 'none',
                            borderRadius: 0,
                        },
                    }}
                >
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        alignContent={'center'}
                        justifyContent={'space-between'}
                        sx={{
                            height: 72,
                            marginLeft: '30px',
                            marginRight: '30px',
                        }}
                    >
                        <VolumeUpIcon
                            sx={{ width: 36, height: 36, color: 'white' }}
                        />
                        <Box
                            sx={{
                                width: 250,
                                marginLeft: '4px',
                                marginRight: '4px',
                            }}
                        >
                            <Box sx={{ size: '24px' }}>{t('video.unmute')}</Box>
                            <Box sx={{ size: '16px' }}>
                                {t('video.unmute_msg')}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'inline-flex',
                                width: 50,
                                height: 50,
                            }}
                        >
                            {timer <= 0 ? (
                                <>
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <CloseIcon
                                            sx={{
                                                color: theme.palette.text
                                                    .primary,
                                                width: 24,
                                                height: 24,
                                            }}
                                        />
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <CircularProgress
                                        variant="determinate"
                                        size={50}
                                        thickness={2.5}
                                        value={100 - 20 * timer}
                                        sx={{ color: '#d9d9d9' }}
                                    />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            color="ffffff"
                                            fontSize={24}
                                        >
                                            {timer}
                                        </Typography>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Stack>
                </Dialog>
            )}
        </>
    );
};
