import { Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { VideoPlayerContext } from '../../../../../../contexts/VideoContext';
import { GameState as GameStateEnum } from '../../../../../../models/games/enums/GameState';
import { RootState } from '../../../../../../store/store';
import { getConfirmBets, getGameSate, getPendingBets } from '../../../../../games/selector';
import { getHostById } from '../../../../../host/slice';
import { gameSliceActions } from '../../../../../games/slice';
import BaseImage from '../../../../../../components/common/baseImage';
import { GameType as GameTypeEnum } from '../../../../../../models/games/enums/GameType';
import { AudioPlayContext } from '../../../../../../contexts/AudioPlayContext';
import { BaseTypography } from '../../../../../../components/common/baseText/BaseTypography';

export const DoubleBetButton = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const { GameType } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const { IsRest, CurrentState, ResultReleased } = useSelector(
        (state: RootState) => getHostById(state, hostId)
    );
    const [isBetting, setIsBetting] = useState<boolean>(false);
    const confirmedBets = useSelector((state: RootState) =>
        getConfirmBets(state)
    );
    const pendingBets = useSelector((state: RootState) =>
        getPendingBets(state)
    );
    const doubleBet = useSelector((state: RootState) => 
        getGameSate(state)
    );
    const dispatch = useDispatch();

    const [enable, setEnable] = useState<boolean>(false);

    useEffect(() => {
        setIsBetting(IsRest ? false : CurrentState === GameStateEnum.Betting);

        if (IsRest || CurrentState != GameStateEnum.Betting) {
            setEnable(false);
        }
    }, [IsRest, CurrentState, ResultReleased]);

    useEffect(() => {
        const hasConfirmedBets = !!confirmedBets?.length;
        const hasPendingBets = !!pendingBets?.length;
        if (CurrentState === GameStateEnum.Betting)
            setEnable(hasConfirmedBets || hasPendingBets);
    }, [confirmedBets, pendingBets]);

    useEffect(() => {
        if(!doubleBet) setEnable(true);
    }, [doubleBet])

    const onClick = () => {
        if (isBetting && enable) {
            setEnable(false);
            playButtonAudio();
            dispatch(gameSliceActions.onDoubleBet({ onClick: true }));
        }
    };

    switch (GameType) {
        case GameTypeEnum.Roulette:
            return <ButtonStyleB enable={enable} onClick={onClick} />;
        default:
            return <ButtonStyleA enable={enable} onClick={onClick} />;
    }
};

type ButtonProps = {
    enable?: boolean;
    onClick: () => void;
};
const ButtonStyleA = (props: ButtonProps) => {
    const { enable, onClick } = props;
    const { t } = useTranslation();
    return (
        <Stack
            justifyContent={'space-between'}
            alignItems={'center'}
            direction={'column'}
            flexWrap={'nowrap'}
            sx={{
                backgroundColor: 'rgba(0,0,0,0.3)',
                width: '70px',
                height: '60px',
                fontSize: '14px',
                padding: '8px 0 8px 0',
                color: enable ? '#ffffff' : '#8a8682',
            }}
            onClick={onClick}
        >
            <svg width={'18px'} height={'18px'}>
                <circle
                    cx={9}
                    cy={9}
                    r={9}
                    fill={enable ? '#c4a374' : '#8a8682'}
                />
                <text
                    x={9}
                    y={10} // 9 + 1
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fill="#000000"
                    fontSize={'12px'}
                >
                    x2
                </text>
            </svg>
            <BaseTypography resize={{ direction:'horizontal', value:'60px' }}>{t('game.double')}</BaseTypography>
        </Stack>
    );
};

const ButtonStyleB = (props: ButtonProps) => {
    const { enable, onClick } = props;
    return (
        <Stack
            justifyContent={'space-between'}
            alignItems={'center'}
            direction={'column'}
            flexWrap={'nowrap'}
            sx={{
                width: '56px',
                height: '56px',
            }}
            onClick={onClick}
        >
            <BaseImage className="commonUI btn_x2" opacity={enable ? 1 : 0.5} />
        </Stack>
    );
};
