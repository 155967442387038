export type Roadmap<T> = {
    HostId: number;
    Bead: Array<T>;
    Other: T[][];
    BigRoad: Array<T>;
    BigEyeRoad: Array<T>;
    SmallRoad: Array<T>;
    BeadRoad: Array<T>;
    CockroachRoad: Array<T>;
    Summary: Record<string, number>;
};

export const getEmptyRoadMap = <T>(hostId: number): Roadmap<T> => ({
    HostId: hostId,
    Bead: [],
    Other: [[]],
    BigRoad: [],
    BigEyeRoad: [],
    SmallRoad: [],
    BeadRoad: [],
    CockroachRoad: [],
    Summary: {},
});
