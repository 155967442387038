import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { amountFormatter } from '../../../../utils/formatter';
import { Bet, BetRecordTotalResult } from '../../../report/slice/betRecord';
import { NestedDialog, NestedDialogIndexProps } from '../NestedDialog';
import { UsernameGrid } from '../UsernameGrid';
import { Detail } from './Details';
import { Summary } from './Summary';
// import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import BaseImage from '../../../../components/common/baseImage';
import { BetState } from '../../../../models/games/enums/BetState';
import { GameType as GameTypeEnum } from '../../../../models/games/enums/GameType';
import { ReportDivider } from '../ReportDivider';
import { GameTypeSelector } from './GameTypeSelector';

type CloseHandler = () => void;
type BetRecordDetailsReportProps = {
    username: string;
    record: BetRecordTotalResult;
    date: string;
    onClose: CloseHandler;
    playButtonAudio: CloseHandler;
} & NestedDialogIndexProps;

export const BetRecordDetailsReport = (props: BetRecordDetailsReportProps) => {
    const {
        username,
        record,
        date,
        onClose,
        currentIndex,
        setCurrentIndex,
        playButtonAudio,
    } = props;
    const [collapseIndex, setCollapseIndex] = useState(-1);
    const [gameType, setGameType] = useState<Array<GameTypeEnum | -1>>([-1]);
    const [curBets, setCurBets] = useState<Array<Bet>>();
    const [curTotalAmount, setCurTotalAmount] = useState<number>(0);
    const { t } = useTranslation();
    useEffect(() => {
        if (record) {
            let newTotalAmount = 0;
            const newBets = record.bets
                .filter(bet => {
                    return (
                        gameType.includes(bet.GameType) || gameType.includes(-1)
                    );
                    //if (gameType !== -1 && gameType !== bet.GameType)
                    //return false;
                    //return true;
                })
                .map(b => {
                    newTotalAmount += b.ResultAmount / 100;
                    return b;
                });
            setCurBets(newBets);
            setCurTotalAmount(newTotalAmount);
        }
    }, [record, gameType]);
    return (
        <>
            <UsernameGrid username={username} />
            <Grid
                item
                container
                justifyContent="space-around"
                alignItems="center"
                sx={{ margin: '12px 0 12px 0' }}
            >
                <Grid item container direction="column" sx={{ width: '45vw' }}>
                    <Grid item sx={{ fontSize: '5.18vw' }}>
                        {date}
                    </Grid>
                    <Grid item>
                        {amountFormatter(curTotalAmount, true, '3.7vw', true)}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    justifyContent="flex-end"
                    sx={{ width: '45vw' }}
                >
                    <GameTypeSelector
                        dropdown={record.gameTypeLists ?? [-1]}
                        gameType={gameType}
                        setGameType={setGameType}
                        playButtonAudio={playButtonAudio}
                    />
                </Grid>
            </Grid>
            <Grid item>
                <ReportDivider />
            </Grid>
            <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={{
                    fontSize: '3.89vw',
                    color: '#ae9268',
                    paddingX: '12px',
                }}
            >
                <BetRecordDetailCell
                    first={t('betlog.time')}
                    second={t('betlog.game')}
                    third={t('betlog.win_lose')}
                    isTitle={true}
                />
            </Stack>
            <Grid item>
                <ReportDivider />
            </Grid>
            <Box
                overflow={'auto'}
                sx={{
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                        display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                    },
                    '&-ms-overflow-style': {
                        display: 'none', // Hide the scrollbar for IE
                    },
                }}
            >
                <List sx={{ paddingY: 0 }}>
                    {curBets &&
                        curBets.map((bet, i) => {
                            const gameName = GetGameName(
                                bet.GameType,
                                bet.HostID,
                                t
                            );
                            return (
                                <div key={`list-detail-${bet.BetID}-${i}`}>
                                    <ListItem
                                        disablePadding
                                        sx={{
                                            height: '13.15vw',
                                        }}
                                    >
                                        <ListItemButton
                                            onClick={() => {
                                                setCurrentIndex(1);
                                                setCollapseIndex(i);
                                            }}
                                            selected={collapseIndex === i}
                                            sx={{ paddingY: 0 }}
                                        >
                                            <ListItemText>
                                                <Summary
                                                    bet={bet}
                                                    gameName={gameName}
                                                />
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                    <ReportDivider isCell={true} />
                                    <NestedDialog
                                        currentIndex={currentIndex}
                                        setCurrentIndex={setCurrentIndex}
                                        isOpen={
                                            currentIndex === 1 &&
                                            collapseIndex === i
                                        }
                                        onClose={() => onClose()}
                                        playButtonAudio={playButtonAudio}
                                        title={'menu.bet_record'}
                                    >
                                        <Detail
                                            onGoing={
                                                bet.State == BetState.Ready
                                            }
                                            bet={bet}
                                            gameName={gameName}
                                            username={username}
                                        />
                                    </NestedDialog>
                                </div>
                            );
                        })}
                </List>
            </Box>
        </>
    );
};

export const BetRecordDetailCell = ({
    first,
    second,
    third,
    isTitle,
    onGoing,
}: {
    first: string | number;
    second: string | number;
    third: string | number | JSX.Element;
    isTitle: boolean;
    onGoing?: boolean;
}) => {
    return (
        <>
            <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-start"
                sx={{ width: '30vw', height: '13.15vw' }}
            >
                {first}
            </Grid>
            <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                sx={{ width: '30vw', height: '13.15vw' }}
            >
                {second}
            </Grid>
            <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ width: '30vw', height: '13.15vw' }}
            >
                {isTitle ? (
                    third
                ) : (
                    <Grid display={'flex'} gap={'10px'} alignItems={'center'}>
                        {onGoing ? <></> : third}
                        <Grid>
                            <BaseImage
                                className="commonUI ic_navigate_next_disable"
                                scale={1}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

const GetGameName = (
    gameType: number,
    hostId: number,
    t: TFunction
): string => {
    switch (gameType) {
        //TODO: other game
        case GameTypeEnum.Baccarat:
        case GameTypeEnum.SqueezeBaccarat:
            return (
                t('baccarat.gametype') + ' - ' + t(`hostCodeNames.${hostId}`)
            );
        case GameTypeEnum.SeDie:
        case GameTypeEnum.Dragon:
        case GameTypeEnum.PokDeng:
        case GameTypeEnum.SicBo:
        case GameTypeEnum.AndarBahar:
        case GameTypeEnum.TeenPatti2020:
        case GameTypeEnum.Roulette:
        case GameTypeEnum.Blackjack:
            return t(`hostList.${hostId}`);
        default:
            return '';
    }
};
