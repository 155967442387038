import { useContext, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { BetChipContext } from '../../../../../../../contexts/BetChipContext';
import { VideoPlayerContext } from '../../../../../../../contexts/VideoContext';
import { useBalance } from '../../../../../../../hooks/useBalance';
import { IsMobile } from '../../../../../../../hooks/useScreenRatio';
import { useUserAgent } from '../../../../../../../hooks/useUserAgent';
import { StateMsgType } from '../../../../../../../models/Popup';
import { GameFeature as GameFeatureEnum } from '../../../../../../../models/games/enums/GameFeature';
import { GameState } from '../../../../../../../models/games/enums/GameState';
import { RootState } from '../../../../../../../store/store';
import { isBitActive } from '../../../../../../../utils/bitwiseUtil';
import { calcLowHigh } from '../../../../../../../utils/commonFunc';
import {
    getCurrentGameId,
    getTotalPendingBetsAmount,
    getWaitingBetResult,
} from '../../../../../../games/selector';
import { gameSliceActions } from '../../../../../../games/slice';
import { getHostById } from '../../../../../../host/slice';
import { getMainPlayerState } from '../../../../../../main/selector';
import { inGamePopupSliceActions } from '../../../../../../popup/inGameSlice';
import { amountOverBetLimit, isOppositeBetting } from '../../utils';

// const fillVal: Array<string> = ['#F40906', '#000000', '#009003', '#FFFFFF'];
const fillVal = '#FFFFFF';

export const TapAreaContainer = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { selectedBetAmount, selectedBetRule } = useContext(BetChipContext);
    const { IsRest, CurrentState, ResultReleased, GameType, MaxBet } =
        useSelector((state: RootState) => getHostById(state, hostId));
    const currentGameId = useSelector((state: RootState) =>
        getCurrentGameId(state)
    );
    const allPendingBetsAmount = useSelector((state: RootState) =>
        getTotalPendingBetsAmount(state)
    );
    const isWaitingBetResult = useSelector((state: RootState) =>
        getWaitingBetResult(state)
    );
    const dispatch = useDispatch();

    const [isBetting, setIsBetting] = useState<boolean>(false);

    const { availableBalance } = useBalance();

    const [onOverBetTypes, setOnOverBetTypes] = useState<Array<number>>([]);
    const [selectedName, setSelectedName] = useState<string>('');

    const { device } = useUserAgent();
    const { GameFeature } = useSelector(getMainPlayerState);
    const [isGameFeatureEnabled, setIsGameFeatureEnabled] =
        useState<boolean>(false);

    useEffect(() => {
        const curGameFeature = calcLowHigh(GameFeature);

        const isEnabled = isBitActive(
            curGameFeature,
            GameFeatureEnum.HedgeBetting
        );
        setIsGameFeatureEnabled(isEnabled);
    }, [GameFeature]);
    useEffect(() => {
        if (IsRest) {
            setIsBetting(false);
            resetSelectedTypes();
        }
        setIsBetting(CurrentState === GameState.Betting);

        if (CurrentState !== GameState.Betting) {
            resetSelectedTypes();
        }
    }, [IsRest, CurrentState, ResultReleased]);
    const resetSelectedTypes = () => {
        setSelectedName('');
        setOnOverBetTypes([]);
    };

    const onTap = (name: string | null) => {
        // TODO: DRY
        // if (selectedName.length == 0) {
        //     resetSelectedTypes();
        //     return;
        // }

        if (!selectedBetRule) {
            dispatch(
                inGamePopupSliceActions.open(
                    'system.error_m226.17',
                    GameType,
                    StateMsgType.betInfo_Fail,
                    currentGameId
                )
            );
            resetSelectedTypes();
            return;
        }

        if (!isBetting || !MaxBet) {
            resetSelectedTypes();
            return;
        }

        if (isWaitingBetResult) return;

        const betTypeVal = name ? name.split('_')[0] : '';
        const containNumVal = name ? name.split('_')[1] : '';
        // console.log('roulette::tap', betTypeVal, containNumVal);\
        if (!isGameFeatureEnabled && isOppositeBetting(Number(betTypeVal))) {
            dispatch(
                inGamePopupSliceActions.open(
                    'system.bet_opposite',
                    GameType,
                    StateMsgType.betInfo_Fail,
                    currentGameId
                )
            );
            return;
        }

        let amount: number = selectedBetAmount;
        let pendingBetAmount: number = 0;
        let isAllIn: boolean = false;
        let haveBetLimitAllIn = false;

        const betTypes =
            Number(betTypeVal) >= 1000
                ? containNumVal.split(',')
                : [Number(betTypeVal)];

        betTypes.forEach(betType => {
            // check over bet limit
            const overLimit = amountOverBetLimit(
                Number(betType),
                hostId,
                amount
            );
            if (overLimit > 0) {
                if (amount <= overLimit) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.bet_out_of_limit_red',
                            GameType,
                            StateMsgType.betInfo_Fail,
                            currentGameId
                        )
                    );
                    resetSelectedTypes();
                    return;
                } else {
                    amount -= overLimit;
                    haveBetLimitAllIn = true;
                }
            }

            pendingBetAmount = amount;
            if (
                allPendingBetsAmount + pendingBetAmount >
                availableBalance * 100
            ) {
                pendingBetAmount =
                    Math.floor(availableBalance) * 100 - allPendingBetsAmount;
                if (pendingBetAmount <= 0) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.not_enough_money',
                            GameType,
                            StateMsgType.betInfo_Fail,
                            currentGameId
                        )
                    );
                    resetSelectedTypes();
                    return;
                }
                isAllIn = true;
            }

            batch(() => {
                if (haveBetLimitAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.bet_exceed_limit_allin',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                } else if (isAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.all_in',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                }

                dispatch(
                    gameSliceActions.onPendingBet({
                        GameId: currentGameId,
                        Type: Number(betType),
                        Amount: pendingBetAmount,
                    })
                );
            });
        });

        dispatch(
            gameSliceActions.onPendingBetHistory([
                {
                    GameId: currentGameId,
                    Type: Number(betTypeVal),
                    Amount: pendingBetAmount,
                    ContainNum: containNumVal.split(',').map(n => Number(n)),
                },
            ])
        );
        resetSelectedTypes();
    };

    const onCancel = () => {
        if (selectedName.length > 0) {
            onTap(selectedName);
        }
    };

    const onMouseOver = (event: React.MouseEvent) => {
        if (IsMobile(device)) {
            return;
        }

        if (!isBetting || isWaitingBetResult) {
            return;
        }

        const target = document.elementFromPoint(event.clientX, event.clientY);

        if (target && target.hasAttribute('name')) {
            const name = target.getAttribute('name');
            updateSelectedName(name);
        }
    };

    const onMouseEnd = () => {
        if (IsMobile(device)) {
            return;
        }

        if (selectedName.length > 0) {
            onTap(selectedName);
        }
    };

    const onEnd = () => {
        if (selectedName.length > 0) {
            onTap(selectedName);
        }
    };

    // const onClick = (event: React.MouseEvent) => {
    //     if (selectedName.length === 0) {
    //         if (!selectedBetRule) {
    //             dispatch(
    //                 inGamePopupSliceActions.open(
    //                     'system.error_m226.17',
    //                     GameType,
    //                     StateMsgType.betInfo_Fail
    //                 )
    //             );
    //             return;
    //         }

    //         if (!isBetting) {
    //             return;
    //         }

    //         const target = event.target as SVGElement;
    //         const name = target.getAttribute('name');

    //         updateSelectedName(name);
    //         onTap(name);
    //     }
    // };

    const onOver = (event: React.TouchEvent) => {
        if (!isBetting || isWaitingBetResult) {
            return;
        }

        const touch = event.touches[0];
        const target = document.elementFromPoint(touch.clientX, touch.clientY);

        if (target && target.hasAttribute('name')) {
            const name = target.getAttribute('name');
            updateSelectedName(name);
        }
    };

    const updateSelectedName = (name: string | null) => {
        if (name) {
            const betTypeVal = name ? name.split('_')[0] : '';
            const containNumVal = name ? name.split('_')[1] : '';

            if (betTypeVal.length > 0) {
                // const betTypes = [
                //     Number(betTypeVal),
                //     ...containNumVal.split(',').map(n => Number(n)),
                // ].filter((value, index, self) => {
                //     return self.indexOf(value) === index;
                // });

                const betTypes = [
                    Number(betTypeVal),
                    ...containNumVal.split(',').map(n => Number(n)),
                ];
                setOnOverBetTypes(betTypes);
                setSelectedName(name);
                // console.log('onOver', betTypes);
            }
        } else {
            setOnOverBetTypes([]);
            setSelectedName('');
        }
    };

    const getOpacity = (ownType: Array<number>): number => {
        if (!isBetting) {
            return 0;
        }

        for (let i = 0; i < ownType.length; i++) {
            if (
                onOverBetTypes.find(overType => overType === ownType[i]) !=
                undefined
            ) {
                return 0.5;
            }
        }

        return 0;
    };

    return (
        <>
            <svg
                widths={340}
                height={688}
                viewBox="0 0 340 688"
                fill="none"
                // onClick={onClick}
                onMouseDown={onMouseOver}
                onMouseMove={onMouseOver}
                onMouseUp={onMouseEnd}
                onTouchStart={onOver}
                onTouchMove={onOver}
                onTouchEnd={onEnd}
                onTouchCancel={onCancel}
            >
                {/* Outside */}
                <rect
                    name="144_1,2,3,4,5,6,7,8,9,10,11,12"
                    id="144"
                    width={61}
                    height={197}
                    x={1}
                    y={49}
                    fill={fillVal}
                    fillOpacity={getOpacity([144])}
                />
                <rect
                    name="145_13,14,15,16,17,18,19,20,21,22,23,24"
                    width={61}
                    height={196}
                    x={1}
                    y={246}
                    fill={fillVal}
                    fillOpacity={getOpacity([145])}
                />
                <rect
                    name="146_25,26,27,28,29,30,31,32,33,34,35,36"
                    width={61}
                    height={196}
                    x={1}
                    y={442}
                    fill={fillVal}
                    fillOpacity={getOpacity([146])}
                />
                <rect
                    name="147_1,4,7,10,13,16,19,22,25,28,31,34"
                    width={73}
                    height={49}
                    x={62}
                    y={640}
                    fill={fillVal}
                    fillOpacity={getOpacity([147])}
                />
                <rect
                    name="148_2,5,8,11,14,17,20,23,26,29,32,35"
                    width={72}
                    height={49}
                    x={135}
                    y={640}
                    fill={fillVal}
                    fillOpacity={getOpacity([148])}
                />
                <rect
                    name="149_3,6,9,12,15,18,21,24,27,30,33,36"
                    width={73}
                    height={49}
                    x={207}
                    y={640}
                    fill={fillVal}
                    fillOpacity={getOpacity([149])}
                />
                <rect
                    name="150_1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18"
                    width={60}
                    height={99}
                    x={280}
                    y={49}
                    fill={fillVal}
                    fillOpacity={getOpacity([150])}
                />
                <rect
                    name="151_19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36"
                    width={60}
                    height={99}
                    x={280}
                    y={539}
                    fill={fillVal}
                    fillOpacity={getOpacity([151])}
                />
                <rect
                    name="152_1,3,5,7,9,11,13,15,17,19,21,23,25,27,29,31,33,35"
                    width={60}
                    height={98}
                    x={280}
                    y={442}
                    fill={fillVal}
                    fillOpacity={getOpacity([152])}
                />
                <rect
                    name="153_2,4,6,8,10,12,14,16,18,20,22,24,26,28,30,32,34,36"
                    width={60}
                    height={98}
                    x={280}
                    y={148}
                    fill={fillVal}
                    fillOpacity={getOpacity([153])}
                />
                <rect
                    name="154_1,3,5,7,9,12,14,16,18,19,21,23,25,27,30,32,34,36"
                    width={60}
                    height={98}
                    x={280}
                    y={246}
                    fill={fillVal}
                    fillOpacity={getOpacity([154])}
                />
                <rect
                    name="155_2,4,6,8,10,11,13,15,17,20,22,24,26,28,29,31,33,35"
                    width={60}
                    height={98}
                    x={280}
                    y={344}
                    fill={fillVal}
                    fillOpacity={getOpacity([155])}
                />
                {/* one number */}
                <path
                    name="0_0"
                    width={218}
                    height={48}
                    d="M 218 18 L 109 0 L 0 18 V 48 H 218 V 18 Z"
                    transform="translate(62,0)"
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    fill={fillVal}
                    fillOpacity={getOpacity([0])}
                />
                {/* 1 to 36 */}
                <rect
                    name={'1_1'}
                    width={72}
                    height={48}
                    x={62 + 72 * (0 % 3) + (0 % 3)}
                    y={50 + 48 * Math.floor(0 / 3) + Math.floor(0 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([1])}
                    fillRule="evenodd"
                />
                <rect
                    name={'2_2'}
                    width={72}
                    height={48}
                    x={62 + 72 * (1 % 3) + (1 % 3)}
                    y={50 + 48 * Math.floor(1 / 3) + Math.floor(1 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([2])}
                    fillRule="evenodd"
                />
                <rect
                    name={'3_3'}
                    width={72}
                    height={48}
                    x={62 + 72 * (2 % 3) + (2 % 3)}
                    y={50 + 48 * Math.floor(2 / 3) + Math.floor(2 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([3])}
                    fillRule="evenodd"
                />
                <rect
                    name={'4_4'}
                    width={72}
                    height={48}
                    x={62 + 72 * (3 % 3) + (3 % 3)}
                    y={50 + 48 * Math.floor(3 / 3) + Math.floor(3 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([4])}
                    fillRule="evenodd"
                />
                <rect
                    name={'5_5'}
                    width={72}
                    height={48}
                    x={62 + 72 * (4 % 3) + (4 % 3)}
                    y={50 + 48 * Math.floor(4 / 3) + Math.floor(4 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([5])}
                    fillRule="evenodd"
                />
                <rect
                    name={'6_6'}
                    width={72}
                    height={48}
                    x={62 + 72 * (5 % 3) + (5 % 3)}
                    y={50 + 48 * Math.floor(5 / 3) + Math.floor(5 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([6])}
                    fillRule="evenodd"
                />
                <rect
                    name={'7_7'}
                    width={72}
                    height={48}
                    x={62 + 72 * (6 % 3) + (6 % 3)}
                    y={50 + 48 * Math.floor(6 / 3) + Math.floor(6 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([7])}
                    fillRule="evenodd"
                />
                <rect
                    name={'8_8'}
                    width={72}
                    height={48}
                    x={62 + 72 * (7 % 3) + (7 % 3)}
                    y={50 + 48 * Math.floor(7 / 3) + Math.floor(7 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([8])}
                    fillRule="evenodd"
                />
                <rect
                    name={'9_9'}
                    width={72}
                    height={48}
                    x={62 + 72 * (8 % 3) + (8 % 3)}
                    y={50 + 48 * Math.floor(8 / 3) + Math.floor(8 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([9])}
                    fillRule="evenodd"
                />
                <rect
                    name={'10_10'}
                    width={72}
                    height={48}
                    x={62 + 72 * (9 % 3) + (9 % 3)}
                    y={50 + 48 * Math.floor(9 / 3) + Math.floor(9 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([10])}
                    fillRule="evenodd"
                />
                <rect
                    name={'11_11'}
                    width={72}
                    height={48}
                    x={62 + 72 * (10 % 3) + (10 % 3)}
                    y={50 + 48 * Math.floor(10 / 3) + Math.floor(10 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([11])}
                    fillRule="evenodd"
                />
                <rect
                    name={'12_12'}
                    width={72}
                    height={48}
                    x={62 + 72 * (11 % 3) + (11 % 3)}
                    y={50 + 48 * Math.floor(11 / 3) + Math.floor(11 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([12])}
                    fillRule="evenodd"
                />
                <rect
                    name={'13_13'}
                    width={72}
                    height={48}
                    x={62 + 72 * (12 % 3) + (12 % 3)}
                    y={50 + 48 * Math.floor(12 / 3) + Math.floor(12 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([13])}
                    fillRule="evenodd"
                />
                <rect
                    name={'14_14'}
                    width={72}
                    height={48}
                    x={62 + 72 * (13 % 3) + (13 % 3)}
                    y={50 + 48 * Math.floor(13 / 3) + Math.floor(13 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([14])}
                    fillRule="evenodd"
                />
                <rect
                    name={'15_15'}
                    width={72}
                    height={48}
                    x={62 + 72 * (14 % 3) + (14 % 3)}
                    y={50 + 48 * Math.floor(14 / 3) + Math.floor(14 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([15])}
                    fillRule="evenodd"
                />
                <rect
                    name={'16_16'}
                    width={72}
                    height={48}
                    x={62 + 72 * (15 % 3) + (15 % 3)}
                    y={50 + 48 * Math.floor(15 / 3) + Math.floor(15 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([16])}
                    fillRule="evenodd"
                />
                <rect
                    name={'17_17'}
                    width={72}
                    height={48}
                    x={62 + 72 * (16 % 3) + (16 % 3)}
                    y={50 + 48 * Math.floor(16 / 3) + Math.floor(16 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([17])}
                    fillRule="evenodd"
                />
                <rect
                    name={'18_18'}
                    width={72}
                    height={48}
                    x={62 + 72 * (17 % 3) + (17 % 3)}
                    y={50 + 48 * Math.floor(17 / 3) + Math.floor(17 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([18])}
                    fillRule="evenodd"
                />
                <rect
                    name={'19_19'}
                    width={72}
                    height={48}
                    x={62 + 72 * (18 % 3) + (18 % 3)}
                    y={50 + 48 * Math.floor(18 / 3) + Math.floor(18 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([19])}
                    fillRule="evenodd"
                />
                <rect
                    name={'20_20'}
                    width={72}
                    height={48}
                    x={62 + 72 * (19 % 3) + (19 % 3)}
                    y={50 + 48 * Math.floor(19 / 3) + Math.floor(19 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([20])}
                    fillRule="evenodd"
                />
                <rect
                    name={'21_21'}
                    width={72}
                    height={48}
                    x={62 + 72 * (20 % 3) + (20 % 3)}
                    y={50 + 48 * Math.floor(20 / 3) + Math.floor(20 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([21])}
                    fillRule="evenodd"
                />
                <rect
                    name={'22_22'}
                    width={72}
                    height={48}
                    x={62 + 72 * (21 % 3) + (21 % 3)}
                    y={50 + 48 * Math.floor(21 / 3) + Math.floor(21 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([22])}
                    fillRule="evenodd"
                />
                <rect
                    name={'23_23'}
                    width={72}
                    height={48}
                    x={62 + 72 * (22 % 3) + (22 % 3)}
                    y={50 + 48 * Math.floor(22 / 3) + Math.floor(22 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([23])}
                    fillRule="evenodd"
                />
                <rect
                    name={'24_24'}
                    width={72}
                    height={48}
                    x={62 + 72 * (23 % 3) + (23 % 3)}
                    y={50 + 48 * Math.floor(23 / 3) + Math.floor(23 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([24])}
                    fillRule="evenodd"
                />
                <rect
                    name={'25_25'}
                    width={72}
                    height={48}
                    x={62 + 72 * (24 % 3) + (24 % 3)}
                    y={50 + 48 * Math.floor(24 / 3) + Math.floor(24 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([25])}
                    fillRule="evenodd"
                />
                <rect
                    name={'26_26'}
                    width={72}
                    height={48}
                    x={62 + 72 * (25 % 3) + (25 % 3)}
                    y={50 + 48 * Math.floor(25 / 3) + Math.floor(25 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([26])}
                    fillRule="evenodd"
                />
                <rect
                    name={'27_27'}
                    width={72}
                    height={48}
                    x={62 + 72 * (26 % 3) + (26 % 3)}
                    y={50 + 48 * Math.floor(26 / 3) + Math.floor(26 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([27])}
                    fillRule="evenodd"
                />
                <rect
                    name={'28_28'}
                    width={72}
                    height={48}
                    x={62 + 72 * (27 % 3) + (27 % 3)}
                    y={50 + 48 * Math.floor(27 / 3) + Math.floor(27 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([28])}
                    fillRule="evenodd"
                />
                <rect
                    name={'29_29'}
                    width={72}
                    height={48}
                    x={62 + 72 * (28 % 3) + (28 % 3)}
                    y={50 + 48 * Math.floor(28 / 3) + Math.floor(28 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([29])}
                    fillRule="evenodd"
                />
                <rect
                    name={'30_30'}
                    width={72}
                    height={48}
                    x={62 + 72 * (29 % 3) + (29 % 3)}
                    y={50 + 48 * Math.floor(29 / 3) + Math.floor(29 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([30])}
                    fillRule="evenodd"
                />
                <rect
                    name={'31_31'}
                    width={72}
                    height={48}
                    x={62 + 72 * (30 % 3) + (30 % 3)}
                    y={50 + 48 * Math.floor(30 / 3) + Math.floor(30 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([31])}
                    fillRule="evenodd"
                />
                <rect
                    name={'32_32'}
                    width={72}
                    height={48}
                    x={62 + 72 * (31 % 3) + (31 % 3)}
                    y={50 + 48 * Math.floor(31 / 3) + Math.floor(31 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([32])}
                    fillRule="evenodd"
                />
                <rect
                    name={'33_33'}
                    width={72}
                    height={48}
                    x={62 + 72 * (32 % 3) + (32 % 3)}
                    y={50 + 48 * Math.floor(32 / 3) + Math.floor(32 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([33])}
                    fillRule="evenodd"
                />
                <rect
                    name={'34_34'}
                    width={72}
                    height={48}
                    x={62 + 72 * (33 % 3) + (33 % 3)}
                    y={50 + 48 * Math.floor(33 / 3) + Math.floor(33 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([34])}
                    fillRule="evenodd"
                />
                <rect
                    name={'35_35'}
                    width={72}
                    height={48}
                    x={62 + 72 * (34 % 3) + (34 % 3)}
                    y={50 + 48 * Math.floor(34 / 3) + Math.floor(34 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([35])}
                    fillRule="evenodd"
                />
                <rect
                    name={'36_36'}
                    width={72}
                    height={48}
                    x={62 + 72 * (35 % 3) + (35 % 3)}
                    y={50 + 48 * Math.floor(35 / 3) + Math.floor(35 / 3)}
                    fill={fillVal}
                    fillOpacity={getOpacity([36])}
                    fillRule="evenodd"
                />
                {/* split */}
                <rect
                    name="37_0,1"
                    width={72}
                    height={24}
                    x={62}
                    y={38}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="38_0,2"
                    width={72}
                    height={24}
                    x={135}
                    y={38}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="39_0,3"
                    width={72}
                    height={24}
                    x={208}
                    y={38}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="40_1,2"
                    width={36}
                    height={48}
                    x={117}
                    y={50}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="41_1,4"
                    width={72}
                    height={24}
                    x={62}
                    y={87}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="42_2,3"
                    width={36}
                    height={48}
                    x={189}
                    y={50}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="43_2,5"
                    width={72}
                    height={24}
                    x={135}
                    y={87}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="44_3,6"
                    width={72}
                    height={24}
                    x={208}
                    y={87}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="45_4,5"
                    width={36}
                    height={48}
                    x={117}
                    y={99}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="46_4,7"
                    width={72}
                    height={24}
                    x={62}
                    y={136}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="47_5,6"
                    width={36}
                    height={48}
                    x={189}
                    y={99}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="48_5,8"
                    width={72}
                    height={24}
                    x={135}
                    y={136}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="49_6,9"
                    width={72}
                    height={24}
                    x={208}
                    y={136}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="50_7,8"
                    width={36}
                    height={48}
                    x={117}
                    y={148}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="51_7,10"
                    width={72}
                    height={24}
                    x={62}
                    y={185}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="52_8,9"
                    width={36}
                    height={48}
                    x={189}
                    y={148}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="53_8,11"
                    width={72}
                    height={24}
                    x={135}
                    y={185}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="54_9,12"
                    width={72}
                    height={24}
                    x={208}
                    y={185}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="55_10,11"
                    width={36}
                    height={48}
                    x={117}
                    y={197}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="56_10,13"
                    width={72}
                    height={24}
                    x={62}
                    y={234}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="57_11,12"
                    width={36}
                    height={48}
                    x={189}
                    y={197}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="58_11,14"
                    width={72}
                    height={24}
                    x={135}
                    y={234}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="59_12,15"
                    width={72}
                    height={24}
                    x={208}
                    y={234}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="60_13,14"
                    width={36}
                    height={48}
                    x={117}
                    y={246}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="61_13,16"
                    width={72}
                    height={24}
                    x={62}
                    y={283}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="62_14,15"
                    width={36}
                    height={48}
                    x={189}
                    y={246}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="63_14,17"
                    width={72}
                    height={24}
                    x={135}
                    y={283}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="64_15,18"
                    width={72}
                    height={24}
                    x={208}
                    y={283}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="65_16,17"
                    width={36}
                    height={48}
                    x={117}
                    y={295}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="66_16,19"
                    width={72}
                    height={24}
                    x={62}
                    y={332}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="67_17,18"
                    width={36}
                    height={48}
                    x={189}
                    y={295}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="68_17,20"
                    width={72}
                    height={24}
                    x={135}
                    y={332}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="69_18,21"
                    width={72}
                    height={24}
                    x={208}
                    y={332}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="70_19,20"
                    width={36}
                    height={48}
                    x={117}
                    y={344}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="71_19,22"
                    width={72}
                    height={24}
                    x={62}
                    y={381}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="72_20,21"
                    width={36}
                    height={48}
                    x={189}
                    y={344}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="73_20,23"
                    width={72}
                    height={24}
                    x={135}
                    y={381}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="74_21,24"
                    width={72}
                    height={24}
                    x={208}
                    y={381}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="75_22,23"
                    width={36}
                    height={48}
                    x={117}
                    y={393}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="76_22,25"
                    width={72}
                    height={24}
                    x={62}
                    y={430}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="77_23,24"
                    width={36}
                    height={48}
                    x={189}
                    y={393}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="78_23,26"
                    width={72}
                    height={24}
                    x={135}
                    y={430}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="79_24,27"
                    width={72}
                    height={24}
                    x={208}
                    y={430}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="80_25,26"
                    width={36}
                    height={48}
                    x={117}
                    y={442}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="81_25,28"
                    width={72}
                    height={24}
                    x={62}
                    y={479}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="82_26,27"
                    width={36}
                    height={48}
                    x={189}
                    y={442}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="83_26,29"
                    width={72}
                    height={24}
                    x={135}
                    y={479}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="84_27,30"
                    width={72}
                    height={24}
                    x={208}
                    y={479}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="85_28,29"
                    width={36}
                    height={48}
                    x={117}
                    y={491}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="86_28,31"
                    width={72}
                    height={24}
                    x={62}
                    y={528}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="87_29,30"
                    width={36}
                    height={48}
                    x={189}
                    y={491}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="88_29,32"
                    width={72}
                    height={24}
                    x={135}
                    y={528}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="89_30,33"
                    width={72}
                    height={24}
                    x={208}
                    y={528}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="90_31,32"
                    width={36}
                    height={48}
                    x={117}
                    y={540}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="91_31,34"
                    width={72}
                    height={24}
                    x={62}
                    y={577}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="92_32,33"
                    width={36}
                    height={48}
                    x={189}
                    y={540}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="93_32,35"
                    width={72}
                    height={24}
                    x={135}
                    y={577}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="94_33,36"
                    width={72}
                    height={24}
                    x={208}
                    y={577}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="95_34,35"
                    width={36}
                    height={48}
                    x={117}
                    y={589}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="96_35,36"
                    width={36}
                    height={48}
                    x={189}
                    y={589}
                    fill={fillVal}
                    fillOpacity={0}
                />
                {/* Trio */}
                <rect
                    name="97_0,1,2"
                    width={36}
                    height={24}
                    x={117}
                    y={38}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="98_0,2,3"
                    width={36}
                    height={24}
                    x={189}
                    y={38}
                    fill={fillVal}
                    fillOpacity={0}
                />
                {/* Line */}
                <rect
                    name="99_1,2,3"
                    width={36}
                    height={48}
                    x={262}
                    y={50}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="100_4,5,6"
                    width={36}
                    height={48}
                    x={262}
                    y={99}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="101_7,8,9"
                    width={36}
                    height={48}
                    x={262}
                    y={148}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="102_10,11,12"
                    width={36}
                    height={48}
                    x={262}
                    y={197}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="103_13,14,15"
                    width={36}
                    height={48}
                    x={262}
                    y={246}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="104_16,17,18"
                    width={36}
                    height={48}
                    x={262}
                    y={295}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="105_19,20,21"
                    width={36}
                    height={48}
                    x={262}
                    y={344}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="106_22,23,24"
                    width={36}
                    height={48}
                    x={262}
                    y={393}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="107_25,26,27"
                    width={36}
                    height={48}
                    x={262}
                    y={442}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="108_28,29,30"
                    width={36}
                    height={48}
                    x={262}
                    y={491}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="109_31,32,33"
                    width={36}
                    height={48}
                    x={262}
                    y={540}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="110_34,35,36"
                    width={36}
                    height={48}
                    x={262}
                    y={589}
                    fill={fillVal}
                    fillOpacity={0}
                />
                {/* Corner */}
                <rect
                    name="111_1,2,4,5"
                    width={36}
                    height={24}
                    x={117}
                    y={87}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="112_2,3,5,6"
                    width={36}
                    height={24}
                    x={189}
                    y={87}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="113_4,5,7,8"
                    width={36}
                    height={24}
                    x={117}
                    y={136}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="114_5,6,8,9"
                    width={36}
                    height={24}
                    x={189}
                    y={136}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="115_7,8,10,11"
                    width={36}
                    height={24}
                    x={117}
                    y={185}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="116_8,9,11,12"
                    width={36}
                    height={24}
                    x={189}
                    y={185}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="117_10,11,13,14"
                    width={36}
                    height={24}
                    x={117}
                    y={234}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="118_11,12,14,15"
                    width={36}
                    height={24}
                    x={189}
                    y={234}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="119_13,14,16,17"
                    width={36}
                    height={24}
                    x={117}
                    y={283}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="120_14,15,17,18"
                    width={36}
                    height={24}
                    x={189}
                    y={283}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="121_16,17,19,20"
                    width={36}
                    height={24}
                    x={117}
                    y={332}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="122_17,18,20,21"
                    width={36}
                    height={24}
                    x={189}
                    y={332}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="123_19,20,22,23"
                    width={36}
                    height={24}
                    x={117}
                    y={381}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="124_20,21,23,24"
                    width={36}
                    height={24}
                    x={189}
                    y={381}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="125_22,23,25,26"
                    width={36}
                    height={24}
                    x={117}
                    y={430}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="126_23,24,26,27"
                    width={36}
                    height={24}
                    x={189}
                    y={430}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="127_25,26,28,29"
                    width={36}
                    height={24}
                    x={117}
                    y={479}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="128_26,27,29,30"
                    width={36}
                    height={24}
                    x={189}
                    y={479}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="129_28,29,31,32"
                    width={36}
                    height={24}
                    x={117}
                    y={528}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="130_29,30,32,33"
                    width={36}
                    height={24}
                    x={189}
                    y={528}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="131_31,32,34,35"
                    width={36}
                    height={24}
                    x={117}
                    y={577}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="132_32,33,35,36"
                    width={36}
                    height={24}
                    x={189}
                    y={577}
                    fill={fillVal}
                    fillOpacity={0}
                />
                {/* 2 Line */}
                <rect
                    name="133_1,2,3,4,5,6"
                    width={36}
                    height={24}
                    x={262}
                    y={87}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="134_4,5,6,7,8,9"
                    width={36}
                    height={24}
                    x={262}
                    y={136}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="135_7,8,9,10,11,12"
                    width={36}
                    height={24}
                    x={262}
                    y={185}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="136_10,11,12,13,14,15"
                    width={36}
                    height={24}
                    x={262}
                    y={234}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="137_13,14,15,16,17,18"
                    width={36}
                    height={24}
                    x={262}
                    y={283}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="138_16,17,18,19,20,21"
                    width={36}
                    height={24}
                    x={262}
                    y={332}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="139_19,20,21,22,23,24"
                    width={36}
                    height={24}
                    x={262}
                    y={381}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="140_22,23,24,25,26,27"
                    width={36}
                    height={24}
                    x={262}
                    y={430}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="141_25,26,27,28,29,30"
                    width={36}
                    height={24}
                    x={262}
                    y={479}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="142_28,29,30,31,32,33"
                    width={36}
                    height={24}
                    x={262}
                    y={528}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="143_31,32,33,34,35,36"
                    width={36}
                    height={24}
                    x={262}
                    y={577}
                    fill={fillVal}
                    fillOpacity={0}
                />
                <rect
                    name="156_0,1,2,3"
                    width={36}
                    height={24}
                    x={262}
                    y={38}
                    fill={fillVal}
                    fillOpacity={0}
                />
            </svg>
        </>
    );
};
