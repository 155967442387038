import { BetType as BetTypeEnum } from '../../../../../models/games/roulette/BetType';
import { Bet } from '../../../../../models/host/BetAmount';
import store from '../../../../../store/store';
import { GAME_KEY } from '../../../../games/slice';
import { PHOST_KEY } from '../../../../host/slice';

const calculateBetAmount = (
    bets: Array<Bet>,
    matchBetTypes: number
): number => {
    let amount = 0;
    amount = bets
        .filter(item => item.Type == matchBetTypes)
        .reduce((sum, bet) => sum + bet.Amount, 0);
    return amount;
};

const getTotalBet = (betType: BetTypeEnum) => {
    const { pendingBets, confirmedBets } = store.getState()[GAME_KEY];
    return (
        calculateBetAmount(pendingBets, betType) +
        calculateBetAmount(confirmedBets, betType)
    );
};

const getBetLimitByBetType = (betType: number, hostId: number): number => {
    const host = store.getState()[PHOST_KEY].entities[hostId];
    let limit = 0;
    if (host && host.MaxBet) {
        const maxBet = host.MaxBet.find(mb => mb.BetType === betType);
        if (maxBet) {
            return maxBet.MaxBet;
        }
    }
    return limit;
};

export const amountOverBetLimit = (
    betType: number,
    hostId: number,
    chipAmount: number
): number => {
    let amount = 0;
    amount =
        chipAmount +
        getTotalBet(betType) -
        getBetLimitByBetType(betType, hostId);

    return amount;
};

export const isOppositeBetting = (betType: number): boolean => {
    let opposite: Array<number>;
    switch (betType) {
        case BetTypeEnum.RB19To36:
            opposite = [BetTypeEnum.RB1To18];
            break;
        case BetTypeEnum.RB1To18:
            opposite = [BetTypeEnum.RB19To36];
            break;
        case BetTypeEnum.RBRed:
            opposite = [BetTypeEnum.RBBlack];
            break;
        case BetTypeEnum.RBBlack:
            opposite = [BetTypeEnum.RBRed];
            break;
        case BetTypeEnum.RBOdd:
            opposite = [BetTypeEnum.RBEven];
            break;
        case BetTypeEnum.RBEven:
            opposite = [BetTypeEnum.RBOdd];
            break;
        case BetTypeEnum.RBRow1:
            opposite = [BetTypeEnum.RBRow2, BetTypeEnum.RBRow3];
            break;
        case BetTypeEnum.RBRow2:
            opposite = [BetTypeEnum.RBRow1, BetTypeEnum.RBRow3];
            break;
        case BetTypeEnum.RBRow3:
            opposite = [BetTypeEnum.RBRow1, BetTypeEnum.RBRow2];
            break;
        case BetTypeEnum.RBSet1:
            opposite = [BetTypeEnum.RBSet2, BetTypeEnum.RBSet3];
            break;
        case BetTypeEnum.RBSet2:
            opposite = [BetTypeEnum.RBSet1, BetTypeEnum.RBSet3];
            break;
        case BetTypeEnum.RBSet3:
            opposite = [BetTypeEnum.RBSet1, BetTypeEnum.RBSet2];
            break;
        default:
            opposite = [];
            break;
    }
    if (opposite.length == 0) {
        return false;
    } else {
        let haveBet = 0;
        for (let i = 0; i < opposite.length; i++) {
            if (getTotalBet(opposite[i]) > 0) {
                haveBet++;
            }
        }
        return haveBet === opposite.length;
    }
};
