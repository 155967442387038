import { Button, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseImage from '../../../../components/common/baseImage';
import { BaseTypography } from '../../../../components/common/baseText/BaseTypography';
import { RootState } from '../../../../store/store';
import ShuffleLabel from '../../../game/components/ShuffleLabel';
import TrialImage from '../../../game/components/TrialImage';
import { ROAD_MAP_COLORS, ROAD_MAP_TYPE } from '../../../lobby/constants';
import { getRoadmap } from '../../slice';
import BeadRoadCell from '../cells/BeadRoadCell';
import BigEyeCell from '../cells/BigEyeCell';
import BigRoadCell from '../cells/BigRoadCell';
import CockroachRoadCell from '../cells/CockroachRoadCell';
import PointRoadCell from '../cells/PointRoadCell';
import SmallRoadCell from '../cells/SmallRoadCell';
import { RESOLUTION, STROKE_WIDTH, useRoadMapState } from '../hook';
import {
    getAskIdx,
    // getMappedRoad,
    getNextRoad,
    getViewBeadRoadRecord,
    getViewRoadRecord,
} from '../util';
import { BaccaratRoadMapProps } from './index';
import BigEyeAnimatedCell from '../cells/BigEyeAnimatedCell';
import CockroachRoadAnimatedCell from '../cells/CockroachRoadAnimatedCell';
import SmallRoadAnimatedCell from '../cells/SmallRoadAnimatedCell';
import { getHostById } from '../../../host/slice';
import { GameState } from '../../../../models/games/enums/GameState';
type getColorProps = {
    nextRoad: number;
    isBanker: boolean;
};
const getColor = ({ nextRoad, isBanker }: getColorProps) => {
    let color: string;
    if (nextRoad == 0) {
        color = isBanker ? ROAD_MAP_COLORS.BLUE : ROAD_MAP_COLORS.RED;
    } else {
        color = isBanker ? ROAD_MAP_COLORS.RED : ROAD_MAP_COLORS.BLUE;
    }
    return color;
};

const BigEyeRoad = (props: getColorProps) => {
    const { nextRoad } = props;
    const color = getColor(props);
    return (
        <>
            {nextRoad >= 0 ? (
                <svg viewBox="0 0 12 12" className="roadmap-cell">
                    <circle
                        cx={RESOLUTION / 2}
                        cy={RESOLUTION / 2}
                        r={RESOLUTION / 2 - STROKE_WIDTH / 2}
                        fill="transparent"
                        stroke={color}
                        strokeWidth={STROKE_WIDTH}
                    />
                </svg>
            ) : (
                <svg viewBox="0 0 12 12" className="roadmap-cell"></svg>
            )}
        </>
    );
};

const SmallRoad = (props: getColorProps) => {
    const { nextRoad } = props;
    const color = getColor(props);
    return (
        <>
            {nextRoad >= 0 ? (
                <svg viewBox="0 0 12 12" className="roadmap-cell">
                    <circle
                        cx={RESOLUTION / 2}
                        cy={RESOLUTION / 2}
                        r={RESOLUTION / 2}
                        fill={color}
                    />
                </svg>
            ) : (
                <svg viewBox="0 0 12 12" className="roadmap-cell"></svg>
            )}
        </>
    );
};

const CockroachRoad = (props: getColorProps) => {
    const { nextRoad } = props;
    const color = getColor(props);
    return (
        <>
            {nextRoad >= 0 ? (
                <svg viewBox="0 0 12 12" className="roadmap-cell">
                    <line
                        x1={2}
                        y1={RESOLUTION - 2}
                        x2={RESOLUTION - 2}
                        y2={2}
                        stroke={color}
                        strokeWidth={STROKE_WIDTH}
                    />
                </svg>
            ) : (
                <svg viewBox="0 0 12 12" className="roadmap-cell"></svg>
            )}
        </>
    );
};
type AskRoadButtonProps = {
    isBanker: boolean;
    btnText: string;
    getBigEyeNextRoad: number;
    getSmallNextRoad: number;
    getCockroachNextRoad: number;
};
export const AskRoadButton = (props: AskRoadButtonProps) => {
    const { t, i18n } = useTranslation();
    const [languageDir, setLanguageDir] = useState<string>('ltr');
    useEffect(() => {
        setLanguageDir(i18n.dir(i18n.language));
    }, [i18n.language]);

    const {
        isBanker,
        btnText,
        getBigEyeNextRoad,
        getSmallNextRoad,
        getCockroachNextRoad,
    } = props;
    return (
        <>
            <Stack
                display="flex"
                direction="row"
                flexWrap={'nowrap'}
                className="askBtnGrp"
            >
                {isBanker ? (
                    <Stack
                        alignContent="center"
                        justifyContent="center"
                        alignItems="center"
                        className="askBanker"
                        fontSize={'8px'}
                    >
                        <BaseTypography
                            fontSize={12}
                            color={'white'}
                            resize={{
                                direction: 'horizontal',
                                value: '60px',
                            }}
                            sx={{
                                direction: languageDir,
                            }}
                        >
                            {t(btnText)}
                        </BaseTypography>
                    </Stack>
                ) : (
                    <Stack
                        alignContent="center"
                        justifyContent="center"
                        alignItems="center"
                        className="askPlayer"
                    >
                        <BaseTypography
                            fontSize={12}
                            color={'white'}
                            resize={{
                                direction: 'horizontal',
                                value: '60px',
                            }}
                            sx={{
                                direction: languageDir,
                            }}
                        >
                            {t(btnText)}
                        </BaseTypography>
                    </Stack>
                )}
                <BigEyeRoad nextRoad={getBigEyeNextRoad} isBanker={isBanker} />
                <SmallRoad nextRoad={getSmallNextRoad} isBanker={isBanker} />
                <CockroachRoad
                    nextRoad={getCockroachNextRoad}
                    isBanker={isBanker}
                />
            </Stack>
        </>
    );
};

export const BaccaratInGameMap = (props: BaccaratRoadMapProps) => {
    const { hostId, roadMapTypeSelected } = props;
    const { t } = useTranslation();
    const {
        Bead,
        Other,
        BigRoad,
        BigEyeRoad,
        SmallRoad,
        BeadRoad,
        CockroachRoad,
        Summary,
    } = useSelector((state: RootState) => getRoadmap(state, hostId));
    const { CurrentState } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [showRoadMap, setShowRoadMap] = useState<boolean>(true);
    const { askType, setAskType, IsRest } = useRoadMapState(hostId);
    const [bigEyeNextRoad, setBigEyeNextRoad] = useState(0);
    const [smallNextRoad, setSmallNextRoad] = useState(0);
    const [cockroachNextRoad, setCockroachNextRoad] = useState(0);
    const askRoadTimeout = useRef<NodeJS.Timeout>();

    useEffect(() => {
        const ber = getNextRoad(Bead, Other, ROAD_MAP_TYPE.BIG_EYE_ROAD);
        const sr = getNextRoad(Bead, Other, ROAD_MAP_TYPE.SMALL_ROAD);
        const cr = getNextRoad(Bead, Other, ROAD_MAP_TYPE.COCKROACH_ROAD);

        setBigEyeNextRoad(ber);
        setSmallNextRoad(sr);
        setCockroachNextRoad(cr);
    }, [Bead, Other]);
    const {
        bankerTotal,
        playerTotal,
        tieTotal,
        bankerPairTotal,
        playerPairTotal,
    } = Summary;

    // ui
    const [beadCells, setBeadCells] = useState<Array<JSX.Element>>([]);
    const [bigCells, setBigCells] = useState<Array<JSX.Element>>([]);
    const [bigEyeCells, setBigEyeCells] = useState<Array<JSX.Element>>([]);
    const [smallCells, setSmallCells] = useState<Array<JSX.Element>>([]);
    const [cockroachCells, setCockroachCells] = useState<Array<JSX.Element>>(
        []
    );
    useEffect(() => {
        setShowRoadMap(!IsRest && CurrentState !== GameState.Shuffle);
    }, [IsRest, CurrentState]);
    useEffect(() => {
        if (showRoadMap) {
            const list = getViewBeadRoadRecord(BeadRoad, 8);
            const bc = list?.map((record, idx) => {
                const key = `bead-road-cell-${idx}`;
                return roadMapTypeSelected === ROAD_MAP_TYPE.POINT_ROAD ? (
                    <PointRoadCell record={record} key={key} />
                ) : (
                    <BeadRoadCell record={record} key={key} />
                );
            });
            if (askType !== -1) {
                const key = `bead-road-cell-${1000 + askType}`;
                bc.push(
                    roadMapTypeSelected === ROAD_MAP_TYPE.POINT_ROAD ? (
                        <PointRoadCell
                            record={{
                                Win: -1,
                                BankerPair: false,
                                PlayerPair: false,
                                WinPoint: -1,
                                AskType: askType,
                            }}
                            key={key}
                        />
                    ) : (
                        <BeadRoadCell
                            record={{
                                Win: -1,
                                BankerPair: false,
                                PlayerPair: false,
                                WinPoint: -1,
                                AskType: askType,
                            }}
                            key={key}
                        />
                    )
                );
            }
            setBeadCells(bc);
        } else {
            setBeadCells([]);
        }
    }, [BeadRoad, showRoadMap, roadMapTypeSelected, askType]);
    useEffect(() => {
        if (showRoadMap) {
            const list = getViewRoadRecord(BigRoad, 20);
            let askIdx = getAskIdx(list, askType);
            let toFill = 0;

            if (askIdx >= list.length) {
                toFill = askIdx - list.length + 1;
            }

            const bc = list?.map((record, idx) => {
                const key = `big-road-cell-${idx}`;
                if (askIdx === idx) {
                    return (
                        <BigRoadCell
                            record={{ ...record, AskType: askType }}
                            key={key}
                        />
                    );
                } else {
                    return <BigRoadCell record={record} key={key} />;
                }
            });

            for (let i = 0; i < toFill; i++) {
                const key = `big-road-cell-${bc.length}`;

                bc.push(
                    <BigRoadCell
                        record={{
                            Win: -1,
                            BankerPair: false,
                            PlayerPair: false,
                            WinPoint: -1,
                            AskType: bc.length == askIdx ? askType : -1,
                        }}
                        key={key}
                    />
                );
            }
            setBigCells(bc);
        } else {
            setBigCells([]);
        }
    }, [BigRoad, showRoadMap, askType]);
    useEffect(() => {
        if (showRoadMap) {
            const list = getViewRoadRecord(BigEyeRoad, 43);
            let color = -1;

            if (bigEyeNextRoad == 0) {
                color = askType == 0 ? 1 : 0;
            } else if (bigEyeNextRoad == 1) {
                color = askType == 0 ? 0 : 1;
            }
            const askIdx = askType < 0 ? -1 : getAskIdx(list, color);
            let toFill = 0;

            if (askIdx >= list.length) {
                toFill = askIdx - list.length + 1;
            }
            const bc = list?.map((record, idx) => {
                const key = `big-eye-cell-${idx}`;
                if (askIdx === idx) {
                    return <BigEyeAnimatedCell win={color} key={key} />;
                } else {
                    return <BigEyeCell win={record.Win} key={key} />;
                }
            });
            for (let i = 0; i < toFill; i++) {
                const key = `big-eye-cell-${bc.length}`;
                bc.push(
                    bc.length == askIdx ? (
                        <BigEyeAnimatedCell win={color} key={key} />
                    ) : (
                        <BigEyeCell win={-1} key={key} />
                    )
                );
            }
            setBigEyeCells(bc);
        } else {
            setBigEyeCells([]);
        }
    }, [BigEyeRoad, showRoadMap, askType]);
    useEffect(() => {
        if (showRoadMap) {
            const list = getViewRoadRecord(SmallRoad, 20);
            let color = -1;

            if (smallNextRoad == 0) {
                color = askType == 0 ? 1 : 0;
            } else if (smallNextRoad == 1) {
                color = askType == 0 ? 0 : 1;
            }
            const askIdx = askType < 0 ? -1 : getAskIdx(list, color);
            let toFill = 0;

            if (askIdx >= list.length) {
                toFill = askIdx - list.length + 1;
            }
            const sc = list?.map((record, idx) => {
                const key = `small-road-cell-${idx}`;
                if (askIdx === idx) {
                    return <SmallRoadAnimatedCell win={color} key={key} />;
                } else {
                    return <SmallRoadCell win={record.Win} key={key} />;
                }
            });
            for (let i = 0; i < toFill; i++) {
                const key = `small-road-cell-${sc.length}`;
                sc.push(
                    sc.length == askIdx ? (
                        <SmallRoadAnimatedCell win={color} key={key} />
                    ) : (
                        <SmallRoadCell win={-1} key={key} />
                    )
                );
            }
            setSmallCells(sc);
        } else {
            setSmallCells([]);
        }
    }, [SmallRoad, showRoadMap, askType]);
    useEffect(() => {
        if (showRoadMap) {
            const list = getViewRoadRecord(CockroachRoad, 20);
            let color = -1;

            if (cockroachNextRoad == 0) {
                color = askType == 0 ? 1 : 0;
            } else if (cockroachNextRoad == 1) {
                color = askType == 0 ? 0 : 1;
            }
            const askIdx = askType < 0 ? -1 : getAskIdx(list, color);
            let toFill = 0;

            if (askIdx >= list.length) {
                toFill = askIdx - list.length + 1;
            }
            const cc = list?.map((record, idx) => {
                const key = `cockroach-road-cell-${idx}`;
                if (askIdx === idx) {
                    return <CockroachRoadAnimatedCell win={color} key={key} />;
                } else {
                    return <CockroachRoadCell win={record.Win} key={key} />;
                }
            });
            for (let i = 0; i < toFill; i++) {
                const key = `cockroach-road-cell-${cc.length}`;
                cc.push(
                    cc.length == askIdx ? (
                        <CockroachRoadAnimatedCell win={color} key={key} />
                    ) : (
                        <CockroachRoadCell win={-1} key={key} />
                    )
                );
            }
            setCockroachCells(cc);
        } else {
            setCockroachCells([]);
        }
    }, [CockroachRoad, showRoadMap, askType]);
    useEffect(() => {
        if (askRoadTimeout.current) {
            clearTimeout(askRoadTimeout.current);
        }
        askRoadTimeout.current = setTimeout(() => {
            setAskType(-1);
        }, 1000);
    }, [askType]);

    const onAskBanker = () => {
        setAskType(0);
    };

    const onAskPlayer = () => {
        setAskType(1);
    };

    return (
        <div className="BaccaratInGameMainContainer">
            <BaseImage
                className="tradbaccRoadmapBg bg_roadmap_bacc"
                scale={0.5}
            />
            <div className="bigRoad">{bigCells}</div>
            <div className="beadRoad">{beadCells}</div>
            <div className="bigEyeRoad">{bigEyeCells}</div>
            <div className="smallRoad">{smallCells}</div>
            <div className="cockroachRoad">{cockroachCells}</div>
            <div className="AskRoad">
                <div className="summaryList">
                    <div className="banker">{t('road.banker_win')}</div>
                    {bankerTotal}
                    <div className="player">{t('road.player_win')}</div>
                    {playerTotal}
                    <div className="tie">{t('road.draw')}</div>
                    {tieTotal}
                    <div className="banker">{t('road.banker_pair')}</div>
                    {bankerPairTotal}
                    <div className="player">{t('road.player_pair')}</div>
                    {playerPairTotal}
                </div>
                <div className="askBtn">
                    <Button onClick={onAskBanker} sx={{ padding: 0 }}>
                        <AskRoadButton
                            isBanker={true}
                            btnText={'road.ask_banker'}
                            getBigEyeNextRoad={bigEyeNextRoad}
                            getSmallNextRoad={smallNextRoad}
                            getCockroachNextRoad={cockroachNextRoad}
                        />
                    </Button>
                    <Button onClick={onAskPlayer} sx={{ padding: 0 }}>
                        <AskRoadButton
                            isBanker={false}
                            btnText={'road.ask_player'}
                            getBigEyeNextRoad={bigEyeNextRoad}
                            getSmallNextRoad={smallNextRoad}
                            getCockroachNextRoad={cockroachNextRoad}
                        />
                    </Button>
                </div>
            </div>
            <TrialImage />
            <ShuffleLabel hostId={hostId} />
        </div>
    );
};
