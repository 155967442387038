import { Grid } from '@mui/material';
import BetArea from '../../../../../../components/common/betArea';
import { BetType } from '../../../../../../models/games/baccarat/BetType';
import { amountOverBetLimit, isOppositeBetting } from '../utils';
import { getBetAreaProps } from './betAreaInfo';
const BB_SS_PLAYER_PAIR = getBetAreaProps(BetType.BBSSPlayerPair);
const BB_SS_PLAYER_NATURAL = getBetAreaProps(BetType.BBSSPlayerNatural);
const BB_SS_LUCKY_SIX = getBetAreaProps(BetType.BBSSSLuckySix);
const BB_SS_BANKER_NATURAL = getBetAreaProps(BetType.BBSSBankerNatural);
const BB_SS_BANKER_PAIR = getBetAreaProps(BetType.BBSSBankerPair);
const BB_SS_PLAYER_WIN = getBetAreaProps(BetType.BBSSPlayerWin);
const BB_SS_TIE = getBetAreaProps(BetType.BBSSTie);
const BB_SS_BANKER_WIN = getBetAreaProps(BetType.BBSSBankerWin);
export const SuperSixBetArea = () => (
    <>
        <Grid
            item
            container
            flexWrap={'nowrap'}
            justifyContent={'space-between'}
            sx={{
                marginTop: '2px',
                marginBottom: '3px',
                width: 540,
            }}
        >
            <BetArea
                style={BB_SS_PLAYER_PAIR.style}
                confirmPanelStyle={BB_SS_PLAYER_PAIR.confirmPanelStyle}
                imageName={BB_SS_PLAYER_PAIR.imageName}
                betType={BetType.BBSSPlayerPair}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                roundNumDisable={BB_SS_PLAYER_PAIR.roundNumDisable}
            />
            <BetArea
                style={BB_SS_PLAYER_NATURAL.style}
                confirmPanelStyle={BB_SS_PLAYER_NATURAL.confirmPanelStyle}
                imageName={BB_SS_PLAYER_NATURAL.imageName}
                betType={BetType.BBSSPlayerNatural}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                roundNumDisable={BB_SS_PLAYER_NATURAL.roundNumDisable}
            />
            <BetArea
                style={BB_SS_LUCKY_SIX.style}
                confirmPanelStyle={BB_SS_LUCKY_SIX.confirmPanelStyle}
                imageName={BB_SS_LUCKY_SIX.imageName}
                betType={BetType.BBSSSLuckySix}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                roundNumDisable={BB_SS_LUCKY_SIX.roundNumDisable}
            />
            <BetArea
                style={BB_SS_BANKER_NATURAL.style}
                confirmPanelStyle={BB_SS_BANKER_NATURAL.confirmPanelStyle}
                imageName={BB_SS_BANKER_NATURAL.imageName}
                betType={BetType.BBSSBankerNatural}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                roundNumDisable={BB_SS_BANKER_NATURAL.roundNumDisable}
            />
            <BetArea
                style={BB_SS_BANKER_PAIR.style}
                confirmPanelStyle={BB_SS_BANKER_PAIR.confirmPanelStyle}
                imageName={BB_SS_BANKER_PAIR.imageName}
                betType={BetType.BBSSBankerPair}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
                roundNumDisable={BB_SS_BANKER_PAIR.roundNumDisable}
            />
        </Grid>
        <Grid
            item
            container
            flexWrap={'nowrap'}
            justifyContent={'center'}
            width={540}
        >
            <BetArea
                style={BB_SS_PLAYER_WIN.style}
                confirmPanelStyle={BB_SS_PLAYER_WIN.confirmPanelStyle}
                imageName={BB_SS_PLAYER_WIN.imageName}
                betType={BetType.BBSSPlayerWin}
                isShowBetAmount={true}
                betTypeKey="Player"
                perColor={'92,126,255'}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
            />
            <BetArea
                style={BB_SS_TIE.style}
                confirmPanelStyle={BB_SS_TIE.confirmPanelStyle}
                imageName={BB_SS_TIE.imageName}
                betType={BetType.BBSSTie}
                isShowBetAmount={true}
                betTypeKey="Tie"
                perColor={'94,193,58'}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
            />
            <BetArea
                style={BB_SS_BANKER_WIN.style}
                confirmPanelStyle={BB_SS_BANKER_WIN.confirmPanelStyle}
                imageName={BB_SS_BANKER_WIN.imageName}
                betType={BetType.BBSSBankerWin}
                isShowBetAmount={true}
                betTypeKey="Banker"
                perColor={'231,51,35'}
                amountOverBetLimit={amountOverBetLimit}
                isOppositeBetting={isOppositeBetting}
            />
        </Grid>
    </>
);
