import { EntityState, PayloadAction } from '@reduxjs/toolkit';
import {
    BetPayload,
    BetRoom,
    BetRoomSeatIndex,
    SeatPayload,
    StandPayload,
    StandsPayload,
    getEmptyBetInfo,
    getNewBetRoom,
} from '../../../models/games/BetRoom';
import { Bet } from '../../../models/host/BetAmount';
import { betRoomAdapter } from './slice';

const reset = (
    state: EntityState<BetRoom, number>,
    action: PayloadAction<number>
) =>
    betRoomAdapter.updateOne(state, {
        id: action.payload,
        changes: getNewBetRoom(action.payload),
    });
const resetBet = (
    state: EntityState<BetRoom, number>,
    action: PayloadAction<number>
) => {
    const hostId = action.payload;
    const betRoom = state.entities[hostId] ?? getNewBetRoom(hostId);
    betRoom.Info[0].Bet = new Array<Bet>();
    for (let i = 1; i <= 7; i++) {
        const sn = i as BetRoomSeatIndex;
        betRoom.Info[sn].Bet = new Array<Bet>();
    }
    return betRoomAdapter.updateOne(state, {
        id: action.payload,
        changes: betRoom,
    });
};
const stand = (
    state: EntityState<BetRoom, number>,
    action: PayloadAction<StandPayload>
) => {
    const hostId = action.payload.HostId;
    const seat = (action.payload.Seat % 100) as BetRoomSeatIndex;
    let newRoom: BetRoom = getNewBetRoom(hostId);
    const oldRoom = state.entities[hostId];
    if (oldRoom) {
        newRoom.Info = oldRoom.Info;
        if (seat) newRoom.Info[seat] = getEmptyBetInfo();
    }
    return betRoomAdapter.updateOne(state, {
        id: hostId,
        changes: newRoom,
    });
};
const stands = (
    state: EntityState<BetRoom, number>,
    action: PayloadAction<StandsPayload>
) => {
    const hostId = action.payload.HostId;
    const seats = action.payload.Seats.map(s => (s % 100) as BetRoomSeatIndex);
    let newRoom: BetRoom = getNewBetRoom(hostId);
    const oldRoom = state.entities[hostId];
    if (oldRoom) {
        newRoom.Info = oldRoom.Info;
        for (const seat of seats)
            if (seat) newRoom.Info[seat] = getEmptyBetInfo();
    }
    return betRoomAdapter.updateOne(state, {
        id: hostId,
        changes: newRoom,
    });
};
const seat = (
    state: EntityState<BetRoom, number>,
    action: PayloadAction<SeatPayload>
) => {
    const hostId = action.payload.HostId;
    const playerId = action.payload.PlayerId;
    let newRoom: BetRoom = getNewBetRoom(hostId);
    const oldRoom = state.entities[hostId];
    if (oldRoom) {
        newRoom.Info = oldRoom.Info;
    }
    const sn = (action.payload.Seat % 100) as BetRoomSeatIndex;
    newRoom.Info[sn].PlayerId = playerId;
    return betRoomAdapter.updateOne(state, {
        id: hostId,
        changes: newRoom,
    });
};
const bet = (
    state: EntityState<BetRoom, number>,
    action: PayloadAction<BetPayload>
) => {
    const hostId = action.payload.HostId;
    const playerId = action.payload.PlayerId;
    const bets = action.payload.Bets;
    let newRoom: BetRoom = getNewBetRoom(hostId);
    const oldRoom = state.entities[hostId];
    if (oldRoom) {
        newRoom.Info = oldRoom.Info;
        const seat = (action.payload.Seat % 100) as BetRoomSeatIndex;
        newRoom.Info[seat] = {
            PlayerId: playerId,
            Bet: bets,
        };
    }
    return betRoomAdapter.updateOne(state, {
        id: hostId,
        changes: newRoom,
    });
};
export const betRoomReducer = {
    reset,
    resetBet,
    seat,
    bet,
    stand,
    stands,
};
