import { useSelector } from 'react-redux';
import { getHostById } from '../../../host/slice';
import { RootState } from '../../../../store/store';
import { useEffect, useState, memo } from 'react';
import { PokDengStatistics } from '../../../../models/games/pokdeng';
import { useTranslation } from 'react-i18next';
import { ROAD_MAP_COLORS } from '../../../lobby/constants';
import { Grid, Stack } from '@mui/material';
type Props = {
    hostId: number;
};
type InfoProps = {
    title: string;
    value: string | number;
};
type WinStatisticsType = [
    InfoProps,
    InfoProps,
    InfoProps,
    InfoProps,
    InfoProps,
];
const initWinStatisticsData = [
    {
        title: 'pokdeng.player.0',
        value: '',
    },
    {
        title: 'pokdeng.player.1',
        value: '',
    },
    {
        title: 'pokdeng.player.2',
        value: '',
    },
    {
        title: 'pokdeng.player.3',
        value: '',
    },
    {
        title: 'pokdeng.player.4',
        value: '',
    },
] as WinStatisticsType;
const PokDengStatisticPanel = ({ hostId }: Props) => {
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const [winStatistics, setWinStatistics] = useState<WinStatisticsType>(
        initWinStatisticsData
    );
    useEffect(() => {
        if (host.Statistics) {
            const statistics = host.Statistics as PokDengStatistics;

            winStatistics[0].value = statistics.player1WinCount;
            winStatistics[1].value = statistics.player2WinCount;
            winStatistics[2].value = statistics.player3WinCount;
            winStatistics[3].value = statistics.player4WinCount;
            winStatistics[4].value = statistics.player5WinCount;
            setWinStatistics(winStatistics);
        }
    }, [host.Statistics]);
    return (
        <Stack direction={'row'} gap={'10px'}>
            {winStatistics?.map((data, index) => (
                <PokDengStatisticsLabel
                    title={data.title}
                    value={data.value}
                    key={`pokdeng-statistic-${index}`}
                />
            ))}
        </Stack>
    );
};
export default memo(PokDengStatisticPanel);

const PokDengStatisticsLabel = ({ title, value }: InfoProps) => {
    const { t } = useTranslation();
    return (
        <Stack direction={'row'} gap={'10px'}>
            <Grid
                item
                sx={{
                    color: ROAD_MAP_COLORS.LIGHT_BLUE,
                    fontSize: '18px',
                    textAlign: 'center',
                }}
            >
                {t(title)}
            </Grid>
            <Grid
                item
                sx={{
                    color: ROAD_MAP_COLORS.WHITE,
                    fontSize: '18px',
                    textAlign: 'center',
                }}
            >
                {value ? value : 0}
            </Grid>
        </Stack>
    );
};
