import { Box, Grid, Typography } from '@mui/material';
import { motion, useAnimationControls } from 'framer-motion';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Chip } from '../../../../../../components/common/chips';
import { SeatAreaContext } from '../../../../../../contexts/SeatAreaContext';
import { useSeatBet } from '../../../../../../hooks/useSeatBet';
import { getDisplaySeat } from '../../../../../../utils/games/blackjack';
import { getMainUserState } from '../../../../../main/selector';
import { PlayerName } from '../PlayerName';
import { BetResultDisplay } from './BetResultDisplay';
import { DecisionSymbols } from './DecisionSymbols';
import './GamingSeatSymbol.scss';
import { PointDisplay } from './PointDisplay';
import { ISeatSymbolProps } from './SeatSymbol';
const DEFAULT_SYMBOL_OPACITY = 0.6;
export const DEFAULT_FLASH_ANIMATION = [
    0,
    DEFAULT_SYMBOL_OPACITY,
    0,
    DEFAULT_SYMBOL_OPACITY,
    0,
    DEFAULT_SYMBOL_OPACITY,
    0,
];
const UnseatedGamingSeatSymbol = () => (
    <svg
        className="gaming-seat-symbol__circle"
        xmlns="http://www.w3.org/2000/svg"
        width="62"
        height="50"
        viewBox="0 0 62 50"
        fill="none"
        style={{
            maxWidth: '55px',
            left: '10%',
            top: '-30%',
            position: 'absolute',
        }}
    >
        <ellipse
            cx="31.1452"
            cy="25.0275"
            rx="28.7123"
            ry="22.9699"
            fill="#0B4527"
            fillOpacity="0.1"
        />
        <path
            d="M31.1452 48.9973C47.3351 48.9973 60.8575 38.4624 60.8575 25.0275C60.8575 11.5926 47.3351 1.05762 31.1452 1.05762C14.9553 1.05762 1.43286 11.5926 1.43286 25.0275C1.43286 38.4624 14.9553 48.9973 31.1452 48.9973Z"
            stroke={'#BAA378'}
            strokeOpacity={DEFAULT_SYMBOL_OPACITY}
            strokeWidth="2"
        />
    </svg>
);
const BetBehindGamingSeatSymbol = ({ sn, c1, c2 }: ISeatSymbolProps) => {
    const {
        split1Amount,
        split2Amount,
        behindSplit1Amount,
        behindSplit2Amount,
        behindPairAmount,
    } = useSeatBet(sn);
    return (
        <>
            <svg
                className="gaming-seat-symbol__circle"
                width="70"
                height="55"
                viewBox="0 0 157 110"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <motion.path
                    animate={c2}
                    initial={{ opacity: 0 }}
                    d="M0 30.7528L33.3625 49.7987C33.3625 49.7987 47.1326 31.138 79.2743 31.138C111.416 31.138 124.619 49.4999 124.619 49.4999L157 31.9354C143.402 20.1075 116.205 -0.000106411 79.1181 0C42.0109 0.000106471 11.5381 21.2904 0 30.7528Z"
                    fill="#078743"
                    fillOpacity={DEFAULT_SYMBOL_OPACITY}
                />
                <motion.path
                    animate={c1}
                    initial={{ opacity: 0 }}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M79.1261 109.795C110.532 109.795 135.992 91.2611 135.992 68.3977C135.992 66.9123 135.885 65.445 135.675 64H22.5771C22.3675 65.445 22.26 66.9123 22.26 68.3977C22.26 91.2611 47.7198 109.795 79.1261 109.795Z"
                    fill="#078743"
                    fillOpacity={DEFAULT_SYMBOL_OPACITY}
                />

                <motion.path
                    animate={c1}
                    initial={{ opacity: 0 }}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M135.98 68H22.2593C22.7017 45.4144 47.9895 27.2046 79.1195 27.2046C110.25 27.2046 135.537 45.4144 135.98 68Z"
                    fill="#078743"
                    fillOpacity={DEFAULT_SYMBOL_OPACITY}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.9178 47.8322L3.72845 32.8813L0 30.7528C0.990017 29.9409 2.11944 29.0419 3.3805 28.0767C16.8163 17.7936 45.1949 9.73353e-05 79.1181 4.22347e-10C113.436 -9.84654e-05 139.285 17.217 153.728 29.1595C154.894 30.1231 155.985 31.0523 157 31.9354L153.24 33.9749L128.13 47.595C133.122 53.7533 135.986 60.9343 135.986 68.6023C135.986 91.4657 110.526 110 79.1195 110C47.7132 110 22.2534 91.4657 22.2534 68.6023C22.2534 61.0326 25.0442 53.9373 29.9178 47.8322ZM124.867 43.7873C125.101 44.0192 125.322 44.2426 125.528 44.4562L149.93 31.2199C135.602 19.6172 111.139 3.99991 79.1181 4C47.5366 4.00009 20.7546 20.0945 7.16003 30.2344L32.5261 44.7153C32.7432 44.4936 32.9754 44.2613 33.223 44.0198C35.1931 42.0977 38.1412 39.5802 42.1444 37.0768C50.178 32.0531 62.3829 27.138 79.2743 27.138C96.1537 27.138 108.22 31.9716 116.125 36.9304C120.063 39.4005 122.945 41.8863 124.867 43.7873ZM131.986 68.6023C131.986 78.4407 126.516 87.6961 116.976 94.6411C107.439 101.583 94.0682 106 79.1195 106C64.1708 106 50.7996 101.583 41.2633 94.6411C31.7232 87.6961 26.2534 78.4407 26.2534 68.6023C26.2534 58.764 31.7232 49.5086 41.2633 42.5636C50.7996 35.6213 64.1708 31.2046 79.1195 31.2046C94.0682 31.2046 107.439 35.6213 116.976 42.5636C126.516 49.5086 131.986 58.764 131.986 68.6023Z"
                    fill="#BAA378"
                    fillOpacity={DEFAULT_SYMBOL_OPACITY}
                />
            </svg>
            {behindPairAmount > 0 && (
                <Grid
                    justifyContent={'center'}
                    sx={{
                        position: 'absolute',
                        top: '-5px',
                        width: '30px',
                        left: '26px',
                    }}
                >
                    <Chip value={behindPairAmount} scale={0.2}></Chip>
                </Grid>
            )}
            <Grid
                container
                justifyContent={'space-around'}
                sx={{
                    position: 'absolute',
                    top: behindSplit1Amount === 0 ? 10 : 0,
                    width: '60px',
                    left: '-10px',
                }}
            >
                {split1Amount > 0 && <Chip value={split1Amount} scale={0.3} />}
                {split2Amount > 0 && <Chip value={split2Amount} scale={0.3} />}
            </Grid>
            <Grid
                container
                justifyContent={'space-around'}
                sx={{
                    position: 'absolute',
                    top: '26px',
                    width: '37px',
                    left: '7px',
                }}
            >
                {behindSplit1Amount > 0 && (
                    <Chip value={behindSplit1Amount} scale={0.2} />
                )}
                {behindSplit2Amount > 0 && (
                    <Chip value={behindSplit2Amount} scale={0.2} />
                )}
            </Grid>
        </>
    );
};
const SeatedGamingSeatSymbol = ({ sn, c1, c2 }: ISeatSymbolProps) => {
    const { showDecision, isUserTurn } = useContext(SeatAreaContext);
    const { split1Amount, split2Amount, pairAmount } = useSeatBet(sn);
    const isMakingDecision = showDecision && isUserTurn;
    return (
        <>
            <svg
                className="gaming-seat-symbol__circle"
                xmlns="http://www.w3.org/2000/svg"
                width="70"
                height="55"
                viewBox="0 0 157 110"
                fill="none"
            >
                <motion.path
                    animate={c2}
                    initial={{ opacity: 0 }}
                    d="M0 30.7528L33.3625 49.7987C33.3625 49.7987 47.1326 31.138 79.2743 31.138C111.416 31.138 124.619 49.4999 124.619 49.4999L157 31.9354C143.402 20.1075 116.205 -0.000106411 79.1181 0C42.0109 0.000106471 11.5381 21.2904 0 30.7528Z"
                    fill={isMakingDecision ? '#078743' : ''}
                    fillOpacity={DEFAULT_SYMBOL_OPACITY}
                />
                <motion.path
                    animate={c1}
                    initial={{ opacity: 0 }}
                    d="M135.986 68.6023C135.986 91.4657 110.526 110 79.1196 110C47.7133 110 22.2534 91.4657 22.2534 68.6023C22.2534 45.739 47.7133 27.2046 79.1196 27.2046C110.526 27.2046 135.986 45.739 135.986 68.6023Z"
                    fill={isMakingDecision ? '#078743' : ''}
                    fillOpacity={DEFAULT_SYMBOL_OPACITY}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.9178 47.8322L3.72845 32.8813L0 30.7528C0.990017 29.9409 2.11944 29.0419 3.3805 28.0767C16.8163 17.7936 45.1949 9.73353e-05 79.1181 4.22347e-10C113.436 -9.84654e-05 139.285 17.217 153.728 29.1595C154.894 30.1231 155.985 31.0523 157 31.9354L153.24 33.9749L128.13 47.595C133.122 53.7533 135.986 60.9343 135.986 68.6023C135.986 91.4657 110.526 110 79.1195 110C47.7132 110 22.2534 91.4657 22.2534 68.6023C22.2534 61.0326 25.0442 53.9373 29.9178 47.8322ZM124.867 43.7873C125.101 44.0192 125.322 44.2426 125.528 44.4562L149.93 31.2199C135.602 19.6172 111.139 3.99991 79.1181 4C47.5366 4.00009 20.7546 20.0945 7.16003 30.2344L32.5261 44.7153C32.7432 44.4936 32.9754 44.2613 33.223 44.0198C35.1931 42.0977 38.1412 39.5802 42.1444 37.0768C50.178 32.0531 62.3829 27.138 79.2743 27.138C96.1537 27.138 108.22 31.9716 116.125 36.9304C120.063 39.4005 122.945 41.8863 124.867 43.7873ZM131.986 68.6023C131.986 78.4407 126.516 87.6961 116.976 94.6411C107.439 101.583 94.0682 106 79.1195 106C64.1708 106 50.7996 101.583 41.2633 94.6411C31.7232 87.6961 26.2534 78.4407 26.2534 68.6023C26.2534 58.764 31.7232 49.5086 41.2633 42.5636C50.7996 35.6213 64.1708 31.2046 79.1195 31.2046C94.0682 31.2046 107.439 35.6213 116.976 42.5636C126.516 49.5086 131.986 58.764 131.986 68.6023Z"
                    fill="#BAA378"
                    fillOpacity={DEFAULT_SYMBOL_OPACITY}
                />
            </svg>
            {pairAmount > 0 && (
                <Grid
                    justifyContent={'center'}
                    sx={{
                        position: 'absolute',
                        top: '-5px',
                        width: '30px',
                        left: '26px',
                    }}
                >
                    <Chip value={pairAmount} scale={0.2}></Chip>
                </Grid>
            )}
            <Grid
                container
                justifyContent={'space-around'}
                sx={{
                    position: 'absolute',
                    top: 10,
                    width: '60px',
                    left: '-10px',
                }}
            >
                {split1Amount > 0 && <Chip value={split1Amount} scale={0.3} />}
                {split2Amount > 0 && <Chip value={split2Amount} scale={0.3} />}
            </Grid>
        </>
    );
};
export const GamingSeatSymbol = (props: ISeatSymbolProps) => {
    const { sn } = props;
    const { isBetBehind, hasBet, currentSeat, setSelectedSeat } =
        useContext(SeatAreaContext);
    const {
        split2Amount,
        hand1Point,
        hand2Point,
        player,
        isHand1,
        isHand2,
        isPairWin,
        isMainWin,
        isBJ,
    } = useSeatBet(sn);
    const { PlayerID } = useSelector(getMainUserState);
    const mainBetAnimationController = useAnimationControls();
    const pairBetAnimationController = useAnimationControls();

    useEffect(() => {
        if (isMainWin) {
            mainBetAnimationController.start({
                opacity: DEFAULT_FLASH_ANIMATION,
                transition: { duration: 3 },
                fill: 'white',
            });
        }
    }, [isMainWin]);
    useEffect(() => {
        if (isPairWin) {
            pairBetAnimationController.start({
                opacity: DEFAULT_FLASH_ANIMATION,
                transition: { duration: 3 },
                fill: 'white',
            });
        }
    }, [isPairWin]);
    useEffect(() => {
        console.log('blackjack::player', sn, player);
        if (player?.id === PlayerID) {
            setSelectedSeat(sn);
        }
    }, [player, PlayerID]);
    return (
        <div className={'gaming-seat-symbol'}>
            <div className="gaming-seat-symbol__seat">
                {player ? (
                    isBetBehind || !hasBet ? (
                        <BetBehindGamingSeatSymbol
                            sn={sn}
                            c1={mainBetAnimationController}
                            c2={pairBetAnimationController}
                        />
                    ) : (
                        <SeatedGamingSeatSymbol
                            sn={sn}
                            c1={mainBetAnimationController}
                            c2={pairBetAnimationController}
                        />
                    )
                ) : (
                    <UnseatedGamingSeatSymbol />
                )}

                <Typography
                    fontSize={30}
                    fontWeight={400}
                    color={'rgba(255, 255, 255, 0.60)'}
                    sx={{
                        position: 'absolute',
                        top: '9px',
                        pointerEvents: 'none',
                        left: '37%',
                    }}
                >
                    {getDisplaySeat(sn)}
                </Typography>
                <DecisionSymbols sn={sn} />
                {player && <PlayerName player={player} />}
                {player && (
                    <Box>
                        {hand1Point.length > 0 && (
                            <>
                                <PointDisplay
                                    points={hand1Point}
                                    isCurrentSeat={
                                        isHand1 && currentSeat === sn
                                    }
                                    leftHand={true}
                                    showArrow={split2Amount > 0}
                                    isBJ={isBJ}
                                />
                                <BetResultDisplay sn={sn} hand={1} />
                            </>
                        )}
                        {hand2Point.length > 0 && (
                            <>
                                <PointDisplay
                                    points={hand2Point}
                                    isCurrentSeat={
                                        isHand2 && currentSeat === sn
                                    }
                                    leftHand={false}
                                    showArrow={split2Amount > 0}
                                    isBJ={false}
                                />
                                <BetResultDisplay sn={sn} hand={2} />
                            </>
                        )}
                    </Box>
                )}
            </div>
        </div>
    );
};
