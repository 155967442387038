import { Grid } from '@mui/material';
import { SeatSymbol } from '../Seat/SeatSymbol';
import { getPopupState } from '../../../../../popup/selector';
import { useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { SeatAreaContext } from '../../../../../../contexts/SeatAreaContext';

export const BettingSeatArea = () => {
    const { isOpen, key } = useSelector(getPopupState);
    const { setSelectedSeat } = useContext(SeatAreaContext);
    useEffect(() => {
        if (isOpen && key === 'blackjack.force_stand') {
            setSelectedSeat(undefined);
        }
    }, [isOpen, key]);
    return (
        <>
            <Grid container>
                <Grid
                    item
                    sx={{
                        position: 'absolute',
                        left: '60px',
                        top: '5px',
                    }}
                >
                    <SeatSymbol sn={7} />
                </Grid>
                <Grid
                    item
                    sx={{
                        position: 'absolute',
                        left: '120px',
                        top: '36px',
                    }}
                >
                    <SeatSymbol sn={6} />
                </Grid>
                <Grid
                    item
                    sx={{
                        position: 'absolute',
                        left: '176px',
                        top: '57px',
                    }}
                >
                    <SeatSymbol sn={5} />
                </Grid>
                <Grid
                    item
                    sx={{
                        position: 'absolute',
                        left: '249px',
                        top: '63px',
                    }}
                >
                    <SeatSymbol sn={4} />
                </Grid>
                <Grid
                    item
                    sx={{
                        position: 'absolute',
                        left: '317px',
                        top: '57px',
                    }}
                >
                    <SeatSymbol sn={3} />
                </Grid>
                <Grid
                    item
                    sx={{
                        position: 'absolute',
                        left: '379px',
                        top: '36px',
                    }}
                >
                    <SeatSymbol sn={2} />
                </Grid>
                <Grid
                    item
                    sx={{
                        position: 'absolute',
                        left: '438px',
                        top: '5px',
                    }}
                >
                    <SeatSymbol sn={1} />
                </Grid>
            </Grid>
            <svg
                width="591"
                height="167"
                viewBox="0 0 1183 234"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="blackjack-mask betting"
            >
                <g filter="url(#filter0_f_747_209371)">
                    <path
                        d="M219 0L0 6.10352e-05L0.000152588 234L1183 234V0L979.499 6.10352e-05C965.999 6.10352e-05 979.499 182.5 596.499 182.5C218.999 182.5 249 0 219 0Z"
                        fill="url(#paint0_linear_747_209371)"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_f_747_209371"
                        x="-20"
                        y="-20"
                        width="1223"
                        height="274"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                        />
                        <feGaussianBlur
                            stdDeviation="10"
                            result="effect1_foregroundBlur_747_209371"
                        />
                    </filter>
                    <linearGradient
                        id="paint0_linear_747_209371"
                        x1="59.9961"
                        y1="0"
                        x2="59.9961"
                        y2="225.5"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopOpacity="0.01" />
                        <stop offset="0.363445" stopOpacity="0.3" />
                        <stop offset="0.536248" stopOpacity="0.39" />
                        <stop offset="0.969222" />
                    </linearGradient>
                </defs>
            </svg>
        </>
    );
};
