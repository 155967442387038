import './index.scss';
import { Chip as ChipPros } from '../../../../models/games/Chip';
import { BaseTypography } from '../../baseText/BaseTypography';
import { useEffect, useState } from 'react';

const PendingChip = (props: ChipPros) => {
    const { value, scale = 1 } = props;

    const [chip, setChip] = useState(<></>);

    useEffect(() => {
        const Chip = () => {
            return (
                <div
                    className="chip ic_casino_chip"
                    style={{
                        width: '130px',
                        height: '133px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        transform: `scale(${scale})`,
                    }}
                >
                    <BaseTypography
                        fontSize={46}
                        resize={{
                            direction: 'horizontal',
                            value: '80px',
                        }}
                    >
                        {(value / 100).toLocaleString('en-US')}
                    </BaseTypography>
                </div>
            );
        };
        setChip(value > 0 ? <Chip></Chip> : <></>);
    }, [value]);

    return chip;
};

export default PendingChip;
