import { useEffect, useMemo, useState } from 'react';
import BaseImage from '../../../../components/common/baseImage';
import BeadRoadCell from '../cells/BeadRoadCell';
import { useRoadMapState } from '../hook';
// svg

import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { getRoadmap } from '../../slice';
import BigEyeCell from '../cells/BigEyeCell';
import BigRoadCell from '../cells/BigRoadCell';
import CockroachRoadCell from '../cells/CockroachRoadCell';
import SmallRoadCell from '../cells/SmallRoadCell';
import { BaccaratRoadMapProps } from './index';
import { getViewBeadRoadRecord, getViewRoadRecord } from '../util';
import { getHostById } from '../../../host/slice';
import { GameState } from '../../../../models/games/enums/GameState';

export const BaccaratRoadMap = (props: BaccaratRoadMapProps) => {
    const { hostId } = props;
    const { isOneColumn, IsRest } = useRoadMapState(hostId);
    const { BigRoad, BigEyeRoad, SmallRoad, BeadRoad, CockroachRoad } =
        useSelector((state: RootState) => getRoadmap(state, hostId));
    const { CurrentState } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [showRoadMap, setShowRoadMap] = useState<boolean>(true);
    const [bigRoadCells, setBigRoadCells] = useState<Array<JSX.Element>>([]);
    const [beadCells, setBeadCells] = useState<Array<JSX.Element>>([]);
    const [bigEyeCells, setBigEyeCells] = useState<Array<JSX.Element>>([]);
    const [smallCells, setSmallCells] = useState<Array<JSX.Element>>([]);
    const [cockroachCells, setCockroachCells] = useState<Array<JSX.Element>>(
        []
    );
    const maxBeadGrid = useMemo(() => (isOneColumn ? 6 : 0), [isOneColumn]);
    const maxBigGrid = useMemo(() => (isOneColumn ? 21 : 15), [isOneColumn]);
    const maxBigEyeGrid = useMemo(() => (isOneColumn ? 43 : 0), [isOneColumn]);
    const maxSmallGrid = useMemo(() => (isOneColumn ? 20 : 0), [isOneColumn]);
    const maxCockroachGrid = useMemo(
        () => (isOneColumn ? 20 : 0),
        [isOneColumn]
    );
    const containerClass = useMemo(
        () =>
            isOneColumn
                ? 'one-column-main-container'
                : 'two-column-main-container',
        [isOneColumn]
    );
    const roadMapClass = useMemo(
        () => (isOneColumn ? 'road_viewlist_bacc' : 'road_viewColumn'),
        [isOneColumn]
    );
    useEffect(() => {
        setShowRoadMap(!IsRest && CurrentState !== GameState.Shuffle);
    }, [IsRest, CurrentState]);
    useEffect(() => {
        if (showRoadMap && maxBigGrid > 0) {
            const list = getViewRoadRecord(BigRoad, maxBigGrid);
            const brc = list?.map((record, idx) => {
                const key = `big-road-cell-${idx}`;
                return <BigRoadCell record={record} key={key} />;
            });
            setBigRoadCells(brc);
        } else {
            setBigRoadCells([]);
        }
    }, [BigRoad, showRoadMap, maxBigGrid]);
    useEffect(() => {
        if (showRoadMap && maxBeadGrid > 0) {
            const list = getViewBeadRoadRecord(BeadRoad, maxBeadGrid);
            const beadc = list.map((record, idx) => (
                <BeadRoadCell record={record} key={`bead-road-cell-${idx}`} />
            ));
            setBeadCells(beadc);
        } else {
            setBeadCells([]);
        }
    }, [BeadRoad, showRoadMap, maxBeadGrid]);
    useEffect(() => {
        if (showRoadMap && maxBigEyeGrid > 0) {
            const list = getViewRoadRecord(BigEyeRoad, maxBigEyeGrid);
            const bigEyec = list.map((record, idx) => {
                const key = `big-eye-cell-${idx}`;
                return <BigEyeCell win={record.Win} key={key} />;
            });
            setBigEyeCells(bigEyec);
        } else {
            setBigEyeCells([]);
        }
    }, [BigEyeRoad, showRoadMap, maxBigEyeGrid]);
    useEffect(() => {
        if (showRoadMap && maxSmallGrid) {
            const list = getViewRoadRecord(SmallRoad, maxSmallGrid);
            const smallc = list.map((record, idx) => {
                const key = `small-road-cell-${idx}`;
                return <SmallRoadCell win={record.Win} key={key} />;
            });

            setSmallCells(smallc);
        } else {
            setSmallCells([]);
        }
    }, [SmallRoad, showRoadMap, maxSmallGrid]);
    useEffect(() => {
        if (showRoadMap && maxCockroachGrid > 0) {
            const list = getViewRoadRecord(CockroachRoad, maxCockroachGrid);
            const cockroachc = list.map((record, idx) => {
                const key = `cockroach-road-cell-${idx}`;
                return <CockroachRoadCell win={record.Win} key={key} />;
            });
            setCockroachCells(cockroachc);
        } else {
            setCockroachCells([]);
        }
    }, [CockroachRoad, showRoadMap, maxCockroachGrid]);

    return (
        <div className={containerClass}>
            <BaseImage className={`lobby ${roadMapClass}`} scale={1} />
            <div className="bigRoad">{bigRoadCells}</div>
            {isOneColumn && (
                <>
                    <div className="beadRoad">{beadCells}</div>
                    <div className="bigEyeRoad">{bigEyeCells}</div>
                    <div className="smallRoad">{smallCells}</div>
                    <div className="cockroachRoad">{cockroachCells}</div>
                </>
            )}
        </div>
    );
};
