import { motion, useAnimationControls } from 'framer-motion';
// import { RESOLUTION, STROKE_WIDTH } from '../hook';
import { ROAD_MAP_COLORS } from '../../../lobby/constants';
import { memo, useEffect } from 'react';
// import { BigEyeBankerWin, BigEyePlayerWin } from '../svg';
type BigEyeCellProps = {
    win: number;
};
const BigEyeAnimatedCell = ({ win }: BigEyeCellProps) => {
    const askRoadAnimation = useAnimationControls();
    const anim = () => {
        askRoadAnimation.start({
            opacity: [1, 1, 0, 1, 1, 0],
            transition: { duration: 1 },
        });
    };
    useEffect(() => {
        anim();
    }, []);
    return (
        <svg viewBox="0 0 12 12" className="roadmap-cell">
            <motion.circle
                animate={askRoadAnimation}
                initial={{ opacity: 0 }}
                cx="6"
                cy="6"
                r="5.25"
                fill="transparent"
                stroke={win == 0 ? ROAD_MAP_COLORS.RED : ROAD_MAP_COLORS.BLUE}
                strokeWidth="1.5"
            />
        </svg>
    );
};
export default memo(BigEyeAnimatedCell);
