import { useTranslation } from 'react-i18next';
import { InGamePopupKeyType } from '../../../../hooks/useInGamePopup';
import { useContext, useEffect, useRef, useState } from 'react';
import { AudioPlayContext } from '../../../../contexts/AudioPlayContext';
import { Box, Stack, Typography } from '@mui/material';
import { LocalState as LocalStateEnum } from '../../../../models/games/enums/LocalState';

export const AndarBaharGameResultPopup = (props: InGamePopupKeyType) => {
    const { amount, message, audioKey, localState, playSound } = props;
    const { t } = useTranslation();
    const { audioPlay } = useContext(AudioPlayContext);
    const ref = useRef<boolean>(false); //防止play 2 次
    const [displayAmount, setDisplayAmount] = useState(false);

    const getBackgroundSvg = () => {
        let color;
        let endColor;
        switch (localState) {
            case LocalStateEnum.PLAYER:
                color = 'rgba(45,48,177,0.85)';
                endColor = 'rgba(45,48,177,0.2)';
                break;
            case LocalStateEnum.BANKER:
                color = 'rgba(217,22,22,0.85)';
                endColor = 'rgba(217,22,22,0.2)';
                break;
            case LocalStateEnum.IDLE:
            case LocalStateEnum.SHUFFLING:
                color = 'rgba(115, 95, 68, 1)';
                endColor = 'rgba(115, 95, 68,0.2)';
                break;
            default:
                color = 'rgba(0,0,0,0.85)';
                endColor = 'rgba(0,0,0,0.2)';
        }

        return getBg(color, endColor);
    };

    useEffect(() => {
        if (!ref.current) {
            ref.current = true;
            if (audioKey && playSound) {
                let aKey = [];
                aKey.push(audioKey);
                audioPlay(aKey);
            }
        }
        if (localState) {
            setDisplayAmount(
                localState >= LocalStateEnum.PLAYER &&
                    localState <= LocalStateEnum.BANKER
            );
        }
    }, []);

    return (
        <Box sx={getBackgroundSvg()} zIndex={theme => theme.zIndex.drawer + 80}>
            <Stack direction={'column'} spacing={0} padding={'5px'}>
                <Typography sx={{ fontSize: 34, textAlign: 'center' }}>
                    {t(message)}
                </Typography>
                {amount && displayAmount ? (
                    <Typography sx={{ fontSize: 28, textAlign: 'center' }}>
                        {amount}
                    </Typography>
                ) : (
                    <></>
                )}
            </Stack>
        </Box>
    );
};

const getBg = (color: string, endColor: string) => {
    return {
        display: 'flex',
        position: 'absolute',
        minHeight: 100,
        width: '100%',
        top: 378,
        left: 0,
        right: 0,
        padding: '5px 10px',
        justifyContent: 'center',
        alignItems: 'center',
        '& div': {
            margin: '0 10px',
        },
        background: `linear-gradient(to right, ${endColor} 0%, ${color} 8%, ${color} 92%, ${endColor}) 100%`,
    };
};
