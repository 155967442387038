import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CdnContext } from '../../../../contexts/CdnContext';
//import { useUserAgent } from '../../../../hooks/useUserAgent';
import { getLocaleByCode } from '../../../../models/Locale';
import { GameType as GameTypeEnum } from '../../../../models/games/enums/GameType';
import { RootState } from '../../../../store/store';
import { getLandingState } from '../../../main/selector';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import { getHostById } from '../../../host/slice';
import { CancelButton } from '../CancelButton';

type Props = {
    hash?: string;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    gameType?: GameTypeEnum;
    playButtonAudio: () => void;
};

export const GameRules = (props: Props) => {
    const { setIsOpen, hash, gameType, playButtonAudio } = props;
    const { hostId } = useContext(VideoPlayerContext);
    const { GameType } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const { webView } = useSelector((state: RootState) =>
        getLandingState(state)
    );

    const { i18n } = useTranslation();
    const { gameRuleUrl } = useContext(CdnContext);
    //const { os } = useUserAgent();

    const currentCode = i18n.language;
    const gameRuleLanguage = getLocaleByCode('long', currentCode).short;
    let url = gameRuleUrl;
    if (url && window.location.protocol == 'https:') {
        url = url.replace('http://', 'https://');
    }
    let openSrc = `${url}?langcode=${gameRuleLanguage}`;
    if (hash) openSrc += `&hash=${hash}`;
    if (gameType) {
        openSrc += `&gametype=${gameType}`;
    } else if (GameType) {
        openSrc += `&gametype=${GameType}`;
    }
    // if (os.name?.toLowerCase() === 'ios' && webView) {
    //     openSrc += '&parenturl=' + encodeURIComponent(window.location.href);
    // }

    const handleCancel = () => {
        setIsOpen(false);
        playButtonAudio();
    };
    useEffect(() => {
        if (!webView) {
            handleCancel();
        }
    }, [webView, setIsOpen]);

    if (webView) {
        return (
            <>
                <iframe
                    style={{ width: '100%', height: '90%' }}
                    src={openSrc}
                />
                <CancelButton
                    onClick={handleCancel}
                    image={'commonUI ic_close'}
                />
            </>
        );
    } else {
        window.open(openSrc, 'GameRules');
        return <></>;
    }
};
