import { Box } from '@mui/material';
import { Moment } from 'moment/moment';
import {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AudioPlayContext } from '../../../contexts/AudioPlayContext';
import { WebSocketContext } from '../../../contexts/WebSocketContext';
import { CMDPsBetLogQuery } from '../../../models/cmd/live';
import { RootState } from '../../../store/store';
import { getMainUserState } from '../../main/selector';
import { DateRangePicker } from '../../report/components/DateRangePicker';
import { betRecordSliceActions } from '../../report/slice/betRecord';
import { UsernameGrid } from './UsernameGrid';
import { BetRecordReport } from './betRecord/BetRecordReport';

type Props = {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setIsBetRecordOpen: Dispatch<SetStateAction<boolean>>;
};

export const BetRecord = (props: Props) => {
    const { setIsOpen, setIsBetRecordOpen } = props;

    const dispatch = useDispatch();
    const [start, setStart] = useState<Moment>();
    const [end, setEnd] = useState<Moment>();
    const [expanded, setExpanded] = useState<boolean>(false);

    const { playButtonAudio } = useContext(AudioPlayContext);
    const { sendCommand } = useContext(WebSocketContext);
    const { Username } = useSelector((state: RootState) =>
        getMainUserState(state)
    );
    const handleSubmit = (startDate: Moment, endDate: Moment) => {
        if (startDate && endDate) {
            const request = new CMDPsBetLogQuery();
            request.FromDate = startDate
                .locale('en')
                .format('YYYY-MM-DD 12:00:00');
            request.ToDate = endDate.locale('en').format('YYYY-MM-DD 11:59:59');
            sendCommand(request);
        } else {
            console.warn('app::report::betLog', 'please select date');
        }
    };
    useEffect(() => {
        return () => {
            dispatch(betRecordSliceActions.reset());
        };
    }, []);
    const onClose = () => {
        playButtonAudio();
        setIsOpen(false);
        setIsBetRecordOpen(false);
    };
    return (
        <>
            {/* <Grid
                container
                justifyContent="flex-start"
                direction="column"
                sx={{ overflowX: 'hidden', overflowY: 'auto' }}
            > */}
            <UsernameGrid username={Username} />
            <DateRangePicker
                start={start}
                end={end}
                expanded={expanded}
                setStart={setStart}
                setEnd={setEnd}
                setExpanded={setExpanded}
                showPreset={true}
                handleSubmit={handleSubmit}
                playButtonAudio={playButtonAudio}
            />
            <Box overflow={'auto'}>
                <BetRecordReport
                    username={Username}
                    onClose={onClose}
                    playButtonAudio={playButtonAudio}
                />
            </Box>
            {/* </Grid> */}
        </>
    );
};
