import { Grid, Typography } from '@mui/material';
import { memo } from 'react';
import BaseImage from '../../../../components/common/baseImage';
import { Suit } from '../../../../models/games/PokerCard';
import { getPokerByIndex } from '../../../../utils/commonFunc';

type CardCellProps = {
    cardIndex?: number;
};
const CardCell = ({ cardIndex }: CardCellProps) => {
    const card = getPokerByIndex(cardIndex);
    return (
        <Grid container className="blackjack-card-cell">
            {card && (
                <>
                    <Grid item xs={6}>
                        <Typography
                            color={
                                card.suit === Suit.HEART ||
                                card.suit === Suit.DIAMOND
                                    ? '#B23634'
                                    : 'black'
                            }
                            textAlign={'center'}
                            className="blackjack-card-cell-text"
                        >
                            {card.text}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sx={{ marginTop: 'auto', marginLeft: 'auto' }}
                    >
                        <BaseImage
                            className={`blackjack-card-cell-suit bj_icon ${Suit[
                                card.suit
                            ].toLowerCase()}`}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};
export default memo(CardCell);
