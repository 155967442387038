import { Box, Grid, Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ChipList } from '../Baccarat/ChipList';
import { ChipSelector } from '../Baccarat/ChipSelector';
import { BankerResultDisplay } from './BankerResultDisplay';
import { DecisionPanel } from './DecisionPanel';
import { PlayerResultDisplay } from './PlayerResultDisplay';

import { useSelector } from 'react-redux';
import '../../../../../assets/image/common/blackjack/bj_seats.scss';
import '../../../../../assets/image/common/blackjack/bj_seats2.scss';
import { SeatAreaContext } from '../../../../../contexts/SeatAreaContext';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import { GameType } from '../../../../../models/games/enums/GameType';
import { RootState } from '../../../../../store/store';
import { getHostById } from '../../../../host/slice';
import { TotalBetAmount } from '../../TotalBetAmount';
import { BetOptions } from './BetOptions';
import { FollowDecisionPanel } from './FollowDecisionPanel';
import { InsurancePanel } from './InsurancePanel';
import { SeatArea } from './SeatArea';
export const BlackjackBetAreaPanel = () => {
    const {
        showBet,
        showInsurance,
        isVideoZoom,
        showDecision,
        isUserTurn,
        hasSeated,
        showFollow,
    } = useContext(SeatAreaContext);
    const [tableColorDealing, setTableColorDealing] = useState<string>('unset');
    const [tableColorBetting, setTableColorBetting] = useState<string>('unset');
    const { hostId } = useContext(VideoPlayerContext);
    const { Group } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    useEffect(() => {
        if (Group === 'a') {
            setTableColorDealing('#663C3B');
            setTableColorBetting(
                'linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(11, 61, 34, 0.20) 40%, rgba(100,62,64, 0.60) 60%, #000 95%), #000;'
            );
        } else {
            setTableColorDealing('#1D3C2E');
            setTableColorBetting(
                'linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(11, 61, 34, 0.20) 40%, rgba(30, 66, 49, 0.60) 60%, #000 95%), #000;'
            );
        }
    }, [Group]);
    return (
        <Stack
            sx={{
                marginTop: '370px',
                width: 540,
                height: 393,
            }}
        >
            {/* desk mask */}
            <Box
                sx={{
                    position: 'absolute',
                    width: 540,
                    height: '625px',
                    top: '335px',
                    // zIndex: -3,
                    background: isVideoZoom
                        ? tableColorDealing
                        : tableColorBetting,
                }}
            />
            {/* result display */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 55,
                    left: 15,
                }}
            >
                <BankerResultDisplay />
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    top: 490,
                    width: '98%',
                }}
            >
                <PlayerResultDisplay />
            </Box>
            {/* seat */}
            <SeatArea isVideoZoom={isVideoZoom} />
            <BetOptions />
            {/* chips and decision */}
            <Box sx={{ zIndex: 1, marginTop: 'auto' }}>
                <Grid
                    item
                    container
                    sx={{
                        paddingTop: '8px',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        marginBottom: '1px',
                    }}
                >
                    {showInsurance && hasSeated && <InsurancePanel />}
                    {showDecision && isUserTurn && <DecisionPanel />}
                    {showFollow && <FollowDecisionPanel />}
                    {showBet && (
                        <>
                            <ChipSelector />
                            <ChipList isShowAllChips={true} />
                        </>
                    )}
                </Grid>
            </Box>
            <Box
                sx={{
                    zIndex: 1,
                    position: 'absolute',
                    bottom: '155px',
                    left: '50%',
                }}
            >
                <TotalBetAmount gameType={GameType.Blackjack} />
            </Box>
        </Stack>
    );
};
