import { SxProps, Theme } from '@mui/material';
import { CSSProperties } from 'react';

export type RouletteBetArea = {
    betType: number;
    style: CSSProperties;
    boxStyle: SxProps<Theme>;
    confirmedChipContainerStyle?: SxProps<Theme>;
    pendingChipContainerStyle?: SxProps<Theme>;
};

export const RouletteBetAreaType1: Array<RouletteBetArea> = [
    // Outside
    {
        betType: 144,
        style: {
            width: '61px',
            height: '197px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '49px',
        },
    },
    {
        betType: 145,
        style: {
            width: '61px',
            height: '196px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '246px',
        },
    },
    {
        betType: 146,
        style: {
            width: '61px',
            height: '196px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '442px',
        },
    },
    {
        betType: 147,
        style: {
            width: '73px',
            height: '49px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '638px',
            left: '62px',
        },
    },
    {
        betType: 148,
        style: {
            width: '73px',
            height: '49px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '638px',
            left: '135px',
        },
    },
    {
        betType: 149,
        style: {
            width: '73px',
            height: '49px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '638px',
            left: '207px',
        },
    },
    {
        betType: 150,
        style: {
            width: '60px',
            height: '99px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '49px',
            left: '280px',
        },
    },
    {
        betType: 151,
        style: {
            width: '60px',
            height: '99px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '539px',
            left: '280px',
        },
    },
    {
        betType: 152,
        style: {
            width: '60px',
            height: '98px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '442px',
            left: '280px',
        },
    },
    {
        betType: 153,
        style: {
            width: '60px',
            height: '98px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '148px',
            left: '280px',
        },
    },
    {
        betType: 154,
        style: {
            width: '60px',
            height: '98px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '246px',
            left: '280px',
        },
    },
    {
        betType: 155,
        style: {
            width: '60px',
            height: '98px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '344px',
            left: '280px',
        },
    },
    // one number
    {
        betType: 0,
        style: {
            width: '218px',
            height: '48px',
        },
        boxStyle: {
            position: 'absolute',
            left: '62px',
        },
        pendingChipContainerStyle: {
            top: '-50px',
        },
    },
    {
        betType: 1,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '50px',
            left: '62px',
        },
    },
    {
        betType: 2,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '50px',
            left: '135px',
        },
    },
    {
        betType: 3,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '50px',
            left: '208px',
        },
    },
    {
        betType: 4,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '99px',
            left: '62px',
        },
    },
    {
        betType: 5,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '99px',
            left: '135px',
        },
    },
    {
        betType: 6,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '99px',
            left: '208px',
        },
    },
    {
        betType: 7,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '148px',
            left: '62px',
        },
    },
    {
        betType: 8,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '148px',
            left: '135px',
        },
    },
    {
        betType: 9,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '148px',
            left: '208px',
        },
    },
    {
        betType: 10,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '197px',
            left: '62px',
        },
    },
    {
        betType: 11,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '197px',
            left: '135px',
        },
    },
    {
        betType: 12,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '197px',
            left: '208px',
        },
    },
    {
        betType: 13,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '246px',
            left: '62px',
        },
    },
    {
        betType: 14,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '246px',
            left: '135px',
        },
    },
    {
        betType: 15,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '246px',
            left: '208px',
        },
    },
    {
        betType: 16,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '295px',
            left: '62px',
        },
    },
    {
        betType: 17,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '295px',
            left: '135px',
        },
    },
    {
        betType: 18,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '295px',
            left: '208px',
        },
    },
    {
        betType: 19,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '344px',
            left: '62px',
        },
    },
    {
        betType: 20,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '344px',
            left: '135px',
        },
    },
    {
        betType: 21,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '344px',
            left: '208px',
        },
    },
    {
        betType: 22,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '393px',
            left: '62px',
        },
    },
    {
        betType: 23,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '393px',
            left: '135px',
        },
    },
    {
        betType: 24,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '393px',
            left: '208px',
        },
    },
    {
        betType: 25,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '442px',
            left: '62px',
        },
    },
    {
        betType: 26,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '442px',
            left: '135px',
        },
    },
    {
        betType: 27,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '442px',
            left: '208px',
        },
    },
    {
        betType: 28,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '491px',
            left: '62px',
        },
    },
    {
        betType: 29,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '491px',
            left: '135px',
        },
    },
    {
        betType: 30,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '491px',
            left: '208px',
        },
    },
    {
        betType: 31,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '540px',
            left: '62px',
        },
    },
    {
        betType: 32,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '540px',
            left: '135px',
        },
    },
    {
        betType: 33,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '540px',
            left: '208px',
        },
    },
    {
        betType: 34,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '589px',
            left: '62px',
        },
    },
    {
        betType: 35,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '589px',
            left: '135px',
        },
    },
    {
        betType: 36,
        style: {
            width: '72px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '589px',
            left: '208px',
        },
    },
    // split
    {
        betType: 37,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '38px',
            left: '62px',
        },
    },
    {
        betType: 38,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '38px',
            left: '135px',
        },
    },
    {
        betType: 39,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '38px',
            left: '208px',
        },
    },
    {
        betType: 40,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '49px',
            left: '117px',
        },
    },
    {
        betType: 41,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '87px',
            left: '62px',
        },
    },
    {
        betType: 42,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '50px',
            left: '189px',
        },
    },
    {
        betType: 43,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '87px',
            left: '135px',
        },
    },
    {
        betType: 44,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '87px',
            left: '208px',
        },
    },
    {
        betType: 45,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '99px',
            left: '117px',
        },
    },
    {
        betType: 46,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '136px',
            left: '62px',
        },
    },
    {
        betType: 47,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '99px',
            left: '189px',
        },
    },
    {
        betType: 48,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '136px',
            left: '135px',
        },
    },
    {
        betType: 49,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '136px',
            left: '208px',
        },
    },
    {
        betType: 50,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '148px',
            left: '117px',
        },
    },
    {
        betType: 51,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '185px',
            left: '62px',
        },
    },
    {
        betType: 52,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '148px',
            left: '189px',
        },
    },
    {
        betType: 53,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '185px',
            left: '135px',
        },
    },
    {
        betType: 54,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '185px',
            left: '208px',
        },
    },
    {
        betType: 55,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '197px',
            left: '117px',
        },
    },
    {
        betType: 56,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '234px',
            left: '62px',
        },
    },
    {
        betType: 57,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '197px',
            left: '189px',
        },
    },
    {
        betType: 58,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '234px',
            left: '135px',
        },
    },
    {
        betType: 59,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '234px',
            left: '208px',
        },
    },
    {
        betType: 60,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '246px',
            left: '117px',
        },
    },
    {
        betType: 61,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '283px',
            left: '62px',
        },
    },
    {
        betType: 62,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '246px',
            left: '189px',
        },
    },
    {
        betType: 63,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '283px',
            left: '135px',
        },
    },
    {
        betType: 64,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '283px',
            left: '208px',
        },
    },
    {
        betType: 65,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '295px',
            left: '117px',
        },
    },
    {
        betType: 66,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '332px',
            left: '62px',
        },
    },
    {
        betType: 67,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '295px',
            left: '189px',
        },
    },
    {
        betType: 68,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '332px',
            left: '135px',
        },
    },
    {
        betType: 69,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '332px',
            left: '208px',
        },
    },
    {
        betType: 70,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '344px',
            left: '117px',
        },
    },
    {
        betType: 71,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '381px',
            left: '62px',
        },
    },
    {
        betType: 72,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '344px',
            left: '189px',
        },
    },
    {
        betType: 73,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '381px',
            left: '135px',
        },
    },
    {
        betType: 74,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '381px',
            left: '208px',
        },
    },
    {
        betType: 75,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '393px',
            left: '117px',
        },
    },
    {
        betType: 76,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '430px',
            left: '62px',
        },
    },
    {
        betType: 77,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '393px',
            left: '189px',
        },
    },
    {
        betType: 78,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '430px',
            left: '135px',
        },
    },
    {
        betType: 79,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '430px',
            left: '208px',
        },
    },
    {
        betType: 80,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '442px',
            left: '117px',
        },
    },
    {
        betType: 81,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '479px',
            left: '62px',
        },
    },
    {
        betType: 82,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '442px',
            left: '189px',
        },
    },
    {
        betType: 83,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '479px',
            left: '135px',
        },
    },
    {
        betType: 84,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '479px',
            left: '208px',
        },
    },
    {
        betType: 85,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '491px',
            left: '117px',
        },
    },
    {
        betType: 86,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '528px',
            left: '62px',
        },
    },
    {
        betType: 87,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '491px',
            left: '189px',
        },
    },
    {
        betType: 88,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '528px',
            left: '135px',
        },
    },
    {
        betType: 89,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '528px',
            left: '208px',
        },
    },
    {
        betType: 90,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '540px',
            left: '117px',
        },
    },
    {
        betType: 91,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '577px',
            left: '62px',
        },
    },
    {
        betType: 92,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '540px',
            left: '189px',
        },
    },
    {
        betType: 93,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '577px',
            left: '135px',
        },
    },
    {
        betType: 94,
        style: {
            width: '72px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '577px',
            left: '208px',
        },
    },
    {
        betType: 95,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '589px',
            left: '117px',
        },
    },
    {
        betType: 96,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '589px',
            left: '189px',
        },
    },
    // Trio
    {
        betType: 97,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '38px',
            left: '117px',
        },
    },
    {
        betType: 98,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '38px',
            left: '189px',
        },
    },
    // Line
    {
        betType: 99,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '50px',
            left: '262px',
        },
    },
    {
        betType: 100,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '99px',
            left: '262px',
        },
    },
    {
        betType: 101,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '148px',
            left: '262px',
        },
    },
    {
        betType: 102,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '197px',
            left: '262px',
        },
    },
    {
        betType: 103,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '246px',
            left: '262px',
        },
    },
    {
        betType: 104,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '295px',
            left: '262px',
        },
    },
    {
        betType: 105,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '344px',
            left: '262px',
        },
    },
    {
        betType: 106,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '393px',
            left: '262px',
        },
    },
    {
        betType: 107,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '442px',
            left: '262px',
        },
    },
    {
        betType: 108,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '491px',
            left: '262px',
        },
    },
    {
        betType: 109,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '540px',
            left: '262px',
        },
    },
    {
        betType: 110,
        style: {
            width: '36px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '589px',
            left: '262px',
        },
    },
    // Corner
    {
        betType: 111,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '87px',
            left: '117px',
        },
    },
    {
        betType: 112,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '87px',
            left: '189px',
        },
    },
    {
        betType: 113,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '136px',
            left: '117px',
        },
    },
    {
        betType: 114,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '136px',
            left: '189px',
        },
    },
    {
        betType: 115,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '185px',
            left: '117px',
        },
    },
    {
        betType: 116,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '185px',
            left: '189px',
        },
    },
    {
        betType: 117,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '234px',
            left: '117px',
        },
    },
    {
        betType: 118,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '234px',
            left: '189px',
        },
    },
    {
        betType: 119,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '283px',
            left: '117px',
        },
    },
    {
        betType: 120,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '283px',
            left: '189px',
        },
    },
    {
        betType: 121,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '332px',
            left: '117px',
        },
    },
    {
        betType: 122,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '332px',
            left: '189px',
        },
    },
    {
        betType: 123,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '381px',
            left: '117px',
        },
    },
    {
        betType: 124,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '381px',
            left: '189px',
        },
    },
    {
        betType: 125,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '430px',
            left: '117px',
        },
    },
    {
        betType: 126,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '430px',
            left: '189px',
        },
    },
    {
        betType: 127,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '479px',
            left: '117px',
        },
    },
    {
        betType: 128,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '479px',
            left: '189px',
        },
    },
    {
        betType: 129,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '528px',
            left: '117px',
        },
    },
    {
        betType: 130,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '528px',
            left: '189px',
        },
    },
    {
        betType: 131,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '577px',
            left: '117px',
        },
    },
    {
        betType: 132,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '577px',
            left: '189px',
        },
    },
    // 2 Line
    {
        betType: 133,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '87px',
            left: '262px',
        },
    },
    {
        betType: 134,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '136px',
            left: '262px',
        },
    },
    {
        betType: 135,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '185px',
            left: '262px',
        },
    },
    {
        betType: 136,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '234px',
            left: '262px',
        },
    },
    {
        betType: 137,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '283px',
            left: '262px',
        },
    },
    {
        betType: 138,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '332px',
            left: '262px',
        },
    },
    {
        betType: 139,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '381px',
            left: '262px',
        },
    },
    {
        betType: 140,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '430px',
            left: '262px',
        },
    },
    {
        betType: 141,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '479px',
            left: '262px',
        },
    },
    {
        betType: 142,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '528px',
            left: '262px',
        },
    },
    {
        betType: 143,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '577px',
            left: '262px',
        },
    },
    {
        betType: 156,
        style: {
            width: '36px',
            height: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '38px',
            left: '262px',
        },
    },
];

export const RouletteBetAreaType2: Array<RouletteBetArea> = [
    {
        betType: 0,
        style: {
            width: '90.5px',
            height: '34.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '108px',
            left: '76px',
        },
    },
    {
        betType: 1,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '143px',
            left: '76px',
        },
    },
    {
        betType: 2,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '143px',
            left: '106.5px',
        },
    },
    {
        betType: 3,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '143px',
            left: '136.5px',
        },
    },
    {
        betType: 4,
        style: {
            width: '28.5px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '176.5px',
            left: '76px',
        },
    },
    {
        betType: 5,
        style: {
            width: '28px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '176.5px',
            left: '106.5px',
        },
    },
    {
        betType: 6,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '176.5px',
            left: '136.5px',
        },
    },
    {
        betType: 7,
        style: {
            width: '28.5px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '210.5px',
            left: '76px',
        },
    },
    {
        betType: 8,
        style: {
            width: '28px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '210.5px',
            left: '106.5px',
        },
    },
    {
        betType: 9,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '210.5px',
            left: '136.5px',
        },
    },
    {
        betType: 10,
        style: {
            width: '28.5px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '244px',
            left: '76px',
        },
    },
    {
        betType: 11,
        style: {
            width: '28px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '244px',
            left: '106.5px',
        },
    },
    {
        betType: 12,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '244px',
            left: '136.5px',
        },
    },
    {
        betType: 13,
        style: {
            width: '28.5px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '278px',
            left: '76px',
        },
    },
    {
        betType: 14,
        style: {
            width: '28px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '278px',
            left: '106.5px',
        },
    },
    {
        betType: 15,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '278px',
            left: '136.5px',
        },
    },
    {
        betType: 16,
        style: {
            width: '28.5px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '311.5px',
            left: '76px',
        },
    },
    {
        betType: 17,
        style: {
            width: '28px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '311.5px',
            left: '106.5px',
        },
    },
    {
        betType: 18,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '311.5px',
            left: '136.5px',
        },
    },
    {
        betType: 19,
        style: {
            width: '28.5px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '345.5px',
            left: '76px',
        },
    },
    {
        betType: 20,
        style: {
            width: '28px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '345.5px',
            left: '106.5px',
        },
    },
    {
        betType: 21,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '345.5px',
            left: '136.5px',
        },
    },
    {
        betType: 22,
        style: {
            width: '28.5px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '379px',
            left: '76px',
        },
    },
    {
        betType: 23,
        style: {
            width: '28px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '379px',
            left: '106.5px',
        },
    },
    {
        betType: 24,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '379px',
            left: '136.5px',
        },
    },
    {
        betType: 25,
        style: {
            width: '28.5px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '413px',
            left: '76px',
        },
    },
    {
        betType: 26,
        style: {
            width: '28px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '413px',
            left: '106.5px',
        },
    },
    {
        betType: 27,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '413px',
            left: '136.5px',
        },
    },
    {
        betType: 28,
        style: {
            width: '28.5px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '446.5px',
            left: '76px',
        },
    },
    {
        betType: 29,
        style: {
            width: '28px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '446.5px',
            left: '106.5px',
        },
    },
    {
        betType: 30,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '446.5px',
            left: '136.5px',
        },
    },
    {
        betType: 31,
        style: {
            width: '28.5px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '480.5px',
            left: '76px',
        },
    },
    {
        betType: 32,
        style: {
            width: '28px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '480.5px',
            left: '106.5px',
        },
    },
    {
        betType: 33,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '480.5px',
            left: '136.5px',
        },
    },
    {
        betType: 34,
        style: {
            width: '28.5px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '514px',
            left: '76px',
        },
    },
    {
        betType: 35,
        style: {
            width: '28px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '514px',
            left: '106.5px',
        },
    },
    {
        betType: 36,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '514px',
            left: '136.5px',
        },
    },
    {
        betType: 37,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '128px',
            left: '76px',
        },
    },
    {
        betType: 38,
        style: {
            width: '28px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '128px',
            left: '106.5px',
        },
    },
    {
        betType: 39,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '128px',
            left: '136.5px',
        },
    },
    {
        betType: 40,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '143px',
            left: '91.5px',
        },
    },
    {
        betType: 41,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '161.5px',
            left: '76px',
        },
    },
    {
        betType: 42,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '143px',
            left: '121.5px',
        },
    },
    {
        betType: 43,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '161.5px',
            left: '106.5px',
        },
    },
    {
        betType: 44,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '161.5px',
            left: '136.5px',
        },
    },
    {
        betType: 45,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '176.5px',
            left: '91.5px',
        },
    },
    {
        betType: 46,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '195px',
            left: '76px',
        },
    },
    {
        betType: 47,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '176.5px',
            left: '121.5px',
        },
    },
    {
        betType: 48,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '195px',
            left: '106.5px',
        },
    },
    {
        betType: 49,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '195px',
            left: '136.5px',
        },
    },
    {
        betType: 50,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '210.5px',
            left: '91.5px',
        },
    },
    {
        betType: 51,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '229px',
            left: '76px',
        },
    },
    {
        betType: 52,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '210.5px',
            left: '121.5px',
        },
    },
    {
        betType: 53,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '229px',
            left: '106.5px',
        },
    },
    {
        betType: 54,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '229px',
            left: '136.5px',
        },
    },
    {
        betType: 55,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '244px',
            left: '91.5px',
        },
    },
    {
        betType: 56,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '263px',
            left: '76px',
        },
    },
    {
        betType: 57,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '244px',
            left: '121.5px',
        },
    },
    {
        betType: 58,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '263px',
            left: '106.5px',
        },
    },
    {
        betType: 59,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '263px',
            left: '136.5px',
        },
    },
    {
        betType: 60,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '278px',
            left: '91.5px',
        },
    },
    {
        betType: 61,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '296.5px',
            left: '76px',
        },
    },
    {
        betType: 62,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '278px',
            left: '121.5px',
        },
    },
    {
        betType: 63,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '296.5px',
            left: '106.5px',
        },
    },
    {
        betType: 64,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '296.5px',
            left: '136.5px',
        },
    },
    {
        betType: 65,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '311.5px',
            left: '91.5px',
        },
    },
    {
        betType: 66,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '330px',
            left: '76px',
        },
    },
    {
        betType: 67,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '311.5px',
            left: '121.5px',
        },
    },
    {
        betType: 68,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '330px',
            left: '106.5px',
        },
    },
    {
        betType: 69,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '330px',
            left: '136.5px',
        },
    },
    {
        betType: 70,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '345.5px',
            left: '91.5px',
        },
    },
    {
        betType: 71,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '363.5px',
            left: '76px',
        },
    },
    {
        betType: 72,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '345.5px',
            left: '121.5px',
        },
    },
    {
        betType: 73,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '363.5px',
            left: '106.5px',
        },
    },
    {
        betType: 74,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '363.5px',
            left: '136.5px',
        },
    },
    {
        betType: 75,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '379px',
            left: '91.5px',
        },
    },
    {
        betType: 76,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '397.5px',
            left: '76px',
        },
    },
    {
        betType: 77,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '379px',
            left: '121.5px',
        },
    },
    {
        betType: 78,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '397.5px',
            left: '106.5px',
        },
    },
    {
        betType: 79,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '397.5px',
            left: '136.5px',
        },
    },
    {
        betType: 80,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '413px',
            left: '91.5px',
        },
    },
    {
        betType: 81,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '431.5px',
            left: '76px',
        },
    },
    {
        betType: 82,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '413px',
            left: '121.5px',
        },
    },
    {
        betType: 83,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '431.5px',
            left: '106.5px',
        },
    },
    {
        betType: 84,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '431.5px',
            left: '136.5px',
        },
    },
    {
        betType: 85,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '446.5px',
            left: '91.5px',
        },
    },
    {
        betType: 86,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '465px',
            left: '76px',
        },
    },
    {
        betType: 87,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '446.5px',
            left: '121.5px',
        },
    },
    {
        betType: 88,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '465px',
            left: '106.5px',
        },
    },
    {
        betType: 89,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '465px',
            left: '136.5px',
        },
    },
    {
        betType: 90,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '480.5px',
            left: '91.5px',
        },
    },
    {
        betType: 91,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '498.5px',
            left: '76px',
        },
    },
    {
        betType: 92,
        style: {
            width: '28px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '480.5px',
            left: '121.5px',
        },
    },
    {
        betType: 93,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '498.5px',
            left: '106.5px',
        },
    },
    {
        betType: 94,
        style: {
            width: '28.5px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '498.5px',
            left: '136.5px',
        },
    },
    {
        betType: 95,
        style: {
            width: '28px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '514px',
            left: '91.5px',
        },
    },
    {
        betType: 96,
        style: {
            width: '28px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '514px',
            left: '121.5px',
        },
    },
    {
        betType: 97,
        style: {
            width: '28px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '128px',
            left: '91.5px',
        },
    },
    {
        betType: 98,
        style: {
            width: '28px',
            height: '28.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '128px',
            left: '121.5px',
        },
    },
    {
        betType: 99,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '143px',
            left: '150px',
        },
    },
    {
        betType: 100,
        style: {
            width: '28.5px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '176.5px',
            left: '150px',
        },
    },
    {
        betType: 101,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '210.5px',
            left: '150px',
        },
    },
    {
        betType: 102,
        style: {
            width: '28.5px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '244px',
            left: '150px',
        },
    },
    {
        betType: 103,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '278px',
            left: '150px',
        },
    },
    {
        betType: 104,
        style: {
            width: '28.5px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '311.5px',
            left: '150px',
        },
    },
    {
        betType: 105,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '345.5px',
            left: '150px',
        },
    },
    {
        betType: 106,
        style: {
            width: '28.5px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '379px',
            left: '150px',
        },
    },
    {
        betType: 107,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '413px',
            left: '150px',
        },
    },
    {
        betType: 108,
        style: {
            width: '28.5px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '446.5px',
            left: '150px',
        },
    },
    {
        betType: 109,
        style: {
            width: '28.5px',
            height: '31.5px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '480.5px',
            left: '150px',
        },
    },
    {
        betType: 110,
        style: {
            width: '28.5px',
            height: '32px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '514px',
            left: '150px',
        },
    },
    {
        betType: 111,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '161.5px',
            left: '91.5px',
        },
    },
    {
        betType: 112,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '161.5px',
            left: '121.5px',
        },
    },
    {
        betType: 113,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '195px',
            left: '91.5px',
        },
    },
    {
        betType: 114,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '195px',
            left: '121.5px',
        },
    },
    {
        betType: 115,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '229px',
            left: '91.5px',
        },
    },
    {
        betType: 116,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '229px',
            left: '121.5px',
        },
    },
    {
        betType: 117,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '263px',
            left: '91.5px',
        },
    },
    {
        betType: 118,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '263px',
            left: '121.5px',
        },
    },
    {
        betType: 119,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '296.5px',
            left: '91.5px',
        },
    },
    {
        betType: 120,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '296.5px',
            left: '121.5px',
        },
    },
    {
        betType: 121,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '330px',
            left: '91.5px',
        },
    },
    {
        betType: 122,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '330px',
            left: '121.5px',
        },
    },
    {
        betType: 123,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '363.5px',
            left: '91.5px',
        },
    },
    {
        betType: 124,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '363.5px',
            left: '121.5px',
        },
    },
    {
        betType: 125,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '397.5px',
            left: '91.5px',
        },
    },
    {
        betType: 126,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '397.5px',
            left: '121.5px',
        },
    },
    {
        betType: 127,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '431.5px',
            left: '91.5px',
        },
    },
    {
        betType: 128,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '431.5px',
            left: '121.5px',
        },
    },
    {
        betType: 129,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '465px',
            left: '91.5px',
        },
    },
    {
        betType: 130,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '465px',
            left: '121.5px',
        },
    },
    {
        betType: 131,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '498.5px',
            left: '91.5px',
        },
    },
    {
        betType: 132,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '498.5px',
            left: '121.5px',
        },
    },
    {
        betType: 133,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '161.5px',
            left: '150px',
        },
    },
    {
        betType: 134,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '195px',
            left: '150px',
        },
    },
    {
        betType: 135,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '229px',
            left: '150px',
        },
    },
    {
        betType: 136,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '263px',
            left: '150px',
        },
    },
    {
        betType: 137,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '296.5px',
            left: '150px',
        },
    },
    {
        betType: 138,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '330px',
            left: '150px',
        },
    },
    {
        betType: 139,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '363.5px',
            left: '150px',
        },
    },
    {
        betType: 140,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '397.5px',
            left: '150px',
        },
    },
    {
        betType: 141,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '431.5px',
            left: '150px',
        },
    },
    {
        betType: 142,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '465px',
            left: '150px',
        },
    },
    {
        betType: 143,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '498.5px',
            left: '150px',
        },
    },
    {
        betType: 156,
        style: {
            width: '28px',
            height: '28px',
            backgroundColor: 'rgba(255, 0, 0, 0)',
        },
        boxStyle: {
            position: 'absolute',
            top: '128px',
            left: '150px',
        },
    },
];
