import {
    PokDengRecord,
    PokDengRoadmapRecord,
} from '../../../models/games/pokdeng';
import { DeckResult } from '../../../models/games/pokdeng/DeckInfo';
import { convertPDFResultToDetail } from '../../../utils/games/pokdeng';
import { SupportedRecord } from '../../host/models';

export const getPokDengRecordList = (
    records: SupportedRecord[] | undefined
) => {
    let map: Array<PokDengRoadmapRecord> = new Array<PokDengRoadmapRecord>();
    if (records) {
        for (let i = 0; i < records.length; i++) {
            const record: PokDengRecord = records[i] as PokDengRecord;
            map.push(parsePokDengFResult(record));
        }
    }
    return map;
};

export const parsePokDengFResult = (
    record: PokDengRecord
): PokDengRoadmapRecord => {
    let results: Array<DeckResult>;

    if (record.gameResult && record.gameResult.FResult) {
        results = convertPDFResultToDetail(record.gameResult.FResult);
    } else {
        results = convertPDFResultToDetail('0');
    }

    const item: PokDengRoadmapRecord = {
        fullDeck: record.gameResult?.fullDeck,
        Detail: {
            GameID: record.gameID,
            Result: record.result,
            Shoes: record.shoes,
            Round: record.round,
        },
        PDR_BankerPoint: results[0].handResult,
        PDR_BankerPair: results[0].isPair,

        playerResult: results.slice(1),
    };

    return item;
};
