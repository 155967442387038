import { Box, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SeatAreaContext } from '../../../../../../contexts/SeatAreaContext';
import { BetRoomSeatIndex } from '../../../../../../models/games/BetRoom';
import { BlackjackResultPlayer } from '../../../../../../models/games/blackjack';

type BetResultDisplayProps = {
    sn: BetRoomSeatIndex;
    hand: 1 | 2;
};
type BetResultBoxProps = {
    result: BlackjackResultPlayer;
};
export const BetResultDisplay = ({ sn, hand }: BetResultDisplayProps) => {
    const { betResult } = useContext(SeatAreaContext);
    const key = useMemo(() => `BJR${sn}_S${hand}`, [sn, hand]);
    const [value, setValue] = useState<BlackjackResultPlayer>(0);
    useEffect(() => {
        if (betResult) {
            const v = Number(Object.assign(betResult)[key]);
            setValue(v);
        }
    }, [betResult, key]);
    return value !== BlackjackResultPlayer.BJRS_NoHand ? (
        <Box
            sx={{
                background: 'rgba(0, 0, 0, 0.60);',
                padding: '4px',
                borderRadius: '2px',
                marginTop: '3px',
            }}
        >
            <Grid
                container
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <BetResultBox result={value} />
            </Grid>
        </Box>
    ) : (
        <></>
    );
};

export const BetResultBox = ({ result }: BetResultBoxProps) => {
    const { t } = useTranslation();
    switch (result) {
        case BlackjackResultPlayer.BJRS_Tie:
            return (
                <>
                    <PushIcon />
                    <Typography>{t('betlog.push')}</Typography>
                </>
            );
        case BlackjackResultPlayer.BJRS_BankerWin:
            return (
                <>
                    <LoseIcon />
                    <Typography>{t('betlog.lose')}</Typography>
                </>
            );
        case BlackjackResultPlayer.BJRS_PlayerWin:
            return (
                <>
                    <WinIcon />
                    <Typography>{t('betlog.win')}</Typography>
                </>
            );
        default:
            return <></>;
    }
};

const PushIcon = () => (
    <svg
        width="22"
        height="22"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="32" height="32" rx="16" fill="#FFA902" />
        <path
            d="M13.7422 28.5V11.3203H19.3086C21.418 11.3203 22.793 11.4062 23.4336 11.5781C24.418 11.8359 25.2422 12.3984 25.9062 13.2656C26.5703 14.125 26.9023 15.2383 26.9023 16.6055C26.9023 17.6602 26.7109 18.5469 26.3281 19.2656C25.9453 19.9844 25.457 20.5508 24.8633 20.9648C24.2773 21.3711 23.6797 21.6406 23.0703 21.7734C22.2422 21.9375 21.043 22.0195 19.4727 22.0195H17.2109V28.5H13.7422ZM17.2109 14.2266V19.1016H19.1094C20.4766 19.1016 21.3906 19.0117 21.8516 18.832C22.3125 18.6523 22.6719 18.3711 22.9297 17.9883C23.1953 17.6055 23.3281 17.1602 23.3281 16.6523C23.3281 16.0273 23.1445 15.5117 22.7773 15.1055C22.4102 14.6992 21.9453 14.4453 21.3828 14.3438C20.9688 14.2656 20.1367 14.2266 18.8867 14.2266H17.2109Z"
            fill="white"
        />
    </svg>
);

const WinIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 32 32"
        fill="none"
    >
        <rect width="32" height="32" rx="16" fill="#398076" />
        <path
            d="M9.71021 24L6.6665 8H9.88049L11.1363 15.8054C11.3704 17.4919 11.6258 19.2 11.86 20.9081H11.9451C12.2644 19.2 12.6049 17.4703 12.9455 15.8054L14.776 8H17.4578L19.2883 15.8054C19.6289 17.4486 19.9481 19.1784 20.2887 20.9081H20.3951C20.6293 19.1784 20.8634 17.4703 21.0975 15.8054L22.332 8H25.3332L22.4172 24H18.5008L16.7342 16.0865C16.4787 14.8324 16.2446 13.6 16.053 12.3892H15.9679C15.7551 13.6 15.5422 14.8324 15.2868 16.0865L13.5627 24H9.71021Z"
            fill="white"
        />
    </svg>
);

const LoseIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 32 32"
        fill="none"
    >
        <rect width="32" height="32" rx="16" fill="#295FBA" />
        <path
            d="M10.6666 24V8H14.1904V21.3189H21.3333V24H10.6666Z"
            fill="white"
        />
    </svg>
);
