export enum BetState {
    BJRefund = -1,
    Won,
    Lost,
    Refunded,
    Ready,
    OutOfMoney,
    UnderMinBet,
    OverLimitRed,
    Dividend,
    Withdraw,
    Recharge,
    OverMaxWin,
    GameNotFound,
    TimeOut,
    BetCancel,
    AgainstBetRule,
    ProportionErrorWon,
    ProportionErrorLost,
    ProportionErrorRefunded,
    DividendError,
    OutOfCash,

    //After Game Result Modify State
    ModifyState = 128,
    MWon,
    MLost,
    MRefunded,
}
