import { motion } from 'framer-motion';
import BaseImage from '../../../../components/common/baseImage';
import './RefreshBalanceButton.scss';

type RefreshBalanceProps = {
    rotateNum: number
};
const RefreshBalance = (props: RefreshBalanceProps) => {
    const { rotateNum } = props;

    return (
        <div className="refresh-button-container">
            {/* <Button className="refresh-button" onClick={onClickButton}> */}
            <div className="refresh-button-bg">
                <BaseImage className="commonUI brownBG" />
                <motion.div
                    className='refresh-grp'
                    animate={{ rotate: rotateNum }}
                    transition={{
                        repeat: 0,
                        repeatType: 'loop',
                        duration: 1,
                    }}
                >
                    <BaseImage className="commonUI ic_refresh" />
                </motion.div>
            </div>

            {/* </Button> */}
        </div>
    );
};

export default RefreshBalance;
