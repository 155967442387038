import { Box, CircularProgress, SxProps, Theme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { VideoPlayerContext } from '../../contexts/VideoContext';
import { VIDEO_PLAYER_CONTAINER_ID } from '../../hooks/useVideoPlayer';

export const VideoPlayer = () => {
    const { zoomFactor, width, height, isLoading, resolution } =
        useContext(VideoPlayerContext);
    const [style, setStyle] = useState<SxProps<Theme>>();
    useEffect(() => {
        const rawWidth = width;
        const rawHeight = height;
        // calculate the scaled width and height
        const scaledWidth = rawWidth * zoomFactor.scale;
        const scaledHeight = rawHeight * zoomFactor.scale;
        // calculate the percentage of basic left and top
        const x = ((scaledWidth - rawWidth) / scaledWidth) * 100;
        const y = ((scaledHeight - rawHeight) / scaledHeight) * 100;
        //
        const uLeft = zoomFactor.u;
        const uRight = 1 - zoomFactor.u;
        const vTop = zoomFactor.v;
        const vBottom = 0; //0.4 - zoomFactor.v;

        const top = 50;
        const left = 0;

        // calculate the scale style
        const leftTop = `${x * uLeft}% ${y * vTop}%`;
        const rightTop = `${100 - x * uRight}% ${y * vTop}%`;
        const rightBottom = `${100 - x * uRight}% ${100 - y * vBottom}%`;
        const leftBottom = `${x * uLeft}% ${100 - y * vBottom}%`;

        const scaleStyle: SxProps<Theme> = {
            transform: `scale(${zoomFactor.scale})`,
            transformOrigin: `${zoomFactor.u * 100}% ${zoomFactor.v * 100}%`,
            clipPath: `polygon(${leftTop}, ${rightTop}, ${rightBottom}, ${leftBottom})`,
        };
        console.log('app::video::scale', scaleStyle);

        const startPointStyle: SxProps<Theme> = {
            position: 'absolute',
            top,
            left,
        };
        // combine the result
        const sx: SxProps<Theme> = {
            zIndex: '-1',
            ...startPointStyle,
            ...scaleStyle,
            '& canvas,& video': {
                zIndex: -1,
            },
        };
        setStyle(sx);
    }, [zoomFactor, width, height]);
    return (
        <>
            {isLoading && resolution !== 'Off' && (
                <CircularProgress
                    size={75}
                    sx={{
                        position: 'absolute',
                        top: '166px',
                        left: '232.5px',
                    }}
                />
            )}
            <Box id={VIDEO_PLAYER_CONTAINER_ID} sx={style}></Box>
        </>
    );
};
