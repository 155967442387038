import { useSelector } from 'react-redux';
import { getInGamePopupSate } from '../../../popup/selector';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Box } from '@mui/material';
import BaseImage from '../../../../components/common/baseImage';
import { StateMsgType as StateMsgTypeEnum } from '../../../../models/Popup';
import { SoundList, VoiceList } from '../../../../models/Voice';
import { AudioPlayContext } from '../../../../contexts/AudioPlayContext';

export const BaccaratBetResultPopup = () => {
    const { t, i18n } = useTranslation();
    const popupData = useSelector(getInGamePopupSate);
    const { key, stateMsgType } = popupData;
    const { audioPlay } = useContext(AudioPlayContext);

    const [message, setMessage] = useState<string>('');
    const [icon, setIcon] = useState<string>('');
    const [textColor, setTextColor] = useState<string>('#ffffff');
    const ref = useRef<boolean>(false); //防止play 2 次
    useEffect(() => {
        if (key) {
            let msg: string[] = [];
            if (typeof key === 'string') {
                msg.push(key);
            } else {
                msg = key;
            }
            const transMsg = msg.map(m => {
                return t(m);
            });
            setMessage(transMsg.join(' '));

            if (!ref.current) {
                ref.current = true;
                let aKey: Array<string> = [];
                switch (key) {
                    case 'system.bet_success':
                        aKey = [VoiceList.BettingSuccess];
                        break;
                    case 'system.not_enough_money':
                        aKey = [VoiceList.NotEnoughCredit];
                        break;
                    case 'system.bet_exceed_limit_allin':
                        aKey = [SoundList.ChipsBet];
                        break;
                    case 'system.all_in':
                        aKey = [VoiceList.AllIn];
                        break;
                }
                if (aKey && aKey.length > 0) {
                    audioPlay(aKey);
                }
            }
        }
    }, [key, i18n.language]);
    useEffect(() => {
        if (stateMsgType !== StateMsgTypeEnum.betInfo_Normal) {
            switch (stateMsgType) {
                case StateMsgTypeEnum.betInfo_Success:
                    setIcon('commonUI ic_tick_msg');
                    setTextColor('#00ff00');
                    break;
                case StateMsgTypeEnum.betInfo_Fail:
                    setIcon('commonUI ic_fail_msg');
                    setTextColor('#ff0000');
                    break;
                case StateMsgTypeEnum.betInfo_Warning:
                    setIcon('commonUI ic_warning_msg');
                    setTextColor('#868583');
                    break;
            }
        }
    }, [stateMsgType]);
    return (
        <>
            {message && (
                <>
                    <Stack
                        direction={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        spacing={1}
                        sx={{
                            position: 'absolute',
                            minHeight: 90,
                            width: '100%',
                            top: 378,
                            fontSize: 30,
                            color: textColor,
                            padding: '0 10px',
                            zIndex: theme => theme.zIndex.drawer + 100,
                            '& div': {
                                margin: '0 10px',
                            },
                            textAlign: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                top: 0,
                                left: 0,
                                zIndex: '-1',
                                background:
                                    'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 100%)',
                            }}
                        ></Box>
                        {message}
                        {icon && (
                            <Stack>
                                <BaseImage className={icon} scale={1} />
                            </Stack>
                        )}
                    </Stack>
                </>
            )}
        </>
    );
};
