import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import BaseImage from '../../../components/common/baseImage';
import { RootState } from '../../../store/store';
import { getPlayerCount } from '../slice';

type PlayerCountProps = {
    hostId: number;
};
export const PlayerCount = (props: PlayerCountProps) => {
    const { hostId } = props;
    const countState = useSelector((state: RootState) =>
        getPlayerCount(state, hostId)
    );
    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            className="PlayerCount"
        >
            {/* <img src="resource/assets/image/common/ic_online.png" /> */}
            <BaseImage
                className={
                    // -1 = server player counts
                    hostId === -1 ? 'lobby icn_ppl_count' : 'commonUI ic_online'
                }
            />
            <div>{countState ? countState.Count : 0}</div>
        </Stack>
    );
};
