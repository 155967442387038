import { Box, Grid } from '@mui/material';
import { AnimationControls } from 'framer-motion';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Chip } from '../../../../../../components/common/chips';
import { SeatAreaContext } from '../../../../../../contexts/SeatAreaContext';
import { useBalance } from '../../../../../../hooks/useBalance';
import { useSeatBet } from '../../../../../../hooks/useSeatBet';
import { BetRoomSeatIndex } from '../../../../../../models/games/BetRoom';
import { getDisplaySeat } from '../../../../../../utils/games/blackjack';
import { PlayerName } from '../PlayerName';
import { LeaveButton } from './LeaveButton';
import './SeatSymbol.scss';
import { getMainUserState } from '../../../../../main/selector';
import { useDispatch, useSelector } from 'react-redux';
import { inGamePopupSliceActions } from '../../../../../popup/inGameSlice';
import { GameType } from '../../../../../../models/games/enums/GameType';
import { StateMsgType } from '../../../../../../models/Popup';

export interface ISeatSymbolProps {
    showTopGrow?: boolean;
    showChipsOnBb?: boolean;
    showChipsOnMain?: boolean;
    sn: BetRoomSeatIndex;
    showChipsOnPair?: boolean;
    showArrow?: boolean;
    property1?: 'available' | 'seated' | 'your-seat';
    grow?: 'no' | 'yes';
    className?: string;
    c1?: AnimationControls;
    c2?: AnimationControls;
}

export const SeatSymbol = ({ sn }: ISeatSymbolProps): JSX.Element => {
    const {
        selectedSeat,
        setSelectedSeat,
        showBet,
        hasSeated,
        canLeave,
        PlayerID,
        hasBet,
        hasBetBehind,
    } = useContext(SeatAreaContext);
    const [canSelect, setCanSelect] = useState(false);

    const dispatch = useDispatch();
    const { IsTrial } = useSelector(getMainUserState);
    const { availableBalance } = useBalance();
    const isCurrentSeat = useMemo(() => selectedSeat === sn, [selectedSeat]);
    const { split1Amount, behindSplit1Amount, pairAmount, player } =
        useSeatBet(sn);

    useEffect(() => {
        if (!hasBetBehind) {
            setCanSelect(true);
        } else if (player && player.id !== PlayerID) {
            setCanSelect(true);
        } else {
            setCanSelect(false);
        }
    }, [hasBetBehind, PlayerID, player]);

    const selectSeat = () => {
        if (showBet && !hasBet && availableBalance > 0 && canSelect) {
            if (IsTrial && split1Amount == 0) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'blackjack.trial_ban_seat',
                        GameType.Blackjack,
                        StateMsgType.betInfo_Fail
                    )
                );
                //Lite Version
                // batch(() => {
                //     dispatch(
                //         inGamePopupSliceActions.updateIsOpen({
                //             hostId: hostId,
                //             isOpen: true,
                //         })
                //     );
                //     dispatch(
                //         inGamePopupSliceActions.updateDataSet({
                //             hostId: hostId,
                //             key: 'blackjack.trial_ban_seat',
                //             gameType: GameType.Blackjack,
                //             stateMsgType: StateMsgType.betInfo_Fail,
                //             gameId: CurrentResult.GameID,
                //         })
                //     );
                // });
            } else {
                setSelectedSeat(sn);
            }
        }
    };
    return (
        <div className={'seat-symbol'} onClick={selectSeat}>
            {player ? (
                <>
                    <svg
                        className="group-17__group-80"
                        width="50"
                        height="35"
                        viewBox="0 0 80 56"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M69.2921 34.9248C69.2921 46.5643 56.3189 56 40.3157 56C24.3125 56 11.3394 46.5643 11.3394 34.9248C11.3394 23.2853 24.3125 13.8496 40.3157 13.8496C56.3189 13.8496 69.2921 23.2853 69.2921 34.9248Z"
                            fill="#0B4527"
                            fillOpacity="0.1"
                        />
                        <g opacity="0.6">
                            <path
                                d="M68.2921 34.9248C68.2921 40.3459 65.273 45.3411 60.2169 49.0185C55.1618 52.6952 48.1289 55 40.3157 55C32.5025 55 25.4696 52.6952 20.4145 49.0185C15.3585 45.3411 12.3394 40.3459 12.3394 34.9248C12.3394 29.5038 15.3585 24.5085 20.4145 20.8311C25.4696 17.1544 32.5025 14.8496 40.3157 14.8496C48.1289 14.8496 55.1618 17.1544 60.2169 20.8311C65.273 24.5085 68.2921 29.5038 68.2921 34.9248Z"
                                stroke="#BAA378"
                                strokeWidth="2"
                            />
                            {/* divider */}
                            <path
                                d="M12.3945 33.5999L68.3945 33.5999"
                                stroke="#BAA378"
                                strokeWidth="2"
                            />
                        </g>
                        <path
                            d="M0 15.656L16.8945 25.3521C16.8945 25.3521 24.0166 15.8521 40.3945 15.8521C56.7725 15.8521 63.3945 25.1999 63.3945 25.1999L80 16.258C73.0709 10.2365 59.2126 -5.41727e-05 40.315 0C21.4068 5.42032e-05 5.87926 10.8388 0 15.656Z"
                            fill="#0B4527"
                            fillOpacity="0.1"
                        />
                        <path
                            d="M1.77145 15.5196C8.28887 10.4278 22.8621 1.00005 40.315 1C57.9018 0.99995 71.0706 10.0438 78.2406 16.0696L63.6492 23.927C63.434 23.68 63.1555 23.3765 62.8109 23.0321C61.8885 22.1101 60.4917 20.8932 58.57 19.6803C54.719 17.2498 48.7857 14.8521 40.3945 14.8521C32.0009 14.8521 25.9452 17.2893 21.9756 19.7523C19.994 20.9818 18.5367 22.2148 17.5691 23.1478C17.199 23.5047 16.9003 23.818 16.671 24.0708L1.77145 15.5196Z"
                            stroke="#BAA378"
                            strokeOpacity="0.6"
                            strokeWidth="2"
                        />
                    </svg>
                    {player && <PlayerName player={player} />}
                    {canLeave && isCurrentSeat && (
                        <Grid container justifyContent={'center'}>
                            <LeaveButton />
                        </Grid>
                    )}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 16,
                            zIndex: 1,
                            pointerEvents: 'none',
                        }}
                    >
                        {split1Amount > 0 && (
                            <Grid item>
                                <Chip value={split1Amount} scale={0.2} />
                            </Grid>
                        )}
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 10,
                            left: 16,
                            zIndex: 1,
                            pointerEvents: 'none',
                        }}
                    >
                        {behindSplit1Amount > 0 && (
                            <Grid item>
                                <Chip value={behindSplit1Amount} scale={0.2} />
                            </Grid>
                        )}
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: -10,
                            left: 16,
                            zIndex: 1,
                            pointerEvents: 'none',
                        }}
                    >
                        {pairAmount > 0 && (
                            <Chip value={pairAmount} scale={0.2} />
                        )}
                    </Box>
                </>
            ) : (
                <svg
                    className="seat-symbol__circle"
                    width="123"
                    height="100"
                    viewBox="0 0 123 100"
                    fill={'none'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M118.412 50C118.412 74.9514 92.9319 95.1786 61.5 95.1786C30.0681 95.1786 4.58746 74.9514 4.58746 50C4.58746 25.0485 30.0681 4.82141 61.5 4.82141C92.9319 4.82141 118.412 25.0485 118.412 50Z"
                        fill={isCurrentSeat ? '#BAA378' : '#0B4527'}
                        fillOpacity={isCurrentSeat ? 1 : 0.1}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M122.337 50C122.337 77.9214 94.2025 99.1071 61.5 99.1071C28.7975 99.1071 0.662476 77.9214 0.662476 50C0.662476 22.0786 28.7975 0.892822 61.5 0.892822C94.2025 0.892822 122.337 22.0786 122.337 50ZM61.5 95.1785C92.9319 95.1785 118.412 74.9514 118.412 50C118.412 25.0485 92.9319 4.82139 61.5 4.82139C30.0681 4.82139 4.58748 25.0485 4.58748 50C4.58748 74.9514 30.0681 95.1785 61.5 95.1785Z"
                        fill="#BAA378"
                        fillOpacity="0.6"
                    />
                </svg>
            )}
            <div
                className={`seat-symbol__number ${
                    player !== undefined && player.id === PlayerID && 'seated'
                }`}
            >
                {getDisplaySeat(sn)}
            </div>
            {!isCurrentSeat &&
                !player &&
                showBet &&
                !hasSeated &&
                availableBalance > 0 &&
                canSelect && (
                    <>
                        <svg
                            className="seat-symbol__arrow"
                            width="49"
                            height="56"
                            viewBox="0 0 49 56"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.6435 2.19056L14.8066 -1.97632L24.5 7.72596L34.1935 -1.97632L38.3566 2.19056L24.5 16.0597L10.6435 2.19056Z"
                                fill="#DAC446"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.6435 19.8691L14.8066 15.7023L24.5 25.4045L34.1935 15.7023L38.3566 19.8691L24.5 33.7383L10.6435 19.8691Z"
                                fill="#DAC446"
                                fillOpacity="0.6"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.6435 37.5477L14.8066 33.3809L24.5 43.0831L34.1935 33.3809L38.3566 37.5477L24.5 51.4169L10.6435 37.5477Z"
                                fill="#DAC446"
                                fillOpacity="0.4"
                            />
                        </svg>
                    </>
                )}
        </div>
    );
};
