import { Grid, List, ListItemButton, ListItemText } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LobbyUiContext } from '../../../../contexts/LobbyUiContext';
import { MobPref } from '../../../../hooks/storage/useLocalStorage';
import { GameType } from '../../../../models/games/enums/GameType';
import { getLobbyStateHallGameTypeMap } from '../../selector';
import './GameTab.scss';
import TableCell from './TableCell';
import './index.scss';
import { AudioPlayContext } from '../../../../contexts/AudioPlayContext';

export const BaccaratTables = () => {
    const { t } = useTranslation();
    const hallGameTypeMap = useSelector(getLobbyStateHallGameTypeMap);
    const [hallSelectedIndex, setHallSelectedIndex] = useState<number>(-1);
    const [selectedHosts, setSelectedHosts] = useState<Array<number>>([]);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const baccaratHallMap = useMemo(
        () => hallGameTypeMap.filter(h => h.gameType === GameType.Baccarat),
        [hallGameTypeMap]
    );
    const hallList = useMemo(() => {
        const array = new Array<number>();
        array.push(-1);
        baccaratHallMap.forEach(h => {
            if (h.hallId) array.push(h.hallId);
        });
        return [...new Set(array)];
    }, [baccaratHallMap]);
    const { isHall, changeIsHall } = useContext(LobbyUiContext);
    const handleListItemClick = (index: number) => {
        setHallSelectedIndex(index);
        playButtonAudio();
        localStorage.setItem(
            'MobPref_' + MobPref.GAME_MENU_TAB,
            JSON.stringify(index)
        );
    };
    useEffect(() => {
        const array = new Array<number>();
        baccaratHallMap.forEach(h => {
            if (h.hallId) {
                if (h.hallId === hallSelectedIndex || hallSelectedIndex === -1)
                    array.push(h.hostId);
            } else {
                array.push(h.hostId);
            }
        });
        setSelectedHosts(array);
    }, [hallSelectedIndex]);
    
    return (
        <>
            {isHall && (
                <div
                    className="hall-selector-container"
                    onClick={() => {
                        changeIsHall(false);
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                        }}
                        onTouchStart={() => {
                            changeIsHall(false);
                        }}
                    ></div>
                    <List
                        component="nav"
                        className="hall-selector"
                        aria-label="Hall Selector"
                    >
                        {hallList.map(id => (
                            <ListItemButton
                                key={id}
                                className="selector-list"
                                selected={hallSelectedIndex === id}
                                onClick={() => handleListItemClick(id)}
                            >
                                <ListItemText
                                    className="selector-list-text"
                                    primary={
                                        id === -1
                                            ? t('all')
                                            : t(`hallList.${id}`)
                                    }
                                />
                            </ListItemButton>
                        ))}
                    </List>
                </div>
            )}
            <Grid
                container
                alignContent="flex-start"
                justifyContent="space-between"
            >
                {selectedHosts.map(h => (
                    <TableCell key={`baccarat-cell-${h}`} hostId={h} />
                ))}
            </Grid>
        </>
    );
};
