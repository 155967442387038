import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import NetState from '../../../components/common/netState';
import { PlayerCount } from '../../count/components/PlayerCount';
import { getLandingState, getMainUserState } from '../../main/selector';

export const Footer = () => {
    const { Username } = useSelector(getMainUserState);
    const { username: paramUserName } = useSelector(getLandingState);

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    position: 'fixed',
                    left: 0,
                    bottom: 0,
                    backgroundColor: 'black',
                    width: '540px',
                    height: '27.5px',
                    padding: '0 10px',
                }}
            >
                <PlayerCount hostId={-1} />
                <div>{paramUserName ? paramUserName : Username}</div>
                <NetState sx={{ marginLeft: '5px' }} />
            </Stack>
        </>
    );
};
