import { Box } from '@mui/material';
import { useContext, useState } from 'react';
import { LobbyUiContext } from '../../../../contexts/LobbyUiContext';
import { GameCategoryTab as GameCategoryTabEnum } from '../../../../models/lobby/GameCategoryTab';
import { BaccaratTables } from './BaccaratTables';
import { FeaturedTables } from './FeaturedTables';
import { MultiBetTables } from './MultiBetTables';
import { OtherGamesTables } from './OtherGamesTables';
import PullToRefresh from 'react-simple-pull-to-refresh';
import BaseImage from '../../../../components/common/baseImage';
import { motion, useAnimationControls } from 'framer-motion';

type TabPanelProps = {
    children?: JSX.Element;
    index: number;
    value: number;
};
export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`lobby-tabpanel-${index}`}
            aria-labelledby={`lobby-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0, overflow: 'hidden' }}>{children}</Box>
            )}
        </div>
    );
};

export const GameTables = () => {
    const { currentTab } = useContext(LobbyUiContext);
    const controller = useAnimationControls();

    const [pullToRefresh, setPullToRefresh] = useState<boolean>(false);

    const handleRefresh = async () => {
        setPullToRefresh(!pullToRefresh);
        controller.start({
            rotate: 720,
            transition: { duration: 1 },
        });
        await new Promise(resolve => {
            setTimeout(() => {
                resolve(null);
            }, 600);
        });
        controller.stop();
        controller.set({ rotate: 0 });
    };

    return (
        <PullToRefresh
            isPullable={currentTab == GameCategoryTabEnum.Featured}
            refreshingContent={
                <div>
                    <motion.div
                        animate={controller}
                        style={{
                            margin: '15px 0',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <BaseImage
                            className="commonUI icn_game_list_refresh"
                            scale={0.63}
                        />
                    </motion.div>
                </div>
            }
            pullingContent={<></>}
            onRefresh={handleRefresh}
            className="pull-to-refresh"
        >
            <Box
                component={'main'}
                className="lobby-main-container"
                sx={{
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                        display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                    },
                    '&-ms-overflow-style': {
                        display: 'none', // Hide the scrollbar for IE
                    },
                }}
            >
                <TabPanel
                    value={currentTab}
                    index={GameCategoryTabEnum.Featured}
                >
                    <FeaturedTables pullToRefresh={pullToRefresh} />
                </TabPanel>
                <TabPanel
                    value={currentTab}
                    index={GameCategoryTabEnum.Baccarat}
                >
                    <BaccaratTables />
                </TabPanel>
                <TabPanel
                    value={currentTab}
                    index={GameCategoryTabEnum.OtherGames}
                >
                    <OtherGamesTables />
                </TabPanel>
                <TabPanel
                    value={currentTab}
                    index={GameCategoryTabEnum.MultiBet}
                >
                    <MultiBetTables />
                </TabPanel>
            </Box>
        </PullToRefresh>
    );
};
