export enum BetSourceTypeId {
    None = 0,
    BSIsMultiBet = 1 << 0, //MultiBet      1
    BSFlash = 1 << 1, //Player        2
    BSAPI = 1 << 2, //Platform      4
    BSMobile = 1 << 3, //Device        8
    BSHTML5 = 1 << 4, //Language      16
    BSRobot = 1 << 5, //Platform      32
    BSBrowser = 1 << 6, //Platform      64
    BSIOS = 1 << 7, //OS            128
    BSAndroid = 1 << 8, //OS            256
    BSEgret = 1 << 9, //Engine        512
    BSApp = 1 << 10, //Platform      1024
    BSPC = 1 << 11, //Device        2048
    BSTablet = 1 << 12, //Device        4096
    BSRevamp = 1 << 13, //Revamp        8192
    BSExternal = 1 << 14, //State         16384
    BSClassic = 1 << 15, //Classic       -32768
    BetSourceMax = ~(~0 << 16),
}

export const BET_SOURCE_WEBSITE =
    BetSourceTypeId.BSBrowser | BetSourceTypeId.BSPC;
export const BET_SOURCE_MOBILE =
    BetSourceTypeId.BSMobile |
    BetSourceTypeId.BSHTML5 |
    BetSourceTypeId.BSBrowser |
    BetSourceTypeId.BSEgret |
    BetSourceTypeId.BSRevamp;
export const BET_SOURCE_ANDROID = BET_SOURCE_MOBILE | BetSourceTypeId.BSAndroid;
export const BET_SOURCE_IOS = BET_SOURCE_MOBILE | BetSourceTypeId.BSIOS;
export const BET_SOURCE_ALL =
    BET_SOURCE_WEBSITE |
    BET_SOURCE_MOBILE |
    BET_SOURCE_ANDROID |
    BET_SOURCE_IOS;
