import { Button } from '@mui/material';
import { useContext, useState } from 'react';
import BaseImage from '../../../components/common/baseImage';
import '../../lobby/components/GameTables/GameTab.scss';
import './SwitchTableButton.scss';
import { GameRules } from '../../setting/components/gameRules';
import { AudioPlayContext } from '../../../contexts/AudioPlayContext';

export const GameRulesButton = () => {
    const [open, setOpen] = useState<boolean>(false);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const changeOpen = (v: boolean) => {
        setOpen(v);
        playButtonAudio();
    };
    return (
        <>
            <Button
                variant="contained"
                className="switch-table-button"
                onClick={() => changeOpen(true)}
            >
                <BaseImage className="commonUI ic_GameRule" scale={1} />
            </Button>
            {open && (
                <GameRules
                    setIsOpen={setOpen}
                    playButtonAudio={playButtonAudio}
                />
            )}
        </>
    );
};
