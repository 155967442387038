import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { GameState as BaccaratGameState } from '../models/games/baccarat/GameState';
// import { GameState as DragonTigerGameState } from '../models/games/dragonTiger/GameState';
// import { GameState as CommonGameState } from '../models/games/enums/GameState';
import { VoiceList } from '../models/Voice';
import { GameType as GameTypeEnum } from '../models/games/enums/GameType';
import { LocalState as LocalStateEnum } from '../models/games/enums/LocalState';
import { getHostById, hostsSliceActions } from '../modules/host/slice';
import { RootState } from '../store/store';
import { getLocalState as getAndarBaharLocalState } from '../utils/games/andarBahar';
import { getLocalState as getBaccaratLocalState } from '../utils/games/baccarat';
import { getBlackjackLocalState } from '../utils/games/blackjack';
import { getLocalState as getDTLocalState } from '../utils/games/dragonTiger';
import { getLocalState as getPokDengLocalState } from '../utils/games/pokdeng';
import { getLocalState as getRouletteLocalState } from '../utils/games/roulette';
import { getLocalState as getSeDieLocalState } from '../utils/games/sedie';
import { getLocalState as getSicBoLocalState } from '../utils/games/sicbo';
import { getLocalState as getTeenPattiLocalState } from '../utils/games/teenpatti';
import { GameState } from '../models/games/enums/GameState';

const MESSAGE_TIME = 4 * 1000;
const BETTING_TIME = 2 * 1000;
const initKey = {
    message: '',
    bg: 'GradientImages bg_black_msg',
    amount: '',
    gameType: GameTypeEnum.Baccarat,
    audioKey: '',
} as InGamePopupKeyType;
export type InGamePopupKeyType = {
    message: string;
    bg: string;
    amount: string;
    audioKey: string;
    gameType: GameTypeEnum;
    localState?: number;
    playSound?: boolean;
};
export const useInGamePopup = (hostId: number) => {
    const dispatch = useDispatch();
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const {
        GameType,
        IsRest,
        CurrentState,
        ResultReleased,
        WinAmount,
        IsRestart,
        IsRoundCancel,
    } = host;
    const [key, setKey] = useState<InGamePopupKeyType>(initKey);
    const [display, setDisplay] = useState(false);
    const [firstRender, setFirstRender] = useState<boolean>(true);
    const [handleMsgEffect, setHandleMsgEffect] = useState<number>(0);
    const timer = useRef<NodeJS.Timeout | null>();
    const [localState, setLocalState] = useState<number>(-1);
    const [soundKey, setSoundKey] = useState<string>();

    const show = () => {
        const ms =
            localState === LocalStateEnum.BETTING ? BETTING_TIME : MESSAGE_TIME;
        setDisplay(true);
        // close after MESSAGE_TIME
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            setDisplay(false);
        }, ms);
    };
    const setInit = () => {
        //set init
        setKey(initKey);
        setDisplay(false);
        setLocalState(-1);
        setHandleMsgEffect(0);
        if (timer.current) clearTimeout(timer.current);
    };
    useEffect(() => {
        if (firstRender === false) {
            setFirstRender(true);
            setInit();
        }
    }, [hostId]); //handle switch table
    useEffect(() => {
        //console.log("in::game", firstRender, firstRender, localState);
        if (firstRender) {
            setFirstRender(false);
        }
        if (firstRender === false && localState !== -1) {
            let keepShowing = false;
            let newKey: InGamePopupKeyType = Object.create(key ?? initKey);
            switch (GameType) {
                case GameTypeEnum.Baccarat:
                    newKey = getBaccaratKey(localState);
                    break;
                case GameTypeEnum.Dragon:
                    newKey = getDragonTigerKey(localState);
                    break;
                case GameTypeEnum.SeDie:
                    newKey = getSeDieKey(localState);
                    break;
                case GameTypeEnum.SicBo:
                    newKey = getSicBoKey(localState);
                    break;
                case GameTypeEnum.AndarBahar:
                    newKey = getAndarBaharKey(localState);
                    if (localState == LocalStateEnum.SHUFFLING)
                        keepShowing = true;
                    break;
                case GameTypeEnum.TeenPatti2020:
                    newKey = getTeenPattiKey(localState);
                    if (localState == LocalStateEnum.SHUFFLING)
                        keepShowing = true;
                    break;
                case GameTypeEnum.Roulette:
                    newKey = getRouletteKey(localState);
                    if (localState == LocalStateEnum.RESULT) keepShowing = true;
                    break;
                case GameTypeEnum.PokDeng:
                    newKey = getPokDengKey(localState, WinAmount);
                    break;
                case GameTypeEnum.Blackjack:
                    newKey = getBlackjackKey(localState, WinAmount);
                    break;
                //TODO: Other Game Local State
                default:
                    newKey = getSicBoKey(localState);
                    break;
            }

            if (WinAmount != undefined) {
                const showAmount = (WinAmount / 100).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                });
                if (host.WinAmount >= 0) {
                    newKey.amount = `+ ${showAmount}`;
                } else {
                    newKey.amount = `${showAmount.replace('-', '- ')}`;
                }
            }
            newKey.playSound = true;
            setSoundKey(newKey.audioKey);
            if (soundKey == newKey.audioKey) {
                newKey.playSound = false;
            }
            setKey(newKey);
            show();
            if (keepShowing && timer.current) clearTimeout(timer.current);
            //setHandleMsgEffect(false);
            console.log('in::game::popup', hostId, localState, newKey);
        }
    }, [handleMsgEffect, hostId]);
    useEffect(() => {
        let newLocalState = -1;
        let speHandleFirstPopup = false;
        switch (GameType) {
            case GameTypeEnum.Baccarat:
                newLocalState = getBaccaratLocalState(
                    IsRest,
                    CurrentState,
                    ResultReleased,
                    IsRoundCancel === true
                );
                break;
            case GameTypeEnum.Dragon:
                newLocalState = getDTLocalState(
                    IsRest,
                    CurrentState,
                    ResultReleased
                );
                break;
            case GameTypeEnum.SeDie:
                newLocalState = getSeDieLocalState(
                    IsRest,
                    CurrentState,
                    ResultReleased,
                    true
                );
                break;
            case GameTypeEnum.SicBo:
                newLocalState = getSicBoLocalState(
                    IsRest,
                    CurrentState,
                    ResultReleased
                );
                break;
            case GameTypeEnum.TeenPatti2020:
                newLocalState = getTeenPattiLocalState(
                    IsRest,
                    CurrentState,
                    ResultReleased,
                    true
                );
                speHandleFirstPopup =
                    newLocalState === LocalStateEnum.SHUFFLING;
                break;
            case GameTypeEnum.AndarBahar:
                newLocalState = getAndarBaharLocalState(
                    IsRest,
                    CurrentState,
                    ResultReleased
                );
                speHandleFirstPopup =
                    newLocalState === LocalStateEnum.SHUFFLING;
                break;
            case GameTypeEnum.Roulette:
                newLocalState = getRouletteLocalState(
                    IsRest,
                    CurrentState,
                    ResultReleased
                );
                break;
            case GameTypeEnum.PokDeng:
                newLocalState = getPokDengLocalState(
                    IsRest,
                    CurrentState,
                    ResultReleased
                );
                break;
            case GameTypeEnum.Blackjack:
                newLocalState = getBlackjackLocalState(
                    IsRest,
                    CurrentState,
                    ResultReleased
                );
                break;
            //TODO: Other Game Local State
            default:
                newLocalState = -1;
                break;
        }
        setLocalState(newLocalState);
        if (
            newLocalState > LocalStateEnum.IDLE &&
            (firstRender === false || speHandleFirstPopup === true)
        ) {
            if (
                !(
                    localState === LocalStateEnum.DEALING &&
                    newLocalState === LocalStateEnum.DEALING
                )
            ) {
                setHandleMsgEffect(handleMsgEffect + 1);
                if (timer.current) {
                    clearTimeout(timer.current);
                    setDisplay(false);
                }
            }
        }

        if (newLocalState === GameState.ReStart && IsRestart) {
            dispatch(
                hostsSliceActions.updateIsRestart({
                    hostId: hostId,
                    isRestart: false,
                })
            );
        }
        // if (newLocalState === LocalStateEnum.ROUND_CANCEL && IsRoundCancel) {
        //     dispatch(
        //         hostsSliceActions.updateIsRoundCancel({
        //             hostId: hostId,
        //             isRoundCancel: false,
        //         })
        //     );
        // }
    }, [
        IsRest,
        CurrentState,
        ResultReleased,
        WinAmount,
        IsRestart,
        IsRoundCancel,
        hostId,
    ]);
    return { display, key };
};

const getBaccaratKey = (localState: number): InGamePopupKeyType => {
    let bg = 'GradientImages bg_black_msg',
        amount = '',
        message = '',
        audioKey = '';

    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.betting';
            audioKey = VoiceList.StopBetting;
            break;
        case LocalStateEnum.PLAYER:
            message = 'game.player';
            bg = 'GradientImages bg_player_msg';
            audioKey = VoiceList.PlayerWin;
            break;
        case LocalStateEnum.BANKER:
            message = 'game.banker';
            bg = 'GradientImages bg_banker_msg';
            audioKey = VoiceList.BankerWin;
            break;
        case LocalStateEnum.TIE:
            message = 'game.tie';
            bg = 'GradientImages bg_tie_msg';
            audioKey = VoiceList.TieGame;
            break;
        case LocalStateEnum.ROUND_CANCEL:
            message = 'popup.round_cancel';
            audioKey = '';
            break;
    }
    return {
        gameType: GameTypeEnum.Baccarat,
        amount: amount,
        bg: bg,
        message: message,
        audioKey: audioKey,
    };
};

const getSeDieKey = (localState: number): InGamePopupKeyType => {
    let bg = 'rgba(0,0,0,0.85)',
        amount = '',
        message = '',
        audioKey = '';
    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.betting';
            audioKey = VoiceList.StopBetting;
            break;
        case GameState.ReStart:
            message = 'sedie.reshake';
            audioKey = '';
            break;
        case LocalStateEnum.ROUND_CANCEL:
            message = 'popup.round_cancel';
            audioKey = '';
            break;
    }
    return {
        gameType: GameTypeEnum.SeDie,
        amount: amount,
        bg: bg,
        message: message,
        audioKey: audioKey,
    };
};

const getTeenPattiKey = (localState: number): InGamePopupKeyType => {
    let bg = 'rgba(0,0,0,0.85)',
        amount = '',
        message = '',
        audioKey = '';
    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.betting';
            audioKey = VoiceList.StopBetting;
            break;
        case LocalStateEnum.PLAYER_A:
            message = 'game.playerA';
            bg = 'rgba(45,48,177,0.85)';
            audioKey = VoiceList.PlayerAWin;
            break;
        case LocalStateEnum.PLAYER_B:
            message = 'game.playerB';
            bg = 'rgba(217,22,22,0.85)';
            audioKey = VoiceList.PlayerBWin;
            break;
        case LocalStateEnum.TIE:
            message = 'game.tie';
            bg = 'rgba(28,150,6,0.85)';
            audioKey = VoiceList.TieGame;
            break;
        case LocalStateEnum.IDLE:
        case LocalStateEnum.SHUFFLING:
            message = 'andar_bahar.shuffle';
            bg = 'GradientImages msg_bg';
            audioKey = '';
            break;
        case LocalStateEnum.ROUND_CANCEL:
            message = 'popup.round_cancel';
            audioKey = '';
            break;
    }
    return {
        gameType: GameTypeEnum.TeenPatti2020,
        amount: amount,
        bg: bg,
        message: message,
        localState: localState,
        audioKey: audioKey,
    };
};

const getDragonTigerKey = (localState: number): InGamePopupKeyType => {
    let bg = 'rgba(0,0,0,0.85)',
        amount = '',
        message = '',
        audioKey = '';
    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.betting';
            audioKey = VoiceList.StopBetting;
            break;
        case LocalStateEnum.PLAYER:
            message = 'game.dragon';
            bg = 'rgba(45,48,177,0.85)'; //2d30b1
            audioKey = VoiceList.DragonWin;
            break;
        case LocalStateEnum.BANKER:
            message = 'game.tiger';
            bg = 'rgba(217,22,22,0.85)'; //d91616
            audioKey = VoiceList.TigerWin;
            break;
        case LocalStateEnum.TIE:
            message = 'game.tie';
            bg = 'rgba(28,150,6,0.85)'; //1c9606
            audioKey = VoiceList.TieGame;
            break;
        case LocalStateEnum.ROUND_CANCEL:
            message = 'popup.round_cancel';
            audioKey = '';
            break;
    }
    return {
        gameType: GameTypeEnum.Dragon,
        amount: amount,
        bg: bg,
        message: message,
        audioKey: audioKey,
    };
};

const getSicBoKey = (localState: number): InGamePopupKeyType => {
    let bg = 'rgba(0,0,0,0.85)',
        amount = '',
        message = '',
        audioKey = '';
    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.betting';
            audioKey = VoiceList.StopBetting;
            break;
        case GameState.ReStart:
            message = 'sedie.reshake';
            audioKey = '';
            break;
        case LocalStateEnum.ROUND_CANCEL:
            message = 'popup.round_cancel';
            audioKey = '';
            break;
        default:
            message = 'game.result';
            audioKey = '';
            break;
    }
    return {
        gameType: GameTypeEnum.SicBo,
        amount: amount,
        bg: bg,
        message: message,
        audioKey: audioKey,
    };
};

const getRouletteKey = (localState: number): InGamePopupKeyType => {
    let bg = 'rgba(0,0,0,0.85)',
        amount = '',
        message = '',
        audioKey = '';
    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.betting';
            audioKey = VoiceList.StopBetting;
            break;
        case GameState.ReStart:
            message = 'roulette.respin';
            audioKey = '';
            break;
        case LocalStateEnum.ROUND_CANCEL:
            message = 'popup.round_cancel';
            audioKey = '';
            break;
        default:
            audioKey = '';
            message = 'game.result';
            break;
    }
    return {
        gameType: GameTypeEnum.Roulette,
        amount: amount,
        bg: bg,
        message: message,
        audioKey: audioKey,
        localState: localState,
    };
};

const getAndarBaharKey = (localState: number): InGamePopupKeyType => {
    let bg = '',
        amount = '',
        message = '',
        audioKey = '';
    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.betting';
            audioKey = VoiceList.StopBetting;
            break;
        case LocalStateEnum.PLAYER:
            message = 'game.andar';
            audioKey = VoiceList.AndarWin;
            break;
        case LocalStateEnum.BANKER:
            message = 'game.bahar';
            audioKey = VoiceList.BaharWin;
            break;
        case LocalStateEnum.IDLE:
        case LocalStateEnum.SHUFFLING:
            message = 'andar_bahar.shuffle';
            audioKey = '';
            break;
        case LocalStateEnum.ROUND_CANCEL:
            message = 'popup.round_cancel';
            audioKey = '';
            break;
    }
    return {
        gameType: GameTypeEnum.AndarBahar,
        amount: amount,
        bg: bg,
        message: message,
        audioKey: audioKey,
        localState: localState,
    };
};

const getPokDengKey = (
    localState: number,
    WinAmount?: number
): InGamePopupKeyType => {
    let bg = 'rgba(0,0,0,0.85)',
        amount = '',
        message = '',
        audioKey = '';
    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.betting';
            audioKey = VoiceList.StopBetting;
            break;
        case LocalStateEnum.ROUND_CANCEL:
            message = 'popup.round_cancel';
            audioKey = '';
            break;
    }
    if (WinAmount != undefined) {
        if (WinAmount >= 0) {
            message = 'pokdeng.game_result_win';
        } else {
            message = 'pokdeng.game_result_lose';
        }
    }
    return {
        gameType: GameTypeEnum.PokDeng,
        amount: amount,
        bg: bg,
        message: message,
        audioKey: audioKey,
    };
};
const getBlackjackKey = (
    localState: number,
    WinAmount?: number
): InGamePopupKeyType => {
    let bg = 'rgba(0,0,0,0.85)',
        amount = '',
        message = '',
        audioKey = '';
    switch (localState) {
        case LocalStateEnum.BETTING:
            message = 'game.start_bet';
            // audioKey = VoiceList.StartBetting;
            break;
        case LocalStateEnum.DEALING:
            message = 'game.betting';
            audioKey = VoiceList.StopBetting;
            break;
        case LocalStateEnum.ROUND_CANCEL:
            message = 'blackjack.round_cancel';
            audioKey = '';
            break;
    }
    if (WinAmount != undefined) {
        if (WinAmount >= 0) {
            message = 'pokdeng.game_result_win';
        } else {
            message = 'pokdeng.game_result_lose';
        }
    }
    return {
        gameType: GameTypeEnum.Blackjack,
        amount: amount,
        bg: bg,
        message: message,
        audioKey: audioKey,
    };
};
