import {
    createEntityAdapter,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { HostTable, initialPHostTable } from '../models';
import { hostsReducers } from './reducers';
export const hostsAdapter = createEntityAdapter<HostTable, number>({
    selectId: host => host.HostId,
    sortComparer: (a, b) => a.OrderBy - b.OrderBy,
});
const initialHostsState = hostsAdapter.getInitialState();
// slice

export const PHOST_KEY = 'app::host';
const hostsSlice = createSlice({
    name: PHOST_KEY,
    initialState: initialHostsState,
    reducers: {
        setAll: hostsAdapter.setAll,
        ...hostsReducers,
    },
});
export const hostsSliceReducer = hostsSlice.reducer;
export const hostsSliceActions = hostsSlice.actions;
// selector
export const getHostsSelectors = hostsAdapter.getSelectors<RootState>(
    state => state[PHOST_KEY]
);
export const getHostById = (state: RootState, hostId: number) =>
    getHostsSelectors.selectById(state, hostId) ?? initialPHostTable;
export const getHostByGameId = (state: RootState, gameId: number) =>
    getHostsSelectors
        .selectAll(state)
        .find(h => h.CurrentResult?.GameID == gameId) ?? initialPHostTable;
export const getGoodRoadHostIds = createSelector(
    [(state: RootState) => getHostsSelectors.selectAll(state)],
    hosts => hosts.filter(h => h.IsGoodRoad).map(h => h.HostId)
);
