import {
    DragonTigerResult,
    DragonTigerRecord,
} from '../../models/games/dragonTiger';
import { BetType } from '../../models/games/dragonTiger/BetType';
import { GameState as DragonTigerState } from '../../models/games/dragonTiger/GameState';
import { GameState } from '../../models/games/enums/GameState';
import { LocalState } from '../../models/games/enums/LocalState';
import { SupportedGameResult } from '../../modules/host/models';
import { isBitActive } from '../bitwiseUtil';
import { GetPokerNumber } from '../pokerCount';
export const convertResultToRecord = (
    result: DragonTigerResult
): DragonTigerRecord => {
    const dragonPoker = result.DragonCard;
    const tigerPoker = result.TigerCard;
    const dragonPoint = GetPokerNumber(dragonPoker);
    const tigerPoint = GetPokerNumber(tigerPoker);
    const record: DragonTigerRecord = {
        gameID: result.GameID,
        result: result.Result,
        shoes: Math.floor(result.GameCount),
        round: result.GameCount % 10000,
        dragonPoker: dragonPoker,
        tigerPoker: tigerPoker,
        dragonWin: isBitActive(result.Result, BetType.DTBDragonWin),
        tigerWin: isBitActive(result.Result, BetType.DTBTigerWin),
        tie: isBitActive(result.Result, BetType.DTBTie),
        dragonPoint: dragonPoint,
        tigerPoint: tigerPoint,
        resultPoint: Math.max(dragonPoint, tigerPoint),
    };

    return record;
};

export const convertResultStringToRecord = (
    currentResult: SupportedGameResult,
    resultString: string,
    result?: number
): DragonTigerRecord => {
    const arr = resultString.split(',');
    const dragonCards = parseInt(arr[0]);
    const tigerCards = parseInt(arr[1]);
    const { FResult, GameID, Result, GameCount } = currentResult;
    const updateResult: DragonTigerResult = {
        DragonCard: dragonCards,
        TigerCard: tigerCards,
        FResult: FResult,
        GameCount: GameCount,
        GameID: GameID,
        Result: result ?? Result,
    };
    const resultRecord = convertResultToRecord(updateResult);
    return resultRecord;
};

export const getLocalState = (
    isRest: boolean,
    state: number,
    isGameResultReleased: boolean
) => {
    if (isRest) {
        return LocalState.REST;
    }

    switch (state) {
        case GameState.RoundCancel:
            return LocalState.ROUND_CANCEL;
        case GameState.Idle:
            return LocalState.IDLE;
        case GameState.Shuffle:
            return LocalState.SHUFFLING;
        case GameState.Betting:
            return LocalState.BETTING;
        case GameState.Started:
        case DragonTigerState.WaitBurn:
        case DragonTigerState.WaitDragonCard:
        case DragonTigerState.WaitTigerCard:
            return LocalState.DEALING;
        case DragonTigerState.DragonWin:
            if (isGameResultReleased) {
                return LocalState.PLAYER;
            } else {
                return LocalState.DEALING;
            }
        case DragonTigerState.TigerWin:
            if (isGameResultReleased) {
                return LocalState.BANKER;
            } else {
                return LocalState.DEALING;
            }
        case DragonTigerState.Draw:
            if (isGameResultReleased) {
                return LocalState.TIE;
            } else {
                return LocalState.DEALING;
            }
    }
    return LocalState.IDLE;
};
