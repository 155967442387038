import { Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { BetRoomSeatIndex } from '../../../../../../models/games/BetRoom';
import { getDisplaySeat } from '../../../../../../utils/games/blackjack';
type SeatLabelProps = {
    sn: BetRoomSeatIndex;
};
export const SeatLabel = memo(function SeatLabel({ sn }: SeatLabelProps) {
    const { t } = useTranslation();
    return (
        <Typography color={theme => theme.palette.primary.main}>
            {t('tableinfo.seat')} {getDisplaySeat(sn)}
        </Typography>
    );
});
