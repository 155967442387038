import { useContext, useState } from 'react';
import { PopupButton } from './PopupButton';
import { PopupPanel } from './PopupPanel';
import { AudioPlayContext } from '../../../../../contexts/AudioPlayContext';

export const LimitInfo = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const changeIsOpen = (o: boolean): void => {
        setIsOpen(o);
        playButtonAudio();
    };
    return (
        <>
            <PopupButton onClickHandler={changeIsOpen} />
            <PopupPanel isOpen={isOpen} onClickHandler={changeIsOpen} />
        </>
    );
};
