import { AppBar } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LobbyUiProvider } from '../../contexts/LobbyUiContext';
import { WebSocketContext } from '../../contexts/WebSocketContext';
import { ROUTE_THANKS4PLAY } from '../../models/Route';
import { CMDPsTableEnter } from '../../models/cmd/live';
import { CMDPsLoginLaucherTime } from '../../models/cmd/live/CMDPsLoginLaucherTime';
import { getGameSate } from '../games/selector';
import { getLandingState, getMainUserState } from '../main/selector';
import { getProgressTimeState } from '../progress/selector';
import { progressTimeActions } from '../progress/slice/time';
import { routeSliceActions } from '../routeController/slice';
import { Footer } from './components/Footer';
import { GameCategory } from './components/GameCategory';
import { GameTables } from './components/GameTables';
import { GridButton } from './components/GridButton';
import { LobbyHeader } from './components/Header';
import { NewUIFloating } from './components/NewUI/NewUIFloating';
import './index.scss';

export const Lobby = () => {
    const dispatch = useDispatch();
    const progressTime = useSelector(getProgressTimeState);
    const { sendCommand } = useContext(WebSocketContext);
    const { PlayerID } = useSelector(getMainUserState);
    const { notToReconnect } = useSelector(getGameSate);
    const { showNewUI } = useSelector(getLandingState);
    useEffect(() => {
        let cmd = new CMDPsTableEnter();
        cmd.HostID = 0;
        sendCommand(cmd);
        if (progressTime.lobby === 0)
            dispatch(progressTimeActions.mark('lobby'));
        if (notToReconnect) {
            //For handle back button
            dispatch(routeSliceActions.goto(ROUTE_THANKS4PLAY));
        }
    }, []);

    useEffect(() => {
        if (progressTime.lobby > 0 && progressTime.launcher > 0) {
            const cmd = new CMDPsLoginLaucherTime();
            cmd.PlayerID = PlayerID;
            cmd.LoginLaucherTime = progressTime.launcher;
            cmd.TimeLobby = progressTime.lobby;
            cmd.TimeLS = progressTime.server;
            cmd.UserAgent = navigator.userAgent;

            cmd.TimeInit = progressTime.command;
            cmd.TimeMaxmind = progressTime.config;
            cmd.TimeJS = progressTime.asset;

            cmd.TimeEngine = progressTime.main;

            sendCommand(cmd);
            console.log('app::lobby::time', cmd);
            dispatch(progressTimeActions.reset('launcher'));
        }
    }, [progressTime]);

    return (
        <div className="lobby-container">
            <LobbyUiProvider>
                <>
                    <AppBar
                        component={'header'}
                        className="lobby-header-container"
                    >
                        <LobbyHeader />
                        <GameCategory />
                    </AppBar>
                    <GameTables />
                    <GridButton />
                    {showNewUI && <NewUIFloating />}
                    <AppBar component={'footer'}>
                        <Footer />
                    </AppBar>
                </>
            </LobbyUiProvider>
        </div>
    );
};
