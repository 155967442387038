import {
    ArrowBack as ArrowBackIcon,
    Close as CloseIcon,
} from '@mui/icons-material';
import {
    AppBar,
    Dialog,
    IconButton,
    SxProps,
    Theme,
    Toolbar,
    Typography,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../theme';

type CloseHandler = () => void;

export type NestedDialogIndexProps = {
    currentIndex: number;
    setCurrentIndex: Dispatch<SetStateAction<number>>;
};

type NestedDialogOwnProps = {
    title: string;
    isOpen: boolean;
    children: JSX.Element;
    onClose?: CloseHandler;
    playButtonAudio?: CloseHandler;
};

type NestedDialogProps = NestedDialogOwnProps & NestedDialogIndexProps;
const IconTheme: SxProps<Theme> = { mr: 2, color: 'inherit', p: 0 };
export const NestedDialog = (props: NestedDialogProps) => {
    const {
        title,
        children,
        currentIndex,
        setCurrentIndex,
        isOpen,
        onClose,
        playButtonAudio,
    } = props;
    const { t } = useTranslation();
    return (
        <Dialog
            className="SettingDialog"
            fullScreen
            open={isOpen}
            disableEscapeKeyDown
        >
            <AppBar position="static" sx={{ backgroundColor: 'primary.main' }}>
                <Toolbar>
                    <IconButton
                        aria-label="close setting"
                        onClick={() => {
                            setCurrentIndex(currentIndex - 1);
                            playButtonAudio && playButtonAudio();
                        }}
                        sx={IconTheme}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h5" color="inherit" component="div">
                        {t(title)}
                    </Typography>
                    <IconButton
                        aria-label="close setting"
                        onClick={() => {
                            setCurrentIndex(-1);
                            onClose && onClose();
                        }}
                        sx={{ ml: 'auto' }}
                    >
                        <CloseIcon
                            sx={{
                                color: theme.palette.text.primary,
                                width: 21,
                                height: 21,
                            }}
                        />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {children}
        </Dialog>
    );
};
