import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DragonTigerStatistics } from '../../../models/games/dragonTiger';
import { RootState } from '../../../store/store';
import { getHostById } from '../../host/slice';
import { ROAD_MAP_COLORS } from '../../lobby/constants';

export const useDragonTigerRoadMapCellColorText = (win: number) => {
    const { t } = useTranslation();
    let color = ROAD_MAP_COLORS.GREEN;
    let text = t('road.draw');
    switch (win) {
        case 0:
            color = ROAD_MAP_COLORS.RED;
            text = t('road.tiger_win');
            break;
        case 1:
            color = ROAD_MAP_COLORS.BLUE;
            text = t('road.dragon_win');
            break;
    }
    return { color, text };
};

export const useDragonTigerStatistics = (hostId: number) => {
    const { Statistics } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [dragon, setDragon] = useState(0);
    const [tiger, setTiger] = useState(0);
    const [tie, setTie] = useState(0);
    const [total, setTotal] = useState(0);
    useEffect(() => {
        const s = Statistics as DragonTigerStatistics;
        let d = 0;
        let t = 0;
        let p = 0;
        if (s !== undefined) {
            d += s.DragonWinCount;
            t += s.TigerWinCount;
            p += s.TieCount;
        }
        setDragon(d);
        setTiger(t);
        setTie(p);
        setTotal(d + t + p);
    }, [Statistics]);
    return {
        dragon,
        tiger,
        tie,
        total,
    };
};
