// import { Done as DoneIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import BaseImage from '../../../components/common/baseImage';
type TickButtonProps = {
    onClick: () => void;
    scale?: number;
};
export const TickButton = (props: TickButtonProps) => {
    const { onClick, scale = 1 } = props;
    return (
        <IconButton onClick={() => onClick()}>
            <BaseImage className="commonUI btnOk" scale={scale} />
        </IconButton>
    );
};
