import { Grid, Stack } from '@mui/material';
import BaseImage from '../../../../../../components/common/baseImage';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { VideoPlayerContext } from '../../../../../../contexts/VideoContext';
import { RootState } from '../../../../../../store/store';
import { getHostById } from '../../../../../host/slice';
import { SicBoStatistics } from '../../../../../../models/games/sicbo';

export const SicboStatisticPoint = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { Statistics } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );

    const [hotList, setHotList] = useState<number[]>();
    const [coldList, setColdList] = useState<number[]>();

    useEffect(() => {
        if (Statistics) {
            const hotNums = (Statistics as SicBoStatistics).HotNums;
            const coldNums = (Statistics as SicBoStatistics).ColdNums;
            if (hotNums && hotNums.length > 0) {
                setHotList(hotNums.slice(0, 5));
            }
            if (coldNums && coldNums.length > 0) {
                setColdList(coldNums.slice(0, 5));
            }
        }
    }, [Statistics]);

    return (
        <>
            <Stack direction={'column'} className="point">
                <Grid item container direction={'row'} className="hot">
                    <Grid item>
                        <BaseImage className="sicbo stat_hot" />
                    </Grid>
                    {[...'-'.repeat(5)].map((_, index) => {
                        const h =
                            (hotList?.at(index) as number | undefined);
                        return (
                            <Grid item key={`point-hot-${index}`}>
                                {h}
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item container direction={'row'} className="cold">
                    <Grid item>
                        <BaseImage className="sicbo stat_cold" />
                    </Grid>
                    {[...'-'.repeat(5)].map((_, index) => {
                        const c =
                        (coldList?.at(index) as number | undefined);
                        return (
                            <Grid item key={`point-cold-${index}`}>
                                {c}
                            </Grid>
                        );
                    })}
                </Grid>
            </Stack>
        </>
    );
};
