import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../store/store';
import { getHostById } from '../../host/slice';
import { useSelector } from 'react-redux';
import { GameState } from '../../../models/games/enums/GameState';

type Prop = {
    hostId: number;
};
const ShuffleLabel = ({ hostId }: Prop) => {
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { t } = useTranslation();
    const [isShow, setIsShow] = useState(false);
    useEffect(() => {
        setIsShow(host.CurrentState == GameState.Shuffle);
    }, [host.CurrentState]);

    return isShow ? (
        <div
            style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '0',
                pointerEvents: 'none',
                backgroundColor: 'rgba(0,0,0,0.5)',
                fontSize: '60px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {t('game.shuffling')}
        </div>
    ) : (
        <></>
    );
};
export default ShuffleLabel;
