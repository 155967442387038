export enum ROAD_MAP_TYPE {
    BEAD_ROAD, // 珠盤
    BIG_ROAD, // 大路
    BIG_EYE_ROAD, // 大眼仔
    SMALL_ROAD, // 小路
    COCKROACH_ROAD, // 曱甴路
    POINT_ROAD, // 點數路
    EVEN_ODD_ROAD, // 單雙路
    BIG_SMALL_ROAD, // 大小路
    DICE = 10,
    RED_BLACK, // roulette red black
}

export enum ROAD_MAP_COLORS {
    RED = '#c70000',
    BLUE = '#2d30b1',
    GREEN = '#009303',
    WHITE = '#ffffff',
    DARK_GREEN = '#196260',
    GRAY = '#a2a2a2',
    LIGHT_BLUE = '#005CCA',
    ORANGE = '#F0812C',
    BLACK = '#000',
    TRANSPARENT = '#fff0',
}

export enum REPORT_COLORS {
    TEXT = '#876F4D',
    TITLE_TEXT = '#c3a475',
}
export enum REPORT_SIZE {
    TEXT = '3.89vw',
}
