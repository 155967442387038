import { Box } from '@mui/material';
import BaseImage from '../../../components/common/baseImage';
import { useSelector } from 'react-redux';
import { getMainUserState } from '../../main/selector';
import { RootState } from '../../../store/store';

type Prop = {
    color?: string;
};
const TrialImage = ({ color }: Prop) => {
    const { IsTrial } = useSelector((state: RootState) =>
        getMainUserState(state)
    );
    const classname = color
        ? `trial_image trial_${color}`
        : 'trial_image trial_black';
    return (
        <Box
            position={'absolute'}
            top={'50%'}
            left={'50%'}
            sx={{
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'none',
            }}
        >
            {IsTrial && (
                <BaseImage
                    className={classname}
                    isMultiLang={true}
                    scale={0.85}
                />
            )}
        </Box>
    );
};
export default TrialImage;
