import { memo, useEffect, useState } from 'react';
import {
    BaccaratRoadMapCellProps,
    RESOLUTION,
    STROKE_WIDTH,
    useBaccaratPointRoadMapCellColorText,
} from '../hook';
import { ROAD_MAP_COLORS } from '../../../lobby/constants';
import { motion, useAnimationControls } from 'framer-motion';

const PointRoadCell = (props: BaccaratRoadMapCellProps) => {
    const {
        record: { Win, WinPoint, AskType },
    } = props;
    const { color, text } = useBaccaratPointRoadMapCellColorText(Win, WinPoint);
    const [askColor, setAskColor] = useState(ROAD_MAP_COLORS.RED);
    const askRoadAnimation = useAnimationControls();
    const anim = () => {
        askRoadAnimation.start({
            opacity: [1, 1, 0, 1, 1, 0],
            transition: { duration: 1 },
        });
    };
    useEffect(() => {
        if (AskType === 0) {
            setAskColor(ROAD_MAP_COLORS.RED);
        } else if (AskType === 1) {
            setAskColor(ROAD_MAP_COLORS.BLUE);
        }
        if (AskType >= 0) {
            anim();
        }
    }, [AskType]);
    return (
        <svg
            viewBox={`0 0 ${RESOLUTION} ${RESOLUTION}`}
            className="roadmap-cell"
        >
            {Win >= 0 && (
                <>
                    <circle
                        cx={RESOLUTION / 2}
                        cy={RESOLUTION / 2}
                        r={RESOLUTION / 2 - STROKE_WIDTH / 2}
                        fill="transparent"
                        stroke={color}
                        strokeWidth={STROKE_WIDTH}
                    />
                    <text
                        x={RESOLUTION / 2}
                        y={RESOLUTION / 2 + 1}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill={color}
                        fontSize={RESOLUTION * 0.75}
                    >
                        {text}
                    </text>
                </>
            )}
            {AskType >= 0 && (
                <motion.circle
                    animate={askRoadAnimation}
                    initial={{ opacity: 0 }}
                    cx={RESOLUTION / 2}
                    cy={RESOLUTION / 2}
                    r={RESOLUTION / 2 - STROKE_WIDTH / 2}
                    fill="transparent"
                    stroke={askColor}
                    strokeWidth={STROKE_WIDTH}
                />
            )}
        </svg>
    );
};
const areEquals = (
    prev: BaccaratRoadMapCellProps,
    next: BaccaratRoadMapCellProps
) => {
    const isSameWin = prev.record.Win === next.record.Win;
    const isSameWinPoint = prev.record.WinPoint === next.record.WinPoint;
    return isSameWin && isSameWinPoint;
};
export default memo(PointRoadCell, areEquals);
