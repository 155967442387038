import { Box, Typography } from "@mui/material";
import { RedeemProps } from "./PromotionRecord";
import { useTranslation } from "react-i18next";
import { dateToString, timeToString } from "../PromotionUtil";
import { displayCurrencyName, mergeCurrencyName, numberFormat } from "../../../../utils/commonFunc";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { getMainUserState } from "../../../main/selector";
import { useContext } from "react";
import { CommonConfigContext } from "../../../../contexts/ConfigContext";

enum EventRewardType{
    Gold = 1,
    Silver,
    Bronze
}
type RewardsType = {
    [key: number]: string;
} & {
    [key in EventRewardType]: string;
};
const rewards:RewardsType = {
    [EventRewardType.Gold]: 'promotion_tool.gold_rewards',
    [EventRewardType.Silver]: 'promotion_tool.silver_rewards',
    [EventRewardType.Bronze]: 'promotion_tool.bronze_rewards'
};

type PromotionRecordCellProps = {
    record: RedeemProps;
};
export const PromotionRecordCell = (props: PromotionRecordCellProps) => {
    const record = props.record;
    const { t } = useTranslation();

    //Currency handling
    const { showCurrencyName } = useContext(CommonConfigContext);
    const { CurrencyName, LobbyCode } = useSelector((state: RootState) => getMainUserState(state));
    const currency = mergeCurrencyName(useSelector((state: RootState) => (getMainUserState(state)).CurrencyName));
    const isShowCurrency = displayCurrencyName(showCurrencyName, CurrencyName, LobbyCode);

    const getRewardTime = (timestamp: number): string => {
        const date = new Date(timestamp * 1000);
        return `${dateToString(date, false, "-")} ${timeToString(date, false, ":")}`;
    }

    return (
        <Box sx={{ display:'flex', flexWrap:'wrap', alignContent:'center', alignItems:'center', width:'100%', height:'72px', overflow:'hidden' }}>
            <Typography sx={{ marginLeft:'16px', width:'200px', fontSize:'20px' }}>{t(rewards[record.PrizeRank])}</Typography>
            <Typography sx={{ marginRight:'16px', width:'268px', fontSize:'20px', textAlign:'right' }}>{`+ ${numberFormat(record.ResultAmount/100)} ${isShowCurrency? currency:''}`}</Typography>
            <Typography sx={{ marginLeft:'16px', width:'268px', fontSize:'16px', color:'#979797' }}>{`${t('promotion_tool.redeem_id')} ${record.RedeemID.toString()}`}</Typography>
            <Typography sx={{ marginRight:'16px', width:'200px', fontSize:'16px', color:'#979797', textAlign:'right' }}>{getRewardTime(record.RedeemTime)}</Typography>
            {!record.LastRecord?
                <Box sx={{ backgroundColor:'#ab8b56', width:'476px', height:'1px', opacity:'0.2', marginLeft:'12px', marginRight:'12px' }}/>:<></>
            }
        </Box>
    )
}