import {
    ArrowLeft as ArrowLeftIcon,
    ArrowRight as ArrowRightIcon,
} from '@mui/icons-material';
import { Box, Button, Grid, Stack } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
// import { CommonConfigContext } from '../../../../../../contexts/ConfigContext';
import BScroll from '@better-scroll/core';
import { BScrollConstructor } from '@better-scroll/core/dist/types/BScroll';
import Slide from '@better-scroll/slide';
import { BaseTypography } from '../../../../../../components/common/baseText/BaseTypography';
import { VideoPlayerContext } from '../../../../../../contexts/VideoContext';
import { MobPref } from '../../../../../../hooks/storage/useLocalStorage';
import { useBalance } from '../../../../../../hooks/useBalance';
import { StateMsgType } from '../../../../../../models/Popup';
import { BetType } from '../../../../../../models/games/baccarat/BetType';
import { GameState as CommonGameState } from '../../../../../../models/games/enums/GameState';
import { Bet } from '../../../../../../models/host/BetAmount';
import { RootState } from '../../../../../../store/store';
import { checkBetsAvailed } from '../../../../../../utils/commonFunc';
import {
    getCurrentGameId,
    getGameSate,
    getTotalPendingBetsAmount,
    isReBetOn,
} from '../../../../../games/selector';
import { gameSliceActions } from '../../../../../games/slice';
import { getHostById } from '../../../../../host/slice';
import { inGamePopupSliceActions } from '../../../../../popup/inGameSlice';
import { ChipList } from '../ChipList';
import { ChipSelector } from '../ChipSelector';
import { DoubleBetButton } from '../DoubleBetButton';
import { ReBetButton } from '../ReBetButton';
import { amountOverBetLimit } from '../utils';
import { BaccaratBetArea } from './BaccaratBetArea';
import { SuperSixBetArea } from './SuperSixBetArea';
import { getBetAreaProps } from './betAreaInfo';
import './index.scss';

export const getChipListMask = (k: string | undefined): string => {
    if (k === 'c') {
        return '12,15,19'; //#0c0f13
    }
    return getTableBetAreaColor(k);
};

export const getTableBetAreaColor = (k: string | undefined): string => {
    switch (k) {
        case 'd':
            return '122,35,20'; //#7a2314
        case 'p':
            return '121,79,177'; //794fb1
        case 'e':
        case 'm':
            return '3,66,31'; //03421f
        case 'a':
            return '100,62,64'; //643E40
        case 'c':
            return '53,68,87'; //354457
        default:
            return '27,57,27'; //1b391b
    }
};

const getTabIndex = (betType: number): number => {
    let tabIndex: number = -1;

    const tabs = [
        [
            BetType.BBPlayerPair,
            BetType.BBPlayerNatural,
            BetType.BBSLuckySix,
            BetType.BBBankerNatural,
            BetType.BBBankerPair,
            BetType.BBPlayerWin,
            BetType.BBTie,
            BetType.BBBankerWin,
        ],
        [
            BetType.BBSSPlayerPair,
            BetType.BBSSPlayerNatural,
            BetType.BBSSSLuckySix,
            BetType.BBSSBankerNatural,
            BetType.BBSSBankerPair,
            BetType.BBSSPlayerWin,
            BetType.BBSSTie,
            BetType.BBSSBankerWin,
        ],
        [BetType.BBCowPlayerWin, BetType.BBCowTie, BetType.BBCowBankerWin],
    ];

    tabs.forEach((tab, idx) => {
        if (tab.indexOf(betType) > -1) {
            tabIndex = idx;
        }
    });

    for (let i = 0; i < tabs.length; i++) {
        if (tabs[i].indexOf(betType) > -1) {
            return i;
        }
    }

    return tabIndex;
};
const betPageList = ['baccarat', 'super_six'];
export const BetAreaPanel = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const {
        IsRest,
        CurrentState,
        ResultReleased,
        CurrentResult,
        GameType,
        Group,
    } = useSelector((state: RootState) => getHostById(state, hostId));
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [tableColor, setTableColor] = useState<string>('');
    const [isShowReBet, setIsShowReBet] = useState<boolean>(false);
    const reBetButtonEnable = useSelector((state: RootState) =>
        isReBetOn(state)
    );
    const [isBetting, setIsBetting] = useState<boolean>(false);
    const [showLeftBtn, setShowLeftBtn] = useState<boolean>(false);
    const [doubleBets, setDoubleBets] = useState<Array<Bet>>([]);

    const { doubleBet, reBet, prevBets, pendingBets, confirmedBets } =
        useSelector(getGameSate);
    useEffect(() => {
        const bets = new Array<Bet>();
        for (const cb of confirmedBets) {
            bets.push({ Type: cb.Type, Amount: cb.Amount, GameId: cb.GameId });
        }
        for (const pb of pendingBets) {
            const index = bets.findIndex(cb => cb.Type === pb.Type);
            if (index === -1) bets.push(pb);
            else bets[index].Amount += pb.Amount;
        }
        setDoubleBets(bets);
    }, [pendingBets, confirmedBets]);
    const addingBet = useRef(false);
    const [toAddBets, setToAddBets] = useState<Array<Bet>>();
    const currentGameId = useSelector(getCurrentGameId);
    const dispatch = useDispatch();
    const { availableBalance } = useBalance();
    const allPendingBetsAmount = useSelector(getTotalPendingBetsAmount);

    const getDisableBetTypes = (bets: Array<Bet>) => {
        const disableBetTypes: Array<number> = [];
        bets.forEach(bet => {
            if (CurrentResult && CurrentResult.GameCount) {
                const currentRound = CurrentResult.GameCount % 10000;
                const betAreaProps = getBetAreaProps(bet.Type);
                if (betAreaProps && betAreaProps.roundNumDisable) {
                    const roundNumDisable = betAreaProps.roundNumDisable;
                    if (roundNumDisable && currentRound > roundNumDisable) {
                        // Disable
                        disableBetTypes.push(bet.Type);
                    }
                }
            }
        });
        return disableBetTypes;
    };
    const doPreBet = (bets: Array<Bet>) => {
        batch(() => {
            bets.forEach(bet => {
                dispatch(
                    gameSliceActions.onPendingBet({
                        GameId: currentGameId,
                        Type: bet.Type,
                        Amount: bet.Amount,
                    })
                );
            });
        });
    };

    useEffect(() => {
        setIsBetting(!IsRest && CurrentState === CommonGameState.Betting);
    }, [IsRest, CurrentState, ResultReleased]);

    useEffect(() => {
        setIsShowReBet(isBetting ? reBetButtonEnable : false);
    }, [reBetButtonEnable, isBetting]);

    useEffect(() => {
        if (doubleBet) {
            if (doubleBets && !toAddBets && !addingBet.current) {
                addingBet.current = true;
                setToAddBets(doubleBets);
            }
            dispatch(gameSliceActions.onDoubleBet({ onClick: false }));
        }
    }, [doubleBet, doubleBets]);
    useEffect(() => {
        if (reBet) {
            if (prevBets && !toAddBets && !addingBet.current) {
                addingBet.current = true;
                setToAddBets(prevBets);
            }
            dispatch(gameSliceActions.onReBet({ onClick: false }));
        }
    }, [reBet, prevBets]);
    useEffect(() => {
        if (toAddBets) {
            const disableBetTypes = getDisableBetTypes(toAddBets);
            const availableBets = checkBetsAvailed({
                hostId: hostId,
                bets: toAddBets,
                amountOverBetLimit: amountOverBetLimit,
                totalPendingBetAmount: allPendingBetsAmount,
                totalPendingWithHoldAmount: 0,
                availableBalance: availableBalance,
                disableBetTypes: disableBetTypes,
            });
            if (availableBets.isNotEnoughMoney) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'system.not_enough_money',
                        GameType,
                        StateMsgType.betInfo_Fail,
                        currentGameId
                    )
                );
            } else if (availableBets.isOutOfBetLimit) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'system.bet_out_of_limit_red',
                        GameType,
                        StateMsgType.betInfo_Fail,
                        currentGameId
                    )
                );
            } else {
                const toBets = availableBets.bets;
                const betTypes = toBets.map((bet: Bet) => ({
                    type: bet.Type,
                }));

                let isCurrTab = false;
                let lastBetType: number = betTypes.length - 1;
                for (let i = betTypes.length - 1; i >= 0; i--) {
                    if (disableBetTypes.indexOf(betTypes[i].type) >= 0)
                        continue;
                    const destTab = getTabIndex(betTypes[i].type);
                    if (currentTab === destTab) {
                        lastBetType = i;
                        isCurrTab = true;
                        break;
                    }
                }

                let delay: number = 0;
                if (!isCurrTab) {
                    while (
                        lastBetType >= 0 &&
                        disableBetTypes.length > 0 &&
                        disableBetTypes.indexOf(betTypes[lastBetType].type) >= 0
                    ) {
                        lastBetType--;
                    }
                    if (lastBetType < 0) lastBetType = betTypes.length - 1;
                    const destTab = getTabIndex(betTypes[lastBetType].type);
                    if (scrollObj) {
                        scrollObj.goToPage(destTab, 0);
                        delay = Math.abs(currentTab - destTab) * 800;
                    }
                }

                if (delay > 0) {
                    setTimeout(() => doPreBet(toBets), delay);
                } else {
                    doPreBet(toBets);
                }

                dispatch(
                    gameSliceActions.setSelectedBetType(betTypes[lastBetType])
                );
                if (availableBets.haveDisabledBetType) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.have_cannot_rebet_zone',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                } else if (availableBets.haveBetLimitAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.bet_exceed_limit_allin',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                } else if (availableBets.isAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.all_in',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                }
            }
            setToAddBets(undefined);
            addingBet.current = false;
        }
    }, [toAddBets]);

    const handleLeftButton = () => {
        if (scrollObj) {
            scrollObj.prev();
        }
    };
    const handleRightButton = () => {
        if (scrollObj) {
            scrollObj.next();
        }
    };

    const ref = useRef<HTMLDivElement>(null);
    const [scrollObj, setScrollObj] = useState<BScrollConstructor>();
    useEffect(() => {
        if (ref && ref.current) {
            const lsSicBoTab = localStorage.getItem(
                `MobPref_${MobPref.BACC_BET_MODE}`
            );
            const numLsTab = Number(lsSicBoTab) ?? 0;
            setShowLeftBtn(numLsTab !== 0);
            setCurrentTab(numLsTab);
            BScroll.use(Slide);
            const newBScroll = new BScroll(ref.current as HTMLDivElement, {
                scrollX: true,
                scrollY: false,
                click: true,
                slide: {
                    loop: false,
                    autoplay: false,
                    threshold: 100,
                    startPageXIndex: numLsTab,
                },
                momentum: false,
            });
            newBScroll.on('slidePageChanged', (page: { pageX: number }) => {
                if (page) {
                    const tab = page.pageX;
                    setShowLeftBtn(tab !== 0);
                    setCurrentTab(tab);
                    dispatch(gameSliceActions.onCancelPendingBet());
                    localStorage.setItem(
                        'MobPref_' + MobPref.BACC_BET_MODE,
                        JSON.stringify(tab)
                    );
                }
            });
            setScrollObj(newBScroll);
        }
        return () => {
            if (ref && ref.current && scrollObj) {
                scrollObj.destroy();
            }
        };
    }, [ref]);

    useEffect(() => {
        if (Group) {
            setTableColor(getTableBetAreaColor(Group[0]));
        }
    }, [Group]);

    return (
        <>
            <Stack className="baccarat-bet-area">
                <Box
                    sx={{
                        position: 'absolute',
                        width: '540px',
                        height: '331px',
                        zIndex: -3,
                        background: `rgba(${tableColor})`,
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        width: '540px',
                        height: '331px',
                        //SA SceneTradBacc -> getTableColorLayer -> [0,60,255]   23.5% = (60 / 255 * 100)
                        background: `linear-gradient(to bottom, rgba(${tableColor},0) 0%, rgba(${tableColor},1) 23.5%, rgba(${tableColor},1)) 100%`,
                    }}
                />
                {(Group[0] === 'c' || Group[0] === 'p') && (
                    <Box
                        sx={{
                            position: 'absolute',
                            width: '540px',
                            height: '331px',
                            background: `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.32) 23.5%, rgba(0,0,0,0.8)) 100%`,
                        }}
                    />
                )}
                <Grid
                    item
                    container
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                    sx={{ height: '24px' }}
                >
                    <Button
                        onClick={() => handleLeftButton()}
                        sx={{ minWidth: '20px', width: '20px', height: '20px' }}
                    >
                        <ArrowLeftIcon
                            fontSize="large"
                            sx={{
                                color: `${showLeftBtn ? '#ffffff' : '#808080'}`,
                            }}
                        />
                    </Button>
                    <Grid
                        item
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{
                            width: '53px',
                            backgroundColor: 'rgba(0,0,0,0.3)',
                            borderRadius: '10px',
                        }}
                    >
                        <BaseTypography fontSize={14}>
                            {t(`baccarat.${betPageList[currentTab]}_short`)}
                        </BaseTypography>
                    </Grid>
                    <Button
                        onClick={() => handleRightButton()}
                        sx={{
                            minWidth: '20px',
                            width: '20px',
                            height: '20px',
                            marginRight: '9px',
                        }}
                    >
                        <ArrowRightIcon
                            fontSize="large"
                            sx={{
                                color: `${
                                    !showLeftBtn ? '#ffffff' : '#808080'
                                }`,
                            }}
                        />
                    </Button>
                </Grid>
                <Box sx={{ zIndex: 1 }}>
                    <Box
                        ref={ref}
                        sx={{
                            width: 540,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            touchAction: 'pan-x',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Box className="tap-area">
                                <BaccaratBetArea />
                            </Box>
                            <Box className="tap-area">
                                <SuperSixBetArea />
                            </Box>
                        </Box>
                    </Box>
                    <Grid
                        item
                        container
                        className="chip-container"
                        sx={{ paddingTop: '1px', paddingLeft: '5px' }}
                    >
                        <ChipSelector />
                        <ChipList />
                        {isShowReBet ? <ReBetButton /> : <DoubleBetButton />}
                    </Grid>
                </Box>
            </Stack>
        </>
    );
};
