import { Bet } from '../host/BetAmount';

// model
export type BetRoom = {
    HostId: number;
    Info: BetRoomInfo;
};
export type BetRoomInfo = {
    0: PlayerBetInfo;
    1: PlayerBetInfo;
    2: PlayerBetInfo;
    3: PlayerBetInfo;
    4: PlayerBetInfo;
    5: PlayerBetInfo;
    6: PlayerBetInfo;
    7: PlayerBetInfo;
};
export type BetRoomSeatIndex = 1 | 2 | 3 | 4 | 5 | 6 | 7;
export type PlayerBetInfo = {
    PlayerId: number;
    Bet: Array<Bet>;
};
export const getEmptyBetInfo = () => ({
    PlayerId: 0,
    Bet: new Array<Bet>(),
});
export const getNewBetRoom = (hostId: number): BetRoom => ({
    HostId: hostId,
    Info: {
        0: getEmptyBetInfo(),
        1: getEmptyBetInfo(),
        2: getEmptyBetInfo(),
        3: getEmptyBetInfo(),
        4: getEmptyBetInfo(),
        5: getEmptyBetInfo(),
        6: getEmptyBetInfo(),
        7: getEmptyBetInfo(),
    },
});
// payload
export type StandPayload = {
    HostId: number;
    Seat: number;
};
export type StandsPayload = {
    HostId: number;
    Seats: Array<number>;
};
export type SeatPayload = {
    PlayerId: number;
} & StandPayload;
export type BetPayload = {
    HostId: number;
    Seat: number;
    PlayerId: number;
    Bets: Array<Bet>;
};
