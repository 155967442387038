import { RoadMapProps } from '.';
import { GameType as CommonGameType } from '../../../../models/games/enums/GameType';
import { AndarBaharSwitchTableMap } from '../../../roadmap/andarBahar';
import { SwitchTableMap } from '../../../roadmap/baccarat/map';
import { BlackjackSwitchTableMap } from '../../../roadmap/blackjack';
import { DragonTigerSwitchTableMap } from '../../../roadmap/dragonTiger';
import { PokDengSwitchTableMap } from '../../../roadmap/pokdeng';
import { RouletteSwitchTableMap } from '../../../roadmap/roulette';
import { SedieSwitchTableMap } from '../../../roadmap/sedie';
import { SicBoSwitchTableMap } from '../../../roadmap/sicbo';
import { TeenPattiSwitchTableMap } from '../../../roadmap/teenpatti';
export const SwitchTableRoadMap = (props: RoadMapProps) => {
    const { hostId, gameType } = props;
    switch (gameType) {
        case CommonGameType.Baccarat:
            return <SwitchTableMap hostId={hostId} />;
        case CommonGameType.Dragon:
            return <DragonTigerSwitchTableMap hostId={hostId} />;
        case CommonGameType.Blackjack:
            return <BlackjackSwitchTableMap hostId={hostId} />;
        case CommonGameType.PokDeng:
            return <PokDengSwitchTableMap hostId={hostId} />;
        case CommonGameType.SeDie:
            return <SedieSwitchTableMap hostId={hostId} />;
        case CommonGameType.SicBo:
            return <SicBoSwitchTableMap hostId={hostId} />;
        case CommonGameType.Roulette:
            return <RouletteSwitchTableMap hostId={hostId} />;
        case CommonGameType.AndarBahar:
            return <AndarBaharSwitchTableMap hostId={hostId} />;
        case CommonGameType.TeenPatti2020:
            return <TeenPattiSwitchTableMap hostId={hostId} />;
        default:
            return <>Host no exist</>;
    }
};
