import { Stack, Typography } from '@mui/material';
import { useInGamePopup } from '../../../hooks/useInGamePopup';
import { getHostById } from '../../host/slice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useEffect, useState } from 'react';
import { getLocalState } from '../../../utils/games/baccarat';
import { LocalState } from '../../../models/games/enums/LocalState';
import { ROAD_MAP_COLORS } from '../../lobby/constants';
import { useTranslation } from 'react-i18next';

type Prop = {
    hostId: number;
};
export const MultiBetGameResult = (props: Prop) => {
    const { hostId } = props;
    const { display, key } = useInGamePopup(hostId);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const [localState, setLocalState] = useState<number>(-1);
    const [show, setShow] = useState<boolean>(false);
    const [stateLabelKey, setStateLabelKey] = useState<string>('');
    const [stateColor, setStateColor] = useState<string>(ROAD_MAP_COLORS.BLACK);
    const [displayAmount, setDisplayAmount] = useState<string>('');
    const { t } = useTranslation();
    useEffect(() => {
        if (display) {
            setDisplayAmount(key.amount);
        }
    }, [display]);
    useEffect(() => {
        if (!show) {
            setDisplayAmount('');
        }
    }, [show]);
    useEffect(() => {
        setLocalState(
            getLocalState(
                host.IsRest,
                host.CurrentState,
                host.ResultReleased,
                host.IsRoundCancel === true
            )
        );
    }, [host.IsRest, host.CurrentState, host.ResultReleased]);
    useEffect(() => {
        if (localState !== -1) {
            if (localState === LocalState.BETTING) {
                setShow(false);
            } else {
                if (localState === LocalState.PLAYER) {
                    setStateLabelKey('game.player');
                    setStateColor(ROAD_MAP_COLORS.BLUE);
                    setShow(true);
                } else if (localState === LocalState.BANKER) {
                    setStateLabelKey('game.banker');
                    setStateColor(ROAD_MAP_COLORS.RED);
                    setShow(true);
                } else if (localState === LocalState.TIE) {
                    setStateLabelKey('game.tie');
                    setStateColor(ROAD_MAP_COLORS.GREEN);
                    setShow(true);
                } else if (localState === LocalState.DEALING) {
                    setShow(false);
                } else if (localState === LocalState.SHUFFLING) {
                    setShow(false);
                } else if (localState === LocalState.REST) {
                    setShow(false);
                } else {
                    setShow(false);
                }
            }
        } else {
            setShow(false);
        }
    }, [localState]);
    return (
        <>
            {show ? (
                <Stack
                    sx={{
                        width: '100%',
                        height: '100px',
                        backgroundColor: stateColor,
                        borderRadius: '5px',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: '20px',
                        }}
                    >
                        {t(stateLabelKey)}
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: '20px',
                        }}
                    >
                        {displayAmount}
                    </Typography>
                </Stack>
            ) : (
                <></>
            )}
        </>
    );
};
