import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { POPUP_CONFIRM_ACTION, POPUP_TYPE } from '../../models/Popup';
const open = {
    reducer(state: PopupState, action: PayloadAction<otherType>) {
        if (!state.leaveFromConfirm || state.clearLoginDataWhenLeave) {
            //not popup new message after leave popup
            state.isOpen = true;
            state.key = action.payload.key;
            state.type = action.payload.type;
            state.dismiss = action.payload.dismiss;
            state.confirmKey = action.payload.confirmKey;
            state.leaveFromConfirm = action.payload.leaveFromConfirm;
            state.clearLoginDataWhenLeave =
                action.payload.clearLoginDataWhenLeave;
            state.params = action.payload.params;
        }
    },
    prepare(
        payload: string | Array<string>,
        confirmFunc: POPUP_CONFIRM_ACTION = POPUP_CONFIRM_ACTION.NONE,
        type: POPUP_TYPE = POPUP_TYPE.ALERT,
        dismiss: number = 0,
        leaveFromConfirm: boolean = false,
        clearLoginDataWhenLeave: boolean = true,
        params: Record<string, string> = {}
    ) {
        // Default
        // type = ALERT
        // dismiss = 0
        // confirmFunc = undefined
        return {
            payload: {
                key: payload,
                confirmKey: confirmFunc,
                type: type,
                dismiss: dismiss,
                leaveFromConfirm: leaveFromConfirm,
                clearLoginDataWhenLeave: clearLoginDataWhenLeave,
                params: params,
            },
        };
    },
};
const close = (state: PopupState) => {
    state.isOpen = false;
};
const triggerConfirm = (
    state: PopupState,
    action: PayloadAction<POPUP_CONFIRM_ACTION>
) => {
    state.confirmValue = action.payload;
};
type openType = {
    isOpen: boolean;
};
type otherType = {
    key: string | Array<string>;
    type: POPUP_TYPE;
    dismiss: number; //ms, set timeout close,
    confirmKey?: POPUP_CONFIRM_ACTION; //for storage
    confirmValue?: POPUP_CONFIRM_ACTION; //for fire back to the location
    leaveFromConfirm?: boolean; //for goto returnUrl after confirm
    clearLoginDataWhenLeave?: boolean; //for error 2001
    params?: Record<string, string>;
};
export type CountryMessage = {
    messageKey: string | Array<string>;
    params?: Record<string, string>;
};
export type PopupState = openType & otherType;
const initialPopupState = {
    isOpen: false,
    key: '',
    type: POPUP_TYPE.ALERT,
    confirmKey: POPUP_CONFIRM_ACTION.NONE,
    confirmValue: POPUP_CONFIRM_ACTION.NONE,
    dismiss: 0,
    leaveFromConfirm: false,
    clearLoginDataWhenLeave: true,
    params: undefined,
} as PopupState;
export const POPUP_KEY = 'app::popup';
const popupSlice = createSlice({
    name: POPUP_KEY,
    initialState: initialPopupState,
    reducers: {
        open,
        close,
        triggerConfirm,
    },
});
export const popupSliceReducer = popupSlice.reducer;
export const popupSliceActions = popupSlice.actions;
