import { Grid } from '@mui/material';
// import { useContext, useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import BetArea from '../../../../../../components/common/betArea';
// import { VideoPlayerContext } from '../../../../../../contexts/VideoContext';
import { BetType } from '../../../../../../models/games/pokdeng/BetType';
// import { RootState } from '../../../../../../store/store';
// import { ExchangeBetAmount } from '../../../../../../utils/commonFunc';
// import { getHostById } from '../../../../../host/slice';
// import { getMainUserState } from '../../../../../main/selector';
import { amountOverBetLimit, isOppositeBetting } from '../utils';
import betAreaJson from './betArea.json';
type Prop = {
    tableColor: string;
};
const { betType } = betAreaJson;
export const PokDengBetArea = (prop: Prop) => {
    const { tableColor } = prop;
    return (
        <>
            <Grid
                item
                container
                flexWrap={'nowrap'}
                justifyContent={'space-between'}
                sx={{
                    marginBottom: '1px',
                    background: tableColor,
                }}
            >
                <BetArea
                    style={betType[BetType.PDBPlayer1Pair].style}
                    confirmPanelStyle={
                        betType[BetType.PDBPlayer1Pair].confirmPanelStyle
                    }
                    imageName={betType[BetType.PDBPlayer1Pair].image}
                    isMultiLang={true}
                    betType={BetType.PDBPlayer1Pair}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                />
                <BetArea
                    style={betType[BetType.PDBPlayer2Pair].style}
                    confirmPanelStyle={
                        betType[BetType.PDBPlayer2Pair].confirmPanelStyle
                    }
                    imageName={betType[BetType.PDBPlayer2Pair].image}
                    isMultiLang={true}
                    betType={BetType.PDBPlayer2Pair}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                />
                <BetArea
                    style={betType[BetType.PDBPlayer3Pair].style}
                    confirmPanelStyle={
                        betType[BetType.PDBPlayer3Pair].confirmPanelStyle
                    }
                    imageName={betType[BetType.PDBPlayer3Pair].image}
                    isMultiLang={true}
                    betType={BetType.PDBPlayer3Pair}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                />
                <BetArea
                    style={betType[BetType.PDBPlayer4Pair].style}
                    confirmPanelStyle={
                        betType[BetType.PDBPlayer4Pair].confirmPanelStyle
                    }
                    imageName={betType[BetType.PDBPlayer4Pair].image}
                    isMultiLang={true}
                    betType={BetType.PDBPlayer4Pair}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                />
                <BetArea
                    style={betType[BetType.PDBPlayer5Pair].style}
                    confirmPanelStyle={
                        betType[BetType.PDBPlayer5Pair].confirmPanelStyle
                    }
                    imageName={betType[BetType.PDBPlayer5Pair].image}
                    isMultiLang={true}
                    betType={BetType.PDBPlayer5Pair}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                />
            </Grid>
            <Grid
                item
                container
                flexWrap={'nowrap'}
                justifyContent={'space-between'}
                sx={{
                    marginBottom: '4px',
                }}
            >
                <BetArea
                    style={betType[BetType.PDBPlayer1Win].style}
                    confirmPanelStyle={
                        betType[BetType.PDBPlayer1Win].confirmPanelStyle
                    }
                    imageName={betType[BetType.PDBPlayer1Win].image}
                    isMultiLang={true}
                    betType={BetType.PDBPlayer1Win}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                />
                <BetArea
                    style={betType[BetType.PDBPlayer2Win].style}
                    confirmPanelStyle={
                        betType[BetType.PDBPlayer2Win].confirmPanelStyle
                    }
                    imageName={betType[BetType.PDBPlayer2Win].image}
                    isMultiLang={true}
                    betType={BetType.PDBPlayer2Win}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                />
                <BetArea
                    style={betType[BetType.PDBPlayer3Win].style}
                    confirmPanelStyle={
                        betType[BetType.PDBPlayer3Win].confirmPanelStyle
                    }
                    imageName={betType[BetType.PDBPlayer3Win].image}
                    isMultiLang={true}
                    betType={BetType.PDBPlayer3Win}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                />
                <BetArea
                    style={betType[BetType.PDBPlayer4Win].style}
                    confirmPanelStyle={
                        betType[BetType.PDBPlayer4Win].confirmPanelStyle
                    }
                    imageName={betType[BetType.PDBPlayer4Win].image}
                    isMultiLang={true}
                    betType={BetType.PDBPlayer4Win}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                />
                <BetArea
                    style={betType[BetType.PDBPlayer5Win].style}
                    confirmPanelStyle={
                        betType[BetType.PDBPlayer5Win].confirmPanelStyle
                    }
                    imageName={betType[BetType.PDBPlayer5Win].image}
                    isMultiLang={true}
                    betType={BetType.PDBPlayer5Win}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    isFlash={true}
                />
            </Grid>
        </>
    );
};
