import { Box, Stack } from '@mui/material';
import { Bet } from '../../../report/slice/betRecord';
import { GameResult as GameResultEnum } from '../../../../models/games/roulette/GameResult';
import { isBitActive } from '../../../../utils/bitwiseUtil';

type RouletteResultRecordPanelProps = {
    bet: Bet;
};
export const RouletteResultRecordPanel = (
    props: RouletteResultRecordPanelProps
) => {
    const { bet } = props;

    const resultToColor = () => {
        if (bet.Result === '0') {
            return '#009203';
        } else if (isBitActive(bet.WinSlot, GameResultEnum.RRRed)) {
            return '#c70000';
        } else if (isBitActive(bet.WinSlot, GameResultEnum.RRBlack)) {
            return 'black';
        }
    };

    return (
        <>
            <Stack justifyContent="center" sx={{ height: '86px' }}>
                <Box
                    bgcolor={resultToColor()}
                    height={30}
                    width={40}
                    display={'flex'}
                    justifyContent={'center'}
                    alignContent={'center'}
                    alignItems={'center'}
                    borderRadius={'3px'}
                    border={`2px solid ${
                        resultToColor() == 'black' ? '#333' : 'unset'
                    }`}
                >
                    {bet.Result}
                </Box>
            </Stack>
        </>
    );
};
