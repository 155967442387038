import { useContext } from 'react';
import { VideoPlayerContext } from '../../../../../../contexts/VideoContext';
import { WebSocketContext } from '../../../../../../contexts/WebSocketContext';
import { CMDPsStandUp } from '../../../../../../models/cmd/live';
import { SeatAreaContext } from '../../../../../../contexts/SeatAreaContext';

export const LeaveButton = () => {
    const { sendCommand } = useContext(WebSocketContext);
    const { hostId } = useContext(VideoPlayerContext);
    const { setSelectedSeat } = useContext(SeatAreaContext);
    return (
        <svg
            width="24.5"
            height="24"
            viewBox="0 0 49 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
                const command = new CMDPsStandUp();
                command.HostID = hostId;
                sendCommand(command);
                setSelectedSeat(undefined);
            }}
        >
            <rect x="0.5" width="48" height="48" rx="24" fill="#6F5F45" />
            <path
                d="M31.2989 20.3797H30.9733C28.0798 20.3797 27.3936 20.3781 24.5001 20.3813C23.9989 20.3813 23.5582 20.237 23.2274 19.8217C22.8532 19.3516 22.7643 18.8095 22.9637 18.2388C23.1594 17.6776 23.5538 17.3368 24.1169 17.2385C24.1169 17.2385 24.2775 17.233 24.4351 17.233C27.3009 17.2314 28.1141 17.2044 30.98 17.2044H31.3661C31.2534 17.0737 31.1899 16.9952 31.1212 16.9215C30.4221 16.1796 29.7155 15.4456 29.0254 14.695C28.4637 14.0839 28.4361 13.1922 28.9342 12.5668C29.4257 11.9494 30.2712 11.817 30.9068 12.2656C31.0218 12.3473 31.1264 12.4503 31.225 12.5541C32.8106 14.2305 34.3948 15.9077 35.9775 17.5881C36.6758 18.3292 36.6743 19.2565 35.9737 20C34.3776 21.6931 32.78 23.3845 31.1824 25.076C30.7402 25.5436 30.2137 25.7029 29.6176 25.5C29.0313 25.3003 28.6922 24.8485 28.6026 24.2072C28.5272 23.6706 28.7094 23.2181 29.0679 22.8392C29.7484 22.1195 30.4265 21.3982 31.1092 20.6809C31.1816 20.6048 31.2735 20.5501 31.3564 20.4859C31.3377 20.451 31.3176 20.4146 31.2989 20.3797Z"
                fill="white"
            />
            <path
                d="M27.2693 28.3597C27.5041 26.6574 25.9975 25.3763 24.2841 25.3729C18.2508 25.3613 22.9644 25.3605 17.3379 25.3729C17.3462 22.455 17.3379 16.2784 17.328 14.1772C17.3272 13.9177 17.2618 13.6374 17.1519 13.4019C16.8302 12.7154 16.0678 12.3646 15.3442 12.5305C14.5644 12.7096 14.0426 13.348 14.0409 14.1631C14.036 16.0976 14.0376 24.562 14.0385 28.0106C14.0261 28.1259 14.0418 28.4327 14.0385 28.5529C13.9558 31.2552 13.5531 31.5952 12.6087 34.0405C12.4326 34.4965 12.4987 34.9907 12.6087 35.2262C12.9304 35.9128 13.7664 36.1176 14.4875 35.9385C15.2491 35.7502 15.6336 35.1068 15.9363 34.509C16.5466 33.3041 17.3098 31.3821 17.3098 29.0919V28.7021H23.9808C23.9808 28.8481 23.9932 28.9725 23.9808 29.0952C23.7005 31.8166 25.0953 34.2652 25.3111 34.6648C25.6593 35.3083 26.549 36.2162 27.6943 35.8779C28.6296 35.6018 28.9298 34.3953 28.6106 33.727C27.6025 31.6134 26.8682 31.2635 27.2693 28.3597Z"
                fill="white"
            />
        </svg>
    );
};
