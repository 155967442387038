import { Stack, Grid, Box } from '@mui/material';
import BaseImage from '../../../../components/common/baseImage';
import { Bet } from '../../../report/slice/betRecord';
import { Poker } from '../../../../components/common/poker';
import { t } from 'i18next';
import { getPattern, updatePattern } from '../../../../utils/games/teenpatti';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type TeenPattiResultRecordPanelProps = {
    bet: Bet;
};
export const TeenPattiResultRecordPanel = (
    props: TeenPattiResultRecordPanelProps
) => {
    const { bet } = props;

    return (
        <>
            <Stack
                direction="column"
                justifyContent="space-between"
                alignItems={'flex-end'}
                sx={{ height: '86px' }}
            >
                <DetailResult lbl={'A'} cards={bet.ResultPlayer} />
                <DetailResult lbl={'B'} cards={bet.ResultBanker} />
            </Stack>
        </>
    );
};

export const DetailResult = ({
    lbl,
    cards,
}: {
    lbl: string;
    cards: number[];
}) => {
    return (
        <>
            <Stack direction="row" alignItems="center">
                <Grid
                    item
                    sx={{
                        marginRight: '3px',
                        marginBottom: '5px',
                    }}
                >
                    {lbl}
                </Grid>
                {cards.map(c => {
                    const suit = Math.floor(c / 13);
                    const rank = c % 13;
                    return (
                        <Grid key={`card-${c}`} item>
                            <Stack direction="row">
                                <BaseImage className="cardrank empty" />
                                <BaseImage
                                    className={`cardsuit c${suit}`}
                                    scale={1}
                                />
                                <Box sx={{ marginLeft: '-1px' }}>
                                    {suit % 2 == 0 ? (
                                        <BaseImage
                                            className={`cardrank c${rank}`}
                                            scale={1}
                                        />
                                    ) : (
                                        <BaseImage
                                            className={`cardrankred c${rank}`}
                                            scale={1}
                                        />
                                    )}
                                </Box>
                            </Stack>
                        </Grid>
                    );
                })}
            </Stack>
        </>
    );
};

type ResultDisplay = {
    winResult: number;
    playerAPattern: number;
    playerBPattern: number;
    sixCardPattern: number;
    sixCardPokers: Array<number>;
    playerALightArr: Array<number>;
    playerBLightArr: Array<number>;
    sixCardLightArr: Array<number>;
};

export const TeenPattiResultDetailPanel = (
    props: TeenPattiResultRecordPanelProps
) => {
    const { bet } = props;
    const {
        winResult,
        playerAPattern,
        playerBPattern,
        sixCardPattern,
        sixCardPokers,
    } = getPattern(BigInt(bet.WinSlot));

    const resultView = {
        winResult,
        playerAPattern,
        playerBPattern,
        sixCardPattern,
        sixCardPokers,
        playerALightArr: updatePattern(bet.ResultPlayer, playerAPattern, false),
        playerBLightArr: updatePattern(bet.ResultBanker, playerBPattern, false),
        sixCardLightArr: updatePattern(sixCardPokers, sixCardPattern, true),
    };

    const getOpacity = (index: number) => {
        if (!resultView) return 0;
        return resultView.sixCardLightArr.indexOf(index) >= 0 ? 1 : 0.4;
    };

    return (
        <Stack alignItems={'center'} width={'100%'} height={'100%'}>
            <Stack
                direction={'column'}
                alignItems={'center'}
                justifyContent={'flex-start'}
                width={'380px'}
                height={'100%'}
                gap={'7px'}
            >
                <Grid
                    container
                    sx={{
                        direction: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <PlayerCards
                        isPlayerA={true}
                        cards={bet.ResultPlayer}
                        resultView={resultView}
                    />
                    <PlayerCards
                        isPlayerA={false}
                        cards={bet.ResultBanker}
                        resultView={resultView}
                    />
                </Grid>
                {resultView && (
                    <Grid
                        container
                        sx={{
                            fontSize: '14px',
                            backgroundColor: 'black',
                            color: 'white',
                            width: '100%',
                        }}
                    >
                        <Grid
                            container
                            direction={'column'}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignSelf: 'center',
                                gap: '6px',
                            }}
                        >
                            <Grid
                                item
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    fontSize: '18px',
                                    color: '#837254',
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                }}
                            >
                                {t('teenpatti.sixCardBonus')}
                            </Grid>
                            <Grid
                                container
                                sx={{
                                    display: 'flex',
                                    direction: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}
                            >
                                {resultView.sixCardPokers.map((card, index) => (
                                    <Poker
                                        key={`six-poker-${index}`}
                                        scale={0.83}
                                        n={card}
                                        opacity={getOpacity(index)}
                                    />
                                ))}
                            </Grid>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '18px',
                                    backgroundColor: '#837254',
                                    color: 'white',
                                    borderRadius: '2px',
                                    height: '28px',
                                    width: '290px',
                                    marginTop: '5px',
                                    marginBottom: '10px',
                                }}
                            >
                                {t(
                                    'teenpatti.sixCardPattern.' +
                                        resultView.sixCardPattern
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Stack>
        </Stack>
    );
};

type PlayerCardsProps = {
    isPlayerA: boolean;
    cards: Array<number>;
    resultView: ResultDisplay | undefined;
};
const PlayerCards = (props: PlayerCardsProps) => {
    const { isPlayerA = true, cards, resultView } = props;
    const { i18n } = useTranslation();
    const [languageDir, setLanguageDir] = useState<string>('ltr');
    useEffect(() => {
        setLanguageDir(i18n.dir(i18n.language));
    }, [i18n.language]);

    const getOpacity = (index: number) => {
        if (!resultView) return 1;
        return (isPlayerA
            ? resultView.playerALightArr
            : resultView.playerBLightArr
        ).indexOf(index) >= 0
            ? 1
            : 0.4;
    };

    const getCardPatternTxt = () => {
        if (!resultView) return '';
        return t(
            'teenpatti.playerPattern.' +
                (isPlayerA
                    ? resultView.playerAPattern
                    : resultView.playerBPattern)
        );
    };

    const getBgColor = () => {
        if (!resultView) return 'black';
        if (isPlayerA && resultView.winResult == 1) return '#436FFF';
        if (!isPlayerA && resultView.winResult == 2) return '#D30000';
        return 'black';
    };

    const getBorderColor = () => {
        if (!resultView) return '#655840';
        if (isPlayerA && resultView.winResult == 1) return '#436FFF';
        if (!isPlayerA && resultView.winResult == 2) return '#D30000';
        return '#655840';
    };

    return (
        <Grid
            container
            sx={{
                direction: 'column',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'start',
                gap: '6px',
                width: '45%',
            }}
        >
            <Grid
                sx={{
                    fontSize: '18px',
                    height: '25px',
                    marginBottom: '5px',
                    width: '100%',
                    direction: languageDir,
                }}
                color={isPlayerA ? '#436fff' : '#d30000'}
            >
                {t(`betlog.bet_teenpatti.${isPlayerA ? 1 : 2}`)}
            </Grid>
            <Grid
                container
                sx={{
                    direction: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'flex-start',
                    width: '100%',
                    gap: '8px',
                }}
            >
                {cards.map((card, index) => (
                    <Poker
                        key={`poker-${index}`}
                        scale={0.83}
                        n={card}
                        opacity={getOpacity(index)}
                    />
                ))}
            </Grid>
            {resultView && (
                <Grid
                    sx={{
                        marginTop: '3px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '14px',
                        color: 'white',
                        width: '100%',
                        borderRadius: '5px',
                        height: '30px',
                    }}
                    bgcolor={getBgColor()}
                    border={`2px solid ${getBorderColor()}`}
                >
                    {getCardPatternTxt()}
                </Grid>
            )}
        </Grid>
    );
};
