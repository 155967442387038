import { SicBoRecord, SicBoRoadmapRecord } from '../../../models/games/sicbo';
import { SupportedRecord } from '../../host/models';
import { ROAD_MAP_TYPE } from '../../lobby/constants';
// import { SicBoRoadMapSummaryListProp } from './hook';
export const getSummaryList = (
    oldResult: SupportedRecord[] | undefined
): Array<number | string> => {
    //let list = new Array<SicBoRoadMapSummaryListProp>();
    let list = new Array<number | string>();
    let b = 0;
    let s = 0;
    let o = 0;
    let e = 0;
    let t = 0;
    if (oldResult) {
        for (let i = 0; i < oldResult.length; i++) {
            let record = oldResult[i] as SicBoRecord;
            if (record.SRBigSmall == 1) {
                //Big = 1, Small = 2, Tie = 0
                b++;
            }
            if (record.SRBigSmall == 2) {
                //Big = 1, Small = 2, Tie = 0
                s++;
            }
            if (record.SROddEven == 1) {
                //Even = 2, Odd = 1, Tie = 0
                o++;
            }
            if (record.SROddEven == 2) {
                //Even = 2, Odd = 1, Tie = 0
                e++;
            }
            if (record.SRBigSmall == 0) {
                //Big = 1, Small = 2, Tie = 0
                t++;
            }
        }
    }
    list = [
        'road.big',
        b,
        'road.small',
        s,
        'road.odd',
        o,
        'road.even',
        e,
        'road.dice_triple',
        t,
    ];
    // list = [
    //     { text: 'road.big', value: b },
    //     { text: 'road.small', value: s },
    //     { text: 'road.odd', value: o },
    //     { text: 'road.even', value: e },
    //     { text: 'road.dice_triple', value: t },
    // ];
    return list;
};
export const getRoadmapRecord = (
    oldResult: SupportedRecord[] | undefined
): SicBoRoadmapRecord[] => {
    let record = new Array<SicBoRoadmapRecord>();
    if (oldResult) {
        record = oldResult.map((r, idx) => {
            const result = r as SicBoRecord;
            let BigSmallResult = ''; // 'big' | 'small' | 'dice_triple';
            let OddEventRResult = ''; // 'odd' | 'even' | 'dice_triple';
            if (result.SRTriple !== 0) {
                BigSmallResult = OddEventRResult = 'dice_triple';
            } else {
                switch (result.SRBigSmall) {
                    case 1:
                        BigSmallResult = 'big';
                        break;
                    case 2:
                        BigSmallResult = 'small';
                        break;
                    default:
                        BigSmallResult = '';
                }
                switch (result.SROddEven) {
                    case 1:
                        OddEventRResult = 'odd';
                        break;
                    case 2:
                        OddEventRResult = 'even';
                        break;
                    default:
                        OddEventRResult = '';
                }
            }
            return {
                GameId: result.gameID,
                Dice1: result.Dice1,
                Dice2: result.Dice2,
                Dice3: result.Dice3,
                PointResult: result.resultPoint,
                BigSmallResult: BigSmallResult,
                OddEventRResult: OddEventRResult,
                Win: -1,
                LastDraw: false,
                SpaceNum: 0,
                IsLast: idx == oldResult.length - 1,
            } as SicBoRoadmapRecord;
        });
    }
    return record;
};
export const getUnion = (list: Array<SicBoRoadmapRecord>) => {
    let tmp: SicBoRoadmapRecord = {
        GameId: list[0].GameId,
        Dice1: list[0].Dice1,
        Dice2: list[0].Dice2,
        Dice3: list[0].Dice3,
        PointResult: list[0].PointResult,
        BigSmallResult: list[0].BigSmallResult,
        OddEventRResult: list[0].OddEventRResult,
        Win: list[0].Win,
        LastDraw: false,
        SpaceNum: list.length - 1,
        IsLast: false,
    };
    return tmp;
};
export const getHistory = (record: SicBoRoadmapRecord[], num: number) => {
    return record.slice(-num);
};
const getNullRecord = (): SicBoRoadmapRecord => {
    return {
        Win: -1,
        GameId: -1,
        Dice1: 0,
        Dice2: 0,
        Dice3: 0,
        LastDraw: false,
        BigSmallResult: '',
        OddEventRResult: '',
        PointResult: 0,
        SpaceNum: 0,
    } as SicBoRoadmapRecord;
};
export const getOtherRoad = (
    roadmapRecord: Array<SicBoRoadmapRecord> | undefined,
    typeID: number
): Array<Array<SicBoRoadmapRecord>> => {
    let record = new Array<Array<SicBoRoadmapRecord>>();
    if (roadmapRecord) {
        let curDataIdx: number = 0;
        let curWin: number = -1;
        let x: number = -1;
        let y: number = -1;
        //win= -1 empty, 0 big/odd, 1 small/even, 2 dice_triple
        while (curDataIdx < roadmapRecord.length) {
            const curRecord = roadmapRecord[curDataIdx];
            switch (typeID) {
                case ROAD_MAP_TYPE.BIG_SMALL_ROAD:
                    if (curRecord.BigSmallResult !== undefined) {
                        let win = -1;
                        if (curRecord.BigSmallResult === 'big') {
                            win = 0;
                        } else if (curRecord.BigSmallResult === 'small') {
                            win = 1;
                        } else if (curRecord.BigSmallResult === 'dice_triple') {
                            win = 2;
                        } else {
                            win = -1;
                        }
                        curRecord.Win = win;
                    }
                    break;
                case ROAD_MAP_TYPE.EVEN_ODD_ROAD:
                    if (curRecord.OddEventRResult !== undefined) {
                        let win = -1;
                        if (curRecord.OddEventRResult === 'odd') {
                            win = 0;
                        } else if (curRecord.OddEventRResult === 'even') {
                            win = 1;
                        } else if (
                            curRecord.OddEventRResult === 'dice_triple'
                        ) {
                            win = 2;
                        } else {
                            win = -1;
                        }
                        curRecord.Win = win;
                    }
                    break;
            }
            if (curRecord.Win != curWin) {
                if (curRecord.Win != 3) {
                    curWin = curRecord.Win;
                    x++;
                    y = 0;
                } else {
                    if (x >= 0 && y >= 0) {
                        record[x][y].LastDraw = true;
                    }
                    curDataIdx++;
                    continue;
                }
            } else {
                y++;
            }

            while (record.length <= x) {
                let tmp = new Array<SicBoRoadmapRecord>();
                tmp.push(getNullRecord());
                record.push(tmp);
            }

            while (record[x].length <= y) {
                record[x].push(getNullRecord());
            }
            record[x][y] = roadmapRecord[curDataIdx];
            curDataIdx++;
        }
    }
    return record;
};
export const getMappedRoad = (
    roadmapRecord: SicBoRoadmapRecord[],
    typeID: number,
    gridWidth: number,
    gridHeight: number
): SicBoRoadmapRecord[] => {
    let record = new Array<SicBoRoadmapRecord>();
    let offset: number = 0;
    let width: number = 0;
    let offsetFixed: boolean;
    let otherRoad = undefined;

    switch (typeID) {
        case ROAD_MAP_TYPE.POINT_ROAD:
            while (gridWidth * gridHeight <= roadmapRecord.length - offset) {
                offset += gridHeight;
            }
            for (let i: number = offset; i < roadmapRecord.length; i++) {
                record.push(roadmapRecord[i]);
            }
            break;
        case ROAD_MAP_TYPE.BIG_SMALL_ROAD:
        case ROAD_MAP_TYPE.EVEN_ODD_ROAD:
            width = gridWidth;
            offset = 0;
            offsetFixed = false;
            otherRoad = getOtherRoad(roadmapRecord, typeID);
            while (!offsetFixed) {
                let placed: boolean = true;
                for (let x: number = offset; x < otherRoad.length; x++) {
                    let start_pt = x - offset;
                    let turned: boolean = false;
                    let turned_pt: number = -1;
                    let spaceOne: boolean = false;
                    for (let y: number = 0; y < otherRoad[x].length; y++) {
                        let idx: number = start_pt * gridHeight + y;
                        const max: number = gridHeight;

                        if (y >= max && !turned) {
                            turned = true;
                            turned_pt = y - 1;
                            idx =
                                (start_pt + (y - (max - 1))) * gridHeight +
                                (max - 1);
                        }

                        if (spaceOne) {
                            // spaceCount++;
                        } else {
                            if (!turned) {
                                if (
                                    idx < record.length &&
                                    record[idx].Win >= 0
                                ) {
                                    turned = true;
                                    turned_pt = y - 1;

                                    if (
                                        record[idx].Win == otherRoad[x][y].Win
                                    ) {
                                        turned_pt--;
                                    }

                                    idx =
                                        (start_pt + (y - turned_pt)) *
                                            gridHeight +
                                        turned_pt;

                                    if (turned_pt == 0) {
                                        spaceOne = true;
                                    }
                                }

                                if (!turned) {
                                    if (
                                        idx + 1 < record.length &&
                                        record[idx + 1].Win ==
                                            otherRoad[x][y].Win
                                    ) {
                                        if (y == 0) {
                                            spaceOne = true;
                                            // spaceCount++;
                                        } else {
                                            idx += gridHeight - 1;

                                            if (idx % gridHeight == 0) {
                                                spaceOne = true;
                                                // spaceCount++;
                                            }
                                        }
                                    }
                                }
                            } else {
                                idx =
                                    (start_pt + (y - turned_pt)) * gridHeight +
                                    turned_pt;
                            }
                        }

                        if (spaceOne) {
                            idx = start_pt * gridHeight;
                        }

                        if (idx < 0) {
                            continue;
                        }

                        while (record.length <= idx) {
                            record.push(getNullRecord());
                        }

                        if (spaceOne) {
                            record[idx].SpaceNum = y + 1;
                            otherRoad[x][y].SpaceNum = y;

                            let tmp = (start_pt + 1) * gridHeight;
                            if (tmp < record.length) {
                                record[tmp].Win = -1;
                            }

                            record[idx] = getUnion(otherRoad[x]);
                        } else {
                            otherRoad[x][y].SpaceNum = 0;
                            record[idx] = otherRoad[x][y];
                        }
                        if ((idx + 1) / gridHeight > width) {
                            placed = false;
                        }
                        //ask road
                    }
                }
                if (placed) {
                    offsetFixed = true;
                } else {
                    while (record.length / gridHeight > width) {
                        let haveLast = false;
                        for (let i = 0; i < gridHeight; i++) {
                            if (record[i]?.IsLast) {
                                haveLast = true;
                            }
                        }

                        if (haveLast) {
                            record = record.slice(0, record.length - 1);
                        } else {
                            record = record.slice(gridHeight);
                        }
                    }
                    offsetFixed = true;
                }
            }
            break;
        default:
            break;
    }

    return record;
};
