import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { SupportedGame } from '../../../modules/host/models';
import { hostsSliceActions } from '../../../modules/host/slice';
import { convertResultToRecord } from '../../../utils/games/blackjack';
import {
    BlackjackOwnResult,
    BlackjackRecord,
    BlackjackResult,
} from '../../games/blackjack';
import { GameState } from '../../games/enums/GameState';
import { CMDScInitBlackjack, ICommand } from '../live';
import { CMDBlackjack } from '../live/CMDBlackjack';
import { AbstractHandler } from './AbstractHandler';

export class CMDScInitBlackjackHandler extends AbstractHandler {
    _command: CMDScInitBlackjack;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScInitBlackjack;
    }
    // issue #239
    convertPokerIndex(json: string) {
        const result = JSON.parse(json) as BlackjackOwnResult;
        result.B.PK = result.B.PK.map(pk => pk - 1);
        for (const p of result.P) {
            for (const h of p.H) {
                h.PK = h.PK.map(pk => pk - 1);
            }
        }
        return JSON.stringify(result);
    }

    override convertResult(result: CMDBlackjack): BlackjackResult {
        // convert index start from 1 to 0
        const convertJson = this.convertPokerIndex(result.JSON);

        const baseResult = this.convertBaseResult(result.BaseResult);
        const ownResult = JSON.parse(convertJson) as BlackjackOwnResult;
        const newResult = Object.assign(
            baseResult,
            ownResult
        ) as BlackjackResult;
        newResult.ResultString = convertJson;
        return newResult;
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const game = {} as SupportedGame;
        const info = this.convertInformation(this._command.Information);
        const result = this.convertResult(this._command.CurrentResult);
        const hostId = info.HostId;
        const multiplier = this._command.BJMultiplier;

        game.CurrentState = this._command.CurrentState;
        game.CurrentResult = result;
        game.IsRest = this._command.Information.Rest != 0;
        game.GameDelay = {
            kickNoBetPlayer:
                this._command.CountDownSetting.KickNoBetPlayerCountDown,
            insurance: this._command.CountDownSetting.InsuranceCountDown,
            decision: this._command.CountDownSetting.DecisionCountDown,
            followDecision:
                this._command.CountDownSetting.FollowDecisionCountDown,
            countDownDelay: this._command.CountDownDelay,
        };
        // add records
        const records = new Array<BlackjackRecord>();
        if (game.CurrentState !== GameState.Shuffle) {
            for (const or of this._command.OldResult) {
                const oldResult = this.convertResult(or);
                const oldRecord = convertResultToRecord(
                    oldResult,
                    oldResult.ResultString ?? '',
                    oldResult.Result
                );
                if (!records.find(r => r.gameID == oldRecord.gameID))
                    records.push(oldRecord);
            }
        }
        batch(() => {
            dispatch(hostsSliceActions.gameInit({ hostId, game }));
            dispatch(hostsSliceActions.updateInformation(info));
            dispatch(
                hostsSliceActions.updateMultiplier({ hostId, multiplier })
            );
            dispatch(
                hostsSliceActions.updateRecord({
                    hostId,
                    record: records,
                })
            );
        });
    }
}
