import { ArrowForwardIosOutlined } from '@mui/icons-material';
import { Grid, List, ListItemButton, ListItemText, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { amountFormatter } from '../../../../utils/formatter';
import { getBetRecordReportState } from '../../../report/selector';
import { NestedDialog } from '../NestedDialog';
import { ReportDivider } from '../ReportDivider';
import { BetRecordDetailsReport } from './BetRecordDetailsReport';

type CloseHandler = () => void;

type Props = {
    username: string;
    onClose: CloseHandler;
    playButtonAudio: CloseHandler;
};

export const BetRecordReport = (props: Props) => {
    const { username, onClose, playButtonAudio } = props;
    const [collapseIndex, setCollapseIndex] = useState(-1);
    const [levelIndex, setLevelIndex] = useState(-1);
    const { records, total } = useSelector((state: RootState) =>
        getBetRecordReportState(state)
    );
    const { t } = useTranslation();
    return (
        <>
            {total > 0 ? (
                <>
                    {records && (
                        <List sx={{ paddingY: 0 }}>
                            {Object.keys(records)?.map((date, ci) => {
                                const isCollapseOpen = collapseIndex === ci;
                                const resultAmount =
                                    records[date].totalAmount ?? 0;
                                let winLoseLabel: string = '';
                                if (resultAmount < 0)
                                    winLoseLabel = t('betlog.lose');
                                if (resultAmount > 0)
                                    winLoseLabel = t('betlog.win');
                                return (
                                    <div key={`list-${date}`}>
                                        <ListItemButton
                                            onClick={() => {
                                                setCollapseIndex(ci);
                                                setLevelIndex(0);
                                            }}
                                            selected={isCollapseOpen}
                                        >
                                            <ListItemText>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item>{date}</Grid>
                                                    <Grid item>
                                                        {winLoseLabel}
                                                    </Grid>
                                                    <Grid item>
                                                        {amountFormatter(
                                                            resultAmount / 100,
                                                            true,
                                                            '1rem',
                                                            true
                                                        )}
                                                    </Grid>
                                                    <Grid item>
                                                        <ArrowForwardIosOutlined
                                                            sx={{
                                                                color: '#6e593a',
                                                            }}
                                                        />
                                                    </Grid>
                                                </Stack>
                                            </ListItemText>
                                        </ListItemButton>
                                        <ReportDivider isCell={true} />
                                        <NestedDialog
                                            isOpen={
                                                levelIndex >= 0 &&
                                                collapseIndex === ci
                                            }
                                            onClose={() => {
                                                onClose();
                                            }}
                                            currentIndex={levelIndex}
                                            setCurrentIndex={setLevelIndex}
                                            playButtonAudio={playButtonAudio}
                                            title={'menu.bet_record'}
                                        >
                                            <BetRecordDetailsReport
                                                onClose={() => onClose()}
                                                currentIndex={levelIndex}
                                                setCurrentIndex={setLevelIndex}
                                                playButtonAudio={
                                                    playButtonAudio
                                                }
                                                username={username}
                                                record={records[date]}
                                                date={date}
                                            />
                                        </NestedDialog>
                                    </div>
                                );
                            })}
                        </List>
                    )}
                </>
            ) : (
                <>
                    <Grid
                        item
                        container
                        justifyContent="center"
                        sx={{ fontSize: '5.55vw' }}
                    >
                        {t('betlog.noRecord')}
                    </Grid>
                </>
            )}
        </>
    );
};
