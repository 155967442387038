import { BetTypeMaxBet } from "../../../../models/host/MaxBet";

export const getMaxBetByBetType = (
    betType: number,
    MaxBet: BetTypeMaxBet[]
): string => {
    if (!MaxBet) return '0';
    const index = MaxBet.findIndex(d => {
        return d.BetType === betType;
    });
    return index !== -1
        ? (MaxBet[index].MaxBet / 100).toLocaleString('en-US', {
              maximumFractionDigits: 2,
          })
        : '0';
};
