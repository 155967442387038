import { Grid } from '@mui/material';
import BetArea from '../../../components/common/betArea';
import { BetType } from '../../../models/games/baccarat/BetType';
import {
    amountOverBetLimit,
    isOppositeBetting,
} from '../../game/components/BetAreaPanels/Baccarat/utils';
import { getBetAreaProps } from './betAreaInfo';
import BaseImage from '../../../components/common/baseImage';
import { getHostById } from '../../host/slice';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';

type Prop = {
    hostId: number;
};
export const MultiBetBaccaratBetArea = ({ hostId }: Prop) => {
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { Group } = host;
    const bgImg = `multibet_game multibet_bacc_supersix_${Group[0]}hall`;
    return (
        <Grid position={'relative'}>
            <Grid position={'absolute'}>
                <BaseImage className={bgImg} scale={0.437} />
            </Grid>
            <Grid position={'absolute'}>
                <BaseImage
                    className="multibet_betarea baccarat"
                    isMultiLang={true}
                    scale={0.437}
                />
            </Grid>
            <Grid
                item
                container
                flexWrap={'nowrap'}
                justifyContent={'space-between'}
                sx={{
                    marginBottom: '3px',
                    width: 540,
                }}
            >
                <BetArea
                    hid={hostId}
                    style={getBetAreaProps(BetType.BBPlayerPair).style}
                    confirmPanelStyle={
                        getBetAreaProps(BetType.BBPlayerPair).confirmPanelStyle
                    }
                    betType={BetType.BBPlayerPair}
                    isMultiBet={true}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    roundNumDisable={
                        getBetAreaProps(BetType.BBPlayerPair).roundNumDisable
                    }
                />
                <BetArea
                    hid={hostId}
                    style={getBetAreaProps(BetType.BBPlayerWin).style}
                    confirmPanelStyle={
                        getBetAreaProps(BetType.BBPlayerWin).confirmPanelStyle
                    }
                    betType={BetType.BBPlayerWin}
                    isMultiBet={true}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                />
                <BetArea
                    hid={hostId}
                    style={getBetAreaProps(BetType.BBTie).style}
                    confirmPanelStyle={
                        getBetAreaProps(BetType.BBTie).confirmPanelStyle
                    }
                    betType={BetType.BBTie}
                    isMultiBet={true}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                />
                <BetArea
                    hid={hostId}
                    style={getBetAreaProps(BetType.BBSLuckySix).style}
                    confirmPanelStyle={
                        getBetAreaProps(BetType.BBSLuckySix).confirmPanelStyle
                    }
                    betType={BetType.BBSLuckySix}
                    isMultiBet={true}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    roundNumDisable={
                        getBetAreaProps(BetType.BBSSSLuckySix).roundNumDisable
                    }
                />
                <BetArea
                    hid={hostId}
                    style={getBetAreaProps(BetType.BBBankerWin).style}
                    confirmPanelStyle={
                        getBetAreaProps(BetType.BBBankerWin).confirmPanelStyle
                    }
                    betType={BetType.BBBankerWin}
                    isMultiBet={true}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                />
                <BetArea
                    hid={hostId}
                    style={getBetAreaProps(BetType.BBBankerPair).style}
                    confirmPanelStyle={
                        getBetAreaProps(BetType.BBBankerPair).confirmPanelStyle
                    }
                    betType={BetType.BBBankerPair}
                    isMultiBet={true}
                    amountOverBetLimit={amountOverBetLimit}
                    isOppositeBetting={isOppositeBetting}
                    roundNumDisable={
                        getBetAreaProps(BetType.BBBankerPair).roundNumDisable
                    }
                />
            </Grid>
        </Grid>
    );
};
