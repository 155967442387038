import { Backdrop, Box, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Poker } from '../../../../components/common/poker';
import { VideoPlayerContext } from '../../../../contexts/VideoContext';
import { RootState } from '../../../../store/store';
import { convertResultStringToRecord } from '../../../../utils/games/baccarat';
import { GetTotalBaccaratPoint } from '../../../../utils/pokerCount';
import { getHostById } from '../../../host/slice';
import { ROAD_MAP_COLORS } from '../../../lobby/constants';
import './index.scss';
import './BaccaratECard.scss';
import { GameState } from '../../../../models/games/enums/GameState';
import { MultiBetGameResult } from '../../../multibet/GameMessage/MultiBetGameResult';
type BaccaratECardDisplay = {
    p1: number;
    p2: number;
    p3: number;
    b1: number;
    b2: number;
    b3: number;
};
type BaccaratECardProps = {
    show: boolean;
    isMultiBet: boolean;
    hid?: number;
};
export const BaccaratECard = (props: BaccaratECardProps) => {
    const { show, isMultiBet, hid } = props;
    const { t } = useTranslation();
    const { hostId: videoHostId } = useContext(VideoPlayerContext);
    const hostId = hid ? hid : videoHostId;
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const [display, setDisplay] = useState<BaccaratECardDisplay>();
    const [playerPoint, setPlayerPoint] = useState(0);
    const [bankerPoint, setBankerPoint] = useState(0);
    const className = isMultiBet
        ? 'multibet-poker-show-backdrop'
        : 'bacc-poker-show-backdrop';
    const pokerSize = isMultiBet ? 0.7 : 1.09;
    const handleHideCard = () => {
        setPlayerPoint(0);
        setBankerPoint(0);
        setDisplay(undefined);
    };
    useEffect(() => {
        const showResult = host?.PokerShowResult;
        if (showResult && showResult.length > 0) {
            // Handle delay
            // TODO: Handle 'forceRun' case
            const d = {} as BaccaratECardDisplay;
            let pp: Array<number> = [],
                bp: Array<number> = [];
            for (const ps of showResult) {
                const card = ps.Card;
                switch (ps.Slot) {
                    case 0:
                        d.p1 = card;
                        pp.push(card);
                        break;
                    case 1:
                        d.p2 = card;
                        pp.push(card);
                        break;
                    case 2:
                        d.b1 = card;
                        bp.push(card);
                        break;
                    case 3:
                        d.b2 = card;
                        bp.push(card);
                        break;
                }
            }
            setPlayerPoint(GetTotalBaccaratPoint(pp));
            setBankerPoint(GetTotalBaccaratPoint(bp));
            setDisplay(d);
        } else {
            if (host.CurrentState === GameState.Started) {
                handleHideCard();
            }
        }
    }, [host.PokerShowResult]);

    useEffect(() => {
        const result = host?.CurrentResult;
        if (result?.ResultString) {
            const record = convertResultStringToRecord(
                result,
                result.ResultString
            );
            const d: BaccaratECardDisplay = {
                p1: record.player1,
                p2: record.player2,
                p3: record.player3,
                b1: record.banker1,
                b2: record.banker2,
                b3: record.banker3,
            };
            setPlayerPoint(record.playerPoint);
            setBankerPoint(record.bankerPoint);
            setDisplay(d);
        }
        return () => {
            handleHideCard();
        };
    }, [host?.CurrentResult]);
    return (
        <Backdrop className={className} open={show}>
            <Stack
                className={'poker-show'}
                alignItems={'center'}
                justifyContent={'space-evenly'}
            >
                {display && (
                    <Stack direction={'row'} className="poker-box">
                        <Poker
                            scale={pokerSize}
                            n={display.p3}
                            transform={'rotate(90deg) translateX(9px)'}
                        />
                        <Poker scale={pokerSize} n={display.p1} />
                        <Poker scale={pokerSize} n={display.p2} />
                        {isMultiBet && (
                            <Box
                                sx={{
                                    width: '152px',
                                    height: '56px',
                                    display: 'flex',
                                }}
                            >
                                <MultiBetGameResult hostId={hostId} />
                            </Box>
                        )}
                        <Poker scale={pokerSize} n={display.b1} />
                        <Poker scale={pokerSize} n={display.b2} />
                        <Poker
                            scale={pokerSize}
                            n={display.b3}
                            transform={'rotate(90deg) translateX(9px)'}
                        />
                    </Stack>
                )}
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    className="point-box"
                >
                    <Typography
                        sx={{
                            bgcolor: ROAD_MAP_COLORS.BLUE,
                            borderRadius: '5px',
                        }}
                    >
                        {t('road.player_win')} {playerPoint}
                    </Typography>
                    <Typography
                        sx={{
                            bgcolor: ROAD_MAP_COLORS.RED,
                            borderRadius: '5px',
                        }}
                    >
                        {t('road.banker_win')} {bankerPoint}
                    </Typography>
                </Stack>
            </Stack>
        </Backdrop>
    );
};
