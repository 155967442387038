import { Dialog, DialogContent, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './TeenPattiTableInfoPanel.scss';
import { useScreenRatio } from '../../../../../hooks/useScreenRatio';
import { useState, useEffect } from 'react';

const playerPatternData = {
    threeKindA: {
        title: 'teenpatti.playerPattern.6',
        value: '50:1',
    },
    threeKind2K: {
        title: 'teenpatti.playerPattern.5',
        value: '40:1',
    },
    straightFlush: {
        title: 'teenpatti.playerPattern.4',
        value: '30:1',
    },
    straight: {
        title: 'teenpatti.playerPattern.3',
        value: '6:1',
    },
    flush: {
        title: 'teenpatti.playerPattern.2',
        value: '3:1',
    },
    pair: {
        title: 'teenpatti.playerPattern.1',
        value: '1:1',
    },
};

const sixCardPatternData = {
    royalFlush: {
        title: 'teenpatti.sixCardPattern.7',
        value: '1,000:1',
    },
    straightFlush: {
        title: 'teenpatti.sixCardPattern.6',
        value: '200:1',
    },
    fourKind: {
        title: 'teenpatti.sixCardPattern.5',
        value: '100:1',
    },
    fullHouse: {
        title: 'teenpatti.sixCardPattern.4',
        value: '20:1',
    },
    flush: {
        title: 'teenpatti.sixCardPattern.3',
        value: '15:1',
    },
    straight: {
        title: 'teenpatti.sixCardPattern.2',
        value: '10:1',
    },
    threeKind: {
        title: 'teenpatti.sixCardPattern.1',
        value: '7:1',
    },
};

const dataList = [
    { title: 'teenpatti.pairplus', data: playerPatternData },
    { title: 'teenpatti.sixCardBonus', data: sixCardPatternData },
];

type TeenPattiOddsTableInfoPopupProps = {
    open: boolean;
    changeOpen: (b: boolean) => void;
};
export const TeenPattiOddsTableInfoPopup = (
    props: TeenPattiOddsTableInfoPopupProps
) => {
    const { open, changeOpen } = props;
    const { t, i18n } = useTranslation();

    const screenRatio = useScreenRatio();
    const [languageDir, setLanguageDir] = useState<string>('ltr');
    useEffect(() => {
        setLanguageDir(i18n.dir(i18n.language));
    }, [i18n.language]);

    const handleClose = () => {
        changeOpen(false);
    };
    return (
        <div
            style={{
                width: '100%',
                height: '960',
                position: 'absolute',
                left: '50%',
                top: '50%',
                padding: '0',
                margin: '0',
                transform: `translate(-50%, -50%) scale(${screenRatio})`,
            }}
            onClick={() => handleClose()}
        >
            <Dialog
                container={() =>
                    document.getElementById('in-game-popup-container')
                }
                fullScreen
                open={open}
                onClose={handleClose}
                hideBackdrop={true}
                disableEscapeKeyDown={true}
                PaperProps={{
                    sx: {
                        boxShadow: 'none',
                        backgroundColor: 'rgba(0,0,0,0.6)',
                        padding: '2px',
                    },
                }}
            >
                <DialogContent
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflowY: 'visible',
                    }}
                >
                    <Grid
                        item
                        container
                        justifyContent={'center'}
                        sx={{
                            width: '400px',
                            height: '600px',
                            padding: '3px',
                            backdropFilter:
                                'contrast(90%) brightness(150%) blur(1px)',
                        }}
                        onClick={e => e.stopPropagation()}
                    >
                        <Grid
                            item
                            container
                            direction={'column'}
                            justifyContent={'center'}
                            gap={'30px'}
                            sx={{
                                backgroundColor: 'rgba(0,0,0,0.95)',
                            }}
                        >
                            {dataList.map((item, grpId) => {
                                return (
                                    <Grid
                                        item
                                        container
                                        key={`odds-group-cell${grpId}`}
                                        direction={'column'}
                                        alignSelf={'center'}
                                        width={'90%'}
                                        fontSize={'18px'}
                                    >
                                        <Grid
                                            item
                                            container
                                            width={'100%'}
                                            display={'flex'}
                                            direction={'row'}
                                            justifyContent={'center'}
                                            color={'#dabd73'}
                                            fontSize={'22px'}
                                            sx={{
                                                direction: languageDir,
                                            }}
                                        >
                                            <div>{t(item.title)}</div>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            width={'100%'}
                                            display={'flex'}
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                            color={'#dabd73'}
                                            padding={'0 20px'}
                                        >
                                            <div>{t('teenpatti.pattern')}</div>
                                            <div>{t('teenpatti.payout')}</div>
                                        </Grid>
                                        {Object.values(item.data).map(
                                            (value, id) => {
                                                return (
                                                    <Grid
                                                        item
                                                        container
                                                        key={`odds-cell${id}`}
                                                        width={'100%'}
                                                        display={'flex'}
                                                        direction={'row'}
                                                        justifyContent={
                                                            'space-between'
                                                        }
                                                        color={'white'}
                                                        bgcolor={
                                                            id % 2 == 0
                                                                ? '#262626'
                                                                : ''
                                                        }
                                                        padding={'0 20px'}
                                                    >
                                                        <div>
                                                            {t(value.title)}
                                                        </div>
                                                        <div>{value.value}</div>
                                                    </Grid>
                                                );
                                            }
                                        )}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};
