import './index.scss';
import { CSSProperties } from 'react';
import BaseImage from '../../baseImage';
import Button from '..';

type Props = {
    onClick?: () => void;
    //isActive: boolean;
    id?: number;
    activeId?: number;
    setActiveId?: (value: number) => void;
    className: string;
    imageName: string;
    active?: string;
    inActive?: string;
    text?: string;
    data?: object;
    style?: CSSProperties;
};

const BaseButton = (props: Props) => {
    let {
        id,
        activeId,
        className,
        imageName,
        active,
        inActive,
        style = {},
    } = props;

    // let imageHtml: JSX.Element = <></>;
    let isActive = id === activeId;
    let imageClassName = '';
    if (imageName) {
        const otherClassName = isActive ? active : inActive;
        imageClassName = id ? imageName + ' ' + otherClassName : imageName;
        // imageHtml = <BaseImage className={imageClassName} isMultiLang={true} />;
    }

    //className += ' buttonDiv';

    // let elementStyle: React.CSSProperties;
    // elementStyle = {...style, ...{transform: 'translate(0px, 0px) scale(0.5)', transformOrigin: '0px 0px'}};

    const onClickButton = () => {
        if (id && id !== activeId && props.setActiveId) props.setActiveId(id);
        if (props.onClick) props.onClick();
    };

    return (
        <Button className={className} onClick={onClickButton} style={style}>
            {/* {imageHtml} */}
            <>
                <BaseImage className={imageClassName} isMultiLang={true} />
            </>
        </Button>
    );
};

export default BaseButton;
