import { Stack } from '@mui/material';
import BaseImage from '../../../../components/common/baseImage';
import { Bet } from '../../../report/slice/betRecord';

type SedieGameResultRecordPanelProps = {
    bet: Bet;
};
export const SedieGameResultRecordPanel = (
    props: SedieGameResultRecordPanelProps
) => {
    const { bet } = props;

    const getImage = () => {
        switch (bet.Result) {
            case '0':
                return 'sedie_sheet sd_4w';
            case '1':
                return 'sedie_sheet sd_3w1r';
            case '2':
                return 'sedie_sheet sd_2w2r';
            case '3':
                return 'sedie_sheet sd_1w3r';
            case '4':
                return 'sedie_sheet sd_4r';
            default:
                return '';
        }
    };

    return (
        <>
            <Stack justifyContent="center" sx={{ height: '86px' }}>
                <BaseImage className={getImage()} scale={0.37} />
            </Stack>
        </>
    );
};
