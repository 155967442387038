import { BetAreaProps } from '../../../../../../components/common/betArea';

export const getBetAreaProps = (betType: number): BetAreaProps => {
    const idx = BetArea.findIndex(ba => ba.betType === betType);

    if (idx >= 0) {
        return BetArea[idx];
    } else {
        return Object.assign({
            betType: betType,
        }) as BetAreaProps;
    }
};

const BetArea: Array<BetAreaProps> = [
    {
        betType: 0,
        style: {
            border: '2px solid #fff',
            width: '137px',
            height: '155px',
            borderRadius: '0',
        },
        imageName: 'bacc_betarea_label tie',
        confirmPanelStyle: {
            top: '95px',
        },
    },
    {
        betType: 1,
        style: {
            borderLeft: '2px solid #fff',
            borderTop: '2px solid #fff',
            borderBottom: '2px solid #fff',
            width: '191px',
            height: '155px',
            borderRadius: '10px 0 0 10px',
        },
        imageName: 'bacc_betarea_label player',
        confirmPanelStyle: {
            top: '95px',
        },
    },
    {
        betType: 2,
        style: {
            borderRight: '2px solid #fff',
            borderTop: '2px solid #fff',
            borderBottom: '2px solid #fff',
            width: '191px',
            height: '155px',
            borderRadius: '0 10px 10px 0',
        },
        imageName: 'bacc_betarea_label banker',
        confirmPanelStyle: {
            top: '95px',
        },
    },
    {
        betType: 3,
        style: {
            marginLeft: '9px',
            border: '2px solid #fff',
            width: '102px',
            height: '82px',
            borderRadius: '10px',
        },
        imageName: 'bacc_betarea_label p_pair',
        confirmPanelStyle: {
            top: '60px',
        },
        roundNumDisable: 60,
    },
    {
        betType: 4,
        style: {
            marginRight: '9px',
            border: '2px solid #fff',
            width: '102px',
            height: '82px',
            borderRadius: '10px',
        },
        imageName: 'bacc_betarea_label b_pair',
        confirmPanelStyle: {
            top: '60px',
        },
        roundNumDisable: 60,
    },
    {
        betType: 36,
        style: {
            border: '2px solid #fff',
            width: '102px',
            height: '82px',
            borderRadius: '10px',
        },
        imageName: 'bacc_betarea_label p_natural',
        confirmPanelStyle: {
            top: '60px',
        },
        roundNumDisable: 50,
    },
    {
        betType: 37,
        style: {
            border: '2px solid #fff',
            width: '102px',
            height: '82px',
            borderRadius: '10px',
        },
        imageName: 'bacc_betarea_label b_natural',
        confirmPanelStyle: {
            top: '60px',
        },
        roundNumDisable: 50,
    },
    {
        betType: 54,
        style: {
            border: '2px solid #fff',
            width: '102px',
            height: '82px',
            borderRadius: '10px',
        },
        imageName: 'bacc_betarea_label lucky6',
        confirmPanelStyle: {
            top: '60px',
        },
        roundNumDisable: 50,
    },
    {
        betType: 28,
        style: {
            marginLeft: '9px',
            border: '2px solid #fff',
            width: '102px',
            height: '82px',
            borderRadius: '10px',
        },
        imageName: 'bacc_betarea_label p_pair',
        confirmPanelStyle: {
            top: '60px',
        },
        roundNumDisable: 60,
    },
    {
        betType: 40,
        style: {
            border: '2px solid #fff',
            width: '102px',
            height: '82px',
            borderRadius: '10px',
        },
        imageName: 'bacc_betarea_label p_natural',
        confirmPanelStyle: {
            top: '60px',
        },
        roundNumDisable: 50,
    },
    {
        betType: 53,
        style: {
            border: '2px solid #fff',
            width: '102px',
            height: '82px',
            borderRadius: '10px',
        },
        imageName: 'bacc_betarea_label lucky6',
        confirmPanelStyle: {
            top: '60px',
        },
        roundNumDisable: 50,
    },
    {
        betType: 41,
        style: {
            border: '2px solid #fff',
            width: '102px',
            height: '82px',
            borderRadius: '10px',
        },
        imageName: 'bacc_betarea_label b_natural',
        confirmPanelStyle: {
            top: '60px',
        },
        roundNumDisable: 50,
    },
    {
        betType: 29,
        style: {
            marginRight: '9px',
            border: '2px solid #fff',
            width: '102px',
            height: '82px',
            borderRadius: '10px',
        },
        imageName: 'bacc_betarea_label b_pair',
        confirmPanelStyle: {
            top: '60px',
        },
        roundNumDisable: 60,
    },
    {
        betType: 26,
        style: {
            borderLeft: '2px solid #fff',
            borderTop: '2px solid #fff',
            borderBottom: '2px solid #fff',
            width: '191px',
            height: '155px',
            borderRadius: '10px 0 0 10px',
        },
        imageName: 'bacc_betarea_label player',
        confirmPanelStyle: {
            top: '95px',
        },
    },
    {
        betType: 25,
        style: {
            border: '2px solid #fff',
            width: '137px',
            height: '155px',
            borderRadius: '0',
        },
        imageName: 'bacc_betarea_label tie',
        confirmPanelStyle: {
            top: '95px',
        },
    },
    {
        betType: 27,
        style: {
            borderRight: '2px solid #fff',
            borderTop: '2px solid #fff',
            borderBottom: '2px solid #fff',
            width: '191px',
            height: '155px',
            borderRadius: '0 10px 10px 0',
        },
        imageName: 'bacc_betarea_label nc_banker',
        confirmPanelStyle: {
            top: '95px',
        },
    },
    {
        betType: 42,
        style: {
            borderLeft: '2px solid #fff',
            borderTop: '2px solid #fff',
            borderBottom: '2px solid #fff',
            width: '191px',
            height: '241px',
            borderRadius: '10px 0 0 10px',
        },
        imageName: 'bacc_betarea_label cc_player',
        confirmPanelStyle: {
            top: '145px',
        },
    },
    {
        betType: 43,
        style: {
            borderRight: '2px solid #fff',
            borderTop: '2px solid #fff',
            borderBottom: '2px solid #fff',
            width: '191px',
            height: '241px',
            borderRadius: '0 10px 10px 0',
        },
        imageName: 'bacc_betarea_label cc_banker',
        confirmPanelStyle: {
            top: '145px',
        },
    },
    {
        betType: 44,
        style: {
            border: '2px solid #fff',
            width: '137px',
            height: '241px',
            borderRadius: '0',
        },
        imageName: 'bacc_betarea_label cc_tie',
        confirmPanelStyle: {
            top: '145px',
        },
    },
];
