import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { AbstractHandler } from './AbstractHandler';
import { CMDSpBetResult, ICommand } from '../live';
import { Bet } from '../../host/BetAmount';
import { gameSliceActions } from '../../../modules/games/slice';
import store from '../../../store/store';
import { GAME_KEY } from '../../../modules/games/slice';
import { inGamePopupSliceActions } from '../../../modules/popup/inGameSlice';
import { StateMsgType } from '../../Popup';
import {
    getHostByGameId,
    hostsSliceActions,
} from '../../../modules/host/slice';
import { GameError } from '../../games/enums/GameError';
import { batch } from 'react-redux';
import moment from 'moment';
export class CMDSpBetResultHandler extends AbstractHandler {
    _command: CMDSpBetResult;

    constructor(command: ICommand) {
        super();
        this._command = command as CMDSpBetResult;
    }

    override handle(dispatch: Dispatch<AnyAction>): void {
        const state = store.getState();
        const gameState = state[GAME_KEY];
        if (gameState.currentGameId != this._command.GameID) return;

        dispatch(gameSliceActions.setWaitingBetResult(false));

        const { GameType, HostId, PendingTime, RemainTime, ConfirmedBets } =
            getHostByGameId(state, this._command.GameID);
        console.log(`hook::socket::run::${HostId}`, this._command);
        if (this._command.Reason === this.SUCCESS_CODE) {
            let Bet = this._command.Bet.map(
                b =>
                    ({
                        Type: b.Base.betType,
                        Amount: b.Base.betAmount,
                        GameId: this._command.GameID,
                    }) as Bet
            );

            if (ConfirmedBets) {
                ConfirmedBets.forEach(bet => {
                    if (bet.GameId == this._command.GameID) {
                        const index = Bet.findIndex(cb => cb.Type === bet.Type);
                        if (index == -1) {
                            Bet.push(bet);
                        } else {
                            Bet[index].Amount += bet.Amount;
                        }
                    }
                });
            }

            batch(() => {
                if (PendingTime) {
                    const curTimer = Math.max(
                        0,
                        Math.round((RemainTime - moment().valueOf()) / 1000)
                    );
                    const time = PendingTime + curTimer * 1000;
                    dispatch(
                        hostsSliceActions.updateCountDown({
                            hostId: HostId,
                            countDown: time,
                            canLeave: false,
                        })
                    );
                }
                dispatch(
                    hostsSliceActions.updateConfirmedBets({
                        hostId: HostId,
                        gameId: this._command.GameID,
                        bets: Bet,
                    })
                );
                dispatch(
                    gameSliceActions.onConfirmedBetResult({
                        gameId: this._command.GameID,
                        bet: Bet,
                    })
                );
            });

            dispatch(
                inGamePopupSliceActions.open(
                    'system.bet_success',
                    GameType,
                    StateMsgType.betInfo_Success,
                    this._command.GameID
                )
            );
        } else {
            dispatch(gameSliceActions.onCancelPendingBet());
            let errorMsg = 'system.bet_failed';
            switch (this._command.Reason) {
                case GameError.BelowMinBet:
                    errorMsg = 'system.bet_below_win_bet';
                    break;
                case GameError.BetTimeOut:
                    errorMsg = 'system.bet_timeout';
                    break;
                case GameError.OutOfLimitRed:
                    errorMsg = 'system.bet_out_of_limit_red';
                    break;
                case GameError.NotEnoughMoney:
                    errorMsg = 'system.not_enough_money';
                    break;
                case GameError.CannotBetOpposite:
                    errorMsg = 'system.bet_opposite';
                    break;
                case 138:
                case 139:
                case 140:
                case 141:
                case 142:
                case 143:
                case 144:
                case 145:
                case 150:
                    errorMsg =
                        'system.commonWalletError.' + this._command.Reason;
                    break;
            }
            dispatch(
                inGamePopupSliceActions.open(
                    errorMsg != 'system.bet_failed'
                        ? errorMsg
                        : [errorMsg, `(${this._command.Reason})`],
                    GameType,
                    StateMsgType.betInfo_Fail,
                    this._command.GameID
                )
            );
            return;
        }
    }
}
