import { useContext, useEffect, useMemo, useState } from 'react';
import { REPORT_COLORS, ROAD_MAP_TYPE } from '../../lobby/constants';
import { useRoadMapState } from './hook';
import { getMappedRoad } from './util';
import BeadRoadCell from './cell/BeadRoadCell';
import RedBlackRoadCell from './cell/RedBlackRoadCell';
import BigSmallRoadCell from './cell/BigSmallRoadCell';
import EvenOddRoadCell from './cell/EvenOddRoadCell';
import BaseImage from '../../../components/common/baseImage';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { Box, Tabs, Tab } from '@mui/material';
import { MobPref } from '../../../hooks/storage/useLocalStorage';
import { TabPanel } from '../../lobby/components/GameTables';
import TrialImage from '../../game/components/TrialImage';
import { AudioPlayContext } from '../../../contexts/AudioPlayContext';

type RouletteRoadMapProps = {
    hostId: number;
    roadTypeSelected?: number;
    inGame?: boolean;
};

export const RouletteRoadMap = (props: RouletteRoadMapProps) => {
    const { hostId, inGame = false } = props;
    const { beadRoadRecord, setBeadRoadRecord, isOneColumn, beadRoad, IsRest } =
        useRoadMapState(hostId);

    const containerClass = useMemo(
        () =>
            inGame
                ? 'roulette-history-container'
                : isOneColumn
                  ? 'roulette-one-column-main-container'
                  : 'roulette-two-column-main-container',
        [isOneColumn]
    );

    const beadRR = useMemo(
        () =>
            getMappedRoad(
                beadRoad,
                ROAD_MAP_TYPE.BEAD_ROAD,
                inGame ? 24 : isOneColumn ? 16 : 8,
                inGame ? 6 : isOneColumn ? 6 : 3
            ),
        [beadRoad, isOneColumn, inGame]
    );

    useEffect(() => {
        if (!IsRest) {
            setBeadRoadRecord(beadRR);
        } else {
            setBeadRoadRecord(undefined);
        }
    }, [beadRoad, isOneColumn, inGame, IsRest]);

    const roadMapBgClass = useMemo(
        () =>
            inGame
                ? 'tradbaccRoadmapBg marker_roadmap'
                : isOneColumn
                  ? 'lobby grid_6row_png'
                  : 'lobby grid_3row_png',
        [isOneColumn]
    );

    return (
        <div className={containerClass}>
            <BaseImage className={`${roadMapBgClass}`} scale={1} />
            <div className="roulette-beadRoad">
                {beadRoadRecord?.map((record, idx) => (
                    <BeadRoadCell
                        size={inGame ? 20.5 : 30}
                        record={record}
                        key={idx}
                    />
                ))}
            </div>
            {inGame && <RoadMapSummaryList hostId={hostId} />}
            {inGame && <TrialImage />}
        </div>
    );
};

export const RouletteSwitchTableMap = (props: RouletteRoadMapProps) => {
    const { hostId } = props;
    const { beadRoad, IsRest } = useRoadMapState(hostId);

    const [beadRoadCells, setBeadRoadCells] = useState<JSX.Element>(<></>);

    useEffect(() => {
        const cells = new Array<JSX.Element>();

        if (!IsRest) {
            const beadRR = getMappedRoad(
                beadRoad,
                ROAD_MAP_TYPE.BEAD_ROAD,
                5,
                3
            );

            let index = 0;
            for (const bead of beadRR) {
                const cell = (
                    <BeadRoadCell size={28} record={bead} key={index} />
                );
                cells.push(cell);
                index++;
            }
        }
        setBeadRoadCells(<>{cells}</>);
    }, [beadRoad, IsRest]);

    return (
        <>
            <div className="roulette-switch-table-map-main-container">
                <BaseImage className={`lobby grid_3row_png`} scale={0.96} />
                <div className="roulette-beadRoad">{beadRoadCells}</div>
            </div>
        </>
    );
};

export const RouletteInGameMap = (props: RouletteRoadMapProps) => {
    const { hostId, roadTypeSelected } = props;

    const roadMap = () => {
        switch (roadTypeSelected) {
            case ROAD_MAP_TYPE.RED_BLACK:
                return <RouletteRedBlackRoadMap hostId={hostId} />;
            case ROAD_MAP_TYPE.BIG_SMALL_ROAD:
                return <RouletteBigSmallRoadMap hostId={hostId} />;
            case ROAD_MAP_TYPE.EVEN_ODD_ROAD:
                return <RouletteOddEvenRoadMap hostId={hostId} />;
        }
    };

    return (
        <div className="roulette-in-game">
            <BaseImage className="tradbaccRoadmapBg marker_roadmap" scale={1} />{' '}
            {roadMap()}
        </div>
    );
};

export const RouletteRoadMapPanel = (props: RouletteRoadMapProps) => {
    const { hostId } = props;
    const { playButtonAudio } = useContext(AudioPlayContext);
    const { t } = useTranslation();
    const [curRoadmapTab, setRoadmapCurTab] = useState<number>(0);
    const handleChange = (tabIdx: number) => {
        playButtonAudio();
        setRoadmapCurTab(tabIdx);
        localStorage.setItem(
            'MobPref_' + MobPref.ROUL_ROADMAP_PORT_MODE,
            JSON.stringify(tabIdx)
        );
    };
    useEffect(() => {
        const lsRoadmapTab = localStorage.getItem(
            `MobPref_${MobPref.ROUL_ROADMAP_PORT_MODE}`
        );

        if (lsRoadmapTab) {
            setRoadmapCurTab(Number(lsRoadmapTab));
        }
    }, []);
    return (
        <>
            <Box className="roulette-roadmap-container">
                <Tabs
                    className="roadmap-tabs"
                    value={curRoadmapTab}
                    onChange={(_, v) => {
                        handleChange(v);
                    }}
                    aria-label="roadmap"
                    sx={{
                        '& Button': { color: REPORT_COLORS.TITLE_TEXT },
                    }}
                >
                    <Tab
                        value={0}
                        label={t('tableinfo.red_black')}
                        onClick={() => setRoadmapCurTab(0)}
                    />
                    <Tab
                        value={1}
                        label={t('tableinfo.big_small')}
                        onClick={() => setRoadmapCurTab(1)}
                    />
                    <Tab
                        value={2}
                        label={t('tableinfo.odd_even')}
                        onClick={() => setRoadmapCurTab(2)}
                    />
                </Tabs>
                <Box height="150px">
                    <Box className="roadmap-tab">
                        <TabPanel value={curRoadmapTab} index={0}>
                            <RouletteInGameMap
                                hostId={hostId}
                                roadTypeSelected={ROAD_MAP_TYPE.RED_BLACK}
                            />
                        </TabPanel>
                        <TabPanel value={curRoadmapTab} index={1}>
                            <RouletteInGameMap
                                hostId={hostId}
                                roadTypeSelected={ROAD_MAP_TYPE.BIG_SMALL_ROAD}
                            />
                        </TabPanel>
                        <TabPanel value={curRoadmapTab} index={2}>
                            <RouletteInGameMap
                                hostId={hostId}
                                roadTypeSelected={ROAD_MAP_TYPE.EVEN_ODD_ROAD}
                            />
                        </TabPanel>
                    </Box>
                    <RoadMapSummaryList hostId={hostId} />
                </Box>
            </Box>
            <TrialImage />
        </>
    );
};

const RoadMapSummaryList = (props: RouletteRoadMapProps) => {
    const { hostId } = props;
    const { t } = useTranslation();
    const { summaryList } = useRoadMapState(hostId);
    const {
        redTotal,
        blackTotal,
        oddTotal,
        evenTotal,
        bigTotal,
        smallTotal,
        zeroTotal,
    } = summaryList;
    return (
        <>
            <div className="roulette-summary-list">
                <div className="summary-red">{t('road.red')}</div>
                {redTotal}
                <div className="summary-black">{t('road.black')}</div>
                {blackTotal}
                <div className="summary-odd">{t('road.odd')}</div>
                {oddTotal}
                <div className="summary-even">{t('road.even')}</div>
                {evenTotal}
                <div className="summary-big">{t('road.big')}</div>
                {bigTotal}
                <div className="summary-small">{t('road.small')}</div>
                {smallTotal}
                <div className="summary-zero">{0}</div>
                {zeroTotal}
            </div>
        </>
    );
};

const RouletteRedBlackRoadMap = (props: RouletteRoadMapProps) => {
    const { hostId } = props;
    const { redBlackRoadRecord, setRedBlackRoadRecord, beadRoad, IsRest } =
        useRoadMapState(hostId);
    const redBlackRR = useMemo(
        () => getMappedRoad(beadRoad, ROAD_MAP_TYPE.RED_BLACK, 24, 6),
        [beadRoad]
    );
    useEffect(() => {
        if (!IsRest) {
            setRedBlackRoadRecord(redBlackRR);
        } else {
            setRedBlackRoadRecord(undefined);
        }
    }, [beadRoad, IsRest]);

    return (
        <div className="roulette-road">
            {redBlackRoadRecord?.map((record, idx) => (
                <RedBlackRoadCell
                    size={20.5}
                    record={record}
                    key={`red-black-road-cell-${idx}`}
                />
            ))}
        </div>
    );
};

const RouletteBigSmallRoadMap = (props: RouletteRoadMapProps) => {
    const { hostId } = props;
    const { bigSmallRoadRecord, setBigSmallRoadRecord, beadRoad, IsRest } =
        useRoadMapState(hostId);
    const bigSmallRR = useMemo(
        () => getMappedRoad(beadRoad, ROAD_MAP_TYPE.BIG_SMALL_ROAD, 24, 6),
        [beadRoad]
    );
    useEffect(() => {
        if (!IsRest) {
            setBigSmallRoadRecord(bigSmallRR);
        } else {
            setBigSmallRoadRecord(undefined);
        }
    }, [beadRoad, IsRest]);

    return (
        <div className="roulette-road">
            {bigSmallRoadRecord?.map((record, idx) => (
                <BigSmallRoadCell
                    size={20.5}
                    record={record}
                    key={`big-small-road-cell-${idx}`}
                />
            ))}
        </div>
    );
};

const RouletteOddEvenRoadMap = (props: RouletteRoadMapProps) => {
    const { hostId } = props;
    const { evenOddRoadRecord, setEvenOddRoadRecord, beadRoad, IsRest } =
        useRoadMapState(hostId);
    const evenOddRR = useMemo(
        () => getMappedRoad(beadRoad, ROAD_MAP_TYPE.EVEN_ODD_ROAD, 24, 6),
        [beadRoad]
    );
    useEffect(() => {
        if (!IsRest) {
            setEvenOddRoadRecord(evenOddRR);
        } else {
            setEvenOddRoadRecord(undefined);
        }
    }, [beadRoad, IsRest]);

    return (
        <div className="roulette-road">
            {evenOddRoadRecord?.map((record, idx) => (
                <EvenOddRoadCell
                    size={20.5}
                    record={record}
                    key={`even-odd-road-cell-${idx}`}
                />
            ))}
        </div>
    );
};
