import {
    TeenPattiRecord,
    TeenPattiRoadmapRecord,
} from '../../../models/games/teenpatti';
import { SupportedRecord } from '../../host/models';
import { ROAD_MAP_TYPE } from '../../lobby/constants';

export const getUnion = (list: Array<TeenPattiRoadmapRecord>) => {
    let tmp: TeenPattiRoadmapRecord = {
        LastWin: list[0].LastWin,
        Win: list[0].Win,
        WinPoint: list[0].WinPoint,
        LastDraw: list[0].LastDraw,
        SpaceNum: list.length - 1,
        IsLast: false,
        Detail: list[0].Detail,
    };
    for (let i = 0; i < list.length; i++) {
        if (list[i].LastDraw) tmp.LastDraw = true;
        if (list[i].Detail) tmp.Detail = list[i].Detail;
    }
    return tmp;
};

export const getBeadRoad = (
    oldResult: SupportedRecord[] | undefined
): Array<TeenPattiRoadmapRecord> => {
    let record = new Array<TeenPattiRoadmapRecord>();
    if (oldResult) {
        oldResult.forEach((resultItem, idx) => {
            const result = resultItem as TeenPattiRecord;
            let win = -1;
            if (result.result == 1) {
                win = 1;
            } else if (result.result == 2) {
                win = 0;
            } else if (result.result == 0) {
                win = 2;
            }
            if (win >= 0) {
                record.push({
                    LastWin: -1,
                    Win: win,
                    WinPoint: result.resultPoint,
                    Idx: idx,
                    LastDraw: false,
                    SpaceNum: 0,
                    IsLast: idx == oldResult.length - 1,
                    Detail: {
                        GameID: result.gameID,
                        Result: result.result,
                        Shoes: result.shoes,
                        Round: result.round,
                    },
                });
            }
        });
    }

    return record;
};

export const getOtherRoad = (
    beadRoad: Array<TeenPattiRoadmapRecord> | undefined
): Array<Array<TeenPattiRoadmapRecord>> => {
    let record = new Array<Array<TeenPattiRoadmapRecord>>();

    if (beadRoad && beadRoad.length > 0) {
        let curDataIdx: number = 0;
        let curWin: number = -1;
        let x: number = -1;
        let y: number = -1;

        while (curDataIdx < beadRoad.length) {
            const curRecord = beadRoad[curDataIdx];

            if (curRecord.Win != curWin) {
                if (curRecord.Win != 2) {
                    curWin = curRecord.Win;
                    x++;
                    y = 0;
                } else {
                    if (x >= 0 && y >= 0) {
                        record[x][y].LastDraw = true;
                    }
                    curDataIdx++;
                    continue;
                }
            } else {
                y++;
            }

            while (record.length <= x) {
                let tmp = new Array<TeenPattiRoadmapRecord>();
                tmp.push({
                    LastWin: 0,
                    Win: -1,
                    WinPoint: 0,
                    LastDraw: false,
                    SpaceNum: 0,
                    IsLast: false,
                });
                record.push(tmp);
            }

            while (record[x].length <= y) {
                record[x].push({
                    LastWin: 0,
                    Win: -1,
                    WinPoint: 0,
                    LastDraw: false,
                    SpaceNum: 0,
                    IsLast: false,
                });
            }

            record[x][y] = beadRoad[curDataIdx];
            curDataIdx++;
        }
    }

    return record;
};
export const getMappedRoad = (
    beadRoad: Array<TeenPattiRoadmapRecord>,
    typeID: number,
    gridWidth: number,
    gridHeight: number
): Array<TeenPattiRoadmapRecord> => {
    let record = new Array<TeenPattiRoadmapRecord>();
    let offset: number = 0;
    let width: number = 0;
    let askRoadFix: boolean;
    let offsetFixed: boolean;

    let otherRoad = undefined;
    switch (typeID) {
        case ROAD_MAP_TYPE.BEAD_ROAD:
            while (gridWidth * gridHeight <= beadRoad.length - offset) {
                offset += gridHeight;
            }

            for (let i: number = offset; i < beadRoad.length; i++) {
                record.push(beadRoad[i]);
            }
            return record;
        default:
            width = gridWidth;
            offset = 0;
            askRoadFix = false;
            offsetFixed = false;
            otherRoad = getOtherRoad(beadRoad);
            while (!offsetFixed) {
                let placed: boolean = true;
                for (let x: number = offset; x < otherRoad.length; x++) {
                    let start_pt = x - offset;
                    let turned: boolean = false;
                    let turned_pt: number = -1;
                    let spaceOne: boolean = false;

                    for (let y: number = 0; y < otherRoad[x].length; y++) {
                        let idx: number = start_pt * gridHeight + y;
                        const max: number = gridHeight;

                        if (y >= max && !turned) {
                            turned = true;
                            turned_pt = y - 1;
                            idx =
                                (start_pt + (y - (max - 1))) * gridHeight +
                                (max - 1);
                        }

                        if (spaceOne) {
                            // spaceCount++;
                        } else {
                            if (!turned) {
                                if (
                                    idx < record.length &&
                                    record[idx].Win >= 0
                                ) {
                                    turned = true;
                                    turned_pt = y - 1;

                                    if (
                                        record[idx].Win == otherRoad[x][y].Win
                                    ) {
                                        turned_pt--;
                                    }

                                    idx =
                                        (start_pt + (y - turned_pt)) *
                                            gridHeight +
                                        turned_pt;

                                    if (turned_pt == 0) {
                                        spaceOne = true;
                                    }
                                }

                                if (!turned) {
                                    if (
                                        idx + 1 < record.length &&
                                        record[idx + 1].Win ==
                                            otherRoad[x][y].Win
                                    ) {
                                        if (y == 0) {
                                            spaceOne = true;
                                            // spaceCount++;
                                        } else {
                                            idx += gridHeight - 1;

                                            if (idx % gridHeight == 0) {
                                                spaceOne = true;
                                                // spaceCount++;
                                            }
                                        }
                                    }
                                }
                            } else {
                                idx =
                                    (start_pt + (y - turned_pt)) * gridHeight +
                                    turned_pt;
                            }
                        }

                        if (spaceOne) {
                            idx = start_pt * gridHeight;
                        }

                        if (idx < 0) {
                            continue;
                        }

                        while (record.length <= idx) {
                            record.push({
                                LastWin: 0,
                                Win: -1,
                                WinPoint: 0,
                                LastDraw: false,
                                SpaceNum: 0,
                                IsLast: false,
                            });
                        }

                        if (spaceOne) {
                            record[idx].SpaceNum = y + 1;
                            otherRoad[x][y].SpaceNum = y;

                            let tmp = (start_pt + 1) * gridHeight;
                            if (tmp < record.length) {
                                record[tmp].Win = -1;
                            }

                            record[idx] = getUnion(otherRoad[x]);
                        } else {
                            otherRoad[x][y].SpaceNum = 0;
                            record[idx] = otherRoad[x][y];
                        }

                        if ((idx + 1) / gridHeight > width) {
                            placed = false;
                        }

                        if (askRoadFix && x == otherRoad.length - 2) {
                            //Fix have turned road longer than new line, cancel ask road width added
                            let lastColumeFirstOffset =
                                record.length % gridHeight == 0
                                    ? gridHeight
                                    : record.length % gridHeight;
                            if (
                                record[record.length - lastColumeFirstOffset]
                                    .Win == -1
                            ) {
                                askRoadFix = false;
                                width -= 1;
                                placed = false;
                            }
                        }
                    }
                }

                if (placed) {
                    offsetFixed = true;
                } else {
                    while (record.length / gridHeight > width) {
                        let haveLast = false;
                        for (let i = 0; i < gridHeight; i++) {
                            if (record[i]?.IsLast) {
                                haveLast = true;
                            }
                        }

                        if (haveLast) {
                            record = record.slice(0, record.length - 1);
                        } else {
                            record = record.slice(gridHeight);
                        }
                    }
                    offsetFixed = true;
                }
            }
            break;
    }
    return record;
};
