import { Box } from '@mui/material';
import { useMemo } from 'react';
import chip_image from '../../../../../assets/image/common/blackjack/chip_black_white.png';
import { BaseTypography } from '../../../../../components/common/baseText/BaseTypography';
type BetAmountProps = {
    amount: number;
    scale?: number;
};
export const BetAmount = ({ amount, scale = 1 }: BetAmountProps) => {
    const w = useMemo(() => 64 * scale, [scale]);
    return (
        <Box
            sx={{
                width: w,
                height: w,
                textAlign: 'center',
            }}
        >
            <img src={chip_image} style={{ width: w, height: w }} />
            <BaseTypography
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
                fontSize={w * 0.5}
                resize={{
                    direction: 'horizontal',
                    value: `${w * 0.8}px`,
                }}
            >
                {amount}
            </BaseTypography>
        </Box>
    );
};
