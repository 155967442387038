import { Stack } from '@mui/material';
import { useContext } from 'react';
import { CommonConfigContext } from '../../../../contexts/ConfigContext';
import { useCurrentTime } from '../../../../hooks/useCurrentTime';
import { Setting } from '../../../setting';
import UserInformation from '../../../setting/components/userInformation';
import { LobbyLogo } from './Logo';
import './index.scss';

export const LobbyHeader = () => {
    const { headerBackground } = useContext(CommonConfigContext);
    const { currentTimeStamp } = useCurrentTime();
    const showEventBg = () => {
        const now = currentTimeStamp();
        return (
            now >= headerBackground.startDate && now < headerBackground.endDate
        );
    };
    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                className="header-container"
                sx={{
                    backgroundImage: showEventBg()
                        ? `url(${process.env.PUBLIC_URL}/resource/assets/image/header_bg/${headerBackground.source})`
                        : '',
                }}
            >
                <LobbyLogo />
                <div className="rightGrp">
                    <div className="user-info-container">
                        <UserInformation isHeader isInGame={false} />
                    </div>
                    <Setting isLobby={true} />
                </div>
            </Stack>
        </>
    );
};
