import BScroll from '@better-scroll/core';
import { BScrollConstructor } from '@better-scroll/core/dist/types/BScroll';
import Slide from '@better-scroll/slide';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Tab, Tabs } from '@mui/material';
import { motion, useAnimationControls } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AudioPlayContext } from '../../../../../contexts/AudioPlayContext';
import { TableInfoContext } from '../../../../../contexts/TableInfoContext';
import { VideoPlayerContext } from '../../../../../contexts/VideoContext';
import { TableInfoKeyType } from '../../../../../hooks/useTableInfo';
import { GameType } from '../../../../../models/games/enums/GameType';
import { theme } from '../../../../../theme';
import { InGameRoadMap } from '../../../../lobby/components/Roadmaps';
import { REPORT_COLORS } from '../../../../lobby/constants';
import { RouletteRoadMap } from '../../../../roadmap/roulette';
import { RouletteTableInfoTab } from './RouletteTableInfoTab';
import './index.scss';

export const TableInfoPanel = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const controller = useAnimationControls();
    const [tableInfoTab, setTableInfoTab] = useState<number>(-1);
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const [scrollObj, setScrollObj] = useState<BScrollConstructor>();
    const { setTableInfo } = useContext(TableInfoContext);

    const tabList = ['road_map', 'tableinfo.history', 'tableinfo.name'];

    const animUp = (tab: number, startY = 0) => {
        tab == 1
            ? controller.start({
                  y: [startY, -165, -165],
              })
            : controller.start({
                  y: [startY, -192, -192],
              });
    };
    const animDown = (tab: number) => {
        controller.start({
            y: [tab == 1 ? -162 : -192, 0, 0],
            zIndex: theme.zIndex.drawer - 400,
        });
    };

    const infoDown = (tab: number) => {
        animDown(tab);
        setTableInfoTab(-1);
    };

    const handleTab = (tab: number) => {
        playButtonAudio();
        if (tableInfoTab === -1) {
            animUp(tab);
            handleTabScrollTo(tab, false);
        } else {
            if (tab === tableInfoTab) {
                infoDown(tab);
            } else {
                if (!(tab != 1 && tableInfoTab != 1))
                    animUp(tab, tab == 1 ? -192 : -165);
                handleTabScrollTo(tab);
            }
        }
    };

    const handleTabScrollTo = (v: number, isSmooth: boolean = true) => {
        if (scrollObj) {
            if (v !== tableInfoTab) {
                setTableInfoTab(v);
                if (isSmooth) {
                    scrollObj.goToPage(v, 0);
                } else {
                    scrollObj.goToPage(v, 0, 0);
                }
            }
        }
    };

    const mounted = useRef();
    const onPageChange = (tab: number) => {
        setTableInfoTab(tab);
        if (mounted.current) controller.set({ y: tab == 1 ? -165 : -192 });
        let gKey: TableInfoKeyType = 'roadmap';
        if (tab === 2) {
            gKey = 'tableInfo';
        }
        setTableInfo(gKey);
    };

    useEffect(() => {
        if (ref && ref.current) {
            BScroll.use(Slide);
            const newBScroll = new BScroll(ref.current as HTMLDivElement, {
                scrollX: true,
                scrollY: false,
                click: true,
                slide: {
                    loop: false,
                    autoplay: false,
                    threshold: 100,
                },
                momentum: false,
            });
            newBScroll.on('slidePageChanged', (page: { pageX: number }) => {
                if (page) {
                    const tab = page.pageX;
                    onPageChange(tab);
                }
            });
            setScrollObj(newBScroll);
        }
        return () => {
            if (ref && ref.current && scrollObj) {
                scrollObj.destroy();
            }
        };
    }, []);

    return (
        <>
            <motion.div
                animate={controller}
                initial={{
                    y: 0,
                    width: 540,
                    height: 54,
                    position: 'absolute',
                    zIndex: theme.zIndex.drawer - 400,
                }}
            >
                <Box
                    ref={mounted}
                    sx={{ height: 243, bgcolor: '#251c12' }}
                    className={'roulette-table-info-container'}
                >
                    <Tabs
                        className="table-info-tabs"
                        value={tableInfoTab}
                        aria-label="table info."
                        sx={{
                            '& Button': { color: REPORT_COLORS.TITLE_TEXT },
                        }}
                    >
                        {/*fix bug Mui Tab value -1 */}
                        <Tab
                            value={-1}
                            sx={{
                                width: 0,
                                minWidth: 0,
                                padding: 0,
                                margin: 0,
                            }}
                        />
                        {tabList &&
                            tabList.map((tab, idx) => (
                                <Tab
                                    key={`tab-${idx}}`}
                                    className={`table-info-tab${idx}`}
                                    label={t(tab)}
                                    value={idx}
                                    icon={
                                        tableInfoTab === -1 ? (
                                            <KeyboardArrowUp />
                                        ) : (
                                            <KeyboardArrowDown
                                                sx={{
                                                    opacity:
                                                        tableInfoTab === idx
                                                            ? 1
                                                            : 0,
                                                }}
                                            />
                                        )
                                    }
                                    iconPosition="end"
                                    onClick={() => handleTab(idx)}
                                />
                            ))}
                    </Tabs>

                    <Box
                        ref={ref}
                        sx={{
                            overflowX: 'auto',
                            scrollSnapType: 'x mandatory',
                            whiteSpace: 'nowrap',
                            scrollBehavior: 'smooth',
                            scrollbarWidth: 'none',
                            '&::-webkit-scrollbar': {
                                display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                            },
                            '&-ms-overflow-style': {
                                display: 'none', // Hide the scrollbar for IE
                            },
                        }}
                    >
                        <Box sx={{ display: 'inline-block' }}>
                            <Box
                                sx={{
                                    display: 'inline-block',
                                    scrollSnapAlign: 'start',
                                    position: 'relative',
                                    overflow: 'hidden',
                                }}
                            >
                                <InGameRoadMap
                                    hostId={hostId}
                                    gameType={GameType.Roulette}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'inline-block',
                                    scrollSnapAlign: 'start',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    height: '180px',
                                }}
                            >
                                <Box className={'history-tab'}>
                                    <RouletteRoadMap
                                        hostId={hostId}
                                        inGame={true}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'inline-block',
                                    scrollSnapAlign: 'start',
                                    position: 'relative',
                                    overflow: 'hidden',
                                }}
                            >
                                <RouletteTableInfoTab hostId={hostId} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </motion.div>
        </>
    );
};
