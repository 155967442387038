import { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { VideoPlayerContext } from '../../../../../../contexts/VideoContext';
import { SicBoStatistics } from '../../../../../../models/games/sicbo';
import { RootState } from '../../../../../../store/store';
import { getHostById } from '../../../../../host/slice';
import { Box, Grid, LinearProgress, Stack, Typography } from '@mui/material';

export const SicboStatisticBetType = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { Statistics } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [rateList, setRateList] = useState<number[]>([]);

    useEffect(() => {
        if (Statistics) {
            const Big = (Statistics as SicBoStatistics).BigRate || 0;
            const BigRate = Math.round(Big * 100);
            const Small = (Statistics as SicBoStatistics).SmallRate || 0;
            const SmallRate = Math.round(Small * 100);
            const Triple = (Statistics as SicBoStatistics).TripleRate || 0;
            const TripleRate = Math.round(Triple * 100);
            const Even = (Statistics as SicBoStatistics).EvenRate || 0;
            const EvenRate = Math.round(Even * 100);
            const Odd = (Statistics as SicBoStatistics).OddRate || 0;
            const OddRate = Math.round(Odd * 100);

            setRateList([BigRate, SmallRate, TripleRate, EvenRate, OddRate]);
        }
    }, [Statistics]);
    return (
        <>
            <Stack direction={'row'} className="bet-type">
                <Grid
                    item
                    container
                    direction={'column'}
                    flexWrap={'nowrap'}
                    justifyContent={'space-around'}
                    height={73}
                >
                    <RateBar type={'B'} rate={rateList[0] || 0} />
                    <RateBar type={'S'} rate={rateList[1] || 0} />
                    <RateBar type={'T'} rate={rateList[2] || 0} />
                </Grid>
                <Grid
                    item
                    container
                    direction={'column'}
                    flexWrap={'nowrap'}
                    justifyContent={'space-around'}
                    height={73}
                >
                    <RateBar type={'E'} rate={rateList[3] || 0} />
                    <RateBar type={'O'} rate={rateList[4] || 0} />
                    <Box className={'Null'}/>
                </Grid>
            </Stack>
        </>
    );
};

const RateBar = ({ type, rate }: { type: string; rate: number }) => {
    return (
        <Grid
            item
            container
            direction={'row'}
            flexWrap={'nowrap'}
            justifyContent={'space-between'}
            alignItems={'center'}
            className={type}
        >
            <Typography className={'title'}>{type}</Typography>
            <LinearProgress
                variant="determinate"
                value={rate}
                sx={{
                    position: 'absolute',
                    width: 83,
                    height: 12,
                    marginLeft: '12px'
                }}
            />
            <Typography className={'per'}>{`${rate}%`}</Typography>
        </Grid>
    );
};
