import { useDispatch, useSelector } from 'react-redux';
import { getInGamePopupSate } from '../../../popup/selector';
import { GameType as GameTypeEnum } from '../../../../models/games/enums/GameType';
import { BaccaratBetResultPopup } from './BaccaratBetResultPopup';
import { useEffect, useRef } from 'react';
import { inGamePopupSliceActions } from '../../../popup/inGameSlice';
import { DragonTigerBetResultPopup } from './DragonTigerBetResultPopup';
import { BlackjackBetResultPopup } from './BlackjackBetResultPopup';
const BetResultPanel = () => {
    const dispatch = useDispatch();
    const popupData = useSelector(getInGamePopupSate);
    const { isOpen, gameType, dismiss, popupId } = popupData;
    const timer = useRef<NodeJS.Timeout | null>();
    useEffect(() => {
        if (dismiss && isOpen) {
            if (timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                dispatch(inGamePopupSliceActions.close());
            }, dismiss);
        } else {
            if (timer.current) clearTimeout(timer.current);
        }
        return () => {
            if (timer.current) clearTimeout(timer.current);
        };
    }, [popupId, dismiss]);

    if (isOpen) {
        switch (gameType) {
            case GameTypeEnum.Baccarat:
                return <BaccaratBetResultPopup />;
            case GameTypeEnum.Dragon:
                return <DragonTigerBetResultPopup />;
            case GameTypeEnum.Blackjack:
                return <BlackjackBetResultPopup />;
            default:
                //TODO: Other game
                return <DragonTigerBetResultPopup />;
        }
    } else {
        return <></>;
    }
};
export default BetResultPanel;
