import { Divider, FormControl, FormLabel, TextField } from '@mui/material';
import moment from 'moment';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AudioPlayContext } from '../../../../contexts/AudioPlayContext';
import { CdnContext } from '../../../../contexts/CdnContext';
import { GlobalDataContext } from '../../../../contexts/GlobalDataContext';
import { WebSocketContext } from '../../../../contexts/WebSocketContext';
import { useClientIp } from '../../../../hooks/useClientIp';
import { IsMobile } from '../../../../hooks/useScreenRatio';
import { useUserAgent } from '../../../../hooks/useUserAgent';
import { Rating, Reason } from '../../../../models/Rating';
import { getGameSate } from '../../../games/selector';
import { getLandingState, getMainUserState } from '../../../main/selector';
import { popupSliceActions } from '../../../popup/slice';
import { TickButton } from '../TickButton';
import { FeedbackRating } from './components/FeedbackRating';
import './index.scss';

type RatingState = {
    Reason: number;
    RateString: string;
    Rate: number;
};
type FeedbackFormState = {
    Username: string;
    PlayerIP: string;
    Opinion: string;
    Displayname: string;
    WalletType: string;
    Rating: string; //Array<RatingState>;
    UI: 'ReactMobile' | 'ReactWeb';
    Browser: string;
    UserAgent: string;
    LobbyServerProxy: string;
    LastVideoStream: string;
};
type FeedbackFormProps = {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
};
export const FeedbackForm = (props: FeedbackFormProps) => {
    const { setIsOpen } = props;
    const { feedbackApi } = useContext(CdnContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const [videoRating, setVideoRating] = useState<Rating>(-1);
    const [guiRating, setGuiRating] = useState<Rating>(-1);
    const [networkRating, setNetworkRating] = useState<Rating>(-1);
    const [dealerRating, setDealerRating] = useState<Rating>(-1);
    const [experienceRating, setExperienceRating] = useState<Rating>(-1);
    const [opinion, setOpinion] = useState('');
    const { feedbackData, setFeedbackData } = useContext(GlobalDataContext);
    const { t } = useTranslation();
    const { ua, browser } = useUserAgent();
    const { ip } = useClientIp();
    const { connectedUrl } = useContext(WebSocketContext);
    const { Username, IsCommonWallet } = useSelector(getMainUserState);
    const { username: LandingUsername } = useSelector(getLandingState);
    const { lastVideo, videoLibraryVersion } = useSelector(getGameSate);
    const { device } = useUserAgent();
    const dispatch = useDispatch();
    const getRating = (reason: Reason, rating: Rating) =>
        ({
            Reason: reason,
            RateString: Rating[rating].toString(),
            Rate: rating,
        }) as RatingState;
    const getRatings = () => {
        const ratings = new Array<RatingState>();
        if (videoRating > -1)
            ratings.push(getRating(Reason.QualityOfTheVideo, videoRating));
        if (guiRating > -1)
            ratings.push(getRating(Reason.GameGraphicInterface, guiRating));
        if (networkRating > -1)
            ratings.push(
                getRating(Reason.NetworkConnectionSpeed, networkRating)
            );
        if (dealerRating > -1)
            ratings.push(
                getRating(Reason.DealersProfessionalism, dealerRating)
            );
        if (experienceRating > -1)
            ratings.push(getRating(Reason.UserFriendliness, experienceRating));
        return ratings;
    };
    const submit = async () => {
        playButtonAudio();
        const timestamp = feedbackData?.blockTimestamp;
        const times = feedbackData?.sends;

        if (timestamp !== undefined) {
            const now = moment().valueOf();
            if (now <= timestamp) {
                //within time range
                if (times && times >= 2) {
                    dispatch(
                        popupSliceActions.open('popup.feedback_invalid_send')
                    );
                    setIsOpen(false);
                    return;
                }
            } else {
                // window.localStorage.setItem(feedbackSendsKey, '0');
                setFeedbackData({
                    blockTimestamp: timestamp,
                    sends: 0,
                });
            }
        }

        if (opinion.trim().length === 0) {
            dispatch(popupSliceActions.open('popup.feedback_checking'));
            return;
        }
        const request = {} as FeedbackFormState;
        // the opinion api is case sensitive, so dont change the case of name
        request.Username = Username;
        request.PlayerIP = ip ?? '';
        // btoa(string) does not support chinese character
        request.Opinion = btoa(unescape(encodeURIComponent(opinion)));
        request.Displayname = LandingUsername ?? '';
        request.WalletType = IsCommonWallet
            ? 'Common Wallet'
            : 'Transfer Wallet';
        request.Rating = JSON.stringify(getRatings());
        request.UI = IsMobile(device) ? 'ReactMobile' : 'ReactWeb';
        request.Browser = `${browser.name} ${browser.version}`;
        request.UserAgent = ua;
        request.LobbyServerProxy = connectedUrl.current ?? '';
        request.LastVideoStream = `${lastVideo} (${videoLibraryVersion})`;
        const opinionUrl = feedbackApi;
        if (opinionUrl)
            fetch(opinionUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(request),
            }).then(response => {
                response
                    .json()
                    .then(data => console.log('app::setting::feedback', data));
            });
        setIsOpen(false);
        dispatch(popupSliceActions.open('popup.feedback_success'));

        const next = moment().valueOf() + 60 * 60000;
        const lsTimes = feedbackData?.sends;
        const nextTimes = lsTimes ? lsTimes + 1 : 1;
        setFeedbackData({
            blockTimestamp: next,
            sends: nextTimes,
        });
    };

    return (
        <>
            <FormControl className="FeedbackForm">
                <FeedbackRating
                    name="feedback.video"
                    value={videoRating}
                    onChange={setVideoRating}
                    playButtonAudio={playButtonAudio}
                />
                <Divider />
                <FeedbackRating
                    name="feedback.gameui"
                    value={guiRating}
                    onChange={setGuiRating}
                    playButtonAudio={playButtonAudio}
                />
                <Divider />
                <FeedbackRating
                    name="feedback.network_speed"
                    value={networkRating}
                    onChange={setNetworkRating}
                    playButtonAudio={playButtonAudio}
                />
                <Divider />
                <FeedbackRating
                    name="feedback.dealer"
                    value={dealerRating}
                    onChange={setDealerRating}
                    playButtonAudio={playButtonAudio}
                />
                <Divider />
                <FeedbackRating
                    name="feedback.user_friendly"
                    value={experienceRating}
                    onChange={setExperienceRating}
                    playButtonAudio={playButtonAudio}
                />
                <Divider />
                <FormControl className="OpinionsTextField">
                    <FormLabel>{t('feedback.detail')}</FormLabel>
                    <TextField
                        id="feedback-text-opinions"
                        // label={t('feedback.detail')}
                        multiline
                        fullWidth
                        rows={4}
                        value={opinion}
                        onChange={e => {
                            setOpinion(e.target.value);
                        }}
                        variant="standard"
                    />
                </FormControl>
                <FormControl className="SubmitButton">
                    <TickButton onClick={submit} />
                </FormControl>
            </FormControl>
        </>
    );
};

export default FeedbackForm;
