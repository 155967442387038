// import './index.scss';
import { Grid, Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import BaseImage from '../../../../../components/common/baseImage';
import AnimationImage from '../../../../../components/common/baseImage/components/animationImage';
import Button from '../../../../../components/common/button';
import { GameCategoryTab as GameCategoryTabEnum } from '../../../../../models/lobby/GameCategoryTab';
import { LobbyUiContext } from '../../../../../contexts/LobbyUiContext';

export const FeaturedButton = () => {
    const { currentTab: activeId } = useContext(LobbyUiContext);
    const [imageBg, setImageBg] = useState<string>();
    const [imageText, setImageText] = useState<string>();
    const [imageIcon, setImageIcon] = useState<string>();

    useEffect(() => {
        let id = GameCategoryTabEnum.Featured;
        let isActive = id === activeId;
        // let imageBgHtml: JSX.Element = <></>;
        let imageBg = 'lobby';
        imageBg += isActive ? ' menu_tab_active' : ' menu_tab_inactive';
        setImageBg(imageBg);

        let imageText = 'menu';
        imageText += isActive ? ' menu_feature' : ' menu_feature_unselected';
        setImageText(imageText);

        let imageIcon = 'star_anim_';
        imageIcon += isActive ? 'active star_a_00' : 'inactive star_b_00';
        setImageIcon(imageIcon);
    }, [activeId]);

    return (
        <>
            {imageBg && imageText && imageIcon && (
                <>
                    <Button>
                        <>
                            <BaseImage className={imageBg} scale={0.5} />
                            <Stack
                                direction="row"
                                alignContent="center"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                                sx={{
                                    width: '150px',
                                    height: '50px',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    margin: 0,
                                }}
                            >
                                <Grid item>
                                    <AnimationImage
                                        key={imageIcon}
                                        source={imageIcon}
                                        endFrame={6}
                                        duration={1000}
                                        intervalTime={3}
                                        //state={'play'}
                                    />
                                </Grid>
                                <Grid item>
                                    <BaseImage
                                        className={imageText}
                                        isMultiLang={true}
                                    />
                                </Grid>
                            </Stack>
                        </>
                    </Button>
                </>
            )}
        </>
    );
};
