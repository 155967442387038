import { Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { PokDengRoadmapRecord } from '../../../models/games/pokdeng';
import { GameResultState as PokDengGameResultState } from '../../../models/games/pokdeng/GameState';
import {
    GetPDCardsFlush,
    GetPDPointFromPDHandResult,
} from '../../../utils/games/pokdeng';
import ShuffleLabel from '../../game/components/ShuffleLabel';
import TrialImage from '../../game/components/TrialImage';
import PokDengRoadmapGrid from './cells/PokDengRoadmapGrid';
import PokDengSingleCell from './cells/PokDengSingleCell';
import PokDengStatisticPanel from './cells/PokDengStatisticPanel';
import { PokDengRoadMapSingleCellProps, useRoadMapState } from './hook';
import './pokdeng.scss';

type PokDengRoadMapProps = {
    hostId: number;
};
export const PokDengRoadMap = ({ hostId }: PokDengRoadMapProps) => {
    // const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { isOneColumn, pokDengRoadRecord, IsRest } = useRoadMapState(hostId);
    // const mapList = useRoadMapState(hostId);
    const containerClass = useMemo(
        () =>
            isOneColumn
                ? 'pokdeng-oneColumnMainContainer'
                : 'pokdeng-twoColumnMainContainer',
        [isOneColumn]
    );
    const columnNum = useMemo(() => (isOneColumn ? 15 : 14), [isOneColumn]);
    // const { Record } = host;
    const size = useMemo(() => (isOneColumn ? 32 : 16), [isOneColumn]);
    return (
        <div className={containerClass}>
            <PokDengRoadmapGrid size={size} havePoint={isOneColumn} />
            {!IsRest && (
                <div className="pokdeng-record">
                    {[...'-'.repeat(columnNum)].map((v, index) => {
                        let start = 0;
                        if (pokDengRoadRecord) {
                            start = Math.max(
                                pokDengRoadRecord.length - columnNum,
                                0
                            );
                        }
                        const r = pokDengRoadRecord?.at(start + index) as
                            | PokDengRoadmapRecord
                            | undefined;
                        return (
                            <PokDengRoadmapColumn
                                size={size}
                                record={r}
                                havePoint={isOneColumn}
                                key={`pokdeng-road-col-${hostId}-${index}`}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};
type PokDengRoadMapColumnProps = {
    record: PokDengRoadmapRecord | undefined;
    size: number;
    havePoint: boolean;
};

const PokDengRoadmapColumn = ({
    record,
    size,
    havePoint,
}: PokDengRoadMapColumnProps) => {
    const mapList = useMemo(() => getPokDengRoadList(record), [record]);
    return (
        <Grid container direction={'column'} justifyContent={'space-around'}>
            <Grid container direction={'row'}>
                {mapList?.map((data, idx) => (
                    <PokDengSingleCell
                        size={size}
                        isBanker={data.isBanker}
                        gameResult={data.gameResult}
                        point={data.point}
                        isPair={data.isPair}
                        isFlush={data.isFlush}
                        havePoint={havePoint}
                        key={`pokdeng-cell-${idx}`}
                    />
                ))}
            </Grid>
        </Grid>
    );
};

export const getPokDengRoadList = (
    record?: PokDengRoadmapRecord
): Array<PokDengRoadMapSingleCellProps> => {
    let list = new Array<PokDengRoadMapSingleCellProps>();
    if (record) {
        const banker: PokDengRoadMapSingleCellProps = {
            isBanker: true,
            gameResult: PokDengGameResultState.PDRS_NoHand,
            point: GetPDPointFromPDHandResult(record.PDR_BankerPoint),
            isPair: record.PDR_BankerPair,
            isFlush: record.fullDeck
                ? GetPDCardsFlush([
                      record.fullDeck.bankerDeck.card1,
                      record.fullDeck.bankerDeck.card2,
                  ])
                : false,
        };
        list.push(banker);

        for (let i = 0; i < record.playerResult.length; i++) {
            list.push({
                isBanker: false,
                gameResult: record.playerResult[i].resultState,
                point: GetPDPointFromPDHandResult(
                    record.playerResult[i].handResult
                ),
                isPair: record.playerResult[i].isPair,
                isFlush: record.fullDeck
                    ? GetPDCardsFlush([
                          record.fullDeck.playerDecks[i].card1,
                          record.fullDeck.playerDecks[i].card2,
                      ])
                    : false,
            });
        }
    }
    return list;
};

export const PokDengInGameRoadMap = (props: PokDengRoadMapProps) => {
    const { hostId } = props;
    const { pokDengRoadRecord, IsRest } = useRoadMapState(hostId);
    const size: number = 23;
    const columnNum: number = 21;
    const havePoint: boolean = true;
    return (
        <div>
            <div className="pokdeng-ingame-main-container">
                <PokDengRoadmapGrid size={size} havePoint={havePoint} />
                {!IsRest && (
                    <div className="pokdeng-record">
                        {[...'-'.repeat(columnNum)].map((v, index) => {
                            let start = 0;
                            if (pokDengRoadRecord) {
                                start = Math.max(
                                    pokDengRoadRecord.length - columnNum,
                                    0
                                );
                            }
                            const r = pokDengRoadRecord?.at(start + index) as
                                | PokDengRoadmapRecord
                                | undefined;
                            return (
                                <PokDengRoadmapColumn
                                    size={size}
                                    record={r}
                                    havePoint={havePoint}
                                    key={`game-id-${hostId}-${index}`}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
            <div className="pokdeng-statistic">
                <PokDengStatisticPanel hostId={hostId} />
            </div>
            <TrialImage />
            <ShuffleLabel hostId={hostId} />
        </div>
    );
};
const size: number = 15.6;
const columnNum: number = 10;
const havePoint: boolean = false;

export const PokDengSwitchTableMap = (props: PokDengRoadMapProps) => {
    const { hostId } = props;
    const { pokDengRoadRecord, IsRest } = useRoadMapState(hostId);
    const [display, setDisplay] = useState(<></>);
    useEffect(() => {
        const cells = new Array<JSX.Element>();
        for (let i = 0; i < columnNum; i++) {
            let start = 0;
            if (pokDengRoadRecord) {
                start = Math.max(pokDengRoadRecord.length - columnNum, 0);
            }
            const r = pokDengRoadRecord?.at(start + i) as
                | PokDengRoadmapRecord
                | undefined;
            const cell = (
                <PokDengRoadmapColumn
                    size={size}
                    record={r}
                    havePoint={havePoint}
                    key={`game-id-${hostId}-${i}`}
                />
            );
            cells.push(cell);
        }
        setDisplay(<>{cells}</>);
    }, [pokDengRoadRecord]);
    return (
        <div className="pokdeng-switch-table-container">
            <PokDengRoadmapGrid size={size} havePoint={havePoint} />
            {!IsRest && <div className="pokdeng-record">{display}</div>}
        </div>
    );
};
