import { Tab, Tabs } from '@mui/material';
import {
    SyntheticEvent,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseButton from '../../../../components/common/button/baseButton';
import { LobbyUiContext } from '../../../../contexts/LobbyUiContext';
import { ROUTE_MULTIBET } from '../../../../models/Route';
import { GameFeature as GameFeatureEnum } from '../../../../models/games/enums/GameFeature';
import { GameType } from '../../../../models/games/enums/GameType';
import { GameCategoryTab as GameCategoryTabEnum } from '../../../../models/lobby/GameCategoryTab';
import { isBitActive } from '../../../../utils/bitwiseUtil';
import { calcLowHigh } from '../../../../utils/commonFunc';
import { getLandingState, getMainPlayerState } from '../../../main/selector';
import { routeSliceActions } from '../../../routeController/slice';
import { getLobbyStateHallGameTypeMap } from '../../selector';
import BaccaratButton from './Buttons/BaccaratButton';
import { FeaturedButton } from './Buttons/FeatureButton';
import './index.scss';
import { CommonConfigContext } from '../../../../contexts/ConfigContext';

export const GameCategory = () => {
    const { lite } = useSelector(getLandingState);
    const { currentTab, changeCurrentTab } = useContext(LobbyUiContext);
    const [isShowFeature, setIsShowFeature] = useState(false);
    const [isShowBaccarat, setIsShowBaccarat] = useState(true);
    const [isShowOther, setIsShowOther] = useState(true);
    const [isShowMultiBet, setIsShowMultiBet] = useState<boolean>(true);
    const { hideMultiBet } = useContext(CommonConfigContext);
    const { GameFeature } = useSelector(getMainPlayerState);
    const dispatch = useDispatch();
    const hallGameTypeMap = useSelector(getLobbyStateHallGameTypeMap);
    const baccaratHallMap = useMemo(
        () => hallGameTypeMap.filter(h => h.gameType === GameType.Baccarat),
        [hallGameTypeMap]
    );
    const otherGameMap = useMemo(
        () => hallGameTypeMap.filter(h => h.gameType !== GameType.Baccarat),
        [hallGameTypeMap]
    );
    useEffect(() => {
        if (GameFeature) {
            const curGameFeature = calcLowHigh(GameFeature);
            const isShow = isBitActive(
                curGameFeature,
                GameFeatureEnum.FeatureTab
            );
            setIsShowFeature(isShow);
        }
    }, [GameFeature]);
    useEffect(() => {
        setIsShowBaccarat(baccaratHallMap.length > 0);
        setIsShowMultiBet(
            lite || hideMultiBet ? false : baccaratHallMap.length > 0
        );
    }, [baccaratHallMap]);
    useEffect(() => {
        setIsShowOther(otherGameMap.length > 0);
    }, [otherGameMap]);
    useEffect(() => {
        if (!isShowBaccarat && currentTab == GameCategoryTabEnum.Baccarat) {
            if (isShowFeature) {
                changeCurrentTab(GameCategoryTabEnum.Featured);
            } else {
                changeCurrentTab(GameCategoryTabEnum.OtherGames);
            }
        }
    }, [isShowBaccarat]);
    useEffect(() => {
        if (!isShowOther && currentTab == GameCategoryTabEnum.OtherGames) {
            if (isShowFeature) {
                changeCurrentTab(GameCategoryTabEnum.Featured);
            } else if (isShowBaccarat) {
                changeCurrentTab(GameCategoryTabEnum.Baccarat);
            }
        }
    }, [isShowOther]);
    const onTabChange = (
        event: SyntheticEvent<Element, Event>,
        v: GameCategoryTabEnum
    ) => {
        if (v == GameCategoryTabEnum.MultiBet) {
            dispatch(routeSliceActions.goto(ROUTE_MULTIBET));
        } else {
            changeCurrentTab(v);
        }
    };
    return (
        <Tabs
            className="game-category-container"
            value={currentTab}
            onChange={onTabChange}
            variant="scrollable"
            scrollButtons={false}
        >
            {isShowFeature && (
                <Tab
                    key={GameCategoryTabEnum.Featured}
                    value={GameCategoryTabEnum.Featured}
                    icon={<FeaturedButton />}
                    iconPosition="start"
                />
            )}
            {isShowBaccarat && (
                <Tab
                    key={GameCategoryTabEnum.Baccarat}
                    value={GameCategoryTabEnum.Baccarat}
                    icon={<BaccaratButton />}
                    iconPosition="start"
                />
            )}
            {isShowOther && (
                <Tab
                    key={GameCategoryTabEnum.OtherGames}
                    value={GameCategoryTabEnum.OtherGames}
                    icon={
                        <BaseButton
                            className={'other-game-button'}
                            id={GameCategoryTabEnum.OtherGames}
                            imageName={'menu'}
                            active={'ic_othergames_hover_vert'}
                            inActive={'ic_othergames_vert'}
                            activeId={currentTab}
                        />
                    }
                    iconPosition="start"
                />
            )}
            {isShowMultiBet && (
                <Tab
                    key={GameCategoryTabEnum.MultiBet}
                    value={GameCategoryTabEnum.MultiBet}
                    icon={
                        <BaseButton
                            className={'other-game-button'}
                            id={GameCategoryTabEnum.MultiBet}
                            imageName={'menu'}
                            active={'ic_multibet_hover_vert'}
                            inActive={'ic_multibet_vert'}
                            activeId={currentTab}
                        />
                    }
                    iconPosition="start"
                />
            )}
        </Tabs>
    );
};
