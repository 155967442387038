import BaseImage from '../baseImage';
import { Stack } from '@mui/material';
// import { Button } from '@mui/material';

type ConfirmPanelProps = {
    onConfirmClick: (event: React.MouseEvent<SVGSVGElement>) => void;
    onCancelClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
    scale?: number;
    isRowReverse?: boolean;
    isEnable?: boolean; // Todo: disable function (now only set button image opacity)
};
const ConfirmPanel = (props: ConfirmPanelProps) => {
    const {
        scale = 0.7,
        isRowReverse = false,
        onConfirmClick,
        onCancelClick,
        isEnable = true,
    } = props;
    const size = 57;
    return (
        <Stack direction={isRowReverse ? 'row-reverse' : 'row'}>
            <Stack>
                <BaseImage
                    className="commonUI btnCancel"
                    scale={scale}
                    opacity={isEnable ? 1 : 0.5}
                />
                <svg
                    height={size}
                    width={size}
                    onClick={onCancelClick}
                    style={{ position: 'absolute' }}
                >
                    <circle
                        cx={size / 2}
                        cy={size / 2}
                        r={size / 2}
                        fill="transparent"
                    />
                </svg>
            </Stack>
            <Stack>
                <BaseImage
                    className="commonUI btnOk"
                    scale={scale}
                    opacity={isEnable ? 1 : 0.5}
                />
                <svg
                    height={size}
                    width={size}
                    onClick={onConfirmClick}
                    style={{ position: 'absolute' }}
                >
                    <circle
                        cx={size / 2}
                        cy={size / 2}
                        r={size / 2}
                        fill="transparent"
                    />
                </svg>
            </Stack>
            {/* <Button onClick={onCancelClick} sx={{ padding: 0, margin: 0 }}>
                <BaseImage
                    className="commonUI btnCancel"
                    scale={scale}
                    opacity={isEnable ? 1 : 0.5}
                />
            </Button> */}
            {/* <Button onClick={onConfirmClick} sx={{ padding: 0, margin: 0 }}>
                <BaseImage
                    className="commonUI btnOk"
                    scale={scale}
                    opacity={isEnable ? 1 : 0.5}
                />
            </Button> */}
        </Stack>
    );
};

export default ConfirmPanel;
