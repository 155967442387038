import { Navigate } from 'react-router-dom';
import { ROUTE_MULTIBET } from '../../../../models/Route';

export const MultiBetTables = () => {
    return (
        <>
            <Navigate to={ROUTE_MULTIBET} />
        </>
    );
};
